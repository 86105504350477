import React, { useState } from "react";
import { Resizable } from "re-resizable";
import { useLocation, useNavigate } from "react-router-dom";
import { Sidebar } from "../UI/Sidebar/Sidebar";
import { ActiveAppState } from "../App/AppProvider";

let previousSize = 0;

type Props = {
  onSignOut: () => any;
  renderSidebar: boolean;
  width: number;
  onWidthChange: (value: number) => void;
};

const CustomSidebar = ({
  onSignOut,
  renderSidebar,
  width,
  onWidthChange,
}: Props) => {
  const [height, setHeight] = useState("fit-content");
  const [resizingBase, setResizingBase] = useState(0);
  const [resize, enableResize] = useState(true);
  const [mobileIsOpen, setMobileOpen] = useState(false);
  const closeSidebar = () => {
    previousSize = width;
    onWidthChange(80);
    enableResize(false);
  };
  const openSidebar = () => {
    onWidthChange(previousSize);
    enableResize(true);
  };

  return renderSidebar ? (
    <Resizable
      className={mobileIsOpen ? "resizeable-open" : "resizeable-closed"}
      size={{ width, height }}
      onResizeStart={() => {
        setResizingBase(width);
      }}
      onResize={(e: any, direction: any, ref: any, d: any) => {
        onWidthChange(d.width + resizingBase);
        // setHeight(height + d.height);
      }}
      onResizeStop={(e: any, direction: any, ref: any, d: any) => {
        onWidthChange(d.width + resizingBase);
        setResizingBase(0);
        // setHeight(height + d.height);
      }}
      minWidth={resize ? 300 : 80}
      maxWidth={resize ? 1000 : 80}
      enable={{
        top: false,
        right: resize,
        bottom: false,
        left: false,
        topRight: false,
        bottomRight: false,
        bottomLeft: false,
        topLeft: false,
      }}>
      <Sidebar
        onSignOut={onSignOut}
        closeSidebar={closeSidebar}
        openSidebar={openSidebar}
        mobileIsOpen={mobileIsOpen}
        setMobileOpen={setMobileOpen}
      />
    </Resizable>
  ) : null;
};

export default CustomSidebar;
