import { Image, ImageProps } from "@chakra-ui/react";
import headerImgLandscape from "../../images/logo_landscape.png";
import { useAppState } from "../App/AppProvider";

type Props = ImageProps;

const AccountLogo = ({ ...imageProps }: Props) => {
  const { app } = useAppState();
  const { logoPath, newLogoPath } = app.accountInfo;

  if (!logoPath) {
    return <Image
    fit="contain"
    src={headerImgLandscape}
    {...imageProps}
  />;
  }
  return (
    <Image
      fit="contain"
      src={ newLogoPath ? newLogoPath : logoPath}
      {...imageProps}
    />
  );
};

export default AccountLogo;
