import {
  Button,
  ButtonGroup,
  Flex,
  HStack,
  Icon,
  IconButton,
  Input,
  Popover,
  PopoverTrigger,
  Tooltip,
  VStack,
  chakra,
  useDisclosure,
} from "@chakra-ui/react";
import { useEffect, useMemo, useState } from "react";
import { RiAddLine, RiCloseLine, RiFilter2Line } from "react-icons/ri";
import {
  FilterableFieldSelection,
  getAvailableOpsForField,
  getCoreFilterableFields,
  getOpLabel,
} from "../../../../constants/frontEndFilterHelpers";
import { CustomFieldType } from "../../../../shared/v2/definitions/customFields";
import { List } from "../../../../shared/v2/definitions/lists";
import { mapFilter } from "../../../../utilities/mapFilter";
import AddFilterPopoverContent from "./AddFilterPopoverContent";
import {
  PreparedFilter,
  RecordsViewAction,
  RecordsViewState,
} from "./useRecordsViewState";
import Select from "../../../Select";
import RecordsViewFilterOpSelect from "./FilterOpSelect";
import RecordsViewFilter from "./RecordsViewFilter";
import { isListType } from "../../../../shared/v2/constants/CustomFieldTypeID";

// Dont allow the user to add more than 8 filters atm
const MAX_FILTERS_COUNT = 8;

type Props = {
  state: RecordsViewState;
  dispatch: React.Dispatch<RecordsViewAction>;
  customFields?: CustomFieldType[];
  lists?: Record<string, List | null>;
  isFetching: boolean;
};

export default function RecordsViewFilters({
  state,
  dispatch,
  customFields,
  lists,
  isFetching,
}: Props) {
  const popoverState = useDisclosure();

  // Get all the custom fields that we can filter on
  const filterableFields: FilterableFieldSelection[] = useMemo(() => {
    const custom: FilterableFieldSelection[] = customFields
      ? mapFilter(customFields, (cf) => ({
          identifier: {
            type: "custom",
            ID: cf.customFieldID,
            agg: isListType(cf.customFieldTypeID) ? true : undefined,
          },
          label: cf.label,
          value: `cf_${cf.customFieldID}`,
          availableOps: getAvailableOpsForField(cf),
          listID: cf.listID || undefined,
        }))
      : [];
    return [...getCoreFilterableFields(), ...custom];
  }, [customFields]);

  const handleAddFilter = (filter: PreparedFilter) => {
    dispatch({
      type: "addFilter",
      filter,
    });
    popoverState.onClose();
  };

  const handleClearFilters = () => {
    dispatch({
      type: "clearFilters",
    });
  };

  const handleDeleteFilter = (filterID: string) => {
    dispatch({
      type: "deleteFilter",
      filterID,
    });
  };

  const atMaxfilters = state.filters.length >= MAX_FILTERS_COUNT;
  const canAddFilter = !atMaxfilters && !isFetching;

  const [internalQueryState, setInternalQueryState] = useState("");
  useEffect(() => {
    setInternalQueryState(state.query);
  }, [state]);
  return (
    <VStack align="start">
      <chakra.form
        onSubmit={(e) => {
          e.preventDefault();
          dispatch({
            type: "updateQuery",
            query: internalQueryState,
          });
        }}>
        <HStack w="full" maxW="96">
          <Input
            value={internalQueryState}
            onChange={(e) => setInternalQueryState(e.target.value)}
            size="sm"
            placeholder="Search by name or id"
          />
          <Button size="sm" type="submit" isDisabled={isFetching}>
            Search
          </Button>
        </HStack>
      </chakra.form>
      <Flex display="flex" flexWrap="wrap" rowGap="1" columnGap="2">
        {state.filters.map((filter) => (
          <RecordsViewFilter
            lists={lists ?? {}}
            filter={filter}
            key={filter.ID}
            onClickDelete={() => handleDeleteFilter(filter.ID)}
            onUpdateFilter={(filterPatch) =>
              dispatch({
                type: "updateFilter",
                filter: filterPatch,
              })
            }
          />
        ))}
        <ButtonGroup size="sm">
          <Popover
            isLazy
            isOpen={popoverState.isOpen}
            onClose={popoverState.onClose}>
            <PopoverTrigger>
              <span>
                <Tooltip
                  shouldWrapChildren
                  label={
                    atMaxfilters
                      ? `You can't have more than ${MAX_FILTERS_COUNT} filters at a time`
                      : undefined
                  }>
                  <Button
                    colorScheme="brand"
                    variant="outline"
                    borderStyle="dashed"
                    isDisabled={!canAddFilter}
                    onClick={popoverState.onOpen}
                    leftIcon={<Icon as={RiAddLine} />}>
                    Filter
                  </Button>
                </Tooltip>
              </span>
            </PopoverTrigger>
            <AddFilterPopoverContent
              addFilter={handleAddFilter}
              lists={lists ?? {}}
              filterableFields={filterableFields}
            />
          </Popover>
          {state.filters.length >= 1 && (
            <Button
              variant="outline"
              colorScheme="red"
              onClick={handleClearFilters}
              leftIcon={<Icon as={RiCloseLine} />}>
              Clear Filters
            </Button>
          )}
        </ButtonGroup>
      </Flex>
    </VStack>
  );
}
