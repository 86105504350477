import React, { useEffect, useState } from "react";
import FileSaver from "file-saver";
import { Col, Container, Row } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { useAppState } from "../../components/App/AppProvider";
import { Text } from "../../components/Forms/Field/Text";
import { Card } from "../../components/UI/Card/Card";
import { Loading } from "../../components/UI/Loading/Loading";
import { get } from "../../utilities/request";

type ScreensExportPageStatus = "Loading" | "Ready";

const ScreensExport = () => {
  const { auth } = useAppState();
  const location = useLocation();
  const [pageStatus, setPageStatus] =
    useState<ScreensExportPageStatus>("Loading");

  useEffect(() => {
    const fetchData = async () => {
      setPageStatus("Loading");
      const split = location.pathname.split("/");
      console.log(split);
      const doc = await get(`export/${split[2]}`, auth);
      console.log("finishedDownloading", doc.data.content);
      if (doc.status === 200) {
        const arraybuffer = Uint8Array.from(doc.data.content.data).buffer;
        const file = new File([arraybuffer], "ExportedCSVs.zip", {
          type: "application/zip",
        });
        FileSaver.saveAs(file);
        setPageStatus("Ready");
      }
    };

    if (auth.isLoggedIn) {
      fetchData();
    }
  }, [auth, location]);
  return pageStatus === "Loading" ? (
    <Container>
      <Card title="Exported Data" collapsable={false}>
        <Row>
          <Col>
            <Text
              name="exportText"
              readOnly
              value="Your data is currently being retrieved. Please do not refresh your browser"
            />
            <Loading />
          </Col>
        </Row>
      </Card>
    </Container>
  ) : (
    <Container>
      <Card title="Exported Data" collapsable={false}>
        <Row>
          <Col>
            <Text
              name="exportText"
              readOnly
              value="Your data has successfully been downloaded!"
            />
          </Col>
        </Row>
      </Card>
    </Container>
  );
};

export { ScreensExport };
