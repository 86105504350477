import {
  Skeleton,
  VStack
} from "@chakra-ui/react";
import React from "react";

const SidebarLoading = () =>(
    <chakra-scope>
          <VStack w="full" spacing={6} px="12%">
            <Skeleton height="30px" w="full" />
            <Skeleton height="20px" w="full" />
            <Skeleton height="20px" w="full" />
            <Skeleton height="20px" w="full" />
            <Skeleton height="20px" w="full" />
            <Skeleton height="20px" w="full" />
            <Skeleton height="20px" w="full" />
          </VStack>
    </chakra-scope>
  );
export default SidebarLoading;
