import { Button, Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";
import Case from "case";
import { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import Icon from "../../../UI/Icon";
import { Filters, Lifecycle } from "../BaseWidgetProps";

type Props = {
  lifecycle?: Lifecycle;
  patchFilters: (filters: Partial<Filters>) => void;
};

const LifecycleFilter = ({ patchFilters, ...props }: Props) => {
  const [lifecycle, setLifecycle] = useState(props.lifecycle);

  useEffect(() => {
    setLifecycle(props.lifecycle);
  }, [props.lifecycle, setLifecycle]);

  const body = document.body;

  return (
    <Menu>
      <MenuButton size="sm" rightIcon={<Icon name="ChevronDown" />} as={Button}>
        {`Lifecycle:
                          ${Case.capital(lifecycle || "Any")}`}
      </MenuButton>

      {createPortal(
        <chakra-scope>
          <MenuList zIndex={11}>
            <MenuItem
              onClick={() => {
                setLifecycle(undefined);
                patchFilters({
                  lifecycle: undefined,
                });
              }}>
              Any
            </MenuItem>
            <MenuItem
              onClick={() => {
                setLifecycle("upcoming");
                patchFilters({
                  lifecycle: "upcoming",
                });
              }}>
              Upcoming
            </MenuItem>
            <MenuItem
              onClick={() => {
                setLifecycle("openResponded");
                patchFilters({
                  lifecycle: "openResponded",
                });
              }}>
              Open Responded
            </MenuItem>
            <MenuItem
              onClick={() => {
                setLifecycle("due");
                patchFilters({
                  lifecycle: "due",
                });
              }}>
              Due
            </MenuItem>
            <MenuItem
              onClick={() => {
                setLifecycle("overdue");
                patchFilters({
                  lifecycle: "overdue",
                });
              }}>
              Overdue
            </MenuItem>

            <MenuItem
              onClick={() => {
                setLifecycle("closed");
                patchFilters({
                  lifecycle: "closed",
                });
              }}>
              Closed (Completed)
            </MenuItem>
            <MenuItem
              onClick={() => {
                setLifecycle("missed");
                patchFilters({
                  lifecycle: "missed",
                });
              }}>
              Closed (Missed)
            </MenuItem>
          </MenuList>
        </chakra-scope>,
        body,
      )}
    </Menu>
  );
};

export default LifecycleFilter;
