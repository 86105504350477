import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Badge,
  Box,
  Button,
  ButtonGroup,
  Heading,
  HStack,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Tooltip,
  useDisclosure
} from "@chakra-ui/react";
import { useState } from "react";
import { RiArrowRightLine } from "react-icons/ri";
import { useQueryClient } from "@tanstack/react-query";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { Card } from "../../components/UI/Card/Card";
import { DeleteIcon } from "../../constants/commonIcons";
import { useApiMutation, useApiQuery } from "../../utilities/apibelRequest";
import { ManualSectionDropdown } from "./ManualSectionDropdown";
import RequirementManualRichText from "./RequirementManualRichText";

type DeleteProps = {
  isOpen: boolean;
  onClose: () => void;
  onDelete: () => void;
  title: string;
};
type Props = {
  recordID: string;
};

function ConfirmDeleteModal({ isOpen, onClose, onDelete, title }: DeleteProps) {
  return (
    <chakra-scope>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <chakra-scope>
            <ModalHeader>Unlink section</ModalHeader>
            <ModalCloseButton variant="ghost" />
            <ModalBody>
              <Text>Are you sure you want to unlink {title}?</Text>
              <Text>The section will not be deleted from the Manual.</Text>
            </ModalBody>
            <ModalFooter>
              <ButtonGroup>
                <Button isDisabled={false} colorScheme="gray" onClick={onClose}>
                  Cancel
                </Button>
                <Button
                  colorScheme="red"
                  variant="solid"
                  isLoading={false}
                  onClick={onDelete}>
                  Unlink
                </Button>
              </ButtonGroup>
            </ModalFooter>
          </chakra-scope>
        </ModalContent>
      </Modal>
    </chakra-scope>
  );
}

export function RequirementManualSectionLinks({ recordID }: Props) {
  const linkedItemsQuery = useApiQuery("manual/manySectionsForLinkedRecord", {
    recordID,
  });
  const deleteRelationshipMutation = useApiMutation(
    "manual/deleteSectionRecordLink",
  );
  const [title, setTitle] = useState("");
  const [requirementManualSectionLinkID, setRequirementManualSectionLinkID] =
    useState("");
  const confirmModal = useDisclosure();
  const queryClient = useQueryClient();
  const invalidateQuery = () => {
    queryClient.invalidateQueries(["manual"]);
  };
  const handleDelete = async (relationshipID: string) => {
    await deleteRelationshipMutation.mutateAsync({
      requirementManualSectionID: relationshipID,
    });
    invalidateQuery();
    confirmModal.onClose();
  };

  return (
    <Card
      collapsable
      title="Manual"
      headerColsRight
      headerCols={[
        {
          colProps: { style: { textAlign: "right" } },
          children: (
            <ManualSectionDropdown selectedIDs={linkedItemsQuery.data?.sections.map((sect) => sect.manualSectionID) || []} recordID={recordID} />
          ),
        },
      ]}>
      <chakra-scope>
        {linkedItemsQuery.isSuccess &&
          (linkedItemsQuery.data.sections.length <= 0 ? (
            <Text>No linked sections...</Text>
          ) : (
            linkedItemsQuery.data.sections.map((section) => (
              <Box key={section.manualSectionID}>
                <Accordion defaultIndex={[0]} allowMultiple>
                  <AccordionItem>
                    <AccordionButton as="h2">
                      <HStack
                        as="span"
                        flex="1"
                        textAlign="left"
                        align="center">
                        <Badge colorScheme="gray">{section.manual.name}</Badge>
                        <Heading size="sm">{section.title}</Heading>
                      </HStack>
                      <Tooltip label="Unlink section" placement="top">
                        <IconButton
                          variant="ghost"
                          colorScheme="red"
                          aria-label="Unlink"
                          icon={<DeleteIcon />}
                          onClick={(e) => {
                            e.preventDefault();
                            setTitle(section.title);
                            setRequirementManualSectionLinkID(
                              section.requirementManualSectionLinkID,
                            );
                            confirmModal.onOpen();
                          }}
                        />
                      </Tooltip>
                      <Tooltip label="Go to manual" placement="top">
                        <IconButton
                          variant="ghost"
                          as={RouterLink}
                          aria-label="Go to manual"
                          icon={<RiArrowRightLine />}
                          to={`/manual/${section.manual.manualID}`}
                        />
                      </Tooltip>
                      <AccordionIcon ml="6" />
                    </AccordionButton>
                    <AccordionPanel>
                      <RequirementManualRichText
                        contentJSON={section.content.jsonStr || ""}
                        preview={section.content.rawPreview || ""}
                      />
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>
              </Box>
            ))
          ))}
        <ConfirmDeleteModal
          isOpen={confirmModal.isOpen}
          onClose={confirmModal.onClose}
          onDelete={() => handleDelete(requirementManualSectionLinkID)}
          title={title}
        />
      </chakra-scope>
    </Card>
  );
}
