import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useAppState } from "../../../components/App/AppProvider";
import { SpinnyButton } from "../../../components/Forms/Field/SpinnyButton";
import { Loading } from "../../../components/UI/Loading/Loading";
import OneDriveLogo from "../../../images/OneDriveLogo.png";
import { OneDriveConfig } from "../../../shared/constants/oneDriveConfig";
import { del, get } from "../../../utilities/request";
import useToast from "../../../utilities/useToast";

type OneDriveIntegrationStatus = "Loading" | "Ready" | "Editing";

const OneDriveDisableIntegration = () => {
  const { auth } = useAppState();
  const [state, setState] = React.useState<any>({
    Connected: false,
    MicrosoftAccountKey: "",
  });
  const [pageStatus, setPageStatus] =
    useState<OneDriveIntegrationStatus>("Loading");
  const { displayToast } = useToast();

  const getIntegrationStatus = async () => {
    const info: any = await get("settings/onedrive", auth);
    setState({ ...state, Connected: info.data.connection !== null });
    // console.log('info', info.data)
    setPageStatus("Ready");
  };

  const disableIntegration = async () => {
    const result = window.confirm(
      "Are you sure you wish to disable your OneDrive integration?",
    );
    if (result) {
      setPageStatus("Loading");

      const deleteResults = await del(`settings/onedrive`, auth);
      // console.log(deleteResults)

      if (deleteResults.data.length === 0) {
        displayToast({
          status: "success",
          title: "OneDrive Integration successfully disabled",
        });
      } else {
        displayToast({
          status: "error",
          title: "Disabling Integraton Error",
        });
      }
      setState({ ...state, Connected: deleteResults.data[0] || null });
      setPageStatus("Ready");
    }
  };

  useEffect(() => {
    getIntegrationStatus();
  }, [auth]);

  if (state.Connected) {
    return (
      <Form.Group as={Row}>
        <Form.Label column sm="2">
          <img
            src={OneDriveLogo}
            alt="OneDriveLogo"
            style={{ width: "100px" }}
          />
        </Form.Label>
        <Col sm="8">
          <Row>
            <Col sm="3">
              <Button variant="outline-success">Connected</Button>
            </Col>
            <Col sm="3">
              <SpinnyButton
                style={{ marginLeft: "20px" }}
                variant="outline-dark"
                onClick={() => disableIntegration()}
                text="Disconnect"
              />
            </Col>
          </Row>
        </Col>
      </Form.Group>
    );
  }
  return null;
};

/* This is what we would use if it was on the integrations page */
const OneDriveIntegration = () => {
  const { auth } = useAppState();
  const [state, setState] = React.useState<any>({
    Connected: false,
    MicrosoftAccountKey: "",
  });
  const [pageStatus, setPageStatus] =
    useState<OneDriveIntegrationStatus>("Loading");
  const { displayToast } = useToast();

  const getIntegrationStatus = async () => {
    const info: any = await get("settings/onedrive", auth);
    setState({ ...state, Connected: info.data.connection !== null });
    // console.log('info', info.data)
    setPageStatus("Ready");
  };

  const disableIntegration = async () => {
    const result = window.confirm(
      "Are you sure you wish to disable your OneDrive integration?",
    );
    if (result) {
      setPageStatus("Loading");

      const deleteResults = await del(`settings/onedrive`, auth);
      // console.log(deleteResults)

      if (deleteResults.data.length === 0) {
        displayToast({
          severity: "success",
          title: "OneDrive Integration successfully disabled",
        });
      } else {
        displayToast({
          severity: "danger",
          title: "Disabling Integraton Error",
        });
      }
      setState({ ...state, Connected: deleteResults.data[0] || null });
      setPageStatus("Ready");
    }
  };

  useEffect(() => {
    getIntegrationStatus();
  }, [props]);

  const client_id = OneDriveConfig.ClientID;
  const scope = OneDriveConfig.Scopes;
  const redirect_uri = `${window.location.protocol}//${window.location.host}${OneDriveConfig.RedirectEndpoint}`;

  const OneDriveUrl = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=${client_id}&scope=${scope}&response_type=code&redirect_uri=${redirect_uri}`;

  return (
    <Formik
      initialValues={state}
      onSubmit={async (values, actions) => {
        setPageStatus("Loading");

        setPageStatus("Ready");
      }}
      render={(formikProps) => (
        <Form onSubmit={formikProps.handleSubmit}>
          <Row>
            <Col sm="3" style={{ textAlign: "center" }}>
              <img
                src={OneDriveLogo}
                alt="OneDriveLogo"
                style={{ width: "200px" }}
              />
            </Col>
            <Col sm="9" style={{ textAlign: "center" }}>
              {pageStatus === "Loading" ? <Loading /> : null}

              {pageStatus === "Ready" && !state.Connected ? (
                <a href={OneDriveUrl}>
                  <Button variant="outline-dark">Set Up</Button>
                </a>
              ) : null}

              {pageStatus === "Ready" && state.Connected ? (
                <>
                  <Button
                    style={{ marginLeft: "20px" }}
                    variant="outline-success">
                    Connected
                  </Button>

                  <Button
                    style={{ marginLeft: "20px" }}
                    variant="outline-dark"
                    onClick={() => disableIntegration()}>
                    Disconnect
                  </Button>
                </>
              ) : null}
            </Col>
          </Row>

          <section />
        </Form>
      )}
    />
  );
};

export { OneDriveDisableIntegration };
