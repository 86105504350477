// import {
//   Container,
//   Box,
//   BoxProps,
//   Heading,
//   IconButton,
//   ButtonGroup,
//   HStack,
//   VStack,
//   ContainerProps,
//   useColorModeValue,
//   ListItem,
//   Card,
//   CardHeader,
//   Text,
//   CardBody,
//   Button,
//   Icon,
//   useDisclosure,
//   Stepper,
//   Step,
//   StepIcon,
//   StepNumber,
//   StepIndicator,
//   StepSeparator,
//   StepStatus,
//   StepTitle,
//   StepDescription,
//   useSteps,
//   List,
//   UnorderedList,
//   Center,
// } from "@chakra-ui/react";
import { Button, Group } from "@mantine/core";
import { useState } from "react";
import { RiArrowLeftSLine } from "react-icons/ri";
import QuestionButton from "./QuestionButton/QuestionButton";

type Props = {
  onYes: () => void;
  onNo: () => void;
  onBack: () => void;
};

type Option = "yes" | "no";

export default function QuestionStepInitial({ onYes, onNo, onBack }: Props) {
  const [selected, setSelected] = useState<Option | null>(null);
  const handleContinue = () => {
    if (selected === "yes") {
      onYes();
    } else if (selected === "no") {
      onNo();
    }
  };
  return (
    <>
      <Group
        w="100%"
        pt="64"
        align="start"
        justify="center"
        style={{ flexGrow: 1, overflowY: "auto" }}>
        <QuestionButton
          maw="240"
          onClick={() => setSelected((prev) => (prev === "yes" ? null : "yes"))}
          label="Yes"
          isChecked={selected === "yes"}
        />
        <QuestionButton
          maw="240"
          onClick={() => setSelected((prev) => (prev === "no" ? null : "no"))}
          label="No"
          isChecked={selected === "no"}
        />
      </Group>
      <Group w="100%" justify="space-between" style={{ alignSelf: "flex-end" }}>
        <Button
          leftSection={<RiArrowLeftSLine size={16} />}
          variant="subtle"
          onClick={onBack}>
          Back
        </Button>
        <Button disabled={selected === null} onClick={handleContinue}>
          Continue
        </Button>
      </Group>
    </>
  );
}
