import React from "react";
import {
  Col,
  Container,
  Dropdown,
  Form,
  OverlayTrigger,
  Row,
  Table,
  Tooltip
} from "react-bootstrap";
import * as Feather from "react-feather";
import { Link, useLocation } from "react-router-dom";
import { useAppState } from "../../components/App/AppProvider";
import { Textarea } from "../../components/Forms/Field/Textarea";
import { Card } from "../../components/UI/Card/Card";
import { Header } from "../../components/UI/Header/Header";
import LegacyScreenContainer from "../../components/UI/LegacyScreenContainer";
import { Loading } from "../../components/UI/Loading/Loading";
import { NewObjectTypeButton } from "../../components/UI/NewObjectTypeButton";
import { Paginator } from "../../components/UI/Paginator/Paginator";
import { LifeCycle } from "../../components/UI/Status/LifeCycle";
import {
  objectStatusToVariant,
  Status
} from "../../components/UI/Status/Status";
import * as ObjectTypeGroup from "../../constants/objectTypeGroup";
import * as ObjectStatus from "../../shared/constants/objectStatus";
import { formatDateTime } from "../../utilities/formatDate";
import * as Request from "../../utilities/request";
import { text_stripHTML, text_truncate } from "../../utilities/strings";
import { getUrlSearchParam } from "../../utilities/url";
import useToast from "../../utilities/useToast";

interface IssueDashboardState {
  requirements?: any[];
  instances?: any[];
  fetchingRequirements?: boolean;
  fetchingInstances?: boolean;
  error?: any;
}

interface CategoryFilter {
  name: string;
  id: string;
  selected: boolean;
}

interface IssueDashboardFiltersState {
  upcoming: boolean;
  due: boolean;
  overdue: boolean;
  importantIssuesPage: number;
  issueActionsPage: number;
  pageSize: number;
  categories: CategoryFilter[];
  selectedCategory: string;
}

type IssueDashboardStatus =
  | "Loading"
  | "Ready"
  | "Editing"
  | "Fetching"
  | "Error";

const lifeCycleFilter = (filters: IssueDashboardFiltersState, instance: any) =>
  instance.LifeCycle !== "Closed" &&
  ((filters.upcoming && instance.LifeCycle === "Upcoming") ||
    (filters.due && instance.LifeCycle === "Due") ||
    (filters.overdue && instance.LifeCycle === "Overdue"));

const categoryFilter = (filters: IssueDashboardFiltersState, issue: any) => {
  let isIncluded = false;
  if (filters.selectedCategory === "all") {
    return true;
  }
  filters.categories.forEach((category: CategoryFilter) => {
    if (
      category.selected &&
      (issue.RequirementObjectTypeID === category.id ||
        issue.ObjectTypeID === category.id)
    ) {
      isIncluded = true;
    }
  });

  return isIncluded;
};
const pageFilter = (page: number, pageSize: number, index: number) =>
  index < page * pageSize && index >= (page - 1) * pageSize;

const ScreensIssueDashboard = () => {
  const [data, setData] = React.useState<IssueDashboardState>({});
  const location = useLocation();
  const { auth } = useAppState();
  const [filters, setFilters] = React.useState<IssueDashboardFiltersState>({
    upcoming: false,
    due: true,
    overdue: true,
    importantIssuesPage: 1,
    issueActionsPage: 1,
    pageSize: 10,
    categories: [],
    selectedCategory: "all",
  });
  const [pageStatus, setPageStatus] =
    React.useState<IssueDashboardStatus>("Loading");
  const { displayToast } = useToast();
  const isMainPage = !location.search;
  const templateType = window.location.href.indexOf("template") > -1;

  const getUrlFilter = (categories?: any) => {
    if (!categories) {
      categories = filters.categories;
    }
    const filter = getUrlSearchParam(location.search, "filter");
    let selected = "all";
    const newCategories = categories.map((category: CategoryFilter) => {
      if (category.id === filter) {
        selected = category.name;
        return { name: category.name, id: category.id, selected: true };
      }
      return {
        name: category.name,
        id: category.id,
        selected: !!(!filter || filter === "all"),
      };
    });
    setFilters({
      ...filters,
      importantIssuesPage: 1,
      categories: newCategories,
      selectedCategory: selected,
    });
  };

  React.useEffect(() => {
    const getData = async () => {
      try {
        setData({ fetchingRequirements: true, fetchingInstances: true });
        const issueObjectTypes = await Request.get(
          `app/objecttypegroup/${ObjectTypeGroup.Issue}/objecttype`,
          auth,
        );
        const categories: CategoryFilter[] =
          issueObjectTypes.data.ObjectTypes.map((objectType: any) => ({
            name: objectType.ObjectTypeName,
            id: objectType.ObjectTypeID,
            selected: true,
          }));
        getUrlFilter(categories);

        await Request.getPaginated(`issue`, 300, updateIssueResults, auth);
        await Request.getPaginated(
          `instance?requirementobjecttypegroupid=${ObjectTypeGroup.Issue}&`,
          500,
          updateInstanceResults,
          auth,
        );
      } catch (err) {
        setData({
          error: err,
        });
      }
    };
    getData();
  }, [auth]); // eslint-disable-line

  React.useEffect(() => {
    getUrlFilter();
  }, [location.search, location.pathname, data]); // eslint-disable-line

  let interimIssueResults: any[] = [];
  const updateIssueResults = (
    responseData: any,
    status: Request.RequestStatus,
  ) => {
    if (status !== "Error") {
      interimIssueResults = interimIssueResults.concat(
        responseData.Requirements,
      );
      setData((prevData) => ({
        ...prevData,
        requirements: interimIssueResults,
        fetchingRequirements: status === "Fetching",
      }));
    } else {
      displayToast({
        status: "error",
        message: `Failed to retrieve Issues`,
      });
      setData((prevData) => ({ ...prevData, fetchingRequirements: false }));
    }
    setPageStatus(status);
  };

  let interimInstanceResults: any[] = [];
  const updateInstanceResults = (
    responseData: any,
    status: Request.RequestStatus,
  ) => {
    if (status !== "Error") {
      interimInstanceResults = interimInstanceResults.concat(
        responseData.Instances,
      );
      setData((prevData) => ({
        ...prevData,
        instances: interimInstanceResults,
        fetchingInstances: status === "Fetching",
      }));
    } else {
      displayToast({
        status: "error",
        message: `Failed to retrieve Tasks`,
      });
      setData((prevData) => ({ ...prevData, fetchingInstances: false }));
    }
    setPageStatus(status);
  };

  // Important Issue Card Filters and Pagination
  let filteredIssues: any[] = data.requirements ? data.requirements : [];
  let paginatedfilteredIssues: any[] = [];
  if (filteredIssues.length > 0) {
    if (isMainPage) {
      // Filter based off Negative Object Status
      filteredIssues = filteredIssues.filter(
        (issue: any) => issue.ObjectStatusID === ObjectStatus.Negative,
      );
    } else {
      // Filter based off Category
      filteredIssues = filteredIssues.filter((issue: any) =>
        categoryFilter(filters, issue),
      );
    }

    // Filter based off Permissions
    filteredIssues = filteredIssues.filter(
      (issue: any) => issue.Permission || issue.ActionIDs.length > 0,
    );

    // Order Results
    filteredIssues = filteredIssues.sort((a: any, b: any) =>
      a.RequirementName.localeCompare(b.RequirementName),
    );

    // Pagination
    paginatedfilteredIssues = filteredIssues.filter(
      (issue: any, index: number) =>
        pageFilter(filters.importantIssuesPage, filters.pageSize, index),
    );
  }

  // Issue Action Card Filters and Pagination
  let filteredIssueActions: any[] = data.instances ? data.instances : [];
  let paginatedFilteredIssueActions: any[] = [];
  if (filteredIssueActions.length > 0) {
    // Filter based off lifecycle
    filteredIssueActions = filteredIssueActions.filter((instance: any) =>
      lifeCycleFilter(filters, instance),
    );
    // Filter based off Category
    filteredIssueActions = filteredIssueActions.filter((instance: any) =>
      categoryFilter(filters, instance),
    );

    // Filter based off Permissions
    filteredIssueActions = filteredIssueActions.filter(
      (instance: any) => instance.Permission,
    );
    // Pagination
    paginatedFilteredIssueActions = filteredIssueActions.filter(
      (instance: any, index: number) =>
        pageFilter(filters.issueActionsPage, filters.pageSize, index),
    );
  }

  return (
    <LegacyScreenContainer pageTitle="Issue Dashboard" breadcrumbs={[]}>
      <>
      {data.requirements || data.instances ? (
        <>
          {/* <Header
            breadcrumbs={[
              { title: "Home", link: "/" },
              { title: "Issue Dashboard" },
            ]}
            title="Issue Dashboard"
          /> */}
          <Card
            title={
              isMainPage
                ? "Important Issues"
                : `${filters.selectedCategory === "all" ? "All" : filters.selectedCategory} Issues`
            }
            collapsable={false}
            headerCols={[
              { colProps: {}, children: <span /> },
              {
                colProps: { sm: "auto" },
                children: (
                  <NewObjectTypeButton
                    variant="primary"
                    title="Create New Issue"
                    header="New Issue"
                    objectTypeGroupID={ObjectTypeGroup.Issue}
                    type={1}
                    parentType={ObjectTypeGroup.Issue}
                    template={templateType}
                  />
                ),
              },
            ]}>
            <Table responsive borderless>
              <thead>
                <tr>
                  <th />
                  <th>Name</th>
                  <th>Issue Type</th>
                  <th>Status</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                {data.requirements && paginatedfilteredIssues.length > 0 ? (
                  paginatedfilteredIssues.map(
                    (requirement: any, index: number) => {
                      const IconType: keyof typeof Feather =
                        requirement.ObjectTypeIcon;
                      const Icon = Feather[IconType];
                      return (
                        <tr key={index}>
                          <td>
                            <Icon />
                          </td>
                          {requirement.RequirementName.length > 25 ? (
                            <td style={{ whiteSpace: "nowrap" }}>
                              <OverlayTrigger
                                placement="right-end"
                                overlay={
                                  <Tooltip id={requirement.RequirementName}>
                                    {requirement.RequirementReadableID} -{" "}
                                    {requirement.RequirementName}
                                  </Tooltip>
                                }>
                                <Link
                                  to={`/issue/${requirement.RequirementID}?display=issues`}>
                                  {requirement.RequirementReadableID} -{" "}
                                  {text_truncate(
                                    requirement.RequirementName,
                                    25,
                                  )}
                                </Link>
                              </OverlayTrigger>
                            </td>
                          ) : (
                            <td>
                              <Link
                                to={`/issue/${requirement.RequirementID}?display=issues`}>
                                {requirement.RequirementReadableID} -{" "}
                                {requirement.RequirementName}
                              </Link>
                            </td>
                          )}
                          <td>{requirement.ObjectTypeName}</td>
                          <td>
                            <Status
                              placement="left"
                              variant={objectStatusToVariant(
                                requirement.ObjectStatusID,
                              )}
                              text={requirement.ObjectStatusName}
                              tooltip={requirement.ObjectStatusDescription}
                            />
                          </td>
                          {requirement.RequirementDescription.length > 25 ? (
                            <OverlayTrigger
                              placement="left"
                              overlay={
                                <Tooltip
                                  id={requirement.RequirementDescription}>
                                  <Textarea
                                    value={requirement.RequirementDescription}
                                    name={`IssueDescription${index}`}
                                    readOnly
                                    richText
                                  />
                                </Tooltip>
                              }>
                              <td style={{ whiteSpace: "nowrap" }}>
                                <Textarea
                                  value={text_truncate(
                                    text_stripHTML(
                                      requirement.RequirementDescription,
                                    ),
                                    25,
                                  )}
                                  name={`IssueDescription${index}`}
                                  readOnly
                                  richText
                                />
                              </td>
                            </OverlayTrigger>
                          ) : (
                            <td>
                              <Textarea
                                value={requirement.RequirementDescription}
                                name={`IssueDescription${index}`}
                                readOnly
                                richText
                              />
                            </td>
                          )}
                        </tr>
                      );
                    },
                  )
                ) : data.requirements ? (
                  <tr>
                    <td />
                    <td colSpan={4}>No Issues</td>
                  </tr>
                ) : null}
              </tbody>
            </Table>
            {data.fetchingRequirements ? (
              <Row className="justify-content-sm-center">
                <Col sm="auto">
                  <Loading size={"md"} />
                </Col>
              </Row>
            ) : (
              <div />
            )}
            <Row className="justify-content-sm-center">
              <Col sm="auto">
                <Paginator
                  filters={filters}
                  setFilterDispatch={setFilters}
                  allPaginatedRecords={filteredIssues}
                  pageKey="importantIssuesPage"
                />
              </Col>
            </Row>
          </Card>
          {isMainPage && (
            <Card
              title="Issue Actions"
              collapsable={false}
              headerColsRight
              headerCols={[
                {
                  colProps: { sm: "auto", style: { paddingTop: "5px" } },
                  children: (
                    <Form.Check
                      custom
                      inline
                      label="Upcoming"
                      type="checkbox"
                      id="checkbox-upcoming"
                      checked={filters.upcoming}
                      onChange={() =>
                        setFilters({
                          ...filters,
                          upcoming: !filters.upcoming,
                          issueActionsPage: 1,
                        })
                      }
                    />
                  ),
                },
                {
                  colProps: { sm: "auto", style: { paddingTop: "5px" } },
                  children: (
                    <Form.Check
                      custom
                      inline
                      label="Due"
                      type="checkbox"
                      id="checkbox-due"
                      checked={filters.due}
                      onChange={() =>
                        setFilters({
                          ...filters,
                          due: !filters.due,
                          issueActionsPage: 1,
                        })
                      }
                    />
                  ),
                },
                {
                  colProps: { sm: "auto", style: { paddingTop: "5px" } },
                  children: (
                    <Form.Check
                      custom
                      inline
                      label="Overdue"
                      type="checkbox"
                      id="checkbox-overdue"
                      checked={filters.overdue}
                      onChange={() =>
                        setFilters({
                          ...filters,
                          overdue: !filters.overdue,
                          issueActionsPage: 1,
                        })
                      }
                    />
                  ),
                },
                {
                  colProps: { sm: "auto" },
                  children: (
                    <Dropdown
                      id="dropdown-basic-button"
                      alignRight
                      onSelect={(eventKey: any) => {
                        const newCategories = filters.categories.map(
                          (category: CategoryFilter) =>
                            category.name === eventKey
                              ? {
                                  name: category.name,
                                  id: category.id,
                                  selected: true,
                                }
                              : {
                                  name: category.name,
                                  id: category.id,
                                  selected: eventKey === "all",
                                },
                        );
                        setFilters({
                          ...filters,
                          issueActionsPage: 1,
                          categories: newCategories,
                          selectedCategory: eventKey,
                        });
                      }}>
                      <Dropdown.Toggle
                        variant="secondary"
                        id="dropdown-issuecategory">
                        Filtered by: {filters.selectedCategory}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item eventKey="all">All</Dropdown.Item>
                        {filters.categories.map(
                          (category: CategoryFilter, index: number) => (
                            <Dropdown.Item key={index} eventKey={category.name}>
                              {category.name}
                            </Dropdown.Item>
                          ),
                        )}
                      </Dropdown.Menu>
                    </Dropdown>
                  ),
                },
              ]}>
              <Row>
                <Col>
                  <Table responsive borderless>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Action Type</th>
                        <th>Action Name</th>
                        <th>Lifecycle</th>
                        <th>Person Responsible</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.instances &&
                      paginatedFilteredIssueActions.length > 0 ? (
                        paginatedFilteredIssueActions.map((instance: any) => (
                          <tr key={instance.InstanceID}>
                            <td>
                              <OverlayTrigger
                                placement="auto"
                                overlay={
                                  <Tooltip id={instance.InstanceID}>
                                    {formatDateTime({
                                      date: instance.InstanceWhenTs,
                                      format: "DateAndTimeAndTimezone",
                                    })}
                                  </Tooltip>
                                }>
                                <Link
                                  to={`/instance/${instance.InstanceID}?display=issues`}>
                                  {formatDateTime({
                                    date: instance.InstanceWhenTs,
                                    format: "Date",
                                  })}
                                </Link>
                              </OverlayTrigger>
                            </td>
                            <td>{instance.ActionTypeName}</td>
                            {instance.ActionName.length > 25 ? (
                              <OverlayTrigger
                                placement="auto"
                                overlay={
                                  <Tooltip id={instance.ActionName}>
                                    {instance.ActionName}
                                  </Tooltip>
                                }>
                                <td style={{ whiteSpace: "nowrap" }}>
                                  {text_truncate(instance.ActionName, 25)}
                                </td>
                              </OverlayTrigger>
                            ) : (
                              <td>{instance.ActionName}</td>
                            )}
                            <td>
                              <LifeCycle
                                placement="left"
                                lifecycle={instance.LifeCycle}
                              />
                            </td>
                            <td>{instance.InstanceResponsibleUserName}</td>
                          </tr>
                        ))
                      ) : data.instances ? (
                        <tr>
                          <td colSpan={5}>No Issues</td>
                        </tr>
                      ) : null}
                    </tbody>
                  </Table>
                </Col>
              </Row>
              {data.fetchingInstances ? (
                <Row className="justify-content-sm-center">
                  <Col sm="auto">
                    <Loading size={"md"} />
                  </Col>
                </Row>
              ) : (
                <div />
              )}
              <Row className="justify-content-sm-center">
                <Col sm="auto">
                  <Paginator
                    filters={filters}
                    setFilterDispatch={setFilters}
                    allPaginatedRecords={filteredIssueActions}
                    pageKey="issueActionsPage"
                  />
                </Col>
              </Row>
            </Card>
          )}
        </>
      ) : (
        <>
          <div className="progress-spinner" style={{ marginTop: "20%" }}>
            <Loading size={"xl"} />
          </div>
        </>
      )}
    </>
    </LegacyScreenContainer>
  );
};

export { ScreensIssueDashboard };
