import {
  Avatar,
  AvatarGroup,
  Badge,
  Box,
  Checkbox,
  Collapse,
  Flex,
  HStack,
  IconButton,
  LinkBox,
  LinkOverlay,
  Spacer,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  UseDisclosureReturn,
  VStack,
} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { ObjectStatus } from "../../../../shared/v2/constants/ObjectStatusID";
import { useApiQuery } from "../../../../utilities/apibelRequest";
import { formatTaskDueDateShort } from "../../../../utilities/dateUtils";
import Spinner from "../../../chakra/common/Spinner";
import DescriptionField from "../../../chakra/DescriptionField";
import { SearchProviderFactory } from "../../../SearchProvider";
import Icon from "../../../UI/Icon";
import ObjectStatusBadge from "../../../UI/ObjectStatusBadge";
import { ActionFilters } from "../BaseWidgetProps";
import DateFilter from "../filters/DateFilter";
import StatusFilter from "../filters/StatusFilter";
import UserFilter from "../filters/UserFilter";

type Action = {
  id: string;
  name: string;
  readableId: string;
  status: ObjectStatus;
  date: string;
  description: string;
  responsibleUsers: string[];
  score: number;
};

type Props = {
  setFilters: (filters: ActionFilters) => void;
  onChangeSearch: (search: string) => void;
  disclosureState: UseDisclosureReturn;
  searchQuery: string;
  filters: ActionFilters;
  users: any[];
};

const ActionSearch = SearchProviderFactory<Action>();

const ActionsTab = ({
  setFilters,
  onChangeSearch,
  disclosureState,
  ...props
}: Props) => {
  const [exactMatch, setExactMatch] = useState(true);

  const [disableTooltips, setDisabled] = useState(false);

  const eventRef = useRef<HTMLDivElement>();

  useEffect(() => {
    if (eventRef.current) {
      eventRef.current.addEventListener("scroll", () => {
        setDisabled(true);
      });
      eventRef.current.addEventListener("scrollend", () => {
        setDisabled(false);
      });
    }
    return () => {
      if (eventRef.current) {
        eventRef.current.removeEventListener("scroll", () => {
          setDisabled(true);
        });
        eventRef.current.removeEventListener("scrollend", () => {
          setDisabled(false);
        });
      }
    };
  }, []);

  const actionsQuery = useApiQuery("dashboard/search/actions", {
    query: props.searchQuery,
    filters: props.filters,
    exactMatch,
  });

  const getContent = (desc: string) => {
    var parser = new DOMParser();
    var htmlDoc = parser.parseFromString(desc, "text/html");
    return htmlDoc.body.textContent || "";
  };

  const renderAvatars = (action: Action) => {
    const avatars: [string, string][] = action.responsibleUsers.map((uid) => {
      const name = props.users.find((u) => u.id === uid)?.name || "";
      return [name, uid];
    });
    return (
      <Tooltip
        label={
          <VStack w="full" align="start" spacing="0">
            {avatars.map(([name, uid]) => (
              <Text margin="0" color="whiteAlpha.800" key={uid}>
                {name}
              </Text>
            ))}
          </VStack>
        }>
        <AvatarGroup size="sm" max={4}>
          {avatars.map(([name, uid]) => (
            <Avatar size="sm" name={name || ""} key={uid} />
          ))}
        </AvatarGroup>
      </Tooltip>
    );
  };

  return (
    <ActionSearch.SearchProvider>
      <HStack w="full">
        <ActionSearch.SearchController
          data={actionsQuery.data || []}
          placeholder="Search by Name or ID"
          onChangeSearch={onChangeSearch}
          value={props.searchQuery}
          searchConfig={{ debounceTime: 300 }}
          external
        />

        <IconButton
          onClick={disclosureState.onToggle}
          variant="ghost"
          aria-label={disclosureState.isOpen ? "Close Results" : "Open Results"}
          icon={
            <Icon name={disclosureState.isOpen ? "ChevronUp" : "ChevronDown"} />
          }
        />
      </HStack>
      <Box w="full">
        <Collapse in={disclosureState.isOpen} animateOpacity>
          <HStack>
            <StatusFilter
              statusId={props.filters.statusId as ObjectStatus | undefined}
              type="actions"
              patchFilters={(patch) => {
                setFilters({
                  ...(props.filters as any),
                  ...patch,
                });
              }}
            />
            <UserFilter
              patchFilters={(patch) => {
                try {
                  setFilters({
                    ...(props.filters as any),
                    ...patch,
                  });
                } catch (e) {
                  console.error(`Error setting user filter: ${e}`);
                }
              }}
              type={"actions"}
              user={props.filters.user}
              users={props.users}
            />
            <Spacer />
            <Checkbox
              pr={2}
              onChange={(e) => {
                setExactMatch(e.target.checked);
              }}
              isChecked={exactMatch}>
              Exact Match Search
            </Checkbox>
          </HStack>
          <TableContainer
            ref={eventRef}
            overflowY="auto"
            h={420}
            position="relative">
            <Table size="sm" variant="data-table">
              <Thead
                style={{ borderBottom: "none" }}
                position="sticky"
                top={0}
                background="white"
                zIndex={1}>
                <Tr>
                  <Th>Name</Th>
                  <Th>Description</Th>
                  <Th>Status</Th>
                  <Th>Responsible User(s)</Th>
                </Tr>
              </Thead>
              {(actionsQuery.isLoading || actionsQuery.isFetching) && (
                <Spinner position="absolute" top="50%" size="md" left="50%" />
              )}
              <Tbody
                opacity={
                  actionsQuery.isLoading || actionsQuery.isFetching ? 0.3 : 1
                }>
                <ActionSearch.SearchContext.Consumer>
                  {({ state }) => {
                    if (state.data.length) {
                      return state.data.map((action) => {
                        const date = new Date(action.date);

                        const isMatch = Number(action.score) > 1;
                        const isGoodMatch = Number(action.score) > 6;
                        return (
                          <LinkBox key={`${action.id}`} as={Tr}>
                            <Td
                              opacity={!exactMatch && !isMatch ? 0.7 : 1}
                              display="flex"
                              flexDir="column"
                              maxW={340}
                              overflowWrap="break-word"
                              overflow="clip">
                              <Text
                                as="span"
                                fontSize="sm"
                                color="blackAlpha.900">
                                <Badge
                                  mr="2"
                                  variant="subtle"
                                  colorScheme={isGoodMatch ? "green" : "gray"}>
                                  {action.readableId}
                                </Badge>
                                <Tooltip
                                  openDelay={500}
                                  closeOnScroll
                                  isDisabled={disableTooltips}
                                  label={`${action.name}${
                                    action.description
                                      ? ` - ${getContent(action.description)}`
                                      : ""
                                  }`}>
                                  <LinkOverlay
                                    as={Link}
                                    to={`/action/${action.id}`}>
                                    {action.name}
                                  </LinkOverlay>
                                </Tooltip>
                              </Text>
                              <Text as="span" color="blackAlpha.600">
                                {formatTaskDueDateShort(date)}
                              </Text>
                            </Td>

                            <Td
                              overflowX="clip"
                              maxW={340}
                              overflowWrap="break-word">
                              <DescriptionField
                                description={action.description}
                              />
                            </Td>
                            <Td>
                              <ObjectStatusBadge statusID={action.status} />
                            </Td>
                            <Td>
                              <Flex align="center" w="full" justify="end">
                                {renderAvatars(action)}
                              </Flex>
                            </Td>
                          </LinkBox>
                        );
                      });
                    }
                    return (
                      <Box position="absolute" top="50%" left="45%">
                        <Text>No records found</Text>
                      </Box>
                    );
                  }}
                </ActionSearch.SearchContext.Consumer>
              </Tbody>
            </Table>
          </TableContainer>
        </Collapse>
      </Box>
    </ActionSearch.SearchProvider>
  );
};

export default ActionsTab;
