const getListType = (customFieldID: string): string => {
    const type: string = ListIDToType[customFieldID]
    return type
    
}

const ListIDToType: any = {
    'f8093fad-696e-43e3-a342-5c90d12029f5': 'Document',
    '1f91c6ca-c2f0-49a5-9e64-9d0b35770be4': 'Number',
    '1f484656-ed84-4f64-b8ad-4af16daee7ab': 'Text',
    '78df4d8d-9944-4a04-ba3f-e01739fb0e42': 'Date'
}

const ListIDToColumnName: any = {
    'f8093fad-696e-43e3-a342-5c90d12029f5': 'DocumentID',
    '1f91c6ca-c2f0-49a5-9e64-9d0b35770be4': 'ListValueINT',
    '1f484656-ed84-4f64-b8ad-4af16daee7ab': 'ListValueVARCHAR',
    '78df4d8d-9944-4a04-ba3f-e01739fb0e42': 'ListValueDATETIME'
}


const getListTypeID = (customFieldID: string): string => (
   ListTypeToID[customFieldID]
)

const ListTypeToID: any = {
    'Document': '046ec61e-ea42-4c24-84a1-1972bad9b837',
    'Number':  '1f91c6ca-c2f0-49a5-9e64-9d0b35770be4',
    'Text': '1f484656-ed84-4f64-b8ad-4af16daee7ab',
    'Date': '78df4d8d-9944-4a04-ba3f-e01739fb0e42'
}

const ListTypeToColumnName: any = {
    'Document': 'DocumentID',
    'Number':  'ListValueINT',
    'Text': 'ListValueVARCHAR',
    'Date': 'ListValueDATETIME'
}

//used in reports and registers for filter operators
const getOperatorOptions:any =(columnName:string) => {
    if (columnName === 'VARCHAR' || columnName === 'URL' || columnName === 'LONGVARCHAR') {
        //contains, not equal to, exists
        return ([{
            ID:'contains',
            Name: 'contains'
        }, {
            ID:'does not contain',
            Name: 'does not contain'
        }, {
            ID:'not equal to',
            Name: 'not equal to'
        }, {
            ID:'exists',
            Name: 'exists'
        }])
    } else if (columnName === 'ListValueID') {
        //exists, equals, not equal to
        return ([{
            ID:'equal to',
            Name: 'equal to'
        }, {
            ID:'not equal to',
            Name: 'not equal to'
        }, {
            ID:'exists',
            Name: 'exists'
        }])
    } else if (columnName === 'DocumentID' || columnName === 'OneDriveDocumentID') {
        return ([{
            ID: 'exists',
            Name: 'exists'
        }])
    } else if (columnName === 'DATETIME') {
        // suppress contains
        return ([{
            ID:'equal to',
            Name: 'is exactly'
        }, {
            ID:'less than',
            Name: 'is before'
        }, {
            ID:'less than equal to',
            Name: 'is on or before'
        }, {
            ID:'greater than',
            Name: 'after'
        }, {
            ID:'greater than equal to',
            Name: 'is on or after'
        }, {
            ID:'not equal to',
            Name: 'is not on'
        }, {
            ID:'relative days - before',
            Name: 'relative days - is on or before'
        }, {
            ID:'relative days - after',
            Name: 'relative days - is on or after'
        },{
            ID:'exists',
            Name: 'exists'
        }])

    }else if (columnName === 'INT') {
        // suppress contains
        return ([{
            ID:'equal to',
            Name: 'equal to'
        }, {
            ID:'less than',
            Name: 'less than'
        }, {
            ID:'less than equal to',
            Name: 'less than equal to'
        }, {
            ID:'greater than',
            Name: 'greater than'
        }, {
            ID:'greater than equal to',
            Name: 'greater than equal to'
        }, {
            ID:'not equal to',
            Name: 'not equal to'
        }, {
            ID:'exists',
            Name: 'exists'
        }])

    } else {
        return []
    }
}
export {getListType, ListIDToType, getListTypeID, ListTypeToID, ListTypeToColumnName, ListIDToColumnName, getOperatorOptions }