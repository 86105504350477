import {
  ButtonGroup,
  Circle,
  HStack,
  IconButton,
  Tooltip,
} from "@chakra-ui/react";
import React from "react";
import * as NoteType from "../../../shared/constants/noteTypes";
import * as Permissions from "../../../shared/constants/permission";
import { checkAccess } from "../../../utilities/access";
import Icon from "../Icon";

interface NotesIconsProps {
  userPermissions: any;
  setNotes: (obj: any) => void;
  isNotesEnabled: boolean;
  isTimeNotesEnabled: boolean;
  numberOfNotes: number;
  numberOfTimeNotes: number;
}

export const NotesIcons = ({
  isNotesEnabled,
  isTimeNotesEnabled,
  userPermissions,
  setNotes,
  numberOfNotes,
  numberOfTimeNotes,
}: NotesIconsProps) => {
  const showNotes =
    isNotesEnabled &&
    (checkAccess(
      userPermissions,
      Permissions.CodeNotes,
      Permissions.TypeRead,
    ) ||
      checkAccess(
        userPermissions,
        Permissions.CodeNotes,
        Permissions.TypeCreate,
      ));
  const showTimeNotes =
    isTimeNotesEnabled &&
    (checkAccess(
      userPermissions,
      Permissions.CodeTimeNotes,
      Permissions.TypeRead,
    ) ||
      checkAccess(
        userPermissions,
        Permissions.CodeTimeNotes,
        Permissions.TypeCreate,
      ));

  const handleClickNote = (noteType: string) => {
    setNotes({ isVisible: true, noteType });
  };

  return (
    <HStack spacing="0">
      <ButtonGroup isAttached size="sm" variant="ghost">
        {showNotes ? (
          <Tooltip id="leave-note" label="Leave a note against this record">
            <IconButton
              onClick={() => handleClickNote(NoteType.Note)}
              aria-label="Notes"
              icon={
                <>
                  <Icon size="md" name="MessageCircle" />
                  {numberOfNotes >= 1 && (
                    <Circle
                      as="span"
                      bg="brand.100"
                      color="brand.900"
                      size="16px"
                      position="absolute"
                      top="0px"
                      right="-2px"
                      fontSize="0.75rem"
                      zIndex="99">
                      {numberOfNotes}
                    </Circle>
                  )}
                </>
              }
            />
          </Tooltip>
        ) : null}
        {showTimeNotes ? (
          <Tooltip
            id="leave-timenote"
            label="Leave a time note against this record">
            <IconButton
              onClick={() => handleClickNote(NoteType.TimeNote)}
              aria-label="Time Notes"
              icon={
                <>
                  <Icon size="md" name="Clock" />
                  {numberOfTimeNotes >= 1 && (
                    <Circle
                      as="span"
                      bg="brand.100"
                      color="brand.900"
                      size="16px"
                      position="absolute"
                      top="0px"
                      right="-2px"
                      fontSize="0.75rem"
                      zIndex="99">
                      {numberOfTimeNotes}
                    </Circle>
                  )}
                </>
              }
            />
          </Tooltip>
        ) : null}
      </ButtonGroup>
    </HStack>
  );
};
