import { ComponentStyleConfig } from "@chakra-ui/react";

const menuStyle: ComponentStyleConfig = {
  parts: ["item", "list"],
  sizes: {
    sm: {
      list: {
        py: 1,
        minWidth: "36",
      },
      item: {
        fontSize: "sm",
      },
    },
  },
};

export default {
  components: {
    Menu: menuStyle,
  },
};
