import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Spinner,
  Table,
  Form,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import { put } from "../../utilities/request";
import { Card } from "../../components/UI/Card/Card";
import { Header } from "../../components/UI/Header/Header";
import { Text } from "../../components/Forms/Field/Text";
import {
  useMessageReducer,
  Messages,
} from "../../components/UI/Messages/Messages";
import { DateField } from "../../components/Forms/Field/Date";
import { useAppState } from "../../components/App/AppProvider";
import useToast from "../../utilities/useToast";

const schema = Yup.object().shape({
  Description: Yup.string().min(1).required("Description is required"),
  From: Yup.date().required("Date From is Required"),
  To: Yup.date()
    .min(Yup.ref("From"), "Date To must be After Date From")
    .required("Date To is Required"),
});

const ScreensAccountExcludedDate = () => {
  const { auth } = useAppState();
  const navigate = useNavigate();
  const [pageStatus, setPageStatus] = useState<any>("Ready");
  const [data, setData] = useState<any>({
    Description: "",
    To: undefined,
    From: undefined,
  });
  const { displayToast } = useToast();
  return (
    <Container>
      <Header
        breadcrumbs={[
          { title: "Account & Calendar", link: "/settings/account" },
          { title: "New Excluded Date" },
        ]}
        title=""
      />
      <Card title="New Excluded Date" collapsable={false}>
        <Formik
          enableReinitialize
          validationSchema={schema}
          initialValues={data}
          onSubmit={async (values, actions) => {
            setPageStatus("Submitting");
            const reply = await put(
              "settings/accountCalendar/excludedDate",
              values,
              auth,
            );
            if (reply.status === 200) {
              console.log("Success: ", reply);
              displayToast({
                status: "success",
                title: "Excluded Date Created Successfully",
              });
              navigate("/settings/account");
            } else {
              displayToast({
                status: "error",
                title: "Failed to Create Excluded Date",
              });
            }
          }}
          render={(formikProps) => (
            <Form onSubmit={formikProps.handleSubmit}>
              <Form.Group as={Row}>
                <Form.Label column sm="2">
                  {" "}
                  Description{" "}
                </Form.Label>
                <Col sm="8">
                  <Text
                    value={formikProps.values.Description}
                    name="Description"
                    onUpdate={(val: any) => {
                      formikProps.setFieldValue(
                        "Description",
                        val.target.value,
                      );
                    }}
                    readOnly={pageStatus !== "Ready"}
                    disabled={false}
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row}>
                <Form.Label column sm="2">
                  {" "}
                  Date From{" "}
                </Form.Label>

                <Col sm="2">
                  <DateField
                    name="From"
                    selected={formikProps.values.From}
                    onUpdate={((name: any, data: any) =>
                      formikProps.setFieldValue(name, data)).bind({}, "From")}
                    readOnly={pageStatus !== "Ready"}
                    min={new Date()}
                    disabled={false}
                    allDates={false}
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row}>
                <Form.Label column sm="2">
                  {" "}
                  Date To{" "}
                </Form.Label>

                <Col sm="2">
                  <DateField
                    name="To"
                    selected={formikProps.values.To}
                    onUpdate={((name: any, data: any) =>
                      formikProps.setFieldValue(name, data)).bind({}, "To")}
                    readOnly={pageStatus !== "Ready"}
                    min={
                      formikProps.values.From
                        ? formikProps.values.From
                        : new Date()
                    }
                    disabled={false}
                    allDates={false}
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row}>
                <Col sm="2" />
                <Col sm="auto">
                  <Button
                    disabled={pageStatus !== "Ready"}
                    type="submit"
                    onClick={() => {
                      const errors = JSON.stringify(formikProps.errors)
                        .replace("{", "")
                        .replace("}", "")
                        .split(",");

                      let errorMessage: string = errors
                        .map(
                          (e: string, index: number) =>
                            (errors[index] = e
                              .substring(e.lastIndexOf(":") + 1)
                              .replace("}", "")),
                        )
                        .join("\n");
                      errorMessage = errorMessage.concat("\n");
                      if (errors.length > 0 && errors[0] !== "") {
                        displayToast({
                          status: "error",
                          title: `${errorMessage}`,
                        });
                      }
                    }}>
                    Submit
                  </Button>
                </Col>

                <Col sm="auto">
                  <Button
                    disabled={pageStatus !== "Ready"}
                    type="button"
                    variant="light"
                    onClick={() => {
                      navigate("/settings/account");
                    }}>
                    Cancel{" "}
                  </Button>
                </Col>
              </Form.Group>
            </Form>
          )}
        />
      </Card>
    </Container>
  );
};

export { ScreensAccountExcludedDate };
