import {
  Button,
  chakra,
  HStack,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
  Text,
  VStack,
} from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import ShortTextField from "../../../components/chakra/form/formComponents/ShortTextField";
import { NewRoleSchema } from "../../../shared/v2/validators/roleSchemas";
import { post } from "../../../utilities/apibelRequest";
import useToast from "../../../utilities/useToast";

type Props = {
  onClose: () => void;
};

const NewRoleModal = ({ onClose }: Props) => {
  const navigate = useNavigate();
  const { displayToast } = useToast();
  const form = useForm({
    mode: "onBlur",
    reValidateMode: "onBlur",
    resolver: zodResolver(NewRoleSchema),
    defaultValues: {
      name: "",
      description: "",
    },
  });
  const { handleSubmit, formState, getValues } = form;

  const onSubmit = async () => {
    const { name, description } = getValues();
    try {
      const result = await post("role/create", {
        name: name.trim(),
        description: description.trim(),
      });
      displayToast({
        title: "Role created successfully",
        status: "success",
      });
      onClose();
      navigate(`/settings/role/${result.roleID}`);
    } catch (e) {
      console.error(e);
      displayToast({
        status: "error",
        title: "Failed to create Role",
      });
    }
  };

  return (
    <chakra-scope>
      <ModalHeader>Create Role</ModalHeader>
      <ModalCloseButton />
      <FormProvider {...form}>
        <chakra.form onSubmit={handleSubmit(onSubmit)}>
          <ModalBody>
            <VStack align="start">
              <ShortTextField
                name="name"
                fieldProps={{
                  label: "Name",
                  maxLength: 64,
                  isRequired: true,
                }}
              />
              <ShortTextField
                name="description"
                fieldProps={{
                  label: "Description",
                  maxLength: 256,
                  isRequired: false,
                }}
              />
            </VStack>
            <Text>
              A new role will be created when you click create. You will be able
              to edit the permissions of the role after it is created.
            </Text>
          </ModalBody>
          <ModalFooter>
            <HStack>
              <Button
                disabled={formState.isSubmitting}
                onClick={onClose}
                variant="solid"
                colorScheme="gray">
                Cancel
              </Button>
              <Button
                isLoading={formState.isSubmitting}
                loadingText="Creating"
                type="submit">
                Create
              </Button>
            </HStack>
          </ModalFooter>
        </chakra.form>
      </FormProvider>
    </chakra-scope>
  );
};

export default NewRoleModal;
