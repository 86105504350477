import React, { useEffect, useState } from "react";
import { Box, Button, Group, Loader, Stack, Text, Title } from "@mantine/core";
import Lottie, { LottieRef, LottieRefCurrentProps } from "lottie-react";
import {
  RiCheckLine,
  RiArrowLeftSLine,
  RiCloseLine,
  RiInformationLine,
} from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import Spinner from "../../../../components/chakra/common/spinner";
import { BlueprintStateAction } from "../useBlueprintState";
import tickanim from "../anim/tickanim.json";
import { post, useApiMutation } from "../../../../utilities/apibelRequest";
import { BlueprintState } from "../../../../shared/v2/definitions/blueprints";
import { chunk } from "lodash";

type Props = {
  blueprintState: BlueprintState;
  dispatch: (action: BlueprintStateAction) => void;
};

type CreatingState =
  | {
      type: "idle";
    }
  | {
      type: "creating";
      step: number;
    }
  | {
      type: "actions";
    }
  | {
      type: "created";
    };

export default function BlueprintReviewCard({
  blueprintState,
  dispatch,
}: Props) {
  const navigate = useNavigate();
  const createRequirementMutation = useApiMutation("blueprint/completeDraft");
  const [createStep, setCreateStep] = useState<CreatingState>({ type: "idle" });

  const handleClickCreate = async () => {
    console.log("creating for id ", blueprintState);
    setCreateStep({
      type: "creating",
      step: 0,
    });
    const mutationPromise = createRequirementMutation.mutateAsync({
      draftID: blueprintState.blueprintStateID,
    });
    setTimeout(() => {
      setCreateStep((prev) =>
        prev.type === "creating" ? { type: "creating", step: 1 } : prev,
      );
    }, 6000);
    setTimeout(() => {
      setCreateStep((prev) =>
        prev.type === "creating" ? { type: "creating", step: 2 } : prev,
      );
    }, 12000);
    const res = await mutationPromise;
    setCreateStep({
      type: "actions",
    });
    // Prepare the actions
    // Slice into chunks of 3
    const actions = chunk(res.createdActionIDs, 3);
    for (const actionChunk of actions) {
      // eslint-disable-next-line no-await-in-loop
      await post("blueprint/prepareActions_V1", {
        actionIDs: actionChunk,
      })
    }
    setCreateStep({
      type: "created",
    });
  };

  const handleClickFinish = () => {
    if (createRequirementMutation.isSuccess && createRequirementMutation.data) {
      navigate(`/requirement/${createRequirementMutation.data.parentID}`);
    }
  };

  const handleClickBack = () => {
    dispatch({
      type: "back",
    });
  };

  const lottieRef = React.useRef<LottieRefCurrentProps>(null);
  useEffect(() => {
    if (lottieRef.current) {
      lottieRef.current.setSpeed(0.7);
      lottieRef.current.play();
    }
  }, [lottieRef]);

  return (
    <Stack
      w="100%"
      mih="0"
      px="sm"
      pt="lg"
      pb="xl"
      justify="space-between"
      flex={1}>
      <Stack w="100%" align="center">
        <Stack align="start" w="100%" maw="40rem" gap={0}>
          <Title order={2} size="h4">
            Setup Complete
          </Title>
          <Text>
            Thank you, we have all the information we need to setup your
            CertCrowd compliance management system.
          </Text>
        </Stack>
        {createRequirementMutation.status === "idle" ? (
          <Stack w="100%" align="center">
            <Button
              size="lg"
              leftSection={<RiCheckLine size="24" />}
              onClick={handleClickCreate}>
              Create my account
            </Button>
          </Stack>
        ) : null}
        {createStep.type === "creating" ? (
          <Stack w="100%" align="center">
            <Stack align="center" gap="0">
              <Text c="dimmed" fs="italic">
                {createStep.step === 0
                  ? "Configuring account..."
                  : createStep.step === 1
                  ? "Readying data..."
                  : "Preparing requirements..."}
              </Text>
              <Text c="dimmed" fs="italic">
                This should only take a minute
              </Text>
            </Stack>
            <Loader size="xl" />
          </Stack>
        ) : createStep.type === "actions" ? (
          <Stack w="100%" align="center">
            <Stack align="center" gap="0">
              <Text c="dimmed" fs="italic">
                Setting up tasks...
              </Text>
              <Text c="dimmed" fs="italic">
                Almost there
              </Text>
            </Stack>
            <Loader size="xl" />
          </Stack>
        ) : createStep.type === "created" ? (
          <>
            <Text>Your account has been created.</Text>
            <Box h="256" w="192">
              <Box
                pos="absolute"
                top="96"
                left="50%"
                style={{ transform: "translate(-50%)" }}>
                <Lottie
                  lottieRef={lottieRef}
                  loop={false}
                  autoplay={false}
                  style={{ height: "240px" }}
                  animationData={tickanim}
                />
              </Box>
              <Button onClick={handleClickFinish}>Go to CertCrowd</Button>
            </Box>
          </>
        ) : null}
      </Stack>
      <Group w="100%" justify="space-between" style={{ alignSelf: "flex-end" }}>
        {createRequirementMutation.status === "idle" && (
          <Button
            leftSection={<RiArrowLeftSLine size={16} />}
            variant="subtle"
            onClick={handleClickBack}>
            Back
          </Button>
        )}
      </Group>
    </Stack>
  );

  // return (
  //   <VStack w="full">
  //     <HStack w="full" justify="space-between" align="start">
  //       <Heading size="md">Complete</Heading>
  //     </HStack>
  //     <VStack w="full" flex="1" align="start" justify="space-between">
  //       <Text>
  //         Thank you - we have all the information we need to set up your
  //         CertCrowd account.
  //       </Text>
  //       {createRequirementMutation.status === "idle" ? (
  //         <HStack w="full" justify="center" align="center" py="12">
  //           <Button
  //             leftIcon={<Icon boxSize="5" as={RiCheckLine} />}
  //             onClick={handleClickCreate}>
  //             Create my account
  //           </Button>
  //         </HStack>
  //       ) : createRequirementMutation.status === "loading" ? (
  //         <Spinner />
  //       ) : (
  //         <>
  //           <Text>Your account has been created.</Text>

  //           <Box h="64" w="48">
  //             <Box
  //               pos="absolute"
  //               top="24"
  //               left="50%"
  //               transform="translate(-50%)">
  //               <Lottie
  //                 lottieRef={lottieRef}
  //                 loop={false}
  //                 autoplay={false}
  //                 style={{ height: "240px" }}
  //                 animationData={tickanim}
  //               />
  //             </Box>
  //             <Button onClick={handleClickFinish}>Go to CertCrowd</Button>
  //           </Box>
  //         </>
  //       )}

  //       <HStack w="full" justify="space-between" align="end">
  //         <Box>
  //           <Button
  //             size="sm"
  //             onClick={handleClickBack}
  //             variant="ghost"
  //             leftIcon={<Icon as={RiArrowLeftSLine} />}>
  //             Back
  //           </Button>
  //         </Box>
  //       </HStack>
  //     </VStack>
  //   </VStack>
  // );
}
