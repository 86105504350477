import {
  Button,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  Skeleton,
  Text,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";
import { ModalBody } from "react-bootstrap";
import { useApiQuery } from "../../../utilities/apibelRequest";
import Icon from "../../UI/Icon";

type Props = {
  onClose: () => void;
};

const RaiseAnIssueModal = ({ onClose }: Props) => {
  const issuePages = useApiQuery("issue/pages", null);
  return (
    <chakra-scope>
      <ModalContent>
        <ModalHeader>Raise An Issue</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={2} align="start" w="full">
            {issuePages.isFetching ? (
              <>
                <Skeleton width="full">
                  <Button variant="link" />
                </Skeleton>
                <Skeleton width="full">
                  <Button variant="link" />
                </Skeleton>
                <Skeleton width="full">
                  <Button variant="link" />
                </Skeleton>
              </>
            ) : issuePages.isSuccess ? (
              <>
                {issuePages.data.map(({ name, objectTypeID, icon }) => (
                  <Button
                    as={Link}
                    leftIcon={<Icon name={icon} />}
                    variant="link"
                    to={`/issue/new?type=${objectTypeID}`}
                    onClick={() => {
                      onClose();
                    }}>
                    New {name}
                  </Button>
                ))}
              </>
            ) : (
              <Text>Something went wrong</Text>
            )}
          </VStack>
        </ModalBody>
      </ModalContent>
    </chakra-scope>
  );
};

export default RaiseAnIssueModal;
