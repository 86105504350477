import { Badge } from "@chakra-ui/react";
import React from "react";
import {
  UserAccountStatus,
  UserAccountStatusIDToName,
} from "../../shared/v2/constants/UserAccountStatusID";

type Props = {
  statusID: UserAccountStatus | (string & {});
};

const IDToColor = {
  "490c6550-663e-49a4-a228-6ca4ffcdada3": "gray", // inactive
  "702e6ac9-c1a0-452b-a0d6-7ae6a4d15d62": "orange", // pending
  "9cf37321-75e8-43b8-8126-eefe72072ac4": "teal", // active
} as const;

const UserAccountStatusBadge = ({ statusID }: Props) => (
  <Badge
    variant="solid"
    colorScheme={IDToColor[statusID as UserAccountStatus] || "gray"}>
    {UserAccountStatusIDToName[statusID as UserAccountStatus] || "Unknown"}
  </Badge>
);

export default UserAccountStatusBadge;
