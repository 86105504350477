import clamp from "lodash/clamp";
import React, { useState } from "react";
import {
  VStack,
  Slider,
  FormControl,
  FormLabel,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Input,
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  StatGroup,
  Container,
} from "@chakra-ui/react";

const BasePrice = 150;
const BaseUsers = 10;
const MaxUsers = 1000;
const tiers = [
  {
    upTo: 100,
    costPer: 5,
  },
  {
    upTo: 1000,
    costPer: 4,
  },
];

const roundUsers = (users: number): number =>
  clamp(Math.ceil(users / 5) * 5, BaseUsers, MaxUsers);

type Result = {
  totalCost: number;
  averageCost: number;
  totalLicenses: number;
};

const calculatePrice = (users: number): Result => {
  // Nearest 5
  const totalLicenses = roundUsers(users);
  const [totalCost] = tiers.reduce(
    ([total, pricedUsers], { upTo, costPer }) => {
      const usersInTier = clamp(totalLicenses, 0, upTo) - pricedUsers;
      const priceForTier = costPer * usersInTier;
      return [total + priceForTier, pricedUsers + usersInTier];
    },
    [BasePrice, 0],
  );

  return {
    totalCost,
    totalLicenses,
    averageCost: totalCost / totalLicenses,
  };
};

const LicenseFeeCalculator = () => {
  const [inputUsers, setInputUsers] = useState(10);
  const [users, setUsers] = useState(10);

  const { totalCost, averageCost, totalLicenses } = calculatePrice(users);

  const handleSliderChange = (num: number) => {
    const value = roundUsers(num);
    setInputUsers(value);
    setUsers(value);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputUsers(+e.target.value);
  };

  const handleInputSubmit = (num: number) => {
    const value = roundUsers(num);
    setInputUsers(value);
    setUsers(value);
  };

  return (
    <chakra-scope>
      <Container>
        <VStack w="full" pt="16" pb="32">
          <FormControl w="full">
            <FormLabel>Users</FormLabel>
            <Input
              value={inputUsers}
              onChange={handleInputChange}
              onBlur={(e) => handleInputSubmit(+e.target.value)}
              onKeyDown={(e) =>
                e.key === "Enter" && handleInputSubmit(+e.target.value)
              }
            />
            <Slider
              defaultValue={10}
              min={BaseUsers}
              max={MaxUsers}
              step={5}
              value={users}
              onChange={handleSliderChange}>
              <SliderTrack>
                <SliderFilledTrack />
              </SliderTrack>
              <SliderThumb />
            </Slider>
          </FormControl>
          <Stat>
            <StatLabel textAlign="center">Cost for up to {totalLicenses} users</StatLabel>
            <StatNumber textColor="brand.700" textAlign="center">${averageCost.toFixed(2)}</StatNumber>
            <StatHelpText textAlign="center">per user (average)</StatHelpText>
            <StatHelpText fontWeight="bold" textColor="brand.900" textAlign="center">Total ${totalCost} a month</StatHelpText>
            <StatHelpText fontWeight="bold" textColor="brand.900" textAlign="center">Or ${totalCost * 11} a year</StatHelpText>
          </Stat>
        </VStack>
      </Container>
    </chakra-scope>
  );
};

export default LicenseFeeCalculator;
