import moment from "moment";
import { useEffect, useState } from "react";
import { Filters } from "../BaseWidgetProps";
import { DateRangePicker } from "react-dates";
import { Moment } from "moment";

type Props = {
  label: string;
  from?: string | null;
  to?: string | null;
  patchFilters: (filter: Partial<Filters>) => void;
};

const useDate = (initialValue?: string | null) => {
  const [dateValue, setDate] = useState<Moment | null>(
    moment.utc(initialValue),
  );

  useEffect(() => {
    if (initialValue) {
      setDate(moment.utc(initialValue));
    } else {
      setDate(null);
    }
  }, [initialValue, setDate]);

  return [dateValue, setDate];
};

const DateFilter = ({ from, to, patchFilters }: Props): JSX.Element => {
  const [fromValue, setFromDate] = useDate(from);
  const [toValue, setToDate] = useDate(to);

  const [focusedInput, setFocusedInput] = useState<
    "startDate" | "endDate" | null
  >(null);

  return (
    <DateRangePicker
      startDate={fromValue as any}
      startDateId="start_date"
      small
      displayFormat={() => "Do MMM YY"}
      hideKeyboardShortcutsPanel
      withPortal
      startDatePlaceholderText="From"
      endDatePlaceholderText="To"
      showClearDates
      endDate={toValue as any}
      endDateId="end_date"
      isOutsideRange={() => false}
      onDatesChange={({ startDate, endDate }) => {
        // @ts-ignore
        if (startDate) setFromDate(startDate);
        // @ts-ignore
        if (endDate) setToDate(endDate);
        patchFilters({
          dueAfter: startDate?.toISOString(),
          dueBefore: endDate?.toISOString(),
        });
      }}
      focusedInput={focusedInput as "startDate" | "endDate" | null}
      onFocusChange={(value) => {
        setFocusedInput(value);
      }}
    />
  );
};

export default DateFilter;
