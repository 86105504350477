import {
  Box,
  Button,
  ButtonGroup,
  Heading,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  Tooltip,
  VStack,
} from "@chakra-ui/react";
import { EditorContent } from "@tiptap/react";
import { forwardRef } from "react";
import { RiEyeLine, RiPencilLine } from "react-icons/ri";
import { DeleteIcon, MoreMenuIcon } from "../../../../constants/commonIcons";
import { useEditorWithExtensions } from "./useEditorWithExtensions";
import { DateString } from "../../../../shared/v2/dateString";
import { formatDateOrIsoString } from "../../../../utilities/dateUtils";
import { useAppState } from "../../../../components/App/AppProvider";

type Props = {
  title: string;
  contentJSON: string;
  sectionHTMLID: string;
  responsibleUser: {
    firstName?: string;
    lastName?: string;
    userID: string;
  };
  manualResponsibleUser: {
    firstName?: string;
    lastName?: string;
    userID: string;
  };
  modified: {
    date: DateString;
    firstName?: string;
    lastName?: string;
    userID: string;
  };
  onClickEdit: () => void;
  onClickDelete: () => void;
  onClickUsage: () => void;
};

const ManualSection = forwardRef<HTMLDivElement, Props>(
  (
    {
      onClickDelete,
      responsibleUser,
      manualResponsibleUser,
      modified,
      title,
      contentJSON,
      sectionHTMLID,
      onClickEdit,
      onClickUsage,
    },
    headingRef,
  ) => {
    const { permissions } = useAppState().app;

    const isOwn =
      responsibleUser.userID === permissions.INFO.userID ||
      manualResponsibleUser.userID === permissions.INFO.userID;

    // Deleting a section just uses the same permissions as editing, MANUAL.DELETE is used for deleting the manual itself
    const canEdit =
      permissions.MANUAL.UPDATE.ALL ||
      (permissions.MANUAL.UPDATE.SELF && isOwn);


    const readonlyEditor = useEditorWithExtensions({
      content: contentJSON.length >= 1 ? JSON.parse(contentJSON) : undefined,
      editable: false,
      placeholder: "No content",
      limit: 20000,
    });

    return (
      <Box
        minH="80px"
        maxW="container.lg"
        w="100%"
        px="1"
        pt="2"
        pb="2"
        _focusWithin={{
          ".hover-buttons": {
            visibility: "visible",
            opacity: 1,
          },
        }}
        _hover={{
          ".hover-buttons": {
            visibility: "visible",
            opacity: 1,
          },
        }}>
        <Box
          px="3"
          py="4"
          bg="white"
          zIndex={1}
          ref={headingRef}
          willChange="transform"
          scrollMargin="calc(var(--chakra-space-top-bar) + 2rem)"
          id={sectionHTMLID}>
          <HStack bg="white" w="100%" justify="space-between">
            <Box flex={1}>
              <VStack align="start" gap="1">
                <Heading as="h3" fontSize="2.5rem" fontWeight="semibold">
                  {title}
                </Heading>
                <VStack align="start" gap="0">
                  <Text fontSize="xs" color="gray.600">
                    Owned by {responsibleUser.firstName}{" "}
                    {responsibleUser.lastName}
                  </Text>
                  <Text fontSize="xs" color="gray.600">
                    Last updated on{" "}
                    {formatDateOrIsoString(modified.date, "datetime-short")} by{" "}
                    {modified.firstName} {modified.lastName}
                  </Text>
                </VStack>
              </VStack>
            </Box>
            <ButtonGroup
              size="sm"
              spacing={0}
              variant="ghost"
              visibility="hidden"
              opacity={0}
              className="hover-buttons">
              <Tooltip
                label={
                  !canEdit
                    ? "You don't have permission to edit this"
                    : undefined
                }>
                <Button
                  isDisabled={!canEdit}
                  leftIcon={<RiPencilLine size="16" />}
                  onClick={onClickEdit}>
                  Edit
                </Button>
              </Tooltip>
              <Menu size="sm">
                <MenuButton as={IconButton} icon={<MoreMenuIcon />} />
                <MenuList>
                  <MenuItem onClick={onClickUsage} icon={<RiEyeLine />}>
                    Usage
                  </MenuItem>
                  <MenuItem
                    isDisabled={!canEdit}
                    onClick={onClickDelete}
                    color="red.700"
                    icon={<DeleteIcon />}>
                    Delete
                  </MenuItem>
                </MenuList>
              </Menu>
            </ButtonGroup>
          </HStack>
        </Box>

        <Box px="3" py="2" className="manual-editor">
          <EditorContent editor={readonlyEditor} />
        </Box>
      </Box>
    );
  },
);

export default ManualSection;
