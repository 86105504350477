import { Route, Routes } from "react-router-dom";
import { MantineProvider } from "@mantine/core";
import BlueprintSetupScreen from "./BlueprintSetupScreen";
import Redirect from "../../../components/common/Redirect";

const BlueprintRoutes = () => {
  return (
    <MantineProvider>
      <Routes>
        <Route path="/setup/:draftID" element={<BlueprintSetupScreen />} />
        <Route path="*" element={<Redirect to="/" />} />
      </Routes>
    </MantineProvider>
  );
};

export default BlueprintRoutes;
