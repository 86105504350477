import { Button, Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";
import { createPortal } from "react-dom";
import { SearchPopper } from "../../../SearchPopper";
import Icon from "../../../UI/Icon";
import { Filters, UserInfo } from "../BaseWidgetProps";

type Props = {
  users: UserInfo[] | undefined;
  user: UserInfo | undefined;
  type?: "tasks" | "actions" | "records";
  patchFilters: (filters: Partial<Filters>) => void;
};

const SearchableMenuList = ({
  users,
  patchFilters,
  type,
}: Omit<Props, "user">) => {
  const { body } = document;
  return createPortal(
    <chakra-scope>
      <MenuList maxH={380} p={0} overflowY={"auto"} zIndex={12}>
        <SearchPopper data={users || []} placeholder="Search Users">
          {({ data, onClose }) => {
            const userItems = data?.map((user) => (
              <MenuItem
                id={user.id}
                key={user.id}
                onClick={() => {
                  onClose();
                  patchFilters({
                    type,
                    user,
                  });
                }}>
                {user.name}
              </MenuItem>
            ));

            return [
              <MenuItem
                id="user-any"
                key="user-any"
                onClick={() => {
                  onClose();
                  patchFilters({
                    type,
                    user: undefined,
                  });
                }}>
                Any
              </MenuItem>,
              ...userItems,
            ];
          }}
        </SearchPopper>
      </MenuList>
    </chakra-scope>,
    body,
  );
};

const UserFilter = ({ user, users, patchFilters, ...props }: Props) => {
  return (
    <Menu preventOverflow>
      <MenuButton size="sm" rightIcon={<Icon name="ChevronDown" />} as={Button}>
        User: {user ? user.name : "Any"}
      </MenuButton>
      <SearchableMenuList
        type={props.type}
        users={users}
        patchFilters={patchFilters}
      />
    </Menu>
  );
};

export default UserFilter;
