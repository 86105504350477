import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Collapse,
  Dropdown,
  OverlayTrigger,
  Row,
  Spinner,
  Table,
  Tooltip,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { isJobsAccount } from "../../../constants/JobsVisibleCheck";
import { formatDateTime } from "../../../utilities/formatDate";
import { getPaginated, RequestStatus } from "../../../utilities/request";
import { text_stripHTML, text_truncate } from "../../../utilities/strings";
import { useAppState } from "../../App/AppProvider";
import { Text } from "../../Forms/Field/Text";
import { Textarea } from "../../Forms/Field/Textarea";
import { CollapseChevron } from "../CollapseChevron/CollapseChevron";
import useToast from "../../../utilities/useToast";
import { Paginator } from "../Paginator/Paginator";
import {
  Action,
  SearchAll,
  SearchFiltersState,
  SearchRecord,
  SearchStatus,
} from "./Search.d";

const configureInitialState = () => ({
  referenceID: "",
  searchText: "",
  searchTypeFilter: "Any",
  searchStatusFilter: "Any",
  searchLifecycleFilter: "Any",
  searchParentFilter: "Any",
  searchUserFilter: "Any",
  searchUserName: "",
  searchRecords: [],
});

const Reducer: React.Reducer<SearchAll, Action> = (state, action) => {
  switch (action.type) {
    case "setData":
      return action.newData;
    case "setSearchRecords":
      return { ...state, searchRecords: action.newRecords };
    case "setSearchText":
      if (action.newText !== "") {
        action.updateDetailsCollapsed(false);
      }
      return { ...state, searchText: action.newText };
    case "setTypeFilter":
      let parentFilter = state.searchParentFilter;
      if (action.filter !== "Issue") {
        parentFilter = "Any";
      }
      let lifecycleFilter = state.searchLifecycleFilter;
      if (action.filter !== "Task") {
        lifecycleFilter = "Any";
      }
      return {
        ...state,
        searchTypeFilter: action.filter,
        searchParentFilter: parentFilter,
        searchLifecycleFilter: lifecycleFilter,
      };
    case "setStatusFilter":
      return { ...state, searchStatusFilter: action.filter };
    case "setUserFilter":
      return {
        ...state,
        searchUserFilter: action.filter,
        searchUserName: action.userName,
      };
    case "setLifecycleFilter":
      return {
        ...state,
        searchTypeFilter: "Task",
        searchLifecycleFilter: action.filter,
      };
    case "setParentFilter":
      return {
        ...state,
        searchTypeFilter: "Issue",
        searchParentFilter: action.filter,
      };
    case "setAllFilters":
      // Work out the name based off an ID if no name is provided
      const getNameFromID = (): string => {
        for (let i = 0; i < state.searchRecords.length; ++i) {
          const userFound = state.searchRecords[i].User.find(
            (u: { UserName: string; UserID: string }) =>
              u.UserID === action.userFilter,
          );
          if (userFound !== undefined) {
            return userFound.UserName;
          }
        }
        return "";
      };

      return {
        ...state,
        searchTypeFilter: action.typeFilter,
        searchStatusFilter: action.statusFilter,
        searchLifecycleFilter: action.lifecycleFilter,
        searchUserFilter: action.userFilter,
        searchUserName:
          action.userName !== undefined ? action.userName : getNameFromID(),
        searchParentFilter: action.parentFilter,
        searchText: action.textFilter,
      };
    case "clear":
      return {
        ...state,
        referenceID: "",
        searchText: "",
        searchTypeFilter: "Any",
        searchStatusFilter: "Any",
        searchLifecycleFilter: "Any",
        searchParentFilter: "Any",
        searchUserFilter: "Any",
        searchUserName: "",
      };
    default:
      return state;
  }
};

/* Render Methods */
const renderSearch = (searchRecords: SearchRecord[]) => {
  if (searchRecords.length > 0) {
    return searchRecords.map((e: any, i: number) => {
      const display =
        e.ReferenceTable === "Issue" || e.ReferenceTable === "Risk"
          ? `?display=${e.ReferenceTable.toLowerCase()}s`
          : "";
      return (
        <tr key={i}>
          <td style={{ whiteSpace: "nowrap" }}>
            <Link
              to={`/${e.ReferenceTable.toLowerCase()}/${
                e.ReferenceID
              }${display}`}>
              {e.ID}
            </Link>
          </td>
          {e.Name.length > 25 ? (
            <OverlayTrigger
              placement="auto"
              overlay={<Tooltip id={e.Name}>{e.Name}</Tooltip>}>
              <td style={{ whiteSpace: "nowrap" }}>
                {text_truncate(e.Name, 25)}
              </td>
            </OverlayTrigger>
          ) : (
            <td>{e.Name}</td>
          )}
          <td>{e.Type}</td>
          <td>{e.Status}</td>
          {e.Description.length > 25 ? (
            <OverlayTrigger
              placement="left"
              overlay={
                <Tooltip id={e.Description}>
                  <Textarea
                    value={e.Description}
                    name={`description${i}`}
                    readOnly
                    richText
                  />
                </Tooltip>
              }>
              <td style={{ whiteSpace: "nowrap" }}>
                <Textarea
                  value={text_truncate(text_stripHTML(e.Description), 25)}
                  name={`description${i}`}
                  readOnly
                  richText
                />
              </td>
            </OverlayTrigger>
          ) : (
            <td>
              <Textarea
                value={e.Description}
                name={`description${i}`}
                readOnly
                richText
              />
            </td>
          )}
          <td>
            {e.User.map(
              (u: { UserName: string; UserID: string }) => u.UserName,
            ).join(", ")}
          </td>
        </tr>
      );
    });
  }
  return (
    <tr>
      <td>No Records (yet...)</td>
    </tr>
  );
};

const renderUserFilterOptions = (
  searchRecords: any[],
  dispatch: any,
  pagerender: any,
  filters: any,
) => {
  const uniqueUserNames = searchRecords.reduce(
    (accumulator: { UserName: string; UserID: string }[], currentValue) => {
      const usersNotAlreadyInAccumulator = currentValue.User
        // filter out the ones that we already have
        .filter(
          (u: any) =>
            !accumulator.find(
              (accumulatorValue) => accumulatorValue.UserID === u.UserID,
            ),
        )
        // alter the name in case of multiple people with the same name
        .map((u: any) => {
          const numberOfSameNames = accumulator.filter(
            (accumulatorValue) => accumulatorValue.UserName === u.UserName,
          ).length;
          return {
            UserID: u.UserID,
            UserName: `${u.UserName}${
              numberOfSameNames > 0 ? ` (${numberOfSameNames + 1})` : ""
            }`,
          };
        });
      return [...accumulator, ...usersNotAlreadyInAccumulator];
    },
    [],
  );

  console.log("users", uniqueUserNames);
  // Sorts the by alpha order first
  return (
    uniqueUserNames
      .sort((userA: any, userB: any) => {
        if (userA.UserName < userB.UserName) {
          return -1;
        }
        if (userA.UserName > userB.UserName) {
          return 1;
        }
        return 0;
      })
      // Sorts the deleted users to the bottom of the list
      .sort((userA: any, userB: any) => {
        if (userA.UserName.includes("Deleted")) {
          return 1;
        }
        if (userB.UserName.includes("Deleted")) {
          return -1;
        }
        return 0;
      })
      .map((e: any, i: number) => (
        <Dropdown.Item
          key={`userFilter${i}`}
          onClick={() => {
            dispatch({
              type: "setUserFilter",
              filter: e.UserID,
              userName: e.UserName,
            });
            pagerender({ ...filters, page: 1 });
          }}>
          {e.UserName}
        </Dropdown.Item>
      ))
  );
};

/* General Functions */
// const getParamFromRoute = (match: match<HistoryMatchParams> | null, param: HistoryParams): string => (
//     (match !== null ? match.params[param] : '')
// )

/* Filters */
const stringFilter = (list: SearchRecord[], text: string) =>
  list.filter(
    (search: SearchRecord) =>
      search.ID.toLowerCase().indexOf(text.toLowerCase()) !== -1 ||
      search.Type.toLowerCase().indexOf(text.toLowerCase()) !== -1 ||
      search.Name.toLowerCase().indexOf(text.toLowerCase()) !== -1 ||
      search.Description.toLowerCase().indexOf(text.toLowerCase()) !== -1,
  );

const typeFilter = (list: SearchRecord[], text: string) => {
  if (text !== "Any") {
    return list.filter(
      (search: SearchRecord) =>
        search.ReferenceTable === (text === "Task" ? "Instance" : text),
      // text = Task
    );
  }
  return list;
};

const statusFilter = (list: SearchRecord[], text: string) => {
  if (text !== "Any") {
    if (text === "Open") {
      return list.filter((search: SearchRecord) => search.Status !== "Closed");
    }

    return list.filter((search: SearchRecord) => search.Status === text);
  }
  return list;
};

const lifecycleFilter = (list: SearchRecord[], text: string) => {
  if (text !== "Any") {
    return list.filter((search: SearchRecord) => search.Lifecycle === text);
  }
  return list;
};

const parentFilter = (list: SearchRecord[], text: string) => {
  if (text !== "Any") {
    return list.filter((search: SearchRecord) => search.Parent === text);
  }
  return list;
};

const userFilter = (list: SearchRecord[], text: string) => {
  if (text !== "Any") {
    return list.filter((search: SearchRecord) =>
      search.User.find(
        (u: { UserName: string; UserID: string }) => u.UserID === text,
      ),
    );
  }
  return list;
};

const pageFilter = (filters: SearchFiltersState, index: number) =>
  index < filters.page * filters.pageSize &&
  index >= (filters.page - 1) * filters.pageSize;

const SearchHook = () =>
  React.useReducer<React.Reducer<SearchAll, Action>>(
    Reducer,
    configureInitialState(),
  );

// because we grab data in chunks, multiple rows belonging to one record (e.g. with multiple users) might be split across data chunks
// and need to be associated together
const reduceData = (currentData: SearchRecord[], newData: SearchRecord[]) =>
  newData.reduce((acc: SearchRecord[], curr: SearchRecord) => {
    const recordIndex = acc.findIndex(
      (item: SearchRecord) => item.ReferenceID === curr.ReferenceID,
    );
    const instanceIndex = acc.findIndex(
      (item: SearchRecord) =>
        item.ActionID &&
        curr.ActionID &&
        item.ActionID === curr.ActionID &&
        item.Date &&
        curr.Date &&
        item.Date === curr.Date,
    );
    if (recordIndex >= 0) {
      acc[recordIndex].User.push({
        UserID: curr.UserID,
        UserName: curr.UserName,
      });
      return acc;
    }

    if (instanceIndex >= 0) {
      acc[instanceIndex].User.push({
        UserID: curr.UserID,
        UserName: curr.UserName,
      });
      return acc;
    }

    return [
      ...acc,
      {
        ...curr,
        ID:
          curr.ReferenceTable === "Task"
            ? `${curr.ID}(${formatDateTime({
                date: curr.Date,
                format: "Date",
              })})`
            : curr.ID,
      },
    ];
  }, currentData);

const Search = (props: any) => {
  const appState = useAppState();
  const [pageStatus, setPageStatus] = useState<SearchStatus>("Loading");
  const { displayToast } = useToast();
  const [filters, setFilters] = React.useState<SearchFiltersState>({
    page: 1,
    pageSize: 10,
  });

  useEffect(() => {
    const fetchSearch = async () => {
      await getPaginated(`search`, 5000, updateResults, appState.auth);
    };

    if (appState.auth.apiToken) {
      fetchSearch();
    }
  }, [appState]); // eslint-disable-line

  let interimResults: SearchRecord[] = [];
  const updateResults = (responseData: any, status: RequestStatus) => {
    if (status !== "Error") {
      interimResults = reduceData(interimResults, responseData.record);
      props.dispatch({
        type: "setSearchRecords",
        newRecords: interimResults,
      });
    } else {
      displayToast({
        severity: "error",
        message: `Failed to retrieve Search records`,
      });
    }
    setPageStatus(status);
  };
  /* Apply filters */
  useEffect(() => {
    setFilters({ ...filters, page: 1 });
  }, [
    props.data.searchText,
    props.data.searchTypeFilter,
    props.data.searchStatusFilter,
    props.data.searchLifecycleFilter,
    props.data.searchParentFilter,
    props.data.searchParentFilter,
    props.data.searchUserFilter,
    props.data.searchUserName,
  ]);

  let filteredRecords: SearchRecord[] = props.data
    ? props.data.searchRecords
    : [];
  let paginatedFilteredRecords: SearchRecord[] = [];
  if (filteredRecords.length > 0) {
    // Filter by string search
    filteredRecords = stringFilter(filteredRecords, props.data.searchText);

    // Filter by type search
    filteredRecords = typeFilter(filteredRecords, props.data.searchTypeFilter);

    // Filter by status
    filteredRecords = statusFilter(
      filteredRecords,
      props.data.searchStatusFilter,
    );

    // Filter by lifecyle (Instance Only)
    filteredRecords = lifecycleFilter(
      filteredRecords,
      props.data.searchLifecycleFilter,
    );

    // Filter by Parent (Issue Only)
    filteredRecords = parentFilter(
      filteredRecords,
      props.data.searchParentFilter,
    );

    // Filter by UserName
    filteredRecords = userFilter(filteredRecords, props.data.searchUserFilter);

    // Pagination
    paginatedFilteredRecords = filteredRecords.filter((search, index: number) =>
      pageFilter(filters, index),
    );
  }

  return (
    <Card>
      <Row style={{ marginBottom: "10px", color: "#374750" }}>
        <Col sm="auto">
          <h1>Search</h1>
        </Col>
        <Col sm={9}>
          <Text
            disabled={!props.data || pageStatus === "Loading"}
            placeHolder={
              !props.data || pageStatus === "Loading"
                ? "Loading..."
                : "Search by Name, Description, ID or Type"
            }
            value={props.data.searchText}
            name="searchText"
            onUpdate={(event: any) =>
              props.dispatch({
                type: "setSearchText",
                newText: event.target.value,
                updateDetailsCollapsed: props.updateDetailsCollapsed,
              })
            }
            readOnly={false}
          />
        </Col>
        <Col sm={1}>
          <CollapseChevron
            collapsed={props.isCollapsed}
            updateCollapsed={props.updateDetailsCollapsed}
          />
        </Col>
      </Row>
      <hr />

      <Collapse in={!props.isCollapsed}>
        <div>
          <Row style={{ marginTop: "10px", marginBottom: "10px" }}>
            <Col sm={2}>Filters:</Col>
            <Col>
              <Row>
                <Col style={{ marginTop: "10px", textAlign: "center" }}>
                  <Dropdown>
                    <Dropdown.Toggle
                      style={{
                        backgroundColor:
                          props.data.searchTypeFilter !== "Any"
                            ? "var(--chakra-colors-brand-500)"
                            : "",
                      }}
                      variant="secondary"
                      id="dropdown-statusFilter">
                      {props.data.searchTypeFilter} Record Type
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={() => {
                          props.dispatch({
                            type: "setTypeFilter",
                            filter: "Any",
                          });
                        }}>
                        Any
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          props.dispatch({
                            type: "setTypeFilter",
                            filter: "Requirement",
                          });
                        }}>
                        Requirement
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          props.dispatch({
                            type: "setTypeFilter",
                            filter: "Issue",
                          });
                        }}>
                        Issue
                      </Dropdown.Item>
                      {isJobsAccount(appState) ? (
                        <Dropdown.Item
                          onClick={() => {
                            props.dispatch({
                              type: "setTypeFilter",
                              filter: "Job",
                            });
                          }}>
                          Job
                        </Dropdown.Item>
                      ) : null}
                      <Dropdown.Item
                        onClick={() => {
                          props.dispatch({
                            type: "setTypeFilter",
                            filter: "Action",
                          });
                        }}>
                        Action
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          props.dispatch({
                            type: "setTypeFilter",
                            filter: "Risk",
                          });
                        }}>
                        Risk
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          props.dispatch({
                            type: "setTypeFilter",
                            filter: "Task",
                          });
                        }}>
                        Task
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          props.dispatch({
                            type: "setTypeFilter",
                            filter: "Register",
                          });
                        }}>
                        Register
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Col>
                {props.data.searchTypeFilter === "Issue" ? (
                  <Col style={{ marginTop: "10px", textAlign: "center" }}>
                    <Dropdown>
                      <Dropdown.Toggle
                        style={{
                          backgroundColor:
                            props.data.searchParentFilter !== "Any"
                              ? "var(--chakra-colors-brand-500)"
                              : "",
                        }}
                        variant="secondary"
                        id="dropdown-statusFilter">
                        {props.data.searchParentFilter} Type
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          onClick={() => {
                            props.dispatch({
                              type: "setParentFilter",
                              filter: "Any",
                            });
                          }}>
                          Any
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => {
                            props.dispatch({
                              type: "setParentFilter",
                              filter: "Sub-Issue",
                            });
                          }}>
                          Sub-Issue
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => {
                            props.dispatch({
                              type: "setParentFilter",
                              filter: "Issue",
                            });
                          }}>
                          Issue
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </Col>
                ) : null}
                {props.data.searchTypeFilter === "Task" ? (
                  <Col style={{ marginTop: "10px", textAlign: "center" }}>
                    <Dropdown>
                      <Dropdown.Toggle
                        style={{
                          backgroundColor:
                            props.data.searchLifecycleFilter !== "Any"
                              ? "var(--chakra-colors-brand-500)"
                              : "",
                        }}
                        variant="secondary"
                        id="dropdown-statusFilter">
                        {props.data.searchLifecycleFilter} Lifecycle
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          onClick={() => {
                            props.dispatch({
                              type: "setLifecycleFilter",
                              filter: "Any",
                            });
                          }}>
                          Any
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => {
                            props.dispatch({
                              type: "setLifecycleFilter",
                              filter: "Closed",
                            });
                          }}>
                          Closed
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => {
                            props.dispatch({
                              type: "setLifecycleFilter",
                              filter: "Overdue",
                            });
                          }}>
                          Overdue
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => {
                            props.dispatch({
                              type: "setLifecycleFilter",
                              filter: "Due",
                            });
                          }}>
                          Due
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => {
                            props.dispatch({
                              type: "setLifecycleFilter",
                              filter: "Open Responded",
                            });
                          }}>
                          Open Responded
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => {
                            props.dispatch({
                              type: "setLifecycleFilter",
                              filter: "Upcoming",
                            });
                          }}>
                          Upcoming
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </Col>
                ) : null}
                <Col style={{ marginTop: "10px", textAlign: "center" }}>
                  <Dropdown>
                    <Dropdown.Toggle
                      style={{
                        backgroundColor:
                          props.data.searchStatusFilter !== "Any"
                            ? "var(--chakra-colors-brand-500)"
                            : "",
                      }}
                      variant="secondary"
                      id="dropdown-statusFilter">
                      {props.data.searchStatusFilter} Status
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={() => {
                          props.dispatch({
                            type: "setStatusFilter",
                            filter: "Any",
                          });
                        }}>
                        Any
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          props.dispatch({
                            type: "setStatusFilter",
                            filter: "Positive",
                          });
                        }}>
                        Positive
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          props.dispatch({
                            type: "setStatusFilter",
                            filter: "Negative",
                          });
                        }}>
                        Negative
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          props.dispatch({
                            type: "setStatusFilter",
                            filter: "Neutral",
                          });
                        }}>
                        Neutral
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          props.dispatch({
                            type: "setStatusFilter",
                            filter: "Open",
                          });
                        }}>
                        Open
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          props.dispatch({
                            type: "setStatusFilter",
                            filter: "Closed",
                          });
                        }}>
                        Closed
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Col>
                <Col style={{ marginTop: "10px", textAlign: "center" }}>
                  <Dropdown>
                    <Dropdown.Toggle
                      style={{
                        backgroundColor:
                          props.data.searchUserFilter !== "Any"
                            ? "var(--chakra-colors-brand-500)"
                            : "",
                      }}
                      variant="secondary"
                      id="dropdown-statusFilter">
                      {props.data.searchUserFilter === "Any"
                        ? "Any"
                        : `${props.data.searchUserName} User`}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={() => {
                          props.dispatch({
                            type: "setUserFilter",
                            filter: "Any",
                          });
                        }}>
                        Any
                      </Dropdown.Item>
                      {renderUserFilterOptions(
                        props.data.searchRecords,
                        props.dispatch,
                        setFilters,
                        filters,
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                </Col>
                <Col style={{ marginTop: "10px", textAlign: "center" }}>
                  <Button
                    type="button"
                    variant="outline-dark"
                    onClick={() => {
                      props.dispatch({ type: "clear" });
                    }}>
                    Clear
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col>
              <span>{filteredRecords.length} results</span>
            </Col>
          </Row>

          <hr />

          <Table responsive borderless>
            <thead>
              <tr>
                <th>ID</th>
                <th>Name</th>
                <th>Type</th>
                <th>Status</th>
                <th>Description</th>
                <th>Person Responsible</th>
              </tr>
            </thead>
            <tbody>{renderSearch(paginatedFilteredRecords)}</tbody>
          </Table>
          {pageStatus === "Fetching" ? (
            <Row>
              <Col sm="auto">
                <Spinner size="sm" animation="border" />
              </Col>
            </Row>
          ) : null}
          <Row className="justify-content-sm-center">
            <Col sm="auto">
              <Paginator
                filters={filters}
                setFilterDispatch={setFilters}
                allPaginatedRecords={filteredRecords}
                pageKey="page"
              />
            </Col>
          </Row>
        </div>
      </Collapse>
    </Card>
  );
};

export { Search, SearchHook };
