import { Auth } from "aws-amplify";
import axios, { AxiosRequestConfig, AxiosError } from "axios";
import {
  useMutation,
  useQuery,
  UseQueryOptions,
  QueryKey,
  UseMutationOptions,
} from "@tanstack/react-query";
import { z } from "zod";
import { FalseLiteral } from "typescript";
import type { AppRouter } from "../../../api/application/apibel/funcs/main";
import type { Endpoint } from "../../../api/application/apibel/routing/rpcRouter";
import { apiConfig } from "../constants/config";
import getApiToken from "./getApiToken";

export type GetPaths = AppRouter["getEndpoints"];
export type PostPaths = AppRouter["postEndpoints"];

type InferQueryOptions<TOut> = Omit<
  UseQueryOptions<TOut, Error, TOut, QueryKey>,
  "queryKey" | "queryFn"
>;
type InferMutationOptions<TIn, TOut> = Omit<
  UseMutationOptions<TOut, Error, TIn, unknown>,
  "mutationFn"
>;

const getHeaders = (apiToken: string | null): AxiosRequestConfig["headers"] =>
  apiToken !== null
    ? {
        Authorization: apiToken,
      }
    : {};

export type InferInput<TEndpoint extends Endpoint<any, any>> =
  TEndpoint extends Endpoint<infer Input, any> ? z.input<Input> : undefined;
export type InferOutput<TEndpoint extends Endpoint<any, any>> =
  TEndpoint extends Endpoint<any, infer Output> ? Output : never;

async function performRequest(
  method: "get" | "post",
  pathAndQuery: string,
  body: any,
  apiToken: string | null,
): Promise<any> {
  const headers = apiToken ? getHeaders(apiToken) : undefined;
  const url = `${apiConfig.apibelBaseUrl}/${pathAndQuery}`;
  const result = await axios.request({ method, data: body, url, headers });
  if (result.data.type === "data") {
    return result.data.data;
  }
  throw Error("Something went wrong");
}

export async function get<
  TPath extends keyof GetPaths,
  TEndpoint extends GetPaths[TPath],
>(path: TPath, input: InferInput<TEndpoint>): Promise<InferOutput<TEndpoint>> {
  const apiToken = await getApiToken();
  const encodedInput = encodeURIComponent(JSON.stringify(input));
  const pathAndQuery = `${path}?q=${encodedInput}`;
  return performRequest("get", pathAndQuery, undefined, apiToken);
}

export async function post<
  TPath extends keyof PostPaths,
  TEndpoint extends PostPaths[TPath],
>(path: TPath, input: InferInput<TEndpoint>): Promise<InferOutput<TEndpoint>> {
  const apiToken = await getApiToken();
  return performRequest("post", path, input, apiToken);
}

export const buildQueryKey = (path: string, input: any): any[] => [
  ...path.split("/"),
  ":",
  input,
];

export const useApiQuery = <
  TPath extends keyof GetPaths,
  TEndpoint extends GetPaths[TPath],
>(
  path: TPath,
  input: InferInput<TEndpoint>,
  queryOptions: InferQueryOptions<InferOutput<TEndpoint>> = {},
) =>
  useQuery<InferOutput<TEndpoint>, Error>(
    buildQueryKey(path, input),
    () => get(path, input),
    queryOptions,
  );

export const useApiMutation = <
  TPath extends keyof PostPaths,
  TEndpoint extends PostPaths[TPath],
>(
  path: TPath,
  mutationOptions:
    | InferMutationOptions<InferInput<TEndpoint>, InferOutput<TEndpoint>>
    | undefined = undefined,
) =>
  useMutation<InferOutput<TEndpoint>, Error, InferInput<TEndpoint>, unknown>(
    (input: InferInput<TEndpoint>) => post(path, input),
    mutationOptions,
  );

export const isAxiosError = (e: unknown): e is AxiosError =>
  (e as AxiosError).isAxiosError;
