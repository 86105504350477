import React from "react";
import { Route, Routes } from "react-router-dom";
import AlertRightNowReportScreen from "./AlertRightNowReportScreen";
import { ScreensReportDashboard } from "./Dashboard";
import GeneratedTimeSheetReportScreen from "./GeneratedTimeSheetReportScreen";
import { ScreensPrintReport } from "./PrintReportPage";
import { ScreensReport } from "./Report";
import TimeSheetReportScreen from "./TimeSheetReportScreen";

const ReportRoutes = () => (
  <Routes>
    <Route path=":reportID" element={<ScreensReport />} />
    <Route path="easy/time-sheet" element={<TimeSheetReportScreen />} />
    <Route path="easy/alert-history" element={<AlertRightNowReportScreen />} />
    <Route
      path="easy/time-sheet/generated"
      element={<GeneratedTimeSheetReportScreen />}
    />
    <Route index element={<ScreensReportDashboard />} />
  </Routes>
);

export default ReportRoutes;
