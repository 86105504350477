import { useToken } from "@chakra-ui/react";
import Chart from "react-apexcharts";
import {
  Closed,
  Negative,
  Neutral,
  Positive,
} from "../../../shared/v2/constants/ObjectStatusID";
import { WidgetConfigTypes } from "../../../shared/v2/definitions/dashboards";
import { useApiQuery } from "../../../utilities/apibelRequest";
import { BaseWidgetProps } from "./BaseWidgetProps";
import DashboardWidget from "./DashboardWidget";
import SkeletonPieChart from "./skeletons/SkeletonPieChart";

type Props = BaseWidgetProps & {
  config: WidgetConfigTypes["objects-by-status"];
};

const labels = ["Positive", "Negative", "Neutral", "Closed"];
const seriesIndexMap = {
  0: "Positive",
  1: "Negative",
  2: "Neutral",
  3: "Closed",
} as const;

const statusNameToObjectStatus = (
  status?: "Positive" | "Negative" | "Neutral" | "Closed",
) => {
  switch (status) {
    case "Positive":
      return Positive;
    case "Negative":
      return Negative;
    case "Neutral":
      return Neutral;
    case "Closed":
      return Closed;
    default:
      return undefined;
  }
};

/**
 * Displays totals of objects of the given type by their ObjectStatus (Positive/Negative/Neutral/Closed)
 */
export default function ObjectsByStatusWidget({
  config,
  title,
  setFilters,
  widgetID,
  dashboardID,
}: Props) {
  const objectByStatusQuery = useApiQuery("dashboard/widget/objectsByStatus", {
    dashboardID,
    widgetID,
    widgetType: "objects-by-status",
  });
  const { data } = objectByStatusQuery;
  const neutralColor = useToken("colors", "blue.600");
  const positiveColor = useToken("colors", "green.600");
  const negativeColor = useToken("colors", "red.600");
  const closedColor = useToken("colors", "gray.600");

  return (
    <DashboardWidget
      isLoading={objectByStatusQuery.isLoading}
      isRefetching={objectByStatusQuery.isRefetching}
      title={
        title ??
        `${config.objectTypeGroup
          .charAt(0)
          .toUpperCase()}${config.objectTypeGroup.slice(1)}s by Status`
      }
      helpText={`All ${config.objectTypeGroup}s in the system grouped by status`}
      onReload={objectByStatusQuery.refetch}>
      {objectByStatusQuery.isLoading || objectByStatusQuery.isFetching ? (
        <SkeletonPieChart />
      ) : (
        <Chart
          type="pie"
          options={{
            chart: {
              toolbar: {
                show: true,
                tools: {
                  download: true,
                },
              },
              events: {
                click(chart) {
                  setFilters({
                    type: "records",
                    recordType: `${config.objectTypeGroup}`,
                    statusId: statusNameToObjectStatus(
                      seriesIndexMap[
                        chart.target.parentElement.getAttribute(
                          "data:realIndex",
                        ) as 0 | 1 | 2 | 3
                      ],
                    ),
                  });
                },
              },
            },
            labels,
            dataLabels: {
              formatter: (_, { seriesIndex, w }) =>
                `${w.config.series[seriesIndex]}`,
            },
            noData: {
              text: "No data to display",
              align: "center",
              verticalAlign: "middle",
            },

            colors: [positiveColor, negativeColor, neutralColor, closedColor],
          }}
          series={[data!.positive, data!.negative, data!.neutral, data!.closed]}
        />
      )}
    </DashboardWidget>
  );
}
