import { ComponentStyleConfig } from "@chakra-ui/react";

const tabsStyle: ComponentStyleConfig = {
  parts: ["tablist", "tab"],
  variants: {
    "line-heading": ({ colorMode, colorScheme }) => ({
      tablist: {
        mb: "-1px",
        borderBottom: "1px solid",
        borderColor: "inherit",
      },
      tab: {
        borderTopRadius: "md",
        border: "1px solid",
        borderColor: "transparent",
        mb: "-1px",
        color: colorMode === "light" ? "blackAlpha.800" : "whiteAlpha.800",

        fontSize: "lg",

        _selected: {
          color: colorMode === "light" ? "blackAlpha.800" : "whiteAlpha.800",
          borderColor: "inherit",
          borderBottomColor: colorMode === "light" ? "white" : "gray.800",
          textDecoration: "none",
          fontWeight: "bold",
        },

        ":hover:not([aria-selected=true])": {
          bg:
            colorMode === "light" ? `${colorScheme}.50` : `${colorScheme}.900`,
        },
        _disabled: {
          _hover: { bg: "none" },
        },
      },
    }),
  },
};

export default {
  components: {
    Tabs: tabsStyle,
  },
};
