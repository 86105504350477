/// <reference path= "../../../../node_modules/react-froala-wysiwyg/lib/index.d.ts" />
import React, { useState } from "react";
import { Form, InputGroup } from "react-bootstrap";
import FroalaEditorComponent from "react-froala-wysiwyg";
import FroalaEditorView from "react-froala-wysiwyg/FroalaEditorView";
import Froala from "../../../constants/froala";

interface TextareaProps {
  id?: string;
  rows?: number;
  value: string;
  name: string;
  onUpdate?: any;
  readOnly: boolean;
  disabled?: boolean;
  showCharacterLimit?: boolean;
  unit?: string;
  richText?: boolean;
  max?: number;
}

const FroalaToolbarButtons = {
  // Key represents the more button from the toolbar.
  moreText: {
    // List of buttons used in the  group.
    buttons: [
      "bold",
      "italic",
      "underline",
      "strikeThrough",
      "subscript",
      "superscript",
      "fontFamily",
      "fontSize",
      "textColor",
      "backgroundColor",
      "inlineClass",
      "inlineStyle",
      "clearFormatting",
    ],

    // Alignment of the group in the toolbar.
    align: "left",

    // By default, 3 buttons are shown in the main toolbar. The rest of them are available when using the more button.
    buttonsVisible: 3,
  },

  moreParagraph: {
    buttons: [
      "alignLeft",
      "alignCenter",
      "formatOLSimple",
      "alignRight",
      "alignJustify",
      "formatOL",
      "formatUL",
      "paragraphFormat",
      "paragraphStyle",
      "lineHeight",
      "outdent",
      "indent",
      "quote",
    ],
    align: "left",
    buttonsVisible: 3,
  },

  moreRich: {
    buttons: [
      "insertLink",
      "insertTable",
      "fontAwesome",
      "specialCharacters",
      "embedly",
      "insertHR",
    ],
    align: "left",
    buttonsVisible: 2,
  },

  moreMisc: {
    buttons: [
      "undo",
      "redo",
      "fullscreen",
      "print",
      "spellChecker",
      "selectAll",
      "help",
    ],
    align: "left",
    buttonsVisible: 2,
  },
};

export const Textarea = (props: TextareaProps) => {
  // for description fields in Requirement (+Issue), Action pages
  const [focus, setFocus] = useState(false);
  const maxCharacters = 5000;

  const handleFocus = () => {
    setFocus(true);
  };
  const handleUnfocus = () => {
    setFocus(false);
  };

  if (props.readOnly) {
    if (props.richText) {
      return (
        <FroalaEditorView
          model={props.value}
          config={{
            key: Froala.apiKey,
            attribution: false,
          }}
        />
      );
    }
    return (
      <div className="largeText" id={props.id}>
        {props.value}
      </div>
    );
  }
  if (props.richText) {
    return (
      <FroalaEditorComponent
        tag="textarea"
        onModelChange={props.onUpdate}
        model={props.value}
        config={{
          events: {
            initialized() {
              const editor: any = this;
              setTimeout(() => {
                if (props.disabled) {
                  if (editor.edit) {
                    editor.edit.off();
                  } else {
                    this.initialized();
                  }
                }
              }, 100);
            },
          },
          key: Froala.apiKey,
          attribution: false,
          charCounterCount: true,
          charCounterMax: 5000,
          toolbarButtons: FroalaToolbarButtons,
          quickInsertTags: [""],
          imagePaste: false,
          imageUpload: false,
          filePaste: false,
          fileUpload: false,
          videoPaste: false,
          videoUpload: false,
          toolbarSticky: false,
          wordPasteModal: false,
          wordPasteKeepFormatting: false
        }}
      />
    );
  }
  return (
    <>
      <InputGroup>
        <Form.Control
          id={props.id}
          as="textarea"
          rows={props.rows ? props.rows : 3}
          value={props.value ? props.value.toString() : ""}
          name={props.name}
          onChange={props.onUpdate}
          plaintext={props.readOnly}
          readOnly={props.readOnly}
          disabled={props.disabled}
          maxLength={
            props.showCharacterLimit
              ? props.max
                ? props.max
                : maxCharacters
              : undefined
          }
          onFocus={props.showCharacterLimit ? handleFocus : undefined}
          onBlur={props.showCharacterLimit ? handleUnfocus : undefined}
        />
        {props.unit ? (
          <InputGroup.Append>
            <InputGroup.Text id="basic-addon2">{props.unit}</InputGroup.Text>
          </InputGroup.Append>
        ) : null}
      </InputGroup>
      {props.showCharacterLimit &&
        (focus ? (
          <span className="form-label-small">
            {props.max
              ? props.max - (props.value ? props.value.toString().length : 0)
              : maxCharacters -
                (props.value ? props.value.toString().length : 0)}{" "}
            characters remaining
          </span>
        ) : (
          <div style={{ height: "24px" }} />
        ))}
    </>
  );
};
