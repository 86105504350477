import { Box, Tooltip } from "@chakra-ui/react";
import RichText from "./form/RichText";

type Props = {
  description?: string | null;
  maxWords?: number;
};

const noop = () => {};

const DescriptionField = ({ description, maxWords = 12 }: Props) => {
  if (description) {
    if (description.split(" ").length > maxWords) {
      const desc = `${description.split(" ").slice(0, maxWords).join(" ")}...`;
      return (
        <Tooltip
          openDelay={500}
          label={<RichText isReadOnly value={description} onChange={noop} />}>
          <span>
            <RichText isReadOnly value={desc} onChange={noop} />
          </span>
        </Tooltip>
      );
    }
    return (
      <Box as="span">
        <RichText isReadOnly value={description} onChange={noop} />
      </Box>
    );
  }
  return <></>;
};

export default DescriptionField;
