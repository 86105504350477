import {
  Icon,
  MenuItem,
  MenuList,
  Modal,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { createPortal } from "react-dom";
import { Link } from "react-router-dom";
import { RiAddLine } from "react-icons/ri";
import { IssueIcon } from "../../../constants/commonIcons";
import IssueMenuList from "./IssueMenuList";
import RaiseAnIssueModal from "./RaiseAnIssueModal";
import TopBarMenu from "./TopBarMenu";

type Props = {
  isInOverflow?: boolean;
};
const IssueNavItem = ({ isInOverflow }: Props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  if (isInOverflow) {
    return (
      <>
        <MenuItem
          as={Link}
          icon={<Icon boxSize={4} as={IssueIcon} />}
          to="/issue">
          Issues
        </MenuItem>
        <MenuItem icon={<Icon boxSize={4} as={RiAddLine} />} onClick={onOpen}>
          Raise an Issue
        </MenuItem>
        <Modal isOpen={isOpen} onClose={onClose} size="sm">
          <ModalOverlay />
          <RaiseAnIssueModal onClose={onClose} />
        </Modal>
      </>
    );
  }

  const { body } = document;

  return (
    <TopBarMenu
      isLazy
      title="Issues"
      leftIcon={<Icon boxSize={4} as={IssueIcon} />}>
      {createPortal(
        <chakra-scope>
          <MenuList zIndex={12}>
            <IssueMenuList />
          </MenuList>
        </chakra-scope>,
        body,
      )}
    </TopBarMenu>
  );
};

export default IssueNavItem;
