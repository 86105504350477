import React from "react";
import { Modal, Button } from "react-bootstrap";

interface DialogProps {
  show: boolean;
  hide: () => void;
  title: string;
  body?: string;
  bodyComponent?: JSX.Element;
  confirm: any; // () => void ;
  headerStyle?: any;
  footerStyle?: any;
}

export const Dialog = (props: DialogProps) => (
  <Modal show={props.show} onHide={props.hide} centered>
    <Modal.Header
      closeButton
      style={props.headerStyle ? props.headerStyle : {}}>
      <Modal.Title>{props.title}</Modal.Title>
    </Modal.Header>
    {props.bodyComponent ? (
      props.bodyComponent
    ) : (
      <Modal.Body>
        <p>{props.body}</p>
      </Modal.Body>
    )}

    <Modal.Footer style={props.footerStyle ? props.footerStyle : {}}>
      <Button variant="light" onClick={props.hide}>
        Cancel
      </Button>
      <Button
        onClick={() => {
          props.confirm();
          props.hide();
        }}>
        Confirm
      </Button>
    </Modal.Footer>
  </Modal>
);
