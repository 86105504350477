import React from "react";
import { Container } from "react-bootstrap";
import { AccountDuplicator } from "../../components/UI/AccountDuplicator/AccountDuplicator";
import { Header } from "../../components/UI/Header/Header";

const ScreensAdminCreateAccount = () => (
  // Access.checkAccess(props.appState.userPermissions, Permissions.CodeAdminAccounts, Permissions.TypeCreate, false)
  <Container>
    <Header
      breadcrumbs={[
        { title: "Admin", link: "/caadmin" },
        { title: "Create Account", link: "/caadmin/createaccount" },
      ]}
      title=""
    />
    <AccountDuplicator />
  </Container>
);
export default ScreensAdminCreateAccount;
