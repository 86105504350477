import { Route, Routes } from "react-router-dom";
import ManualScreen from "./ManualScreen";
import EditManualSectionScreen from "./EditManualSectionScreen";
import ManualsScreen from "./ManualsScreen";

export default function ManualRoutes() {
  return (
      <Routes>
        <Route path="/" element={<ManualsScreen />} />
        <Route path="/:manualID" element={<ManualScreen />} />
        <Route path="/section/:sectionID/edit" element={<EditManualSectionScreen />} />
      </Routes>
  );
}
