import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  VStack,
  Text,
  Link,
  Center,
} from "@chakra-ui/react";
import * as React from "react";
import AuthScreenContainer from "./AuthScreenContainer";
import { Underline } from "react-feather";

type Props = {
  signIn: (email: string, password: string) => Promise<void>;
  forgotPassword: () => void;
};

const SignIn = ({ signIn, forgotPassword }: Props) => {
  const [loading, setLoading] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [error, setError] = React.useState<{
    error: boolean;
    msg: string | null;
  }>({
    error: false,
    msg: null,
  });

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    try {
      await signIn(email.trim(), password);
    } catch (e) {
      setError({
        error: true,
        msg: (e instanceof Error && e.message) || "Something went wrong",
      });
    }
    setLoading(false);
  };

  return (
    <chakra-scope>
      <AuthScreenContainer
        title="Sign in to your account"
        bottomContent={
          <>
            <Box pt={6} pb={4}>
              <HStack w="full" justifyContent="center">
                <Text>Don't have an Account?</Text>
                <Button
                  size="md"
                  variant="link"
                  as={Link}
                  href="https://certcrowd.com/contact/">
                  Contact Us
                </Button>
              </HStack>
            </Box>
            <Box pb={6}>
              <VStack w="full" justifyContent="center">
                <Text fontSize="xs" textAlign="center">
                  By Signing In, you agree to CertCrowd's{" "}
                  <Link
                    textDecor="underline"
                    fontWeight="semibold"
                    href="https://certcrowd.com/terms/">
                    {" "}
                    Terms & Conditions
                  </Link>{" "}
                  and{" "}
                  <Link
                    fontWeight="semibold"
                    textDecor="underline"
                    href="https://certcrowd.com/privacy-policy/">
                    Privacy Policy
                  </Link>
                  .
                </Text>
              </VStack>
            </Box>
          </>
        }>
        <Box as="form" onSubmit={(e: any) => handleSubmit(e)}>
          <VStack spacing={6}>
            <FormControl isInvalid={error.error} isRequired>
              <FormLabel>Email</FormLabel>
              <Input
                autoFocus
                onChange={(event: any) => {
                  setError({ error: false, msg: null });
                  setEmail(event.target.value);
                }}
                name="email"
                key="email"
                id="email"
                type="email"
              />
            </FormControl>
            <FormControl isInvalid={error.error} isRequired>
              <FormLabel>Password</FormLabel>
              <Input
                onChange={(event: any) => {
                  setError({ error: false, msg: null });
                  setPassword(event.target.value);
                }}
                value={password}
                name="password"
                key="password"
                id="password"
                type="password"
              />

              {error.error && <FormErrorMessage>{error.msg}</FormErrorMessage>}
            </FormControl>

            <Button
              type="submit"
              w="full"
              isLoading={loading}
              loadingText="Signing in...">
              Sign In
            </Button>

            <HStack w="full" justifyContent="end">
              <Button
                size="sm"
                colorScheme="gray"
                variant="link"
                onClick={forgotPassword}>
                Forgot your password?
              </Button>
            </HStack>
          </VStack>
        </Box>
      </AuthScreenContainer>
    </chakra-scope>
  );
};

export default SignIn;
