import cloneDeep from "lodash/cloneDeep";
import moment from "moment";
import Papa from "papaparse";
import * as Yup from "yup";
import { CustomFieldTypes } from "../../constants/customFieldType";
import { Requirement } from "../Requirement/Requirement.d";
import { CustomFieldListOption } from "./types";

type SuccessResult = {
  type: "success";
  data: Requirement[];
  warnings: string[];
};
type FailureResult = {
  type: "failure";
  errors: string[];
  warnings: string[];
};
export const DATE_PARSE_FORMAT = "D/MM/YYYY";

const parseCSV = (
  onLog: (log: string) => void,
  csvFile: File,
  fieldNames: string[],
  prototype: Requirement,
  schema: Yup.MixedSchema,
  responsibleUser: string,
): Promise<SuccessResult | FailureResult> =>
  new Promise((resolve, reject) => {
    Papa.parse<Record<string, unknown>>(csvFile, {
      header: true,
      skipEmptyLines: "greedy",
      complete: async (parsedCsv) => {
        if (prototype === null) {
          reject(Error("No prototype - shouldnt happen?"));
          return;
        }
        if (schema === undefined) {
          reject(Error("Missing validation schema - shouldnt happen?"));
          return;
        }
        console.log(parsedCsv.data);
        onLog(`CSV has ${parsedCsv.data.length} rows`);

        onLog("Matching fields...");

        if (!responsibleUser) {
          console.error("No responsible user set");
          return;
        }

        onLog(`Using responsible user id: ${responsibleUser}`);

        const columnNames = parsedCsv.meta.fields;
        onLog(`Columns found in csv: ${columnNames?.join(", ")}`);
        if (!columnNames) {
          console.error("fields is empty - something has gone wrong, aborting");
          return;
        }
        console.log(
          "Transforming data - replacing emptystrings with undefined",
        );

        const transformedData: Record<string, unknown>[] = parsedCsv.data.map(
          (data: Record<string, unknown>) => {
            const newData: Record<string, unknown> = {};
            columnNames.forEach((colName, idx) => {
              const colData = data[colName];
              if (colData === "" || colData === null || colData === undefined) {
                console.warn(
                  `Column ${colName} in row ${idx + 1} is (${colData})`,
                );
                newData[colName] = undefined;
              } else {
                newData[colName] = colData;
              }
            });
            return newData;
          },
        );
        console.log("transformed data: ", transformedData);

        // Fields that are on the register but missing in the csv
        const missingFields: string[] = fieldNames.filter(
          (field) => !columnNames.includes(field),
        );
        // Fields that are in the csv but not on the register
        const superflousFields: string[] = columnNames.filter(
          (col) =>
            !fieldNames.includes(col) &&
            col !== "Name" &&
            col !== "Description",
        );

        const fieldWarnings = [];
        if (missingFields.length >= 1) {
          fieldWarnings.push(
            `The following fields exist on the register but are missing in the CSV. These fields wont be set. [${missingFields.join(
              ", ",
            )}]`,
          );
        }
        if (superflousFields.length >= 1) {
          fieldWarnings.push(
            `The following columns exist in the CSV but dont exist on the register. These columns will be skipped [${superflousFields.join(
              ", ",
            )}]`,
          );
        }

        const registerItems: Requirement[] = [];
        // Validate using the schema
        try {
          schema.validateSync(transformedData, {
            abortEarly: false,
          });
        } catch (error) {
          const validationError = error as Yup.ValidationError;
          const errors = validationError.inner.map(
            (err) => `${err.path}: ${err.message}`,
          );
          console.error(`Validation error: `, errors);
          resolve({
            type: "failure",
            errors,
            warnings: fieldWarnings,
          });
          return;
        }

        onLog("Preparing rows...");
        transformedData.forEach((data, idx) => {
          const rowNum = idx + 1;
          const line = data as Record<string, string | undefined>;
          console.log(
            `Getting data ready for ${rowNum} out of ${transformedData.length} rows`,
          );
          const newRegisterItem: Requirement = cloneDeep(prototype);
          if (line) {
            // Set core fields
            newRegisterItem.RequirementName = line.Name ? line.Name : "";
            newRegisterItem.RequirementDescription = line.Description
              ? line.Description
              : "";

            // Set custom fields
            for (const customField of newRegisterItem.CustomFields) {
              const rawValue = line[customField.Label];
              if (rawValue !== undefined) {
                switch (customField.CustomFieldTypeID) {
                  case CustomFieldTypes.List:
                    // If its a list we have to grab the id from the options
                    customField.Value = customField.ListValues.find(
                      (item: CustomFieldListOption) =>
                        item.ListValue === rawValue,
                    ).ListValueID;
                    break;
                  case CustomFieldTypes.MultiList:
                    // If its a multi list we need to split then grab the ids
                    if (rawValue === "") {
                      customField.Value = [];
                    } else {
                      customField.Value = rawValue.split(",").map((item) => {
                        const trimmedItem = item.trim();
                        return customField.ListValues.find(
                          (option: CustomFieldListOption) =>
                            option.ListValue === trimmedItem,
                        ).ListValueID;
                      });
                    }
                    break;
                  case CustomFieldTypes.NumberType:
                    customField.Value = +rawValue;
                    break;
                  case CustomFieldTypes.DateType:
                    // eslint-disable-next-line no-case-declarations
                    const isoString = moment(
                      rawValue,
                      DATE_PARSE_FORMAT,
                    ).toISOString();
                    console.log({ isoString, rawValue });
                    customField.Value = isoString;
                    break;
                  default:
                    customField.Value = rawValue;
                    break;
                }
              } else if (
                customField.CustomFieldTypeID === CustomFieldTypes.List ||
                customField.CustomFieldTypeID === CustomFieldTypes.MultiList
              ) {
                // if value is undefined but were in a list, set it to null explicitly
                customField.Value = null;
              }
            }
            // Set responsible user
            newRegisterItem.RequirementResponsibleUserID = responsibleUser;
            registerItems.push(newRegisterItem);
            console.log(`Data for row ${rowNum} is ready.`, newRegisterItem);
          }
        });
        onLog("Data ready and valid");
        resolve({
          type: "success",
          warnings: fieldWarnings,
          data: registerItems,
        });
      },
    });
  });

export default parseCSV;
