/// <reference path="../../../../node_modules/react-froala-wysiwyg/lib/index.d.ts" />
import FroalaEditorView from "react-froala-wysiwyg/FroalaEditorView";
import FroalaEditorComponent from "react-froala-wysiwyg";
import "froala-editor/js/plugins/word_paste.min";
import Froala from "../../../constants/froala";

type Props = {
  value: string;
  isReadOnly?: boolean;
  onChange: (value: string) => void;
  heightMin?: number;
  heightMax?: number;
  height?: number;
  charCounterMax?: number;
  showCharCounter?: boolean;
};

const FroalaToolbarButtons = {
  // Key represents the more button from the toolbar.
  moreText: {
    // List of buttons used in the  group.
    buttons: [
      "bold",
      "italic",
      "underline",
      "strikeThrough",
      "subscript",
      "superscript",
      "fontFamily",
      "fontSize",
      "textColor",
      "backgroundColor",
      "inlineClass",
      "inlineStyle",
      "clearFormatting",
    ],

    // Alignment of the group in the toolbar.
    align: "left",

    // By default, 3 buttons are shown in the main toolbar. The rest of them are available when using the more button.
    buttonsVisible: 3,
  },

  moreParagraph: {
    buttons: [
      "alignLeft",
      "alignCenter",
      "formatOLSimple",
      "alignRight",
      "alignJustify",
      "formatOL",
      "formatUL",
      "paragraphFormat",
      "paragraphStyle",
      "lineHeight",
      "outdent",
      "indent",
      "quote",
    ],
    align: "left",
    buttonsVisible: 3,
  },

  moreRich: {
    buttons: [
      "insertLink",
      "insertTable",
      "fontAwesome",
      "specialCharacters",
      "embedly",
      "insertHR",
    ],
    align: "left",
    buttonsVisible: 2,
  },

  moreMisc: {
    buttons: [
      "undo",
      "redo",
      "fullscreen",
      "print",
      "spellChecker",
      "selectAll",
      "help",
    ],
    align: "left",
    buttonsVisible: 2,
  },
};

const RichText = ({
  value,
  isReadOnly,
  onChange,
  heightMin,
  heightMax,
  height,
  charCounterMax,
  showCharCounter,
}: Props) => {
  if (isReadOnly) {
    return (
      <FroalaEditorView
        model={value}
        config={{
          key: Froala.apiKey,
          attribution: false,
        }}
      />
    );
  }
  return (
    <FroalaEditorComponent
      tag="textarea"
      onModelChange={onChange}
      model={value}
      config={{
        key: Froala.apiKey,
        attribution: false,
        charCounterCount:
          showCharCounter !== undefined ? showCharCounter : true,
        charCounterMax: charCounterMax ?? 5000,
        toolbarButtons: FroalaToolbarButtons,
        quickInsertTags: [""],
        imagePaste: false,
        imageUpload: false,
        filePaste: false,
        fileUpload: false,
        videoPaste: false,
        videoUpload: false,
        toolbarSticky: false,
        wordPasteModal: false,
        wordPasteKeepFormatting: false,
        heightMin: heightMin ?? undefined,
        heightMax: heightMax ?? undefined,
        height: height ?? undefined,
      }}
    />
  );
};

export default RichText;
