import React from "react";
import { Form, Row, Col } from "react-bootstrap";
import Plot from "react-plotly.js";
import { CheckType } from "./Radio";
import * as RiskMatrixDimensionType from "../../../constants/riskMatrixDimensionType";

/* used for the division of parts */
const fill = (n: number, x: number) => Array(n).fill(x);
const concat = (xs: any, ys: any) => xs.concat(ys);
const quotrem = (n: number, d: number) => [
  Math.floor(n / d),
  Math.floor(n % d),
];
const distribute = (p: number, d: number, n: number) => {
  const e = Math.pow(10, p);

  const [q, r] = quotrem(n * e, d);

  return concat(fill(r, (q + 1) / e), fill(d - r, q / e));
};
/** */

// turn hex colors to rgba
const hexToRGBA = (hex: any, alpha: any) => {
  if (hex !== null) {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);

    if (alpha) {
      return `rgba(${r}, ${g}, ${b}, ${alpha})`;
    }
    return `rgba(${r}, ${g}, ${b})`;
  }
  return "";
};

export const Matrix = (props: any) => {
  const Likelihood = props.RiskMatrix.RiskMatrixDimensions.find(
    (dimension: any) =>
      dimension.RiskMatrixDimensionTypeID ===
      RiskMatrixDimensionType.Likelihood,
  );
  const Consequence = props.RiskMatrix.RiskMatrixDimensions.find(
    (dimension: any) =>
      dimension.RiskMatrixDimensionTypeID ===
      RiskMatrixDimensionType.Consequence,
  );
  const Levels = props.RiskMatrix.RiskMatrixDimensions.find(
    (dimension: any) =>
      dimension.RiskMatrixDimensionTypeID === RiskMatrixDimensionType.RiskLevel,
  );
  const likelihoodResponse = props.Responses.find(
    (response: any) =>
      response.RiskMatrixDimensionTypeID === RiskMatrixDimensionType.Likelihood,
  );
  const consequenceResponse = props.Responses.find(
    (response: any) =>
      response.RiskMatrixDimensionTypeID ===
      RiskMatrixDimensionType.Consequence,
  );
  let RiskColor = "#ffffff00";
  const getIntersection = (likelihood: any, consequence: any) =>
    props.RiskMatrix.intersection.find(
      (level: any) =>
        level.RiskMatrixElementID1 === likelihood.RiskMatrixElementID &&
        level.RiskMatrixElementID2 === consequence.RiskMatrixElementID,
    ) || "";

  const getRiskLevel = () => {
    if (likelihoodResponse && consequenceResponse) {
      const intersection = props.RiskMatrix.intersection.find(
        (level: any) =>
          level.RiskMatrixElementID1 ===
            likelihoodResponse.RiskMatrixElementID &&
          level.RiskMatrixElementID2 ===
            consequenceResponse.RiskMatrixElementID,
      );
      if (intersection) {
        const level = Levels.RiskMatrixElements.find(
          (level: any) =>
            level.RiskMatrixElementID === intersection.RiskMatrixElementID3,
        );
        return level;
      }
    }
    return "";
  };

  const getIntersectionPosition = (
    intersection: any[],
    intersectValue: any,
  ): any => {
    const ID1 = intersectValue.RiskMatrixElementID1;
    const ID2 = intersectValue.RiskMatrixElementID2;
    let intersectPosition: number = -1;
    intersection.find((intersect: any, index: number) => {
      if (
        intersect.RiskMatrixElementID1 === ID1 &&
        intersect.RiskMatrixElementID2 === ID2
      ) {
        intersectPosition = index;
        return intersect;
      }
    });
    return intersectPosition;
  };
  // gets the coresponding color for a given risk level
  const getColorsPerLevel = (props: any, Levels: any) => {
    const Colormatrix: any[] = [];
    const unique: any[] = [];
    Levels.RiskMatrixElements.map((el: any) => {
      unique.push(el.RiskMatrixElementName);
    });
    for (let i = 0; i < unique.length; i++) {
      props.RiskMatrix.RiskMatrixDimensions[0].RiskMatrixElements.map(
        (riskElementRow: any, rowNum: number) => {
          props.RiskMatrix.RiskMatrixDimensions[1].RiskMatrixElements.map(
            (riskElementCol: any, colNum: number) => {
              const riskLevel =
                props.RiskMatrix.RiskMatrixDimensions[2].RiskMatrixElements.find(
                  (element: any, index: number) =>
                    element.RiskMatrixElementID ===
                    props.RiskMatrix.intersection[
                      getIntersectionPosition(props.RiskMatrix.intersection, {
                        RiskMatrixElementID1:
                          riskElementRow.RiskMatrixElementID,
                        RiskMatrixElementID2:
                          riskElementCol.RiskMatrixElementID,
                      })
                    ].RiskMatrixElementID3,
                ).RiskMatrixElementName;
              const Color =
                props.RiskMatrix.RiskMatrixDimensions[2].RiskMatrixElements.find(
                  (element: any, index: number) =>
                    element.RiskMatrixElementID ===
                    props.RiskMatrix.intersection[
                      getIntersectionPosition(props.RiskMatrix.intersection, {
                        RiskMatrixElementID1:
                          riskElementRow.RiskMatrixElementID,
                        RiskMatrixElementID2:
                          riskElementCol.RiskMatrixElementID,
                      })
                    ].RiskMatrixElementID3,
                ).RiskMatrixElementColourHex;
              let check = false;
              Colormatrix.map((el: any) => {
                if (el[0] === riskLevel && el[1] === Color) check = true;
              });
              if (riskLevel === unique[i] && check === false) {
                Colormatrix.push([riskLevel, Color]);
              }
            },
          );
        },
      );
    }
    return Colormatrix;
  };
  // color arrays formation
  const getTheColorArrays = (props: any, Levels: any) => {
    const ColorPerRisk = getColorsPerLevel(props, Levels);
    const totalOfCol: any[] = [];
    ColorPerRisk.map((r: any) => {
      totalOfCol.push(r[1]);
    });
    const filteredCol = totalOfCol.filter(
      (item, pos) => totalOfCol.indexOf(item) === pos,
    );
    const coloursSelected: any[] = [];
    const idleColours: any[] = [];
    const inbetweenColours: any[] = [];

    filteredCol.map((col: any) => {
      coloursSelected.push(hexToRGBA(col, 1));
      idleColours.push(hexToRGBA(col, 0.2));
      inbetweenColours.push(hexToRGBA(col, 0.5));
    });
    return [coloursSelected, idleColours, inbetweenColours];
  };
  // this functions get the level values to be used
  const getLevelsInMatrixForm = (Likelihood: any, Consequence: any) => {
    const z: any[][] = [];
    let temp: any[] = [];
    Likelihood.RiskMatrixElements.map((likelihood: any, rowIndex: number) => {
      temp = [];
      Consequence.RiskMatrixElements.map(
        (consequence: any, colIndex: number) => {
          temp.push(
            getIntersection(likelihood, consequence).RiskMatrixElementName,
          );
        },
      );
      z.push(temp);
    });
    return z.reverse();
  };
  // get the axis of the matrix
  const getAxis = (Likelihood: any, Consequence: any): any[] => {
    const x: any[] = [];
    const y: any[] = [];
    Consequence.RiskMatrixElements.map((consequence: any, index: number) => {
      x.push(consequence.RiskMatrixElementName);
    });
    Likelihood.RiskMatrixElements.map((likelihood: any, rowIndex: number) => {
      y.push(likelihood.RiskMatrixElementName);
    });

    return [x, y.reverse()];
  };
  // gets the z matrix used by the plot in number values
  const getZmatrix = (
    Levels: any,
    Likelihood: any,
    Consequence: any,
    matrixValuesForColors: any,
  ) => {
    const levelsMatrix = getLevelsInMatrixForm(Likelihood, Consequence);
    const ColorPerRisk = getColorsPerLevel(props, Levels);
    const RGBAColorPerRisk: any[] = [];
    for (let i = 0; i < ColorPerRisk.length; i++) {
      RGBAColorPerRisk[i] = [
        ColorPerRisk[i][0],
        hexToRGBA(ColorPerRisk[i][1], 1),
      ];
    }
    const newZMatrix: any[][] = levelsMatrix;
    for (let i = 0; i < RGBAColorPerRisk.length; i++) {
      for (let j = 0; j < matrixValuesForColors.length; j++) {
        if (RGBAColorPerRisk[i][1] === matrixValuesForColors[j][0]) {
          RGBAColorPerRisk[i][1] = matrixValuesForColors[j][1];
        }
      }
    }
    for (let i = 0; i < RGBAColorPerRisk.length; i++) {
      levelsMatrix.map((row: any, index: number) => {
        row.map((el: any, col: number) => {
          if (el === RGBAColorPerRisk[i][0]) {
            newZMatrix[index][col] = RGBAColorPerRisk[i][1];
          }
        });
      });
    }
    return newZMatrix;
  };
  // reverses a number
  const reverseTheRow = (nr: number, length: any) => {
    const middle = Math.round(length / 2);
    for (let i = 0; i <= length; i++) {
      if (nr === i) {
        return length - i;
      }
      if (nr === middle) {
        return nr;
      }
    }
    return 0;
  };
  // get the needed colors
  const theColors = getTheColorArrays(props, Levels);
  const coloursSelected = theColors[0];
  const idleColours = theColors[1];
  const inbetweenColours = theColors[2];
  const newPartitions = distribute(2, coloursSelected.length, 1);
  // building the selecteds color indicator values
  const colorArrayValuesSeperator: any[] = [];
  for (let i = 0; i < coloursSelected.length; i++) {
    if (i === 0) {
      colorArrayValuesSeperator[i] = 0;
    } else if (i === coloursSelected.length - 1) {
      colorArrayValuesSeperator[i] = 1;
    } else {
      colorArrayValuesSeperator[i] =
        colorArrayValuesSeperator[i - 1] + newPartitions[i];
    }
  }
  // matching the selected colors with the values
  const colorValue: any[] = [];
  for (let i = 0; i < coloursSelected.length; i++) {
    colorValue.push([coloursSelected[i], colorArrayValuesSeperator[i]]);
  }
  // this function gets the colorscale for the given colors
  const getColorScale = (
    colorArrayValuesSeperator: any,
    inbetweenColours: any,
    coloursSelected: any,
    idleColours: any,
    special: boolean,
  ) => {
    // be warned if you try and modify this function, it's a mess
    const colorscaleValueSelected: any[] = [];
    let i = 0;
    let colorCount = 0;
    let startingPoint = 0;
    const length = coloursSelected.length * 5 + 1;
    const newdiv = distribute(
      2,
      colorArrayValuesSeperator.length + 1,
      colorArrayValuesSeperator[1],
    );
    while (i < length) {
      if (i === 0) {
        colorscaleValueSelected[i] = [
          colorArrayValuesSeperator[i],
          idleColours[i],
        ];
        colorscaleValueSelected[i + 1] = [0.000001, coloursSelected[0]];

        colorscaleValueSelected[i + 2] = [
          newdiv[1] + colorscaleValueSelected[i][0],
          inbetweenColours[0],
        ];
        colorscaleValueSelected[i + 3] = [
          parseFloat(
            (
              newdiv[1] +
              colorscaleValueSelected[i + 2][0] -
              0.000001
            ).toPrecision(6),
          ),
          coloursSelected[0],
        ];
        colorscaleValueSelected[i + 4] = [
          newdiv[1] + colorscaleValueSelected[i + 2][0],
          idleColours[0],
        ];
        colorscaleValueSelected[i + 5] = [
          parseFloat(
            (
              newdiv[1] +
              colorscaleValueSelected[i + 4][0] -
              0.000001
            ).toPrecision(6),
          ),
          idleColours[0],
        ];

        colorCount += 1;
        startingPoint = newdiv[1] + colorscaleValueSelected[i + 4][0];
        i += 6;

        // special case when we have 2 colours as the previous algorithm does not work
      } else if (i + 5 === length && coloursSelected.length === 2) {
        colorscaleValueSelected[i - 1] = [
          parseFloat(
            (colorscaleValueSelected[i - 2][0] + 0.000001).toPrecision(6),
          ),
          idleColours[0],
        ];
        colorscaleValueSelected[i] = [
          parseFloat(
            (
              newdiv[1] / 3 +
              colorscaleValueSelected[i - 1][0] -
              0.000001
            ).toPrecision(6),
          ),
          coloursSelected[colorCount],
        ];
        colorscaleValueSelected[i + 1] = [
          newdiv[1] / 3 + colorscaleValueSelected[i - 1][0],
          inbetweenColours[colorCount],
        ];
        colorscaleValueSelected[i + 2] = [
          parseFloat(
            (
              newdiv[1] / 3 +
              colorscaleValueSelected[i][0] -
              0.000001
            ).toPrecision(6),
          ),
          coloursSelected[colorCount],
        ];
        colorscaleValueSelected[i + 3] = [
          parseFloat(
            (newdiv[1] / 3 + colorscaleValueSelected[i][0]).toPrecision(6),
          ),
          idleColours[colorCount],
        ];
        colorscaleValueSelected[i + 4] = [
          colorArrayValuesSeperator[coloursSelected.length - 1],
          special ? coloursSelected[colorCount] : idleColours[colorCount],
        ];
        i += 5;
      } else if (i + 5 === length) {
        colorscaleValueSelected[i] = [
          startingPoint,
          coloursSelected[colorCount],
        ];
        colorscaleValueSelected[i + 1] = [
          newdiv[1] + colorscaleValueSelected[i][0],
          inbetweenColours[colorCount],
        ];
        colorscaleValueSelected[i + 2] = [
          parseFloat(
            (
              newdiv[1] +
              colorscaleValueSelected[i + 1][0] -
              0.000001
            ).toPrecision(6),
          ),
          coloursSelected[colorCount],
        ];
        colorscaleValueSelected[i + 3] = [
          parseFloat(
            (newdiv[1] + colorscaleValueSelected[i + 1][0]).toPrecision(6),
          ),
          idleColours[colorCount],
        ];
        colorscaleValueSelected[i + 4] = [
          colorArrayValuesSeperator[coloursSelected.length - 1],
          special ? coloursSelected[colorCount] : idleColours[colorCount],
        ];
        i += 5;
      } else {
        colorscaleValueSelected[i] = [
          startingPoint,
          coloursSelected[colorCount],
        ];
        colorscaleValueSelected[i + 1] = [
          newdiv[1] + colorscaleValueSelected[i][0],
          inbetweenColours[colorCount],
        ];
        colorscaleValueSelected[i + 2] = [
          parseFloat(
            (colorArrayValuesSeperator[colorCount] - 0.000001).toPrecision(6),
          ),
          coloursSelected[colorCount],
        ];
        colorscaleValueSelected[i + 3] = [
          colorArrayValuesSeperator[colorCount],
          idleColours[colorCount],
        ];
        colorscaleValueSelected[i + 4] = [
          parseFloat(
            (
              colorscaleValueSelected[i + 3][0] +
              newdiv[1] -
              0.000001
            ).toPrecision(6),
          ),
          idleColours[colorCount],
        ];

        colorCount += 1;
        startingPoint = parseFloat(
          (colorscaleValueSelected[i + 3][0] + newdiv[1]).toPrecision(6),
        );
        i += 5;
      }
    }
    return colorscaleValueSelected;
  };
  // everything willl repeatedly refer to this so we declare it
  const colorscaleValueSelected = getColorScale(
    colorArrayValuesSeperator,
    inbetweenColours,
    coloursSelected,
    idleColours,
    false,
  );

  // this functions populates the matrix of the plot, it only displays the value when selected
  const getFillOfMatrix = (xValues: any[], yValues: any[]) => {
    const result: any[] = [];
    let row = -1;
    let col = -1;
    Likelihood.RiskMatrixElements.map((item: any, index: number) => {
      if (
        item.RiskMatrixElementID === props.Responses
          ? props.Responses.find(
              (element: any) =>
                element.RiskMatrixDimensionTypeID ===
                RiskMatrixDimensionType.Likelihood,
            ).RiskMatrixElementID
          : null
      ) {
        row = reverseTheRow(index, Likelihood.RiskMatrixElements.length - 1);
      }
    });
    Consequence.RiskMatrixElements.map((item: any, index: number) => {
      if (
        item.RiskMatrixElementID === props.Responses
          ? props.Responses.find(
              (element: any) =>
                element.RiskMatrixDimensionTypeID ===
                RiskMatrixDimensionType.Consequence,
            ).RiskMatrixElementID
          : null
      ) {
        col = index;
      }
    });
    for (let j = yValues.length - 1; j > -1; j--) {
      for (let i = 0; i < xValues.length; i++) {
        const textColor = "white";
        let text = "";
        if (i === col && j === row) {
          text = levelsMatrix[row][col];
        }
        result.push({
          xref: "x1",
          yref: "y1",
          x: xValues[i],
          y: yValues[j],
          text,
          hoverinfo: "none",
          font: {
            family: "Arial",
            size: 12,
            backgroundcolor: "rgb(50, 171, 96)",
            color: textColor,
          },
          showarrow: false,
        });
      }
    }

    return result;
  };
  // neccessary variables to build the plot
  const xValues = getAxis(Likelihood, Consequence)[0];
  const yValues = getAxis(Likelihood, Consequence)[1];
  let levelsMatrix = getLevelsInMatrixForm(Likelihood, Consequence);
  const zValues = getZmatrix(Levels, Likelihood, Consequence, colorValue);
  // plot's data
  const dataPlot = [
    {
      x: xValues,
      y: yValues,
      z: zValues,
      type: "heatmap",
      colorscale: colorscaleValueSelected,
      showscale: false,
    },
  ];

  // dets the selected values' color
  const getSelected = (value: any, equalDim: boolean, farEndValue: boolean) => {
    if (
      (farEndValue && equalDim) ||
      value ===
        parseFloat(
          (
            colorscaleValueSelected[colorscaleValueSelected.length - 2][0] -
            0.000001
          ).toPrecision(6),
        )
    ) {
      RiskColor = coloursSelected[coloursSelected.length - 1];
    } else {
      colorscaleValueSelected.map((row: any) => {
        if (row[0] === value) {
          RiskColor = row[1];
        }
      });
    }
  };

  // sets the layout of the plot
  const getLayout = (xValues: any[], yValues: any[]) => ({
    annotations: getFillOfMatrix(xValues, yValues),
    xaxis: {
      ticks: "",
      side: "top",
      tickangle: 90,
      fixedrange: true,
      automargin: true,
      showgrid: false,
    },
    yaxis: {
      ticks: "",
      ticksuffix: " ",
      fixedrange: true,
      automargin: true,
      showgrid: false,
    },
    autosize: true,
    // width: 600,
    // height: 500,
    // margin: { l: 110, r: 10, b: 20, t: 110, pad: 0 },
    hovermode: false,
  });
  // function to determine the plot's display
  const getStyling = (data: any[]): any[] => {
    // we have an extreme case here where the bottom right value of the matrix is selected and so the z matrix has no "1"s in its matrix messing up this way the color scales
    // to fix this we include a extreme case where the colorscale matrix is switchen and tha very right bottom value will be changed into 1
    const newIndexes = getIndexes(data[0].z);
    // check if we have 1s
    let check = false;
    newIndexes.map((row: any) => {
      row.map((el: any) => {
        if (el === 1) {
          check = true;
        }
      });
    });
    if (check === false) {
      // we have entered the extreme case
      newIndexes[0][newIndexes[0].length - 1] = 1;
      return [
        {
          x: xValues,
          y: yValues,
          z: newIndexes,
          type: "heatmap",
          colorscale: getColorScale(
            colorArrayValuesSeperator,
            inbetweenColours,
            coloursSelected,
            idleColours,
            true,
          ),
          showscale: false,
        },
      ];
    }
    return [
      {
        x: xValues,
        y: yValues,
        z: newIndexes,
        type: "heatmap",
        colorscale: colorscaleValueSelected,
        showscale: false,
      },
    ];
  };

  // gets the new values of the z Matrix for the plot when selections are made
  const getIndexes = (zValues: any[][]): any[][] => {
    const newZ = zValues;
    const rowResponse = props.Responses.find(
      (response: any) =>
        response.RiskMatrixDimensionTypeID ===
        RiskMatrixDimensionType.Likelihood,
    )
      ? props.Responses.find(
          (response: any) =>
            response.RiskMatrixDimensionTypeID ===
            RiskMatrixDimensionType.Likelihood,
        ).RiskMatrixElementID
      : [];
    const colResponse = props.Responses.find(
      (response: any) =>
        response.RiskMatrixDimensionTypeID ===
        RiskMatrixDimensionType.Consequence,
    )
      ? props.Responses.find(
          (response: any) =>
            response.RiskMatrixDimensionTypeID ===
            RiskMatrixDimensionType.Consequence,
        ).RiskMatrixElementID
      : [];
    levelsMatrix.map((row: any, rowIndex: number) => {
      row.map((col: any, colIndex: number) => {
        const rowElement = Likelihood.RiskMatrixElements.find(
          (element: any) => element.RiskMatrixElementOrder === rowIndex + 1,
        ).RiskMatrixElementID;
        const colElement = Consequence.RiskMatrixElements.find(
          (element: any) => element.RiskMatrixElementOrder === colIndex + 1,
        ).RiskMatrixElementID;

        if (rowElement === rowResponse && colElement === colResponse) {
          const originvalue =
            newZ[reverseTheRow(rowIndex, levelsMatrix.length - 1)][colIndex];
          let newVal = 0;
          colorscaleValueSelected.map((el: any) => {
            for (let i = 0; i < coloursSelected.length; i++) {
              if (
                el[0] === originvalue &&
                (el[1] === idleColours[i] || el[1] === inbetweenColours[i])
              ) {
                colorscaleValueSelected.map((el2: any) => {
                  if (el2[1] === coloursSelected[i]) {
                    newVal = el2[0];
                  }
                });
              }
            }
          });
          newZ[reverseTheRow(rowIndex, levelsMatrix.length - 1)][colIndex] =
            newVal;
          getSelected(
            newZ[reverseTheRow(rowIndex, levelsMatrix.length - 1)][colIndex],
            rowIndex === colIndex,
            originvalue === 1,
          );
        } else if (rowElement === rowResponse || colElement === colResponse) {
          const originvalue =
            newZ[reverseTheRow(rowIndex, levelsMatrix.length - 1)][colIndex];
          let newVal = 0;
          colorscaleValueSelected.map((el: any) => {
            for (let i = 0; i < inbetweenColours.length; i++) {
              if (el[0] === originvalue && el[1] === idleColours[i]) {
                colorscaleValueSelected.map((el2: any) => {
                  if (el2[1] === inbetweenColours[i]) {
                    newVal = el2[0];
                  }
                });
              }
            }
          });
          newZ[reverseTheRow(rowIndex, levelsMatrix.length - 1)][colIndex] =
            newVal;
        }
      });
    });
    return newZ;
  };
  if (props.readOnly && props.actionQuestion) {
    return <span>{props.actionQuestion}</span>;
  }
  return (
    <Form.Group>
      <Row>
        <Col sm="auto">
          <fieldset>
            {/* dimension name shouldn't be hardcoded once data is clean */}
            <span className={props.readOnly ? "disabled" : ""}>Likelihood</span>
            {Likelihood.RiskMatrixElements.map((item: any) => (
              <CheckType
                key={item.RiskMatrixElementID}
                label={item.RiskMatrixElementName}
                name={`${props.name}_${item.RiskMatrixDimensionTypeID}`}
                id={`${props.name}_${item.RiskMatrixElementID}`}
                value={item.RiskMatrixElementID}
                checked={
                  props.Responses.find(
                    (element: any) =>
                      element.RiskMatrixDimensionTypeID ===
                      RiskMatrixDimensionType.Likelihood,
                  )
                    ? item.RiskMatrixElementID ===
                      props.Responses.find(
                        (element: any) =>
                          element.RiskMatrixDimensionTypeID ===
                          RiskMatrixDimensionType.Likelihood,
                      ).RiskMatrixElementID
                    : false
                }
                onChange={props.onUpdate}
                disabled={props.readOnly}
                type="radio"
                tooltipText={item.RiskMatrixElementDescription}
              />
            ))}
          </fieldset>
        </Col>
        <Col />
        <Col sm={4}>
          <fieldset>
            <span className={props.readOnly ? "disabled" : ""}>
              Consequence
            </span>
            {Consequence.RiskMatrixElements.map((item: any) => (
              <CheckType
                key={item.RiskMatrixElementID}
                label={item.RiskMatrixElementName}
                name={`${props.name}_${item.RiskMatrixDimensionTypeID}`}
                id={`${props.name}_${item.RiskMatrixElementID}`}
                value={item.RiskMatrixElementID}
                checked={
                  props.Responses.find(
                    (element: any) =>
                      element.RiskMatrixDimensionTypeID ===
                      RiskMatrixDimensionType.Consequence,
                  )
                    ? item.RiskMatrixElementID ===
                      props.Responses.find(
                        (element: any) =>
                          element.RiskMatrixDimensionTypeID ===
                          RiskMatrixDimensionType.Consequence,
                      ).RiskMatrixElementID
                    : false
                }
                onChange={props.onUpdate}
                disabled={props.readOnly}
                type="radio"
                tooltipText={item.RiskMatrixElementDescription}
              />
            ))}
          </fieldset>
        </Col>
        <Col sm="1" />
      </Row>
      <Row>
        <Plot
          className="js-plotly-plot2"
          data={getStyling(dataPlot)}
          layout={getLayout(xValues, yValues)}
        />
      </Row>
      <Row>
        <Col
          xs="12"
          md="2"
          style={{
            backgroundColor: RiskColor,
            color: RiskColor === "#ffffff00" ? "black" : "white",
            border: RiskColor === "#ffffff00" ? "solid" : "none",
            textAlign: "center",
            height: "80px",
            lineHeight: "80px",
          }}>
          {getRiskLevel().RiskMatrixElementName || "Risk level"}
        </Col>
        <Col xs="12" md="10">
          {getRiskLevel().RiskMatrixElementDescription}
        </Col>
      </Row>
    </Form.Group>
  );
};
