import {
  BlueprintConfigQuestion,
  BlueprintStateOrgInfo,
} from "../../../shared/v2/definitions/blueprints";
import * as Request from "../../../utilities/apibelRequest";

/**
 *
 * @param question
 * @param orgInfo
 * @param useAI If false, will use the default example text instead of real AI results (note this example test was generated by AI)
 * @returns
 */
export const handleGetAIResult = async (
  question: BlueprintConfigQuestion,
  orgInfo: BlueprintStateOrgInfo,
): Promise<string> => {
  const { handleCreateContent } = question;
  if (!handleCreateContent) {
    return "";
  }

  const { promptID } = handleCreateContent;

  const text = await Request.post("ai/promptBlueprint", {
    promptID,
    orgInfo: {
      orgName: orgInfo.orgName,
      orgIndustry: orgInfo.orgIndustry,
      orgLocation: orgInfo.orgLocation,
      orgSize: orgInfo.orgSize,
    },
  });

  // Replace {CompanyName} with the business name
  return text.replace(
    /{CompanyName}/g,
    orgInfo.orgName || "(Organisation Name)",
  );
};
