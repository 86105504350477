import { Badge, Icon } from "@chakra-ui/react";
import React from "react";
import { NotificationIcon } from "../../../constants/commonIcons";

type Props = {
  number: Number;
};

const NotificationBadge = ({ number }: Props) => (
  <span>
    <Icon boxSize="4" as={NotificationIcon} />
    {number ? (
      <Badge
        borderRadius="full"
        px="2"
        right="-2px"
        colorScheme="red"
        position="absolute"
        top="0px"
        zIndex="99">
        {number < 9 ? number : "9+"}
      </Badge>
    ) : null}
  </span>
);

export default NotificationBadge;
