import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  HStack,
  InputGroup,
  Skeleton,
  Stack,
  Table,
  TableCaption,
  Tbody,
  Td,
  Text,
  Textarea,
  Th,
  Thead,
  Tr,
  Progress,
  VStack,
} from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import Select, { ValueType } from "react-select";
import * as Yup from "yup";
import { Card, CardHeading, CardContent } from "../../components/chakra/card";
import Icon from "../../components/UI/Icon";
import { CustomFieldIDToName } from "../../constants/customFieldType";
import * as Request from "../../utilities/request";
import { Requirement } from "../Requirement/Requirement.d";
import createRegisterSchema from "./createRegisterSchema";
import parseCSV from "./parseCSV";
import { CustomField } from "./types";
import AccessGuard from "../../components/chakra/AccessGuard";
import ChakraScreenContainer from "../../components/chakra/ChakraScreenContainer";
import { useAppState } from "../../components/App/AppProvider";

declare global {
  namespace JSX {
    interface IntrinsicElements {
      "chakra-scope": React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement>,
        HTMLElement
      >;
    }
  }
}

const assertNever = (value: never) => value;

const SUPPORTED_FIELD_IDS = [
  "046ec61e-ea42-4c24-84a1-1972bad9b837", // List
  "a7411ec3-7397-4298-a55d-12ac471e7120", // Multilist
  "4e60b38e-e11d-4c3f-9ff5-786999f12c2c", // NumberType
  "b1b550b5-8e76-401b-ae86-476558ce69f7", // TextType
  "f638a15d-cd76-41be-aac3-4a3204acb48d", // DateType
  "f2b854e7-1e3f-48ac-9b72-46bd2ebec228", // LongTextType
];

const BREADCRUMBS = [
  {
    label: "Home",
    link: "/",
  },
  {
    label: "Registers",
    link: "/register",
  },
  {
    label: "Upload",
    link: "/register/upload",
  },
];

type SelectOption = {
  value: string;
  label: string;
};

// Screen State Reducer

type ScreenData = {
  prototype: Requirement;
  supportedFields: CustomField[];
  unsupportedFields: CustomField[];
  schema: Yup.MixedSchema;
  userOptions: SelectOption[];
};
type ParsedData = {
  items: Requirement[];
  warnings: string[];
};

type FetchingState = {
  type: "fetching";
};
type ErrorState = {
  type: "error";
  errors: string[];
};
type ReadyState = {
  type: "ready";
  screenData: ScreenData;
};
type ParsedState = {
  type: "parsed";
  screenData: ScreenData;
  parsedData: ParsedData;
};
type UploadingState = {
  type: "upload";
  currentRow: number;
  screenData: ScreenData;
  parsedData: ParsedData;
};
type DoneState = {
  type: "done";
  screenData: ScreenData;
};

type ScreenState =
  | FetchingState
  | ErrorState
  | ReadyState
  | ParsedState
  | UploadingState
  | DoneState;

const CSVUploaderScreen = () => {
  const { app, auth } = useAppState();
  const params = useParams();
  const [screenState, setScreenState] = useState<ScreenState>({
    type: "fetching",
  });
  // Form fields
  const [responsibleUser, setResponsibleUser] =
    useState<ValueType<SelectOption>>();
  const [csvFile, setCSVFile] = useState<File | null>(null);

  const fileInputRef = useRef<HTMLInputElement>(null);
  const logRef = useRef<HTMLTextAreaElement>(null);

  const [log, setLog] = useState<string[]>([]);
  const appendLog = (line: string) => {
    setLog((prev) => [...prev, line]);
    if (logRef.current) {
      logRef.current.scrollTop = logRef.current.scrollHeight;
    }
  };

  const isInternal: boolean = Boolean(app.userInfo.isInternal);

  const { registerID } = params;

  const fetchRegister = async () => {
    setScreenState({ type: "fetching" });
    setResponsibleUser(undefined);
    setCSVFile(null);
    setLog([]);
    try {
      appendLog("Fetching register data...");
      if (registerID === undefined) {
        setScreenState({ type: "error", errors: [`Invalid register id`] });
        return;
      }
      const result = await Request.get(`register/new?type=${registerID}`, auth);
      if (result && result.data) {
        const prototype = result.data as Requirement;
        if (app.attributes.userID === undefined) {
          throw Error("User id is undefiend");
        }
        // Setup the options for responsible user, as well as setting the default
        prototype.RequirementResponsibleUserID = app.attributes.userID;
        const userOptions = prototype.ResponsibleUsers.map(
          ({ UserFullName, UserID }) => ({
            label: UserFullName,
            value: UserID,
          }),
        );
        setResponsibleUser(userOptions[0]);

        // Check supported and unsupported fields
        const allFields = prototype.CustomFields;
        const supportedFields: CustomField[] = allFields.filter((customField) =>
          SUPPORTED_FIELD_IDS.includes(customField.CustomFieldTypeID),
        );
        const unsupportedFields: CustomField[] = allFields.filter(
          (customField) =>
            !SUPPORTED_FIELD_IDS.includes(customField.CustomFieldTypeID),
        );
        // Check if any unsupported fields are required
        const mandatoryUnsupported = unsupportedFields.filter(
          (field) => field.IsMandatory,
        );
        if (mandatoryUnsupported.length >= 1) {
          setScreenState({
            type: "error",
            errors: [
              `One or more fields are mandatory on this register, but are of types not supported by the CSV uploader [${mandatoryUnsupported
                .map((field) => field.Label)
                .join(", ")}]`,
            ],
          });
        }

        // Create schema
        appendLog("Creating validation schema...");
        const schema = createRegisterSchema(supportedFields);
        console.log("Schema created");

        appendLog("Ready");
        setScreenState({
          type: "ready",
          screenData: {
            prototype,
            schema,
            supportedFields,
            unsupportedFields,
            userOptions,
          },
        });
      } else {
        setScreenState({ type: "error", errors: [`Failed to fetch register`] });
        return;
      }
    } catch (e) {
      setScreenState({ type: "error", errors: ["Failed to fetch register"] });
    }
  };

  useEffect(() => {
    // Fetch data for the current register
    fetchRegister();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const isValid = Boolean(csvFile && responsibleUser);

  const handlePressRestart = fetchRegister;

  const handleParseCSV = async () => {
    if (screenState.type !== "ready") {
      return;
    }
    const { schema, prototype, supportedFields } = screenState.screenData;
    // Typescript doesnt like unions of T[] | T because if T is already unknown[] then it gets fucky
    const responsibleUserID = Array.isArray(responsibleUser)
      ? (responsibleUser[0] as SelectOption).value
      : (responsibleUser as SelectOption).value;
    if (!csvFile) {
      appendLog("Invalid CSV");
      return;
    }
    const parsedCSVResult = await parseCSV(
      appendLog,
      csvFile,
      supportedFields.map((field) => field.Label),
      prototype,
      schema,
      responsibleUserID,
    );

    if (parsedCSVResult.type === "failure") {
      setScreenState({
        type: "error",
        errors: parsedCSVResult.errors,
      });
      return;
    }

    setScreenState({
      type: "parsed",
      screenData: screenState.screenData,
      parsedData: {
        items: parsedCSVResult.data,
        warnings: parsedCSVResult.warnings,
      },
    });
  };

  const handlePressUpload = async () => {
    if (screenState.type !== "parsed") return;
    let row = 1;
    setScreenState({
      type: "upload",
      currentRow: row,
      screenData: screenState.screenData,
      parsedData: screenState.parsedData,
    });
    const { items } = screenState.parsedData;
    for (const item of items) {
      setScreenState({
        type: "upload",
        currentRow: row,
        screenData: screenState.screenData,
        parsedData: screenState.parsedData,
      });
      // eslint-disable-next-line no-await-in-loop
      await Request.put("register", item, auth);
      row += 1;
    }
    setScreenState({ type: "done", screenData: screenState.screenData });
  };

  let stepUI: JSX.Element | null = null;
  const { type } = screenState;
  switch (type) {
    case "fetching":
      stepUI = (
        <Stack>
          <Skeleton height="20px" />
          <Skeleton height="20px" />
          <Skeleton height="20px" />
          <Skeleton height="20px" />
        </Stack>
      );
      break;
    case "ready":
      stepUI = (
        <VStack spacing={6}>
          <Table variant="striped" colorScheme="gray">
            <TableCaption placement="top">Register Fields</TableCaption>
            {screenState.screenData.unsupportedFields.length >= 1 ? (
              <TableCaption textColor="red" placement="bottom">
                The following fields are unsupported:{" "}
                {screenState.screenData.unsupportedFields
                  .map((field) => field.Label)
                  .join(", ")}
              </TableCaption>
            ) : (
              <TableCaption placement="bottom">
                All fields on this register are supported
              </TableCaption>
            )}
            <Thead>
              <Tr>
                <Th>Field</Th>
                <Th>Type</Th>
                <Th>Required?</Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr>
                <Td>Name</Td>
                <Td>Text</Td>
                <Td>Required</Td>
              </Tr>
              <Tr>
                <Td>Description</Td>
                <Td>Long Text</Td>
                <Td>Required</Td>
              </Tr>
              {screenState.screenData.supportedFields.map((customField) => (
                <Tr key={customField.CustomFieldID}>
                  <Td>{customField.Label}</Td>
                  <Td>
                    {CustomFieldIDToName[customField.CustomFieldTypeID] ??
                      "Unknown Field"}
                  </Td>
                  <Td>{customField.IsMandatory ? "Required" : "Optional"}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
          <VStack width="full" spacing={4} align="flex-start">
            <FormControl id="responsible-user" isRequired mb={2}>
              <FormLabel>Responsible User</FormLabel>
              <Box maxWidth="300px">
                <Select
                  options={screenState.screenData.userOptions}
                  isMulti={false}
                  isSearchable
                  placeholder="Nothing selected"
                  value={responsibleUser}
                  onChange={(value: ValueType<SelectOption>) =>
                    setResponsibleUser(value)
                  }
                  name="Responsible User"
                />
              </Box>
              <FormHelperText>
                All rows will be set to this user.
              </FormHelperText>
            </FormControl>
            <FormControl id="csv" isRequired>
              <FormLabel>CSV</FormLabel>
              <InputGroup>
                <input
                  onChange={(e: any) => setCSVFile(e.target.files[0])}
                  type="file"
                  accept="csv"
                  name="csv-upload"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                />
                <Button
                  minWidth="300px"
                  variant="outline"
                  onClick={() => fileInputRef.current?.click()}>
                  <Icon name="File" />
                  {csvFile ? csvFile.name : "Upload a CSV"}
                </Button>
              </InputGroup>
            </FormControl>
            <Text>
              Dates should be in DD/MM/YYYY format. It is reccomended you check
              what the CSV looks like in a normal text editor (like notepad) as
              sometimes excel shows the dates in a different format than what it
              saves them in.
            </Text>
            <Button onClick={handleParseCSV} disabled={!isValid}>
              Parse CSV
            </Button>
          </VStack>
        </VStack>
      );
      break;
    case "parsed":
      stepUI = (
        <VStack spacing={2} align="flex-start">
          <Text>Parsed Successfully</Text>
          {screenState.parsedData.warnings.length >= 1 ? (
            <Box>
              <Text>
                Some warnings have occured, please ensure these are fine:
              </Text>
              {screenState.parsedData.warnings.map((warning) => (
                <Text key={warning} textColor="yellow.800">
                  {warning}
                </Text>
              ))}
            </Box>
          ) : null}
          <Text>Data is ready to upload</Text>
          <HStack spacing={2}>
            <Button onClick={handlePressUpload}>
              Upload {screenState.parsedData.items.length} rows
            </Button>
            <Button variant="outline" onClick={handlePressRestart}>
              Cancel
            </Button>
          </HStack>
        </VStack>
      );
      break;
    case "upload":
      // eslint-disable-next-line no-case-declarations
      const row = screenState.currentRow;
      // eslint-disable-next-line no-case-declarations
      const total = screenState.parsedData.items.length;
      // eslint-disable-next-line no-case-declarations
      const progress = 100 * (row / total);
      stepUI = (
        <>
          <Text>
            Uploading row {row} of {total}...
          </Text>
          <Progress value={progress} />
        </>
      );
      break;
    case "done":
      stepUI = (
        <Text>
          Complete! You will likely need to refresh the page before you can see
          the new registers in the sidebar.
        </Text>
      );
      break;
    case "error":
      stepUI = (
        <VStack spacing={2} align="flex-start">
          <Button onClick={handlePressRestart}>Restart</Button>
          <Text>
            {screenState.errors.length >= 2 ? "Some errors" : "An error"}{" "}
            occured:
          </Text>
          <Box>
            {screenState.errors.map((error) => (
              <Text key={error} textColor="red">
                {error}
              </Text>
            ))}
          </Box>
        </VStack>
      );
      break;
    default:
      assertNever(type);
      break;
  }

  return (
    <chakra-scope>
      <AccessGuard hasAccess={isInternal}>
        <ChakraScreenContainer
          pageTitle="Register - CSV Upload"
          breadcrumbs={BREADCRUMBS}>
          <Card>
            <CardHeading
              title={
                screenState.type !== "error" && screenState.type !== "fetching"
                  ? `CSV Upload - ${screenState.screenData.prototype.ObjectTypeName}`
                  : "CSV Upload"
              }
            />
            <CardContent>
              {stepUI}
              <Box pt={4}>
                <Text>Log</Text>
                <Textarea
                  h="200px"
                  ref={logRef}
                  value={log.join("\n")}
                  readOnly
                />
              </Box>
            </CardContent>
          </Card>
        </ChakraScreenContainer>
      </AccessGuard>
    </chakra-scope>
  );
};

export default CSVUploaderScreen;
