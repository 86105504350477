import { createBrowserHistory } from "history";

// removes state object from history's location object, so it does not persist when page is refreshed
export const clearLocationState = (property: string) => {
  const history = createBrowserHistory();
  if (
    history.location.state &&
    history.location.state.hasOwnProperty(property)
  ) {
    const state = {...history.location.state};
    state[property] = undefined;
    history.replace({...history.location, state});
  }
};
