import {
  Box,
  Button,
  chakra,
  Tooltip,
  Checkbox,
  VStack,
  Flex,
  FormLabel,
  Divider,
  HStack,
  FormHelperText,
  FormControl,
  ButtonGroup,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import ShortTextField from "../../../components/chakra/form/formComponents/ShortTextField";
import Select from "../../../components/Select";
import { UserSummary } from "../../../shared/v2/definitions/user";
import { useApiMutation, useApiQuery } from "../../../utilities/apibelRequest";
import { getUserAccountStatus } from "../../../utilities/userAccountStatus";
import useToast from "../../../utilities/useToast";

type ConfirmProps = {
  isOpen: boolean;
  onClose: () => void;
  onDelete: () => void;
  loading: boolean;
  title?: string;
};

function ConfirmDeleteModal({
  isOpen,
  onClose,
  onDelete,
  loading,
  title,
}: ConfirmProps) {
  return (
    <chakra-scope>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <chakra-scope>
            <ModalHeader>Delete user from account</ModalHeader>
            <ModalCloseButton variant="ghost" />
            <ModalBody>
              <Text>
                Are you sure you want to delete this user from your account?
              </Text>
            </ModalBody>

            <ModalFooter>
              <ButtonGroup>
                <Button
                  isDisabled={loading}
                  colorScheme="gray"
                  onClick={onClose}>
                  Cancel
                </Button>
                <Button
                  colorScheme="red"
                  variant="solid"
                  isLoading={loading}
                  onClick={onDelete}>
                  Delete
                </Button>
              </ButtonGroup>
            </ModalFooter>
          </chakra-scope>
        </ModalContent>
      </Modal>
    </chakra-scope>
  );
}

type Props = {
  user?: UserSummary;
  users?: UserSummary[];
  accountOwnerID: string;
  roles?: { roleID: string; name: string }[];
  onClose: (refresh?: boolean) => void;
};

type SelectableUserOption = {
  userID: string;
  name: string;
  email: string;
};

const UserModal = ({ onClose, user, users, roles, accountOwnerID }: Props) => {
  const [reassignRecordUser, setReassignRecordUser] = useState<
    SelectableUserOption | undefined
  >(undefined);
  const [options, setOptions] = useState<SelectableUserOption[]>([]);
  const confirmModal = useDisclosure();

  const [recordsPresent, setRecordsPresent] = useState(true);

  const [assignedRoles, setAssignedRoles] = useState<
    { roleID: string; name: string }[]
  >([]);

  const toast = useToast();
  const form = useForm({
    defaultValues: user,
  });

  const hasRecordsQuery = useApiQuery("user/hasRecords", {
    userID: user!.userID,
  });

  useEffect(() => {
    if (hasRecordsQuery.data && !hasRecordsQuery.error) {
      setRecordsPresent(hasRecordsQuery.data.hasRecords);
    }
    if (hasRecordsQuery.error) {
      setRecordsPresent(true);
      console.error(hasRecordsQuery.error);
    }
  }, [setRecordsPresent, hasRecordsQuery]);

  const [reassignedRecords, setReassignedRecords] = useState(false);

  const deleteUserMutation = useApiMutation("user/delete");

  const saveUserMutation = useApiMutation("user/updateRoles");

  const reassignUserMutation = useApiMutation("user/reassign");

  const handleSave = async () => {
    if (!user) return;
    await saveUserMutation
      .mutateAsync({
        userID: user.userID,
        roles: assignedRoles.map((role) => role.roleID),
      })
      .then((data) => {
        if (data) {
          toast.displayToast({
            title: "Success",
            status: "success",
            description: `Successfully Updated User`,
          });
          onClose(true);
        } else {
          toast.displayToast({
            title: "Error",
            status: "error",
            description: `Error updating user`,
          });
        }
      });
  };

  const handleReassign = async () => {
    const reassignToUserID = reassignRecordUser?.userID;
    if (!reassignToUserID || !user) {
      toast.displayToast({
        title: "Error",
        status: "error",
        description: `Please select a user to reassign records to`,
      });
      return;
    }
    await reassignUserMutation
      .mutateAsync({
        reassignToUserID,
        reassignFromUserID: user.userID,
      })
      .then((data) => {
        toast.displayToast({
          title: "Submitted",
          status: "success",
          description: (
            <VStack align="start" w="full" spacing="0">
              <Text>Successfully Reassigned</Text>
              <Text>Requirements: {data.requirements}</Text>
              <Text>Registers: {data.registers}</Text>
              <Text>Issues: {data.issues}</Text>
              <Text>Risks: {data.risks}</Text>
              <Text>Reports: {data.reports}</Text>
              <Text>Actions: {data.actions}</Text>
              <Text>Tasks: {data.instances}</Text>
            </VStack>
          ),
        });

        setReassignedRecords(true);
        hasRecordsQuery.refetch();
      })
      .catch((err) => {
        toast.displayToast({
          title: "Error",
          status: "error",
          description: `Error reassigning Records`,
        });
        console.error(err);
      });
  };

  const handleDelete = async () => {
    if (!user?.userID) {
      toast.displayToast({
        title: "Error",
        status: "error",
        description: `Error deleting user`,
      });
      return;
    }

    if (user?.userID === accountOwnerID) {
      toast.displayToast({
        title: "Error",
        status: "error",
        description: `Cannot delete account owner`,
      });
      return;
    }

    await deleteUserMutation
      .mutateAsync({ userID: user.userID })
      .then((data) => {
        if (data.success) {
          toast.displayToast({
            title: "Success",
            status: "success",
            description: `Successfully Deleted User`,
          });
          onClose(true);
        }
      })
      .catch((err) => {
        switch (err) {
          case "ACCOUNT_NOT_FOUND":
            toast.displayToast({
              title: "Account Not Found",
              status: "error",
              description: `There was an error finding the account`,
            });
            break;
          case "CANNOT_DELETE_ACCOUNT_OWNER":
            toast.displayToast({
              title: "Cannot Delete Account Owner",
              status: "error",
              description: `Please reassign the account owner before deleting this user`,
            });
            break;
          default:
            toast.displayToast({
              title: "Error",
              status: "error",
              description: `Error Deleting User`,
            });
            break;
        }
        console.error(err);
      });
  };

  useEffect(() => {
    if (users && user) {
      const userRoles = Array.from(
        user.roles.reduce<Set<string>>((acc, curr) => {
          acc.add(curr.roleID);
          return acc;
        }, new Set()),
      );

      setAssignedRoles(
        (roles ?? []).filter((role) => userRoles.includes(role.roleID)),
      );

      const opts: SelectableUserOption[] = users
        .filter((u) => u.userID !== user.userID)
        .map(({ userID, firstName, lastName, email }) => ({
          userID,
          email,
          name: `${firstName} ${lastName}`,
        }))
        .sort((a, b) => a.name.localeCompare(b.name));

      setOptions(opts);
    }
  }, [users, roles, user]);

  const mutationIsLoading =
    reassignUserMutation.isLoading ||
    deleteUserMutation.isLoading ||
    saveUserMutation.isLoading;

  return (
    <chakra-scope>
      <ModalHeader>User Details</ModalHeader>
      <ModalCloseButton
        isDisabled={mutationIsLoading}
        onClick={() => onClose()}
      />
      <FormProvider {...form}>
        <chakra.form onSubmit={form.handleSubmit(handleSave)}>
          <ModalBody>
            <Stack spacing={4}>
              <ShortTextField
                name="email"
                isReadOnly
                fieldProps={{
                  label: "Email",
                  maxLength: 64,
                }}
              />
              <HStack w="full" align="start">
                <ShortTextField
                  name="firstName"
                  isReadOnly
                  fieldProps={{
                    label: "First Name",
                    maxLength: 64,
                  }}
                />
                <ShortTextField
                  name="lastName"
                  isReadOnly
                  fieldProps={{
                    label: "Last Name",
                    maxLength: 64,
                  }}
                />
              </HStack>
              <div>
                <FormLabel>Status</FormLabel>
                <Input
                  value={
                    user ? getUserAccountStatus(user.userAccountStatus) : ""
                  }
                  isReadOnly
                />
              </div>
              <FormControl>
                <FormLabel htmlFor="roles">Roles</FormLabel>
                <Select
                  multiselect
                  options={roles ?? []}
                  getKey={(opt) => opt.roleID}
                  getLabel={(opt) => opt.name}
                  value={assignedRoles}
                  onChange={(sel) => setAssignedRoles(sel)}
                  placeholderText="No roles"
                  name="roles"
                />
              </FormControl>
              <Divider w="full" />
              <HStack alignItems="end">
                <FormControl>
                  <FormLabel>Reassign Records</FormLabel>
                  <HStack>
                    <Select
                      value={reassignRecordUser}
                      placeholderText="Select user to reassign records to"
                      isDisabled={
                        reassignedRecords ||
                        hasRecordsQuery.isLoading ||
                        hasRecordsQuery.isFetching ||
                        !recordsPresent ||
                        mutationIsLoading
                      }
                      getKey={(option) => option.userID}
                      getLabel={(option) => option.name}
                      options={[...options]}
                      onChange={(value) => setReassignRecordUser(value)}
                      renderOptionWithWrapper
                      renderOption={(renderProps) => (
                        <HStack as="span" flexWrap="wrap">
                          <span>{renderProps.option.name}</span>
                          <Text
                            size="xs"
                            fontStyle="italic"
                            color="blackAlpha.600">
                            ({renderProps.option.email})
                          </Text>
                        </HStack>
                      )}
                    />
                    <Button
                      isDisabled={
                        mutationIsLoading ||
                        reassignedRecords ||
                        !reassignRecordUser
                      }
                      variant={"outline"}
                      isLoading={reassignUserMutation.isLoading}
                      onClick={handleReassign}>
                      Reassign
                    </Button>
                  </HStack>

                  {!mutationIsLoading && (
                    <FormHelperText>
                      {recordsPresent
                        ? "Reassign all records, actions, reports and tasks from this user to another user."
                        : "This user is not responsible for any records, actions, reports or tasks."}
                    </FormHelperText>
                  )}
                </FormControl>
              </HStack>
              {user!.userID !== accountOwnerID ? (
                <Box>
                  <Tooltip
                    label={
                      recordsPresent
                        ? "User can't be deleted while they have records assigned to them."
                        : "Delete this user from the Account"
                    }>
                    <Button
                      isDisabled={mutationIsLoading || recordsPresent}
                      variant={"outline"}
                      colorScheme="red"
                      size="sm"
                      onClick={() => confirmModal.onOpen()}
                      isLoading={deleteUserMutation.isLoading}>
                      Delete User
                    </Button>
                  </Tooltip>
                </Box>
              ) : null}
            </Stack>
            <ConfirmDeleteModal
              isOpen={confirmModal.isOpen}
              onClose={confirmModal.onClose}
              onDelete={handleDelete}
              loading={deleteUserMutation.isLoading}
            />
          </ModalBody>
          <ModalFooter>
            <ButtonGroup>
              <Button
                isDisabled={mutationIsLoading}
                colorScheme="gray"
                onClick={() => onClose()}>
                Close
              </Button>
              <Button
                isDisabled={mutationIsLoading}
                colorScheme="brand"
                isLoading={saveUserMutation.isLoading}
                onClick={handleSave}>
                Save
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </chakra.form>
      </FormProvider>
    </chakra-scope>
  );
};

export default UserModal;
