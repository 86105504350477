import {
  Button,
  chakra,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Input,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  UnorderedList,
  UseDisclosureReturn,
  VStack,
} from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { Auth } from "aws-amplify";
import { FormProvider, useForm } from "react-hook-form";
import { z } from "zod";
import ShortTextField from "../../../../components/chakra/form/formComponents/ShortTextField";
import { isAxiosError, post } from "../../../../utilities/apibelRequest";
import useToast from "../../../../utilities/useToast";

type Props = {
  modalState: UseDisclosureReturn;
  onRefresh: () => void;
};

const schema = z.object({
  newEmail: z.string().email(),
  confirmNewEmail: z.string().email(),
  password: z.string(),
});

export default function ChangeEmailModal({ modalState, onRefresh }: Props) {
  const { displayToast } = useToast();

  const form = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: zodResolver(schema),
    defaultValues: {
      newEmail: "",
      confirmNewEmail: "",
      password: "",
    },
  });

  const onSubmit = async () => {
    const { newEmail, confirmNewEmail, password } = form.getValues();
    if (newEmail !== confirmNewEmail) {
      form.setError("confirmNewEmail", {
        message: "Emails don't match",
      });
      return;
    }
    try {
      /* AWS Amplify does not have a method to check the password, but calling ChangePassword with the existing password
              will throw an error if it is incorrect, or change it to itself if its correct. */
      const user = await Auth.currentAuthenticatedUser();
      await Auth.changePassword(user, password, password);
      // Change email on our end & in cognito
      await post("userSettings/updateEmail", { email: newEmail });

      displayToast({
        status: "success",
        title: "Email updated",
      });
      onRefresh();
      modalState.onClose();
    } catch (error) {
      onRefresh();
      if (isAxiosError(error)) {
        if (error.response?.data.error === "EMAIL_EXISTS") {
          displayToast({
            status: "error",
            title: "Failed to change Email",
            description: "Email already in use",
          });
        }
      } else {
        displayToast({
          status: "error",
          title: "Failed to change Email",
          description: (error as Error)?.message || "Something went wrong",
        });
      }
    }
  };

  const isBusy = false;
  return (
    <Modal
      onCloseComplete={form.reset}
      closeOnEsc={!isBusy}
      closeOnOverlayClick={!isBusy}
      isOpen={isBusy || modalState.isOpen}
      onClose={modalState.onClose}>
      <ModalOverlay />
      <chakra-scope>
        <ModalContent>
          <FormProvider {...form}>
            <chakra.form onSubmit={form.handleSubmit(onSubmit)}>
              <ModalHeader>Change Email</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <VStack w="full" align="start">
                  <ShortTextField
                    fieldProps={{ label: "New Email", isRequired: true }}
                    {...form.register("newEmail")}
                    inputPropsOverride={{ type: "email" }}
                  />
                  <ShortTextField
                    fieldProps={{
                      label: "Confirm New Email",
                      isRequired: true,
                    }}
                    {...form.register("confirmNewEmail")}
                    inputPropsOverride={{ type: "email" }}
                  />
                  <ShortTextField
                    fieldProps={{
                      label: "Password",
                      isRequired: true,
                      helperText:
                        "Please verify your password in order to change your email address.",
                    }}
                    {...form.register("password")}
                    inputPropsOverride={{ type: "password" }}
                  />
                </VStack>
              </ModalBody>
              <ModalFooter>
                <HStack>
                  <Button
                    onClick={modalState.onClose}
                    variant="solid"
                    colorScheme="gray">
                    Cancel
                  </Button>
                  <Button
                    isDisabled={!form.formState.isValid}
                    loadingText="Updating Email"
                    isLoading={form.formState.isSubmitting}
                    type="submit">
                    Change Email
                  </Button>
                </HStack>
              </ModalFooter>
            </chakra.form>
          </FormProvider>
        </ModalContent>
      </chakra-scope>
    </Modal>
  );
}
