import { Box, Skeleton, Tooltip } from "@chakra-ui/react";
import React from "react";
import {
  CustomFieldRecord,
  CustomFieldType,
  SingleSelectListCustomFieldRecord,
  MultiSelectListCustomFieldRecord,
} from "../../../shared/v2/definitions/customFields";
import { useApiQuery } from "../../../utilities/apibelRequest";
import { List } from "../../../shared/v2/definitions/lists";
import { formatDateFromDBToReadOnly } from "../../Forms/Field/Date";
import Spinner from "../common/Spinner";
import * as CustomFieldTypeID from "../../../shared/v2/constants/CustomFieldTypeID";
import RichText from "../form/RichText";

type ReadOnlyListFieldProps = {
  values: (string | null)[];
  listID: string;
};

const ReadOnlyListField = ({ values, listID }: ReadOnlyListFieldProps) => {
  // Get the list data
  const listData = useApiQuery("customFields/getListByID", { ID: listID });
  if (listData.isLoading) {
    return <Skeleton height="1em" m={1} />;
  }
  if (
    listData.isError ||
    listData.data === undefined ||
    listData.data === null
  ) {
    return "(failed to fetch list)";
  }
  const list = listData.data;
  // Filter out null and undefined as an otherwise empty multiselect will usually include a null
  const filteredValue: string[] = values.filter(
    (item) => item !== null && item !== undefined,
  ) as string[];
  if (filteredValue.length <= 0) return null;
  return filteredValue
    .map((item) => {
      const idx = list.options.findIndex((opt) => opt.id === item);
      if (idx >= 0) {
        const opt = list.options[idx];
        if (opt && opt.value !== null) {
          return `${opt.value}`;
        }
      }
      return "(invalid option)";
    })
    .join(", ");
};

type Props = {
  unitLabel?: string | null;
  listID?: string | null;
  customFieldRecord: CustomFieldRecord | null | undefined;
  isInTable: boolean;
};

const ReadOnlyRecordField = ({
  customFieldRecord,
  unitLabel,
  listID,
  isInTable,
}: Props) => {
  const getFieldValue = (): JSX.Element | string | null => {
    if (
      customFieldRecord === null ||
      customFieldRecord === undefined ||
      customFieldRecord.value === null ||
      customFieldRecord.value === undefined
    ) {
      return null;
    }
    switch (customFieldRecord.customFieldTypeID) {
      case CustomFieldTypeID.Date:
        return formatDateFromDBToReadOnly(customFieldRecord.value);
      case CustomFieldTypeID.Text:
        return customFieldRecord.value;
      case CustomFieldTypeID.LongText:
        if (isInTable && customFieldRecord.value.length > 12) {
          const str = `${customFieldRecord.value.slice(0, 8)}...`;
          return (
            <Tooltip
              bg="white"
              label={<RichText isReadOnly value={customFieldRecord.value} />}>
              <span>
                <RichText isReadOnly value={str} />
              </span>
            </Tooltip>
          );
        }
        return <RichText isReadOnly value={customFieldRecord.value} />;
      case CustomFieldTypeID.Number:
        // Weve already checked if its null above so can just put in a string
        return `${customFieldRecord.value}`;
      case CustomFieldTypeID.SingleSelectList:
      case CustomFieldTypeID.MultiselectList: {
        if (listID) {
          return <ReadOnlyListField listID={listID} values={customFieldRecord.value} />;
        }
        return "(invalid option)";
      }
      case CustomFieldTypeID.Link:
        return `${customFieldRecord.value}`;
      case CustomFieldTypeID.Upload:
      case CustomFieldTypeID.OnedriveUpload:
        return customFieldRecord.value === null ? "(No Upload)" : "(Uploaded)";
      default:
        try {
          assertNever(customFieldRecord);
        } catch (e) {
          console.error(
            `Unknown customFieldTypeID in table: ${
              (customFieldRecord as CustomFieldRecord).customFieldTypeID
            }`,
          );
          return null;
        }
    }
    return null;
  };
  const valueToDisplay = getFieldValue();
  return valueToDisplay === null ? (
    <Box as="span" fontStyle="italic" color="gray.400">
      No Value
    </Box>
  ) : (
    <Box as="span" color="gray.900">
      {valueToDisplay}
      {unitLabel !== null && unitLabel !== undefined ? ` ${unitLabel}` : ""}
    </Box>
  );
};

export default ReadOnlyRecordField;
