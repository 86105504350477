import { useState } from "react";
import { Box, Button, Text } from "@chakra-ui/react";
import { EditorContent } from "@tiptap/react";
import { useEditorWithExtensions } from "../_chakra/manual/components/useEditorWithExtensions";

type Props = {
  contentJSON: string;
  preview: string;
};

export default function RequirementManualRichText({
  contentJSON,

  preview,
}: Props) {
  const readonlyEditor = useEditorWithExtensions({
    content: contentJSON.length >= 1 ? JSON.parse(contentJSON) : undefined,
    editable: false,
    placeholder: "No content",
    limit: 20000,
  });

  const [viewMore, setViewMore] = useState(false);


  if (preview.length < 255 && (preview.split("\n").length <= 2)) {
    return (
      <Text className="manual-editor" as="div">
        <EditorContent editor={readonlyEditor} />
      </Text>
    );
  }

  return (
    <Box>
      {viewMore ? (
        <Text className="manual-editor" as="div">
          <EditorContent editor={readonlyEditor} />
        </Text>
      ) : (
        <Text className="manual-editor" as="div" noOfLines={2}>
          <EditorContent editor={readonlyEditor} />
        </Text>
      )}
      <Button variant="link" onClick={() => setViewMore((prev) => !prev)}>
        {viewMore ? "view less" : "view more"}
      </Button>
    </Box>
  );
}
