import { cardAnatomy } from "@chakra-ui/anatomy";
import {
  createMultiStyleConfigHelpers,
  cssVar,
  defineStyle,
} from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(cardAnatomy.keys);
const $padding = cssVar("card-padding");

const variants = {
  ghost: definePartsStyle({
    container: {
      border: "none",
    },
  }),
};

const defaultProps = {
  variant: "ghost",
} as const;

const baseStyle = definePartsStyle({
  body: {
    paddingLeft: $padding.reference,
    paddingBottom: $padding.reference,
    paddingRight: $padding.reference,
    "&:first-child": {
      paddingTop: $padding.reference, // Only apply the top padding if theres no header
    },
    "&:not(first-child)": {
      paddingTop: "0",
    },
  },
});

export default {
  components: {
    Card: defineMultiStyleConfig({ variants, defaultProps, baseStyle }),
  },
};
