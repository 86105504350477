import { RecordObjectTypeGroupNames } from "../constants/ObjectTypeGroupID";

/**
 * Permissions are in the form on an array of object type ids
 * Create only has all, other permissions can be all or self
 */
export type ObjectTypePermission = {
  CREATE: {
    ALL: string[];
    SELF: null;
  };
  DELETE: {
    ALL: string[];
    SELF: string[];
  };
  READ: {
    ALL: string[];
    SELF: string[];
  };
  UPDATE: {
    ALL: string[];
    SELF: string[];
  };
};

/**
 * Create only has all, other permissions can be all or self
 */
export type SimpleCRUDPermission = {
  CREATE: { ALL: boolean; SELF: null };
  READ: { ALL: boolean; SELF: boolean };
  DELETE: { ALL: boolean; SELF: boolean };
  UPDATE: { ALL: boolean; SELF: boolean };
};
/**
 * Note and Time Note permissions have CREATE/READ/DELETE but no UPDATE
 */
export type NotePermission = Omit<SimpleCRUDPermission, "UPDATE"> & {
  UPDATE: null;
};

/**
 * Manual permissions have all permissions but no READ
 */
export type ManualPermission = Omit<SimpleCRUDPermission, "READ"> & {
  READ: null;
};

/**
 * Account settings is just one permissions
 */
export type AccountSettingsPermission = boolean;

export type PermissionObject = {
  ACCOUNT_SETTINGS: AccountSettingsPermission;
  USER_SETTINGS: SimpleCRUDPermission;
  REPORTS: SimpleCRUDPermission;
  NOTES: NotePermission;
  TIME_NOTES: NotePermission;
  MANUAL: ManualPermission;
  OBJECTS: {
    REGISTER: ObjectTypePermission;
    REQUIREMENT: ObjectTypePermission;
    RISK: ObjectTypePermission;
    ACTION: ObjectTypePermission;
    ISSUE: ObjectTypePermission;
  };
  INFO: {
    accountID: string;
    userID: string;
    isUserAccountActive: boolean;
    isSystem: boolean;
  };
  // Admin permissions for creating accounts, sending notifs, ect.
  CC_ADMIN?: {
    ADMIN_ACCOUNTS: {
      UPDATE: boolean;
      CREATE: boolean;
      READ: boolean;
    };
  };
};

export type PermissionObjectTypeGroupKey = keyof PermissionObject["OBJECTS"];

export const PermissionObjectTypeGroupKeys: PermissionObjectTypeGroupKey[] = [
  "REGISTER",
  "REQUIREMENT",
  "RISK",
  "ACTION",
  "ISSUE",
];

export const isValidObjectTypeGroupKey = (
  value: string
): value is PermissionObjectTypeGroupKey =>
  (PermissionObjectTypeGroupKeys as string[]).includes(value);

export const objectTypeGroupToPermissionKey = (
  objectTypeGroup: RecordObjectTypeGroupNames
) => {
  const upper = objectTypeGroup.toUpperCase();
  if (isValidObjectTypeGroupKey(upper)) {
    return upper;
  }
  throw new Error("Invalid object type");
};

export type PermissionObjectTypeKey = keyof ObjectTypePermission;

export const PermissionObjectTypeKeys: PermissionObjectTypeKey[] = [
  "CREATE",
  "DELETE",
  "READ",
  "UPDATE",
];
