import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Stack,
  VStack,
  Icon,
} from "@chakra-ui/react";
import { RiFileAddLine } from "react-icons/ri";
import React, { useState } from "react";
import {
  useLocation,
  useNavigate,
  useParams,
  Link as BrowserLink,
} from "react-router-dom";
import { useAppState } from "../../components/App/AppProvider";
import FullLoader from "../../components/chakra/FullLoader";
import { BreadcrumbElement } from "../../components/chakra/Breadcrumbs";
import { Card, CardContent, CardHeading } from "../../components/chakra/card";
import ChakraScreenContainer from "../../components/chakra/ChakraScreenContainer";
import { useApiQuery } from "../../utilities/apibelRequest";
import NotFoundScreen from "../NotFound";
import RegisterTable from "../Register/RegisterTable";
import RecordsViewTable from "../../components/chakra/common/RecordsView/RecordsViewTable";

const BREADCRUMBS: BreadcrumbElement[] = [
  {
    label: "Registers",
    link: "/register",
  },
];

const RegisterDashboardScreen = () => {
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { objectTypeID } = params;

  const pageDefinition = useApiQuery("register/pageByID", {
    ID: objectTypeID || "null",
  });

  if (!objectTypeID || pageDefinition.isError) {
    return <NotFoundScreen />;
  }

  let pageTitle: string | undefined;
  if (pageDefinition.isSuccess) {
    pageTitle = pageDefinition.data.namePlural;
  } else {
    pageTitle = location.state?.registerName;
  }
  return (
    <chakra-scope>
      <ChakraScreenContainer
        contentSize="full"
        headerEndContent={
          <Button
            role="link"
            size="sm"
            variant="solid"
            onClick={() => navigate(`/register/new?type=${objectTypeID}`)}
            leftIcon={<Icon as={RiFileAddLine} />}>
            New {pageDefinition?.data?.name}
          </Button>
        }
        pageTitle={pageTitle ? `${pageTitle}` : "Registers"}
        breadcrumbs={BREADCRUMBS}>
        <RecordsViewTable
          pageDefinition={pageDefinition.data}
          objectTypeID={objectTypeID}
        />
      </ChakraScreenContainer>
    </chakra-scope>
  );
};

export default RegisterDashboardScreen;
