import { Upload } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import * as Feather from "react-feather";
import { Link } from "react-router-dom";
import { Buffer } from "buffer";
import { del, put } from "../../../utilities/request";
import useToast from "../../../utilities/useToast";
import { useAuthState } from "../../App/AppProvider";
import { Loading } from "../../UI/Loading/Loading";
import Icon from "../../UI/Icon";

const typesAccepted =
  "application/pdf, image/png, image/jpg, image/jpeg, application/msword, image/gif, .doc, .docx, application/vnd.openxmlformats-officedocument.wordprocessingml.document, .dot, .wbk, .docm, .dotx, .dotm, .docb, .xls, .xltm, .xlm, .xlsx, .xlsm, .xltx, .xltm, .xlsb, .xla, .xlam, .xll, .xlw, .ppt, .pot, .pps, .pptx, .pptm, .potx, .potm, .ppam, .ppsx, .ppsm, .sldx, .sldm, .one, .pub, .xps";

export const fileNameRegex = (fileName: string) => {
  return fileName.replace(/[\u0250-\ue007]/g, "");
};

const UploaderGetType = (type: any) => {
  let Icon: any = null;
  let imagetype = "";
  if (type === "png" || type === "jpg" || type === "jpeg" || type === "gif") {
    imagetype = "Image";
  } else if (type === "doc" || type === "docx") {
    imagetype = "FileText";
  } else {
    imagetype = "File";
  }
  Object.keys(Feather)
    .filter((icon: string) => icon !== "EyeOff")
    .map((icon: any) => {
      if (icon === imagetype) {
        const IconType: keyof typeof Feather = icon;
        Icon = Feather[IconType];
        return <Icon />;
      }
    });
  return <Icon />;
};

const UploaderParseType = (type: any) => {
  if (
    type ===
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
  ) {
    return "application/msword";
  }
  return type;
};

interface UploaderProps {
  fileList: any[];
  uploading: boolean;
  previewVisible?: boolean;
  previewImage?: string;
  onChange: (event: any) => void;
  readOnly: boolean;
  disabled?: boolean;
  name?: string;
  limitTypes?: string;
  limitTypesExplanation?: string;
  limitSize?: number;
  limitUploadNumber?: number;
}

interface LoadingProps {
  isAction: boolean;
  description: string;
}

const Uploader = (props: UploaderProps): any => {
  const authState = useAuthState();
  const [files, setFiles] = useState<any>({
    fileList: props.fileList
      ? props.fileList.length > 0
        ? props.fileList
        : []
      : [],
    uploading: false,
    previewVisible: false,
    previewImage: "",
  });
  const [isLoading, setLoading] = useState<LoadingProps>({
    isAction: false,
    description: "",
  });
  const { displayToast } = useToast();

  useEffect(() => {
    // Handle the file buffer and uploading
    const logger = async () => {
      if (files.fileList.length > 0) {
        if (
          props.limitUploadNumber === undefined ||
          props.limitUploadNumber === files.fileList.length
        ) {
          await Promise.all(
            files.fileList.map(async (file: any) => {
              // Ensure file has not already been uploaded before
              if (!file.status) {
                if (typeof file.arrayBuffer === "function") {
                  if (
                    !props.limitSize ||
                    (props.limitSize && file.size <= props.limitSize)
                  ) {
                    const content = await file.arrayBuffer();
                    if (file.buffer === undefined) {
                      // Prepare a buffer
                      const prestring = `data:${UploaderParseType(
                        file.type,
                      )};base64,`;
                      const poststring =
                        Buffer.from(content).toString("base64");
                      file.buffer = prestring.concat(poststring);

                      // Upload to S3
                      setLoading({ isAction: true, description: "Processing" });
                      const fileName = fileNameRegex(file.name);
                      try {
                        file.isUploading = true;
                        // Reserve a GUID, get an Upload Link
                        const signedUrl = await put(`uploadfile`, {
                          name: fileName,
                          type: file.type,
                          documentRefTable: null,
                          documentRefID: null,
                        });

                        // Upload file to S3
                        const options = {
                          headers: {
                            "Content-Type": `${file.type}`,
                            "Content-Disposition": `attachment; filename="${fileName}"`,
                          },
                        };
                        const buffer = Buffer.from(
                          file.buffer.split("base64,")[1],
                          "base64",
                        ); // Needed to remove junk before our core data, without this word documents are corrupted

                        setLoading({
                          isAction: true,
                          description: "Uploading",
                        });

                        const req = await axios.put(
                          signedUrl.data.UploadURL,
                          buffer,
                          options,
                        );

                        // Clean up file, add our DocumentID and remove the buffer so it is not sent to the back end
                        file.DocumentID = signedUrl.data.DocumentID;
                        file.buffer = "";
                        file.isUploading = false;

                        // Before changing the loading status, check no other file is still being uploaded
                        const aFileIsStillUploading = files.fileList.find(
                          (file: any) => file.isUploading,
                        );
                        if (!aFileIsStillUploading) {
                          setLoading({
                            isAction: false,
                            description: "Uploading",
                          });
                        }
                      } catch (error) {
                        console.log(error);
                        displayToast({
                          status: "error",
                          title: "Failed to upload file",
                        });
                        setLoading({
                          isAction: false,
                          description: "Uploading",
                        });
                      }
                    }
                  } else {
                    setFiles((state: any) => ({
                      ...files,
                      fileList: [],
                    }));
                    props.onChange({ fileList: [] });

                    // file is too big for logo
                    displayToast({
                      status: "error",
                      title: "Failed to upload",
                      description: "File is too big to be an account logo",
                    });
                  }
                }
              }
            }),
          );
        } else {
          // more than one logo was attempted for upload
          displayToast({
            status: "error",
            title: "Failed to upload",
            description: `You can only upload ${props.limitUploadNumber} logo per account`,
          });
        }
      }
    };

    logger();
  }, [files]);

  return props.readOnly ? (
    // return the links
    props.fileList ? (
      props.fileList.length > 0 ? (
        props.previewVisible ? (
          <img style={{ width: "50%" }} src={props.fileList[0].url} />
        ) : (
          props.fileList.map((file: any, index: number) => (
            <p>
              {UploaderGetType(file.type)}
              <a
                key={file.uid}
                rel="noreferrer"
                target="_blank"
                href={file.url}>
                {" "}
                {file.name}{" "}
              </a>
            </p>
          ))
        )
      ) : (
        <Form.Label>0 files uploaded</Form.Label>
      )
    ) : (
      <Form.Label>0 files uploaded</Form.Label>
    )
  ) : (
    <div>
      <span>
        <div
          className="ant-upload ant-upload-drag"
          style={{ color: "#6b52f8" }}>
          <span
            tabIndex={0}
            className="ant-upload ant-upload-btn"
            role="button"
            style={{ color: "#6b52f8", display: "block" }}>
            <Upload
              multiple
              accept={props.limitTypes ? props.limitTypes : typesAccepted}
              onChange={props.onChange}
              fileList={props.fileList || []}
              disabled={props.disabled}
              name={props.name}
              showUploadList
              onRemove={(file: any) => {
                // In this case we have already uploaded the document to s3, but the user has canceled it before we can connect the document to the record
                if (file.originFileObj && file.originFileObj.DocumentID) {
                  new Promise(async (resolve, reject) => {
                    setLoading({ isAction: true, description: "Deleting" });
                    try {
                      await del(
                        `document/${file.originFileObj.DocumentID}`,
                        authState,
                      );
                      setLoading({ isAction: false, description: "Deleting" });

                      setFiles((state: any) => {
                        const index = state.fileList.indexOf(file);
                        const newFileList = state.fileList.slice();
                        newFileList.splice(index, 1);
                        return {
                          ...files,
                          fileList: newFileList,
                        };
                      });
                      resolve(false);
                    } catch (error) {
                      displayToast({
                        status: "error",
                        title: "Failed to delete document",
                      });
                      setLoading({ isAction: false, description: "Deleting" });
                      resolve(false);
                    }
                  });
                }

                setFiles((state: any) => {
                  const index = state.fileList.indexOf(file);
                  const newFileList = state.fileList.slice();
                  newFileList.splice(index, 1);
                  return {
                    ...files,
                    fileList: newFileList,
                  };
                });
              }}
              onPreview={async (file: any) => {
                window.open(file.url);
              }}
              beforeUpload={(file: any) => {
                // here we need to scan for viruses
                // then see if extension is modified as well or it is an executable
                setFiles((state: any) => ({
                  ...files,
                  fileList: [...state.fileList, file],
                }));

                return false;
              }}>
              {isLoading.isAction ? (
                <>
                  <Row>
                    <Col>
                      <Loading noBorder={false} />
                    </Col>
                  </Row>
                  <p className="ant-upload-text">
                    {isLoading.description}, please wait
                  </p>
                </>
              ) : (
                <>
                  <p
                    className="ant-upload-drag-icon"
                    style={{ color: "#6b52f8" }}>
                    <Icon name="Inbox" size="lg" style={{ color: "#6b52f8" }} />
                  </p>
                  <p style={{ color: "var(--chakra-colors-gray-500)" }}>
                    Click or drag file to this area to upload
                  </p>
                  <p style={{ color: "var(--chakra-colors-gray-500)" }}>
                    Accepts files of type:{" "}
                    {props.limitTypesExplanation
                      ? props.limitTypesExplanation
                      : ".pdf, .png, .jpeg, .jpg, .gif, all Microsoft Office types"}
                  </p>
                  <p style={{ color: "var(--chakra-colors-gray-500)" }}>
                    Files are only saved when 'Submit' is pressed
                  </p>
                </>
              )}
            </Upload>
          </span>
        </div>
      </span>
    </div>
  );
};
export { Uploader, UploaderGetType, UploaderParseType, typesAccepted };
