import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Textarea } from "../Field/Textarea";

interface MandatoryIndicatorProps {
  ID: string;
  elementType: "field" | "question";
  pageStatus?: string;
}

export const MandatoryIndicator = (props: MandatoryIndicatorProps) =>
  !props.pageStatus ||
  props.pageStatus === "New" ||
  props.pageStatus === "Editing" ? (
    <span>
      <OverlayTrigger
        placement="auto"
        overlay={
          <Tooltip id={`tooltip-mandatory-${props.ID}`}>
            <Textarea
              value={`This ${props.elementType} is mandatory`}
              name={`tooltip-text-mandatory-${props.ID}`}
              readOnly
              richText
            />
          </Tooltip>
        }>
        <small className="light-font"> *</small>
      </OverlayTrigger>
    </span>
  ) : (
    <></>
  );
