import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Col, Dropdown, Form, Row } from "react-bootstrap";
import * as Feather from "react-feather";
import { useAppState } from "../../../components/App/AppProvider";
import { RefreshWorkflowMaxData } from "../../../components/UI/Job/RefreshWorkflowMaxData";
import { Loading } from "../../../components/UI/Loading/Loading";
import { isJobsAccount } from "../../../constants/JobsVisibleCheck";
import workflowMaxLogo from "../../../images/workflowMaxLogo.png";
import { del, get, put } from "../../../utilities/request";
import useToast from "../../../utilities/useToast";

type WorkflowMaxIntegrationStatus = "Loading" | "Ready" | "Editing";

const WorkflowMaxIntegration = () => {
  const appState = useAppState();
  const { auth, app } = appState;
  const currentAccountID = app.accountInfo?.accountID;
  const [state, setState] = React.useState<any>({
    Connected: false,
    WorkflowMaxAccountKey: "",
  });
  const [pageStatus, setPageStatus] =
    useState<WorkflowMaxIntegrationStatus>("Loading");
  const { displayToast } = useToast();

  const getIntegrationStatus = async () => {
    const info: any = await get("settings/job", auth);
    setState({
      ...state,
      Connected: info.data.connection !== null,
      connection: info.data.connection,
      url: info.data.url,
    });
    // console.log('info', info)
    setPageStatus("Ready");
  };

  const disableIntegration = async () => {
    if (!currentAccountID) {
      return;
    }
    const result = window.confirm(
      "WAIT: Disabling the integration will result in ALL existing Jobs and their children being deleted, are you sure you want to do this? ",
    );
    if (result) {
      setPageStatus("Loading");

      const deleteResults = await del(`settings/job/${currentAccountID}`, auth);
      // console.log(deleteResults)

      if (deleteResults.data.length === 0) {
        displayToast({
          status: "success",
          title: "WorkflowMax Integrations successfully disabled",
        });
      } else {
        displayToast({
          status: "error",
          title: "Disabling Integraton Error",
        });
      }
      getIntegrationStatus();
    }
  };

  useEffect(() => {
    getIntegrationStatus();
  }, []);

  return (
    <Formik
      initialValues={state}
      onSubmit={async (values, actions) => {
        setPageStatus("Loading");
        const putresult: any = await put("settings/job", values, auth);

        if (putresult.data.error) {
          displayToast({
            status: "error",
            title: putresult.data.error,
          });
        } else {
          setState({ ...state, Connected: true });

          displayToast({
            status: "success",
            title: "WorkflowMax Account Key updated successfully",
          });
        }

        setPageStatus("Ready");
      }}
      render={(formikProps) => (
        <Form onSubmit={formikProps.handleSubmit}>
          <Row>
            <Col sm="3" style={{ textAlign: "center" }}>
              <img
                src={workflowMaxLogo}
                alt="workflowMaxLogo"
                style={{ width: "200px" }}
              />
            </Col>
            <Col sm="9" style={{ textAlign: "center" }}>
              {pageStatus === "Loading" ? <Loading /> : null}

              {pageStatus === "Ready" && !state.Connected ? (
                <a href={isJobsAccount(appState) ? state.url : ""}>
                  <Button
                    variant="outline-dark"
                    disabled={!isJobsAccount(appState)}>
                    Set Up
                  </Button>
                </a>
              ) : null}

              {pageStatus === "Ready" && state.Connected ? (
                <>
                  <RefreshWorkflowMaxData />

                  <Button
                    style={{ marginLeft: "20px" }}
                    variant="outline-success">
                    Connected
                  </Button>

                  <Button
                    style={{ marginLeft: "20px" }}
                    variant="outline-dark"
                    onClick={() => disableIntegration()}>
                    Disconnect & Delete
                  </Button>
                </>
              ) : null}
            </Col>
          </Row>

          {state.Connected ? <FrequencySetter /> : null}

          <section />
        </Form>
      )}
    />
  );
};

const FrequencySetter = (props: any) => {
  const { auth } = useAppState();
  const { displayToast } = useToast();

  const [state, setState] = React.useState<any>(null);
  const [pageStatus, setStatus] = React.useState<any>("Loading");

  React.useEffect(() => {
    const getFrequencyData = async () => {
      try {
        const info: any = await get("settings/job/frequency", auth);
        setState({ NotificationSettings: info.data });
      } catch (err) {
        displayToast({
          status: "error",
          title: "Failed to retrieve WorkflowMax frequency details",
        });
      }
    };

    getFrequencyData();
  }, []);

  const submitNewFrequency = async (values: any) => {
    setStatus("Loading");

    if (values.NotificationSettings.NotificationSendOn === -1) {
      values.NotificationSettings.ActionRecurrenceID =
        values.NotificationSettings.NotificationOptions.recurrenceOptions[0].ActionRecurrenceID; // Used to keep foriegn key constraint happy
    }

    const result: any = await put("settings/job/frequency", values, auth);

    if (result.status === 200) {
      displayToast({
        status: "success",
        title: "Successfully updated WorkflowMax frequency details",
      });
    } else {
      displayToast({
        status: "error",
        title: "Failed to update WorkflowMax frequency details",
      });
    }

    setStatus("Ready");
  };

  if (state) {
    return (
      <Formik
        initialValues={state}
        onSubmit={async (values, actions) => {}}
        render={(formikProps) => (
          <Form.Group
            as={Row}
            style={{ marginTop: "20px" }}
            className="largeText">
            <Form.Label column sm="3" style={{ textAlign: "center" }}>
              Refresh Frequency Settings
            </Form.Label>
            <Col sm="7" style={{ display: "inline-block" }}>
              {pageStatus === "Editing" ? (
                <>
                  {formikProps.values.NotificationSettings
                    .NotificationSendOn !== -1 ? (
                    <span>Refresh on a</span>
                  ) : null}

                  <Dropdown
                    onSelect={(e: any) => {
                      e = JSON.parse(e);

                      formikProps.setFieldValue(
                        "NotificationSettings.ActionRecurrenceText",
                        e.key,
                      );
                      formikProps.setFieldValue(
                        "NotificationSettings.ActionRecurrenceID",
                        e.value,
                      );

                      if (e.key !== "Once Off") {
                        formikProps.setFieldValue(
                          "NotificationSettings.NotificationOnceOff",
                          null,
                        ); // Remove when not in use
                      }

                      if (e.key === "Weekly") {
                        formikProps.setFieldValue(
                          "NotificationSettings.NotificationSendOn",
                          "2",
                        ); // Default to Monday
                      } else if (e.key === "Monthly" || e.key === "Daily") {
                        formikProps.setFieldValue(
                          "NotificationSettings.NotificationSendOn",
                          "1",
                        ); // Default to 1st
                      }

                      if (e.key === `Don't Refresh`) {
                        // formikProps.setFieldValue('NotificationSettings.RecordStatusID', RecordStatus.Deleted)
                        formikProps.setFieldValue(
                          "NotificationSettings.NotificationSendOn",
                          -1,
                        ); // "-1" marks the send on as dont sent
                      } else {
                        // formikProps.setFieldValue('NotificationSettings.RecordStatusID', RecordStatus.Active)
                      }
                    }}
                    className="inlineDropdown">
                    <Dropdown.Toggle
                      className="darkText"
                      variant="outline-dark"
                      id="dropdown-statusFilter">
                      {formikProps.values.NotificationSettings
                        .NotificationSendOn !== -1
                        ? formikProps.values.NotificationSettings
                            .ActionRecurrenceText
                          ? ` ${formikProps.values.NotificationSettings.ActionRecurrenceText} Recurrence`
                          : "Set Recurrence"
                        : `Don't Refresh`}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        eventKey={JSON.stringify({
                          value: null,
                          key: `Don't Refresh`,
                        })}
                        onSelect={() =>
                          formikProps.setFieldValue(
                            "NotificationSettings.NotificationSendOn",
                            -1,
                          )
                        }>
                        Don't Refresh
                      </Dropdown.Item>
                      {renderNotificationRecurrence(
                        formikProps.values.NotificationSettings
                          .NotificationOptions,
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                </>
              ) : formikProps.values.NotificationSettings.NotificationSendOn !==
                -1 ? (
                `WorfklowMax data will be refreshed ${formikProps.values.NotificationSettings.ActionRecurrenceText}`
              ) : (
                `Never refresh data`
              )}

              {formikProps.values.NotificationSettings.NotificationSendOn !==
                -1 &&
              formikProps.values.NotificationSettings.ActionRecurrenceText ===
                "Weekly" ? (
                pageStatus === "Editing" ? (
                  <>
                    <span>on</span>
                    <Dropdown
                      onSelect={(e: any) => {
                        e = JSON.parse(e);
                        formikProps.setFieldValue(
                          "NotificationSettings.NotificationSendOn",
                          e.value,
                        );
                      }}
                      className="inlineDropdown">
                      <Dropdown.Toggle
                        className="darkText"
                        variant="outline-dark"
                        id="dropdown-statusFilter">
                        {formikProps.values.NotificationSettings
                          .NotificationSendOn
                          ? mySqlDayOfWeekEquivilant(
                              formikProps.values.NotificationSettings
                                .NotificationSendOn,
                            )
                          : "Select day of week"}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          eventKey={JSON.stringify({
                            value: "2",
                            key: "Monday",
                          })}>
                          Monday
                        </Dropdown.Item>
                        <Dropdown.Item
                          eventKey={JSON.stringify({
                            value: "3",
                            key: "Tuesday",
                          })}>
                          Tuesday
                        </Dropdown.Item>
                        <Dropdown.Item
                          eventKey={JSON.stringify({
                            value: "4",
                            key: "Wednesday",
                          })}>
                          Wednesday
                        </Dropdown.Item>
                        <Dropdown.Item
                          eventKey={JSON.stringify({
                            value: "5",
                            key: "Thursday",
                          })}>
                          Thursday
                        </Dropdown.Item>
                        <Dropdown.Item
                          eventKey={JSON.stringify({
                            value: "6",
                            key: "Friday",
                          })}>
                          Friday
                        </Dropdown.Item>
                        <Dropdown.Item
                          eventKey={JSON.stringify({
                            value: "7",
                            key: "Saturday",
                          })}>
                          Saturday
                        </Dropdown.Item>
                        <Dropdown.Item
                          eventKey={JSON.stringify({
                            value: "1",
                            key: "Sunday",
                          })}>
                          Sunday
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </>
                ) : formikProps.values.NotificationSettings
                    .NotificationSendOn !== -1 ? (
                  ` on ${mySqlDayOfWeekEquivilant(
                    formikProps.values.NotificationSettings.NotificationSendOn,
                  )}`
                ) : null
              ) : null}

              {formikProps.values.NotificationSettings.NotificationSendOn !==
                -1 &&
              formikProps.values.NotificationSettings.ActionRecurrenceText ===
                "Monthly" ? (
                pageStatus === "Editing" ? (
                  <>
                    <span>on the</span>
                    <Dropdown
                      onSelect={(e: any) => {
                        e = JSON.parse(e);
                        formikProps.setFieldValue(
                          "NotificationSettings.NotificationSendOn",
                          e.value,
                        );
                      }}
                      className="inlineDropdown">
                      <Dropdown.Toggle
                        className="darkText"
                        variant="outline-dark"
                        id="dropdown-statusFilter">
                        {getOrdinal(
                          formikProps.values.NotificationSettings
                            .NotificationSendOn,
                        )}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>{renderMonthDropdown()}</Dropdown.Menu>
                    </Dropdown>
                  </>
                ) : formikProps.values.NotificationSettings
                    .NotificationSendOn !== -1 ? (
                  ` on the ${getOrdinal(
                    formikProps.values.NotificationSettings.NotificationSendOn,
                  )}`
                ) : null
              ) : null}
            </Col>
            <Col sm="2">
              {pageStatus === "Editing" ? (
                <Button
                  type="button"
                  variant="outline-dark"
                  onClick={() => {
                    setStatus("Editing");
                    submitNewFrequency(formikProps.values);
                  }}>
                  Save
                </Button>
              ) : (
                <Button
                  type="button"
                  variant="outline-dark"
                  onClick={() => {
                    setStatus("Editing");
                  }}>
                  <Feather.Edit2 className="feather" size="16" />
                  Edit
                </Button>
              )}
            </Col>
          </Form.Group>
        )}
      />
    );
  }
  return <></>;
};

// This is used instead of moment to replicate how mySQL will later use DAYOFWEEK() to verify a day
const mySqlDayOfWeekEquivilant = (dayOfWeek: any) => {
  switch (dayOfWeek.toString()) {
    case "1":
      return "Sunday";
    case "2":
      return "Monday";
    case "3":
      return "Tuesday";
    case "4":
      return "Wednesday";
    case "5":
      return "Thursday";
    case "6":
      return "Friday";
    case "7":
      return "Saturday";
    default:
      return "Unrecognised Date";
  }
};

const renderNotificationRecurrence = (NotificationOptions: any) =>
  NotificationOptions.recurrenceOptions.map((option: any, i: number) => {
    if (option.ActionRecurrenceText !== "Once Off") {
      return (
        <Dropdown.Item
          eventKey={JSON.stringify({
            value: option.ActionRecurrenceID,
            key: option.ActionRecurrenceText,
          })}>
          {option.ActionRecurrenceText}
        </Dropdown.Item>
      );
    }
  });

const getOrdinal = (i: number) => {
  const j = i % 10;
  const k = i % 100;
  if (j === 1 && k !== 11) {
    return `${i}st`;
  }
  if (j === 2 && k !== 12) {
    return `${i}nd`;
  }
  if (j === 3 && k !== 13) {
    return `${i}rd`;
  }
  return `${i}th`;
};

const renderMonthDropdown = () => {
  const result = [];
  for (let i = 1; i < 32; i++) {
    result.push(
      <Dropdown.Item eventKey={JSON.stringify({ value: i })}>
        {getOrdinal(i)}
      </Dropdown.Item>,
    );
  }
  return result;
};

export { WorkflowMaxIntegration };
