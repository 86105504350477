import { Center, Container, Heading, VStack, Button } from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";

declare global {
  namespace JSX {
    interface IntrinsicElements {
      "chakra-scope": React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement>,
        HTMLElement
      >;
    }
  }
}

const NotFoundScreen = () => (
  <chakra-scope>
    <Container pt="8%" maxW="container.lg">
      <Center w="full" h="full">
        <VStack spacing="4">
          <VStack>
            <Heading size="2xl">
              404
            </Heading>
            <Heading size="md">Page Not Found</Heading>
          </VStack>
          <Button variant="link" as={Link} to="/">
            Navigate Home
          </Button>
        </VStack>
      </Center>
    </Container>
  </chakra-scope>
);

export default NotFoundScreen;
