import { add, sub, set } from "date-fns";
import { formatDateForTask } from "./dateUtils";

type TaskDateInfo = {
  date: Date;
  displayDate: string;
  displayTime: string;
};

export type TaskWindow = {
  open: TaskDateInfo;
  close: TaskDateInfo;
  due: TaskDateInfo;
};

export function isTaskAvailable(taskWindow: TaskWindow): {
  isAvailable: boolean;
  isEarly: boolean;
  isLate: boolean;
} {
  const now = new Date();
  if (now < taskWindow.open.date) {
    return {
      isAvailable: false,
      isEarly: true,
      isLate: false,
    };
  }
  if (now > taskWindow.close.date) {
    return {
      isAvailable: false,
      isEarly: false,
      isLate: true,
    };
  }
  return {
    isAvailable: true,
    isEarly: false,
    isLate: false,
  };
}



function dateTo1159PreviousDay(date: Date): Date {
    const prev = sub(date, { days: 1 });
    return set(prev, { hours: 23, minutes: 59, seconds: 59 });
  }
  

/**
 *
 * @param task The task dates, as converted to the current timezone
 * @returns
 */
export function getTaskWindow(task: {
  instanceWhen: Date;
  windowBefore?: number;
  windowAfter?: number;
}): TaskWindow {
  console.log({
    instanceWhen: task.instanceWhen,
    windowBefore: task.windowBefore,
    windowAfter: task.windowAfter,
  });
  // Tasks are displayed as due on a day, but are actually set to midnight that night.
  // For example, a task due on the 15th of october is set to being due on the 16th at 00:00:00 (which is one second after the 15th ends)
  // So we subtract 1 second from it for display purposes
  const adjustedInstanceWhen = sub(task.instanceWhen, { seconds: 1 });

  // Task is open 24 hours before the 'instanceWhen' date, with an extra 24 hours for each 'days before'
  const openTime = sub(task.instanceWhen, { days: (task.windowBefore ?? 0) + 1 });
  // Task is closed exactly on the 'adjusted' instance when date, with an extra 24 hours for each 'days after'
  const closeTime = add(adjustedInstanceWhen, { days: task.windowAfter ?? 0 });

  // Task is due exactly on the instance when date, unless task is due between 12am and 3am (in which case it's due on the previous day at 11:59pm)
  const dueDate =
    adjustedInstanceWhen.getHours() < 3
      ? dateTo1159PreviousDay(adjustedInstanceWhen)
      : adjustedInstanceWhen;

  const open = formatDateForTask(openTime);
  const close = formatDateForTask(closeTime);
  const due = formatDateForTask(dueDate);

  return {
    open: {
      date: openTime,
      displayDate: open.displayDate,
      displayTime: open.displayTime,
    },
    close: {
      date: closeTime,
      displayDate: close.displayDate,
      displayTime: close.displayTime,
    },
    due: {
      date: dueDate,
      displayDate: due.displayDate,
      displayTime: due.displayTime,
    },
  };
}