const text_truncate = (str: string, length: number | null) => {
  if (length == null) {
    length = 100;
  }
  const delim = " ";
  const appendix = "...";
  if (str.length <= length) {
    return str;
  }
  let trimmedStr = str.substr(0, length + delim.length);
  const lastDelimIndex = trimmedStr.lastIndexOf(delim);
  if (lastDelimIndex >= 0) {
    trimmedStr = trimmedStr.substr(0, lastDelimIndex);
  }
  if (trimmedStr) {
    trimmedStr += appendix;
  }
  return trimmedStr;
};

const text_stripHTML = (str: string) => {
  const doc = new DOMParser().parseFromString(str, "text/html");
  return doc.body.textContent || "";
};
const toProperCase = (s: string) =>
  s.replace(
    /\w\S*/g,
    (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(),
  );

// https://emailregex.com/
const isValidEmail = (str: string) => {
  const regex =
    /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
  return regex.test(str);
};

export {text_truncate, toProperCase, text_stripHTML, isValidEmail};
