import React from "react";
import { ListIDToType } from "../../../constants/listType";
import { DateField } from "../Field/Date";
import { Number } from "../Field/Number";
import { Textarea } from "../Field/Textarea";
import { Text } from "../Field/Text";

const ListType = (props: any) => {
  if (ListIDToType[props.type] === "Date") {
    return (
      <DateField
        selected={props.value.ListValueDATETIME}
        name={props.name}
        onUpdate={props.onUpdate}
        readOnly={props.readOnly}
        disabled={props.disabled}
        allDates
      />
    );
  }
  if (ListIDToType[props.type] === "Number") {
    return (
      <Number
        value={props.value.ListValueINT}
        name={props.name}
        onUpdate={props.onUpdate}
        readOnly={props.readOnly}
        disabled={props.disabled}
      />
    );
  }
  if (ListIDToType[props.type] === "Text") {
    return (
      <Textarea
        value={props.value.ListValueVARCHAR}
        name={props.name}
        onUpdate={props.onUpdate}
        readOnly={props.readOnly}
        disabled={props.disabled}
        max={127}
        showCharacterLimit
      />
    );
  }
  if (ListIDToType[props.type] === "Document") {
    return (
      <Textarea
        value={props.value}
        name={props.name}
        onUpdate={props.onUpdate}
        readOnly={props.readOnly}
        disabled={props.disabled}
      />
    );
  }
  console.log("p", props);
  return null;
};

export { ListType };
