import React, { useEffect, useState, useRef } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
  Container,
  Button,
  Col,
  Form,
  Row,
  Dropdown,
  Collapse,
  DropdownButton,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import {
  PlusCircle,
  AlignLeft,
  Trash2,
  Move,
  Plus,
  Square,
  Layers,
  CloudDrizzle,
  Code,
  Sun,
  Mail,
  Type,
} from "react-feather";
import { State, Action } from "./QuestionWrapper.d";
import { HelpText } from "../HelpText/HelpText";
import * as CustomFieldType from "../../../constants/customFieldType";
import { CollapseChevron } from "../../UI/CollapseChevron/CollapseChevron";
import { Text } from "../Field/Text";
import { Select } from "../Field/Select";
import { Matrix } from "../Field/Matrix";
import * as ObjectStatus from "../../../shared/constants/objectStatus";
import * as QuestionType from "../../../shared/constants/questionType";
import * as RiskMatrixDimensionType from "../../../constants/riskMatrixDimensionType";
import { SingleSelectIcon } from "./singleSelect";
import useToast from "../../../utilities/useToast";

export const useFocus = () => {
  const htmlElRef = useRef<any>();
  const setFocus = () => {
    const currentEl = htmlElRef.current;
    // If the element is defined
    if (currentEl) {
      // If the value is empty, must be a new question so set focus to new Question Text
      if (currentEl.value === "") {
        currentEl && currentEl.focus();
      }
    }
  };
  return [htmlElRef, setFocus] as const;
};

const hasConditionalChildren = (
  questions: any[],
  questionID: string | null,
  responseID: string | number | null,
  questionIndex: number | null,
) => {
  const thisQuestion =
    questionIndex !== null
      ? questions[questionIndex]
      : questions.find((question: any) => question.id === questionID);
  const conditionals = questions.filter((question: any) => {
    if (question.staticQuestions.Parent) {
      const nonUUIDarray = question.staticQuestions.Parent.split("_");
      if (
        nonUUIDarray.length === 2 &&
        nonUUIDarray[0] == thisQuestion.id &&
        (responseID !== null
          ? responseID == nonUUIDarray[1]
          : thisQuestion.dynamicResponses.Elements.find(
              (response: any) => response.id == nonUUIDarray[1],
            ))
      ) {
        return true;
      }
      if (
        responseID !== null
          ? question.staticQuestions.Parent === responseID
          : thisQuestion.dynamicResponses.Elements.find(
              (response: any) =>
                response.id === question.staticQuestions.Parent,
            )
      ) {
        return true;
      }
      return false;
    }
    return false;
  });
  return conditionals.length > 0;
};

const conditionalsAreInOrder = (
  questions: any[],
  sourceIndex: number,
  destinationIndex: number,
) => {
  // if the question being moved has a parent, don't let it move above the parent
  if (questions[sourceIndex].staticQuestions.Parent) {
    const nonUUIDs = questions[sourceIndex].staticQuestions.Parent.split("_");
    const parentQuestionIndex = questions.findIndex((question: any) =>
      nonUUIDs.length === 2
        ? question.id == nonUUIDs[0]
        : question.dynamicResponses.Elements.find(
            (response: any) =>
              response.id === questions[sourceIndex].staticQuestions.Parent,
          ),
    );
    if (destinationIndex <= parentQuestionIndex) {
      return false;
    }
  }

  // if the question being moved has children, don't let it move below them
  const responseIDs = questions[sourceIndex].dynamicResponses.Elements.map(
    (response: any) => response.id,
  );
  for (let i = sourceIndex + 1; i < questions.length; i++) {
    if (questions[i].staticQuestions.Parent) {
      const nonUUIDs = questions[i].staticQuestions.Parent.split("_");
      if (nonUUIDs.length === 2) {
        if (nonUUIDs[0] == questions[sourceIndex].id && destinationIndex >= i) {
          return false;
        }
      } else if (
        responseIDs.includes(questions[i].staticQuestions.Parent) &&
        destinationIndex >= i
      ) {
        return false;
      }
    }
  }
  return true;
};

const getTypeAction = (id: string) => {
  switch (id) {
    case QuestionType.Custom:
      return "addCustomFieldQuestion";
    case QuestionType.Notes:
      return "addQuestionText";
    case QuestionType.SingleSelect:
      return "addQuestionOption";
    case QuestionType.InherentRisk:
      return "addQuestionInherentRisk";
    case QuestionType.ResidualRisk:
      return "addQuestionResidualRisk";
    case QuestionType.RiskControls:
      return "addQuestionRiskControls";
    case QuestionType.AlertRightNow:
      return "addQuestionAlertRightNow";
    case QuestionType.SectionBreak:
      return "addQuestionSectionBreak";
  }
  return "Unrecognised";
};

// Data Structure:
// {
//     id: -1,
//     questions: {
//         (ActionQuestion) Elements: [{
//             id: 1,
//             readOnly: false,
//             staticQuestions: {
//                (QuestionType) Type: "singleSelect",
//                 Text: "",
//                 Uber: -1,
//                 Mandatory: -1
//             },
//            (ActionQuestionElement)dynamicResponses: {
//                 Elements: [{
//                     id: 1,
//                     selectValue: "pos",
//                     textValue: ""
//                 }],
//                 Count: 0
//             }
//         }],
//         Count: 0
//     }
// }

const getCFType = (id: string, cf?: any[]) => {
  if (cf && cf.length > 0) {
    const res = cf.find((field: any) => field.CustomFieldID === id);
    return res ? res.CustomFieldTypeID : "";
  }
  return "";
};
const initialState: State = {
  id: "defaultQuestionWrapper",
  questionsAreDraggable: false,
  addingQuestion: false,
  questions: {
    Elements: [],
    Count: 0,
  },
  questionTypes: [],
  customFields: [],
  riskMatrix: {},
};

/*
 *   Functions
 */
const formatToDB = (state: any) => {
  let formattedArray: any[] = [];
  for (const [qIndex, question] of state.questions.Elements.entries()) {
    let responseArray: any[] = [];
    for (const [
      rIndex,
      response,
    ] of question.dynamicResponses.Elements.entries()) {
      const thisResponse = {
        QuestionElementID: response.id,
        QuestionElementText: response.textValue,
        ObjectStatusID:
          question.staticQuestions.Type === QuestionType.Custom
            ? ObjectStatus.Neutral
            : response.selectValue,
        QuestionElementOrder: rIndex,
        CustomFieldID: response.customFieldid ? response.customFieldid : null,
        RiskMatrixDimensionTypeID: response.riskMatrixDimensionTypeID
          ? response.riskMatrixDimensionTypeID
          : null,
      };
      responseArray = [...responseArray, thisResponse];
    }

    const thisQuestion = {
      QuestionID: question.id,
      QuestionText: question.staticQuestions.Text,
      QuestionOrder: qIndex,
      QuestionIsUber: question.staticQuestions.Uber,
      QuestionIsMulti: question.staticQuestions.MultiResponse,
      QuestionMandatory: question.staticQuestions.Mandatory,
      QuestionTypeID: question.staticQuestions.Type,
      ParentQuestionElementID: question.staticQuestions.Parent,
      Responses: responseArray,
    };
    formattedArray = [...formattedArray, thisQuestion];
  }
  return formattedArray;
};

const formatFromDB = (questionArray: any[]) => {
  let formattedArray: any[] = [];

  for (const question of questionArray) {
    let responseArray: any[] = [];
    for (const response of question.Responses) {
      const thisResponse = {
        id: response.QuestionElementID,
        Type: question.QuestionTypeID,
        textValue: response.QuestionElementText,
        selectValue: response.ObjectStatusID,
        customFieldid: response.CustomFieldID ? response.CustomFieldID : null,
        riskMatrixDimensionTypeID: response.RiskMatrixDimensionTypeID
          ? response.RiskMatrixDimensionTypeID
          : null,
      };
      responseArray = [...responseArray, thisResponse];
    }

    const thisQuestion = {
      id: question.QuestionID || question.ActionQuestionID,
      readOnly: true,
      staticQuestions: {
        Type: question.QuestionTypeID,
        Text: question.QuestionText,
        Uber: question.QuestionIsUber,
        MultiResponse: question.QuestionIsMulti,
        Mandatory: question.QuestionMandatory,
        Parent: question.ParentQuestionElementID,
      },
      dynamicResponses: {
        Elements: responseArray,
        Count: responseArray.length,
      },
    };

    formattedArray = [...formattedArray, thisQuestion];
  }

  return { Elements: formattedArray, Count: formattedArray.length };
};

const reorder = (list: any, startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const isNumeric = (num: number | string): boolean => !isNaN(Number(num));

const convertNumberToBoolean = (n: number) => {
  switch (n) {
    case -1:
      return "Unanswered";
    case 0:
      return "No";
    case 1:
      return "Yes";
  }
  return "Unrecognised";
};

const questionTypeToIcon = (questionType: string) => {
  switch (questionType) {
    case "Notes":
      return <AlignLeft style={{ margin: "10px" }} className="feather" />;
    case "Single Select":
      return <SingleSelectIcon />;
    case "Custom Question":
      return <Layers style={{ margin: "10px" }} className="feather" />;
    case "Inherent Risk":
      return <CloudDrizzle style={{ margin: "10px" }} className="feather" />;
    case "Risk Controls":
      return <Code style={{ margin: "10px" }} className="feather" />;
    case "Residual Risk":
      return <Sun style={{ margin: "10px" }} className="feather" />;
    case "Alert Right Now":
      return <Mail style={{ margin: "10px" }} className="feather" />;
    case "Section Break":
      return <Type style={{ margin: "10px" }} className="feather" />;
    default:
      return <Square style={{ margin: "10px" }} className="feather" />;
  }
};

const formatQuestionType = (s: string) => {
  switch (s) {
    case QuestionType.Custom:
      return "Custom";
    case QuestionType.Notes:
      return "Notes";
    case QuestionType.SingleSelect:
      return "Single Select";
    case QuestionType.InherentRisk:
      return "Inherent Risk";
    case QuestionType.ResidualRisk:
      return "Residual Risk";
    case QuestionType.RiskControls:
      return "Risk Controls";
    case QuestionType.AlertRightNow:
      return "Alert Right Now";
    case QuestionType.SectionBreak:
      return "Section Break";
  }
  return "Unrecognised";
};

const formatResponseAlignment = (s: string) => {
  switch (s) {
    case ObjectStatus.Negative:
      return "Negative";
    case ObjectStatus.Positive:
      return "Positive";
    case ObjectStatus.Neutral:
      return "Neutral";
  }
  return "Unrecognised";
};

const Reducer: React.Reducer<State, Action> = (state, action) => {
  const questions = { ...state.questions };
  switch (action.type) {
    case "addQuestionOption":
      for (const question of questions.Elements) {
        question.readOnly = true;
      }
      questions.Count = state.questions.Count + 1;
      const newQuestionOption = {
        id: questions.Count,
        readOnly: false,
        dispatch: action.dispatch,
        staticQuestions: {
          Type: QuestionType.SingleSelect,
          Text: "",
          Uber: 1,
          Mandatory: 1,
          MultiResponse: 0,
          Parent: "",
        },
        dynamicResponses: {
          Elements: [
            {
              id: 0,
              Type: QuestionType.SingleSelect,
              selectValue: ObjectStatus.Positive,
              textValue: "Yes",
              riskMatrixDimensionTypeID: "",
              dispatch: action.dispatch,
            },
            {
              id: 1,
              Type: QuestionType.SingleSelect,
              selectValue: ObjectStatus.Negative,
              textValue: "No",
              riskMatrixDimensionTypeID: "",
              dispatch: action.dispatch,
            },
          ],
          Count: 2,
        },
      };
      questions.Elements = [...state.questions.Elements, newQuestionOption];
      return { ...state, questions, addingQuestion: false };
    case "addQuestionText":
      for (const question of questions.Elements) {
        question.readOnly = true;
      }
      questions.Count = state.questions.Count + 1;
      const newQuestionText = {
        id: questions.Count,
        readOnly: false,
        dispatch: action.dispatch,
        staticQuestions: {
          Type: QuestionType.Notes,
          Text: "",
          Uber: 0,
          Mandatory: 1,
          MultiResponse: 0,
          Parent: "",
        },
        dynamicResponses: {
          Elements: [
            {
              id: 0,
              Type: QuestionType.Notes,
              selectValue: ObjectStatus.Neutral,
              textValue: "",
              riskMatrixDimensionTypeID: "",
              dispatch: action.dispatch,
            },
          ],
          Count: 1,
        },
      };
      questions.Elements = [...state.questions.Elements, newQuestionText];
      return { ...state, questions, addingQuestion: false };
    case "addQuestionInherentRisk":
      for (const question of questions.Elements) {
        question.readOnly = true;
      }
      questions.Count = state.questions.Count + 1;
      const newQuestionInherentRisk = {
        id: questions.Count,
        readOnly: false,
        dispatch: action.dispatch,
        staticQuestions: {
          Type: QuestionType.InherentRisk,
          Text: "",
          Uber: 0,
          Mandatory: 1,
          MultiResponse: 0,
          Parent: "",
        },
        dynamicResponses: {
          Elements: [
            {
              id: 0,
              Type: QuestionType.InherentRisk,
              selectValue: ObjectStatus.Neutral,
              textValue: "",
              riskMatrixDimensionTypeID: RiskMatrixDimensionType.Likelihood,
              dispatch: action.dispatch,
            },
            {
              id: 1,
              Type: QuestionType.InherentRisk,
              selectValue: ObjectStatus.Neutral,
              textValue: "",
              riskMatrixDimensionTypeID: RiskMatrixDimensionType.Consequence,
              dispatch: action.dispatch,
            },
          ],
          Count: 2,
        },
      };
      questions.Elements = [
        ...state.questions.Elements,
        newQuestionInherentRisk,
      ];
      return { ...state, questions, addingQuestion: false };
    case "addQuestionResidualRisk":
      for (const question of questions.Elements) {
        question.readOnly = true;
      }
      questions.Count = state.questions.Count + 1;
      const newQuestionResidualRisk = {
        id: questions.Count,
        readOnly: false,
        dispatch: action.dispatch,
        staticQuestions: {
          Type: QuestionType.ResidualRisk,
          Text: "",
          Uber: 0,
          Mandatory: 1,
          MultiResponse: 0,
          Parent: "",
        },
        dynamicResponses: {
          Elements: [
            {
              id: 0,
              Type: QuestionType.ResidualRisk,
              selectValue: ObjectStatus.Neutral,
              textValue: "",
              riskMatrixDimensionTypeID: RiskMatrixDimensionType.Likelihood,
              dispatch: action.dispatch,
            },
            {
              id: 1,
              Type: QuestionType.ResidualRisk,
              selectValue: ObjectStatus.Neutral,
              textValue: "",
              riskMatrixDimensionTypeID: RiskMatrixDimensionType.Consequence,
              dispatch: action.dispatch,
            },
          ],
          Count: 2,
        },
      };
      questions.Elements = [
        ...state.questions.Elements,
        newQuestionResidualRisk,
      ];
      return { ...state, questions, addingQuestion: false };
    case "addQuestionRiskControls":
      for (const question of questions.Elements) {
        question.readOnly = true;
      }
      questions.Count = state.questions.Count + 1;
      const newQuestionRiskControls = {
        id: questions.Count,
        readOnly: false,
        dispatch: action.dispatch,
        staticQuestions: {
          Type: QuestionType.RiskControls,
          Text: "",
          Uber: 0,
          Mandatory: 0,
          MultiResponse: 0,
          Parent: "",
        },
        dynamicResponses: {
          Elements: [],
          Count: 0,
        },
      };
      questions.Elements = [
        ...state.questions.Elements,
        newQuestionRiskControls,
      ];
      return { ...state, questions, addingQuestion: false };
    case "addCustomFieldQuestion":
      for (const question of questions.Elements) {
        question.readOnly = true;
      }
      questions.Count = state.questions.Count + 1;
      const newCustomQuestion = {
        id: questions.Count,
        readOnly: false,
        dispatch: action.dispatch,
        staticQuestions: {
          Type: QuestionType.Custom,
          Text: "",
          Uber: 0,
          Mandatory: 0,
          MultiResponse: 0,
          Parent: "",
        },
        dynamicResponses: {
          Elements: [],
          Count: 0,
        },
      };
      questions.Elements = [...state.questions.Elements, newCustomQuestion];
      return { ...state, questions, addingQuestion: false };
    case "addQuestionAlertRightNow":
      for (const question of questions.Elements) {
        question.readOnly = true;
      }
      questions.Count = state.questions.Count + 1;
      const newQuestionAlertRightNow = {
        id: questions.Count,
        readOnly: false,
        dispatch: action.dispatch,
        staticQuestions: {
          Type: QuestionType.AlertRightNow,
          Text: "",
          Uber: 0,
          Mandatory: 0,
          MultiResponse: 0,
          Parent: "",
        },
        dynamicResponses: {
          Elements: [
            {
              id: 0,
              Type: QuestionType.AlertRightNow,
              selectValue: ObjectStatus.Neutral,
              textValue: "",
              riskMatrixDimensionTypeID: "",
              dispatch: action.dispatch,
            },
          ],
          Count: 0,
        },
      };
      questions.Elements = [
        ...state.questions.Elements,
        newQuestionAlertRightNow,
      ];
      return { ...state, questions, addingQuestion: false };
    case "addQuestionSectionBreak":
      for (const question of questions.Elements) {
        question.readOnly = true;
      }
      questions.Count = state.questions.Count + 1;
      const newQuestionSectionBreak = {
        id: questions.Count,
        readOnly: false,
        dispatch: action.dispatch,
        staticQuestions: {
          Type: QuestionType.SectionBreak,
          Text: "",
          Uber: 0,
          Mandatory: 0,
          MultiResponse: 0,
          Parent: "",
        },
        dynamicResponses: {
          Elements: [],
          Count: 0,
        },
      };
      questions.Elements = [
        ...state.questions.Elements,
        newQuestionSectionBreak,
      ];
      return { ...state, questions, addingQuestion: false };
    case "removeQuestion":
      questions.Elements = state.questions.Elements.filter(
        (val: any) => val.id !== action.id,
      );
      return { ...state, questions };
    case "setQuestionType":
      // Prefill the dynamic reponses based on the question type
      if (action.selected === QuestionType.SingleSelect) {
        questions.Elements[action.questionIndex].dynamicResponses.Elements = [
          {
            id: 0,
            Type: QuestionType.SingleSelect,
            selectValue: ObjectStatus.Positive,
            textValue: "Yes",
            riskMatrixDimensionTypeID: "",
            dispatch: action.dispatch,
          },
          {
            id: 1,
            Type: QuestionType.SingleSelect,
            selectValue: ObjectStatus.Negative,
            textValue: "No",
            riskMatrixDimensionTypeID: "",
            dispatch: action.dispatch,
          },
        ];
        questions.Elements[action.questionIndex].dynamicResponses.Count = 2;
      } else if (action.selected === QuestionType.Notes) {
        questions.Elements[action.questionIndex].dynamicResponses.Elements = [
          {
            id: 0,
            Type: QuestionType.Notes,
            selectValue: ObjectStatus.Neutral,
            textValue: "",
            riskMatrixDimensionTypeID: "",
            dispatch: action.dispatch,
          },
        ];
        questions.Elements[action.questionIndex].dynamicResponses.Count = 1;
      } else if (action.selected === QuestionType.InherentRisk) {
        questions.Elements[action.questionIndex].dynamicResponses.Elements = [
          {
            id: 0,
            Type: QuestionType.InherentRisk,
            selectValue: ObjectStatus.Neutral,
            textValue: "Likelihood",
            riskMatrixDimensionTypeID: RiskMatrixDimensionType.Likelihood,
            dispatch: action.dispatch,
          },
          {
            id: 1,
            Type: QuestionType.InherentRisk,
            selectValue: ObjectStatus.Neutral,
            textValue: "Consequence",
            riskMatrixDimensionTypeID: RiskMatrixDimensionType.Consequence,
            dispatch: action.dispatch,
          },
        ];
        questions.Elements[action.questionIndex].dynamicResponses.Count = 2;
      } else if (action.selected === QuestionType.ResidualRisk) {
        questions.Elements[action.questionIndex].dynamicResponses.Elements = [
          {
            id: 0,
            Type: QuestionType.ResidualRisk,
            selectValue: ObjectStatus.Neutral,
            textValue: "Likelihood",
            riskMatrixDimensionTypeID: RiskMatrixDimensionType.Likelihood,
            dispatch: action.dispatch,
          },
          {
            id: 1,
            Type: QuestionType.ResidualRisk,
            selectValue: ObjectStatus.Neutral,
            textValue: "Consequence",
            riskMatrixDimensionTypeID: RiskMatrixDimensionType.Consequence,
            dispatch: action.dispatch,
          },
        ];
        questions.Elements[action.questionIndex].dynamicResponses.Count = 2;
      } else if (
        action.selected === QuestionType.RiskControls ||
        action.selected === QuestionType.Custom ||
        action.selected === QuestionType.SectionBreak
      ) {
        questions.Elements[action.questionIndex].dynamicResponses.Elements = [];
        questions.Elements[action.questionIndex].dynamicResponses.Count = 0;
      } else if (action.selected === QuestionType.AlertRightNow) {
        questions.Elements[action.questionIndex].dynamicResponses.Elements = [
          {
            id: 0,
            Type: QuestionType.AlertRightNow,
            selectValue: ObjectStatus.Neutral,
            textValue: "",
            riskMatrixDimensionTypeID: "",
            dispatch: action.dispatch,
          },
        ];
        questions.Elements[action.questionIndex].dynamicResponses.Count = 1;
      }

      // Set the new question type
      const newStaticQuestionsType =
        questions.Elements[action.questionIndex].staticQuestions;
      newStaticQuestionsType.Type = action.selected;

      return { ...state, questions };
    case "setQuestionText":
      questions.Elements[action.questionIndex].staticQuestions.Text =
        action.value;
      return { ...state, questions };
    case "setQuestionUber":
      if (action.radio === "Yes") {
        questions.Elements[action.questionIndex].staticQuestions.Uber = 1;
        questions.Elements[action.questionIndex].staticQuestions.Mandatory = 1;
      } else if (action.radio === "No") {
        questions.Elements[action.questionIndex].staticQuestions.Uber = 0;
      }
      return { ...state, questions };
    case "setQuestionMandatory":
      if (action.radio === "Yes") {
        questions.Elements[action.questionIndex].staticQuestions.Mandatory = 1;
      } else if (action.radio === "No") {
        questions.Elements[action.questionIndex].staticQuestions.Mandatory = 0;
      }
      return { ...state, questions };
    case "setMultiResponses":
      if (action.selected) {
        questions.Elements[
          action.questionIndex
        ].staticQuestions.MultiResponse = 1;
      } else {
        questions.Elements[
          action.questionIndex
        ].staticQuestions.MultiResponse = 0;
      }
      return { ...state, questions };
    case "setConditional":
      questions.Elements[action.questionIndex].staticQuestions.Parent =
        action.parent;
      return { ...state, questions };
    case "addResponse":
      // only for adding to single select
      questions.Elements[action.questionIndex].dynamicResponses.Count =
        questions.Elements[action.questionIndex].dynamicResponses.Count + 1;
      const newResponse = {
        id: questions.Elements[action.questionIndex].dynamicResponses.Count,
        Type: QuestionType.SingleSelect,
        selectValue: ObjectStatus.Positive,
        textValue: "",
        riskMatrixDimensionTypeID: "",
        dispatch: action.dispatch,
      };
      questions.Elements[action.questionIndex].dynamicResponses.Elements = [
        ...questions.Elements[action.questionIndex].dynamicResponses.Elements,
        newResponse,
      ];
      return { ...state, questions };
    case "addCustomResponse":
      // only for adding custom fields
      questions.Elements[action.questionIndex].dynamicResponses.Count =
        questions.Elements[action.questionIndex].dynamicResponses.Count + 1;
      const newCustomResponse = {
        id: questions.Elements[action.questionIndex].dynamicResponses.Count,
        Type: QuestionType.Custom,
        selectValue: action.customType.type,
        customFieldid: action.customType.id,
        textValue: action.customType.label,
        riskMatrixDimensionTypeID: "",
        dispatch: action.dispatch,
      };
      questions.Elements[action.questionIndex].dynamicResponses.Elements = [
        ...questions.Elements[action.questionIndex].dynamicResponses.Elements,
        newCustomResponse,
      ];
      return { ...state, questions };
    case "removeResponse":
      questions.Elements[action.questionIndex].dynamicResponses.Elements =
        questions.Elements[
          action.questionIndex
        ].dynamicResponses.Elements.filter((val: any) => val.id !== action.id);
      return { ...state, questions };
    case "setResponseText":
      questions.Elements[action.questionIndex].dynamicResponses.Elements[
        action.index
      ].textValue = action.value;
      return { ...state, questions };
    case "setResponseSelect":
      questions.Elements[action.questionIndex].dynamicResponses.Elements[
        action.index
      ].selectValue = action.value;
      return { ...state, questions };
    case "reset":
      return initialState;
    case "setReadOnly":
      if (questions.Elements.length > 0 && !action.readOnly) {
        questions.Elements[0].readOnly = action.readOnly;
      }
      return { ...state, questionsAreDraggable: !action.readOnly, questions };
    case "changeEditToMe":
      for (const question of questions.Elements) {
        question.readOnly = !(question.id === action.id);
      }
      return { ...state, questions };
    case "setID":
      return { ...state, id: action.id };
    case "setQuestions":
      return { ...state, questions: action.questions };
    case "onDragEndQuestion":
      // dropped outside the list OR parent question below child question
      if (!action.result.destination) {
        return state;
      }
      if (
        !conditionalsAreInOrder(
          questions.Elements,
          action.result.source.index,
          action.result.destination.index,
        )
      ) {
        action.displayToast({
          status: "warning",
          title: "Can not move question",
          description: `A conditional question must remain below its parent`,
        });
        return state;
      }

      questions.Elements = reorder(
        questions.Elements,
        action.result.source.index,
        action.result.destination.index,
      );

      return { ...state, questions };
    case "onDragEndResponse":
      // dropped outside the list
      if (!action.result.destination) {
        return state;
      }

      questions.Elements[action.questionIndex].dynamicResponses.Elements =
        reorder(
          questions.Elements[action.questionIndex].dynamicResponses.Elements,
          action.result.source.index,
          action.result.destination.index,
        );

      return { ...state, questions };
    case "addQuestionTypes":
      return { ...state, questionTypes: action.result };
    case "addQuestioncustomFields":
      return { ...state, customFields: action.result };
    default:
      return state;
  }
};

const renderQuestions = (state: any, dispatch: React.Dispatch<Action>) => {
  if (state.questions.Elements.length > 0) {
    return state.questions.Elements.map((e: any, i: number) => {
      if (state.questionsAreDraggable) {
        return (
          <div key={`QuestionWrapDrag${e.id}`}>
            <Draggable key={e.id} draggableId={e.id} index={i}>
              {(provided, snapshot) => (
                <div
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  ref={provided.innerRef}
                  style={getQuestionStyle(
                    snapshot.isDragging,
                    provided.draggableProps.style,
                    e.readOnly,
                  )}
                  key={`Question${i.toString()}`}
                  onClick={(event) =>
                    dispatch({ type: "changeEditToMe", id: e.id })
                  }>
                  <Question
                    key={`Question${e.id}`}
                    index={i}
                    id={e.id}
                    dispatch={dispatch}
                    staticQuestions={e.staticQuestions}
                    dynamicResponses={e.dynamicResponses}
                    readOnly={e.readOnly}
                    viewMode={false}
                    showCharacterLimit={!e.readOnly}
                    customFields={state.customFields}
                    questionTypes={state.questionTypes}
                    state={state}
                  />
                </div>
              )}
            </Draggable>
          </div>
        );
      }
      return (
        <div key={`QuestionWrap${e.id}`}>
          <Question
            key={`Question${e.id}`}
            index={i}
            id={e.id}
            dispatch={dispatch}
            staticQuestions={e.staticQuestions}
            dynamicResponses={e.dynamicResponses}
            readOnly={e.readOnly}
            viewMode
            showCharacterLimit={!e.readOnly}
            customFields={state.customFields}
            questionTypes={state.questionTypes}
            state={state}
          />
        </div>
      );
    });
  }
  return null;
};

const getCFTypeName = (id: string, cf?: any[]) => {
  if (cf && cf.length > 0) {
    const res = cf.find((field: any) => field.CustomFieldID === id);
    return res ? res.CustomFieldTypeName : "";
  }
  return "";
};

const renderResponses = (
  responses: any,
  questionIndex: number,
  readOnly: boolean,
  viewMode: boolean,
  type: string,
  dispatch: React.Dispatch<Action>,
  state: any,
  cf?: any[],
) => {
  if (responses.Elements.length > 0) {
    if (viewMode) {
      return (
        <div id="static" style={{ paddingTop: "10px", paddingBottom: "10px" }}>
          <Form.Group as={Row}>
            <Col sm="2" className="colTitle">
              <h2>Responses</h2>
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm="2" className="greyTitle">
              Response options
            </Form.Label>
            <Col sm="10">
              {type === QuestionType.SingleSelect ? (
                responses.Elements.map((e: any, i: number) => (
                  <div key={i}>
                    <span>
                      {e.textValue} _ {formatResponseAlignment(e.selectValue)}
                    </span>
                  </div>
                ))
              ) : type === QuestionType.Notes ? (
                <span>Multi-line (long answer)</span>
              ) : type === QuestionType.RiskControls ? (
                <span>View risk controls</span>
              ) : type === QuestionType.Custom ? (
                responses.Elements.map((e: any, i: number) => (
                  <div key={i}>
                    <span>
                      {e.textValue}_{getCFTypeName(e.customFieldid, cf)}
                    </span>
                  </div>
                ))
              ) : type === QuestionType.AlertRightNow ? (
                <span>Select users to notify by email</span>
              ) : (
                <span>Risk assessment</span>
              )}
            </Col>
          </Form.Group>
        </div>
      );
    }
    return responses.Elements.map((e: any, i: number) => (
      <Response
        key={`Response${e.id}`}
        index={i}
        questionIndex={questionIndex}
        id={e.id}
        type={e.Type}
        dispatch={dispatch}
        selectValue={e.selectValue}
        textValue={e.textValue}
        riskMatrixDimensionTypeID={e.riskMatrixDimensionTypeID}
        readOnly={readOnly}
        viewMode={viewMode}
        maxIndex={responses.Elements.length}
        showCharacterLimit={!e.readOnly}
        customFieldid={e.customFieldid}
        customFields={cf}
        state={state}
      />
    ));
  }
  return null;
};

const renderToolBar = (state: any, dispatch: any) => {
  const sortedQuestionTypes: any[] = [];
  sortedQuestionTypes.push(
    state.questionTypes.find(
      (q: any) => q.QuestionTypeID === QuestionType.SingleSelect,
    ),
  );
  sortedQuestionTypes.push(
    state.questionTypes.find(
      (q: any) => q.QuestionTypeID === QuestionType.Notes,
    ),
  );
  sortedQuestionTypes.push(
    state.questionTypes.find(
      (q: any) => q.QuestionTypeID === QuestionType.Custom,
    ),
  );
  sortedQuestionTypes.push(
    state.questionTypes.find(
      (q: any) => q.QuestionTypeID === QuestionType.AlertRightNow,
    ),
  );
  sortedQuestionTypes.push(
    state.questionTypes.find(
      (q: any) => q.QuestionTypeID === QuestionType.SectionBreak,
    ),
  );
  sortedQuestionTypes.push.apply(
    sortedQuestionTypes,
    state.questionTypes.filter((q: any) => !sortedQuestionTypes.includes(q)),
  );

  if (state.questionsAreDraggable) {
    return (
      <div>
        <Row style={{ marginTop: "40px" }}>
          <Dropdown drop="right">
            <Dropdown.Toggle
              id="dropdown-custom-1"
              style={{
                backgroundColor: "white",
                color: "gray",
                border: "none",
                float: "left",
                width: "175px",
                height: "42px",
                padding: "2px",
              }}>
              <PlusCircle className="feather" />
              Add new question
            </Dropdown.Toggle>
            <Dropdown.Menu
              style={{ width: "400px", minHeight: "253px", height: "auto" }}>
              <Dropdown.Header>New Question Type</Dropdown.Header>
              <Dropdown.Divider />
              {sortedQuestionTypes.map((q: any) => {
                const type = getTypeAction(q.QuestionTypeID);
                return (
                  <Dropdown.Item
                    key={q.QuestionTypeID}
                    onClick={() => dispatch({ type, dispatch })}>
                    <Row>
                      <Col sm="2">{questionTypeToIcon(q.QuestionTypeName)}</Col>
                      <Col>
                        <Row>{q.QuestionTypeName}</Row>
                        <Row style={{ fontSize: "10px" }}>
                          {q.QuestionTypeDescription}
                        </Row>
                      </Col>
                    </Row>
                  </Dropdown.Item>
                );
              })}
            </Dropdown.Menu>
          </Dropdown>
        </Row>
        <hr />
      </div>
    );
  }
  return null;
};

/*
 *   Components
 */

const configureInitialState = (initial: any, props: any) => {
  if (props.id) {
    initial.id = props.id;
  }
  if (props.initialQuestions) {
    const formattedQuestion = formatFromDB(props.initialQuestions);
    initial.questions = formattedQuestion;
  }
  initial.riskMatrix = props.riskMatrix;
  return initial;
};

const QuestionWrapper = (props: any) => {
  const { displayToast } = useToast();
  const [state, dispatch] = React.useReducer<React.Reducer<State, Action>>(
    Reducer,
    configureInitialState(initialState, props),
  );
  const [isDetailsCollapsedNew, updateDetailsCollapsedNew] = useState<boolean>(
    props.readOnly,
  );

  useEffect(() => {
    props.handleChange(formatToDB(state));
    updateDetailsCollapsedNew(props.readOnly);
  }, [state]); // eslint-disable-line

  useEffect(() => {
    if (props.initialQuestions) {
      dispatch({
        type: "setQuestions",
        questions: configureInitialState(initialState, props).questions,
      });
    }
  }, [props.initialQuestions, props.reset]); // eslint-disable-line

  useEffect(() => {
    dispatch({ type: "setReadOnly", readOnly: props.readOnly });
  }, [dispatch, props.readOnly]);

  useEffect(() => {
    dispatch({ type: "addQuestionTypes", result: props.questionTypes });
  }, [dispatch, props.questionTypes]);
  useEffect(() => {
    dispatch({ type: "addQuestioncustomFields", result: props.customFields });
  }, [dispatch, props.customFields]);
  return (
    <Container style={{ marginTop: "40px" }}>
      <Row>
        <Col>
          <h1
            className="question-list"
            onClick={() => {
              updateDetailsCollapsedNew(!isDetailsCollapsedNew);
            }}>
            Question List
          </h1>
        </Col>
        <Col sm="auto">
          <CollapseChevron
            collapsed={isDetailsCollapsedNew}
            updateCollapsed={updateDetailsCollapsedNew}
          />
        </Col>
      </Row>
      <Collapse in={!isDetailsCollapsedNew}>
        <div
          style={{
            marginTop: "30px",
            marginLeft: "-47px",
            marginRight: "-47px",
          }}>
          <DragDropContext
            onDragEnd={(result) =>
              dispatch({
                type: "onDragEndQuestion",
                result,
                displayToast: displayToast,
              })
            }>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  style={getQuestionWrapStyle(snapshot.isDraggingOver)}>
                  {renderQuestions(state, dispatch)}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          <div style={{ marginLeft: "47px", marginRight: "47px" }}>
            {renderToolBar(state, dispatch)}
          </div>
        </div>
      </Collapse>
    </Container>
  );
};

type StaticQuestion = {
  Type: string;
  Text: string;
  Uber: number;
  Mandatory: number;
  showCharacterLimit: boolean;
  MultiResponse: number;
  Parent: string;
};

type DynamicResponseElement = {
  id: number;
  selectValue: string;
  textValue: string;
  customFieldid?: string;
};

type DynamicResponse = {
  Elements: any[];
  Count: number;
};

type QuestionProps = {
  id: string;
  index: number;
  staticQuestions: StaticQuestion;
  dynamicResponses: DynamicResponse;
  dispatch: any;
  readOnly: boolean;
  viewMode: boolean;
  showCharacterLimit: boolean;
  customFields: any[];
  questionTypes: any[];
  state: any;
};

const calculateQuestionNumber = (questions: any[], questionIndex: number) =>
  questions.filter(
    (question, index) =>
      question.staticQuestions.Type !== QuestionType.SectionBreak &&
      index < questionIndex,
  ).length + 1;

const Question = (obj: QuestionProps) => {
  const [input1Ref, setInput1Focus] = useFocus();
  const [focus, setFocus] = useState(false);
  const maxCharacters = 512;

  const handleFocus = () => {
    setFocus(true);
  };
  const handleUnfocus = () => {
    setFocus(false);
  };

  useEffect(() => {
    setInput1Focus();
  }, []); // eslint-disable-line

  if (obj.viewMode) {
    return (
      <div style={{ marginLeft: "47px", marginRight: "47px" }}>
        {obj.index !== 0 &&
        obj.state.questions.Elements[obj.index - 1].staticQuestions.Type !==
          QuestionType.SectionBreak ? (
          <hr style={{ marginBottom: "30px" }} />
        ) : (
          <div style={{ marginBottom: "30px" }} />
        )}
        {obj.staticQuestions.Parent && (
          <ConditionalDropdown
            questions={obj.state.questions.Elements}
            index={obj.index}
            dispatch={obj.dispatch}
          />
        )}
        {obj.staticQuestions.Type !== QuestionType.SectionBreak ? (
          <>
            <Form.Group as={Row}>
              <Col sm="2" className="colTitle">
                <h2>
                  Question{" "}
                  {calculateQuestionNumber(
                    obj.state.questions.Elements,
                    obj.index,
                  )}
                </h2>
              </Col>
              <Col sm="10">
                <span style={{ fontSize: "12px" }}>
                  {formatQuestionType(obj.staticQuestions.Type)}
                </span>
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm="2" className="greyTitle">
                {" "}
                Question Text
              </Form.Label>
              <Col sm="10">{obj.staticQuestions.Text}</Col>
            </Form.Group>
            {obj.staticQuestions.Type === QuestionType.Custom ? (
              <Form.Group as={Row}>
                <Form.Label column sm="2" className="greyTitle">
                  Have multi-responses?
                </Form.Label>
                <Col sm="10">
                  {convertNumberToBoolean(obj.staticQuestions.MultiResponse)}
                </Col>
              </Form.Group>
            ) : (
              ""
            )}
            {obj.staticQuestions.Type === QuestionType.SingleSelect ? (
              <Form.Group as={Row}>
                <Form.Label column sm="2" className="greyTitle">
                  Master
                </Form.Label>
                <Col sm="10">
                  {convertNumberToBoolean(obj.staticQuestions.Uber)}
                </Col>
              </Form.Group>
            ) : (
              ""
            )}
            {obj.staticQuestions.Type !== QuestionType.RiskControls &&
            obj.staticQuestions.Type !== QuestionType.SectionBreak ? (
              <Form.Group as={Row}>
                <Form.Label column sm="2" className="greyTitle">
                  Mandatory
                </Form.Label>
                <Col sm="10">
                  {convertNumberToBoolean(obj.staticQuestions.Mandatory)}
                </Col>
              </Form.Group>
            ) : (
              ""
            )}
          </>
        ) : (
          <>
            <h1>{obj.staticQuestions.Text}</h1>
            <hr style={{ border: "1px grey solid", margin: 0 }} />
          </>
        )}
        {renderResponses(
          obj.dynamicResponses,
          obj.index,
          obj.readOnly,
          obj.viewMode,
          obj.staticQuestions.Type,
          obj.dispatch,
          obj.state,
          obj.customFields,
        )}
      </div>
    );
  }
  if (obj.readOnly) {
    return (
      <div style={{ marginLeft: "47px", marginRight: "47px" }}>
        <hr style={{ marginBottom: "30px" }} />
        {obj.staticQuestions.Parent && (
          <ConditionalDropdown
            questions={obj.state.questions.Elements}
            index={obj.index}
            dispatch={obj.dispatch}
          />
        )}
        {obj.staticQuestions.Type !== QuestionType.SectionBreak ? (
          <>
            <Form.Group as={Row}>
              <Col sm="2" className="colTitle">
                <h2>
                  Question{" "}
                  {calculateQuestionNumber(
                    obj.state.questions.Elements,
                    obj.index,
                  )}
                </h2>
              </Col>
              <Col sm="10">
                <span>{formatQuestionType(obj.staticQuestions.Type)}</span>
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm="2" className="greyTitle">
                {" "}
                Question Text
              </Form.Label>
              <Col sm="10">{obj.staticQuestions.Text}</Col>
            </Form.Group>
            {obj.staticQuestions.Type === QuestionType.Custom ? (
              <Form.Group as={Row}>
                <Form.Label column sm="2" className="greyTitle">
                  Have multi-responses?
                </Form.Label>
                <Col sm="10">
                  {convertNumberToBoolean(obj.staticQuestions.MultiResponse)}
                </Col>
              </Form.Group>
            ) : (
              ""
            )}
            {obj.staticQuestions.Type === QuestionType.SingleSelect ? (
              <Form.Group as={Row}>
                <Form.Label column sm="2" className="greyTitle">
                  Master
                </Form.Label>
                <Col sm="10">
                  {convertNumberToBoolean(obj.staticQuestions.Uber)}
                </Col>
              </Form.Group>
            ) : (
              ""
            )}
            {obj.staticQuestions.Type !== QuestionType.RiskControls &&
            obj.staticQuestions.Type !== QuestionType.SectionBreak ? (
              <Form.Group as={Row}>
                <Form.Label column sm="2" className="greyTitle">
                  Mandatory
                </Form.Label>
                <Col sm="10">
                  {convertNumberToBoolean(obj.staticQuestions.Mandatory)}
                </Col>
              </Form.Group>
            ) : (
              ""
            )}
            <hr style={{ marginTop: "30px" }} />
          </>
        ) : (
          <>
            <h1>{obj.staticQuestions.Text}</h1>
            <hr style={{ border: "1px grey solid", margin: 0 }} />
          </>
        )}
        {renderResponses(
          obj.dynamicResponses,
          obj.index,
          obj.readOnly,
          obj.viewMode,
          obj.staticQuestions.Type,
          obj.dispatch,
          obj.state,
          obj.customFields,
        )}
      </div>
    );
  }
  return (
    <div id="question">
      <ConditionalDropdown
        questions={obj.state.questions.Elements}
        index={obj.index}
        dispatch={obj.dispatch}
      />
      <Row>
        <Col sm="11" />
        <Col sm="1">
          <DeleteButton
            hasConditionalChildren={hasConditionalChildren(
              obj.state.questions.Elements,
              obj.id,
              null,
              null,
            )}
            handleClick={() =>
              obj.dispatch({ type: "removeQuestion", id: obj.id })
            }
            tooltipText="Another question is conditional on this question - it cannot be deleted."
          />
        </Col>
      </Row>
      <div id="StaticQuestions">
        <Form.Group as={Row}>
          <Col sm="3" style={{ textAlign: "right" }}>
            <Row>
              <Col sm="4" style={{ paddingTop: "5px" }}>
                <Move style={{ color: "#d8d8d8" }} />
              </Col>
              <Col sm="8">
                <h2>
                  {obj.staticQuestions.Type === QuestionType.SectionBreak
                    ? ""
                    : `Question ${calculateQuestionNumber(
                        obj.state.questions.Elements,
                        obj.index,
                      )}`}
                </h2>
              </Col>
            </Row>
          </Col>
          <Col sm="7">
            {isNumeric(obj.id) ? (
              <Select
                onUpdate={(event: any) =>
                  obj.dispatch({
                    type: "setQuestionType",
                    questionIndex: obj.index,
                    selected: event.value,
                    dispatch: obj.dispatch,
                  })
                }
                name="QuestionTypeSelector"
                value={obj.staticQuestions.Type}
                optionsList={obj.questionTypes}
                optionsListID="QuestionTypeID"
                optionsListValue="QuestionTypeName"
                readOnly={obj.readOnly}
              />
            ) : (
              <Text
                value={formatQuestionType(obj.staticQuestions.Type)}
                name="QuestionType"
                readOnly
              />
            )}
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label column sm="3">
            {obj.staticQuestions.Type === QuestionType.SectionBreak
              ? "Title"
              : "Question Text"}
          </Form.Label>
          <Col sm="7">
            <div>
              <Form.Control
                ref={input1Ref}
                type="text"
                onFocus={obj.showCharacterLimit ? handleFocus : undefined}
                onBlur={obj.showCharacterLimit ? handleUnfocus : undefined}
                autoFocus={obj.staticQuestions.Text === ""}
                style={{ borderRadius: "25px" }}
                value={obj.staticQuestions.Text}
                name="ActionName"
                onChange={(event: any) => {
                  if (maxCharacters - event.target.value.length >= 0) {
                    obj.dispatch({
                      type: "setQuestionText",
                      questionIndex: obj.index,
                      value: event.target.value,
                    });
                  }
                }}
              />
              {obj.showCharacterLimit && focus ? (
                <span className="form-label-small">
                  {maxCharacters -
                    (obj.staticQuestions.Text
                      ? obj.staticQuestions.Text.length
                      : 0)}{" "}
                  characters remaining
                </span>
              ) : (
                <div style={{ height: "24px" }} />
              )}
            </div>
          </Col>
          <Col sm="2">
            {/* <HelpText id={`Help${obj.index}`} text={"TODO, there will be some actual info here soon!"} /> */}
          </Col>
        </Form.Group>
        {obj.staticQuestions.Type === QuestionType.Custom ? (
          <Form.Group as={Row}>
            <Form.Label column sm="3">
              {" "}
              Have multi-responses?{" "}
            </Form.Label>
            <Col sm="7">
              <Form.Check
                id="Multi-Responses"
                type="checkbox"
                checked={obj.staticQuestions.MultiResponse === 1}
                onChange={(event: any) =>
                  obj.dispatch({
                    type: "setMultiResponses",
                    questionIndex: obj.index,
                    selected: event.target.checked,
                  })
                }
                label="Yes, I want multi-responses"
              />
            </Col>
          </Form.Group>
        ) : (
          ""
        )}
        {obj.staticQuestions.Type === QuestionType.SingleSelect &&
        obj.staticQuestions.Parent ? (
          <OverlayTrigger
            placement="auto"
            overlay={
              <Tooltip id="dependent-cannot-be-master">
                A dependent question cannot be a Master question
              </Tooltip>
            }>
            <Form.Group as={Row}>
              <Form.Label column sm="3">
                Master Question
              </Form.Label>
              <Col sm="7" style={{ paddingLeft: "30px" }}>
                <Row>
                  <Form.Check
                    id={`MasterYes${obj.id}`}
                    type="radio"
                    checked={obj.staticQuestions.Uber === 1}
                    disabled
                    onChange={(event: any) =>
                      obj.dispatch({
                        type: "setQuestionUber",
                        questionIndex: obj.index,
                        radio: "Yes",
                      })
                    }
                    label="Yes, use this question to determine the status of the Activity"
                  />
                </Row>
                <Row>
                  <Form.Check
                    id={`MasterNo${obj.id}`}
                    type="radio"
                    checked={obj.staticQuestions.Uber === 0}
                    disabled
                    onChange={(event: any) =>
                      obj.dispatch({
                        type: "setQuestionUber",
                        questionIndex: obj.index,
                        radio: "No",
                      })
                    }
                    label={`No, don't use this question to determine the status of the Activity`}
                  />
                </Row>
              </Col>
            </Form.Group>
          </OverlayTrigger>
        ) : obj.staticQuestions.Type === QuestionType.SingleSelect ? (
          <Form.Group as={Row}>
            <Form.Label column sm="3">
              Master Question
            </Form.Label>
            <Col sm="7" style={{ paddingLeft: "30px" }}>
              <Row>
                <Form.Check
                  id={`MasterYes${obj.id}`}
                  type="radio"
                  checked={obj.staticQuestions.Uber === 1}
                  onChange={(event: any) =>
                    obj.dispatch({
                      type: "setQuestionUber",
                      questionIndex: obj.index,
                      radio: "Yes",
                    })
                  }
                  label="Yes, use this question to determine the status of the Activity"
                />
              </Row>
              <Row>
                <Form.Check
                  id={`MasterNo${obj.id}`}
                  type="radio"
                  checked={obj.staticQuestions.Uber === 0}
                  onChange={(event: any) =>
                    obj.dispatch({
                      type: "setQuestionUber",
                      questionIndex: obj.index,
                      radio: "No",
                    })
                  }
                  label={`No, don't use this question to determine the status of the Activity`}
                />
              </Row>
            </Col>
          </Form.Group>
        ) : (
          ""
        )}
        {obj.staticQuestions.Type !== QuestionType.RiskControls &&
        obj.staticQuestions.Type !== QuestionType.SectionBreak ? (
          <Form.Group as={Row}>
            <Form.Label column sm="3">
              Mandatory Question
            </Form.Label>
            <Col sm="7" style={{ paddingLeft: "30px" }}>
              <Row>
                <Form.Check
                  id={`MandatoryYes${obj.id}`}
                  type="radio"
                  disabled={obj.staticQuestions.Uber === 1}
                  checked={obj.staticQuestions.Mandatory === 1}
                  onChange={(event: any) =>
                    obj.dispatch({
                      type: "setQuestionMandatory",
                      questionIndex: obj.index,
                      radio: "Yes",
                    })
                  }
                  label="Yes, do not allow this question to be finished without answering"
                />
              </Row>
              <Row>
                <Form.Check
                  id={`MandatoryNo${obj.id}`}
                  type="radio"
                  disabled={obj.staticQuestions.Uber === 1}
                  checked={obj.staticQuestions.Mandatory === 0}
                  onChange={(event: any) =>
                    obj.dispatch({
                      type: "setQuestionMandatory",
                      questionIndex: obj.index,
                      radio: "No",
                    })
                  }
                  label="No, this question should not stop a response being saved"
                />
              </Row>
            </Col>
          </Form.Group>
        ) : (
          ""
        )}
        <hr style={{ marginTop: "40px" }} />
      </div>

      <div id="DynamicResponses">
        <DragDropContext
          onDragEnd={(result) =>
            obj.dispatch({
              type: "onDragEndResponse",
              result,
              questionIndex: obj.index,
            })
          }>
          <Droppable
            droppableId={`droppableResponse${obj.id}`}
            key={`droppableResponse${obj.id}`}>
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                style={getQuestionWrapStyle(snapshot.isDraggingOver)}>
                {renderResponses(
                  obj.dynamicResponses,
                  obj.index,
                  obj.readOnly,
                  obj.viewMode,
                  obj.staticQuestions.Type,
                  obj.dispatch,
                  obj.state,
                  obj.customFields,
                )}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>

      {renderQuestionResponseButton(obj)}
    </div>
  );
};

interface DeleteButtonProps {
  hasConditionalChildren: boolean;
  handleClick: (obj: Object) => void;
  tooltipText: string;
}

const DeleteButton = (props: DeleteButtonProps) => {
  if (props.hasConditionalChildren) {
    return (
      <OverlayTrigger
        placement="auto"
        overlay={<Tooltip id="cannot-be-deleted">{props.tooltipText}</Tooltip>}>
        <button
          disabled
          style={{
            backgroundColor: "white",
            border: "none",
            marginTop: "10px",
            marginRight: "10px",
            cursor: "not-allowed",
          }}>
          <Trash2 style={{ color: "#d8d8d8" }} className="feather" />
        </button>
      </OverlayTrigger>
    );
  }
  return (
    <button
      style={{
        backgroundColor: "white",
        border: "none",
        marginTop: "10px",
        marginRight: "10px",
      }}
      onClick={props.handleClick}>
      <Trash2 style={{ color: "#d8d8d8" }} className="feather" />
    </button>
  );
};

interface ConditionalDropdownProps {
  questions: any[];
  index: number;
  dispatch: any;
}

const ConditionalDropdown = (props: ConditionalDropdownProps) => {
  const possibleParentQuestions = props.questions.filter(
    (question: any, index: number) =>
      question.staticQuestions.Type === QuestionType.SingleSelect &&
      index < props.index,
  );
  if (possibleParentQuestions.length > 0) {
    const list: any = [{ label: "No parent", value: "" }];
    possibleParentQuestions.forEach((parent: any) => {
      parent.dynamicResponses.Elements.forEach((response: any) => {
        list.push({
          label: `${parent.staticQuestions.Text}: ${response.textValue}`,
          value: isNumeric(response.id)
            ? `${parent.id}_${response.id}`
            : response.id,
        });
      });
    });
    const findParent = (id: string) => {
      if (!id) {
        return "";
      }
      const idArray = id.split("_");
      let parent = "";
      possibleParentQuestions.forEach((question: any) => {
        question.dynamicResponses.Elements.forEach((response: any) => {
          if (
            response.id === id ||
            (idArray.length === 2 &&
              idArray[0] == question.id &&
              idArray[1] == response.id)
          ) {
            parent = `${question.staticQuestions.Text}: ${response.textValue}`;
          }
        });
      });
      return parent;
    };
    return (
      <Form.Group as={Row}>
        <Col sm="7" />
        <Col
          sm="2"
          style={{
            textAlign: "right",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}>
          Conditional on:
        </Col>
        <Col>
          {props.questions[props.index].staticQuestions.Uber ? (
            <OverlayTrigger
              placement="auto"
              overlay={
                <Tooltip id="master-cannot-be-conditional">
                  A Master question cannot be conditional
                </Tooltip>
              }>
              <span>
                <Select
                  onUpdate={(event: any) =>
                    props.dispatch({
                      type: "setConditional",
                      questionIndex: props.index,
                      parent: event.value,
                    })
                  }
                  name="ConditionalQuestionSelector"
                  value={props.questions[props.index].staticQuestions.Parent}
                  readOnlyValue={findParent(
                    props.questions[props.index].staticQuestions.Parent,
                  )}
                  optionsList={list}
                  optionsChild={(item: any) => ({
                    label: item.label,
                    value: item.value,
                  })}
                  readOnly={props.questions[props.index].readOnly}
                  disabled
                />
              </span>
            </OverlayTrigger>
          ) : (
            <Select
              onUpdate={(event: any) =>
                props.dispatch({
                  type: "setConditional",
                  questionIndex: props.index,
                  parent: event.value,
                })
              }
              name="ConditionalQuestionSelector"
              value={props.questions[props.index].staticQuestions.Parent}
              readOnlyValue={findParent(
                props.questions[props.index].staticQuestions.Parent,
              )}
              optionsList={list}
              optionsChild={(item: any) => ({
                label: item.label,
                value: item.value,
              })}
              readOnly={props.questions[props.index].readOnly}
            />
          )}
        </Col>
      </Form.Group>
    );
  }
  return null;
};

const renderQuestionResponseButton = (obj: QuestionProps) => {
  if (obj.staticQuestions.Type === QuestionType.SingleSelect) {
    return (
      <div style={{ textAlign: "right" }}>
        <Button
          variant="dark"
          onClick={() =>
            obj.dispatch({
              type: "addResponse",
              questionIndex: obj.index,
              dispatch: obj.dispatch,
            })
          }>
          <Plus className="feather" />
          Add a Response
        </Button>
      </div>
    );
  }
  if (obj.staticQuestions.Type === QuestionType.Custom) {
    return (
      <DropdownButton
        variant="dark"
        style={{ marginLeft: "72%" }}
        id="customFieldSelect"
        title="Add Custom Response"
        onSelect={(selected: any) => {
          const sel = obj.customFields.find(
            (cf: any) => cf.CustomFieldID === selected,
          );
          obj.dispatch({
            type: "addCustomResponse",
            questionIndex: obj.index,
            customType: {
              id: selected,
              label: sel.CustomFieldLabel,
              type: sel.CustomFieldTypeID,
            },
            dispatch: obj.dispatch,
          });
        }}>
        {obj.customFields.map((customField: any, index: any) => {
          if (
            !obj.dynamicResponses.Elements.find(
              (objecttype: any) =>
                objecttype.customFieldid === customField.CustomFieldID,
            )
          ) {
            return (
              <Dropdown.Item
                eventKey={customField.CustomFieldID}
                key={
                  index
                }>{`${customField.CustomFieldLabel}: ${customField.CustomFieldTypeName}`}</Dropdown.Item>
            );
          }
        })}
      </DropdownButton>
    );
  }
  return null;
};

type ResponseProps = {
  id: number;
  type: string;
  index: number;
  questionIndex: number;
  selectValue: string;
  textValue: string;
  customFieldid: string;
  riskMatrixDimensionTypeID?: string;
  dispatch: any;
  readOnly: boolean;
  viewMode: boolean;
  maxIndex: number;
  showCharacterLimit: boolean;
  customFields?: any[];
  state: any;
};

const Response: any = (obj: ResponseProps) => {
  const [focus, setFocus] = useState(false);
  const maxCharacters = 512;

  const handleFocus = () => {
    setFocus(true);
  };
  const handleUnfocus = () => {
    setFocus(false);
  };
  if (obj.readOnly) {
    if (obj.type === QuestionType.SingleSelect) {
      return (
        <div id="static" style={{ paddingTop: "10px", minHeight: "165px" }}>
          <Form.Group as={Row}>
            <Col sm="2" className="colTitle">
              <h2>Response {obj.index + 1}</h2>
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm="2" className="greyTitle">
              Response Text
            </Form.Label>
            <Col sm="10">{obj.textValue}</Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm="2" className="greyTitle">
              Response Status
            </Form.Label>
            <Col sm="10">{formatResponseAlignment(obj.selectValue)}</Col>
          </Form.Group>
          {obj.index + 1 !== obj.maxIndex ? (
            <hr style={{ marginTop: "30px" }} />
          ) : null}
        </div>
      );
    }
    if (obj.type === QuestionType.Notes) {
      return (
        <div
          id="dystatic"
          style={{
            paddingTop: "10px",
            paddingBottom: "20px",
            minHeight: "165px",
          }}>
          <Form.Group as={Row}>
            <Col sm="2" className="colTitle">
              <h2>Text response</h2>
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm="2" className="greyTitle">
              Response
            </Form.Label>
            <Col sm="10">
              <Form.Control
                as="textarea"
                rows="3"
                value={""}
                disabled={true}
                placeholder={
                  "When answering, a textbox will be shown to fill out"
                }
              />
            </Col>
          </Form.Group>
        </div>
      );
    }
    if (
      obj.type === QuestionType.InherentRisk ||
      obj.type === QuestionType.ResidualRisk
    ) {
      if (obj.index === 0) {
        return (
          <div
            id="dystatic"
            style={{
              paddingTop: "10px",
              paddingBottom: "20px",
              minHeight: "165px",
            }}>
            <Form.Group as={Row}>
              <Col sm="2" className="colTitle">
                <h2>Risk</h2>
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm="2" className="greyTitle">
                Response
              </Form.Label>
              <Col sm="7">
                <Matrix
                  RiskMatrix={obj.state.riskMatrix}
                  Responses={
                    formatToDB(obj.state).find(
                      (question: any) =>
                        question.QuestionTypeID === QuestionType.InherentRisk ||
                        question.QuestionTypeID === QuestionType.ResidualRisk,
                    ).Responses
                  }
                  readOnly={true}
                  actionQuestion={
                    obj.type === QuestionType.InherentRisk
                      ? "Inherent Risk"
                      : "Residual Risk"
                  }
                />
              </Col>
            </Form.Group>
          </div>
        );
      }
      return null;
    }
    if (obj.type === QuestionType.RiskControls) {
      return (
        <div
          id="dystatic"
          style={{
            paddingTop: "10px",
            paddingBottom: "20px",
            minHeight: "165px",
          }}>
          <Form.Group as={Row}>
            <Col sm="2" className="colTitle">
              <h2>Risk</h2>
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm="2" className="greyTitle">
              Response
            </Form.Label>
            <Col sm="10">Control list</Col>
          </Form.Group>
        </div>
      );
    }
    if (obj.type === QuestionType.Custom) {
      return (
        <div
          id="dystatic"
          style={{
            paddingTop: "10px",
            paddingBottom: "20px",
            minHeight: "165px",
          }}>
          <Form.Group as={Row}>
            <Col sm="2" className="colTitle">
              <h2>Response {obj.index + 1}</h2>
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm="2" className="greyTitle">
              {obj.textValue}
            </Form.Label>
            <Col sm="10">
              {/* <Form.Control as='textarea' rows="3" value={''} disabled={true} placeholder={"When answering,the customField in preview should show instead "}/> */}
              {CustomFieldType.getCustomFieldType(
                getCFType(obj.customFieldid, obj.customFields),
              ) === "Single Select List" ? (
                <Form.Control
                  as="textarea"
                  rows="1"
                  value={""}
                  disabled={true}
                  placeholder={
                    "When answering, you will be able to choose from a list"
                  }
                />
              ) : CustomFieldType.getCustomFieldType(
                  getCFType(obj.customFieldid, obj.customFields),
                ) === "Number" ? (
                <Form.Control
                  as="textarea"
                  rows="1"
                  value={""}
                  disabled={true}
                  placeholder={
                    "When answering, you will be able to enter a number"
                  }
                />
              ) : CustomFieldType.getCustomFieldType(
                  getCFType(obj.customFieldid, obj.customFields),
                ) === "Text" ? (
                <Form.Control
                  as="textarea"
                  rows="1"
                  value={""}
                  disabled={true}
                  placeholder={
                    "When answering, a textline will be shown to fill out"
                  }
                />
              ) : CustomFieldType.getCustomFieldType(
                  getCFType(obj.customFieldid, obj.customFields),
                ) === "Long Text" ? (
                <Form.Control
                  as="textarea"
                  rows="3"
                  value={""}
                  disabled={true}
                  placeholder={
                    "When answering, a textbox will be shown to fill out"
                  }
                />
              ) : CustomFieldType.getCustomFieldType(
                  getCFType(obj.customFieldid, obj.customFields),
                ) === "Date" ? (
                <Form.Control
                  as="textarea"
                  rows="1"
                  value={""}
                  disabled={true}
                  placeholder={
                    "When answering, you will be able to pick a date"
                  }
                />
              ) : CustomFieldType.getCustomFieldType(
                  getCFType(obj.customFieldid, obj.customFields),
                ) === "Link" ? (
                <Form.Control
                  as="textarea"
                  rows="1"
                  value={""}
                  disabled={true}
                  placeholder={
                    "When answering, you will be able to enter a link"
                  }
                />
              ) : CustomFieldType.getCustomFieldType(
                  getCFType(obj.customFieldid, obj.customFields),
                ) === "Upload" ? (
                <Form.Control
                  as="textarea"
                  rows="1"
                  value={""}
                  disabled={true}
                  placeholder={
                    "When answering, you will be able to upload files"
                  }
                />
              ) : CustomFieldType.getCustomFieldType(
                  getCFType(obj.customFieldid, obj.customFields),
                ) === "OneDrive Upload" ? (
                <Form.Control
                  as="textarea"
                  rows="1"
                  value={""}
                  disabled={true}
                  placeholder={
                    "When answering, you will be able to upload files to OneDrive"
                  }
                />
              ) : CustomFieldType.getCustomFieldType(
                  getCFType(obj.customFieldid, obj.customFields),
                ) === "Multi-select List" ? (
                <Form.Control
                  as="textarea"
                  rows="1"
                  value={""}
                  disabled={true}
                  placeholder={
                    "When answering, you will be able to choose multiple items from a list"
                  }
                />
              ) : null}
            </Col>
          </Form.Group>
        </div>
      );
    }
    if (obj.type === QuestionType.AlertRightNow) {
      return (
        <div
          id="dystatic"
          style={{
            paddingTop: "10px",
            paddingBottom: "20px",
            minHeight: "165px",
          }}>
          <Form.Group as={Row}>
            <Col sm="2" className="colTitle">
              <h2>Alert users</h2>
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm="2" className="greyTitle">
              Response
            </Form.Label>
            <Col sm="10">Select users to notify by email</Col>
          </Form.Group>
        </div>
      );
    }
    return null;
  }
  if (obj.type === QuestionType.SingleSelect) {
    return (
      <Draggable
        key={obj.id.toString()}
        draggableId={obj.id.toString()}
        index={obj.index}>
        {(provided, snapshot) => (
          <div
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
            style={getResponseStyle(
              snapshot.isDragging,
              provided.draggableProps.style,
            )}>
            <div>
              <Row>
                <Col sm="11"></Col>
                <Col sm="1">
                  <DeleteButton
                    hasConditionalChildren={hasConditionalChildren(
                      obj.state.questions.Elements,
                      null,
                      obj.id,
                      obj.questionIndex,
                    )}
                    handleClick={() =>
                      obj.dispatch({
                        type: "removeResponse",
                        id: obj.id,
                        questionIndex: obj.questionIndex,
                      })
                    }
                    tooltipText={`Another question is conditional on this response - it cannot be deleted.`}
                  />
                  {/* <button disabled={true} style={{backgroundColor: "white", border: "none", marginTop: "10px", marginRight: "10px"}} onClick={() => obj.dispatch({ type: 'removeResponse', id: obj.id, questionIndex: obj.questionIndex})}>
                                            <Trash2 style={{color:"#d8d8d8"}} className="feather"/>
                                        </button> */}
                </Col>
              </Row>
            </div>
            <div>
              <Form.Group as={Row}>
                <Col sm="3" style={{ textAlign: "right" }}>
                  <Row>
                    <Col sm="4" style={{ paddingTop: "5px" }}>
                      <Move style={{ color: "#d8d8d8" }} />
                    </Col>
                    <Col sm="8">
                      <h2>Response {obj.index + 1}</h2>
                    </Col>
                  </Row>
                </Col>
                <Col sm="9"></Col>
              </Form.Group>

              <Form.Group as={Row}>
                <Form.Label column sm="3">
                  Response Text
                </Form.Label>
                <Col sm="5">
                  <Form.Control
                    type="text"
                    style={{ width: "100%", borderRadius: "25px" }}
                    onFocus={obj.showCharacterLimit ? handleFocus : undefined}
                    onBlur={obj.showCharacterLimit ? handleUnfocus : undefined}
                    value={obj.textValue}
                    onChange={(event: any) => {
                      if (maxCharacters - event.target.value.length >= 0) {
                        obj.dispatch({
                          type: "setResponseText",
                          index: obj.index,
                          questionIndex: obj.questionIndex,
                          value: event.target.value,
                        });
                      }
                    }}
                  />
                  {obj.showCharacterLimit && focus ? (
                    <span className="form-label-small">
                      {maxCharacters -
                        (obj.textValue ? obj.textValue.length : 0)}{" "}
                      characters remaining
                    </span>
                  ) : (
                    <div style={{ height: "24px" }}></div>
                  )}
                </Col>
                <Col sm="4">
                  {/* <HelpText id={`Help${obj.index}`} text={"TODO, there will be some actual info here soon!"} /> */}
                </Col>
              </Form.Group>

              <Form.Group as={Row}>
                <Form.Label column sm="3">
                  Response Status
                </Form.Label>
                <Col sm="5">
                  <Select
                    onUpdate={(event: any) => {
                      obj.dispatch({
                        type: "setResponseSelect",
                        index: obj.index,
                        questionIndex: obj.questionIndex,
                        value: event.value,
                      });
                    }}
                    name="Response Status"
                    value={obj.selectValue}
                    unselectedOption={"Select a Value"}
                    optionsList={[
                      {
                        ListValueID: ObjectStatus.Positive,
                        ListValue: formatResponseAlignment(
                          ObjectStatus.Positive,
                        ),
                      },
                      {
                        ListValueID: ObjectStatus.Neutral,
                        ListValue: formatResponseAlignment(
                          ObjectStatus.Neutral,
                        ),
                      },
                      {
                        ListValueID: ObjectStatus.Negative,
                        ListValue: formatResponseAlignment(
                          ObjectStatus.Negative,
                        ),
                      },
                    ]}
                    optionsListID={"ListValueID"}
                    optionsListValue={"ListValue"}
                    readOnly={false}
                  />
                </Col>
                <Col sm="4">
                  {/* <HelpText id={`Help${obj.index}`} text={"TODO, there will be some actual info here soon!"} /> */}
                </Col>
              </Form.Group>
              {obj.index + 1 !== obj.maxIndex ? (
                <hr style={{ marginTop: "30px" }} />
              ) : null}
            </div>
          </div>
        )}
      </Draggable>
    );
  }
  if (obj.type === QuestionType.Notes) {
    return (
      <div style={{ padding: "30px", minHeight: "165px" }}>
        <div>
          <Row>
            <Col sm="11"></Col>
            <Col sm="1"></Col>
          </Row>
        </div>
        <div>
          <Form.Group as={Row}>
            <Col sm="3" style={{ textAlign: "right" }} className="colTitle">
              <h2>Text response</h2>
            </Col>
          </Form.Group>

          <Form.Group as={Row}>
            <Form.Label column sm="3">
              Response
            </Form.Label>
            <Col sm="6">
              <Form.Control
                as="textarea"
                rows="3"
                value={""}
                disabled={true}
                placeholder={
                  "When answering, a textbox will be shown to fill out"
                }
              />
            </Col>
            <Col sm="3">
              {/* <HelpText id={`Help${obj.index}`} text={"TODO, there will be some actual info here soon!"} /> */}
            </Col>
          </Form.Group>
        </div>
      </div>
    );
  }
  if (
    obj.type === QuestionType.InherentRisk ||
    obj.type === QuestionType.ResidualRisk
  ) {
    if (obj.index === 0) {
      return (
        <div style={{ padding: "30px", minHeight: "165px" }}>
          <div>
            <Row>
              <Col sm="11" />
              <Col sm="1" />
            </Row>
          </div>
          <div>
            <Form.Group as={Row}>
              <Col sm="3" style={{ textAlign: "right" }} className="colTitle">
                <h2>Risk Assessment</h2>
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm="3">
                Response
              </Form.Label>
              <Col sm="6">
                <Matrix
                  RiskMatrix={obj.state.riskMatrix}
                  Responses={
                    formatToDB(obj.state).find(
                      (question: any) =>
                        question.QuestionTypeID === QuestionType.InherentRisk ||
                        question.QuestionTypeID === QuestionType.ResidualRisk,
                    ).Responses
                  }
                  readOnly={true}
                  actionQuestion={
                    obj.type === QuestionType.InherentRisk
                      ? "Inherent Risk"
                      : "Residual Risk"
                  }
                />
              </Col>
              <Col sm="3">
                {/* <HelpText id={`Help${obj.index}`} text={"TODO, there will be some actual info here soon!"} /> */}
              </Col>
            </Form.Group>
          </div>
        </div>
      );
    } else return null;
  }
  if (obj.type === QuestionType.RiskControls) {
    return (
      <div style={{ padding: "30px", minHeight: "165px" }}>
        <div>
          <Row>
            <Col sm="11"></Col>
            <Col sm="1"></Col>
          </Row>
        </div>
        <div>
          <Form.Group as={Row}>
            <Col sm="3" style={{ textAlign: "right" }} className="colTitle">
              <h2>Controls</h2>
            </Col>
          </Form.Group>

          <Form.Group as={Row}>
            <Form.Label column sm="3">
              Response
            </Form.Label>
            <Col sm="6">Risk controls will be displayed here</Col>
            <Col sm="3">
              {/* <HelpText id={`Help${obj.index}`} text={"TODO, there will be some actual info here soon!"} /> */}
            </Col>
          </Form.Group>
        </div>
      </div>
    );
  } else if (obj.type === QuestionType.Custom) {
    return (
      <Draggable
        key={obj.id.toString()}
        draggableId={obj.id.toString()}
        index={obj.index}>
        {(provided, snapshot) => (
          <div
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
            style={getResponseStyle(
              snapshot.isDragging,
              provided.draggableProps.style,
            )}>
            <div>
              <Row>
                <Col sm="11"></Col>
                <Col sm="1">
                  <button
                    style={{
                      backgroundColor: "white",
                      border: "none",
                      marginTop: "10px",
                      marginRight: "10px",
                    }}
                    onClick={() =>
                      obj.dispatch({
                        type: "removeResponse",
                        id: obj.id,
                        questionIndex: obj.questionIndex,
                      })
                    }>
                    <Trash2 style={{ color: "#d8d8d8" }} className="feather" />
                  </button>
                </Col>
              </Row>
            </div>
            <div>
              <Form.Group as={Row}>
                <Col sm="3" style={{ textAlign: "right" }}>
                  <Row>
                    <Col sm="4" style={{ paddingTop: "5px" }}>
                      <Move style={{ color: "#d8d8d8" }} />
                    </Col>
                    <Col sm="8">
                      <h2>Response {obj.index + 1}</h2>
                    </Col>
                  </Row>
                </Col>
                <Col sm="9"></Col>
              </Form.Group>

              <Form.Group as={Row}>
                <Form.Label column sm="3">
                  {obj.textValue}
                </Form.Label>
                <Col sm="6">
                  {CustomFieldType.getCustomFieldType(
                    getCFType(obj.customFieldid, obj.customFields),
                  ) === "Single Select List" ? (
                    <Form.Control
                      as="textarea"
                      rows="1"
                      value={""}
                      disabled={true}
                      placeholder={
                        "When answering, you will be able to choose from a list"
                      }
                    />
                  ) : CustomFieldType.getCustomFieldType(
                      getCFType(obj.customFieldid, obj.customFields),
                    ) === "Number" ? (
                    <Form.Control
                      as="textarea"
                      rows="1"
                      value={""}
                      disabled={true}
                      placeholder={
                        "When answering, you will be able to enter a number"
                      }
                    />
                  ) : CustomFieldType.getCustomFieldType(
                      getCFType(obj.customFieldid, obj.customFields),
                    ) === "Text" ? (
                    <Form.Control
                      as="textarea"
                      rows="1"
                      value={""}
                      disabled={true}
                      placeholder={
                        "When answering, a textline will be shown to fill out"
                      }
                    />
                  ) : CustomFieldType.getCustomFieldType(
                      getCFType(obj.customFieldid, obj.customFields),
                    ) === "Long Text" ? (
                    <Form.Control
                      as="textarea"
                      rows="3"
                      value={""}
                      disabled={true}
                      placeholder={
                        "When answering, a textbox will be shown to fill out"
                      }
                    />
                  ) : CustomFieldType.getCustomFieldType(
                      getCFType(obj.customFieldid, obj.customFields),
                    ) === "Date" ? (
                    <Form.Control
                      as="textarea"
                      rows="1"
                      value={""}
                      disabled={true}
                      placeholder={
                        "When answering, you will be able to pick a date"
                      }
                    />
                  ) : CustomFieldType.getCustomFieldType(
                      getCFType(obj.customFieldid, obj.customFields),
                    ) === "Link" ? (
                    <Form.Control
                      as="textarea"
                      rows="1"
                      value={""}
                      disabled={true}
                      placeholder={
                        "When answering, you will be able to enter a link"
                      }
                    />
                  ) : CustomFieldType.getCustomFieldType(
                      getCFType(obj.customFieldid, obj.customFields),
                    ) === "Upload" ? (
                    <Form.Control
                      as="textarea"
                      rows="1"
                      value={""}
                      disabled={true}
                      placeholder={
                        "When answering, you will be able to upload files"
                      }
                    />
                  ) : CustomFieldType.getCustomFieldType(
                      getCFType(obj.customFieldid, obj.customFields),
                    ) === "OneDrive Upload" ? (
                    <Form.Control
                      as="textarea"
                      rows="1"
                      value={""}
                      disabled={true}
                      placeholder={
                        "When answering, you will be able to upload files to OneDrive"
                      }
                    />
                  ) : CustomFieldType.getCustomFieldType(
                      getCFType(obj.customFieldid, obj.customFields),
                    ) === "Multi-select List" ? (
                    <Form.Control
                      as="textarea"
                      rows="1"
                      value={""}
                      disabled={true}
                      placeholder={
                        "When answering, you will be able to choose multiple items from a list"
                      }
                    />
                  ) : null}
                </Col>
                <Col sm="3">
                  {/* <HelpText id={`Help${obj.index}`} text={"TODO!"} /> */}
                </Col>
              </Form.Group>
              {obj.index + 1 !== obj.maxIndex ? (
                <hr style={{ marginTop: "30px" }} />
              ) : null}
            </div>
          </div>
        )}
      </Draggable>
    );
  } else if (obj.type === QuestionType.AlertRightNow) {
    return (
      <div style={{ padding: "30px", minHeight: "165px" }}>
        <div>
          <Row>
            <Col sm="11"></Col>
            <Col sm="1"></Col>
          </Row>
        </div>
        <div>
          <Form.Group as={Row}>
            <Col sm="3" style={{ textAlign: "right" }} className="colTitle">
              <h2>Alert users</h2>
            </Col>
          </Form.Group>

          <Form.Group as={Row}>
            <Form.Label column sm="3">
              Response
            </Form.Label>
            <Col sm="6">Select users to notify by email</Col>
            <Col sm="3">
              {/* <HelpText id={`Help${obj.index}`} text={"TODO, there will be some actual info here soon!"} /> */}
            </Col>
          </Form.Group>
        </div>
      </div>
    );
  } else return null;
};

/*
 *   Styling
 */

const getQuestionWrapStyle = (isDraggingOver: boolean) => ({
  // background: isDraggingOver ? "lightblue" : "white"
});

const getQuestionStyle = (
  isDragging: boolean,
  draggableStyle: any,
  readOnly: boolean,
) => {
  if (readOnly) {
    return {
      // background: isDragging ? "lightgreen" : "white",
      width: "100%",
      boxShadow: isDragging ? "0 3px 10px 0 rgba(0, 0, 0, 0.17)" : "",
      background: "white",

      // styles we need to apply on draggables
      ...draggableStyle,
    };
  }
  return {
    // background: isDragging ? "lightgreen" : "white",
    width: "100%",
    borderLeft: "solid 0.7rem #374750",
    paddingLeft: "40px",
    padding: "30px 20px 30px 40px",
    boxShadow: "0 3px 10px 0 rgba(0, 0, 0, 0.17)",
    backgroundColor: "#ffffff",

    // styles we need to apply on draggables
    ...draggableStyle,
  };
};

const getResponseStyle = (isDragging: boolean, draggableStyle: any) => ({
  // background: isDragging ? "lightgreen" : "white",
  boxShadow: isDragging ? "0 3px 10px 0 rgba(0, 0, 0, 0.17)" : "",
  background: "white",
  minHeight: "190px",

  // styles we need to apply on draggables
  ...draggableStyle,
});

export { QuestionWrapper };
