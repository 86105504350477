import React from "react";
import { Form, InputGroup } from "react-bootstrap";

interface NumberProps {
  value: string;
  name: string;
  onUpdate?: (event: any) => void;
  readOnly: boolean;
  disabled?: boolean;
  min?: number;
  max?: number;
  step?: number;
  defaultValue?: string;
  textAlignment?: string;
  style?: any;
  inputGroupStyle?: any;
  unit?: string;
}

export const Number = (props: NumberProps) =>
  !props.readOnly ? (
    <InputGroup
      style={props.inputGroupStyle ? props.inputGroupStyle : undefined}>
      <Form.Control
        type="number"
        name={props.name}
        value={props.value}
        onChange={props.onUpdate}
        min={props.min}
        max={props.max}
        step={props.step}
        defaultValue={props.defaultValue}
        style={props.style ? props.style : undefined}
        disabled={props.disabled}
      />
      {props.unit ? (
        <InputGroup.Append>
          <InputGroup.Text id="basic-addon2">{props.unit}</InputGroup.Text>
        </InputGroup.Append>
      ) : null}
    </InputGroup>
  ) : (
    <>
      {props.value}
      {props.unit ? (
        <span className="col-form-label form-unit">{props.unit}</span>
      ) : null}
    </>
  );
