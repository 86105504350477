/* eslint-disable react/jsx-boolean-value */
import {
  Box,
  Button,
  Divider,
  Heading,
  HStack,
  useColorModeValue,
  chakra,
  Image,
  Menu,
  MenuButton,
  Icon,
} from "@chakra-ui/react";
import React from "react";
import { useMatch, useNavigate } from "react-router-dom";
import NewTemplateMenu from "../components/chakra/templates/NewTemplateMenu";
import AccountLogo from "../components/UI/AccountLogo";
import useSystemMode from "../utilities/useSystemMode";
import TopBar from "./TopBar";
import headerImgLandscape from "../images/logo_landscape.png";
import TopbarAccount from "../components/UI/Sidebar/TopbarAccount";
import { RiAlertLine, RiArrowDownSLine } from "react-icons/ri";

type Props = {
  children: React.ReactNode;
  showSidebarDrawerButton: boolean;
  handleClickSidebarDrawer: () => void;
};

const MainLayout = ({
  children,
  showSidebarDrawerButton,
  handleClickSidebarDrawer,
}: Props) => {
  const systemMode = useSystemMode();
  const templateType =
    useMatch("/template/:templateType")?.params.templateType || null;
  const topBarBg = useColorModeValue("white", "gray.800");
  const topBarBorder = useColorModeValue("gray.100", "gray.700");
  const handleClickGetOut = () => {
    window.location.href = "/";
  };

  return (
    <div>
      <Box
        borderBottomWidth="1px"
        borderBottomStyle="solid"
        borderBottomColor={topBarBorder}
        boxShadow="sm"
        zIndex={12}
        width="full"
        pos="fixed"
        h="top-bar"
        bg={topBarBg}>
        <chakra-scope>
          {systemMode === "template" ? (
            <HStack
              alignItems="center"
              spacing="6"
              justifyContent="space-between"
              w="full"
              h="full">
              <HStack h="full" spacing={6} px={4}>
                <AccountLogo maxH={8} minW={12} maxW={32} />
                <Divider orientation="vertical" />
                <Heading size="md" color="red.800">
                  Template Management
                </Heading>
              </HStack>
              <HStack h="full" spacing={6} px={4}>
                {templateType !== null && (
                  <NewTemplateMenu objectTypeGroupName={templateType} />
                )}
                <Button onClick={handleClickGetOut} colorScheme="red">
                  Get Out
                </Button>
              </HStack>
            </HStack>
          ) : systemMode === "blueprint" ? (
            <HStack
              alignItems="center"
              spacing="6"
              justifyContent="space-between"
              w="full"
              h="full">
              <HStack h="full" spacing={6} px={4}>
                <Menu closeOnSelect={false} placement="bottom" isLazy>
                  <MenuButton
                    as={Button}
                    rightIcon={<Icon as={RiArrowDownSLine} />}
                    variant="ghost"
                    colorScheme="gray">
                    <HStack ps="2" h="full" spacing="4">
                      <Image
                        src={headerImgLandscape}
                        maxH={8}
                        minW={12}
                        maxW={32}
                      />
                    </HStack>
                  </MenuButton>
                  <TopbarAccount />
                </Menu>

                <Divider orientation="vertical" />
                <Heading size="md" color="brand.800">
                  Account Setup
                </Heading>
              </HStack>
            </HStack>
          ) : (
            <TopBar
              showSidebarDrawerButton={showSidebarDrawerButton}
              handleClickSidebarDrawer={handleClickSidebarDrawer}
            />
          )}
        </chakra-scope>
      </Box>

      <chakra.div pt="top-bar" w="100%" m="0">
        {systemMode === "template" && (
          <Box padding={4} color="white" bg="red.400" w="full">
            <Icon size="lg" as={RiAlertLine} margin="0 8px" />
            You are in Template Management mode. You can exit at any time by
            pressing the 'Get Out' button.
          </Box>
        )}
        {children}
      </chakra.div>
    </div>
  );
};

export default MainLayout;
