import React from "react";
import { Form, Col, Row, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Text } from "../../Forms/Field/Text";
import { Select } from "../../Forms/Field/Select";

const HttpWithUrl = (props: any) => {
  // Set the protocol to protocol of prop.value. If undefined, protocol is http
  const proto: any = props.value
    ? props.value.split("://")[props.value.split("//").length - 2]
    : "http";

  // Strip the protocol from the props.value
  const urlValue = props.value
    ? props.value.split("://")[props.value.split("//").length - 1]
    : undefined;

  if (props.readOnly === false) {
    return (
      <Row>
        <Col
          style={{
            paddingRight: 0,
            maxWidth: props.customField ? "19%" : "initial",
          }}
          className="text-right"
          sm={4}>
          <Select
            onUpdate={(event: any) => {
              // Get the path value of props.valu
              const path = props.value
                ? props.value.split("//")[props.value.split("//").length - 1]
                : "";
              // Update the URL to have new protocol at the front of path
              props.onUpdate(`${event.value}://${path}`);
            }}
            className="select1"
            name={proto}
            value={proto}
            optionsList={[
              { value: "http", label: "http://" },
              { value: "https", label: "https://" },
            ]}
            optionsListID="value"
            optionsListValue="label"
            readOnly={props.readOnly}
            disabled={props.disabled}
            customField={
              props.customField !== undefined ? props.customField : false
            }
          />
        </Col>
        <Col
          sm={props.customField ? 9 : 8}
          style={{
            paddingLeft: 0,
            maxWidth: props.customField ? "81%" : "66%",
            flex: props.customField ? "81%" : "66%",
          }}>
          <Text
            disabled={props.disabled}
            value={urlValue}
            style={{
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
              width: "90%",
              marginLeft: 0,
              height: 38,
            }}
            name={props.name}
            readOnly={props.readOnly}
            onUpdate={(event: any) => {
              // The new value is the value entered
              let newValue = event.target.value;
              let newProto = proto;
              // IF the new value has a protocol in it, then set the protocol to this value and update the newValue to the correct path
              if (
                newValue.split("http://").length > 1 ||
                newValue.split("https://").length > 1
              ) {
                newProto =
                  newValue.split("://")[newValue.split("://").length - 2];
                newValue =
                  newValue.split("://")[newValue.split("://").length - 1];
              }
              // IF there is a path value
              if (event.target.value) {
                props.onUpdate(`${newProto}://${newValue}`);
              }
              // Else there is no path value. just update with protocol and no path value
              else {
                props.onUpdate(`${newProto}://`);
              }
            }}
          />
        </Col>
      </Row>
    );
  }
  if (
    props.customField === true &&
    props.value !== "" &&
    props.value !== null &&
    props.value !== "http://" &&
    props.value !== "https://"
  ) {
    return (
      <>
        <OverlayTrigger
          placement="auto"
          overlay={<Tooltip id={props.value}>{props.value}</Tooltip>}>
          <button
            type="button"
            className="linkButton"
            onClick={() => window.open(props.value ? props.value : "")}>
            Go to link
          </button>
        </OverlayTrigger>
      </>
    );
  }
  return (
    <Text
      value={urlValue}
      name={props.name}
      readOnly={props.readOnly}
      onUpdate={props.onUpdate}
    />
  );
};

export { HttpWithUrl };
