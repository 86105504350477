import { createContext, Dispatch, ReactNode, useReducer } from "react";
import { createSearchController } from "./SearchController";

export type State<T> = {
  data: T[];
};

export type Action<T> = {
  type: "SET_DATA";
  data: T[];
};

type Props = {
  children: ReactNode | ReactNode[];
};

export const initialState = {
  data: [],
};

export const SearchProviderFactory = <T,>() => {
  const reducer = (state: State<T>, action: Action<T>) => {
    switch (action.type) {
      case "SET_DATA":
        return {
          ...state,
          data: action.data,
        };

      default:
        return state;
    }
  };

  const SearchContext = createContext<{
    state: State<T>;
    dispatch: Dispatch<Action<T>>;
  }>({
    state: initialState,
    dispatch: (action: Action<T>) => {},
  });

  const SearchProvider = ({ children }: Props) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    return (
      <SearchContext.Provider value={{ state, dispatch }}>
        {children}
      </SearchContext.Provider>
    );
  };

  const SearchController = createSearchController(SearchContext);

  return { SearchContext, SearchProvider, SearchController };
};

export type SeachProviderComponentType<T> = ReturnType<
  typeof SearchProviderFactory<T>
>;
