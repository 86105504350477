import { chakra, Tooltip } from "@chakra-ui/react";
import { stripHtml as stringStripHtml } from "string-strip-html";
import RichText from "../../form/RichText";

type Props = {
  description?: string | null;
};

const TableDescriptionField = ({ description }: Props) => {
  const stripped = description ? stringStripHtml(description).result : "";

  return (
    <Tooltip
      bg="gray.50"
      color="black"
      label={
        <RichText onChange={() => {}} isReadOnly value={description || ""} />
      }>
      <chakra.span
        whiteSpace="nowrap"
        display="inline-block"
        textOverflow="ellipsis"
        overflow="hidden"
        maxW="64">
        {stripped}
      </chakra.span>
    </Tooltip>
  );
};

export default TableDescriptionField;
