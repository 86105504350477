import React, { useState } from "react";
import { Form, InputGroup } from "react-bootstrap";

interface TextProps {
  value?: string;
  name: string;
  onUpdate?: (event: any) => void;
  readOnly: boolean;
  style?: any;
  placeHolder?: any;
  disabled?: boolean;
  showCharacterLimit?: boolean;
  type?: string;
  unit?: string;
  max?: number;
}

export const Text = (props: TextProps) => {
  const [, setFocus] = useState(false);
  const maxCharacters = 512;

  const handleFocus = () => {
    setFocus(true);
  };
  const handleUnfocus = () => {
    setFocus(false);
  };
  return !props.readOnly ? (
    <InputGroup>
      <Form.Control
        type={props.type ? props.type : "text"}
        value={props.value}
        name={props.name}
        onChange={props.onUpdate}
        plaintext={props.readOnly}
        readOnly={props.readOnly}
        disabled={props.disabled}
        style={props.style ? props.style : undefined}
        placeholder={props.placeHolder ? props.placeHolder : undefined}
        maxLength={
          props.max
            ? props.max
            : props.showCharacterLimit
            ? maxCharacters
            : undefined
        }
        onFocus={props.showCharacterLimit ? handleFocus : undefined}
        onBlur={props.showCharacterLimit ? handleUnfocus : undefined}
      />
      {props.unit ? (
        <InputGroup.Append>
          <InputGroup.Text id="basic-addon2">{props.unit}</InputGroup.Text>
        </InputGroup.Append>
      ) : null}
    </InputGroup>
  ) : (
    <div>
      {props.value}
      {props.unit ? (
        <span className="col-form-label form-unit">{props.unit}</span>
      ) : null}
    </div>
  );
};
