import React from "react";
import {
  Row,
  Col,
  Breadcrumb as ReactBootstrapBreadcrumb,
} from "react-bootstrap";
import { NewIssueButton } from "../NewIssueButton";
import { Status, objectStatusToVariant } from "../Status/Status";
import { NewOnDemandActionButton } from "../NewOnDemandActionButton";

interface Breadcrumb {
  title: string;
  link?: string;
}

interface HeaderProps {
  breadcrumbs: Breadcrumb[];
  title: string;
  template?: boolean;
  buttonProps?: { pageStatus: any };
  draft?: boolean;
  subtitle?: string;
  notes?: string;
  timestamp?: string;
}

export const Header = ({
  breadcrumbs,
  template,
  title,
  buttonProps,
  draft,
  timestamp,
  subtitle,
  notes,
}: HeaderProps) => (
  <header>
    <Row>
      <Col sm="auto" style={{ flex: "1 1 auto" }}>
        <ReactBootstrapBreadcrumb>
          {breadcrumbs.map(
            (breadcrumb: Breadcrumb, index: number, array: Breadcrumb[]) =>
              index + 1 !== array.length && breadcrumb.link ? (
                <ReactBootstrapBreadcrumb.Item
                  key={`${breadcrumb.title}.${breadcrumb.link}`}
                  href={breadcrumb.link}>
                  {breadcrumb.title}
                </ReactBootstrapBreadcrumb.Item>
              ) : (
                <ReactBootstrapBreadcrumb.Item
                  key={`${breadcrumb.title}.${breadcrumb.link}`}
                  active>
                  {breadcrumb.title}
                </ReactBootstrapBreadcrumb.Item>
              ),
          )}
        </ReactBootstrapBreadcrumb>
      </Col>
      {!template && buttonProps !== undefined ? (
        <>
          <Col sm="auto">
            <NewOnDemandActionButton
              title="Start a Task"
              variant="primary"
              pageStatus={buttonProps.pageStatus}
            />
          </Col>
          <Col sm="auto">
            <NewIssueButton />
          </Col>
        </>
      ) : null}
    </Row>
    <Row>
      {draft ? (
        <>
          <Col sm={3}>
            <h1 className="page-title">
              {title}
              {timestamp ? (
                <span className="page-title-timestamp"> {timestamp}</span>
              ) : (
                ""
              )}
            </h1>
          </Col>
          <Col style={{ paddingTop: "10px", paddingLeft: 0 }}>
            <Status
              style={{ color: "white" }}
              variant={objectStatusToVariant("Draft")}
              text="Draft"
              tooltip="The Response for this Instance is a Draft"
            />
          </Col>
        </>
      ) : (
        <Col>
          <h1 className="page-title">
            {title}
            {timestamp ? (
              <span className="page-title-timestamp"> {timestamp}</span>
            ) : (
              ""
            )}
          </h1>
        </Col>
      )}
    </Row>
    {subtitle ? (
      <Row>
        <Col>
          <h1
            className="page-title"
            style={{ fontSize: "15px", display: "inline" }}>
            {subtitle}
          </h1>
          {notes}
        </Col>
      </Row>
    ) : null}
  </header>
);

export default Header;
