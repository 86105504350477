import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  HStack,
  Input,
  ListItem,
  UnorderedList,
  VStack,
} from "@chakra-ui/react";
import { Amplify } from "aws-amplify";
import * as React from "react";
import useToast from "../../utilities/useToast";
import { User } from "./AuthComponent";
import AuthScreenContainer from "./AuthScreenContainer";

type Props = {
  user: User | null;
  backToSignin: () => void;
};

const RequireNewPassword = ({ user, backToSignin }: Props) => {
  const [password, setPassword] = React.useState<string>("");
  const [loading, setLoading] = React.useState<boolean>(false);
  const { displayToast } = useToast();
  const [error, setError] = React.useState<{
    error: boolean;
    msg: string | null;
  }>({ error: false, msg: null });

  const submitNewPassword = async () => {
    setLoading(true);
    try {
      await Amplify.Auth.completeNewPassword(user, password);
      displayToast({
        title: "Password Updated",
        description: "Please sign in with your new password",
        status: "success",
      });
      backToSignin();
    } catch (e) {
      setError({
        error: true,
        msg: e instanceof Error ? e.message : "Something wen't wrong",
      });
    }
    setLoading(false);
  };

  return (
    <AuthScreenContainer title="Update your password">
      <VStack spacing={4} align="start">
        <FormControl isRequired isInvalid={error.error}>
          <FormLabel>Password</FormLabel>
          <Input
            onChange={(e) => setPassword(e.target.value)}
            value={password}
            isInvalid={error.error}
            name="password"
            key="new-password"
            id="new-password"
            type="password"
            autoComplete="new-password"
          />
          {error.msg && <FormErrorMessage>{error.msg}</FormErrorMessage>}
        </FormControl>
        <Heading size="xs">Tips for a good password</Heading>
        <UnorderedList stylePosition="inside">
          <ListItem>Use a series of words strung together</ListItem>
          <ListItem>Keep it at least 8 characters long</ListItem>
          <ListItem>Use a password manager</ListItem>
          <ListItem>Don't re-use or recycle old passwords</ListItem>
          <ListItem>Don't use all numbers or all letters</ListItem>
          <ListItem>Don't use single words</ListItem>
        </UnorderedList>
        <Button
          w="full"
          onClick={submitNewPassword}
          isLoading={loading}
          loadingText="Updating password...">
          Update Password
        </Button>
        <HStack w="full" justifyContent="end">
          <Button
            size="sm"
            colorScheme="gray"
            variant="link"
            onClick={backToSignin}>
            Back to Sign In
          </Button>
        </HStack>
      </VStack>
    </AuthScreenContainer>
  );
};

export default RequireNewPassword;
