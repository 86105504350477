import { useMatch } from "react-router-dom";

export type SystemMode = "normal" | "template" | "blueprint";

const useSystemMode = () => {
  const isTemplate = useMatch("/template/*") !== null;
  const isBlueprint = useMatch("/blueprint/*") !== null;
  return isTemplate ? "template" : isBlueprint ? "blueprint" : "normal";
};

export default useSystemMode;
