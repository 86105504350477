import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import moment from "moment";
import { HelpText } from "../HelpText/HelpText";
import { Select } from "../Field/Select";
import { DateField } from "../Field/Date";
import { Number } from "../Field/Number";
import { Textarea } from "../Field/Textarea";
import { Text } from "../Field/Text";
import { HttpWithUrl } from "../../UI/URL/URLSelector";
import { Uploader } from "../Field/Uploader";
import { OneDriveUploader } from "../Field/OneDriveUploader";
import { CustomFieldIDToName } from "../../../constants/customFieldType";
import { MultiSelect } from "../Field/MultiSelect";
import { MandatoryIndicator } from "../MandatoryIndicator/MandatoryIndicator";
import * as RecordStatus from "../../../shared/constants/recordStatus";

type ListValue = {
  ListValueID: string;
  ListValue: any;
  ListURL?: any;
  RecordStatusID?: string;
};

type CustomFields = {
  CustomFieldID: string;
  CustomFieldTypeColumnName: string;
  CustomFieldTypeID: string;
  ValueID?: string;
  IsMandatory?: boolean;
  Label?: string;
  HelpText: string;
  Type?: string;
  Value: any;
  Unit?: string;
  ListValues: null | ListValue[];
};

type CustomFieldProps = {
  customField: CustomFields;
  onUpdate: any;
  formError?: boolean;
  pageStatus: any;
  disabled?: boolean;
  questionid?: string;
  questionOrder?: number;
  report?: boolean;
  operator: "equal to" | "less than" | "less than equal to" | "greater than" | "greater than equal to" | "not equal to" | "contains" | "does not contain" | "relative days - before" | "relative days - after" | "exists" ;
};

const getMultiValues = (values: any[], listOfValues: any) => {
  const multipleValues: any[] = listOfValues.reduce(
    (unique: any[], current: any) => {
      if (values && values.includes(current.ListValueID)) {
        return [...unique, current];
      }
      return unique;
    },
    [],
  );
  return multipleValues;
};

const getCurrentListValue = (value: string, list: any[]) => {
  const readOnlyListItem = list.filter(
    (listItem) => listItem.ListValueID === value,
  );
  if (
    readOnlyListItem.length > 0 &&
    readOnlyListItem[0].ListURL !== undefined &&
    readOnlyListItem[0].ListURL !== null
  ) {
    return undefined;
  }
  return readOnlyListItem.length > 0 ? readOnlyListItem[0].ListValue : "";
};

const getCurrentListComponent = (value: string, list: any[]) => {
  const readOnlyListItem = list.filter(
    (listItem) => listItem.ListValueID === value,
  );
  if (
    readOnlyListItem.length > 0 &&
    readOnlyListItem[0].ListURL !== undefined &&
    readOnlyListItem[0].ListURL !== null
  ) {
    return () => (
      <a
        href={`${readOnlyListItem[0].ListURL}`}
        target="_blank"
        className="notDisabled"
        rel="noreferrer">
        {readOnlyListItem[0].ListValue}
      </a>
    );
  }
  return undefined;
};

const CustomField = (props: CustomFieldProps) => {
  let field = null;
  const readOnly =
    props.pageStatus !== "Editing" &&
    props.pageStatus !== "Responding" &&
    props.pageStatus !== "New" &&
    props.pageStatus !== "Copy";
  if (
    props.customField.CustomFieldTypeColumnName === "ListValueID" &&
    props.customField.ListValues
  ) {
    field =
      CustomFieldIDToName[props.customField.CustomFieldTypeID] ===
        "Single Select List" || props.report ? (
        <Select
          onUpdate={
            props.onUpdate !== undefined
              ? (value: any) =>
                  props.onUpdate({
                    target: {
                      value: value.value,
                      name: props.customField.CustomFieldID,
                      questionID: props.questionid,
                      questionOrder: props.questionOrder,
                    },
                  })
              : props.onUpdate
          }
          name={props.customField.CustomFieldID}
          value={props.customField.Value}
          unselectedOption="Select a Value"
          optionsList={props.customField.ListValues.filter(
            (value) =>
              value.RecordStatusID === undefined ||
              value.RecordStatusID === RecordStatus.Active,
          )} // List items with undefined record status are for Requirements and Actions, we only check the status for instances
          optionsListID="ListValueID"
          optionsListValue="ListValue"
          readOnly={readOnly}
          disabled={props.disabled}
          readOnlyValue={getCurrentListValue(
            props.customField.Value,
            props.customField.ListValues,
          )}
          readOnlyComponent={getCurrentListComponent(
            props.customField.Value,
            props.customField.ListValues,
          )}
          customField
          unit={props.customField.Unit}
        />
      ) : (
        <MultiSelect
          onUpdate={
            props.onUpdate !== undefined
              ? (value: any) =>
                  props.onUpdate({
                    target: {
                      value:
                        value === null ? "" : value.map((v: any) => v.value),
                      name: props.customField.CustomFieldID,
                      questionID: props.questionid,
                      questionOrder: props.questionOrder,
                    },
                  })
              : props.onUpdate
          }
          name={props.customField.CustomFieldID}
          value={getMultiValues(
            props.customField.Value,
            props.customField.ListValues,
          )}
          unselectedOption="Select a Value"
          optionsList={props.customField.ListValues.filter(
            (value) =>
              value.RecordStatusID === undefined ||
              value.RecordStatusID === RecordStatus.Active,
          )} // List items with undefined record status are for Requirements and Actions, we only check the status for instances
          optionsListID="ListValueID"
          optionsListValue="ListValue"
          readOnly={readOnly}
          disabled={props.disabled}
          readOnlyComponent={props.customField.Value}
          expiredReadOnlyComponent={
            props.customField.Value
              ? props.customField.ListValues.filter((value) =>
                  props.customField.Value.includes(value.ListValueID),
                )
              : []
          }
          unit={props.customField.Unit}
          customField
        />
      );
  } else if (
    props.customField.CustomFieldTypeColumnName === "VARCHAR" ||
    ((props.customField.CustomFieldTypeColumnName === "DocumentID" ||
      props.customField.CustomFieldTypeColumnName === "OneDriveDocumentID") &&
      props.report) ||
    (props.customField.CustomFieldTypeColumnName === "URL" && props.report)
  ) {
    // this is so the filters for doc and url in report come as text fields
    // } else if (props.customField.CustomFieldTypeColumnName === 'VARCHAR') {
    field = (
      <Text
        value={props.customField.Value}
        name={props.customField.CustomFieldID}
        onUpdate={
          props.onUpdate !== undefined
            ? (value: any) =>
                props.onUpdate({
                  target: {
                    value: value.target.value,
                    name: props.customField.CustomFieldID,
                    questionID: props.questionid,
                    questionOrder: props.questionOrder,
                  },
                })
            : props.onUpdate
        }
        readOnly={readOnly}
        disabled={props.disabled}
        style={{ padddingTop: 0 }}
        showCharacterLimit={
          props.pageStatus === "Editing" ||
          (props.pageStatus === "New" && props.pageStatus === "Copy")
        }
        unit={props.customField.Unit}
      />
    );
  } else if (props.customField.CustomFieldTypeColumnName === "INT" || props.operator?.includes("relative") ) {
    field = (
      <Number
        value={props.customField.Value}
        name={props.customField.CustomFieldID}
        onUpdate={
          props.onUpdate !== undefined
            ? (value: any) =>
                props.onUpdate({
                  target: {
                    value: value.target.value,
                    name: props.customField.CustomFieldID,
                    questionID: props.questionid,
                    questionOrder: props.questionOrder,
                  },
                })
            : props.onUpdate
        }
        readOnly={readOnly}
        disabled={props.disabled}
        unit={props.customField.Unit}
      />
    );
  }else if (props.customField.CustomFieldTypeColumnName === "DATETIME") {
    field = (
      <DateField
        selected={props.customField.Value}
        name={props.customField.CustomFieldID}
        onUpdate={
          props.onUpdate !== undefined
            ? (date: any) =>
                props.onUpdate({
                  target: {
                    value: date
                      ? moment.utc(date).format("YYYY-MM-DDTHH:mm:ssZ")
                      : null,
                    name: props.customField.CustomFieldID,
                    questionID: props.questionid,
                    questionOrder: props.questionOrder,
                  },
                })
            : props.onUpdate
        }
        readOnly={readOnly}
        disabled={props.disabled}
        unit={props.customField.Unit}
        allDates
      />
    );
  } else if (props.customField.CustomFieldTypeColumnName === "LONGVARCHAR") {
    field = (
      <Textarea
        value={
          props.customField.Value ? props.customField.Value.toString() : ""
        }
        name={props.customField.CustomFieldID}
        onUpdate={
          props.onUpdate
            ? props.onUpdate.bind(null, {
                cfID: props.customField.CustomFieldID,
                questionID: props.questionid,
                questionOrder: props.questionOrder,
              })
            : props.onUpdate
        }
        readOnly={readOnly}
        disabled={props.disabled}
        showCharacterLimit={
          props.pageStatus === "Editing" ||
          (props.pageStatus === "New" && props.pageStatus === "Copy")
        }
        unit={props.customField.Unit}
        richText
      />
    );
  } else if (props.customField.CustomFieldTypeColumnName === "URL") {
    field = (
      <HttpWithUrl
        value={props.customField.Value}
        readOnly={readOnly}
        name={props.customField.CustomFieldID}
        disabled={props.disabled}
        showCharacterLimit={
          props.pageStatus === "Editing" ||
          (props.pageStatus === "New" && props.pageStatus === "Copy")
        }
        onUpdate={
          props.onUpdate !== undefined
            ? (value: any) =>
                props.onUpdate({
                  target: {
                    value: value.replace(/\s/g, ""),
                    name: props.customField.CustomFieldID,
                    questionID: props.questionid,
                    questionOrder: props.questionOrder,
                  },
                })
            : props.onUpdate
        }
        customField
      />
    );
  } else if (props.customField.CustomFieldTypeColumnName === "DocumentID") {
    field = (
      <Uploader
        fileList={props.customField.Value}
        uploading={false}
        onChange={
          props.onUpdate
            ? props.onUpdate.bind(null, {
                cfID: props.customField.CustomFieldID,
                questionID: props.questionid,
                questionOrder: props.questionOrder,
              })
            : props.onUpdate
        }
        readOnly={readOnly}
        disabled={props.disabled}
        name={props.customField.CustomFieldID}
      />
    );
  } else if (
    props.customField.CustomFieldTypeColumnName === "OneDriveDocumentID"
  ) {
    field = (
      <OneDriveUploader
        fileList={props.customField.Value}
        uploading={false}
        onChange={
          props.onUpdate
            ? props.onUpdate.bind(null, {
                cfID: props.customField.CustomFieldID,
                questionID: props.questionid,
                questionOrder: props.questionOrder,
              })
            : props.onUpdate
        }
        readOnly={readOnly}
        disabled={props.disabled}
        name={props.customField.CustomFieldID}
      />
    );
  }
  if (props.report) {
    return <Col>{field}</Col>;
  }
  return (
    <Form.Group as={Row}>
      <Form.Label column sm="2">
        {props.customField.Label}
        {props.customField.IsMandatory &&
        (props.pageStatus === "New" || props.pageStatus === "Editing") ? (
          <MandatoryIndicator
            ID={props.customField.CustomFieldID}
            elementType="field"
          />
        ) : null}
      </Form.Label>
      <Col
        sm={
          (props.customField.CustomFieldTypeColumnName === "DocumentID" ||
            props.customField.CustomFieldTypeColumnName ===
              "OneDriveDocumentID") &&
          readOnly
            ? "7"
            : "8"
        }>
        {field}
      </Col>
      {props.customField.HelpText !== null &&
      props.customField.HelpText !== "" ? (
        <Col sm="1">
          <HelpText
            id={props.customField.CustomFieldID}
            text={props.customField.HelpText}
          />
        </Col>
      ) : null}
    </Form.Group>
  );
};

interface CustomFieldListProps {
  customFields: any[];
  onUpdate?: (event: any) => void;
  formError?: any;
  disabled?: boolean;
  pageStatus:
    | "Ready"
    | "Editing"
    | "New"
    | "Loading"
    | "Submitting"
    | "Responding"
    | "Copy";
}

const CustomFieldList = (props: any) =>
  props.customFields.map((customField: any, index: number) => (
    <CustomField
      key={index}
      customField={customField}
      onUpdate={props.onUpdate}
      disabled={props.disabled}
      formError={props.formError}
      pageStatus={props.pageStatus}
      questionOrder={props.QuestionOrder}
      questionid={props.QuestionID}
    />
  ));

export { CustomFieldList, CustomField };
