const Likelihood = '45fb66e2-7531-466f-a4bd-65cd7e5f72c2';
const Consequence = '61fd7dad-ff3d-4101-923b-7b43681b9c9c';
const RiskLevel = '8717c046-1fcd-49bd-b75b-68c89a739745';

const formatToDisplay = (id: string | undefined): string => {
    switch (id) {
        case Likelihood:
            return "Likelihood"
        case Consequence:
            return "Consequence"
        case RiskLevel:
            return "Risk Level"
        default:
            return ""
    }
}

export { Likelihood, Consequence, RiskLevel, formatToDisplay }