import {
  Box, Center, Spinner,
  Text,
  VStack
} from "@chakra-ui/react";
import React from "react";
type Props = {
  info?: string;
}
const HomeLoading = ({info}: Props) =>(
    <chakra-scope>
      <Box py="12%">
        <Center h="full" w="full">
          <VStack >
            <Spinner
                speed="1.25s"
                thickness="4px"
                color="primary1.main"
                emptyColor="primary2.main"
                size="xl"
            />
            {info && <Text>{info}</Text>}
          </VStack>
        </Center>
      </Box>
    </chakra-scope>
  );
export default HomeLoading;
