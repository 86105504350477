import {
  Input,
  InputGroup,
  InputLeftElement,
  useCallbackRef,
} from "@chakra-ui/react";
import {
  useCallback,
  useEffect,
  useState,
  useMemo,
  useId,
  useContext,
  Context,
  Dispatch,
} from "react";
import { debounce } from "lodash";
import Icon from "./UI/Icon";
import { retryRequestsOf } from "../utilities/retryRequestOf";
import { Action, State } from "./SearchProvider";

/**
 * @property reties: number - number of times to retry the request
 * @property onFecthError: (error: Error) => void - callback to handle fetch errors
 * @property debounceTime: number - time in ms to debounce the search
 * @property shouldRetry: ()=> boolean - function the returns whether to retry the request or not
 **/
type FetchConfig = {
  reties?: number;
  onFetchError?: (error: Error) => void;
  debounceTime?: number;
  shouldRetry?: () => boolean;
};

/**
 * @property filter: A function that takes in the data and returns the filtered data
 * @property debounceTime: time in ms to wait before calling the filter function
 **/
type SearchConfig<T> = {
  filter?: (item: T, search: string) => boolean;
  debounceTime?: number;
};

type BaseAssumedDataType = {
  id: string;
  name: string;
}

type Props<DataType> = {
  data: DataType[] | (() => DataType[]) | (() => Promise<DataType[]>);
  value?: string;
  id?: string;
  placeholder?: string;
  onChangeSearch?: (search: string) => void;
  searchConfig?: SearchConfig<DataType>;
  fetchConfig?: FetchConfig;
  external?: boolean;
};

/**
 * @name SearchController
 * @description
 * A search controller that can be used to search through a list of items.
 * [Required]
 * @property data - The data to search through. Can be an array of items, a function that returns an array of items, or a function that returns a promise that resolves to an array of items.
 * [Optional]
 * @property id - The id of the search poppper.
 * @property placeholder - The placeholder of the search input.
 * @property onChangeSearch - A function that is called when the search input changes.
 * @property searchConfig - The search configuration.
 * @property fetchConfig - The fetch configuration.
 * @returns A search controller.
 **/
export const createSearchController = <T,>(
  context: Context<{ dispatch: Dispatch<Action<T>>; state: State<T> }>,
) =>
  function SearchController<DataType extends T>(props: Props<DataType>) {
    const newid = useId();
    const id = props.id ?? newid;
    const { dispatch } = useContext(context);
    const [search, setSearch] = useState("");
    const [value, setValue] = useState(props.value ?? "");
    const [data, setData] = useState<DataType[] | null>(null);

    const onFetchError = (error: Error) => {
      console.error(`Error fetching data for SearchController: ${error}`);
    };

    const fetchData = useCallbackRef(() => {
      if (typeof props.data === "function") {
        const retryRequest = retryRequestsOf(props.data, {
          retries: props.fetchConfig?.reties ?? 3,
          onError: props.fetchConfig?.onFetchError || onFetchError,
          shouldRetry: props.fetchConfig?.shouldRetry || (() => true),
        });

        retryRequest().then((data) => {
          setData(data);
          dispatch({ type: "SET_DATA", data });
        });
      }
    }, [props.data, setData, retryRequestsOf]);

    const debouncedFetchData = useMemo(
      () => debounce(fetchData, props.fetchConfig?.debounceTime ?? 500),
      [setData, fetchData],
    );

    const searchHandler = useCallbackRef(
      (search: string) => {
        setSearch(search);
        props.onChangeSearch?.(search);
      },
      [setSearch, props.onChangeSearch],
    );

    const debouncedSetSearch = useCallback(
      debounce(searchHandler, props.searchConfig?.debounceTime ?? 500),
      [setData, fetchData],
    );
    // Fetch data on mount
    useEffect(() => {
      if (typeof props.data === "function") {
        debouncedFetchData();
      } else if (Array.isArray(props.data)) {
        setData(props.data);
        dispatch({ type: "SET_DATA", data: props.data });
      }
      return () => {
        debouncedFetchData.cancel();
      };
    }, [props.data, setData, debouncedFetchData]);

    useEffect(() => {
      if (props.external) {
        setValue(props.value ?? "");
      }
    }, [props.value, setValue]);

    const filterData = useCallbackRef((d: DataType[]) => {
      return !props.external
        ? !!props.searchConfig?.filter
          ? d.filter((item) => props.searchConfig?.filter?.(item, search))
          : // @ts-ignore
            d.filter((item: BaseAssumedDataType) =>
              item?.name
                .toLocaleLowerCase()
                .includes(search.toLocaleLowerCase()),
            )
        : d;
    }, []);

    // Filter the data based on the search string.
    useEffect(() => {
      if (!!data) {
        dispatch({ type: "SET_DATA", data: filterData(data) });
      }
    }, [search, data]);

    const handleValueChange = async (e: any) => {
      setValue(e.target.value);
      props.external && debouncedFetchData();
      debouncedSetSearch(e.target.value);
    };

    return (
      <InputGroup>
        <InputLeftElement
          pointerEvents="none"
          zIndex={0}
          children={<Icon style={{ color: "#a0aec0" }} name="Search" />}
        />
        <Input
          id={`search-controller-input-${id}`}
          key={`search-controller-input-${id}`}
          type="text"
          placeholder={props.placeholder ?? "Search"}
          value={value}
          onChange={handleValueChange}
          autoFocus
        />
      </InputGroup>
    );
  };

export default createSearchController;
