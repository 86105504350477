const Inactive = "490c6550-663e-49a4-a228-6ca4ffcdada3";
const Pending = "702e6ac9-c1a0-452b-a0d6-7ae6a4d15d62";
const Active = "9cf37321-75e8-43b8-8126-eefe72072ac4";

export type UserAccountStatus =
  | typeof Inactive
  | typeof Pending
  | typeof Active;

export const UserAccountStatusIDToName = {
  "490c6550-663e-49a4-a228-6ca4ffcdada3": "Inactive",
  "702e6ac9-c1a0-452b-a0d6-7ae6a4d15d62": "Pending",
  "9cf37321-75e8-43b8-8126-eefe72072ac4": "Active",
} as const;

export { Inactive, Pending, Active };
