import Bugsnag, {Event} from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import {Auth} from "aws-amplify";

export default () => {
  Bugsnag.start({
    apiKey: "afb9449f4e9dc4f83b32a254607fa020",
    plugins: [new BugsnagPluginReact()],
    onError: async (event: Event) => {
      const user = await Auth.currentAuthenticatedUser();
      if (!user) return;
      const {attributes, pool, username} = user;
      const userID = attributes["custom:userID"] || "UNKNOWN_USER_ID";
      const accountID =
        attributes["custom:currentAccountID"] || "UNKNOWN_ACC_ID";
      const email = attributes.email || "UNKNOWN_EMAIL";
      const poolID = pool.userPoolId || "UNKNOWN_POOL";
      event.setUser(userID, email, username);
      event.addMetadata("user", {
        accountID,
        poolID,
      });
    },
  });
};
