import {
  Box,
  Container,
  ContainerProps,
  Heading,
  HStack,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import Breadcrumbs, { BreadcrumbElement } from "../chakra/Breadcrumbs";

type Props = {
  children: React.ReactNode;
  breadcrumbs?: BreadcrumbElement[];
  pageTitle: string;
  pageSubtitle?: string;
  contentSize?: ContainerProps["maxW"];
  headerSize?: ContainerProps["maxW"];
  headerBottomContent?: React.ReactNode;
  headerEndContent?: React.ReactNode;
};

/**
 * Very similar to the chakrascreencontainer
 * Defaults to container.xl
 * Header size defaults to container.2xl
 */

const LegacyScreenContainer = ({
  children,
  breadcrumbs = [],
  pageTitle,
  pageSubtitle,
  contentSize = "container.xl",
  headerSize = "container.2xl",
  headerBottomContent,
  headerEndContent,
}: Props): JSX.Element => (
  <>
    <chakra-scope>
      <Container maxW={headerSize} px={4} py={6}>
        <VStack w="full" align="start" spacing="0">
          <HStack justify="space-between" w="full">
            <Box>
              {breadcrumbs.length >= 1 && (
                <Breadcrumbs breadcrumbs={breadcrumbs} />
              )}
              {pageTitle && (
                <Heading as="h1" size="lg" color="gray.700">
                  {pageTitle}
                </Heading>
              )}
            </Box>
            {headerEndContent}
          </HStack>

          {(pageSubtitle || headerBottomContent) && (
            <HStack spacing="3">
              {pageSubtitle && (
                <Heading ps="0.5" color="blackAlpha.700" size="xs">
                  {pageSubtitle}
                </Heading>
              )}
              {headerBottomContent}
            </HStack>
          )}
        </VStack>
      </Container>
    </chakra-scope>
    <Container px={4} maxW={contentSize}>
      {children}
    </Container>
  </>
);

export default LegacyScreenContainer;
