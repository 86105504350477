import { Tr, Td, Heading, Text } from "@chakra-ui/react";

type Props = {
  title?: string;
  text?: string;
  children?: React.ReactNode;
};

const TableSectionHeading = ({ title, text, children }: Props) => (
  <Tr className="table-section-heading">
    <Td colSpan={2}>
      {title && <Heading size="sm">{title}</Heading>}
      {text && <Text fontSize="sm">{text}</Text>}
      {children}
    </Td>
  </Tr>
);

export default TableSectionHeading;
