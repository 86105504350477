import { Box, Button, Group, Stack, Text, rem } from "@mantine/core";
import { Dropzone, FileWithPath } from "@mantine/dropzone";
import { useState } from "react";
import {
  RiArrowLeftSLine,
  RiCloseLine,
  RiDeleteBin2Line,
  RiUpload2Line,
  RiUploadLine,
} from "react-icons/ri";
import RichText from "../../../../../components/chakra/form/RichText";
import { BlueprintConfigQuestion } from "../../../../../shared/v2/definitions/blueprints";
import { post } from "../../../../../utilities/apibelRequest";

type Props = {
  onBack: () => void;
  onContinue: (result: string | undefined) => void;
  onContinueWithFile: (file: { fileID: string; fileName: string }) => void;
};

export const fileNameRegex = (fileName: string) => {
  return fileName.replace(/[\u0250-\ue007]/g, "");
};

const FILE_SIZE_LIMIT = 15 * 1024 ** 2; // 15MB

export default function QuestionStepInput({
  onContinueWithFile,
  onBack,
  onContinue,
}: Props) {
  const [rtf, setRtf] = useState<string>("");
  const [isUploading, setIsUploading] = useState(false);

  const [file, setFile] = useState<FileWithPath | null>(null);
  const [uploadProg, setUploadProg] = useState(-1);

  const handleAddFiles = (files: FileWithPath[]) => {
    console.log({ files });
    const f = files[0];
    if (f) {
      setFile(f);
    }
  };

  const handleUploadFile = async (): Promise<
    { fileID: string; fileName: string } | false
  > => {
    if (file === null) {
      return false;
    }
    setIsUploading(true);
    // Prepare upload
    try {
      const fileName = fileNameRegex(file.name);
      const extension = file.name.split(".").pop() || "unknown";
      console.log({ fileName, file });
      const prepared = await post("file/prepareUpload", {
        fileName,
        fileType: file.type,
        fileExtension:
          extension.length < fileName.length ? extension : "unknown",
      });

      // const headers = {
      //   "Content-Type": file.type,
      // };
      const params = {
        ContentType: file.type,
        Key: file.name,
      };
      const formData = new FormData();
      formData.append("file", file);

      const fileAsReadableStream =
        file as unknown as ReadableStream<Uint8Array>;

      const uploadResult = await fetch(prepared.uploadUrl, {
        method: "PUT",
        headers: new Headers({
          "Content-Type": file.type,
        }),
        body: fileAsReadableStream,
      });

      return {
        fileID: prepared.fileID,
        fileName: file.name,
      };
    } catch (e) {
      console.error("Error in file upload", e);
      setIsUploading(false);
      return false;
    }
  };

  const handleClickContinue = async () => {
    if (rtf.length >= 1) {
      onContinue(rtf);
    } else if (file !== null) {
      console.log("Uploading file");
      const uploadedFile = await handleUploadFile();
      console.log({ uploadedFile });
      if (uploadedFile) {
        onContinueWithFile(uploadedFile);
      }
    } else {
      onContinue(undefined);
    }
  };

  return (
    <>
      <Stack w="100%" align="center" style={{ flexGrow: 1, overflowY: "auto" }}>
        <Stack justify="center" align="center" style={{ flexGrow: 1 }}>
          <Group justify="center" gap="xl" mih="128" w="100%">
            <Stack>
              <Dropzone
                loading={isUploading}
                maxFiles={1}
                onDrop={handleAddFiles}
                onReject={(files) => console.log("rejected files", files)}
                maxSize={FILE_SIZE_LIMIT}>
                <Group
                  justify="center"
                  gap="sm"
                  mih={60}
                  maw={300}
                  style={{ pointerEvents: "none" }}>
                  <Dropzone.Accept>
                    <RiUpload2Line />
                  </Dropzone.Accept>
                  <Dropzone.Reject>
                    <RiCloseLine
                      style={{
                        width: rem(32),
                        height: rem(32),
                        color: "var(--mantine-color-red-6)",
                      }}
                    />
                  </Dropzone.Reject>
                  <Dropzone.Idle>
                    <RiUploadLine
                      style={{
                        width: rem(32),
                        height: rem(32),
                        color: "var(--mantine-color-dimmed)",
                      }}
                    />
                  </Dropzone.Idle>

                  <div>
                    <Text size="md" inline>
                      Drag file here or click to select
                    </Text>
                    <Text size="sm" c="dimmed" inline mt={7}>
                      Max file size is 15MB
                    </Text>
                  </div>
                </Group>
              </Dropzone>
              {file !== null && (
                <Stack gap={0}>
                  <Text size="md" inline c="dimmed">
                    File ready for upload
                  </Text>
                  <Group w="100%" justify="space-between">
                    <Text size="md" inline>
                      {file.name}
                    </Text>
                    <Button variant="subtle" size="compact-sm" color="red">
                      <RiDeleteBin2Line />
                    </Button>
                  </Group>
                </Stack>
              )}
            </Stack>

            {file === null && <Text>Or enter below</Text>}
          </Group>

          {file === null && (
            <Box
              className="rich-text-container"
              w="80%"
              style={{ flexGrow: 0 }}>
              <RichText
                height={200}
                value={rtf}
                onChange={setRtf}
                charCounterMax={10000}
              />
            </Box>
          )}
        </Stack>
      </Stack>
      <Group w="100%" justify="space-between" style={{ alignSelf: "flex-end" }}>
        <Button
          leftSection={<RiArrowLeftSLine size={16} />}
          variant="subtle"
          onClick={onBack}>
          Back
        </Button>
        <Button loading={isUploading} onClick={handleClickContinue}>
          {file !== null ? "Upload and Continue" : "Save and Continue"}
        </Button>
      </Group>
    </>
  );
}
