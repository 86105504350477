//check if there are unsaved changes
const checkStoredValues = (storedValues:any, dbValues: any, type:any) => {
    if (dbValues.ObjectTypeID === storedValues.ObjectTypeID && dbValues[`${type}ID`] === storedValues[`${type}ID`]) {
        if (storedValues[`${type}Name`] !== dbValues[`${type}Name`]) {
            return true
        } else if (storedValues[`${type}Description`] !== dbValues[`${type}Description`]) {
            return true
        } else if (storedValues[`${type}ResponsibleUserID`] !== dbValues[`${type}ResponsibleUserID`]) {
            return true
        } else if (JSON.stringify(storedValues.ParentIDs) !== JSON.stringify(dbValues.ParentIDs)) {
            return true
        } else if (JSON.stringify(storedValues.CustomFields) !== JSON.stringify(dbValues.CustomFields)){
            return true
        } else if (dbValues.RiskIDs && storedValues.RiskIDs && (JSON.stringify(dbValues.RiskIDs) !== JSON.stringify(storedValues.RiskIDs))) {
            //for issues
            return true
        } else if (dbValues.RelatedRequirements && storedValues.RelatedRequirements && (JSON.stringify(dbValues.RelatedRequirements) !== JSON.stringify(storedValues.RelatedRequirements))) {
            //for risks
            return true
        } else if (type === 'Instance' && JSON.stringify(storedValues.Questions) !== JSON.stringify(dbValues.Questions)) {
            // for instance question responses
            return true
        } else {
            return false
        }
    } else {
        return false
    }
}

export{checkStoredValues}