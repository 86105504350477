import React from "react";
import { Routes, Route } from "react-router-dom";
import { useAppState } from "../../components/App/AppProvider";
import * as Permissions from "../../shared/constants/permission";
import * as Access from "../../utilities/access";
import NotFound from "../NotFound";

import AdminScreen from "./AdminScreen";
import CreateAccountScreen from "./CreateAccount";
import CreateNotification from "./CreateNotification";
import SuspendAccountScreen from "./SuspendAccount";

const AdminRoutes = () => {
  const { app } = useAppState();

  // Do the access check here, preventing access to any of the sub routes
  return Access.checkAccess(
    app.permissions_LEGACY,
    Permissions.CodeAdminAccounts,
    Permissions.TypeRead,
    false,
  ) ? (
    <Routes>
      <Route index element={<AdminScreen />} />
      <Route path="create" element={<CreateAccountScreen />} />
      <Route path="suspend" element={<SuspendAccountScreen />} />
      <Route path="notify" element={<CreateNotification />} />
    </Routes>
  ) : (
    <>
      <NotFound />
    </>
  );
};

export default AdminRoutes;
