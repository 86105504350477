const Issue = "1d410566-8902-4e51-a5ef-7d1d53477d9b";
const Requirement = "24816703-d4bb-4e7d-89f9-341e38b4d66d";
const Action = "b2662c1b-c2c4-4013-aab7-080ce019f073";
const Risk = "8d60cafe-91bb-4e5d-9b7c-739927419130";
const Job = "a5e5841b-7056-4ba0-8ce4-6efc73fb583f";
const Register = "2264d816-9067-49f4-bdc2-706246e1a670";

const ObjectTypeGroupIDToName = {
  "1d410566-8902-4e51-a5ef-7d1d53477d9b": "Issue",
  "24816703-d4bb-4e7d-89f9-341e38b4d66d": "Requirement",
  "b2662c1b-c2c4-4013-aab7-080ce019f073": "Action",
  "8d60cafe-91bb-4e5d-9b7c-739927419130": "Risk",
  "a5e5841b-7056-4ba0-8ce4-6efc73fb583f": "Job",
  "2264d816-9067-49f4-bdc2-706246e1a670": "Register",
} as const;

/**
 * A map of object type group ids to the lower case name of the group, that we are using as the effective ids
 */
const ObjectTypeGroupIDToGroupValue = {
  "1d410566-8902-4e51-a5ef-7d1d53477d9b": "issue",
  "24816703-d4bb-4e7d-89f9-341e38b4d66d": "requirement",
  "b2662c1b-c2c4-4013-aab7-080ce019f073": "action",
  "8d60cafe-91bb-4e5d-9b7c-739927419130": "risk",
  "a5e5841b-7056-4ba0-8ce4-6efc73fb583f": "job",
  "2264d816-9067-49f4-bdc2-706246e1a670": "register",
} as const;

export type ObjectTypeGroup = keyof typeof ObjectTypeGroupIDToName;

/**
 * Everything that sits in the 'Requirement' table, so basically just not actions
 */
export type RecordObjectTypeGroupNames = keyof Omit<
  typeof ObjectTypeGroupNameToID,
  "action"
>;

const ObjectTypeGroupNameToID = {
  issue: "1d410566-8902-4e51-a5ef-7d1d53477d9b",
  requirement: "24816703-d4bb-4e7d-89f9-341e38b4d66d",
  action: "b2662c1b-c2c4-4013-aab7-080ce019f073",
  risk: "8d60cafe-91bb-4e5d-9b7c-739927419130",
  job: "a5e5841b-7056-4ba0-8ce4-6efc73fb583f",
  register: "2264d816-9067-49f4-bdc2-706246e1a670",
} as const;

const isValidObjectType = (
  name: string
): name is keyof typeof ObjectTypeGroupNameToID =>
  name in ObjectTypeGroupNameToID;

const isValidObjectTypeID = (
  id: string
): id is keyof typeof ObjectTypeGroupIDToGroupValue =>
  id in ObjectTypeGroupIDToGroupValue;

/**
 * Returns the correct object type ID for the given name or throws
 */
const getObjectTypeGroupIDFromName = (name: string) => {
  if (isValidObjectType(name)) {
    return ObjectTypeGroupNameToID[name];
  }
  throw Error(`${name} isnt a valid object type`);
};

/**
 * Returns the correct object type value for the given type ID or throws
 */
const getObjectTypeGroupValueFromID = (id: string) => {
  if (isValidObjectTypeID(id)) {
    return ObjectTypeGroupIDToGroupValue[id];
  }
  throw Error(`${id} isnt a valid object type`);
};

/**
 * Excludes job
 */
const ObjectTypeGroupValues = [
  "issue",
  "requirement",
  "action",
  "risk",
  "register",
] as const;

export {
  Issue,
  Requirement,
  Action,
  Risk,
  ObjectTypeGroupIDToName,
  Job,
  Register,
  getObjectTypeGroupValueFromID,
  ObjectTypeGroupNameToID,
  ObjectTypeGroupIDToGroupValue,
  ObjectTypeGroupValues,
  getObjectTypeGroupIDFromName,
};
