import {
  Box,
  Input,
  InputGroup,
  InputLeftElement,
  useCallbackRef,
  useDisclosure,
  useOutsideClick,
} from "@chakra-ui/react";
import {
  useCallback,
  useEffect,
  useState,
  useMemo,
  useRef,
  useId,
} from "react";
import { debounce } from "lodash";
import Icon from "./UI/Icon";
import { retryRequestsOf } from "../utilities/retryRequestOf";

/**
 * @property reties: number - number of times to retry the request
 * @property onFecthError: (error: Error) => void - callback to handle fetch errors
 * @property debounceTime: number - time in ms to debounce the search
 * @property shouldRetry: ()=> boolean - function the returns whether to retry the request or not
 **/
type FetchConfig = {
  reties?: number;
  onFetchError?: (error: Error) => void;
  debounceTime?: number;
  shouldRetry?: () => boolean;
};

type BaseAssumedDataType = {
  id: string;
  name: string;
}

/**
 * @property filter: A function that takes in the data and returns the filtered data
 * @property debounceTime: time in ms to wait before calling the filter function
 **/
type SearchConfig<T> = {
  filter?: (item: T, search: string) => boolean;
  debounceTime?: number;
};

type Props<DataType> = {
  data: DataType[] | (() => DataType[]) | (() => Promise<DataType[]>);
  id?: string;
  placeholder?: string;
  onChangeSearch?: (search: string) => void;
  searchConfig?: SearchConfig<DataType>;
  fetchConfig?: FetchConfig;
  children: (props: {
    data: DataType[];
    onClose: () => void;
    onOpen: () => void;
  }) => JSX.Element[];
  onClose?: () => void;
};

const defaultChildDataValue: any[] = [];

/**
 * @name SearchPopper
 * @description A search popper that can be used to search through a list of items.
 * --[Required]--
 * @property data - The data to search through. Can be an array of items, a function that returns an array of items, or a function that returns a promise that resolves to an array of items.
 * @property children - A function that returns the children of the search controller.
 * [Optional]
 * @property id - The id of the search poppper.
 * @property placeholder - The placeholder of the search input.
 * @property onChangeSearch - A function that is called when the search input changes.
 * @property searchConfig - The search configuration.
 * @property fetchConfig - The fetch configuration.
 * @property onClose - A function that is called when the search popper is closed.
 * @returns A search popper.
 **/
export function SearchPopper<DataType>(props: Props<DataType>) {
  const id = props.id ?? useId();
  const outsideRef = useRef<HTMLElement>(null);
  const { onClose, onOpen } = useDisclosure();
  const [search, setSearch] = useState("");
  const [value, setValue] = useState("");
  const [data, setData] = useState<DataType[] | null>(null);

  const [filteredData, setFilteredData] = useState<DataType[]>([]);

  const handleClose = () => {
    if (props.onClose) {
      props.onClose();
    }
    setValue("");
    setSearch("");
    onClose();
  };

  useOutsideClick({
    ref: outsideRef,
    handler: handleClose,
  });

  const onFetchError = (error: Error) => {
    console.error(`Error fetching data for SearchPopper: ${error}`);
  };

  const fetchData = useCallbackRef(() => {
    if (typeof props.data === "function") {
      const retryRequest = retryRequestsOf(props.data, {
        retries: props.fetchConfig?.reties ?? 3,
        onError: props.fetchConfig?.onFetchError || onFetchError,
        shouldRetry: props.fetchConfig?.shouldRetry || (() => true),
      });

      retryRequest().then(setData);
    }
  }, [props.data, setData]);

  const debouncedFetchData = useMemo(
    () => debounce(fetchData, props.fetchConfig?.debounceTime ?? 500),
    [setData, fetchData],
  );

  const debouncedSetSearch = useCallback(
    debounce(setSearch, props.searchConfig?.debounceTime ?? 500),
    [setData, fetchData],
  );
  // Fetch data on mount
  useEffect(() => {
    if (typeof props.data === "function") {
      debouncedFetchData();
    } else if (Array.isArray(props.data)) {
      setData(props.data);
    }
    return () => {
      debouncedFetchData.cancel();
    };
  }, [props.data, setData, debouncedFetchData]);

  const filterData = useCallbackRef((d: DataType[]) => {
    return !!props.searchConfig?.filter
      ? d.filter((item) => props.searchConfig?.filter?.(item, search))
      : // @ts-ignore
      d.filter((item: BaseAssumedDataType) =>
        item?.name.toLocaleLowerCase().includes(search.toLocaleLowerCase()),
      );
  }, []);

  // Filter the data based on the search string.
  useEffect(() => {
    if (!!data) {
      setFilteredData(filterData(data));
    }
  }, [search, setFilteredData, data]);

  const handleValueChange = async (e: any) => {
    setValue(e.target.value);
    debouncedSetSearch(e.target.value);
    if (props.onChangeSearch) {
      props.onChangeSearch(e.target.value);
    }
  };

  let renderChildren;
  if (props.children) {
    renderChildren = props.children?.({
      data: data ? filteredData : defaultChildDataValue,
      onClose: handleClose,
      onOpen,
    });
  }

  return (
    <Box
      onFocus={onOpen}
      ref={outsideRef as any}
      id={`search-popper-${id}`}
      key={`search-popper-${id}`}
      w={"full"}>
      <Box
        position="sticky"
        top={0}
        zIndex={1}
        backgroundColor="white"
        padding={4}>
        <InputGroup>
          <InputLeftElement
            pointerEvents="none"
            children={<Icon style={{ color: "#a0aec0" }} name="Search" />}
          />
          <Input
            id={`search-popper-input-${id}`}
            key={`search-popper-input-${id}`}
            type="text"
            placeholder={props.placeholder ?? "Search"}
            value={value}
            onChange={handleValueChange}
            autoFocus
          />
        </InputGroup>
      </Box>
      {renderChildren}
    </Box>
  );
}

export default SearchPopper;
