import React from "react";
import FullLoader from "../../chakra/FullLoader";

interface LoadingProps {
  size?: "sm" | "md" | "lg" | "xl";
  fillScreen?: boolean;
  text?: string;
}

export const Loading = ({ size, fillScreen, text }: LoadingProps) => (
    <FullLoader size={size ?? "xl"} fillScreen={fillScreen} text={text} />
    // <Spinner
    //   animation="border"
    //   role="status"
    //   style={
    //     props.noBorder
    //       ? {
    //           margin: "0px",
    //           display: "block",
    //           height: props.size ? `${props.size}rem` : "2rem",
    //           width: props.size ? `${props.size}rem` : "2rem",
    //         }
    //       : {
    //           display: "block",
    //           height: props.size ? `${props.size}rem` : "2rem",
    //           width: props.size ? `${props.size}rem` : "2rem",
    //         }
    //   }>
    //   <span className="sr-only">Loading...</span>
    // </Spinner>
  );

