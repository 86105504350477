import { Button, Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";
import { useState, useEffect } from "react";
import Icon from "../../../UI/Icon";
import { Filters } from "../BaseWidgetProps";
import { createPortal } from "react-dom";

type Props = {
  patchFilters: (filters: Partial<Filters>) => void;
  recordType: string;
  subIssues?: boolean;
};

const SubIssueFilter = ({ patchFilters, recordType, subIssues }: Props) => {
  const [subIssue, setSubIssue] = useState(subIssues);

  useEffect(() => {
    setSubIssue(subIssues);
  }, [subIssues, setSubIssue]);

  const body = document.body;

  return (
    <Menu>
      <MenuButton size="sm" rightIcon={<Icon name="ChevronDown" />} as={Button}>
        {`${
          recordType === "issue" && subIssue !== undefined
            ? subIssue
              ? "Sub-Issues"
              : "Issues"
            : "All Issues"
        }`}
      </MenuButton>
      {createPortal(
        <chakra-scope>
          <MenuList zIndex={11}>
            <MenuItem
              onClick={() => {
                setSubIssue(undefined);
                patchFilters({
                  subIssues: undefined,
                });
              }}
            >
              All Issues
            </MenuItem>
            <MenuItem
              onClick={() => {
                setSubIssue(false);
                patchFilters({
                  subIssues: false,
                });
              }}
            >
              Parent-Issues Only
            </MenuItem>
            <MenuItem
              onClick={() => {
                setSubIssue(true);
                patchFilters({
                  subIssues: true,
                });
              }}
            >
              Sub-Issues Only
            </MenuItem>
          </MenuList>
        </chakra-scope>,
        body,
      )}
    </Menu>
  );
};

export default SubIssueFilter;
