/**
 * Maps over an array, returning only the non-null and non-undefined values.
 */
export const mapFilter = <TIn, TOut>(
  array: TIn[],
  fn: (item: TIn, idx: number) => TOut,
): NonNullable<TOut>[] =>
  array
    .map(fn)
    .filter((v): v is NonNullable<TOut> => v !== null && v !== undefined);
