import { Box, useColorModeValue } from "@chakra-ui/react";
import {
  CustomFieldRecord,
  CustomFieldType,
} from "../../../../shared/v2/definitions/customFields";
import getFieldDisplayValue from "./getFieldDisplayValue";

type Props = {
  customFieldData?: CustomFieldRecord;
  customField?: CustomFieldType;
};

/**
 * Renders a field in a table
 */
export default function TableCustomField({
  customFieldData,
  customField,
}: Props) {
  const mainTextColor = useColorModeValue("gray.900", "gray.100");

  const NoValue = (
    <Box as="span" fontStyle="italic" color="gray.400">
      No Value
    </Box>
  );

  if (!customFieldData || !customField) {
    return NoValue;
  }

  const { unitLabel } = customField;

  const valueToDisplay =
    customFieldData && customField
      ? getFieldDisplayValue(customFieldData, customField)
      : null;

  return valueToDisplay === null ? (
    <Box as="span" fontStyle="italic" color="gray.400">
      No Value
    </Box>
  ) : (
    <Box as="span" color={mainTextColor}>
      {valueToDisplay}
      {unitLabel !== null ? ` ${unitLabel}` : ""}
    </Box>
  );
}
