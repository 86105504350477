import {
  Button,
  Grid,
  GridItem,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Skeleton,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useState } from "react";
import ChakraScreenContainer from "../../components/chakra/ChakraScreenContainer";
import Spinner from "../../components/chakra/common/Spinner";
import DashboardRenderer from "../../components/dashboard/DashboardRenderer";
import AccountLogo from "../../components/UI/AccountLogo";
import Icon from "../../components/UI/Icon";
import { useApiQuery } from "../../utilities/apibelRequest";

const HomeDashboardScreen = () => {
  const [selectedDashboardID, setSelectedDashboardID] = useState<string | null>(
    null,
  );

  const usersQuery = useApiQuery("user/allUsersInfo", null);
  const dashQuery = useApiQuery("dashboard/all", null);

  const currentDashboard = dashQuery.isSuccess
    ? selectedDashboardID !== null
      ? dashQuery.data.dashboards.find(
        (dash) => dash.dashboardID === selectedDashboardID,
      )
      : dashQuery.data.dashboards.find(
        (dash) => dash.dashboardID === dashQuery.data.primaryDashboardID,
      )
    : undefined;

  return (
    <chakra-scope>
      <ChakraScreenContainer contentSize="container.2xl">
        <VStack w="full" paddingBottom="10">
          <Grid
            templateAreas={`"menu _ logo"`}
            templateColumns="1fr 1fr 1fr"
            gap="4"
            w="full">
            <GridItem display="flex" alignItems="flex-end" area="menu">
              {dashQuery.isLoading ? (
                <Skeleton h="24px" />
              ) : (
                <Menu>
                  <MenuButton
                    as={Button}
                    variant="ghost"
                    rightIcon={<Icon name="ChevronDown" />}>
                    {currentDashboard?.name}
                  </MenuButton>
                  <MenuList>
                    <MenuOptionGroup
                      value={
                        currentDashboard ? currentDashboard.dashboardID : ""
                      }
                      onChange={(val) => setSelectedDashboardID(val)}
                      type="radio"
                      title="Change Dashboard">
                      {dashQuery.data?.dashboards.map(
                        ({ name, dashboardID }) => (
                          <MenuItemOption key={dashboardID} value={dashboardID}>
                            {name}
                          </MenuItemOption>
                        ),
                      )}
                    </MenuOptionGroup>
                  </MenuList>
                </Menu>
              )}
            </GridItem>
            <GridItem
              display="flex"
              area="logo"
              pt={8}
              justifyContent="flex-end">
              <AccountLogo maxH="24" maxW="100%" />
            </GridItem>
          </Grid>
          {dashQuery.isError ? (
            <Text>Something went wrong</Text>
          ) : dashQuery.isFetching ? (
            <Spinner />
          ) : (
            <DashboardRenderer
              users={usersQuery.data}
              dashboardConfig={currentDashboard}
            />
          )}
        </VStack>
      </ChakraScreenContainer>
    </chakra-scope>
  );
};

export default HomeDashboardScreen;
