import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Center,
  Link,
} from "@chakra-ui/react";
import { useState } from "react";
import { Link as RouterLink, useLocation, useParams } from "react-router-dom";
import { BreadcrumbElement } from "../../components/chakra/Breadcrumbs";
import { Card, CardContent, CardHeading } from "../../components/chakra/card";
import ChakraScreenContainer from "../../components/chakra/ChakraScreenContainer";
import Spinner from "../../components/chakra/common/Spinner";
import RecordForm from "../../components/chakra/form/RecordForm";
import ReadOnlyRecord from "../../components/chakra/record/ReadOnlyRecord";
import Icon from "../../components/UI/Icon";
import { useApiQuery } from "../../utilities/apibelRequest";
import NotFound from "../NotFound";


const BREADCRUMBS: BreadcrumbElement[] = [
  {
    label: "Home",
    link: "/",
  },
  {
    label: "Registers",
    link: "/registernew",
  },
];
const RegisterRecordScreen = () => {
  const params = useParams();
  const location = useLocation();

  const [isEditingRecord, setIsEditingRecord] = useState(false);

  const passedObjectType: { name: string; ID: string } | undefined =
    location.state?.objectType;
  const passedRecordData: { name: string, readableID: string } | undefined = location.state?.recordData;


  const { recordID } = params;

  const record = useApiQuery(
    "register/recordByID",
    { ID: recordID || "" },
    { enabled: !!recordID },
  );

  if (recordID === undefined) {
    return <NotFound />;
  }
  
  const objectType = record.isSuccess ? { name: record.data.objectTypeNamePlural, ID: record.data.objectTypeID } : passedObjectType;
  const recordTitle: string | undefined = record.data?.name || passedRecordData?.name;
  const recordReadableID: string | undefined = record.data?.readableID || passedRecordData?.readableID;

  return (
    <chakra-scope>
      <ChakraScreenContainer
        contentSize="container.lg"
        breadcrumbs={
          objectType !== undefined
            ? [
                ...BREADCRUMBS,
                {
                  label: objectType.name,
                  link: `/registernew/dashboard/${objectType.ID}`,
                },
              ]
            : BREADCRUMBS
        }
        pageTitle={recordTitle || "Register Record"}
        pageSubtitle={recordReadableID ? `Register - ${recordReadableID}` : recordTitle ? undefined : "Register Record"}>
        <Box mb="8">
          <Alert status="warning">
            <AlertIcon />
            <Box>
              <AlertTitle>This page is not yet fully implemented.</AlertTitle>
              <AlertDescription display="block">
                <Link
                  color="blue"
                  as={RouterLink}
                  to={`/register/${recordID}?display=registers`}>
                  Click here
                </Link>{" "}
                to go to the legacy register record page.
              </AlertDescription>
            </Box>
          </Alert>
        </Box>
        {record.isSuccess ? (
          <>
            <Card>
              <CardHeading title="Details">
                {isEditingRecord ? (
                  <Button
                    size="sm"
                    variant="ghost"
                    colorScheme="red"
                    onClick={() => setIsEditingRecord(false)}>
                    Cancel
                  </Button>
                ) : (
                  <Button
                    size="sm"
                    variant="ghost"
                    leftIcon={<Icon name="Edit2" />}
                    onClick={() => setIsEditingRecord(true)}>
                    Edit
                  </Button>
                )}
              </CardHeading>
              <CardContent>
                {isEditingRecord ? (
                  <RecordForm record={record.data} />
                ) : (
                  <ReadOnlyRecord record={record.data} />
                )}
              </CardContent>
            </Card>
          </>
        ) : (
          <Center>
            <Spinner />
          </Center>
        )}
      </ChakraScreenContainer>
    </chakra-scope>
  );
};

export default RegisterRecordScreen;
