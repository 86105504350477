import { Box, Button, Center, Group, Stack, Text } from "@mantine/core";

import { RiArrowLeftSLine, RiDeleteBin2Line } from "react-icons/ri";
import RichText from "../../../../../components/chakra/form/RichText";

import styles from "./QuestionStepReviewComplete.module.css";
import { BlueprintStateQuestion } from "../../../../../shared/v2/definitions/blueprints";
import { VStack } from "@chakra-ui/react";

type Props = {
  onBack: () => void;
  onContinue: () => void;
  questionState: BlueprintStateQuestion;
};

export default function QuestionStepReviewComplete({
  questionState,
  onBack,
  onContinue,
}: Props) {
  return (
    <>
      {questionState.step === "complete" ? (
        questionState.answerType === "text" && questionState.answerText ? (
          <Box className={styles.richTextContainer}>
            <RichText
              heightMin={240}
              value={questionState.answerText || ""}
              isReadOnly
              onChange={() => {}}
              charCounterMax={10000}
            />
          </Box>
        ) : questionState.answerType === "file" && questionState.answerFile ? (
         <Center>
           <Stack gap={0} justify="center">
            <Text size="md" inline c="dimmed">
              File uploaded
            </Text>
            <Group justify="space-between">
              <Text size="md" inline>
                {questionState.answerFile.fileName}
              </Text>
              <Button variant="subtle" size="compact-sm" color="red" onClick={onBack}>
                <RiDeleteBin2Line /> Change file
              </Button>
            </Group>
          </Stack>
         </Center>
        ) : (
          <Text c="dimmed">No answer provided</Text>
        )
      ) : null}

      <Group w="100%" justify="space-between" style={{ alignSelf: "flex-end" }}>
        <Button
          leftSection={<RiArrowLeftSLine size={16} />}
          variant="subtle"
          onClick={onBack}>
          Back
        </Button>
        <Button onClick={onContinue}>Continue</Button>
      </Group>
    </>
  );
}
