import { Box, Button, Center, Heading, Text } from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";
import Icon from "../UI/Icon";

type Props = {
  hasAccess: boolean;
  children: React.ReactNode;
};

const AccessGuard = ({ hasAccess, children }: Props): JSX.Element =>
  hasAccess ? (
    <>{children}</>
  ) : (
    <Box mt={4} mb={8}>
      <Button variant="link" color="black" as={Link} to="/" leftIcon={<Icon name="Home" />}>Return Home</Button>
      <Center pt="20%">
        <Box>
            <Center><Heading>404</Heading></Center>
            <Text fontSize="3xl" fontWeight="bold" >This page could not be found.</Text>
        </Box>
      </Center>
    </Box>
  );

export default AccessGuard;
