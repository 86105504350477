import { Box } from "@chakra-ui/react";
import { Amplify } from "aws-amplify";
import { createContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { awsConfig } from "../../constants/config";
import { AppState } from "../App/AppProvider";
import ForgotPassword from "./ForgotPassword";
import MFASetup from "./MFASetup";
import MFAVerify from "./MFAVerify";
import RequireNewPassword from "./RequireNewPassword";
import SignIn from "./SignIn";

Amplify.configure(awsConfig);

type AuthClient = {
  endpoint: string;
  fetchOptions: {};
  keyPrefix: string;
};

type SignedInUserSession = {
  accessToken: {
    jwtToken: string;
    payload: {
      auth_time: number;
      client_id: string;
      event_id: string;
      exp: number;
      iat: number;
      iss: string;
      jti: string;
      scope: string;
      sub: string;
      token_use: string;
      username: string;
    };
  };
  idToken: {
    jwtToken: string;
    payload: {
      aud: string;
      auth_time: number;
      "cognito:username": string;
      "custom:currentAccountID": string;
      "custom:userID": string;
      "custom:mfaExempt"?: string;
      email: string;
      email_verified: boolean;
      event_id: string;
      exp: number;
      family_name: string;
      given_name: string;
      iat: number;
      iss: string;
      sub: string;
      token_use: string;
    };
  };
  clockDrift: number;
  refreshToken: { token: string };
  refreshTokenExpiration: number;
  tokenExpiration: number;
};

export type User = {
  challengeName: ChallengeTypes;
  challengeParam: { requiredAttributes: string[] };
  username: string;
  email?: string;
  preferredMFA: "NOMFA" | "SMS" | "TOTP";
  Session?: string;
  client?: AuthClient;
  attributes?: Record<string, string>;
  pool?: {
    userPoolId: string;
    clientId: string;
    client: AuthClient;
    storage: Record<string, string>;
    advancedSecurityDataCollectionFlag: boolean;
    wrapRefreshSessionCallback: () => void;
  };
  signInUserSession: SignedInUserSession | null; // Once the user is signed in, this is set to the user's session
  userDataKey: string;
  storage: Record<string, string>;
};

type AuthContextType = {
  user: User | null;
};

type ChallengeTypes =
  | "SOFTWARE_TOKEN_MFA"
  | "MFA_SETUP"
  | "NEW_PASSWORD_REQUIRED";

export type AuthState =
  | "signedIn"
  | "signIn"
  | "mfaSetup"
  | "mfaVerify"
  | "forgotPassword"
  | "newPasswordRequired";

type Props = {
  baseAppState: AppState;
  updateBaseAppState: React.Dispatch<React.SetStateAction<AppState>>;
};

export const AuthContext = createContext<AuthContextType>({ user: null });

export const AuthComponent = ({ updateBaseAppState, baseAppState }: Props) => {
  const [authState, setAuthState] = useState<AuthState>("signIn");

  const navigate = useNavigate();
  const [context, setContext] = useState<AuthContextType>({ user: null });

  const setUser = (user: User | null) => {
    setContext({ user });
  };

  const updateAppState = (type: AuthState, data: User) => {
    switch (type) {
      case "signedIn":
        window.localStorage.setItem(
          "authState",
          JSON.stringify({
            authState: "signedIn",
            auth: {
              apiToken: data.signInUserSession!.idToken.jwtToken,
              isLoggedIn: true,
            },
          }),
        );
        updateBaseAppState((prev) => ({
          type: "preparing",
          auth: {
            apiToken: data.signInUserSession!.idToken.jwtToken,
            isLoggedIn: true,
          },
          currentPageTitle: prev.currentPageTitle,
        }));
        break;
      case "signIn":
        updateBaseAppState((prev) => ({
          type: "loggedout",
          currentPageTitle: prev.currentPageTitle,
        }));
        break;
      default:
        console.warn("Missed auth state change ", type);
    }
  };

  const signIn = async (email: string, password: string) => {
    await Amplify.Auth.signIn(email, password).then(async (user: User) => {
      user.email = email;
      setUser(user);
      if (
        user.preferredMFA === "NOMFA" ||
        user.preferredMFA === "SMS" ||
        user.challengeName === "MFA_SETUP"
      ) {
        setAuthState("mfaSetup");
      } else if (user.challengeName === "SOFTWARE_TOKEN_MFA") {
        setAuthState("mfaVerify");
      } else if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
        setAuthState("newPasswordRequired");
      } else {
        try {
          updateAppState("signedIn", user);
        } catch (e) {
          if ((e as Error).message.includes("data.signInUserSession is null")) {
            console.error("Unable to sign in user: ", e);
            throw new Error("Failed to sign in user, contact support.");
          }
        }
      }
    });
  };

  const confirmSignIn = async (code: string) => {
    const loggedUser = await Amplify.Auth.confirmSignIn(
      context.user, // Return object from Auth.signIn()
      code, // Confirmation code
      "SOFTWARE_TOKEN_MFA", // CURRENTLY ONLY SOFTWARE_TOKEN_MFA
    );

    updateAppState("signedIn", loggedUser);
  };

  const switchToForgotPassword = () => setAuthState("forgotPassword");

  const setupMfa = async () => Amplify.Auth.setupTOTP(context.user);

  return (
    <chakra-scope>
      <AuthContext.Provider value={context}>
        {baseAppState.type === "loggedout" && (
          <Box w="100%" h="100%">
            {authState === "signIn" ? (
              <SignIn signIn={signIn} forgotPassword={switchToForgotPassword} />
            ) : authState === "mfaSetup" ? (
              <MFASetup
                updateAppState={updateAppState}
                user={context.user}
                setupMfa={setupMfa}
              />
            ) : authState === "mfaVerify" ? (
              <MFAVerify confirmSignIn={confirmSignIn} />
            ) : authState === "forgotPassword" ? (
              <ForgotPassword backToSignin={() => setAuthState("signIn")} />
            ) : authState === "newPasswordRequired" ? (
              <RequireNewPassword
                backToSignin={() => setAuthState("signIn")}
                user={context.user}
              />
            ) : (
              <SignIn signIn={signIn} forgotPassword={switchToForgotPassword} />
            )}
          </Box>
        )}
      </AuthContext.Provider>
    </chakra-scope>
  );
};

export default AuthComponent;
