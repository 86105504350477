import {
  Button,
  FormControl,
  FormLabel,
  HStack,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Switch,
  VStack,
  chakra,
} from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { ModalBody } from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { z } from "zod";
import { useAppState } from "../../../components/App/AppProvider";
import ShortTextField from "../../../components/chakra/form/formComponents/ShortTextField";
import SingleSelectField from "../../../components/chakra/form/formComponents/SingleSelectField";
import {
  CustomFieldTypeIDToName,
  Date,
  Link,
  ListCustomFieldTypeID,
  LongText,
  MultiselectList,
  NonListCustomFieldTypeID,
  Number,
  OnedriveUpload,
  SingleSelectList,
  Text,
  Upload,
  isListType,
} from "../../../shared/v2/constants/CustomFieldTypeID";
import { post, useApiQuery } from "../../../utilities/apibelRequest";
import useToast from "../../../utilities/useToast";

const getTypeOptions = () => {
  return [
    {
      label: CustomFieldTypeIDToName[Number],
      value: Number,
    },
    {
      label: CustomFieldTypeIDToName[SingleSelectList],
      value: SingleSelectList,
    },
    {
      label: CustomFieldTypeIDToName[Text],
      value: Text,
    },
    {
      label: CustomFieldTypeIDToName[Date],
      value: Date,
    },
    {
      label: CustomFieldTypeIDToName[LongText],
      value: LongText,
    },
    {
      label: CustomFieldTypeIDToName[Link],
      value: Link,
    },
    {
      label: CustomFieldTypeIDToName[Upload],
      value: Upload,
    },
    {
      label: CustomFieldTypeIDToName[MultiselectList],
      value: MultiselectList,
    },
    {
      label: CustomFieldTypeIDToName[OnedriveUpload],
      value: OnedriveUpload,
    },
  ];
};

const getDefaultType = (type: string | null): string[] | undefined => {
  const foundType = getTypeOptions().find((t) => t.value === type) || null;
  return [foundType?.value] ?? undefined;
};

const FormSchema = z
  .object({
    name: z.string(),
    isMandatory: z.boolean(),
    helpText: z.string(),
    type: z.array(z.string()),
    list: z.array(z.string()).optional(),
    units: z.string().optional(),
  })
  .refine((data) => {
    if (isListType(data?.type[0])) {
      return data.list !== undefined && data.list.length >= 1;
    }
    return true;
  }, "List is required");

type Props = {
  onClose: () => void;
  type: string;
};

const NewCustomFieldModal = ({ onClose, ...props }: Props) => {
  const { displayToast } = useToast();
  const form = useForm<z.infer<typeof FormSchema>>({
    mode: "onBlur",
    reValidateMode: "onBlur",
    resolver: zodResolver(FormSchema),
    defaultValues: {
      name: "",
      isMandatory: false,
      helpText: "",
      type: getDefaultType(props.type),
      list: undefined,
      units: "",
    },
  });
  const navigate = useNavigate();
  const { permissions } = useAppState().app;
  const hasAccountPermission = permissions.ACCOUNT_SETTINGS;
  const listsQuery = useApiQuery("customFields/getAllLists", null, {
    enabled: hasAccountPermission,
  });
  const type = form.watch("type");
  const isListRequired =
    type[0] === SingleSelectList || type[0] === MultiselectList;

  const { handleSubmit, formState, getValues, register } = form;
  const onSubmit = async () => {
    const values = getValues();
    const customFieldTypeID = values.type[0];
    if (!customFieldTypeID || customFieldTypeID === "") {
      return;
    }
    const isList = isListType(customFieldTypeID);
    let result: {
      customFieldID: string;
    };
    if (isList) {
      result = await post("customFields/create", {
        type: "list",
        name: values.name,
        customFieldTypeID: customFieldTypeID as ListCustomFieldTypeID,
        isMandatory: values.isMandatory,
        helpText: values.helpText,
        units: values.units,
        list: values?.list[0],
      });
    } else {
      result = await post("customFields/create", {
        type: "normal",
        name: values.name,
        customFieldTypeID: customFieldTypeID as NonListCustomFieldTypeID,
        isMandatory: values.isMandatory,
        helpText: values.helpText,
        units: values.units,
      });
    }

    try {
      displayToast({
        title: "Custom Field created successfully",
        status: "success",
      });
      onClose();
      navigate(`/settings/customfield/${result.customFieldID}`);
    } catch (e) {
      console.error(e);
      displayToast({
        status: "error",
        title: "Failed to create Custom Field",
      });
    }
  };
  const listOptions = listsQuery.isSuccess ? listsQuery.data : [];

  return (
    <chakra-scope>
      <ModalContent>
        <ModalHeader>New Custom Field</ModalHeader>
        <ModalCloseButton />
        <FormProvider {...form}>
          <chakra.form onSubmit={handleSubmit(onSubmit)}>
            <ModalBody>
              <VStack align="start">
                <SingleSelectField
                  name="type"
                  fieldProps={{
                    label: "Type",
                    options: getTypeOptions() ?? [],
                    isRequired: true,
                  }}
                />
                {isListRequired && (
                  <SingleSelectField
                    name="list"
                    fieldProps={{
                      label: "Chosen List",
                      options:
                        listOptions.map((o) => ({
                          label: o.listName,
                          value: o.listID,
                        })) ?? [],
                      isRequired: isListRequired,
                    }}
                  />
                )}
                <ShortTextField
                  name="name"
                  fieldProps={{
                    label: "Name",
                    maxLength: 100,
                    isRequired: true,
                  }}
                />
                <ShortTextField
                  name="helpText"
                  fieldProps={{
                    label: "Help Text",
                    maxLength: 100,
                    isRequired: false,
                  }}
                />
                <ShortTextField
                  name="units"
                  fieldProps={{
                    label: "Units",
                    maxLength: 32,
                    isRequired: false,
                  }}
                />
                <FormControl display="flex" alignItems="center">
                  <FormLabel>Mandatory</FormLabel>
                  <Switch {...register("isMandatory")} id="mandatory" />
                </FormControl>
              </VStack>
            </ModalBody>
            <ModalFooter>
              <HStack>
                <Button
                  isDisabled={formState.isSubmitting}
                  onClick={onClose}
                  variant="solid"
                  colorScheme="gray">
                  Cancel
                </Button>
                <Button
                  isLoading={formState.isSubmitting}
                  loadingText="Creating"
                  type="submit"
                  variant="solid">
                  Create
                </Button>
              </HStack>
            </ModalFooter>
          </chakra.form>
        </FormProvider>
      </ModalContent>
    </chakra-scope>
  );
};

export default NewCustomFieldModal;
