import { Box, Skeleton, SkeletonText } from "@chakra-ui/react";

const SkeletonBarChart = () => {
  return (
    <Box
      display="grid"
      gridTemplateAreas={`"space1 bar1 space2 bar2 space3 bar3 space4" "_ _ labels labels labels __ __"`}
      gridTemplateColumns="1fr 2fr 1fr 2fr 1fr 2fr 1fr"
      alignItems="end"
      gridTemplateRows="3fr 56px"
    >
      <Skeleton gridArea="bar1" height="240px" />
      <Skeleton gridArea="bar2" height="180px" />
      <Skeleton gridArea="bar3" height="160px" />
      <Box gridArea="labels" alignItems="center">
        <SkeletonText noOfLines={1} />
      </Box>
    </Box>
  );
};

export default SkeletonBarChart;
