import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { FieldError, useController, useFormState } from "react-hook-form";
import Select from "../../../Select";
import Icon from "../../../UI/Icon";

export type SelectOption = {
  value: string;
  label: string;
};

type Props = {
  name: string;
  isReadOnly?: boolean;
  fieldProps: {
    label: string;
    options: SelectOption[];
    helperText?: string | null;
    isRequired?: boolean;
    iconsValueDisplay?: boolean;
  };
};

/**
 * Wrapper around Select for usage with react hook form
 * Simplified from main Select component
 * If you want to use a custom render or option type, use the main Select component and create a new wrapper if needed for react hook form
 * Treats value as string instead of whole object
 */
const SingleSelectField = ({ fieldProps, name, isReadOnly }: Props) => {
  const [selectedOption, setSelectedOption] = useState<
    SelectOption | undefined
  >(undefined);

  const { errors } = useFormState({ name });
  const { field } = useController({ name });
  const error = errors[name];
  const { label, isRequired, helperText } = fieldProps;

  useEffect(() => {
    // Wait for the useController hook to finish executing
    if (field.value && fieldProps.options.length > 0) {
      const option = fieldProps.options.find(
        (option) =>
          option.value?.toLowerCase() ===
          (typeof field.value[0] === "string"
            ? field.value[0].toLowerCase()
            : field.value[0]),
      );
      setSelectedOption(option);
    }
  }, [field.value, fieldProps.options]);

  return (
    <FormControl
      isRequired={isRequired}
      isReadOnly={isReadOnly}
      isInvalid={!!error}>
      <FormLabel htmlFor={field.name}>{label}</FormLabel>
      <Flex>
        {fieldProps.iconsValueDisplay && (
          <Icon margin="8px" size="lg" name={field.value} />
        )}
        <Select
          value={selectedOption}
          onChange={(option) => field.onChange([option?.value])}
          getKey={(opt) => opt.value}
          getLabel={(opt) => opt.label}
          options={fieldProps.options}
        />
      </Flex>
      <FormHelperText>{helperText}</FormHelperText>
      <FormErrorMessage>
        {error?.message === undefined
          ? (error as any)?.map((e: FieldError) => e?.message).join(", ")
          : error.message?.toString()}
      </FormErrorMessage>
    </FormControl>
  );
};

export default SingleSelectField;
