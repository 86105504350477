import { useToken } from "@chakra-ui/react";
import { format } from "date-fns";
import Chart from "react-apexcharts";
import { WidgetConfigTypes } from "../../../shared/v2/definitions/dashboards";
import { useApiQuery } from "../../../utilities/apibelRequest";
import { useAppState } from "../../App/AppProvider";
import { BaseWidgetProps, getDaterange, Lifecycle } from "./BaseWidgetProps";
import DashboardWidget from "./DashboardWidget";
import SkeletonPieChart from "./skeletons/SkeletonPieChart";

type Props = BaseWidgetProps & {
  config: WidgetConfigTypes["my-tasks"];
};

const MyTasksWidget = ({ title, setFilters, widgetID, dashboardID }: Props) => {
  const upcomingCol = useToken("colors", "teal.400");
  const dueCol = useToken("colors", "blue.500");
  const overdueCol = useToken("colors", "orange.500");
  const openRespondedCol = useToken("colors", "purple.400");
  const missedCol = useToken("colors", "red.700");
  const closedCol = useToken("colors", "blue.700");
  const labelColor = useToken("colors", "whiteAlpha.900");

  const { userInfo } = useAppState().app;
  const myTasksQuery = useApiQuery("dashboard/widget/myTasks", {
    widgetID,
    dashboardID,
    widgetType: "my-tasks",
  });
  const data = myTasksQuery.data || {
    upcoming: 0,
    due: 0,
    overdue: 0,
    openResponded: 0,
    closed: 0,
    missed: 0,
  };
  const seriesIndexMap = {
    0: "upcoming",
    1: "due",
    2: "overdue",
    3: "openResponded",
    4: "closed",
    5: "missed",
  } as const;
  return (
    <DashboardWidget
      isLoading={myTasksQuery.isLoading}
      isRefetching={myTasksQuery.isRefetching}
      title={title ?? "My Tasks"}
      helpText={`All tasks that are assigned to you from ${format(
        getDaterange().from,
        "do MMMM",
      )} to ${format(getDaterange().to, "do MMMM yyyy")}`}
      onReload={myTasksQuery.refetch}>
      {myTasksQuery.isLoading || myTasksQuery.isFetching ? (
        <SkeletonPieChart />
      ) : (
        <Chart
          options={{
            noData: {
              text: "No Tasks",
            },
            labels: [
              "Upcoming",
              "Due",
              "Overdue",
              "Open Responded",
              "Closed (Completed)",
              "Closed (Missed)",
            ],
            colors: [
              upcomingCol,
              dueCol,
              overdueCol,
              openRespondedCol,
              closedCol,
              missedCol,
            ],
            dataLabels: {
              style: {
                colors: [labelColor],
              },
              formatter: (_, { seriesIndex, w }) =>
                `${w.config.series[seriesIndex]}`,
            },
            toolbar: {
              show: true,
              tools: {
                download: true,
              },
            },
            chart: {
              events: {
                click(chart) {
                  const lifecycle = seriesIndexMap[
                    chart.target.parentElement.getAttribute(
                      "data:realIndex",
                    ) as 0 | 1 | 2 | 3 | 4 | 5
                  ] as Lifecycle;

                  const { from, to } = getDaterange();

                  setFilters({
                    type: "tasks",
                    user: {
                      id: userInfo.userID,
                      name: `${userInfo.firstName} ${userInfo.lastName}`,
                    },
                    lifecycle,
                    dueAfter: from ? from.toISOString() : undefined,
                    dueBefore: to ? to.toISOString() : undefined,
                  });
                },
              },
            },
            tooltip: {
              y: {
                title: {
                  formatter: (x) => {
                    switch (x) {
                      case "Upcoming":
                        return "Upcoming (Due in the next 30 days)";
                      case "Due":
                        return "Due and open to answer";
                      case "Overdue":
                        return "Overdue and open to answer";
                      case "Open Responded":
                        return "Open and answered";
                      case "Closed":
                        return "Closed and Completed";
                      case "Missed":
                        return "Closed and Missed (In the last 30 days)";
                      default:
                        return x;
                    }
                  },
                },
              },
            },
          }}
          type="pie"
          show
          series={[
            data.upcoming,
            data.due,
            data.overdue,
            data.openResponded,
            data.closed,
            data.missed,
          ]}
        />
      )}
    </DashboardWidget>
  );
};

export default MyTasksWidget;
