import React from "react";
import moment from "moment";
import { Status } from "./Status";

interface LifeCycleProps {
  lifecycle: LifeCycle;
  placement?: any;
}

type LifeCycle =
  | "Upcoming"
  | "Due"
  | "Overdue"
  | "Closed"
  | "Open Responded"
  | "Closed (Missed)"
  | null;

const lifeCycleDescriptions = {
  Upcoming: "This task is not yet available for response",
  "Open Responded":
    "The task is now available for response and a response has been provided (it can still be modified)",
  Due: "The task is now available for response",
  Overdue:
    "This task is now past the due date, but is still available for response",
  Closed: "This task is closed - it’s no longer available for response",
  Missed:
    "This task is closed and was not answered - it’s no longer available for response",
};

// const getLifeCycle = (params: LifeCycleProps): getLifeCycleResult => {
//     const now = moment()
//     const dueDate = moment.utc(params.dueDate)
//     const beforeDate = moment.utc(params.dueDate).add(-params.windowBefore, 'days')
//     const afterDate = moment.utc(params.dueDate).add(params.windowAfter, 'days')

//     if (moment(now).isBefore(beforeDate, 'day')) {
//         return 'Upcoming'
//     } else if (moment(now).isSameOrAfter(beforeDate, 'day') && moment(now).isSameOrBefore(afterDate, 'day') && params.responded) {
//         return 'Open Responded'
//     } else if (moment(now).isSameOrAfter(beforeDate, 'day') && moment(now).isSameOrBefore(dueDate, 'day')) {
//         return 'Due'
//     } else if (moment(now).isAfter(dueDate, 'day') && moment(now).isSameOrBefore(afterDate, 'day')) {
//         return 'Overdue'
//     } else if (moment(now).isAfter(afterDate, 'day')) {
//         return 'Closed'
//     } else {
//         return null // can't happen lol
//     }
// }

const LifeCycle = (props: LifeCycleProps) => {
  switch (props.lifecycle) {
    case "Upcoming":
      return (
        <Status
          placement={props.placement}
          variant="success"
          text="Upcoming"
          tooltip={lifeCycleDescriptions.Upcoming}
        />
      );
    case "Open Responded":
      return (
        <Status
          placement={props.placement}
          variant="warning"
          text="Open Responded"
          tooltip={lifeCycleDescriptions["Open Responded"]}
        />
      );
    case "Due":
      return (
        <Status
          placement={props.placement}
          variant="warning"
          text="Due"
          tooltip={lifeCycleDescriptions.Due}
        />
      );
    case "Overdue":
      return (
        <Status
          placement={props.placement}
          variant="danger"
          text="Overdue"
          tooltip={lifeCycleDescriptions.Overdue}
        />
      );
    case "Closed":
      return (
        <Status
          placement={props.placement}
          variant="light"
          text="Closed"
          tooltip={lifeCycleDescriptions.Closed}
        />
      );
    case "Closed (Missed)":
      return (
        <Status
          placement={props.placement}
          variant="light"
          text="Closed (Missed)"
          tooltip={lifeCycleDescriptions.Missed}
        />
      );

    default:
      return null;
  }
};

export { LifeCycle };
