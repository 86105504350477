import React from "react";
import {
  Box,
  Group,
  Space,
  Text,
  Stack,
  UnstyledButton,
  Checkbox,
  UnstyledButtonProps,
} from "@mantine/core";
import styles from "./QuestionButton.module.css";

type Props = {
  label: React.ReactNode;
  description?: React.ReactNode;
  onClick: () => void;
  isChecked?: boolean;
} & UnstyledButtonProps;

export default function QuestionButton({
  label,
  description,
  onClick,
  isChecked,
  ...otherProps
}: Props) {
  return (
    <UnstyledButton
      className={styles.buttonWrapper}
      bg={isChecked ? "blue.6" : undefined}
      onClick={onClick}
      {...otherProps}>
      <Box className={styles.checkboxWrapper}>
        <Checkbox
          color="transparent"
          checked={isChecked}
          onChange={() => {}}
          tabIndex={-1}
          size="lg"
          styles={{ input: { cursor: "pointer" } }}
          aria-hidden
        />
      </Box>
      <div>
        <Text
          c={isChecked ? "white" : undefined}
          fw={600}
          mb={6}
          lh={1}
          size="xl">
          {label}
        </Text>
        {description && (
          <Text c={isChecked ? "white" : "dimmed"} fz="sm">
            {description}
          </Text>
        )}
      </div>
    </UnstyledButton>
  );
}
