import {
  Button,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import NotificationsSettingsCard from "./sections/NotificationsSettingsCard";
import UseriCalSettingsCard from "./sections/UseriCalSettingsCard";

import UserDetailsCard from "./sections/UserDetailsCard";
import SettingsScreenContainer from "./SettingsScreenContainer";

export default function ProfileScreen() {
  const changeEmailModalState = useDisclosure({ defaultIsOpen: false });

  return (
    <chakra-scope>
      <SettingsScreenContainer contentSize="container.xl" pageTitle="Profile">
        <Flex align="start" justify="start" flexWrap="wrap">
          <UserDetailsCard />
          <Spacer flex="1" maxW="8" />
          <VStack align="start" flex="1">
            <UseriCalSettingsCard />
            <NotificationsSettingsCard />
          </VStack>
        </Flex>
      </SettingsScreenContainer>

      <Modal
        isOpen={changeEmailModalState.isOpen}
        onClose={changeEmailModalState.onClose}>
        <ModalOverlay />
        <chakra-scope>
          <ModalContent>
            <ModalHeader>Change Email</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <VStack w="full" align="start">
                <FormControl>
                  <FormLabel>New Email</FormLabel>
                  <Input type="email" />
                </FormControl>
                <FormControl>
                  <FormLabel>Confirm New Email</FormLabel>
                  <Input type="email" />
                </FormControl>
                <FormControl>
                  <FormLabel>Password</FormLabel>
                  <Input type="password" />
                  <FormHelperText>
                    Please verify your password in order to change your email
                    address.
                  </FormHelperText>
                </FormControl>
              </VStack>
            </ModalBody>
            <ModalFooter>
              <HStack>
                <Button
                  onClick={changeEmailModalState.onClose}
                  variant="solid"
                  colorScheme="gray">
                  Cancel
                </Button>
                <Button type="submit">Change Email</Button>
              </HStack>
            </ModalFooter>
          </ModalContent>
        </chakra-scope>
      </Modal>
    </chakra-scope>
  );
}
