import * as AccessTypes from "../shared/definitions/access";
import * as Permissions from "../shared/constants/permission";
import * as ObjectTypeGroup from "../constants/objectTypeGroup";

const checkAccess = (
  userPermissions: AccessTypes.AccountPermissionCache,
  permissionCode: AccessTypes.PermissionCode,
  permissionType: AccessTypes.PermissionType,
  isResponsible?: boolean,
  objectType?: string,
): AccessTypes.ScopePermissionCache | boolean => {
  if (userPermissions.hasOwnProperty(permissionCode)) {
    if (userPermissions[permissionCode].hasOwnProperty(permissionType)) {
      if (isResponsible !== undefined && objectType) {
        if (!isResponsible) {
          return userPermissions[permissionCode][permissionType].ALL.includes(
            objectType,
          );
        }
        if (
          userPermissions[permissionCode][permissionType].SELF !== undefined &&
          userPermissions[permissionCode][permissionType].ALL !== undefined
        ) {
          return (
            userPermissions[permissionCode][permissionType].SELF.includes(
              objectType,
            ) ||
            userPermissions[permissionCode][permissionType].ALL.includes(
              objectType,
            )
          );
        }
        if (userPermissions[permissionCode][permissionType].ALL !== undefined) {
          return userPermissions[permissionCode][permissionType].ALL.includes(
            objectType,
          );
        }
        return userPermissions[permissionCode][permissionType].SELF.includes(
          objectType,
        );
      }

      if (isResponsible !== undefined) {
        if (!isResponsible) {
          return userPermissions[permissionCode][permissionType].hasOwnProperty(
            "ALL",
          );
        }
        return (
          userPermissions[permissionCode][permissionType].hasOwnProperty(
            "SELF",
          ) ||
          userPermissions[permissionCode][permissionType].hasOwnProperty("ALL")
        );
      }

      return userPermissions[permissionCode][permissionType];
    }
  }
  return false;
};

const objectTypeGroupIDToName = (id: string): AccessTypes.PermissionCode => {
  switch (id) {
    case ObjectTypeGroup.Issue:
      return Permissions.CodeIssue;
    case ObjectTypeGroup.Requirement:
      return Permissions.CodeRequirement;
    case ObjectTypeGroup.Risk:
      return Permissions.CodeRisk;
    case ObjectTypeGroup.Action:
      return Permissions.CodeAction;
    case ObjectTypeGroup.Job:
      return Permissions.CodeJob;
    case ObjectTypeGroup.Register:
      return Permissions.CodeRegister;
    default:
      return Permissions.CodeUserSettings;
  }
};

export {checkAccess, objectTypeGroupIDToName};
