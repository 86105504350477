import { Box, Title, Text, Stack, ScrollArea, Image } from "@mantine/core";
import React from "react";
import interestedParties from "./images/interested-parties.png";
import scope from "./images/scope.jpg";
import leadership from "./images/leadership.jpg";
import qualityPolicy from "./images/policy.jpg";
import rolesAndResponsibilities from "./images/roles-responsibilities.jpg";
import risk from "./images/risk.jpg";
import qualityObjectives from "./images/quality-objectives.jpg";
import changeManagement from "./images/change-management.jpg";
import competenceTraining from "./images/competence-training.jpg";
import documentControl from "./images/document-control.jpg";
import requirementsProcedure from "./images/requirements-procedure.jpg";
import designDevelopmentProcedure from "./images/design-development-procedure.jpg";
import supplierContractor from "./images/supplier-contractors.jpg";
import operatingProcedures from "./images/operating-procedures.jpg";
import productServiceIssuesProcedure from "./images/product-service-issues-procedure.jpg";
import customerSatisfaction from "./images/customer-satisfaction.jpg";
import internalAudit from "./images/internal-audit.jpg";
import managementReview from "./images/management-review.jpg";
import issuesNonConformityCorrectiveAction from "./images/issues-nonconformity-corrective-action.jpg";

type Props = {
  helpDocText: string | null;
};

const docs: Record<
  string,
  {
    title: React.ReactNode;
    content: React.ReactNode;
  }
> = {
  "interested-parties-procedure": {
    title: <Title order={3}>Context and Interested Parties</Title>,
    content: (
      <Stack>
        <Text>
          ISO9001 requires top management to ensure external requirements
          including statutory and regulatory requirements are determined,
          understood and met.
        </Text>
        <Text>
          You must also understand the needs and requirements of external and
          internal parties relevant to your organisation.
        </Text>
        <Text>
          Records related to these may take many forms, such as: customer
          contracts, legal registers, stakeholder registers, market or
          competitor analysis, staff surveys, client feedback or you may have a
          procedure.
        </Text>
        <Image pr="md" src={interestedParties} alt="interested parties" />
      </Stack>
    ),
  },
  "qms-scope": {
    title: <Title order={3}>Scope</Title>,
    content: (
      <Stack>
        <Text>
          The Scope of your system is a simple statement of the boundaries of
          your system, what is in and what is out – does your system cover all
          parts of the business? Every location? Every product and service or
          just some of these things?
        </Text>
        <Image pr="md" src={scope} alt="scope" />
      </Stack>
    ),
  },
  "leadership-procedure": {
    title: <Title order={3}>Leadership</Title>,
    content: (
      <Stack>
        <Text>
          ISO9001 requires top management to take responsibility for things such
          as:{" "}
        </Text>
        <ul>
          <li>Effectiveness of the system</li>
          <li>Establishment of policies and objectives</li>
          <li>
            Resourcing systems and ensuring they are integrated into the
            business
          </li>
          <li>Promoting a process approach and risk-based thinking</li>
          <li>Communicating importance of quality and systems</li>
          <li>Ensuring systems achieve their aims</li>
          <li>Engaging and supporting people to contribute to quality</li>
          <li>Promote improvement</li>
          <li>Supporting other management personnel</li>
        </ul>
        <Image pr="md" src={leadership} alt="leadership" />
      </Stack>
    ),
  },
  "quality-policy": {
    title: <Title order={3}>Quality Policy</Title>,
    content: (
      <Stack>
        <Text>
          The quality policy tells people - this is why you should select us;
          this is what we stand for and what you should expect from us
        </Text>

        <Text>The policy should:</Text>
        <ul>
          <li>Be appropriate for the context of the organisation</li>
          <li>Provide a framework for setting objectives and targets</li>
          <li>Include a commitment to satisfy applicable requirements</li>
          <li>Commit to continual improvement</li>
          <li>Be documented and available</li>
          <li>Communicated within the organisation</li>
          <li>Available as required</li>
        </ul>
        <Image pr="md" src={qualityPolicy} alt="qualityPolicy" />
      </Stack>
    ),
  },
  "roles-responsibilities-procedure": {
    title: <Title order={3}>Roles and Responsibilities</Title>,
    content: (
      <Stack>
        <Text>
          Roles and responsibilities need to be assigned and communicated
          throughout the organisation including responsibility for:
        </Text>
        <ul>
          <li>Compliance with ISO9001</li>
          <li>Reporting on system performance </li>
          <li>Customer focus</li>
          <li>Opportunities for improvement and change</li>
        </ul>
        <Image
          pr="md"
          src={rolesAndResponsibilities}
          alt="roles and responsibilities"
        />
      </Stack>
    ),
  },
  "risk-procedure": {
    title: <Title order={3}>Risk and Opportunity</Title>,
    content: (
      <Stack>
        <Text>ISO9001 requires you to consider:</Text>
        <ul>
          <li>
            The risks that could affect your system achieving its intended
            outcome
          </li>
          <li>Opportunities for improvement to the system</li>
        </ul>
        <Text>
          ISO9001 also requires actions to address these things to be planned.
          (CertCrowd comes with a risk module compliant with ISO31000 Risk
          Management)
        </Text>
        <Image pr="md" src={risk} alt="risk" />
      </Stack>
    ),
  },
  "quality-objectives": {
    title: <Title order={3}>Quality Objectives</Title>,
    content: (
      <Stack>
        <Text>
          ISO9001 specifies the requirement for measurable objectives. These
          objectives need to be:
        </Text>
        <ul>
          <li>Consistent with the Policy</li>
          <li>Measurable</li>
          <li>Monitored and communicated and updated as required</li>
          <li>Documented</li>
        </ul>
        <Text>Consider who, what, when and how for each objective</Text>
        <Image pr="md" src={qualityObjectives} alt="quality objectives" />
      </Stack>
    ),
  },
  "change-management-procedure": {
    title: <Title order={3}>Planning of Changes</Title>,
    content: (
      <Stack>
        <Text>
          Any changes to your quality management system need to be carried out
          in a planned manner taking into consideration things such as purpose,
          consequences, resources and responsibilities.
        </Text>
        <Text>
          CertCrowd comes with a robust permission model so you can limit who
          makes changes as required; CertCrowd also has audit history that
          records changes made to the system.
        </Text>
        <Image pr="md" src={changeManagement} alt="change management" />
      </Stack>
    ),
  },
  competence: {
    title: <Title order={3}>Competence and Training Procedure</Title>,
    content: (
      <Stack>
        <Text>
          There is a requirement for your organisation to determine what
          competencies are required at different levels of the organisation and
          to take action to achieve this.
        </Text>
        <Text>Evidence of competence is required to be maintained.</Text>
        <Text>
          A good way of managing this is through a Competency Register,
          CertCrowd has a robust register module just for this with an
          opportunity to create renewal tasks to ensure nothing expires.
        </Text>
        <Image pr="md" src={competenceTraining} alt="competence or training" />
      </Stack>
    ),
  },
  "document-control": {
    title: <Title order={3}>Document Control</Title>,
    content: (
      <Stack>
        <Text>
          ISO9001 requires processes for managing documented information. What
          is documented information?
        </Text>
        <Text>
          It is things such as procedures, software, records, forms, policies,
          websites, and contracts.
        </Text>
        <Text>
          It is internal documents and external documents you may use such as
          copies of standards, legislation, codes of practice, guidelines,
          drawings, safety data sheets and licenses.
        </Text>
        <Text>
          ISO9001 requires you to control internal documents in terms of things
          like who can create and update key documents; ISO9001 also requires
          you to ensure documents are protected from loss (backups) and
          inappropriate access (permissions)?
        </Text>
        <Text>
          How do you ensure documents are protected from loss (backups) and
          inappropriate access (permissions)?
        </Text>
        <Text>
          You may use software such as Sharepoint, Dropbox, Google Drive,
          OneDrive, Box to manage documents or you may want to use a document
          register in CertCrowd or both.
        </Text>
        <Image pr="md" src={documentControl} alt="document control" />
      </Stack>
    ),
  },
  "requirements-procedure": {
    title: (
      <Title order={3}>Products and Services Requirements Procedure</Title>
    ),
    content: (
      <Stack>
        <Text>
          Requirements for your products or services may be initiated from
          external sources such as legislation or regulations,
          suppliers/partners, customers.
        </Text>
        <Text>
          Internally you probably make statements or claims about the quality of
          your products or services that also will create requirements.
        </Text>
        <Text>
          ISO 9001 requires you to determine these requirements, review them and
          manage any changes in a controlled manner.
        </Text>
        <Image
          pr="md"
          src={requirementsProcedure}
          alt="requirements procedure"
        />
      </Stack>
    ),
  },
  "design-development-procedure": {
    title: <Title order={3}>Design and Development Procedure</Title>,
    content: (
      <Stack>
        <Text>
          ISO9001 requires control over the following for design and
          development:
        </Text>
        <ul>
          <li>Inputs</li>
          <li>Outputs</li>
          <li>Process controls</li>
          <li>Changes</li>
        </ul>
        <Text>
          Complexity of these processes depends on the size and nature of your
          activities and you will need records to support these activities.
        </Text>
        <Text>
          Design can include things such as product development, drawings, app
          development, service project scoping and work proposals, CAD
          processes.
        </Text>
        <Image
          pr="md"
          src={designDevelopmentProcedure}
          alt="design procedure"
        />
      </Stack>
    ),
  },
  "suppliers-contractors-procedure": {
    title: <Title order={3}>Suppliers and Contractors Procedure</Title>,
    content: (
      <Stack>
        <Text>
          ISO 9001 requires that any externally provided products or services
          conform to your organisation’s requirements. Controls are to be
          established and performance of external suppliers evaluated.
        </Text>
        <Text>
          CertCrowd comes with a robust register module where suppliers can be
          listed and actions created to assess them regularly.
        </Text>

        <Image
          pr="md"
          src={supplierContractor}
          alt="suppliers and contractors"
        />
      </Stack>
    ),
  },
  "operating-procedures": {
    title: <Title order={3}>Standard Operating Procedures</Title>,
    content: (
      <Stack>
        <Text>
          ISO9001 requires you to ensure products and services are produced
          under controlled conditions, that traceability is possible, that you
          protect customer property and manage post work activities such as
          warranty and customer feedback appropriately.
        </Text>
        <Image pr="md" src={operatingProcedures} alt="operating procedures" />
      </Stack>
    ),
  },
  "product-service-issues-procedure": {
    title: <Title order={3}>Product and Service Issues Procedure</Title>,
    content: (
      <Stack>
        <Text>
          ISO9001 requires you to ensure that product or service requirements
          have been met and that you don’t release the product or service until
          you are satisfied this is done.
        </Text>
        <Text>
          So it is a case of what checks have you in place throughout the
          realisation process or what final checks have you in place before the
          product is sent?
        </Text>
        <Text>
          This is often considered quality control or product testing or final
          checks.
        </Text>
        <Text>
          CertCrowd has a start a task function that allows you to create a
          checklist on demand for a check like this
        </Text>
        <Image
          pr="md"
          src={productServiceIssuesProcedure}
          alt="product issues"
        />
      </Stack>
    ),
  },
  "customer-satisfaction": {
    title: <Title order={3}>Customer Satisfaction Processes</Title>,
    content: (
      <Stack>
        <Text>
          ISO9001 requires you to monitor customers perception, you must
          proactively seek to obtain customer feedback and measure satisfaction
          as a means to continually improve Quality.
        </Text>
        <Text>Do you:</Text>
        <ul>
          <li>Have a survey</li>
          <li>Get feedback from client meetings</li>
          <li>Monitor reviews on sites like Google and Trip Advisor</li>
        </ul>
        <Image pr="md" src={customerSatisfaction} alt="customer satisfaction" />
      </Stack>
    ),
  },
  "internal-audit": {
    title: <Title order={3}>Internal Audit</Title>,
    content: (
      <Stack>
        <Text>
          ISO9001 requires you have an internal process that checks that you
          comply with your own processes and that they continue to meet the
          requirements of ISO9001.
        </Text>

        <Text>Your internal auditor should be competent and impartial.</Text>
        <Text>Results of audit(s) should be reported to management.</Text>
        <Text>
          You can create audit check lists in CertCrowd in start a task, audit
          register or as a requirement.
        </Text>
        <Image pr="md" src={internalAudit} alt="internal audit" />
      </Stack>
    ),
  },
  "management-review": {
    title: <Title order={3}>Management Review Procedure</Title>,
    content: (
      <Stack>
        <Text>
          Top Management is required to have a structured review process of your
          Quality Management System.
        </Text>
        <Text>
          You need to ensure &ldquo;top management&rdquo;, the leadership team
          review key information including:
        </Text>
        <ul>
          <li>Status of actions from previous reviews</li>
          <li>Changes to external and internal issues</li>
          <li>Performance of the Quality system</li>
          <li>Customer satisfaction and feedback from interested parties</li>
          <li>Quality objective metrics</li>
          <li>Performance and conformity of products and services</li>
          <li>Nonconformities and corrective action</li>
          <li>Monitoring and measuring results</li>
          <li>Audit results</li>
          <li>Performance of external providers</li>
        </ul>
        <Image pr="md" src={managementReview} alt="management review" />
      </Stack>
    ),
  },
  "issues-nonconformity-corrective-action": {
    title: <Title order={3}>Issues, Nonconformity and Corrective Action</Title>,
    content: (
      <Stack>
        <Text>
          ISO9001 requires significant issues and significant opportunities for
          improvement to be identified, recorded and managed effectively.
        </Text>
        <Text>
          Where a nonconformity exists action must be taken to control and
          correct it.
        </Text>
        <Text>
          There is also a requirement to identify the cause so you can fix
          things properly so that hopefully it won’t happen again.
        </Text>
        <Image
          pr="md"
          src={issuesNonConformityCorrectiveAction}
          alt="issues nonconformity corrective action"
        />
      </Stack>
    ),
  },
};

export default function BlueprintHelpSidebar({ helpDocText }: Props) {
  if (helpDocText === null) return null;
  const doc = docs[helpDocText];
  return (
    <>
      <Box px="md">{doc?.title}</Box>
      <ScrollArea px="md" type="hover">
        {doc?.content}
      </ScrollArea>
    </>
  );
}
