import {
  Box,
  Button,
  Heading,
  SimpleGrid,
  Text
} from "@chakra-ui/react";
import ChakraScreenContainer from "../../../components/chakra/ChakraScreenContainer";
import FullLoader from "../../../components/chakra/FullLoader";
import { useApiMutation, useApiQuery } from "../../../utilities/apibelRequest";
import { ManualCard } from "./components/ManualCard";

export default function ManualsScreen() {
  const manuals = useApiQuery("manual/many", {});
  const manualCreateMutation = useApiMutation("manual/create");

  const handleCreateManual = async () => {
    await manualCreateMutation.mutateAsync({
      manual: {
        name: "System Manual",
      },
    });
    manuals.refetch();
  };

  const hasAnyManuals = manuals.isSuccess && manuals.data.length > 0;

  const canCreateManual = manuals.isSuccess && manuals.data.length < 3; // TODO: Use a limit api?

  const isLoading = manuals.isFetching || manualCreateMutation.isLoading;

  return (
    <chakra-scope>
      <ChakraScreenContainer contentSize="container.lg" pageTitle="Manuals" headerEndContent={hasAnyManuals && canCreateManual ? <Button size="sm" onClick={handleCreateManual}>Create Manual</Button> : null}>
        {isLoading ? (
          <Box pt="8vh">
            <FullLoader />
          </Box>
        ) : manuals.isSuccess && manuals.data.length <= 0 ? (
          <Box
            mt="6"
            borderStyle="dashed"
            borderWidth="1px"
            borderColor="gray.200"
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
            height="200px">
            <Heading mt={4} mb={1} fontSize="lg">
              No data
            </Heading>
            <Text maxWidth="sm">
              You don't have any manuals yet. Create a manual to get started.
            </Text>
            <Button onClick={handleCreateManual} mt="4">
              Create Manual
            </Button>
          </Box>
        ) : (
          <SimpleGrid
          pt="4"
            flexWrap="wrap"
            columns={[2, 3]}
            spacing="6">
              {
                manuals.data?.map((manual) => (
                  <ManualCard manual={manual} />
                ))
              }
            </SimpleGrid>
        )}
      </ChakraScreenContainer>
    </chakra-scope>
  );
}
