import React, { SetStateAction } from "react";
import { AccountPermissionCache } from "../../shared/definitions/access";
import { PermissionObject } from "../../shared/v2/definitions/permissions";
import { Account, UserAccount } from "../../shared/v2/definitions/accounts";
import { UserDetails } from "../../shared/v2/definitions/user";

export type CoreAppInfo = {
  userAccounts: UserAccount[];
  userInfo: UserDetails;
  accountInfo: Account;
  attributes: Record<string, string>;
  permissions_LEGACY: AccountPermissionCache;
  permissions: PermissionObject;
  features: {
    enabled: string[];
  }
};

export type UnvalidatedCoreAppInfo = Omit<CoreAppInfo, "accountInfo"> & {
  accountInfo: Account | null;
};

export type AuthState = {
  apiToken: string;
  isLoggedIn: true;
};

// User is logged out
type LoggedOutAppState = {
  type: "loggedout";
  currentPageTitle: string;
};

// User is logged in but we need to fetch app state
type PreparingAppState = {
  type: "preparing";
  auth: AuthState;
  currentPageTitle: string;
};

type ReadyAppState = {
  type: "ready";
  auth: AuthState;
  currentPageTitle: string;
  app: UnvalidatedCoreAppInfo;
};

// Use is all ready to go
// This isnt part of the union, instead a ReadyAppState is transformed into this before being put into the context
// This is the only version of app state that should be in context
export type ActiveAppState = {
  type: "active";
  auth: AuthState;
  app: CoreAppInfo;
  currentPageTitle: string;
};

export type AppState = PreparingAppState | ReadyAppState | LoggedOutAppState;

export const createInitialAppState = (): AppState => {
  if (window.localStorage.getItem("authState")) {
    const currentState = JSON.parse(window.localStorage.getItem("authState")!);
    if (currentState.authState === "signedIn") {
      return {
        type: "preparing",
        auth: {
          apiToken: currentState.auth.apiToken,
          isLoggedIn: true,
        },
        currentPageTitle: "Home",
      };
    }
  }
  return {
    type: "loggedout",
    currentPageTitle: "Home",
  };
};

export type ActiveAppContextType = {
  appState: ActiveAppState;
  setAppState: React.Dispatch<SetStateAction<AppState>>;
  setPageTitle: (title: string) => void;
};

const ActiveAppContext = React.createContext<ActiveAppContextType>(null!);

type Props = {
  children: React.ReactNode;
  activeAppContext: ActiveAppContextType;
};

export const ActiveAppProvider = ({ children, activeAppContext }: Props) => (
  <ActiveAppContext.Provider value={activeAppContext}>
    {children}
  </ActiveAppContext.Provider>
);

export const useActiveAppState = () => React.useContext(ActiveAppContext);

export const useAppState = () => React.useContext(ActiveAppContext).appState;

export const useAuthState = () =>
  React.useContext(ActiveAppContext).appState.auth;
