import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Dropdown, Row, Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Edit, File } from "react-feather";
import * as Feather from "react-feather";
import { get } from "../../utilities/request";
import * as ObjectTypeGroup from "../../constants/objectTypeGroup";
import { checkAccess, objectTypeGroupIDToName } from "../../utilities/access";
import { useAppState } from "../App/AppProvider";
import isJobsAccount from "../../constants/JobsVisibleCheck";

type NewObjectTypeButtonProps = {
  parentID?: string;
  objectTypeGroupID: string;
  title: string;
  header: string;
  template?: boolean;
  type?: number;
  parentType?: any;
  variant: "primary" | "danger";
};

type ObjectType = {
  ObjectTypeID: string;
  ObjectTypeName: string;
  ObjectTypeDescription: string;
  ObjectTypeGroupID: string;
  ObjectTypeIcon: any;
};

type NewObjectTypeButtonState = {
  ObjectTypes: ObjectType[];
  Relationships?: any[];
};

type NewObjectTypeButtonStateSelector = {
  [objectTypeGroupID: string]: NewObjectTypeButtonState;
};

type NewObjectTypeButtonTypeSelector = {
  [objectTypeGroupID: string]: string;
};

const initialState: NewObjectTypeButtonState = {
  ObjectTypes: [],
};

const objectType: NewObjectTypeButtonTypeSelector = {
  [ObjectTypeGroup.Requirement]: "requirement",
  [ObjectTypeGroup.Action]: "action",
  [ObjectTypeGroup.Issue]: "issue",
  [ObjectTypeGroup.Risk]: "risk",
  [ObjectTypeGroup.Register]: "register",
};

export const NewObjectTypeButton = (props: NewObjectTypeButtonProps) => {
  const [state, setState] = useState<NewObjectTypeButtonState>(initialState);
  const appState = useAppState();
  const { auth } = appState;
  const permissions = appState.app.permissions_LEGACY;
  const navigate = useNavigate();

  const isJobs = isJobsAccount(appState);

  useEffect(() => {
    const getObjectTypes = async () => {
      const results = await get(
        `app/objecttypegroup/${props.objectTypeGroupID}/objecttype`,
        auth,
      );
      if (results.status === 200 && results.data) {
        let filtered;
        if (props.objectTypeGroupID === "Report") {
          if (checkAccess(permissions, "REPORTS", "CREATE", false)) {
            filtered = isJobs
              ? results.data.ObjectTypes
              : results.data.ObjectTypes.filter(
                  (objectType: any) =>
                    objectType.ObjectTypeID !== ObjectTypeGroup.Job,
                );
          } else {
            filtered = [];
          }
        } else {
          filtered = results.data.ObjectTypes.filter((objectType: any) =>
            checkAccess(
              permissions,
              objectTypeGroupIDToName(props.objectTypeGroupID),
              "CREATE",
              false,
              objectType.ObjectTypeID,
            ),
          );
        }
        setState({
          ObjectTypes: filtered,
          Relationships: results.data.Relationships,
        });
      }
    };
    getObjectTypes();
  }, [props.objectTypeGroupID, auth]);

  const title = (
    <>
      {objectType[props.objectTypeGroupID] === "requirement" ? (
        <File size="16" className="feather" />
      ) : (
        <Edit size="16" className="feather" />
      )}
      {props.title}
    </>
  );

  const checkRelationship = (
    ObjectTypeID: any,
    template: any,
    objectTypeGroupID: any,
  ) => {
    let check = false;
    if (state.Relationships) {
      state.Relationships.map((el: any) => {
        if (
          el.ObjectTypeID === ObjectTypeID &&
          el.ObjectTypeGroupID === props.parentType &&
          el.ObjectTypeRelationshipParent === props.type
        ) {
          check = true;
        } else if (
          el.ObjectTypeID === ObjectTypeID &&
          (el.ObjectTypeGroupID === props.parentType ||
            el.ObjectTypeRelationshipParent === props.type) &&
          template &&
          objectTypeGroupID === ObjectTypeGroup.Action
        ) {
          check = true;
        }
      });
    }
    return check;
  };

  if (state.ObjectTypes.length === 0) {
    return (
      <OverlayTrigger
        placement="auto"
        overlay={
          <Tooltip id="tooltip-not-authorised-to-edit">
            You do not have permission to create this
          </Tooltip>
        }>
        <Dropdown
          id="dropdown-basic-button"
          alignRight
          onSelect={(eventKey: any) => {
            navigate(eventKey);
          }}>
          <Dropdown.Toggle
            disabled
            variant={props.variant}
            id="dropdown-newobjecttype">
            {title}
          </Dropdown.Toggle>
          <Dropdown.Menu className="newObjectDropdown">
            <Dropdown.Header>{props.header}</Dropdown.Header>
            <Dropdown.Divider />

            <Dropdown.Item>
              <Row>
                <Col>
                  {`You do not have permisison to create new ${objectTypeGroupIDToName(
                    props.objectTypeGroupID,
                  ).toLowerCase()} `}
                </Col>
              </Row>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </OverlayTrigger>
    );
  }
  return (
    <Dropdown
      id="dropdown-basic-button"
      alignRight
      onSelect={(eventKey: any) => {
        navigate(eventKey);
      }}>
      <Dropdown.Toggle variant={props.variant} id="dropdown-newobjecttype">
        {title}
      </Dropdown.Toggle>
      <Dropdown.Menu className="newObjectDropdown">
        <Dropdown.Header>{props.header}</Dropdown.Header>
        <Dropdown.Divider />
        {state.ObjectTypes.map((objectTypes: ObjectType, index: number) => {
          if (
            checkRelationship(
              objectTypes.ObjectTypeID,
              props.template,
              props.objectTypeGroupID,
            ) ||
            props.parentType === ObjectTypeGroup.Action ||
            props.parentType === "Report" ||
            (props.parentType === ObjectTypeGroup.Register &&
              props.objectTypeGroupID === ObjectTypeGroup.Register)
          ) {
            const IconType: keyof typeof Feather = objectTypes.ObjectTypeIcon;
            const Icon = Feather[IconType];
            return (
              <Dropdown.Item
                key={index}
                eventKey={`${props.template ? "/template" : ""}/${
                  props.parentType === "Report"
                    ? "report"
                    : objectType[props.objectTypeGroupID]
                }/new?type=${objectTypes.ObjectTypeID}${
                  props.parentID ? `&parentid=${props.parentID}` : ""
                }`}>
                <Row>
                  <Col>
                    <Icon className="feather" size="18" />
                    {objectTypes.ObjectTypeName}
                    {props.parentType === "Report" ? " Report" : ""}
                  </Col>
                </Row>
              </Dropdown.Item>
            );
          }
          return null;
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
};
