import { Tooltip, VStack, Divider, Text } from "@chakra-ui/react";
import {
  formatFullDateOrIsoStringInTz,
  formatDateOrIsoString,
} from "../../../utilities/dateUtils";

type Props = {
  date: string | Date;
  timezones?: {
    tz: string;
    label: string;
  }[];
};

/**
 * Shows a date with a popup that shows the full date and time in multiple timezones
 */
export default function DateView({ date, timezones }: Props) {
  const localTimeStr = formatDateOrIsoString(date, "datetime-short");
  const localTimeStrFull = formatFullDateOrIsoStringInTz(date, "datetime");
  const tooltip = timezones ? (
    <VStack align="start" spacing="0" m="0">
      <span>Date & Time</span>
      <Divider />
      <table>
        <tbody>
          <tr>
            <td>
              <Text
                m="0"
                pr="1"
                fontWeight="bold"
                color="gray.300"
                fontSize="xs">
                Local Time
              </Text>
            </td>
            <td>
              <Text m="0" color="inherit" fontSize="xs">
                {localTimeStrFull}
              </Text>
            </td>
          </tr>
          <tr>
            {timezones.map((tz) => (
              <>
                <td key={tz.tz}>
                  <Text
                    m="0"
                    pr="1"
                    fontWeight="bold"
                    color="gray.300"
                    fontSize="xs">
                    {tz.label}
                  </Text>
                </td>
                <td>
                  <Text m="0" color="inherit" fontSize="xs">
                    {formatFullDateOrIsoStringInTz(date, "datetime", tz.tz)}
                  </Text>
                </td>
              </>
            ))}
          </tr>
        </tbody>
      </table>
    </VStack>
  ) : (
    <Text m="0" fontSize="xs" color="inherit">
      {localTimeStrFull}
    </Text>
  );
  return (
    <Tooltip label={tooltip} maxW="lg">
      <span>{localTimeStr}</span>
    </Tooltip>
  );
}
