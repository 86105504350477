import { Box, Center, Spacer, VStack, Icon, Button } from "@chakra-ui/react";
import { RiArrowRightFill, RiArrowRightSFill } from "react-icons/ri";
import { Link } from "react-router-dom";

type Props = {
  children: React.ReactNode;
  icon: React.ReactElement;
  link?: string;
  onClick?: () => void;
};

export default function ServicePortalButton({
  children,
  link,
  icon,
  onClick,
}: Props) {
  return (
    <Box
      _hover={{
        ".icon": {
          transform: "translateX(6px)",
          opacity: 1.0,
        },
      }}>
      <Button
        px="8"
        py="6"
        height="auto"
        w="auto"
        variant="outline"
        as={link !== undefined ? Link : undefined}
        to={link || ""}
        leftIcon={icon}
        onClick={onClick}>
        {children}
        <Icon
          position="absolute"
          bottom="0"
          right="1"
          opacity="0"
          transition="transform, opacity 0.5s"
          className="icon"
          color="brand.500"
          boxSize="6"
          as={RiArrowRightSFill}
        />
      </Button>
    </Box>
  );
}
