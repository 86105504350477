import {
  Box,
  Button,
  Center,
  Group,
  Image,
  Stack,
  Stepper
} from "@mantine/core";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import FullLoader from "../../../components/chakra/FullLoader";
import { useApiMutation, useApiQuery } from "../../../utilities/apibelRequest";
import BlueprintHelpSidebar from "./BlueprintHelpSidebar";
import BlueprintOrganisationStepCard from "./blueprint-config-steps/BlueprintOrganisationStepCard";
import BlueprintQuestionsStepCard from "./blueprint-config-steps/BlueprintQuestionsStepCard";
import BlueprintReviewCard from "./blueprint-config-steps/BlueprintReviewCard";
import CertCrowdLogoWhite from "./images/CERT-CROWD-BRANDMARK-WHITE.png";
import {
  BlueprintStateAction,
  blueprintStateReducer,
  useBlueprintState,
} from "./useBlueprintState";

import { useDisclosure } from "@mantine/hooks";
import { RiQuestionLine } from "react-icons/ri";
import styles from "./BlueprintSetupScreen.module.css";

const steps = [
  {
    title: "Organisation",
    key: "organisation",
    description: "Information about your organisation",
  },
  {
    title: "ISO9001 Requirements",
    key: "questions",
    useProg: true,
    description: "Policies, Procedures and Requirements for ISO9001",
  },
  {
    title: "Complete",
    key: "review",
    description: "",
  },
];

type Props = {};

export default function BlueprintSetupScreen({}: Props) {
  // On load, we grab the draft if it exists

  const { draftID } = useParams();

  const [helpSidebarState, setHelpSidebarState] = useDisclosure(true);

  const draft = useApiQuery(
    "blueprint/draftByID",
    {
      draftID: draftID || "",
    },
    {
      // Only request once
      cacheTime: Infinity,
      staleTime: Infinity,
    },
  );

  const updateDraftMutation = useApiMutation("blueprint/updateDraft");

  const [blueprintState, dispatchBlueprintState] = useBlueprintState();
  const dispatchBlueprintStateAndPersist = (action: BlueprintStateAction) => {
    // Guess next state
    console.log("Maybe persisting state");
    const nextState = blueprintStateReducer(blueprintState, action);
    if (nextState !== null) {
      console.log("Persisting state");
      updateDraftMutation.mutate({
        draftID: nextState.state.blueprintStateID,
        newState: nextState.state,
      });
    }
    // Do the actual dispatch
    dispatchBlueprintState(action);
  };

  // If draft changes we update blueprint state
  useEffect(() => {
    console.log("Draft change");
    if (draft.isSuccess) {
      console.log("Draft: ", draft.data);
      dispatchBlueprintState({
        type: "set-state",
        payload: {
          state: draft.data.draft.state,
          questions: draft.data.questions,
        },
      });
    } else {
      console.log("No draft");
    }
  }, [draft.isSuccess, dispatchBlueprintState, draft.data]);

  const sidebarState = useDisclosure({ defaultIsOpen: true });

  if (blueprintState === null) {
    return (
      <Center minH="100vh">
        <FullLoader />
      </Center>
    );
  }

  const { state, questions } = blueprintState;

  const numQuestionsComplete = questions.reduce((acc, question) => {
    const qstate = state.questionStates[question.questionID];
    if (!qstate) return acc;
    if (qstate.step === "complete") {
      return 1 + acc;
    }
    if (qstate.step === "ai-result" || qstate.step === "input") {
      return 0.25 + acc;
    }
    return acc;
  }, 0);

  const currQuestionConfig = questions[state.currentQuestionIdx];

  const helpBoxOverrideToHidden = state.step !== "questions";
  const isHelpBoxOpen = helpSidebarState && !helpBoxOverrideToHidden;

  return (
    <Group
      className={styles.outerContainer}
      w="100vw"
      h="100vh"
      py="3%"
      justify="center">
       <Group justify="flex-start" w="100vw"  className={styles.outerContainer}>
        <Image className={styles.logo}  src={CertCrowdLogoWhite}/>
        </Group>
      <Box
        w="100%"
        h="100%"
        maw="92rem"
        mah="45rem"
        className={styles.mainContainer}
        bg="white"> 
        <Group h="100%" align="start" wrap="nowrap">
          <Stack
            py="md"
            pl="xl"
            pr="sm"
            h="100%"
            data-sidebar-open={isHelpBoxOpen}
            className={styles.content}>
            <Group justify={helpBoxOverrideToHidden ? "center" : "space-between"} align="start" wrap="nowrap">
                <Stepper allowNextStepsSelect={false} active={0}>
                  {steps.map((step, idx) => (
                    <Stepper.Step
                      key={step.key}
                      label={step.title}
                      description={step.description}
                    />
                  ))}
                </Stepper>
              {!helpBoxOverrideToHidden && (
                <Button
                  variant="subtle"
                  size="sm"
                  onClick={setHelpSidebarState.toggle}>
                  <RiQuestionLine size="24" />
                </Button>
              )}
            </Group>
            {state.step === "organisation" ? (
              <BlueprintOrganisationStepCard
                orgInfo={state.organisationInfo}
                dispatch={dispatchBlueprintState}
                onComplete={() => {
                  dispatchBlueprintStateAndPersist({
                    type: "complete-org-info",
                  });
                }}
              />
            ) : state.step === "questions" ? (
              <BlueprintQuestionsStepCard
                blueprintState={blueprintState.state}
                blueprintQuestions={blueprintState.questions}
                dispatch={dispatchBlueprintState}
                dispatchAndPersist={dispatchBlueprintStateAndPersist}
              />
            ) : state.step === "review" ? (
              <BlueprintReviewCard
                blueprintState={blueprintState.state}
                dispatch={dispatchBlueprintState}
              />
            ) : null}
          </Stack>
          <Box
            py="sm"
            h="100%"
            data-sidebar-open={isHelpBoxOpen}
            className={styles.helpBox}>
            {isHelpBoxOpen && (
              <BlueprintHelpSidebar
                helpDocText={
                  state.step === "organisation"
                    ? "base-org"
                    : state.step === "review"
                    ? "base-review"
                    : currQuestionConfig?.detailedHelpDoc || null
                }
              />
            )}
          </Box>
        </Group>
      </Box>
    </Group>
  );
}
