import React from "react";
import { Spinner as ChakraSpinner, SpinnerProps } from "@chakra-ui/react";

const Spinner = (props: SpinnerProps) => (
  <ChakraSpinner
    speed="1.25s"
    thickness="4px"
    color="primary1.main"
    emptyColor="primary2.main"
    size="xl"
    {...props}
  />
);

export default Spinner;
