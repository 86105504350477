import React from "react";
import { HStack, Heading, Skeleton } from "@chakra-ui/react";

type Props = {
  title: string;
  children?: React.ReactNode;
  isLoading?: boolean;
};

const CardHeading = ({ title, children, isLoading }: Props) => (
  <HStack align="center" justify="space-between" px="6" pt="4" flexWrap="wrap">
    {isLoading ? (
      <Skeleton height="16px" minWidth="80px" maxWidth="60%" />
    ) : (
      <>
        <Heading fontSize="lg">{title}</Heading>
        <HStack align="end" flexWrap="wrap">
          {children}
        </HStack>
      </>
    )}
  </HStack>
);

export default CardHeading;
