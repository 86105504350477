// import { Button, HStack, Heading, Icon, Text, VStack } from "@chakra-ui/react";
import { Stack, Text, Title } from "@mantine/core";
import {
  BlueprintConfigQuestion,
  BlueprintState,
} from "../../../../shared/v2/definitions/blueprints";
import { post } from "../../../../utilities/apibelRequest";
import {
  BlueprintStateAction
} from "../useBlueprintState";
import QuestionStepAiResult from "./question-steps/QuestionStepAIResult";
import QuestionStepAskAI from "./question-steps/QuestionStepAskAI";
import QuestionStepInitial from "./question-steps/QuestionStepInitial";
import QuestionStepInput from "./question-steps/QuestionStepInput";
import QuestionStepReviewComplete from "./question-steps/QuestionStepReviewComplete";

type Props = {
  blueprintState: BlueprintState;
  blueprintQuestions: BlueprintConfigQuestion[];
  dispatch: (action: BlueprintStateAction) => void;
  dispatchAndPersist: (action: BlueprintStateAction) => void;
};

export default function BlueprintQuestionsStepCard({
  blueprintState,
  blueprintQuestions,
  dispatch,
  dispatchAndPersist,
}: Props) {
  // Current question
  const questionConfig = blueprintQuestions[blueprintState.currentQuestionIdx];
  const questionState =
    blueprintState.questionStates[questionConfig?.questionID || ""];
  // blueprintState.questions[blueprintState.currentQuestionIdx];

  if (!questionConfig) {
    console.log(
      "Question config or state doesnt exist for this question so going to review step",
    );
    dispatch({
      type: "goto-review",
    });
    return null;
  }

  if (!questionState) {
    dispatch({
      type: "init-question-state",
      payload: {
        questionID: questionConfig.questionID,
      },
    });
    return null;
  }

  const handleChooseNo = () => {
    console.log("click-ai");
    dispatch({
      type: "update-question-state",
      payload: {
        state: {
          ...questionState,
          step: "ask-ai",
        },
        questionID: questionConfig.questionID,
      },
    });
  };

  const handleChooseYes = () => {
    console.log("click-yes");
    dispatch({
      type: "update-question-state",
      payload: {
        state: {
          ...questionState,
          step: "input",
        },
        questionID: questionConfig.questionID,
      },
    });
  };

  const handleChooseAI = () => {
    dispatch({
      type: "update-question-state",
      payload: {
        state: {
          ...questionState,
          step: "ai-result",
        },
        questionID: questionConfig.questionID,
      },
    });
  };

  const handleChooseAcceptAI = (result: string) => {
    dispatchAndPersist({
      type: "complete-question",
      payload: {
        resultText: result,
        resultType: "text",
        questionID: questionConfig.questionID,
      },
    });
  };

  const handleChooseAcceptInput = (result: string | undefined) => {
    dispatchAndPersist({
      type: "complete-question",
      payload: {
        resultText: result,
        resultType: "text",
        questionID: questionConfig.questionID,
      },
    });
  };

  const handleClickContinueInputWithFile = async (file: {fileID: string, fileName: string}) => {
    // Complete the file upload
    await post("blueprint/completeFileUploadForBlueprint", {
      fileID: file.fileID,
      draftID: blueprintState.blueprintStateID,
    });
    dispatchAndPersist({
      type: "complete-question",
      payload: {
        resultFile: file,
        resultType: "file",
        questionID: questionConfig.questionID,
      },
    });
  }

  const handleClickContinueWithoutValue = () => {
    dispatchAndPersist({
      type: "complete-question",
      payload: {
        resultText: undefined,
        resultFile: undefined,
        resultType: "text",
        questionID: questionConfig.questionID,
      },
    });
  };

  const handleContinueWithoutEditing = () => {
    dispatch({
      type: "next-question",
    });
  };

  const handleClickBack = () => {
    // Go back a step or question
    dispatch({
      type: "back",
    });
  };

  return (
    <Stack
      w="100%"
      mih="0"
      px="sm"
      pt="lg"
      pb="xl"
      justify="space-between"
      flex={1}>
      <Stack w="100%" align="start">
        <Stack align="start" w="100%" maw="40rem" gap={0}>
          <Title order={2} size="h4">
            {questionState.step === "initial"
              ? questionConfig.label
              : questionConfig.docName}
          </Title>
          {questionState.step === "initial" && (
            <Text c="gray.6" fs="italic">
              {questionConfig.helpText}
            </Text>
          )}
        </Stack>
      </Stack>
      {questionState.step === "initial" ? (
        <QuestionStepInitial
          onBack={handleClickBack}
          onYes={handleChooseYes}
          onNo={handleChooseNo}
        />
      ) : questionState.step === "input" ? (
        <QuestionStepInput
          onBack={handleClickBack}
          onContinue={handleChooseAcceptInput}
          onContinueWithFile={handleClickContinueInputWithFile}
        />
      ) : questionState.step === "ask-ai" ? (
        <QuestionStepAskAI
          onBack={handleClickBack}
          docName={questionConfig.docName}
          onAI={handleChooseAI}
          onContinue={handleClickContinueWithoutValue}
        />
      ) : questionState.step === "ai-result" ? (
        <QuestionStepAiResult
          onBack={handleClickBack}
          question={questionConfig}
          orgInfo={blueprintState.organisationInfo}
          onContinue={handleChooseAcceptAI}
        />
      ) : questionState.step === "complete" ? (
        <QuestionStepReviewComplete
          onBack={handleClickBack}
          questionState={questionState}
          onContinue={handleContinueWithoutEditing}
        />
      ) : null}
      {/* <Group w="100%" justify="end" style={{ alignSelf: "flex-end" }}>
      <Button onClick={handleClickNext}>Save and Continue</Button>
    </Group> */}
    </Stack>
  );

}
