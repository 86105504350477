import React from "react";
import { useSearchParams } from "react-router-dom";
import Redirect from "../../components/common/Redirect";
import { AppState } from "../../components/App/AppProvider";

type Props = {
  baseAppState: AppState;
}

const LoginScreen = ({ baseAppState }: Props) => {
  const isLoggedIn = baseAppState.type !== "loggedout";

  const [urlSearchParams] = useSearchParams();
  const redirect = urlSearchParams.get("redirect");
  return (
  <div>
    {isLoggedIn ? (
      <Redirect
        to={redirect ? decodeURIComponent(redirect) : "/"}
      />
    ) : null}
  </div>
)};

export default LoginScreen;
