import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Link,
  PinInput,
  Spacer,
  Text,
  VStack,
  PinInputField,
} from "@chakra-ui/react";
import { Amplify } from "aws-amplify";
import QRCode from "qrcode.react";
import * as React from "react";
import { AuthState, User } from "./AuthComponent";
import AuthScreenContainer from "./AuthScreenContainer";

type Props = {
  user: User | null;
  updateAppState: (type: AuthState, data: any) => void;
};

const insertCharEveryN = (str: string, char: string, n: number) =>
  str.split("").reduce((acc: string, curr: string, index: number) => {
    if (index % n === 1) {
      return acc + curr + char;
    }
    return acc + curr;
  }, "");

const MFASetup = ({ user, updateAppState }: Props) => {
  const [code, setCode] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [userCode, setUserCode] = React.useState("");
  const [showCode, setShowCode] = React.useState(false);
  const [error, setError] = React.useState<{
    error: boolean;
    msg: string | null;
  }>({ error: false, msg: null });
  const [verifying, setVerifying] = React.useState(false);

  console.log("attr", user?.attributes);

  React.useEffect(() => {
    setLoading(true);
    Amplify.Auth.setupTOTP(user)
      .then((code: string) => {
        setCode(code);
        setLoading(false);
      })
      .catch((e: Error) => {
        console.error(e);
        setLoading(false);
        setError({ error: true, msg: e.message });
      });
  }, [user]);

  const verifyMfaCode = async (e: React.FormEvent<HTMLDivElement>) => {
    e.preventDefault();
    setVerifying(true);
    await Amplify.Auth.verifyTotpToken(user, userCode)
      .then(() => {
        // don't forget to set TOTP as the preferred MFA method
        Amplify.Auth.setPreferredMFA(user, "TOTP");
        updateAppState("signedIn", user);
      })
      .catch((e: Error) => {
        console.error(e);
        setError({ error: true, msg: e.message });
      });
    setVerifying(false);
  };

  const str = `otpauth://totp/CertCrowd:${
    user?.email ?? user?.username
  }?secret=${code}&issuer=CertCrowd`;

  return (
    <chakra-scope>
      <AuthScreenContainer title="Setup Multifactor Authentication?">
        <Box as="form" onSubmit={(e: any) => verifyMfaCode(e)}>
          <Text marginY={4}>
            Using your phone, <b>scan the QR code</b> below with an
            authenticator (i.e. <b>Google Authenticator</b>, Microsoft
            Authenticator, etc.) and then <b>enter the 6-digit code</b> below;
            Then <b>click &quot;Verify & Setup&quot;</b>.
            <br />
            <Link
              color="blue.500"
              href="https://help.certcrowd.com/using-certcrowd/logging-in-and-multi-factor-authentication/"
              isExternal>
              See more info about MFA setup.
            </Link>
          </Text>
          <VStack spacing={2}>
            <QRCode value={str} />
            {showCode && (
              <Text>
                <b>{insertCharEveryN(code, " ", 5)}</b>
              </Text>
            )}
            <Text
              _hover={{ cursor: "pointer", textDecoration: "underline" }}
              color="gray.500"
              onClick={() => {
                setShowCode(true);
              }}>
              {!showCode && <i>Can&apos;t scan the QRcode? Show code.</i>}
            </Text>
            <FormControl isRequired isInvalid={error.error}>
              <FormLabel>Authenticator Code (6 digits)</FormLabel>
              <HStack>
                <PinInput value={userCode} autoFocus onChange={setUserCode}>
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                </PinInput>
              </HStack>
              {error.error && <FormErrorMessage>{error.msg}</FormErrorMessage>}
            </FormControl>
          </VStack>
          <VStack spacing={4}>
            <Spacer />
            <Button
              w="full"
              type="submit"
              isDisabled={!userCode || userCode.length !== 6 || loading}
              isLoading={verifying}
              loadingText="Verifying...">
              Verify & Setup
            </Button>
            <Button
              alignSelf="end"
              colorScheme="gray"
              variant="link"
              type="button"
              onClick={() => updateAppState("signedIn", user)}>
              Skip this time
            </Button>
          </VStack>
        </Box>
      </AuthScreenContainer>
    </chakra-scope>
  );
};

export default MFASetup;
