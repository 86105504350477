import {
  Button,
  chakra,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  PinInput,
  PinInputField,
  VStack,
} from "@chakra-ui/react";
import * as React from "react";
import AuthScreenContainer from "./AuthScreenContainer";

type Props = {
  confirmSignIn: (code: string) => Promise<void>;
};

const MFAVerify = ({ confirmSignIn }: Props) => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [userCode, setUserCode] = React.useState("");
  const [error, setError] = React.useState<
    | {
        error: true;
        msg: string;
      }
    | {
        error: false;
      }
  >({ error: false });

  const handleOnChangeCode = (value: string) => {
    if (error) {
      setError({ error: false });
    }
    setUserCode(value);
  };

  const verifyMfaCode = async () => {
    setLoading(true);
    try {
      await confirmSignIn(userCode);
      setLoading(false);
    } catch (e) {
      if (e instanceof Error) {
        setError({ error: true, msg: e.message });
      } else {
        setError({ error: true, msg: "Something wen't wrong " });
      }
      setLoading(false);
    }
  };

  return (
    <chakra-scope>
      <AuthScreenContainer title="Multifactor Authentication">
        <chakra.form
          w="full"
          onSubmit={(event) => {
            event.preventDefault();
            verifyMfaCode();
          }}>
          <VStack w="full" align="start" spacing={6}>
            <FormControl isRequired isInvalid={error.error}>
              <FormLabel>Authenticator Code (6 digits)</FormLabel>
              <HStack>
                <PinInput
                  value={userCode}
                  autoFocus
                  onChange={handleOnChangeCode}>
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                </PinInput>
              </HStack>
              {error.error && <FormErrorMessage>{error.msg}</FormErrorMessage>}
            </FormControl>
            <Button
              w="full"
              type="submit"
              isLoading={loading}
              isDisabled={!userCode || userCode.length !== 6}
              loadingText="Verifying...">
              Verify
            </Button>
          </VStack>
        </chakra.form>
      </AuthScreenContainer>
    </chakra-scope>
  );
};

export default MFAVerify;
