import React from "react";
import { Form, InputGroup, Row, Col } from "react-bootstrap";
import ReactSelect from "react-select";

interface SelectProps {
  onUpdate?: (event: any) => void;
  name: string;
  value: string;
  unselectedOption?: string;
  optionsList: any[];
  optionsListID?: string;
  optionsListValue?: string | number;
  optionsChild?: (listItem: any) => { label: string; value: string };
  readOnly: boolean;
  readOnlyValue?: string;
  readOnlyComponent?: () => JSX.Element;
  disabled?: boolean;
  customField?: boolean;
  unit?: string;
  className?: any;
  style?: any;
  icon?: boolean;
}

export const Select = (props: SelectProps) => {
  let optionsList: any[] = [];
  if (props.unselectedOption !== undefined) {
    optionsList = [{ value: "", label: props.unselectedOption }];
  }
  if (
    props.optionsListID !== undefined &&
    props.optionsListValue !== undefined &&
    props.icon
  ) {
    optionsList = optionsList.concat(
      props.optionsList.map((listItem: any) => ({
        value: listItem[props.optionsListID!],
        label: (
          <Row>
            <Col sm="2" xs="2">
              {listItem[1]}
            </Col>
            <Col>{listItem[props.optionsListValue!].concat("  ")}</Col>
          </Row>
        ),
      })),
    );
  } else if (
    props.optionsListID !== undefined &&
    props.optionsListValue !== undefined
  ) {
    optionsList = optionsList.concat(
      props.optionsList.map((listItem: any) => ({
        value: listItem[props.optionsListID!],
        label: listItem[props.optionsListValue!],
      })),
    );
  } else if (props.optionsChild !== undefined) {
    optionsList = optionsList.concat(props.optionsList.map(props.optionsChild));
  } else {
    throw new Error(
      "Either optionsListID and optionsListValue must be defined or optionsChild",
    );
  }

  return !props.readOnly ? (
    <Row>
      <Col>
        <ReactSelect
          value={optionsList.filter(({ value }) => {
            if (value === props.value) {
              if (value && value.Permission) {
                return value.Permission;
              }
              return true;
            }
          })}
          options={optionsList}
          isMulti={false}
          isSearchable
          placeholder={props.unselectedOption}
          onChange={props.onUpdate}
          className={props.className !== undefined ? props.className : "select"}
          styles={props.style}
          isDisabled={props.disabled}
        />
      </Col>
      {props.unit && props.value ? (
        <span className="col-form-label form-unit">{props.unit}</span>
      ) : null}
    </Row>
  ) : (
    <>
      {props.readOnlyComponent
        ? props.readOnlyComponent()
        : props.readOnlyValue}
      {props.unit && props.value ? (
        <span className="col-form-label form-unit">{props.unit}</span>
      ) : null}
    </>
  );
};
