import React from "react";
import { Form, Col, Row, OverlayTrigger, Tooltip } from "react-bootstrap";

interface RadioProps {
  label: any;
  name: string;
  id: string;
  value: string;
  checked: boolean;
  onChange: (event: any) => void;
  disabled: boolean;
  type: "radio" | "checkbox";
  labelPosition?: "left" | "right";
  tooltipText?: string;
  style?: any;
}

const CheckType = (props: RadioProps) => {
  // if (props.labelPosition && props.labelPosition === "left") {
  //   return (
  //     <Form.Check style={props.style ? props.style : undefined}>
  //       <Row>
  //         <Col
  //           xs="8"
  //           sm="6"
  //           style={{ textAlign: "right", paddingRight: "40px" }}>
  //           <Form.Check.Label
  //             className="col-form-label"
  //             htmlFor={props.id}
  //             style={{ fontSize: "16px" }}>
  //             {props.label}
  //           </Form.Check.Label>
  //         </Col>
  //         <Col xs="4" sm="auto">
  //           <Form.Check.Input
  //             type={props.type}
  //             id={props.id}
  //             style={{ width: "16px", height: "16px", marginTop: "8px" }}
  //             name={props.name}
  //             value={props.value}
  //             onChange={props.onChange}
  //             checked={props.checked}
  //             disabled={props.disabled}
  //           />
  //         </Col>
  //       </Row>
  //     </Form.Check>
  //   );
  // }
  if (props.tooltipText) {
    // return (
    // <Form.Check type={props.type} id={props.id}>
    //   <Form.Check.Input
    //     type={props.type}
    //     id={props.id}
    //     name={props.name}
    //     value={props.value}
    //     onChange={props.onChange}
    //     checked={props.checked}
    //     disabled={props.disabled}
    //   />
    // <OverlayTrigger
    //   placement="auto"
    //   overlay={
    //     <Tooltip id={`tooltip--radio-element-description-${props.id}`}>
    //       {props.tooltipText}
    //     </Tooltip>
    //   }>
    //     <Form.Check.Label>{props.label}</Form.Check.Label>
    //   </OverlayTrigger>
    // </Form.Check>
    return (
      
        <OverlayTrigger
          placement="auto"
          overlay={
            <Tooltip id={`tooltip--radio-element-description-${props.id}`}>
              {props.tooltipText}
            </Tooltip>
          }>
          <Form.Check type={props.type} {...props} />
        </OverlayTrigger>
      
    );
    // );
  }
  return (
    <Form.Check className="form-check-container" type={props.type} {...props} />
  );
};

export { CheckType };
