import React from "react";
import {
  FieldIdentifier,
  Filter,
} from "../../../../shared/v2/helpers/filters/baseFilterTypes";
import assertNever from "../../../../utilities/assertNever";
import {
  FilterableFieldSelection,
  WorkingFilter,
  getAvailableOpsForField,
  getCoreFilterableFields,
  getFilterTypeForCoreField,
} from "../../../../constants/frontEndFilterHelpers";
import { FilterOperationsType } from "../../../../../../api/application/shared/v2/helpers/filters/filterSchemas";
import { Closed } from "../../../../shared/v2/constants/ObjectStatusID";

export const SortOptions = [
  {
    value: "name",
    label: "Name",
  },
  {
    value: "readableID",
    label: "ID",
  },
  {
    value: "createTs",
    label: "Create Time",
  },
  {
    value: "modifiedTs",
    label: "Updated Time",
  },
];

export type RecordsViewState = {
  filters: WorkingFilter[];
  query: string;
  page: number;
  resultsPerPage: number;
  sortBy: {
    value: string;
    label: string;
  };
};

export type RecordsViewAction =
  | {
      type: "updateQuery";
      query: string;
    }
  | {
      type: "reset";
    }
  | {
      type: "updatePage";
      page: number;
    }
  | {
      type: "updateResultsPerPage";
      resultsPerPage: number;
    }
  | {
      type: "addFilter";
      filter: WorkingFilter;
    }
  | {
      type: "updateFilter";
      filter: Partial<WorkingFilter>;
    }
  | {
      type: "deleteFilter";
      filterID: string;
    }
  | {
      type: "clearFilters";
    }
  | {
      type: "updateSortBy";
      sortBy: {
        value: string;
        label: string;
      };
    };

const statusField = getCoreFilterableFields().find(
  (field) => field.value === "status",
);

const statusOps = getAvailableOpsForField("objectStatusID").find(
  (field) => field.op === "is-not-status",
);

const statusNotClosedFilter: WorkingFilter | undefined =
  statusField !== undefined && statusOps !== undefined
    ? {
        ID: "default-filter",
        field: statusField,
        op: statusOps,
        value: {
          label: "Closed",
          value: Closed,
        },
      }
    : undefined;

const baseFilters = [];
if (statusNotClosedFilter) {
  baseFilters.push(statusNotClosedFilter);
}

const DefaultValue: RecordsViewState = {
  filters: baseFilters,
  query: "",
  page: 0,
  resultsPerPage: 30,
  sortBy: {
    value: "name",
    label: "Name",
  },
};
function reducer(state: RecordsViewState, action: RecordsViewAction) {
  switch (action.type) {
    case "updateQuery":
      return { ...state, query: action.query };
    case "reset":
      return { ...DefaultValue };
    case "updatePage":
      return { ...state, page: action.page };
    case "updateResultsPerPage":
      return { ...state, resultsPerPage: action.resultsPerPage, page: 0 };
    case "addFilter":
      return {
        ...state,
        filters: [...state.filters, action.filter],
      };
    case "updateFilter":
      return {
        ...state,
        filters: state.filters.map((f) =>
          f.ID === action.filter.ID ? { ...f, ...action.filter } : f,
        ),
      };
    case "deleteFilter":
      return {
        ...state,
        filters: state.filters.filter((f) => f.ID !== action.filterID),
      };
    case "clearFilters":
      return {
        ...state,
        filters: [],
      };
    case "updateSortBy":
      return {
        ...state,
        sortBy: action.sortBy,
      };
    default:
      return assertNever(action);
  }
}

export default function useRecordsViewState() {
  return React.useReducer(reducer, { ...DefaultValue });
}
