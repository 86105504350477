import React from "react";
import { Form, Row, Col, Table } from "react-bootstrap";
import moment from "moment";
import { Link } from "react-router-dom";
import { Trash2 } from "react-feather";
import { Badge, Circle, HStack, Tooltip } from "@chakra-ui/react";
import { Text } from "./Text";
import { CheckType } from "./Radio";
import { Textarea } from "./Textarea";
import { AlertUsers } from "./AlertUsers";
import * as QuestionType from "../../../shared/constants/questionType";
import { User } from "../../../shared/definitions/users";
import { Matrix } from "./Matrix";
import { Status, objectStatusToVariant } from "../../UI/Status/Status";
import { CustomFieldList } from "../CustomFields/CustomField";
import { MandatoryIndicator } from "../MandatoryIndicator/MandatoryIndicator";
import {
  ObjectStatus,
  ObjectStatusIDToName,
} from "../../../shared/v2/constants/ObjectStatusID";

const isNumeric = (num: number | string): boolean => !isNaN(Number(num));
const getQCF = (
  cfields: any[],
  responses: any[],
  InstanceMinViableResponse: boolean,
) => {
  const qcf: any[] = [];
  let customField: any;
  responses.map((response: any, index: number) => {
    if (isNumeric(response.QuestionElementID)) {
      customField = cfields.find(
        (cfield: any) => cfield.CustomFieldID === response.CustomFieldID,
      );
      if (customField) {
        if (
          customField.CustomFieldTypeColumnName === "ListValueID" ||
          customField.CustomFieldTypeColumnName === "DocumentID" ||
          customField.CustomFieldTypeColumnName === "OneDriveDocumentID"
        ) {
          customField.Value =
            response[`${customField.CustomFieldTypeColumnName}`];
        } else {
          customField.Value =
            response[
              `QuestionElementResponse${customField.CustomFieldTypeColumnName}`
            ];
        }
        qcf.push(customField);
      }
    } else {
      cfields.map((cf: any) => {
        if (response.CustomFieldID === cf.CustomFieldID) {
          // && (response.QuestionElementID === cf.InstanceQuestionElementID || cf.CustomFieldTypeColumnName === 'ListValueID' || cf.CustomFieldTypeColumnName === 'DocumentID')) { //commented because it would stop some custom fields from showing up

          // Use the responded too question text in case it has changed since it was responsed to
          if (InstanceMinViableResponse) {
            cf.Label = response.QuestionElementText;
            cf.HelpText = response.QuestionElementHelpText;
            cf.Unit = response.QuestionElementUnitLabel;
          }

          if (cf.CustomFieldTypeColumnName === "ListValueID") {
            cf.Value = response.ListValueID;
          } else if (cf.CustomFieldTypeColumnName === "VARCHAR") {
            cf.Value = response.QuestionElementResponseVARCHAR;
          } else if (cf.CustomFieldTypeColumnName === "DATETIME") {
            cf.Value = response.QuestionElementResponseDATETIME;
          } else if (cf.CustomFieldTypeColumnName === "INT") {
            cf.Value = response.QuestionElementResponseINT;
          } else if (cf.CustomFieldTypeColumnName === "LONGVARCHAR") {
            cf.Value = response.QuestionElementResponseLONGVARCHAR;
          } else if (cf.CustomFieldTypeColumnName === "URL") {
            cf.Value = response.QuestionElementResponseURL;
          } else if (
            cf.CustomFieldTypeColumnName === "DocumentID" ||
            cf.CustomFieldTypeColumnName === "OneDriveDocumentID"
          ) {
            cf.Value = response.DocumentID;
          }
          if (
            cf.CustomFieldTypeColumnName !== "ListValueID" ||
            cf.ListValues.length > 0
          ) {
            qcf.push(cf);
          }
        }
      });
    }
  });

  return qcf;
};

export interface QuestionResponse {
  QuestionElementID: string;
  QuestionElementText: string;
  QuestionElementOrder: number;
  QuestionElementResponseBIT: 0 | 1;
  QuestionElementResponseVARCHAR: string;
  QuestionElementResponseDATETIME: Date;
  QuestionElementResponseINT: number;
  QuestionElementResponseLONGVARCHAR: string;
  QuestionElementResponseURL: string;
  ListValueID: string;
  QuestionID: string;
  ObjectStatusID: string;
  CustomFieldID: string;
}

interface QuestionProps {
  InstanceQuestionID: string;
  QuestionText: string;
  QuestionOrder: number;
  QuestionIsUber: 0 | 1;
  QuestionIsMulti: 0 | 1;
  QuestionMandatory: 0 | 1;
  QuestionTypeID: string;
  Responses: QuestionResponse[];
  ObjectStatusID: string;
  QuestionResponseTs: string;
  QuestionRespondentUserID: string;
  QuestionRespondentUserName: string;
  onUpdate: (event: any) => void;
  readOnly: boolean;
  RiskMatrix?: any;
  Controls?: any[];
  isPrefilled?: boolean;
  QuestionTypes?: any[];
  CustomFieldID: string;
  CustomFields: any[];
  pageStatus: string;
  formikData?: any;
  binIcon?: boolean;
  setFieldValue?: (field: any, fields: any[]) => void;
  ResponsibleUsers: User[];
  InstanceMinViableResponse: boolean;
}

const readOnlyView = (
  questionTypeID: string,
  responses: QuestionResponse[],
) => {
  if (questionTypeID === QuestionType.SingleSelect) {
    const currentValue = responses.find(
      (response: QuestionResponse) => response.QuestionElementResponseBIT === 1,
    );
    return currentValue ? currentValue.QuestionElementText : "No Response";
  }
  if (questionTypeID === QuestionType.Notes) {
    const currentValue = responses.find(
      (response: QuestionResponse) =>
        response.QuestionElementResponseVARCHAR !== null,
    );
    return currentValue
      ? currentValue.QuestionElementResponseVARCHAR
      : "No Response";
  }
  // else if (questionTypeID === QuestionType.List) {
  //     return '' // TO DO
  // }
  if (
    questionTypeID === QuestionType.InherentRisk ||
    questionTypeID === QuestionType.ResidualRisk
  ) {
    // display answers - for each dimension
    return "Answers here";
  }
  if (questionTypeID === QuestionType.RiskControls) {
    return "No response required";
  }
  return "";
};
const IDToColor = {
  "2cc17bd9-3ff4-4392-aeac-0c8971765747": "var(--chakra-colors-red-500)", // negative
  "a30dabb5-0818-41ca-bb17-db56c8c81bb5": "var(--chakra-colors-green-500)", // positive
  "eb01e49c-5d14-408b-a0dd-a1ec425606bb": "var(--chakra-colors-blue-500)", // neutral
} as const;
const getQuestionField = (props: QuestionProps) => {
  if (props.QuestionTypeID === QuestionType.SingleSelect) {
    // show radio buttons
    if (
      !props.readOnly ||
      !props.Responses.find(
        (response: QuestionResponse) =>
          response.QuestionElementResponseBIT === 1,
      )
    ) {
      return (
        <fieldset>
          {props.Responses.map((response: QuestionResponse) => (
            <HStack
              alignItems="center"
              justifyContent="space-between"
              spacing={2}
              w="full">
              {props.QuestionIsUber && !props.readOnly ? (
                <Tooltip
                  label={`This is a master question, the response will influence the status of the task. This response is ${
                    ObjectStatusIDToName[
                      response.ObjectStatusID
                    ] as ObjectStatus
                  }.`}>
                  <Circle
                    as="span"
                    bg={IDToColor[response.ObjectStatusID] as ObjectStatus}
                    color={IDToColor[response.ObjectStatusID] as ObjectStatus}
                    size="2"
                    position="absolute"
                    fontSize="0.75rem"
                    zIndex="99"
                  />
                </Tooltip>
              ) : (
                ""
              )}
              <CheckType
                key={response.QuestionElementID}
                label={response.QuestionElementText}
                name={props.InstanceQuestionID}
                id={response.QuestionElementID}
                value={response.QuestionElementID}
                checked={response.QuestionElementResponseBIT === 1}
                onChange={props.onUpdate}
                disabled={props.readOnly}
                type="radio"
                labelPosition="left"
              />
            </HStack>
          ))}
        </fieldset>
      );
    }
    return (
      <Col sm="2">
        <Text
          value={readOnlyView(props.QuestionTypeID, props.Responses)}
          name={props.InstanceQuestionID}
          readOnly
          style={{ textAlign: "right" }}
        />
      </Col>
    );
  }
  if (props.QuestionTypeID === QuestionType.Notes) {
    const noteResponse = props.Responses[0];
    return (
      <Textarea
        id={noteResponse.QuestionElementID}
        value={noteResponse.QuestionElementResponseVARCHAR}
        name={props.InstanceQuestionID}
        readOnly={props.readOnly}
        onUpdate={props.onUpdate.bind({}, props.InstanceQuestionID)}
        showCharacterLimit
        richText
      />
    );
  }
  if (props.QuestionTypeID === QuestionType.Custom) {
    return (
      <CustomFieldList
        customFields={getQCF(
          props.CustomFields,
          props.Responses,
          props.InstanceMinViableResponse,
        )}
        pageStatus={props.pageStatus}
        disabled={props.readOnly}
        onUpdate={props.onUpdate}
        QuestionID={props.InstanceQuestionID}
        QuestionOrder={props.QuestionOrder}
      />
    );
  }
  if (
    props.QuestionTypeID === QuestionType.InherentRisk ||
    props.QuestionTypeID === QuestionType.ResidualRisk
  ) {
    return <Matrix name={props.InstanceQuestionID} {...props} />;
  }
  if (props.QuestionTypeID === QuestionType.RiskControls) {
    return (
      <Table responsive>
        <thead>
          <tr>
            <th>Name</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {props.Controls && props.Controls.length > 0 ? (
            props.Controls.map((control: any, index: number) => (
              <tr className="table-risk-controls" key={index}>
                <td>
                  <Link
                    to={`/risk/${control.RequirementID}?display=risks`}
                    target="_blank">
                    {control.RequirementName}
                  </Link>
                </td>
                <td>
                  <Status
                    variant={objectStatusToVariant(control.ObjectStatusID)}
                    text={control.ObjectStatusName}
                    tooltip={control.ObjectStatusDescription}
                  />
                </td>
              </tr>
            ))
          ) : (
            <td>No controls</td>
          )}
        </tbody>
      </Table>
    );
  }
  if (props.QuestionTypeID === QuestionType.AlertRightNow) {
    return <AlertUsers {...props} />;
  }
  if (props.QuestionTypeID === QuestionType.SectionBreak) {
    return (
      <div>
        <h1 style={{ margin: "1em 0 0" }}>{props.QuestionText}</h1>
        <hr style={{ border: "1px grey solid", margin: 0 }} />
      </div>
    );
  }
  return null;
};

const Question = (props: QuestionProps) => (
  <>
    {props.QuestionTypeID !== QuestionType.SectionBreak ? (
      <Row style={{ marginBottom: "15px", paddingRight: "8px" }}>
        <Col>
          {props.QuestionIsUber && !props.readOnly ? (
            <>
              <Badge colorScheme="brand">Master Question</Badge>
              <span>&nbsp;</span>
            </>
          ) : null}
          <strong>
            {props.QuestionText}
            {isNumeric(props.InstanceQuestionID)
              ? ` (${props.InstanceQuestionID})`
              : ""}
            {props.QuestionMandatory ? (
              <MandatoryIndicator
                ID={props.InstanceQuestionID}
                elementType="question"
              />
            ) : (
              ""
            )}
          </strong>
          <Form.Label as="label" column sm={5}>
            {props.isPrefilled ? (
              <div className="form-label-small-italic">
                (result of previous risk assessment selected)
              </div>
            ) : (
              ""
            )}
          </Form.Label>
        </Col>
        {props.binIcon ? (
          <Col sm="1">
            <button
              style={{
                backgroundColor: "white",
                border: "none",
                marginTop: "10px",
                marginRight: "10px",
              }}
              onClick={
                props.onUpdate !== undefined
                  ? (value: any) =>
                      props.onUpdate({
                        value: "remove_question",
                        questionID: props.InstanceQuestionID,
                        questionOrder: props.QuestionOrder,
                      })
                  : props.onUpdate
              }>
              <Trash2 style={{ color: "#d8d8d8" }} className="feather" />
            </button>
          </Col>
        ) : null}
      </Row>
    ) : null}
    <Row style={{ marginBottom: "30px" }}>
      <Col>{getQuestionField(props)}</Col>
    </Row>
    {props.QuestionResponseTs &&
    props.QuestionRespondentUserID &&
    props.readOnly &&
    props.Responses.length > 0 ? (
      <Form.Group as={Row}>
        <Col sm={{ span: 7, offset: 2 }}>
          <small className="light-font">{`Response from ${
            props.QuestionRespondentUserName
          } at ${moment
            .utc(props.QuestionResponseTs)
            .local()
            .format("D-MMM-YYYY HH:mm")}`}</small>
        </Col>
      </Form.Group>
    ) : null}
  </>
);

export { Question };
