import React from "react";
import {
  BreadcrumbSeparator,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  chakra,
  Icon,
  useColorModeValue,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { RiArrowRightSLine } from "react-icons/ri";

export type BreadcrumbElement = {
  label: string;
  link?: string;
};

type Props = {
  breadcrumbs: BreadcrumbElement[];
};

const Breadcrumbs = ({ breadcrumbs }: Props): JSX.Element => {
  const bcColor = useColorModeValue("gray.600", "whiteAlpha.800");

  return (
    <Breadcrumb
      fontSize="sm"
      separator={
        <chakra.span>
          <Icon as={RiArrowRightSLine} boxSize={3} />
        </chakra.span>
      }>
      {breadcrumbs.map(({ label, link }, idx) => (
        <BreadcrumbItem
          key={`${label}:${link}`}
          color={bcColor}
          sx={{
            "& > span": {
              marginStart: 1,
              marginEnd: 1,
            },
          }}>
          {link !== undefined ? (
            <BreadcrumbLink as={Link} to={link}>
              {label}
            </BreadcrumbLink>
          ) : (
            <BreadcrumbLink isCurrentPage>{label}</BreadcrumbLink>
          )}
          {idx >= breadcrumbs.length - 1 && <BreadcrumbSeparator />}
        </BreadcrumbItem>
      ))}
    </Breadcrumb>
  );
};

export default Breadcrumbs;
