import {
  Box,
  Button,
  Center,
  FormLabel,
  HStack,
  Input,
  LinkBox,
  LinkOverlay,
  Select,
  Spacer,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tfoot,
  Th,
  Thead,
  Tooltip,
  Tr,
  useBreakpointValue,
  useColorModeValue,
  VStack,
  ButtonGroup,
} from "@chakra-ui/react";
import { format } from "date-fns";
import Papa from "papaparse";
import { useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { Link as RouterLink } from "react-router-dom";
import { useAppState } from "../../components/App/AppProvider";
import ChakraScreenContainer from "../../components/chakra/ChakraScreenContainer";
import MissingPermission from "../../components/chakra/common/MissingPermission";
import Spinner from "../../components/chakra/common/Spinner";
import Icon from "../../components/UI/Icon";
import {
  objectStatusNameToVariant,
  Status,
} from "../../components/UI/Status/Status";
import { useApiQuery } from "../../utilities/apibelRequest";
import assertNever from "../../utilities/assertNever";
import {
  formatUtcDateReadableLong,
  userDateToUtcDate,
  utcDateToUserDate,
} from "../../utilities/dateUtils";
import { downloadAsCsv } from "../../utilities/downloadAsCsv";

type User = {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
};

type AlertType =
  | "created_note"
  | "started_ondemand_action"
  | "created_issue"
  | "responsed_to_action";

const typeToDescription = (type: AlertType) => {
  switch (type) {
    case "created_note":
      return "Created a note";
    case "started_ondemand_action":
      return "Started an on-demand action";
    case "created_issue":
      return "Created an issue";
    case "responsed_to_action":
      return "Response to an action";
    default:
      return assertNever(type);
  }
};

type Alert = {
  id: string;
  type: AlertType;
  notifiedUser: User;
  context: {
    type: "task" | "record" | "action";
    objectTypeGroup?:
      | "issue"
      | "requirement"
      | "risk"
      | "register"
      | (string | Record<string, never>);
    objectTypeName: string;
    objectStatusName: string;
    id: string;
    name: string;
    readableId: string;
    createUser: User;
    responsibleUser: User;
    recordCreateTs: string;
  };
  time: string;
};

const AlertRightNowReportScreen = () => {
  const tooltipColor = useColorModeValue("white", "black");
  const tooltipBgColor = useColorModeValue("gray.700", "white");
  const { permissions } = useAppState().app;
  const hasReadReportPermission = permissions.REPORTS.READ.ALL;
  const tableSize = useBreakpointValue({ base: "sm", lg: "md" }) as "md" | "lg";
  const [startDate, setStartDate] = useState<string | undefined>(undefined);
  const [endDate, setEndDate] = useState<string | undefined>(undefined);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const alertQuery = useApiQuery("alert/all", {
    orderBy: "date",
    limit,
    skip: (page - 1) * limit,
    dateFrom: startDate || undefined,
    dateTo: endDate || undefined,
  });

  const getPageItems = () => {
    return (
      <>
        <Button
          onClick={() => {
            setPage(page - 1);
          }}
          disabled={!alertQuery.data?.hasPreviousPage}
        >
          Prev
        </Button>
        <Button
          onClick={() => {
            setPage(page + 1);
          }}
          disabled={!alertQuery.data?.hasNextPage}
        >
          Next
        </Button>
      </>
    );
  };

  const queryClient = useQueryClient();
  const handleClickRefresh = () => {
    queryClient.invalidateQueries("alert");
  };

  const handleClickExport = () => {
    if (!alertQuery.isSuccess) return;

    const data = alertQuery.data.alerts.map((alert) => ({
      ID: alert.context.readableId,
      Record: alert.context.name,
      Description: typeToDescription(alert.type),
      "Notified User": `${alert.notifiedUser.firstName} ${alert.notifiedUser.lastName}`,
      "Triggered User": `${alert.context.createUser.firstName} ${alert.context.createUser.lastName}`,
      Time: formatUtcDateReadableLong(new Date(alert.context.recordCreateTs)),
    }));
    const filename = `alert-history-${startDate ? `${startDate}_` : ""}${
      endDate ? `${endDate}-` : ""
    }export.csv`;
    downloadAsCsv(data, filename);
  };

  const renderRow = (alert: Alert) => (
    <Tr key={alert.id}>
      <LinkBox _hover={{ textDecor: "underline" }} as={Td}>
        <LinkOverlay
          as={RouterLink}
          to={`/${
            alert.context.objectTypeGroup
              ? alert.context.objectTypeGroup.toLowerCase()
              : alert.context.type === "task"
              ? "instance"
              : "action"
          }/${alert?.context.id}`}
          alignItems="center"
        >
          {alert?.context.readableId} - {alert?.context.name}
        </LinkOverlay>
      </LinkBox>

      <Td>
        {alert.type !== "created_note" ? (
          <Status
            variant={objectStatusNameToVariant(alert.context.objectStatusName)}
            text={alert.context.objectStatusName}
          />
        ) : (
          "-"
        )}
      </Td>
      <Td>{typeToDescription(alert?.type)}</Td>
      <Td>{`${alert?.notifiedUser.firstName} ${alert?.notifiedUser.lastName}`}</Td>
      <Td>
        {`${alert?.context.createUser.firstName} ${alert?.context.createUser.lastName}`}
      </Td>
      <Tooltip
        backgroundColor={tooltipBgColor}
        label={
          <chakra-scope>
            <VStack align="start" spacing="0">
              <Table size="sm" whiteSpace="pre-wrap" maxWidth="sm">
                <Thead position="relative">
                  <Tr>
                    <Th backgroundColor={tooltipBgColor} color={tooltipColor}>
                      TimeZone
                    </Th>
                    <Th backgroundColor={tooltipBgColor} color={tooltipColor}>
                      Time
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  <td>
                    <Text
                      p="0"
                      fontSize="sm"
                      backgroundColor={tooltipBgColor}
                      color={tooltipColor}
                    >
                      Computer Timezone (
                      {Intl.DateTimeFormat().resolvedOptions().timeZone}):
                    </Text>
                  </td>
                  <td>
                    <Text
                      p="0"
                      fontSize="sm"
                      backgroundColor={tooltipBgColor}
                      color={tooltipColor}
                    >
                      {format(
                        utcDateToUserDate(new Date(alert.time)),
                        "PPPPp",
                      ).toString()}
                    </Text>
                  </td>
                  <tr>
                    <td>
                      <Text
                        p="0"
                        fontSize="sm"
                        backgroundColor={tooltipBgColor}
                        color={tooltipColor}
                      >
                        UTC:
                      </Text>
                    </td>
                    <td width="auto">
                      <Text
                        p="0"
                        fontSize="sm"
                        backgroundColor={tooltipBgColor}
                        color={tooltipColor}
                      >
                        {format(
                          userDateToUtcDate(new Date(alert.time)),
                          "PPPPp",
                        ).toString()}
                      </Text>
                    </td>
                  </tr>
                </Tbody>
              </Table>
            </VStack>
          </chakra-scope>
        }
      >
        <Td>
          <Text>{formatUtcDateReadableLong(new Date(alert?.time || 0))}</Text>
        </Td>
      </Tooltip>
    </Tr>
  );

  return (
    <chakra-scope>
      <ChakraScreenContainer pageTitle="Alert Right Now History Report">
        {!hasReadReportPermission ? (
          <MissingPermission />
        ) : (
          <>
            <HStack align="end">
              <div>
                <FormLabel>After Date (Inclusive)</FormLabel>
                <Input
                  id="startDate"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                  type="date"
                />
              </div>
              <div>
                <FormLabel>Before Date (Inclusive)</FormLabel>
                <Input
                  id="endDate"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                  type="date"
                />
              </div>
              <Box>
                <FormLabel>Alerts Per Page</FormLabel>
                <Select
                  onChange={(e) => {
                    setLimit(Number(e.target.value));
                    setPage(1);
                  }}
                  placeholder="Alerts"
                  value={limit}
                >
                  <option value={10}>10</option>
                  <option value={25}>25</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                  <option value={200}>200</option>
                </Select>
              </Box>
              <Spacer />

              <Button
                leftIcon={<Icon name="Download" />}
                onClick={handleClickExport}
                size="sm"
                variant="ghost"
              >
                Export as CSV
              </Button>

              <Button
                isLoading={alertQuery.isRefetching}
                disabled={alertQuery.isFetching}
                leftIcon={<Icon name="RefreshCw" />}
                variant="ghost"
                loadingText="Refreshing"
                size="sm"
                onClick={handleClickRefresh}
              >
                Refresh
              </Button>
            </HStack>
            <TableContainer w="full" pt={2} pb={4}>
              <Table
                variant="data-table"
                size={tableSize}
                position="relative"
                isFetching={alertQuery.isFetching}
              >
                <Thead position="relative">
                  <Tr>
                    <Th>Record</Th>
                    <Th>Status</Th>
                    <Th>Description</Th>
                    <Th>Alerted user</Th>
                    <Th>Triggered By</Th>
                    <Th>Time</Th>
                  </Tr>
                </Thead>
                {alertQuery?.isSuccess && (
                  <>
                    <Tbody opacity={alertQuery.isFetching ? 0.3 : 1}>
                      {alertQuery.data?.alerts.map(renderRow)}
                    </Tbody>
                    <Tfoot>
                      <Tr>
                        <Th>Record</Th>
                        <Th>Status</Th>
                        <Th>Description</Th>
                        <Th>Alerted user</Th>
                        <Th>Triggered By</Th>
                        <Th>Time</Th>
                      </Tr>
                    </Tfoot>
                  </>
                )}
                {(alertQuery.isFetching || alertQuery.isLoading) && (
                  <Center height={100}>
                    <Spinner
                      zIndex={1}
                      opacity={1}
                      size="lg"
                      position="absolute"
                      top="16"
                      left="50%"
                    />
                  </Center>
                )}
              </Table>
              <HStack>
                <Spacer />
                <ButtonGroup size="sm" variant="outline" isAttached>
                  {alertQuery?.isSuccess && <>{getPageItems()}</>}
                </ButtonGroup>
              </HStack>
            </TableContainer>
          </>
        )}
      </ChakraScreenContainer>
    </chakra-scope>
  );
};

export default AlertRightNowReportScreen;
