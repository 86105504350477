import React, { useEffect, useState } from "react";
import { Container, Button, Col, Form, Row, Collapse } from "react-bootstrap";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Plus, Trash2 } from "react-feather";
import Select, { components, ValueType } from "react-select";
import { State, Action } from "./SettingsFilters.d";
import { CollapseChevron } from "../../UI/CollapseChevron/CollapseChevron";
import { MultiSelect } from "../Field/MultiSelect";
import { Select as SelectField } from "../Field/Select";
import { CustomField } from "../CustomFields/CustomField";
import * as CoreFields from "../../../shared/constants/coreFields";
import { getOperatorOptions } from "../../../constants/listType";
import { CustomFieldNameToID } from "../../../constants/customFieldType";
import { findNotNull } from "../../../screens/Report/Common";
import { DraggableMultiSelect } from "../Field/MultiSelectDraggable";

const initialState: State = {
  id: "defaultSettingsFilters",
  filtersAreDraggable: false,
  filters: {
    Elements: [],
    Count: 0,
  },
  addingFilter: false,
  columnFields: [],
  columnValue: [],
  orderBy: [],
  groupBy: "",
  allFields: [],
  allColumnFieldsFlattened: [],
  pageStatus: "Ready",
  readOnly: true,
  latestInstance: false,
};

/*
 *   Functions
 */
const formatToDB = (stateProps: any) => {
  let formattedArray: any[] = [];
  if (stateProps.filters.Count > 0) {
    for (const [fIndex, filter] of stateProps.filters.Elements.entries()) {
      const thisFilter = {
        ReportFilterID: filter.id,
        ReportFilterOrder: fIndex,
        ReportFilterJoiningOperator: filter.joinOperator,
        ReportFilterValueOperator: filter.valueOperator,
        ReportFilterValueDATETIME: filter.valueDATETIME,
        ReportFilterValueVARCHAR: filter.valueVARCHAR,
        ReportFilterValueINT: filter.valueINT,
        ReportFilterValueURL: filter.valueURL,
        ListValueID: filter.ListValueID,
        DocumentID: filter.DocumentID,
        ReportColumnID: filter.ReportColumnID,
        FieldID: filter.columnID,
        Level: filter.level,
      };
      formattedArray = [...formattedArray, thisFilter];
    }
    return { ...stateProps, filters: formattedArray };
  }
  return { ...stateProps, filters: [] };
};

const formatFromDB = (stateProps: any) => {
  const filterArray = stateProps.filters;
  let formattedArray: any[] = [];
  if (filterArray && filterArray.length > 0) {
    for (const filter of filterArray) {
      const thisFilter = {
        id: filter.ReportFilterID,
        readOnly: true,
        order: filter.ReportFilterOrder,
        joinOperator: filter.ReportFilterJoiningOperator,
        valueOperator: filter.ReportFilterValueOperator,
        valueDATETIME: filter.ReportFilterValueDATETIME,
        valueVARCHAR: filter.ReportFilterValueVARCHAR,
        valueINT: filter.ReportFilterValueINT,
        valueURL: filter.ReportFilterValueURL,
        ListValueID: filter.ListValueID,
        DocumentID: filter.DocumentID,
        ReportColumnID: filter.ReportColumnID,
        columnID: filter.FieldID,
        level: filter.Level,
        value: `${filter.Level}_${filter.FieldID}`,
      };

      formattedArray = [...formattedArray, thisFilter];
    }

    return { Elements: formattedArray, Count: formattedArray.length };
  }
  if (filterArray && filterArray.Elements) {
    return filterArray;
  }
  return { Elements: [], Count: 0 };
};
const reorder = (list: any, startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const Reducer: React.Reducer<State, Action> = (state, action) => {
  const filters = { ...state.filters };
  switch (action.type) {
    case "reset":
      return configureInitialState(initialState, state);
    case "addColumns":
      // strip anything in order by that is no longer in columns
      let orderBy = state.orderBy.filter((col) =>
        action.newColumns.some((newCol) => newCol.ID === col.ID),
      );
      // add or remove first order by when selecting columns
      if (orderBy.length === 0 && action.newColumns.length > 0) {
        orderBy = [{ ...action.newColumns[0], Order: 0, isFixed: false }];
      }
      if (action.newColumns.length === 0) {
        orderBy = [];
      }
      return { ...state, columnValue: action.newColumns, orderBy };
    case "reorderColumns":
      const columns: any[] = reorder(
        state.columnValue,
        action.reorder.oldIndex,
        action.reorder.newIndex,
      );
      let startIndex = action.reorder.newIndex;
      let endIndex = action.reorder.oldIndex;
      if (action.reorder.oldIndex < startIndex) {
        startIndex = action.reorder.oldIndex;
        endIndex = action.reorder.newIndex;
      }
      for (; startIndex <= endIndex; startIndex++) {
        columns[startIndex].Order = startIndex;
      }
      return { ...state, columnValue: columns };
    case "addColumnValue":
      return { ...state, columnValue: action.result };
    case "updateFilters":
      return {
        ...state,
        filters: {
          Elements: action.newFilters,
          Count: action.newFilters.length,
        },
      };
    case "addOrderBy":
      return { ...state, orderBy: action.newOrder };
    case "addGroupBy":
      return { ...state, groupBy: action.groupId };
    case "latestInstance":
      return { ...state, latestInstance: action.latestInstance };
    case "setReadOnly":
      if (state.filters.length > 0 && !action.readOnly) {
        // filters.Elements[0].readOnly = action.readOnly;
      }
      return {
        ...state,
        readOnly: action.readOnly,
        filtersAreDraggable: !action.readOnly,
        filters,
      };
    case "addFilter":
      for (const filter of filters.Elements) {
        filter.readOnly = true;
      }
      filters.Count = state.filters.Count + 1;
      const newFilter = {
        id: filters.Count,
        readOnly: false,
        dispatch: action.dispatch,
        order: filters.Count - 1,
        operator: "",
        valueOperator: "",
        valueDATETIME: "",
        valueVARCHAR: "",
        valueINT: "",
        valueURL: "",
        ListValueID: "",
        DocumentID: "",
        ReportColumnID: "",
        columnID: "",
      };
      filters.Elements = [...state.filters.Elements, newFilter];
      return { ...state, filters, addingFilter: false };
    case "changeEditToMe":
      for (const filter of filters.Elements) {
        filter.readOnly = !(filter.id === action.id);
      }
      return { ...state, filters };
    case "removeFilter":
      filters.Elements = state.filters.Elements.filter(
        (val: any) => val.id !== action.id,
      );
      filters.Count = state.filters.Count - 1;
      // initialState = {...initialState , filters: filters }
      return { ...state, filters };
    case "onDragEndFilter":
      // dropped outside the list
      if (!action.result.destination) {
        return state;
      }
      filters.Elements = reorder(
        filters.Elements,
        action.result.source.index,
        action.result.destination.index,
      );

      return { ...state, filters };
    default:
      return state;
  }
};

const fixLV = (List: any) => {
  let FinalFields: any[] = [];
  FinalFields = List.map((field: any) => {
    const newLV: any[] = [];
    if (field.ListValues) {
      field.ListValues.forEach((l: any) => {
        const fixedList = {
          ListValueID: l.ListValueID,
          ListValue:
            l.DocumentName ||
            l.DocumentID ||
            l.ListValueDATETIME ||
            l.ListValueINT ||
            l.ListValueVARCHAR ||
            l.ListValue,
        };
        newLV.push(fixedList);
      });
      return { ...field, ListValues: newLV };
    }
    return field;
  });
  return FinalFields;
};

const getColName = (id: string, cf: any) => {
  let colName = "";
  cf.forEach((field: any) => {
    if (id === field.CustomFieldID || id === field.CoreFieldID) {
      colName = field.CustomFieldTypeColumnName;
    }
  });
  return colName;
};

const isCFID = (id: string, cf: any) => {
  let check = false;
  cf.forEach((field: any) => {
    if (id === field.CustomFieldID || id === field.CoreFieldID) {
      check = true;
    }
  });
  return check;
};

const flattenFields = (fields: any) => {
  const allFields: any[] = [];
  fields.forEach((group: any) => {
    group.options.forEach((field: any) => {
      allFields.push(field);
    });
  });
  return allFields;
};

const configureInitialState = (initial: any, props: any) => {
  if (props.id) {
    initial.id = props.id;
  }
  initial.filters = formatFromDB(props);
  initial.columnFields = props.columnFields;
  initial.allFields = fixLV(props.allFields);
  initial.pageStatus = props.pageStatus;
  initial.columnValue = props.columnValue.sort(
    (a: any, b: any) => a.Order - b.Order,
  );
  initial.orderBy = props.orderBy;
  initial.groupBy = props.groupBy;
  initial.readOnly = props.readOnly;
  initial.latestInstance = props.latestInstance;
  initial.allColumnFieldsFlattened = flattenFields(props.columnFields);

  return initial;
};

const getTheField = (id: string, fields: any, value?: any) => {
  const field = fields.filter(
    (f: any) => f.CustomFieldID === id || f.CoreFieldID === id,
  );
  if (field.length > 0) {
    const checkIfBillable =
      CoreFields.Requirement.TimeNoteBillable === field[0].CoreFieldID ||
      CoreFields.Issue.TimeNoteBillable === field[0].CoreFieldID ||
      CoreFields.Risk.TimeNoteBillable === field[0].CoreFieldID ||
      CoreFields.Job.TimeNoteBillable === field[0].CoreFieldID ||
      CoreFields.Action.TimeNoteBillable === field[0].CoreFieldID ||
      CoreFields.Register.TimeNoteBillable === field[0].CoreFieldID;
    const newCf = {
      CustomFieldID: field[0].CustomFieldID || field[0].CoreFieldID,
      CustomFieldTypeColumnName: checkIfBillable
        ? "ListValueID"
        : field[0].CustomFieldTypeColumnName === "LONGVARCHAR"
        ? "VARCHAR"
        : field[0].CustomFieldTypeColumnName,
      ValueID: "",
      Label: field[0].CustomFieldLabel || field[0].CoreFieldLabel,
      ListValues: checkIfBillable
        ? [
            { ListValueID: 1, ListValue: "Billable" },
            { ListValueID: 0, ListValue: "Nonbillable" },
          ]
        : field[0].ListValues,
      Value: value !== null && value !== undefined ? value : "",
    };
    return newCf;
  }
  return field;
};

const getOperatorList = (fieldID: any, allFields: any) => {
  // equal to, less than, less than equal to, greater than, greater than equal to, contains, not equal to, exists
  // special case for TimeNotebillable field, curently it is an int value but we want it to be treated as a list and get its operators lists
  // we check for all object type groups Requirement,    Issue,    Risk,    Job,    Action,    Register
  const checkIfBillable =
    CoreFields.Requirement.TimeNoteBillable === fieldID ||
    CoreFields.Issue.TimeNoteBillable === fieldID ||
    CoreFields.Risk.TimeNoteBillable === fieldID ||
    CoreFields.Job.TimeNoteBillable === fieldID ||
    CoreFields.Action.TimeNoteBillable === fieldID ||
    CoreFields.Register.TimeNoteBillable === fieldID;
  const columnName = checkIfBillable
    ? "ListValueID"
    : getColName(fieldID, allFields);
  const operatorList: any = getOperatorOptions(columnName);

  return operatorList;
};

const SettingsFilters = (props: any) => {
  const [state, dispatch] = React.useReducer<React.Reducer<State, Action>>(
    Reducer,
    configureInitialState(initialState, props),
  );
  const [isDetailsCollapsedNew, updateDetailsCollapsedNew] =
    useState<boolean>(false);

  useEffect(() => {
    props.handleChange(formatToDB(state));
  }, [state]); // eslint-disable-line

  useEffect(() => {
    dispatch({ type: "setReadOnly", readOnly: props.readOnly });
  }, [dispatch, props.readOnly]);

  useEffect(() => {
    dispatch({ type: "reset" });
  }, [props.reset]);

  useEffect(() => {
    dispatch({ type: "addColumnValue", result: props.columnValue });
  }, [dispatch, props.columnValue]);

  return (
    <Container style={{ marginTop: "40px" }}>
      <Row>
        <Col>
          <h1>Settings & Filters</h1>
        </Col>
        <Col sm="auto">
          <CollapseChevron
            collapsed={isDetailsCollapsedNew}
            updateCollapsed={updateDetailsCollapsedNew}
          />
        </Col>
      </Row>
      <Collapse in={!isDetailsCollapsedNew}>
        <div>
          <hr />
          {props.columnFields.length > 0 ? (
            <Container>
              <Form.Group as={Row}>
                <Form.Label column sm="2">
                  Columns
                </Form.Label>
                <Col sm="9">
                  <DraggableMultiSelect
                    name="ColumnsID"
                    options={props.columnFields}
                    value={state.columnValue}
                    onUpdate={(e: any, { action }) => {
                      const allOptions = state.allColumnFieldsFlattened;
                      if (action === "clear") {
                        dispatch({
                          type: "addColumns",
                          newColumns: allOptions.filter((v: any) => v.isFixed),
                          dispatch,
                        });
                      } else if (action === "reorder") {
                        const draggedCol = state.columnValue[e.oldIndex];
                        const colDetails = allOptions.find(
                          (opt: any) => opt.value === draggedCol.value,
                        );
                        if (colDetails) {
                          dispatch({
                            type: "reorderColumns",
                            reorder: {
                              list: e.list,
                              oldIndex: e.oldIndex,
                              newIndex: e.newIndex,
                            },
                          });
                        }
                      } else if (e !== null) {
                        let newColumn;
                        if (e.length > 0) {
                          newColumn = e.map((element: any, index: number) => ({
                            ...element,
                            Order: index,
                          }));
                        } else {
                          newColumn = allOptions.filter((v: any) => v.isFixed);
                        }
                        dispatch({
                          type: "addColumns",
                          newColumns: newColumn,
                          dispatch,
                        });
                      } else {
                        const newColumn: any[] = allOptions.filter(
                          (v: any) => v.isFixed,
                        );
                        dispatch({
                          type: "addColumns",
                          newColumns: newColumn,
                          dispatch,
                        });
                      }
                    }}
                    placeholder="Select a Value"
                    disabled={false}
                    readOnly={props.readOnly}
                    clearable={props.columnFields.some((group: any) =>
                      group.options.some((v: any) => !v.isFixed),
                    )}
                  />
                </Col>
              </Form.Group>
              {OrderBySelector(state, dispatch)}
              {GroupBySelector(state, dispatch)}
              {LatestInstanceCheck(state, dispatch)}
              {state.filters.Count > 0 ? (
                <Form.Group as={Row}>
                  <Form.Label column sm="2">
                    Filters
                  </Form.Label>
                  <Col sm="10">
                    <DragDropContext
                      onDragEnd={(result) =>
                        dispatch({ type: "onDragEndFilter", result })
                      }>
                      <Droppable droppableId="droppable">
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.droppableProps}>
                            {FiltersDragable(state, dispatch)}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </Col>
                </Form.Group>
              ) : null}
              {addFilterButton(state, dispatch)}
            </Container>
          ) : null}
        </div>
      </Collapse>
    </Container>
  );
};

const LatestInstanceCheck = (props: any, dispatch: any) => {
  if (props.columnValue.length > 0) {
    // this checkbox should only be shown if the following columns have been selected: “Instance Due Date” or “Instance Response” or “Instance Response Date”
    return (
      <Form.Group as={Row}>
        <Form.Label column sm="2">
          Restrict to latest task only
        </Form.Label>
        <Col sm="9">
          <input
            type="checkbox"
            style={{ width: "25px", height: "25px", margin: "7px 0px" }}
            id="latestInstanceCheck"
            checked={props.latestInstance}
            disabled={props.readOnly}
            onChange={(event) => {
              dispatch({
                type: "latestInstance",
                latestInstance: event.target.checked,
              });
            }}
          />
        </Col>
      </Form.Group>
    );
  }
  return null;
};

const OrderBySelector = (props: any, dispatch: any) => {
  if (props.columnValue.length > 0) {
    return (
      <Form.Group as={Row}>
        <Form.Label column sm="2">
          Order By
        </Form.Label>
        <Col sm="9">
          <MultiSelect
            onUpdate={(e: any) => {
              const allOptions = props.allColumnFieldsFlattened;
              if (e !== null) {
                const newColumn = e.map((element: any, index: number) => {
                  const option = allOptions.find(
                    (opt: any) => opt.value === element.value,
                  );
                  const column = {
                    ID: option.ID,
                    Name: option.Name,
                    Type: option.Type,
                    Order: index,
                    Level: option.Level,
                    isFixed: false,
                    value: element.value,
                    label: element.label,
                  };
                  return column;
                });
                dispatch({ type: "addOrderBy", newOrder: newColumn, dispatch });
              } else {
                const newColumn: any[] = [];
                dispatch({ type: "addOrderBy", newOrder: newColumn, dispatch });
              }
            }}
            name="OrdersID"
            value={props.orderBy}
            unselectedOption="Select a Value"
            readOnly={props.readOnly}
            disabled={false}
            optionsListID="ID"
            optionsListValue="label"
            optionsList={props.columnValue
              .filter((c: any) => c.CustomFieldTypeColumnName !== "LONGVARCHAR")
              .map((c: any) => ({ ...c, isFixed: false }))}
            optionsChild={false}
            readOnlyComponent={props.orderBy}
            display={props.display}
            visibility
            reportSettings
          />
        </Col>
      </Form.Group>
    );
  }
  return null;
};

const GroupBySelector = (props: any, dispatch: any) => {
  if (props.columnValue.length > 0) {
    const selected = props.columnValue.find(
      (col: any) => col.value === props.groupBy,
    );
    return (
      <Form.Group as={Row}>
        <Form.Label column sm="2">
          Group By
        </Form.Label>
        <Col sm="9">
          <SelectField
            onUpdate={(e: any) => {
              dispatch({ type: "addGroupBy", groupId: e.value, dispatch });
            }}
            name="GroupByID"
            value={props.groupBy}
            unselectedOption="Select a Value"
            optionsList={props.columnValue.filter(
              (c: any) => c.CustomFieldTypeColumnName !== "LONGVARCHAR",
            )}
            optionsChild={(listItem: any) => ({
              value: listItem.value,
              label: listItem.label,
            })}
            readOnly={props.readOnly}
            readOnlyValue={selected ? selected.label : ""}
          />
        </Col>
      </Form.Group>
    );
  }
  return null;
};

const addFilterButton = (props: any, dispatch: any) => {
  if (props.columnValue.length > 0 && !props.readOnly) {
    return (
      <Form.Group as={Row}>
        <Form.Label column sm="2" />
        <Col sm="9">
          <Button
            variant="dark"
            onClick={() => dispatch({ type: "addFilter", dispatch })}>
            <Plus className="feather" />
            Add a Filter
          </Button>
        </Col>
      </Form.Group>
    );
  }
  return null;
};

const FiltersDragable = (props: any, dispatch: any) => {
  if (props.columnValue.length > 0 && props.filters.Count > 0) {
    return props.filters.Elements.map((f: any, i: number) => {
      const selected = props.columnValue.find(
        (c: any) =>
          c.ID === f.columnID && c.Level.toString() === f.level.toString(),
      );
      if (props.filtersAreDraggable || !props.readOnly) {
        return (
          <div key={`FilterWrapDrag${f.id}`}>
            <Draggable key={f.id} draggableId={f.id} index={i}>
              {(provided, snapshot) => (
                <div
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  ref={provided.innerRef}
                  key={`Filter${i.toString()}`}
                  style={getFilterStyle(
                    snapshot.isDragging,
                    provided.draggableProps.style,
                    f.readOnly,
                  )}
                  onClick={(event) =>
                    dispatch({ type: "changeEditToMe", id: f.id })
                  }>
                  <Container>
                    {i > 0 ? (
                      <Form.Group as={Row}>
                        <Col sm="3">
                          <SelectField
                            onUpdate={(e: any) => {
                              f.joinOperator = e.value;
                            }}
                            name={`JoinOp${i}`}
                            value={f.joinOperator}
                            unselectedOption="Select a Value"
                            optionsList={[
                              { ID: "and", Name: "and" },
                              { ID: "or", Name: "or" },
                            ]}
                            optionsChild={(listItem: any) => ({
                              value: listItem.ID,
                              label: listItem.Name,
                            })}
                            readOnly={props.readOnly}
                            readOnlyValue={f.joinOperator}
                          />
                        </Col>
                      </Form.Group>
                    ) : null}
                    <Row>
                      <Col sm="4">
                        <SelectField
                          onUpdate={(e: any) => {
                            const identifiers = e.value.split("_");
                            f.value = e.value;
                            f.columnID = identifiers[1];
                            f.level = identifiers[0];
                            f.valueVARCHAR = "";
                            f.valueINT = "";
                            f.valueURL = "";
                            f.ListValueID = "";
                            f.DocumentID = "";
                            f.valueDATETIME = "";
                          }}
                          name={`Filter${i}`}
                          value={f.value}
                          unselectedOption="Select a Value"
                          optionsList={props.columnValue}
                          optionsChild={(listItem: any) => ({
                            value: listItem.value,
                            label: listItem.label,
                          })}
                          readOnly={props.readOnly}
                          readOnlyValue={selected ? selected.label : ""}
                        />
                      </Col>
                      <Col sm="3">
                        <SelectField
                          onUpdate={(e: any) => {
                            f.valueOperator = e.value;
                          }}
                          name={`Filter${i}`}
                          value={f.valueOperator}
                          unselectedOption="Select a Value"
                          optionsList={getOperatorList(
                            f.columnID,
                            props.allFields,
                          )}
                          optionsChild={(listItem: any) => ({
                            value: listItem.ID,
                            label: listItem.Name,
                          })}
                          readOnly={props.readOnly}
                          readOnlyValue={f.valueOperator}
                        />
                      </Col>
                      {f.valueOperator !== "exists" ? (
                        f.columnID !== "" ? (
                          <CustomField
                            key={i}
                            customField={getTheField(
                              f.columnID,
                              props.allFields,
                              findNotNull([
                                f.ListValueID,
                                f.valueVARCHAR,
                                f.valueINT,
                                f.valueDATETIME,
                                f.valueURL,
                                f.DocumentID,
                              ]),
                            )}
                            onUpdate={(event: any, a: any) => {
                              // change the value of the cf
                              const newFilters = props.filters.Elements.map(
                                (filter: any) => {
                                  if (
                                    event.target !== undefined &&
                                    isCFID(
                                      event.target.name,
                                      props.allFields,
                                    ) &&
                                    event.target.questionID === filter.id
                                  ) {
                                    const columnName = getColName(
                                      event.target.name,
                                      props.allFields,
                                    );
                                    if (columnName === "ListValueID") {
                                      return {
                                        ...filter,
                                        valueDATETIME: "",
                                        valueVARCHAR: "",
                                        valueINT: "",
                                        valueURL: "",
                                        DocumentID: "",
                                        ListValueID: event.target.value,
                                      };
                                    }
                                    if (
                                      columnName === "VARCHAR" ||
                                      columnName === "LONGVARCHAR"
                                    ) {
                                      return {
                                        ...filter,
                                        valueDATETIME: "",
                                        valueINT: "",
                                        valueURL: "",
                                        ListValueID: "",
                                        DocumentID: "",
                                        valueVARCHAR: event.target.value,
                                      };
                                    }
                                    if (
                                      columnName === "INT" ||
                                      f.valueOperator.includes("relative")
                                    ) {
                                      return {
                                        ...filter,
                                        valueDATETIME: "",
                                        valueVARCHAR: "",
                                        valueURL: "",
                                        ListValueID: "",
                                        DocumentID: "",
                                        valueINT: event.target.value,
                                      };
                                    }
                                    if (columnName === "DATETIME") {
                                      return {
                                        ...filter,
                                        valueVARCHAR: "",
                                        valueINT: "",
                                        valueURL: "",
                                        ListValueID: "",
                                        DocumentID: "",
                                        valueDATETIME: event.target.value,
                                      };
                                    }
                                    if (columnName === "URL") {
                                      return {
                                        ...filter,
                                        valueDATETIME: "",
                                        valueVARCHAR: "",
                                        valueINT: "",
                                        ListValueID: "",
                                        DocumentID: "",
                                        valueURL: event.target.value,
                                      };
                                    }
                                    if (columnName === "DocumentID") {
                                      return {
                                        ...filter,
                                        valueDATETIME: "",
                                        valueVARCHAR: "",
                                        valueINT: "",
                                        valueURL: "",
                                        ListValueID: "",
                                        DocumentID: event.target.value,
                                      };
                                    }
                                  }
                                  return filter;
                                },
                              );
                              dispatch({
                                type: "updateFilters",
                                newFilters,
                                dispatch,
                              });
                            }}
                            report
                            disabled={props.disabled}
                            formError={props.formError}
                            pageStatus={
                              !props.readOnly && props.pageStatus === "Ready"
                                ? "Editing"
                                : props.pageStatus
                            } // copy mode does not have a page status anymore so it made lists readOnly
                            questionid={f.id}
                            operator={f.valueOperator}
                          />
                        ) : null
                      ) : null}

                      <Col sm="auto">
                        <button
                          style={{
                            backgroundColor: "#ffffff00",
                            border: "none",
                            marginTop: "10px",
                            marginRight: "10px",
                          }}
                          onClick={() =>
                            dispatch({ type: "removeFilter", id: f.id })
                          }>
                          <Trash2
                            style={{ color: "#d8d8d8" }}
                            className="feather"
                          />
                        </button>
                      </Col>
                    </Row>
                  </Container>
                </div>
              )}
            </Draggable>
          </div>
        );
      }
      // Readonly:
      return (
        <Container key={f.id}>
          {i > 0 ? (
            <Form.Group as={Row}>
              <Col sm="3">
                <Form.Control
                  type="text"
                  value={f.joinOperator}
                  readOnly
                  plaintext
                />
              </Col>
            </Form.Group>
          ) : null}
          <Row>
            <Col sm="4">{selected ? selected.label : ""}</Col>
            <Col sm="3">
              {getOperatorList(f.columnID, props.allFields).find(
                (item: any) => item.ID === f.valueOperator,
              )?.Name ?? f.valueOperator}
            </Col>
            {f.valueOperator !== "exists" ? (
              f.valueOperator === "relative days - after" ||
              f.valueOperator === "relative days - before" ? (
                <>
                  {f.valueINT === 0 ? "today" : f.valueINT < 0 ? `${Math.abs(f.valueINT)} days in the past` : `${f.valueINT} days in the future`}
                </>
              ) : f.columnID !== "" ? (
                <CustomField
                  key={i}
                  customField={getTheField(
                    f.columnID,
                    props.allFields,
                    findNotNull([
                      f.ListValueID,
                      f.valueVARCHAR,
                      f.valueINT,
                      f.valueDATETIME,
                      f.valueURL,
                      f.DocumentID,
                    ]),
                  )}
                  onUpdate={(event: any, a: any) => {}}
                  report
                  disabled={props.disabled}
                  formError={props.formError}
                  pageStatus={props.pageStatus}
                  questionid={f.id}
                  operator={f.valueOperator}
                />
              ) : null
            ) : null}
          </Row>
        </Container>
      );
    });
  }
  return null;
};
const getFilterStyle = (
  isDragging: boolean,
  draggableStyle: any,
  readOnly: boolean,
) => {
  if (readOnly) {
    return {
      // background: isDragging ? "lightgreen" : "white",
      width: "100%",
      boxShadow: isDragging ? "0 3px 10px 0 rgba(0, 0, 0, 0.17)" : "",
      background: "white",

      // styles we need to apply on draggables
      ...draggableStyle,
    };
  }
  return {
    // background: isDragging ? "lightgreen" : "white",
    width: "100%",
    padding: "30px 5px 30px 5px",
    boxShadow: "0 3px 10px 0 rgba(0, 0, 0, 0.17)",
    backgroundColor: "#ffffff",

    // styles we need to apply on draggables
    ...draggableStyle,
  };
};
// addFilterButton(props,dispatch)
export { SettingsFilters };
