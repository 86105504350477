import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Collapse,
  DropdownButton,
  Form,
  OverlayTrigger,
  Row,
  Tooltip,
  Table,
} from "react-bootstrap";
import { CSVLink } from "react-csv";
import * as Feather from "react-feather";
import { Plus, Trash2 } from "react-feather";
import { Link } from "react-router-dom";
import { getOperatorOptions } from "../../../constants/listType";
import { put } from "../../../utilities/request";
import { CustomField } from "../../Forms/CustomFields/CustomField";
import { DateField } from "../../Forms/Field/Date";
import { Select } from "../../Forms/Field/Select";
import { Text } from "../../Forms/Field/Text";
import { Textarea } from "../../Forms/Field/Textarea";
import { text_truncate } from "../../../utilities/strings";
import { CollapseChevron } from "../CollapseChevron/CollapseChevron";
import { Dialog } from "../Dialog/Dialog";
import { Action, SearchFiltersState, State } from "./AdvancedSearch.d";
import { useAppState } from "../../App/AppProvider";
import useToast from "../../../utilities/useToast";

const rEmail =
  /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i;
const RelatedRecordTable: any = (props: any) => {
  const IconType: keyof typeof Feather = props.objType[0].ObjectTypeIcon;
  const Icon = Feather[IconType];
  return (
    <div style={{ paddingBottom: "10px" }}>
      <div style={{ backgroundColor: "#f7f7f7" }}>
        <div style={{ padding: "20px" }}>
          <div style={{ fontSize: "16px", paddingBottom: "10px" }}>
            <Icon />{" "}
            {`${props.objType[0].ObjectTypeName} (${props.objType.length})`}
          </div>
          <Table
            responsive
            borderless
            key={props.record.RequirementID}
            title={props.objType[0].ObjectTypeName}>
            <thead>
              <tr>
                {props.objType[0].RegisterView.map((rec: any, index: number) =>
                  rec.colName === "ID" ? (
                    <th key={index} style={{ width: "124px" }}>
                      {rec.colName}
                    </th>
                  ) : (
                    <th key={index}>{rec.colName}</th>
                  ),
                )}
              </tr>
            </thead>
            <tbody>
              {props.objType.map((rec: any, index: number) => (
                <tr key={index}>
                  {rec.RegisterView.map((col: any, index: number) => {
                    if (col.colLink && Array.isArray(col.colValue)) {
                      return (
                        <td key={index}>
                          {col.colValue.map((v: any, index: number) => (
                            <Link key={index} to={col.colLink}>
                              {col.colValue}
                              {index === col.colValue.length - 1 ? "" : ", "}
                            </Link>
                          ))}
                        </td>
                      );
                    }
                    if (col.colLink) {
                      return (
                        <td key={index}>
                          <Link to={col.colLink}>{col.colValue}</Link>
                        </td>
                      );
                    }
                    return col.colValue !== null && col.colValue.length > 25 ? (
                      <OverlayTrigger
                        key={index}
                        placement="right"
                        overlay={
                          <Tooltip id={`RegisterView${index}`}>
                            <Textarea
                              value={col.colValue}
                              name={`RegisterView${index}`}
                              readOnly
                              richText
                            />
                          </Tooltip>
                        }>
                        <td>
                          <Textarea
                            value={text_truncate(col.colValue, 25)}
                            name={`RegisterView${index}`}
                            readOnly
                            richText
                          />
                        </td>
                      </OverlayTrigger>
                    ) : (
                      <td key={index}>
                        <Textarea
                          value={col.colValue === null ? "" : col.colValue}
                          name={`RegisterView${index}`}
                          readOnly
                          richText
                        />
                      </td>
                    );
                  })}
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
};

const RowData: any = (props: any) => {
  const { app, auth } = useAppState();
  const [isCollapsed, setCollapsed] = React.useState<boolean>(true);

  return (
    <>
      <Row style={{ padding: "12px", paddingLeft: "15px" }}>
        {props.record.RegisterView.RegisterView.map(
          (col: any, index: number) => {
            if (col.colLink && Array.isArray(col.colValue)) {
              return (
                <Col key={index}>
                  {col.colValue.map((v: any, index: number) => {
                    const extraProps =
                      col.CustomFieldTypeColumnName === "DocumentID" ||
                      col.CustomFieldTypeColumnName === "OneDriveDocumentID"
                        ? {
                            onClick: (event: any) => {
                              event.preventDefault();
                              window.open(col.colLink[index]);
                            },
                            target: "_blank",
                          }
                        : {};
                    return (
                      <Col key={index}>
                        <Link to={col.colLink[index]} {...extraProps}>
                          {v}
                          {index === col.colValue.length - 1 ? "" : ","}
                        </Link>
                      </Col>
                    );
                  })}
                </Col>
              );
            }
            if (col.colLink) {
              return (
                <Col key={index} sm="1" style={{ minWidth: "100px" }}>
                  <Link to={col.colLink}>{col.colValue}</Link>
                </Col>
              );
            }
            if (col.CustomFieldTypeColumnName === "DATETIME") {
              return (
                <Col key={index}>
                  <DateField
                    readOnly
                    allDates
                    selected={col.colValue}
                    authState={auth}
                    name={`RegisterView${index}`}
                    onUpdate={() => {}}
                  />
                </Col>
              );
            }
            return col.colValue && col.colValue.length > 25 ? (
              <OverlayTrigger
                key={index}
                placement="top"
                overlay={
                  <Tooltip id={`RegisterView${index}`}>
                    <Textarea
                      value={col.colValue}
                      name={`RegisterView${index}`}
                      readOnly
                      richText
                    />
                  </Tooltip>
                }>
                <Col>
                  <Textarea
                    value={text_truncate(col.colValue, 25)}
                    name={`RegisterView${index}`}
                    readOnly
                    richText
                  />
                </Col>
              </OverlayTrigger>
            ) : (
              <Col key={index}>
                <Textarea
                  value={col.colValue}
                  name={`RegisterView${index}`}
                  readOnly
                  richText
                />
              </Col>
            );
          },
        )}
        <Col sm="1">
          <CollapseChevron
            collapsed={isCollapsed}
            updateCollapsed={setCollapsed}
          />
        </Col>
      </Row>
      <Collapse in={!isCollapsed}>
        {props.record.RelatedRecords.length > 0 ? (
          <div>
            {props.record.RelatedRecords.map((objType: any, index: number) => (
              <RelatedRecordTable
                key={index}
                objType={objType}
                record={props.record}
              />
            ))}
          </div>
        ) : (
          <div style={{ padding: "20px" }}>No related records</div>
        )}
      </Collapse>
    </>
  );
};

const SearchResult: any = (props: any) => {
  if (props.searchViewData.length > 0) {
    return (
      <>
        <Row style={{ padding: "12px", paddingLeft: "15px" }}>
          {props.searchViewData[0].RegisterView.RegisterView.map(
            (col: any, index: number) =>
              col.colName === "ID" ? (
                <Col
                  key={index}
                  style={{ minWidth: "100px" }}
                  className="header-row-register-Dashboard "
                  sm="1">
                  {col.colName}
                </Col>
              ) : (
                <Col key={index} className="header-row-register-Dashboard ">
                  {col.colName}
                </Col>
              ),
          )}
          <Col sm="1" className="header-row-register-Dashboard " />
        </Row>
        {props.searchViewData.map((rec: any, index: number) => (
          <RowData key={index} record={rec} />
        ))}
      </>
    );
  }
  return "No records";
};

const timezone = moment.tz.guess();

const initialState: State = {
  id: "defaultSearch",
  filters: [],
  addingFilter: false,
  searchData: [],
  searchText: "",
  pageStatus: "Ready",
  allData: [],
  availableFilters: [],
  typeID: "",
};

const passesCondition = (
  fieldValue: any,
  operator: string,
  filterValue: any,
) => {
  if (operator === "equal to") {
    return fieldValue === filterValue;
  }
  if (operator === "less than") {
    return fieldValue < filterValue;
  }
  if (operator === "less than equal to") {
    return fieldValue <= filterValue;
  }
  if (operator === "greater than") {
    return fieldValue > filterValue;
  }
  if (operator === "greater than equal to") {
    return fieldValue >= filterValue;
  }
  if (operator === "contains") {
    return fieldValue.includes(filterValue);
  }
  if (operator === "not equal to") {
    return fieldValue !== filterValue;
  }
};

const getColumn = (fieldName: string, availableFilters: any) =>
  availableFilters.find((f: any) => f.colName === fieldName);

const Reducer: React.Reducer<State, Action> = (state, action) => {
  switch (action.type) {
    case "setData":
      return { ...state, searchData: action.newData };
    case "configureState":
      return action.state;
    case "setSearchText":
      if (action.newText !== "") {
        action.updateDetailsCollapsed(false);
      }
      return { ...state, searchText: action.newText };
    case "addFilter":
      const emptyFilter = {
        ID: "",
        ColumnName: "",
        ColumnTypeID: "",
        Value: "",
        Operator: "",
        ListValues: [],
      };
      return { ...state, filters: state.filters.concat(emptyFilter) };
    case "clear":
      return {
        ...state,
        referenceID: "",
        searchText: "",
        filters: [],
      };
    case "setFilterSearch":
      const changedFilters = state.filters;
      if (action.id > -1) {
        if (action.newFilter.ListValues.length > 0) {
          // remove duplicate values
          action.newFilter.ListValues = action.newFilter.ListValues.filter(
            (thing: any, index: any, self: any[]) =>
              self.findIndex(
                (t: any) =>
                  t.ListValueID === thing.ListValueID &&
                  t.ListValue === thing.ListValue,
              ) === index,
          );
        }
        changedFilters[action.id] = action.newFilter;
      }
      return { ...state, filters: changedFilters };
    case "removeFilter":
      const newFilters = state.filters;
      if (action.id > -1) {
        newFilters.splice(action.id, 1);
      }
      return { ...state, filters: newFilters };
    case "updateFilters":
      // here a check according to all filters is needed
      // note multiple filters are equal to AND conditions
      return { ...state, filters: action.newFilters };
    default:
      return state;
  }
};

/* Filters */
const stringFilter = (list: any[], text: string) =>
  list.filter((item: any) => checkContent(item, text));

const applyFilters = (list: any[], filters: any[]) => {
  if (filters.length > 0) {
    return list.filter((item: any) => checkFilters(item, filters));
  }
  return list;
};

const checkFilters = (item: any, filters: any[]) =>
  filters.every((filter: any) => {
    if (filter.ID === "Relationship") {
      //check its related records
      return filter.Operator === "exists"
        ? item.RelatedRecords.length > 0
        : Array.isArray(filter.Value)
        ? filter.Value.every((v: any) => {
            let check = false;
            item.RelatedRecords.forEach((recordType: any) => {
              recordType.forEach((record: any) => {
                record.RegisterView.forEach((content: any) => {
                  if (
                    content.colValue &&
                    content.colValue.toLowerCase().indexOf(v.toLowerCase()) !==
                      -1
                  ) {
                    check = true;
                  }
                });
              });
            });
            return check;
          })
        : item.RelatedRecords.some((recordType: any) =>
            recordType.some((record: any) =>
              record.RegisterView.some(
                (content: any) =>
                  content.colValue &&
                  String(content.colValue)
                    .toLowerCase()
                    .includes(String(filter.Value).toLowerCase()),
              ),
            ),
          );
    }
    const col = item.RegisterView.RegisterView.find(
      (c: any) => c.colName === filter.ID,
    );
    let value = filter.Value;
    if (col && col.colValue) {
      if (filter.Operator === "exists" && Array.isArray(col.colValue)) {
        // parents
        return col.colValue.length > 0;
      }
      if (filter.Operator === "not equal to" && Array.isArray(col.colValue)) {
        return !col.colValue.includes(value);
      }
      if (filter.Operator === "contains" && Array.isArray(col.colValue)) {
        return (
          col.colValue.toLowerCase().includes(value.toLowerCase()) ||
          col.colValue.some((v: any) =>
            v.toLowerCase().includes(value.toLowerCase()),
          )
        );
      }
      if (filter.Operator === "exists") {
        return col.colValue !== "";
      }
      if (filter.ColumnName === "DATETIME") {
        const colValue = moment(col.colValue, "DD-MMM-YYYY 00:00:00.000")
          .tz(timezone)
          .utc()
          .format("YYYY-MM-DDTHH:mm:ss+00:00");
        return passesCondition(colValue, filter.Operator, value);
      }
      if (filter.ColumnName === "ListValueID") {
        const listValue = filter.ListValues.find(
          (listVal: any) => listVal.ListValueID === filter.Value,
        );
        value = listValue ? listValue.ListValue : value;
        return passesCondition(
          typeof col.colValue === "string"
            ? col.colValue.toLowerCase()
            : col.colValue,
          filter.Operator,
          typeof value === "string" ? value.toLowerCase() : value,
        );
      }
      return passesCondition(
        typeof col.colValue === "string"
          ? col.colValue.toLowerCase()
          : col.colValue,
        filter.Operator,
        typeof value === "string" ? value.toLowerCase() : value,
      );
    }
    if (filter.Operator === "not equal to") {
      // records with no colValue still need to go through this test
      return passesCondition(
        typeof col.colValue === "string"
          ? col.colValue.toLowerCase()
          : col.colValue,
        filter.Operator,
        typeof value === "string" ? value.toLowerCase() : value,
      );
    }
    if (filter.Operator === "exists") {
      return col !== undefined;
    } else {
      return false; //it is not within the desired filters so we remove it
    }
  });

const checkContent = (item: any, text: string) => {
  if (text !== "") {
    // search the main item
    let check = false;
    item.RegisterView.RegisterView.forEach((col: any) => {
      if (
        col.colValue &&
        String(col.colValue)
          .toLowerCase()
          .indexOf(String(text).toLowerCase()) !== -1
      ) {
        check = true;
      }
    });
    if (check) {
      return true;
    }
    // check its related records
    item.RelatedRecords.forEach((recordType: any) => {
      recordType.forEach((record: any) => {
        record.RegisterView.forEach((content: any) => {
          if (
            content.colValue &&
            String(content.colValue)
              .toLowerCase()
              .indexOf(String(text).toLowerCase()) !== -1
          ) {
            check = true;
          }
        });
      });
    });
    return check;
  }
  return true;
};

const pageFilter = (filters: SearchFiltersState, index: number) =>
  index < filters.page * filters.pageSize &&
  index >= (filters.page - 1) * filters.pageSize;

const configureInitialState = (initial: any, props: any) => {
  if (props.id) {
    initial.id = props.id;
  }
  initial.searchData = props.data;
  initial.allData = props.data;
  initial.availableFilters = props.availableFilters;
  initial.typeID = props.typeID;
  return JSON.parse(JSON.stringify(initial));
};

const AdvancedSearch = (props: any) => {
  const { app, auth } = useAppState();
  const [state, dispatch] = React.useReducer<React.Reducer<State, Action>>(
    Reducer,
    configureInitialState(initialState, props),
  );
  const { displayToast } = useToast();
  const [filters, setFilters] = React.useState<SearchFiltersState>({
    page: 1,
    pageSize: 10,
  });
  const [dialogVisible, setDialogVisible] = useState(false);

  const filteredData = applyFilters(
    stringFilter(state.allData, state.searchText),
    state.filters.filter(
      (filter: any) =>
        filter.ID !== "" &&
        (filter.Value !== "" || filter.Operator === "exists"),
    ),
  );

  useEffect(() => {
    dispatch({
      type: "configureState",
      state: configureInitialState(state, props),
    });
  }, [props.data]);

  React.useEffect(() => {
    dispatch({ type: "clear" });
  }, [props.urlSearch]);

  return (
    <>
      <Row style={{ marginBottom: "10px", color: "#374750" }}>
        <Col sm="auto">
          <h1>Search</h1>
        </Col>
        <Col sm="9">
          <Text
            disabled={props.data.length === 0}
            placeHolder={
              props.data.length === 0
                ? "No records available"
                : props.searchText
            }
            value={state.searchText}
            name="searchText"
            onUpdate={(event: any) =>
              dispatch({
                type: "setSearchText",
                newText: event.target.value,
                updateDetailsCollapsed: props.updateDetailsCollapsed,
              })
            }
            readOnly={false}
          />
        </Col>
        <Col sm="auto">
          <CollapseChevron
            collapsed={props.isCollapsed}
            updateCollapsed={props.updateDetailsCollapsed}
          />
        </Col>
      </Row>

      <Collapse in={!props.isCollapsed}>
        <div>
          {/* adding the filters here */}
          {state.filters.length > 0
            ? state.filters.map((filter: any, index: number) => (
                <Row key={index} style={{ padding: "10px" }}>
                  <Col sm="4">
                    <Select
                      onUpdate={(e: any) => {
                        dispatch({
                          type: "setFilterSearch",
                          id: index,
                          newFilter: {
                            ID: e.value,
                            ColumnName: getColumn(
                              e.value,
                              state.availableFilters,
                            ).CustomFieldTypeColumnName,
                            ColumnTypeID: getColumn(
                              e.value,
                              state.availableFilters,
                            ).CustomFieldTypeID,
                            Value: "",
                            Operator: "",
                            ListValues:
                              getColumn(e.value, state.availableFilters)
                                .CustomFieldTypeColumnName === "ListValueID"
                                ? getColumn(e.value, state.availableFilters)
                                    .ListValues
                                : [],
                          },
                        });
                      }}
                      name={`filterOptions${index}`}
                      value={filter.ID}
                      readOnly={false}
                      unselectedOption="Select a Value"
                      optionsList={state.availableFilters}
                      optionsListID="colName"
                      optionsListValue="colName"
                    />
                  </Col>

                  {/** add operator */}
                  <Col sm="3">
                    <Select
                      onUpdate={(e: any) => {
                        if (e.value === "exists") {
                          const { filters } = state;
                          filters[index] = {
                            ...filter,
                            Operator: e.value,
                          };
                          dispatch({
                            type: "updateFilters",
                            newFilters: filters,
                          });
                        } else {
                          dispatch({
                            type: "setFilterSearch",
                            id: index,
                            newFilter: {
                              ...filter,
                              Operator: e.value,
                            },
                          });
                          filter.Operator = e.value;
                        }
                      }}
                      name={`Operator${index}`}
                      value={filter.Operator}
                      unselectedOption="Select a Value"
                      optionsList={
                        filter.ColumnName !== ""
                          ? getOperatorOptions(filter.ColumnName)
                          : []
                      }
                      optionsChild={(listItem: any) => ({
                        value: listItem.ID,
                        label: listItem.Name,
                      })}
                      readOnly={props.readOnly}
                      readOnlyValue={filter.Operator}
                    />
                  </Col>

                  {filter.Operator !== "exists" ? (
                    filter.ID !== "" ? (
                      <CustomField
                        key={index}
                        customField={{
                          CustomFieldID: filter.ID,
                          CustomFieldTypeID: filter.ColumnTypeID,
                          CustomFieldTypeColumnName: filter.ColumnName,
                          Value: filter.Value,
                          ListValues: filter.ListValues,
                          Unit: "",
                          HelpText: "",
                        }}
                        onUpdate={(event: any) => {
                          // change the value of the cf
                          const newFilters = state.filters.map(
                            (filter: any) => {
                              if (
                                event.target !== undefined &&
                                event.target.questionID === filter.ID
                              ) {
                                return {
                                  ...filter,
                                  Value:
                                    filter.ColumnName === "INT"
                                      ? Number(event.target.value)
                                      : event.target.value,
                                };
                              }
                              return filter;
                            },
                          );
                          dispatch({
                            type: "updateFilters",
                            newFilters,
                          });
                        }}
                        report
                        disabled={false}
                        pageStatus="Editing" // copy mode does not have a page status anymore so it made lists readOnly
                        questionid={filter.ID}
                        operator={filter.Operator}
                      />
                    ) : null
                  ) : null}
                  {/** add bin */}
                  <Col sm="auto">
                    <button
                      style={{
                        backgroundColor: "#ffffff00",
                        border: "none",
                        marginTop: "10px",
                        marginRight: "10px",
                      }}
                      onClick={() =>
                        dispatch({
                          type: "removeFilter",
                          id: index,
                          dispatch,
                        })
                      }>
                      <Trash2
                        style={{ color: "#d8d8d8" }}
                        className="feather"
                      />
                    </button>
                  </Col>
                </Row>
              ))
            : null}
          {props.data.length !== 0 ? (
            <>
              <Row
                style={{
                  marginTop: "10px",
                  marginBottom: "10px",
                  textAlign: "center",
                }}>
                <Col>
                  <Button
                    variant="dark"
                    onClick={() => dispatch({ type: "addFilter", dispatch })}>
                    <Plus className="feather" />
                    Add a Filter
                  </Button>
                </Col>
              </Row>
              <Row
                style={{
                  marginTop: "10px",
                  marginBottom: "10px",
                  textAlign: "center",
                }}>
                <Col>
                  {/* email */}
                  <Button
                    variant="outline-dark"
                    onClick={() => setDialogVisible(true)}>
                    Email
                  </Button>
                  <Dialog
                    show={dialogVisible}
                    hide={() => setDialogVisible(false)}
                    title="Email Report"
                    headerStyle={{ borderBottom: "0px" }}
                    footerStyle={{ borderTop: "0px" }}
                    bodyComponent={
                      <Row>
                        <Col sm="12">
                          <p style={{ padding: "0 16px" }}>
                            An email will be sent to you with the contents of
                            this dashboard
                          </p>
                        </Col>
                      </Row>
                    }
                    confirm={() => {
                      sendEmail(state, auth, displayToast);
                    }}
                  />
                </Col>
                {/* <Col>
                                    print 
                                </Col> */}
                <Col>
                  {/* export */}
                  <DropdownButton
                    id="dropdown-basic-button"
                    variant="outline-dark"
                    title="Export">
                    <CSVLink
                      data={getJustRegisterTableData(
                        state.searchData,
                        props.relatedObjectTypes,
                        "csv",
                      )}
                      filename={`${props.type}.csv`}
                      enclosingCharacter={`"`}>
                      .csv
                    </CSVLink>
                    {/* <Dropdown.Item > .pdf </Dropdown.Item>
                                        <Dropdown.Item > .docx </Dropdown.Item>
                                        <Dropdown.Item > .xlsx </Dropdown.Item> */}
                  </DropdownButton>
                </Col>
              </Row>
            </>
          ) : null}
          <hr />
        </div>
      </Collapse>

      <Row>
        <Col>
          <SearchResult searchViewData={filteredData} />
        </Col>
      </Row>
    </>
  );
};

const sendEmail = async (
  data: any,
  authState: any,
  displayToast: ReturnType<typeof useToast>["displayToast"],
) => {
  const text = data.filters
    .map((filter: any) => `${filter.ID} ${filter.Operator} ${filter.Value}`)
    .join(" and ");

  const emailData = {
    text,
    tableData: data.searchData,
  };
  try {
    const result = await put(`registerdashboard/email`, emailData, authState);
    if (result.status === 200 || !result.data) {
      displayToast({
        status: "success",
        title: "Email sent successfully",
      });
    } else {
      throw new Error("request failed");
    }
  } catch (error) {
    displayToast({
      status: "error",
      title: "Failed to send email",
    });
  }
};

const getJustRegisterTableData = (
  content: any,
  relatedObjectTypes: any,
  purpose: string,
) => {
  if (purpose === "csv" && content.length > 0 && relatedObjectTypes) {
    const data = content
      .map((table: any) => {
        const relatedRecordsArray = relatedObjectTypes
          .map((obj: any) => {
            const records = table.RelatedRecords.filter(
              (record: any) => obj.ObjectTypeID === record[0].ObjectTypeID,
            );
            return records.map(
              (record: any) =>
                `"${record
                  .map((rec: any) =>
                    rec.RegisterView.map(
                      (row: any) => `${row.colName}: ${row.colValue}`,
                    ).join(","),
                  )
                  .join("\n")}"`,
            );
          })
          .join(",");
        return [
          table.RegisterView.RegisterView.map((row: any) => {
            return `"${row.colValue}"`;
          })
            .join(",")
            .concat(",")
            .concat(relatedRecordsArray),
        ].join("\n");
      })
      .join("\n");
    const headers = [
      content[0].RegisterView.RegisterView.map((row: any) => row.colName)
        .join(",")
        .concat(",")
        .concat(
          relatedObjectTypes
            .map((obj: any) => `Related ${obj.ObjectTypeNamePlural}`)
            .join(","),
        ),
    ].join("\n");
    return headers.concat("\n").concat(data);
  }
  return [];
};

export { AdvancedSearch };
