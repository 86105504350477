import React from "react";
import { ChevronRight, ChevronDown } from "react-feather";

interface CollapseChevronProps {
  collapsed: boolean;
  updateCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
}

export const CollapseChevron = (props: CollapseChevronProps) =>
  props.collapsed ? (
    <ChevronRight
      role="button"
      aria-pressed="true"
      size="16"
      onClick={() => props.updateCollapsed(false)}
    />
  ) : (
    <ChevronDown
      role="button"
      aria-pressed="false"
      size="16"
      onClick={() => props.updateCollapsed(true)}
    />
  );
