import moment from "moment";
import React, { useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { get, put } from "../../../utilities/request";
import useToast from "../../../utilities/useToast";
import { useAuthState } from "../../App/AppProvider";
import { Loading } from "../Loading/Loading";

const RefreshWorkflowMaxData = () => {
  const auth = useAuthState();
  const { displayToast } = useToast();
  const [pageStatus, setStatus] = useState<any>("Loading");
  const [state, setState] = useState<any>({
    WorkflowMaxLastRefreshedTs: null,
    connection: null,
  });

  const getIntegrationStatus = async () => {
    const info: any = await get("settings/job", auth);
    setState({
      WorkflowMaxLastRefreshedTs:
        info.data.connection && info.data.connection.WorkflowMaxLastRefreshedTs
          ? moment
              .utc(info.data.connection.WorkflowMaxLastRefreshedTs)
              .local()
              .format("DD-MMM-YYYY h:mm a")
          : "Never",
      connection: info.data.connection,
    });
    setStatus("Ready");
  };

  React.useEffect(() => {
    getIntegrationStatus();
  }, []);

  return (
    <Button
      type="button"
      variant="outline-dark"
      disabled={state.connection === null}
      onClick={async () => {
        setStatus("Loading");
        put("settings/job/refresh", {}, auth);

        const removeSpinner = () => {
          setStatus("Ready");
          displayToast({
            status: "success",
            title: `Your WorkflowMax Jobs are being refreshed`,
            description:
              "This can take a couple of minutes please check back later",
          });
        };
        setTimeout(removeSpinner, 4000);
      }}>
      <Row>
        <Col sm="auto" style={{ paddingRight: "0px" }}>
          Refresh All WorkflowMax Data
        </Col>
        <Col sm="auto" style={{ paddingLeft: "5px" }}>
          {state.WorkflowMaxLastRefreshedTs && pageStatus === "Ready" ? (
            `(Last refreshed ${state.WorkflowMaxLastRefreshedTs})`
          ) : (
            <Loading size="md" noBorder />
          )}
        </Col>
      </Row>
    </Button>
  );
};

interface RefreshWFMpropsSingle {
  JobsWorkflowMaxID: string;
  JobModifiedTs: Date;
}

const RefreshWorkflowMaxDataSingle = (props: RefreshWFMpropsSingle) => {
  const auth = useAuthState();
  const [pageStatus, setStatus] = useState<any>("Loading");
  const [state, setState] = useState<any>({
    WorkflowMaxLastRefreshedTs: null,
    connection: null,
  });

  const getIntegrationStatus = async () => {
    const info: any = await get("settings/job");
    setState({
      WorkflowMaxLastRefreshedTs: moment
        .utc(props.JobModifiedTs)
        .local()
        .format("DD-MMM-YYYY h:mm a"),
      connection: info.data.connection,
    });
    setStatus("Ready");
  };

  React.useEffect(() => {
    getIntegrationStatus();
  }, []);

  return (
    <Button
      type="button"
      variant="outline-dark"
      disabled={state.connection === null}
      onClick={async () => {
        setStatus("Loading");
        await put(`settings/job/refresh/${props.JobsWorkflowMaxID}`, {});
        window.location.reload();
      }}>
      <Row>
        <Col sm="auto" style={{ paddingRight: "0px" }}>
          Refresh Job
        </Col>
        <Col sm="auto" style={{ paddingLeft: "5px" }}>
          {state.WorkflowMaxLastRefreshedTs && pageStatus === "Ready" ? (
            `(Last refreshed ${state.WorkflowMaxLastRefreshedTs})`
          ) : (
            <Loading size="md" noBorder />
          )}
        </Col>
      </Row>
    </Button>
  );
};

export { RefreshWorkflowMaxData, RefreshWorkflowMaxDataSingle };
