import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { Frown } from "react-feather";
import queryString from "query-string";
import Redirect from "../../../components/common/Redirect";
import { put, post } from "../../../utilities/request";
import { useAppState } from "../../../components/App/AppProvider";

type MicrosoftCallbackStatus = "Loading" | "Ready" | "Failed";

const MicrosoftCallback = () => {
  const location = useLocation();
  const { auth } = useAppState();
  const [pageStatus, setPageStatus] =
    useState<MicrosoftCallbackStatus>("Failed");
  const [periods, setPeriods] = useState<string>(".");

  useEffect(() => {
    const updateCallback = async () => {
      setPageStatus("Loading");

      const returnedQueryString = queryString.parse(location.search);

      try {
        await put(
          "settings/onedrive",
          returnedQueryString.code,
          auth,
        );
        setPageStatus("Ready");
      } catch (err) {
        setPageStatus("Failed");
      }
    };

    updateCallback();
  }, [location, auth]);

  useEffect(() => {
    const interval = setInterval(() => {
      setPeriods(".".repeat((periods.length % 3) + 1));
    }, 250);
    return () => clearInterval(interval);
  }, []);

  const OneDriveCallback = window.localStorage.getItem(`OneDriveCallback`);
  if (pageStatus === "Loading") {
    return (
      <Container>
        <Card style={{ marginTop: "20%", textAlign: "center" }}>
          <Row>
            <Col>
              <h1>We are connecting you to OneDrive now{periods}</h1>
            </Col>
          </Row>
        </Card>
      </Container>
    );
  }
  if (pageStatus === "Ready") {
    return <Redirect to={OneDriveCallback || "/settings/integrations"} />;
  }
  // (pageStatus === 'Failed')
  return (
    <Container>
      <Card style={{ marginTop: "20%", textAlign: "center" }}>
        <Row style={{ marginBottom: "20px" }}>
          <Col>
            <Frown />
          </Col>
        </Row>
        <Row>
          <Col>
            <h1>Oops! An error occurred</h1>
          </Col>
        </Row>
        <Row>
          <Col>
            <h1>
              <Link to={OneDriveCallback || "/settings/integrations"}>
                Go back
              </Link>{" "}
              to the integrations page to try again
            </h1>
          </Col>
        </Row>
      </Card>
    </Container>
  );
};

export { MicrosoftCallback };
