import React from "react";
import { Badge, OverlayTrigger, Tooltip } from "react-bootstrap";
import * as ObjectStatus from "../../../shared/constants/objectStatus";

interface StatusProps {
  variant: "success" | "danger" | "warning" | "light" | "secondary";
  text: string;
  tooltip?: string;
  style?: any;
  placement?: any;
}

export const Status = (props: StatusProps) => (
  <OverlayTrigger
    placement={props.placement ? props.placement : "auto"}
    overlay={
      <Tooltip id={`tooltip-${props.tooltip}`}>{props.tooltip}</Tooltip>
    }>
    <Badge style={props.style ? props.style : {}} variant={props.variant}>
      {props.text}
    </Badge>
  </OverlayTrigger>
);

export const objectStatusToVariant = (
  objectStatusID: string,
): "warning" | "success" | "danger" | "light" | "secondary" => {
  if (objectStatusID === ObjectStatus.Negative) {
    return "danger";
  }
  if (objectStatusID === ObjectStatus.Positive) {
    return "success";
  }
  if (objectStatusID === ObjectStatus.Neutral) {
    return "warning";
  }
  if (objectStatusID === "Draft") {
    return "secondary";
  }
  return "light";
};

export const objectStatusNameToVariant = (
  objectStatusName:
    | "Negative"
    | "Positive"
    | "Neutral"
    | "Draft"
    | (string & {}),
): "warning" | "success" | "danger" | "light" | "secondary" => {
  if (objectStatusName === "Negative") {
    return "danger";
  }
  if (objectStatusName === "Positive") {
    return "success";
  }
  if (objectStatusName === "Neutral") {
    return "warning";
  }
  if (objectStatusName === "Draft") {
    return "secondary";
  }
  return "light";
};
