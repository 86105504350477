/**
 * Custom Field Type IDs
 */
export const SingleSelectList = "046ec61e-ea42-4c24-84a1-1972bad9b837"; // "single-sel"
export const Number = "4e60b38e-e11d-4c3f-9ff5-786999f12c2c"; // "number"
export const Text = "b1b550b5-8e76-401b-ae86-476558ce69f7"; // "text"
export const Date = "f638a15d-cd76-41be-aac3-4a3204acb48d"; // "date"
export const LongText = "f2b854e7-1e3f-48ac-9b72-46bd2ebec228"; // "text-rtf"
export const Link = "fe4cdcdb-c0e9-4681-b00c-37cd868aba91"; // "link"
export const Upload = "efc1d115-6cb3-46c8-aff3-d7082f16e74c"; // "upload"
export const MultiselectList = "a7411ec3-7397-4298-a55d-12ac471e7120"; // "multi-sel"
export const OnedriveUpload = "a5778559-530c-46b2-8b3c-001942e285b5"; // "upload-onedrive"

export const CustomFieldTypeID = {
  SingleSelectList,
  Number,
  Text,
  Date,
  LongText,
  Link,
  Upload,
  MultiselectList,
  OnedriveUpload,
} as const;

export const CustomFieldTypeIDs = [
  SingleSelectList,
  Number,
  Text,
  Date,
  LongText,
  Link,
  Upload,
  MultiselectList,
  OnedriveUpload,
] as const;

export type CustomFieldTypeID = (typeof CustomFieldTypeIDs)[number];

export const NonListCustomFieldTypeIDs = [
  Number,
  Text,
  Date,
  LongText,
  Link,
  Upload,
  OnedriveUpload,
] as const;

export type NonListCustomFieldTypeID =
  (typeof NonListCustomFieldTypeIDs)[number];

export const ListCustomFieldTypeIDs = [
  SingleSelectList,
  MultiselectList,
] as const;

export type ListCustomFieldTypeID = (typeof ListCustomFieldTypeIDs)[number];

export const CustomFieldTypeIDToName = {
  [SingleSelectList]: "Single Select List",
  [Number]: "Number",
  [OnedriveUpload]: "OneDrive Upload",
  [MultiselectList]: "Multi Select List",
  [Text]: "Text Short",
  [Upload]: "Upload",
  [LongText]: "Text Long",
  [Date]: "Date",
  [Link]: "Link",
} as const;

/**
 * Returns true if this type id is any kind of list
 */
export const isListType = (
  id: string,
): id is (typeof ListCustomFieldTypeIDs)[number] =>
  id === SingleSelectList || id === MultiselectList;
