import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Dropdown,
  DropdownButton,
  OverlayTrigger,
  Row,
  Table,
  Tooltip,
} from "react-bootstrap";
import * as Feather from "react-feather";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAppState } from "../../components/App/AppProvider";
import { CustomFieldDragAndDrop } from "../../components/Forms/CustomFields/CustomFieldDD";
import { Card } from "../../components/UI/Card/Card";
import { Dialog } from "../../components/UI/Dialog/Dialog";
import { Header } from "../../components/UI/Header/Header";
import LegacyScreenContainer from "../../components/UI/LegacyScreenContainer";
import { Loading } from "../../components/UI/Loading/Loading";
import * as ObjectTypeGroupID from "../../constants/objectTypeGroup";
import * as Permissions from "../../shared/constants/permission";
import * as Access from "../../utilities/access";
import { del, get, post, put } from "../../utilities/request";
import useToast from "../../utilities/useToast";

// this is for switching between the values of 1 and 0
const switchBinaryValue = (value: boolean): boolean => !value;

const getObjectTypesForRelationship = (
  objectTypes: any,
  selectedObjectType: any,
) => {
  const availableObjectTypes = objectTypes
    .filter((o: any) => o.ObjectTypeGroupID !== ObjectTypeGroupID.Action)
    .filter((obj: any) => {
      if (
        !selectedObjectType.ObjectTypeTree.find(
          (rel: any) => rel.ObjectTypeID === obj.ObjectTypeID,
        )
      ) {
        return obj;
      }
    });
  return availableObjectTypes;
};
const ScreensPageFields = () => {
  const { app, auth } = useAppState();
  const navigate = useNavigate();
  const location = useLocation();
  const [pageStatus, setPageStatus] = useState<PageStatus>("Loading");
  const { displayToast } = useToast();
  const [customFields, setCustomFields] = useState<any>(null);
  const [coreFields, setCoreFields] = useState<any>(null);
  const [objectTypeCoreFields, setObjectTypeCoreFields] = useState<any>(null);
  const [objectTypes, setObjectTypes] = useState<any>(null);
  const [selectedObjectType, setSelectedObjectType] = useState<any>(null);
  const [objectTypeCustomFieldOrder, setObjectTypeCustomFieldOrder] =
    useState<any>(null);
  const [originalOrder, setOriginalOrder] = useState<any>(null);

  // icons declared like this so we can modify color
  const checked: keyof typeof Feather = "CheckSquare";
  const unChecked: keyof typeof Feather = "Square";
  const Icon = Feather[checked];
  const Icon2 = Feather[unChecked];

  const setOrder = async (list: any[]) => {
    setObjectTypeCustomFieldOrder(list);
  };

  const executeRelationshipDelete = async (removeID: string) => {
    let newSelectedObjectType: any = {};
    const newObjectTypes: any[] = objectTypes.map((obj: any) => {
      if (obj.ObjectTypeID === selectedObjectType.ObjectTypeID) {
        const newObjectTree = obj.ObjectTypeTree.filter(
          (rel: any) => rel.ObjectTypeID !== removeID,
        );
        newSelectedObjectType = { ...obj, ObjectTypeTree: newObjectTree };
        return { ...obj, ObjectTypeTree: newObjectTree };
      }
      if (obj.ObjectTypeID === removeID) {
        const newObjectTree = obj.ObjectTypeTree.filter(
          (rel: any) => rel.ObjectTypeID !== selectedObjectType.ObjectTypeID,
        );
        return { ...obj, ObjectTypeTree: newObjectTree };
      }
      return obj;
    });
    const response = await del(
      `settings/pages/${selectedObjectType.ObjectTypeID}/objecttype/${removeID}`,
      auth,
    );
    if (response.status !== 200 || !response.data) {
      displayToast({
        status: "error",
        title: "Failed to remove Relationship",
      });
    } else {
      setObjectTypes(newObjectTypes);
      setSelectedObjectType(newSelectedObjectType);
      setObjectType(
        newSelectedObjectType.ObjectTypeID,
        newObjectTypes,
        customFields,
        coreFields,
      );
      displayToast({
        status: "success",
        title: "Relationship removed successfully",
      });
    }
    setPageStatus("Ready");
  };

  const RemoveButton = ({ object }: { object: any }) => {
    const [dialogVisible, setDialogVisible] = useState(false);

    return (
      <Col sm="1" style={{ padding: "10px" }}>
        {" "}
        <Button
          variant="outline-dark"
          id={object.ObjectTypeID}
          onClick={async (event: any) => {
            if (object.ConnectedRecordsTotal === 0) {
              await executeRelationshipDelete(event.target.id);
            } else {
              setDialogVisible(true);
            }
          }}>
          Remove
        </Button>
        <Dialog
          show={dialogVisible}
          hide={() => setDialogVisible(false)}
          title="Confirm delete"
          body={`This relationship has ${object.ConnectedRecordsTotal} active ${
            object.ConnectedRecordsTotal === 1
              ? "relationship"
              : "relationships"
          } between records - are you sure you want to delete ${
            object.ConnectedRecordsTotal === 1 ? "it" : "all of them"
          }?`}
          confirm={async () => {
            const removeID: string = object.ObjectTypeID;
            await executeRelationshipDelete(removeID);
          }}
        />
      </Col>
    );
  };

  const updateCustomFieldRegView = (field: any, customFields: any[]) =>
    customFields.map((custom: any) => {
      if (custom.CustomFieldID === field.CustomFieldID) {
        const ObjectTypes = custom.ObjectTypes.map((obj: any) => {
          if (obj.ObjectTypeID === selectedObjectType.ObjectTypeID) {
            return {
              ...obj,
              ObjectTypeCustomFieldRegisterView: switchBinaryValue(
                obj.ObjectTypeCustomFieldRegisterView,
              ),
            };
          }
          return obj;
        });
        return { ...custom, ObjectTypes };
      }
      return custom;
    });

  const updateCoreFieldRegView = (field: any, coreFields: any[]) =>
    coreFields.map((core: any) => {
      if (core.CoreFieldID === field.CoreFieldID) {
        const ObjectTypes = core.ObjectTypes.map((obj: any) => {
          if (obj.ObjectTypeID === selectedObjectType.ObjectTypeID) {
            return {
              ...obj,
              ObjectTypeCoreFieldRegisterView: switchBinaryValue(
                obj.ObjectTypeCoreFieldRegisterView,
              ),
            };
          }
          return obj;
        });
        return { ...core, ObjectTypes };
      }
      return core;
    });
  // this function will be called each time a picture of register view is pressed
  const updateRegisterView = async (
    field: any,
    type: string,
    objectTypeID?: string,
  ) => {
    // make request
    const req =
      type === "corefield"
        ? await post(
            `objecttype/${objectTypeID}/corefield/${field.CoreFieldID}`,
            { CoreField: field, ObjectTypeID: objectTypeID },
            auth,
          )
        : await post(
            `objecttype/${objectTypeID}/customfield/${field.CustomFieldID}`,
            { CustomField: field, ObjectTypeID: objectTypeID },
            auth,
          );
    if (req.status !== 200) {
      displayToast({
        status: "error",
        title: "Failed to update Register View Settings",
      });
    } else {
      if (type === "corefield") {
        // make new core fields and object type fields
        const newGeneralCorefields = updateCoreFieldRegView(field, coreFields);
        setCoreFields(newGeneralCorefields);

        const newSpecificCorefields = updateCoreFieldRegView(
          field,
          objectTypeCoreFields,
        );
        setObjectTypeCoreFields(newSpecificCorefields);
      } else {
        // make new custom fields and object type fields
        const newGeneralCustomfields = updateCustomFieldRegView(
          field,
          customFields,
        );
        setCustomFields(newGeneralCustomfields);

        const newSpecificCustomfields = updateCustomFieldRegView(
          field,
          objectTypeCustomFieldOrder,
        );
        setObjectTypeCustomFieldOrder(newSpecificCustomfields);
      }

      displayToast({
        status: "success",
        title: "Register View Settings updated successfully",
      });
    }
  };
  const setObjectType = async (
    selected: string,
    objectTypes: any[],
    customFields: any[],
    coreFields: any[],
  ) => {
    const selectedObjectType = objectTypes.find(
      (objectType: any) => objectType.ObjectTypeID === selected,
    );
    // specify the corefields for the objectTypeGroup chosen
    const ObjectTyupeGroupCoreFields = coreFields.filter((field: any) =>
      field.ObjectTypes.find(
        (objecttype: any) =>
          objecttype.ObjectTypeID === selectedObjectType.ObjectTypeID,
      ),
    );
    setObjectTypeCoreFields(ObjectTyupeGroupCoreFields);

    setSelectedObjectType(selectedObjectType);
    const objectTypeCFOrder = customFields.filter(
      (customfield: any, fieldIndex: any) =>
        customfield.ObjectTypes.find(
          (objecttype: any) =>
            objecttype.ObjectTypeID === selectedObjectType.ObjectTypeID,
        ),
    );

    const order = objectTypeCFOrder.sort((a: any, b: any) => {
      const aOrder = a.ObjectTypes.find(
        (objectType: any) =>
          objectType.ObjectTypeID === selectedObjectType.ObjectTypeID,
      );
      const bOrder = b.ObjectTypes.find(
        (objectType: any) =>
          objectType.ObjectTypeID === selectedObjectType.ObjectTypeID,
      );

      return aOrder.ObjectTypeCustomFieldOrder <
        bOrder.ObjectTypeCustomFieldOrder
        ? -1
        : aOrder.ObjectTypeCustomFieldOrder > bOrder.ObjectTypeCustomFieldOrder
        ? 1
        : 0;
    });
    setOriginalOrder(order);
    setObjectTypeCustomFieldOrder(order);
  };

  useEffect(() => {
    const fetchPageData = async () => {
      setPageStatus("Loading");
      const result = await get("settings/pages", auth);

      if (result.status !== 200 || !result.data) {
        displayToast({
          status: "error",
          title: "Failed to retrieve Pages",
        });
      } else {
        setCustomFields(result.data.customFields);
        setCoreFields(result.data.coreFields);
        setObjectTypes(result.data.objectTypeInformation);
        if (location.search) {
          const selected = location.search.split("=")[1];
          setObjectType(
            selected,
            result.data.objectTypeInformation,
            result.data.customFields,
            result.data.coreFields,
          );
        } else {
          console.log("No Search");
        }

        setPageStatus("Ready");
      }
    };

    if (auth.isLoggedIn) {
      fetchPageData();
    }
    if (pageStatus === "Submitting") {
      setPageStatus("Ready");
    }
  }, [auth]);

  return Access.checkAccess(
    app.permissions_LEGACY,
    Permissions.CodeUserSettings,
    Permissions.TypeRead,
    false,
  ) ? (
    pageStatus !== "Loading" ? (
      <LegacyScreenContainer
        pageTitle={`${
          !selectedObjectType
            ? "Fields"
            : selectedObjectType.ObjectTypeGroupName
        }: ${selectedObjectType.ObjectTypeName} (Fields)`}
        breadcrumbs={[{ label: "Pages", link: "/settings/pages" }]}>
        <>
          <>
            {/* <Header
              breadcrumbs={[
                { title: "Settings", link: "/settings" },
                { title: "Pages & Fields" },
                { title: "Pages", link: "/settings/pages" },
                !selectedObjectType
                  ? { title: "Fields" }
                  : { title: selectedObjectType.ObjectTypeName },
              ]}
              title={`${
                !selectedObjectType
                  ? "Fields"
                  : selectedObjectType.ObjectTypeGroupName
              }: ${selectedObjectType.ObjectTypeName}`}
              buttons={[
                <DropdownButton
                  id="pageSelect"
                  title="Select Page"
                  onSelect={async (selected: any) => {
                    const selectedObjectType = objectTypes.find(
                      (objectType: any) => objectType.ObjectTypeID === selected,
                    );
                    // specify the corefields for the objectTypeGroup chosen
                    const ObjectTypeGroupCoreFields = coreFields.filter(
                      (field: any) =>
                        field.ObjectTypeGroupID ===
                        selectedObjectType.ObjectTypeGroupID,
                    );
                    setObjectTypeCoreFields(ObjectTypeGroupCoreFields);

                    setSelectedObjectType(selectedObjectType);
                    const objectTypeCFOrder = customFields.filter(
                      (customfield: any, fieldIndex: any) =>
                        customfield.ObjectTypes.find(
                          (objecttype: any) =>
                            objecttype.ObjectTypeID ===
                            selectedObjectType.ObjectTypeID,
                        ),
                    );

                    // specify the corefields for the objectTypeGroup chosen
                    const ObjectTyupeGroupCoreFields = coreFields.filter(
                      (field: any) =>
                        field.ObjectTypes.find(
                          (objecttype: any) =>
                            objecttype.ObjectTypeID ===
                            selectedObjectType.ObjectTypeID,
                        ),
                    );
                    setObjectTypeCoreFields(ObjectTyupeGroupCoreFields);

                    const order = objectTypeCFOrder.sort((a: any, b: any) => {
                      const aOrder = a.ObjectTypes.find(
                        (objectType: any) =>
                          objectType.ObjectTypeID ===
                          selectedObjectType.ObjectTypeID,
                      );
                      const bOrder = b.ObjectTypes.find(
                        (objectType: any) =>
                          objectType.ObjectTypeID ===
                          selectedObjectType.ObjectTypeID,
                      );

                      return aOrder.ObjectTypeCustomFieldOrder <
                        bOrder.ObjectTypeCustomFieldOrder
                        ? -1
                        : aOrder.ObjectTypeCustomFieldOrder >
                          bOrder.ObjectTypeCustomFieldOrder
                        ? 1
                        : 0;
                    });
                    setOriginalOrder(order);
                    setObjectTypeCustomFieldOrder(order);
                  }}>
                  {objectTypes.map((requirement: any, index: any) => (
                    <Dropdown.Item
                      eventKey={requirement.ObjectTypeID}
                      key={
                        index
                      }>{`${requirement.ObjectTypeGroupName}:  ${requirement.ObjectTypeName}`}</Dropdown.Item>
                  ))}
                </DropdownButton>,
              ]}
            /> */}
          </>

          {selectedObjectType !== null &&
          customFields !== null &&
          coreFields !== null ? (
            <>
              <Card
                title="Fields"
                collapsable={false}
                headerCols={
                  Access.checkAccess(
                    app.permissions_LEGACY,
                    Permissions.CodeUserSettings,
                    Permissions.TypeUpdate,
                  )
                    ? [
                        { colProps: {}, children: <span /> },
                        {
                          colProps: { sm: "auto" },
                          children: (
                            <Button
                              type="button"
                              variant="outline-dark"
                              onClick={() => setPageStatus("Editing")}
                              block>
                              Change Custom Field Order
                            </Button>
                          ),
                        },
                        {
                          colProps: { sm: "auto" },
                          children: (
                            <Col sm="2">
                              <DropdownButton
                                id="pageSelect"
                                title="Add Custom Field"
                                onSelect={async (selected: any) => {
                                  const newCust = customFields.find(
                                    (cf: any) => cf.CustomFieldID === selected,
                                  );

                                  const entry: any = {
                                    CustomFieldID: selected,
                                    ObjectTypeID:
                                      selectedObjectType.ObjectTypeID,
                                    Type: selectedObjectType.ObjectTypeGroupName,
                                  };

                                  const newCustomFields: any[] = [
                                    ...customFields,
                                  ];
                                  const insert: any[] = newCustomFields.map(
                                    (customField: any) => ({
                                      ...customField,
                                      ObjectTypes:
                                        customField.ObjectTypes.concat(
                                          customField.CustomFieldID ===
                                            entry.CustomFieldID
                                            ? [
                                                {
                                                  ObjectTypeCustomFieldOrder:
                                                    objectTypeCustomFieldOrder.length,
                                                  ObjectTypeCustomFieldRegisterView: 0,
                                                  ObjectTypeGroupID:
                                                    selectedObjectType.ObjectTypeGroupID,
                                                  ObjectTypeID:
                                                    selectedObjectType.ObjectTypeID,
                                                  ObjectTypeName:
                                                    selectedObjectType.ObjectTypeName,
                                                },
                                              ]
                                            : [],
                                        ),
                                    }),
                                  );

                                  const newFields: any[] = [
                                    ...objectTypeCustomFieldOrder,
                                    {
                                      ...newCust,
                                      ObjectTypes: newCust.ObjectTypes.concat({
                                        ObjectTypeCustomFieldOrder:
                                          objectTypeCustomFieldOrder.length,
                                        ObjectTypeCustomFieldRegisterView: 0,
                                        ObjectTypeGroupID:
                                          selectedObjectType.ObjectTypeGroupID,
                                        ObjectTypeID:
                                          selectedObjectType.ObjectTypeID,
                                        ObjectTypeName:
                                          selectedObjectType.ObjectTypeName,
                                      }),
                                    },
                                  ];

                                  setObjectTypeCustomFieldOrder(newFields);
                                  setCustomFields(insert);
                                  const response: any = await put(
                                    `settings/pages/${entry.ObjectTypeID}`,
                                    {
                                      id: entry.CustomFieldID,
                                      order: originalOrder.length + 1,
                                    },
                                    auth,
                                  );

                                  if (
                                    response.status !== 200 ||
                                    !response.data
                                  ) {
                                    setObjectTypeCustomFieldOrder(
                                      originalOrder,
                                    );
                                    displayToast({
                                      status: "error",
                                      title: "Failed to add Custom Field",
                                    });
                                  } else {
                                    setOriginalOrder(newFields);
                                    displayToast({
                                      status: "success",
                                      title: "Custom Field added successfully",
                                    });
                                  }
                                  setPageStatus("Ready");
                                }}>
                                {customFields.map(
                                  (customField: any, index: any) => {
                                    if (
                                      !customField.ObjectTypes.find(
                                        (objecttype: any) =>
                                          objecttype.ObjectTypeID ===
                                          selectedObjectType.ObjectTypeID,
                                      )
                                    ) {
                                      return (
                                        <Dropdown.Item
                                          eventKey={customField.CustomFieldID}
                                          key={
                                            index
                                          }>{`${customField.CustomFieldLabel}: ${customField.CustomFieldTypeName}`}</Dropdown.Item>
                                      );
                                    }
                                  },
                                )}
                              </DropdownButton>
                            </Col>
                          ),
                        },
                      ]
                    : [
                        { colProps: {}, children: <span /> },
                        { colProps: {}, children: <span /> },
                        {
                          colProps: { sm: "auto" },
                          children: (
                            <OverlayTrigger
                              placement="auto"
                              overlay={
                                <Tooltip id="tooltip-not-authorised-to-edit">
                                  You do not have permission to edit Pages
                                </Tooltip>
                              }>
                              <Button type="button" className="disabled">
                                Change Custom Field Order
                              </Button>
                            </OverlayTrigger>
                          ),
                        },
                        {
                          colProps: { sm: "auto" },
                          children: (
                            <OverlayTrigger
                              placement="auto"
                              overlay={
                                <Tooltip id="tooltip-not-authorised-to-edit">
                                  You do not have permission to edit Pages
                                </Tooltip>
                              }>
                              <Button type="button" className="disabled">
                                Add Custom Field
                              </Button>
                            </OverlayTrigger>
                          ),
                        },
                      ]
                }>
                <Table borderless>
                  <tbody>
                    <Row>
                      <Col sm="1" className="header-row-pageFields">
                        {" "}
                        Order{" "}
                      </Col>
                      <Col sm="3" className="header-row-pageFields">
                        {" "}
                        Name{" "}
                      </Col>
                      <Col sm="2" className="header-row-pageFields">
                        {" "}
                        Type{" "}
                      </Col>
                      <Col sm="2" className="header-row-pageFields">
                        {" "}
                        Mandatory{" "}
                      </Col>
                      {selectedObjectType.ObjectTypeGroupID ===
                      ObjectTypeGroupID.Register ? (
                        <Col sm="2" className="header-row-pageFields">
                          {" "}
                          Register View{" "}
                        </Col>
                      ) : null}
                      <Col sm="2" className="header-row-pageFields">
                        {" "}
                        Action{" "}
                      </Col>
                    </Row>
                    {objectTypeCoreFields.map((field: any, index: number) => (
                      <Row key={field.CoreFieldName} id={`coref${index}`}>
                        <Col
                          sm="1"
                          className="normal-column-pageFields"
                          key={index}>
                          {" "}
                          {index + 1}{" "}
                        </Col>
                        <Col sm="3" className="normal-column-pageFields">
                          {field.CoreFieldName}
                        </Col>
                        <Col sm="2" className="normal-column-pageFields">
                          {field.CoreFieldName === "Status" ||
                          field.CoreFieldName === "ID"
                            ? `Core: Calculated`
                            : `Core: ${field.CustomFieldTypeName}`}
                        </Col>
                        <Col sm="2" className="checkBox-column-pageFields">
                          {field.CoreFieldName === "Status" ||
                          field.CoreFieldName === "ID" ? (
                            ` - `
                          ) : field.ObjectTypes.find(
                              (o: any) =>
                                o.ObjectTypeID ===
                                selectedObjectType.ObjectTypeID,
                            ).ObjectTypeCoreFieldMandatory === 1 ? (
                            <Icon color="rgb(162, 170, 176)" />
                          ) : (
                            <Icon2 color="rgb(162, 170, 176)" />
                          )}{" "}
                        </Col>
                        {selectedObjectType.ObjectTypeGroupID ===
                        ObjectTypeGroupID.Register ? (
                          <Col sm="2" className="checkBox-column-pageFields">
                            {" "}
                            {selectedObjectType.ObjectTypeGroupID ===
                            ObjectTypeGroupID.Register ? (
                              field.CoreFieldName === "ID" ? (
                                <Icon color="rgb(162, 170, 176)" />
                              ) : field.ObjectTypes.find(
                                  (o: any) =>
                                    o.ObjectTypeID ===
                                    selectedObjectType.ObjectTypeID,
                                ).ObjectTypeCoreFieldRegisterView ? (
                                <Icon
                                  onClick={() => {
                                    updateRegisterView(
                                      field,
                                      "corefield",
                                      selectedObjectType.ObjectTypeID,
                                    );
                                  }}
                                />
                              ) : (
                                <Icon2
                                  onClick={() => {
                                    updateRegisterView(
                                      field,
                                      "corefield",
                                      selectedObjectType.ObjectTypeID,
                                    );
                                  }}
                                />
                              )
                            ) : null}{" "}
                          </Col>
                        ) : null}
                        <Col sm="1" className="normal-column-pageFields">
                          {" "}
                          -{" "}
                        </Col>
                      </Row>
                    ))}
                    {selectedObjectType != null && objectTypes !== null ? (
                      objectTypes.find(
                        (element: any) =>
                          element.ObjectTypeID ===
                          selectedObjectType.ObjectTypeID,
                      ).ObjectTypeGroupName === "Issue" ? (
                        <Row>
                          <Col sm="1" className="normal-column-pageFields">
                            {" "}
                            {objectTypeCoreFields.length + 1}{" "}
                          </Col>
                          <Col sm="3" className="normal-column-pageFields">
                            {" "}
                            Related Risks/Controls{" "}
                          </Col>
                          <Col sm="2" className="normal-column-pageFields">
                            {" "}
                            Core: Multi-select List{" "}
                          </Col>
                          <Col sm="2" className="checkBox-column-pageFields">
                            {" "}
                            <Icon2 color="rgb(162, 170, 176)" />{" "}
                          </Col>
                          <Col sm="2" className="normal-column-pageFields">
                            {" "}
                            -{" "}
                          </Col>
                        </Row>
                      ) : objectTypes.find(
                          (element: any) =>
                            element.ObjectTypeID ===
                            selectedObjectType.ObjectTypeID,
                        ).ObjectTypeGroupName === "Risk" ? (
                        <Row>
                          <Col sm="1" className="normal-column-pageFields">
                            {" "}
                            {objectTypeCoreFields.length + 1}{" "}
                          </Col>
                          <Col sm="3" className="normal-column-pageFields">
                            {" "}
                            Related Issues{" "}
                          </Col>
                          <Col sm="2" className="normal-column-pageFields">
                            {" "}
                            Core: Multi-select List{" "}
                          </Col>
                          <Col sm="2" className="checkBox-column-pageFields">
                            {" "}
                            <Icon2 color="rgb(162, 170, 176)" />{" "}
                          </Col>
                          <Col sm="2" className="normal-column-pageFields">
                            {" "}
                            -{" "}
                          </Col>
                        </Row>
                      ) : null
                    ) : null}
                    {customFields !== null && pageStatus === "Editing" ? (
                      <CustomFieldDragAndDrop
                        id="mydd"
                        selectObjectType={selectedObjectType}
                        setOrder={setOrder}
                        fields={objectTypeCustomFieldOrder}
                        customfields={objectTypeCustomFieldOrder.map(
                          (customField: any, index: any) => (
                            <Row
                              key={customField.CustomFieldLabel}
                              id={`cf${index}`}>
                              <Col
                                sm="1"
                                key={index}
                                accessKey={customField.CustomFieldID}
                                className="normal-column-pageFields">
                                <Feather.Move style={{ color: "#d8d8d8" }} />
                              </Col>
                              <Col sm="3" className="normal-column-pageFields">
                                {customField.CustomFieldLabel}
                              </Col>
                              <Col
                                sm="2"
                                className="normal-column-pageFields">{`Custom: ${customField.CustomFieldTypeName}`}</Col>
                              <Col
                                sm="2"
                                className="checkBox-column-pageFields">
                                {" "}
                                {customField.CustomFieldMandatory === 1 ? (
                                  <Icon color="rgb(162, 170, 176)" />
                                ) : (
                                  <Icon2 color="rgb(162, 170, 176)" />
                                )}{" "}
                              </Col>
                              {selectedObjectType.ObjectTypeGroupID ===
                              ObjectTypeGroupID.Register ? (
                                <Col
                                  sm="2"
                                  className="checkBox-column-pageFields">
                                  {" "}
                                  {customField.CustomFieldRegisterView ? (
                                    <Icon color="rgb(162, 170, 176)" />
                                  ) : (
                                    <Icon2 color="rgb(162, 170, 176)" />
                                  )}{" "}
                                </Col>
                              ) : null}
                              <Col sm="1" className="normal-column-pageFields">
                                {" "}
                                <Button
                                  variant="outline-dark"
                                  id={customField.CustomFieldID}
                                  onClick={async (event: any) => {
                                    const cFieldID: string = event.target.id;
                                    const allFields: any[] = customFields;
                                    const custFieldData = allFields.map(
                                      (cf: any) => ({
                                        ...cf,
                                        ObjectTypes:
                                          cf.CustomFieldID === cFieldID
                                            ? cf.ObjectTypes.filter(
                                                (objectType: any) =>
                                                  objectType.ObjectTypeID !==
                                                  selectedObjectType.ObjectTypeID,
                                              )
                                            : cf.ObjectTypes,
                                      }),
                                    );
                                    setCustomFields(custFieldData);

                                    const newCust = customFields.find(
                                      (cf: any) =>
                                        cf.CustomFieldID === cFieldID,
                                    );

                                    const newFields =
                                      objectTypeCustomFieldOrder.filter(
                                        (cf: any) =>
                                          cf.CustomFieldID !==
                                          newCust.CustomFieldID,
                                      );

                                    setObjectTypeCustomFieldOrder(newFields);

                                    const response = await del(
                                      `objecttype/${selectedObjectType.ObjectTypeID}/customfield/${cFieldID}`,
                                      auth,
                                    );
                                    if (
                                      response.status !== 200 ||
                                      !response.data
                                    ) {
                                      setObjectTypeCustomFieldOrder(
                                        originalOrder,
                                      );
                                      displayToast({
                                        status: "error",
                                        title: "Failed to remove Custom Field",
                                      });
                                    } else {
                                      setOriginalOrder(newFields);
                                      displayToast({
                                        status: "success",
                                        title:
                                          "Custom Field removed successfully",
                                      });
                                    }
                                    setPageStatus("Ready");
                                  }}>
                                  Remove
                                </Button>
                              </Col>{" "}
                            </Row>
                          ),
                        )}
                      />
                    ) : null}
                    {pageStatus !== "Editing"
                      ? objectTypeCustomFieldOrder.map(
                          (customField: any, index: any) => (
                            <Row key={index}>
                              <Col
                                sm="1"
                                className="normal-column-pageFields"
                                key={index}>
                                {" "}
                                {objectTypeCoreFields.length +
                                  (selectedObjectType.ObjectTypeGroupID ===
                                    ObjectTypeGroupID.Risk ||
                                  selectedObjectType.ObjectTypeGroupID ===
                                    ObjectTypeGroupID.Issue
                                    ? 2
                                    : 1) +
                                  index}{" "}
                              </Col>
                              <Col sm="3" className="normal-column-pageFields">
                                <Link
                                  to={`/settings/customField/${customField.CustomFieldID}`}>
                                  {" "}
                                  {customField.CustomFieldLabel}
                                </Link>
                              </Col>
                              <Col
                                sm="2"
                                className="normal-column-pageFields">{`Custom: ${customField.CustomFieldTypeName}`}</Col>
                              <Col
                                sm="2"
                                className="checkBox-column-pageFields">
                                {" "}
                                {customField.CustomFieldMandatory === 1 ? (
                                  <Icon color="rgb(162, 170, 176)" />
                                ) : (
                                  <Icon2 color="rgb(162, 170, 176)" />
                                )}{" "}
                              </Col>
                              {selectedObjectType.ObjectTypeGroupID ===
                              ObjectTypeGroupID.Register ? (
                                <Col
                                  sm="2"
                                  className="checkBox-column-pageFields">
                                  {" "}
                                  {customField.ObjectTypes.find(
                                    (o: any) =>
                                      o.ObjectTypeID ===
                                      selectedObjectType.ObjectTypeID,
                                  ).ObjectTypeCustomFieldRegisterView ? (
                                    <Icon
                                      onClick={() => {
                                        updateRegisterView(
                                          customField,
                                          "customfield",
                                          selectedObjectType.ObjectTypeID,
                                        );
                                      }}
                                    />
                                  ) : (
                                    <Icon2
                                      onClick={() => {
                                        updateRegisterView(
                                          customField,
                                          "customfield",
                                          selectedObjectType.ObjectTypeID,
                                        );
                                      }}
                                    />
                                  )}{" "}
                                </Col>
                              ) : null}
                              <Col sm="1" className="normal-column-pageFields">
                                {" "}
                                <Button
                                  variant="outline-dark"
                                  id={customField.CustomFieldID}
                                  onClick={async (event: any) => {
                                    const cFieldID: string = event.target.id;
                                    const allFields: any[] = customFields;
                                    const custFieldData = allFields.map(
                                      (cf: any) => ({
                                        ...cf,
                                        ObjectTypes:
                                          cf.CustomFieldID === cFieldID
                                            ? cf.ObjectTypes.filter(
                                                (objectType: any) =>
                                                  objectType.ObjectTypeID !==
                                                  selectedObjectType.ObjectTypeID,
                                              )
                                            : cf.ObjectTypes,
                                      }),
                                    );
                                    setCustomFields(custFieldData);

                                    const newCust = customFields.find(
                                      (cf: any) =>
                                        cf.CustomFieldID === cFieldID,
                                    );

                                    const newFields =
                                      objectTypeCustomFieldOrder.filter(
                                        (cf: any) =>
                                          cf.CustomFieldID !==
                                          newCust.CustomFieldID,
                                      );

                                    setObjectTypeCustomFieldOrder(newFields);
                                    const response = await del(
                                      `objecttype/${selectedObjectType.ObjectTypeID}/customfield/${cFieldID}`,
                                      auth,
                                    );
                                    if (
                                      response.status !== 200 ||
                                      !response.data
                                    ) {
                                      setObjectTypeCustomFieldOrder(
                                        originalOrder,
                                      );
                                      displayToast({
                                        status: "error",
                                        title: "Failed to remove Custom Field",
                                      });
                                    } else {
                                      setOriginalOrder(newFields);
                                      displayToast({
                                        status: "success",
                                        title:
                                          "Custom Field removed successfully",
                                      });
                                    }
                                    setPageStatus("Ready");
                                  }}>
                                  Remove
                                </Button>
                              </Col>{" "}
                            </Row>
                          ),
                        )
                      : null}

                    <Row>
                      <Col>
                        <Link
                          to={`/settings/pages/settings?objecttype=${selectedObjectType.ObjectTypeID}`}>
                          {" "}
                          Manage the Settings for this page
                        </Link>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Link
                          to={`/settings/pages/documents?objecttype=${selectedObjectType.ObjectTypeID}`}>
                          {" "}
                          Manage the Template Documents for this page
                        </Link>
                      </Col>
                    </Row>

                    <Row as="span">
                      {pageStatus === "Editing" ||
                      pageStatus === "Submitting" ? (
                        <>
                          <Col sm={{ offset: "1", span: "1" }}>
                            <Button
                              type="submit"
                              onClick={async (event: any) => {
                                setPageStatus("Submitting");
                                const result = await post(
                                  `settings/objectTypeCustomFieldOrder/${selectedObjectType.ObjectTypeID}`,
                                  objectTypeCustomFieldOrder,
                                  auth,
                                );

                                if (result.status !== 200) {
                                  displayToast({
                                    status: "error",
                                    title:
                                      "Failed to update Custom Field order",
                                  });
                                } else {
                                  displayToast({
                                    status: "success",
                                    title:
                                      "Custom Field Order updated successfully",
                                  });
                                }
                                setPageStatus("Ready");
                              }}>
                              Submit
                            </Button>
                          </Col>
                          <Col sm={{ offset: "1", span: "2" }}>
                            <Button
                              type="button"
                              variant="light"
                              onClick={() => {
                                setObjectTypeCustomFieldOrder(originalOrder);
                                setPageStatus("Ready");
                              }}
                              block>
                              Cancel
                            </Button>
                          </Col>
                        </>
                      ) : null}
                    </Row>
                  </tbody>
                </Table>
              </Card>
              {selectedObjectType.ObjectTypeGroupID !==
              ObjectTypeGroupID.Action ? (
                <Card
                  title="Relationships"
                  collapsable={false}
                  headerCols={
                    Access.checkAccess(
                      app.permissions_LEGACY,
                      Permissions.CodeUserSettings,
                      Permissions.TypeUpdate,
                    )
                      ? [
                          { colProps: {}, children: <span /> },
                          {
                            colProps: { sm: "auto" },
                            children: (
                              <Col sm="2">
                                <DropdownButton
                                  id="pageSelect"
                                  title="Add Relationship"
                                  onSelect={async (selected: any) => {
                                    let newSelectedObjectType: any = {};
                                    // insert the selected objectType into the objectTypeTrees of the current object type displaying but also add the opposite
                                    const newRelationship = objectTypes
                                      .filter(
                                        (o: any) =>
                                          o.ObjectTypeGroupID !==
                                          ObjectTypeGroupID.Action,
                                      )
                                      .filter(
                                        (object: any, index: any) =>
                                          object.ObjectTypeID === selected,
                                      );

                                    const entryInCurrentObject: any = {
                                      ObjectTypeID: selected,
                                      ObjectTypeName:
                                        newRelationship[0].ObjectTypeName,
                                      ObjectTypeGroupName:
                                        newRelationship[0].ObjectTypeGroupName,
                                    };
                                    const entryInSelectedObject: any = {
                                      ObjectTypeID:
                                        selectedObjectType.ObjectTypeID,
                                      ObjectTypeName:
                                        selectedObjectType.ObjectTypeName,
                                      ObjectTypeGroupName:
                                        selectedObjectType.ObjectTypeGroupName,
                                    };
                                    const newObjectTypes: any[] =
                                      objectTypes.map((obj: any) => {
                                        if (
                                          obj.ObjectTypeID ===
                                          selectedObjectType.ObjectTypeID
                                        ) {
                                          const newObjectTree =
                                            obj.ObjectTypeTree.concat(
                                              entryInCurrentObject,
                                            ).sort((a: any, b: any) =>
                                              a.ObjectTypeName.localeCompare(
                                                b.ObjectTypeName,
                                                "en",
                                                { numeric: true },
                                              ),
                                            );
                                          newSelectedObjectType = {
                                            ...obj,
                                            ObjectTypeTree: newObjectTree,
                                          };
                                          return {
                                            ...obj,
                                            ObjectTypeTree: newObjectTree,
                                          };
                                        }
                                        if (obj.ObjectTypeID === selected) {
                                          const newObjectTree =
                                            obj.ObjectTypeTree.concat(
                                              entryInSelectedObject,
                                            ).sort((a: any, b: any) =>
                                              a.ObjectTypeName.localeCompare(
                                                b.ObjectTypeName,
                                                "en",
                                                { numeric: true },
                                              ),
                                            );
                                          return {
                                            ...obj,
                                            ObjectTypeTree: newObjectTree,
                                          };
                                        }
                                        return obj;
                                      });

                                    const response: any = await put(
                                      `settings/pages/${selectedObjectType.ObjectTypeID}/objecttype`,
                                      { ID: selected },
                                      auth,
                                    );

                                    if (
                                      response.status !== 200 ||
                                      !response.data
                                    ) {
                                      displayToast({
                                        status: "error",
                                        title: "Failed to add Relationship",
                                      });
                                    } else {
                                      setObjectTypes(newObjectTypes);
                                      setSelectedObjectType(
                                        newSelectedObjectType,
                                      );
                                      setObjectType(
                                        newSelectedObjectType.ObjectTypeID,
                                        newObjectTypes,
                                        customFields,
                                        coreFields,
                                      );
                                      displayToast({
                                        status: "success",
                                        title:
                                          "Relationship added successfully",
                                      });
                                    }
                                    setPageStatus("Ready");
                                  }}>
                                  {getObjectTypesForRelationship(
                                    objectTypes,
                                    selectedObjectType,
                                  ).map((object: any, index: any) => {
                                    if (
                                      object.ObjectTypeID !==
                                      selectedObjectType.ObjectTypeID
                                    )
                                      return (
                                        <Dropdown.Item
                                          eventKey={object.ObjectTypeID}
                                          key={
                                            index
                                          }>{`${object.ObjectTypeGroupName}:  ${object.ObjectTypeName}`}</Dropdown.Item>
                                      );
                                  })}
                                </DropdownButton>
                              </Col>
                            ),
                          },
                        ]
                      : [
                          { colProps: {}, children: <span /> },
                          { colProps: {}, children: <span /> },
                          {
                            colProps: { sm: "auto" },
                            children: (
                              <OverlayTrigger
                                placement="auto"
                                overlay={
                                  <Tooltip id="tooltip-not-authorised-to-edit">
                                    You do not have permission to edit Pages
                                  </Tooltip>
                                }>
                                <Button type="button" className="disabled">
                                  Add Relationship
                                </Button>
                              </OverlayTrigger>
                            ),
                          },
                        ]
                  }>
                  <Table borderless>
                    <tbody>
                      <Row>
                        <Col
                          sm="1"
                          style={{
                            color: "rgb(162, 170, 176)",
                            paddingBottom: "10px",
                          }}>
                          {" "}
                          Order{" "}
                        </Col>
                        <Col
                          sm="3"
                          style={{
                            color: "rgb(162, 170, 176)",
                            paddingBottom: "10px",
                          }}>
                          {" "}
                          Name{" "}
                        </Col>
                        <Col
                          sm="2"
                          style={{
                            color: "rgb(162, 170, 176)",
                            paddingBottom: "10px",
                          }}>
                          {" "}
                          Type{" "}
                        </Col>
                        <Col
                          sm="2"
                          style={{
                            color: "rgb(162, 170, 176)",
                            paddingBottom: "10px",
                          }}>
                          {" "}
                          Mandatory{" "}
                        </Col>
                        {selectedObjectType.ObjectTypeGroupID ===
                        ObjectTypeGroupID.Register ? (
                          <Col
                            sm="2"
                            style={{
                              color: "rgb(162, 170, 176)",
                              paddingBottom: "10px",
                            }}>
                            {" "}
                            Register View{" "}
                          </Col>
                        ) : null}
                        <Col
                          sm="2"
                          style={{
                            color: "rgb(162, 170, 176)",
                            paddingBottom: "10px",
                          }}>
                          {" "}
                          Action{" "}
                        </Col>
                      </Row>
                      {selectedObjectType &&
                      selectedObjectType.ObjectTypeTree &&
                      selectedObjectType.ObjectTypeTree.length !== 0
                        ? selectedObjectType.ObjectTypeTree.map(
                            (obj: any, index: number) => (
                              <Row>
                                <Col sm="1" style={{ padding: "10px" }}>
                                  {" "}
                                  {objectTypeCoreFields.length +
                                    (selectedObjectType.ObjectTypeGroupID ===
                                      ObjectTypeGroupID.Risk ||
                                    selectedObjectType.ObjectTypeGroupID ===
                                      ObjectTypeGroupID.Issue
                                      ? 2
                                      : 1) +
                                    objectTypeCustomFieldOrder.length +
                                    index}{" "}
                                </Col>
                                <Col sm="3" style={{ padding: "10px" }}>
                                  {" "}
                                  {obj.ObjectTypeName}{" "}
                                </Col>
                                <Col sm="2" style={{ padding: "10px" }}>
                                  {" "}
                                  Relationship: {obj.ObjectTypeGroupName}{" "}
                                </Col>
                                <Col
                                  sm="2"
                                  style={{ padding: "10px 0px 0px 40px" }}>
                                  <Icon2 color="rgb(162, 170, 176)" />
                                </Col>
                                {selectedObjectType.ObjectTypeGroupID ===
                                ObjectTypeGroupID.Register ? (
                                  <Col
                                    sm="2"
                                    style={{ padding: "10px 0px 0px 40px" }}>
                                    {" "}
                                    <Icon color="rgb(162, 170, 176)" />
                                  </Col>
                                ) : null}
                                <RemoveButton object={obj} />
                              </Row>
                            ),
                          )
                        : null}
                    </tbody>
                  </Table>
                </Card>
              ) : null}
            </>
          ) : null}
          <section></section>
        </>
      </LegacyScreenContainer>
    ) : (
      <>
        <div id="progressSpinner" style={{ marginTop: "20%" }}>
          <Loading size={"xl"} />
        </div>
      </>
    )
  ) : (
    <></>
  );
};

export type PageStatus = "Loading" | "Ready" | "Editing" | "Submitting";

export { ScreensPageFields };
