import {
  Tooltip,
  useToken,
  Card,
  CardHeader,
  CardBody,
  Heading,
  Flex,
  HStack,
  IconButton,
} from "@chakra-ui/react";
import Icon from "../../UI/Icon";

type Props = {
  children: React.ReactNode;
  title: string;
  onReload: () => void;
  helpText?: string;
  isRefetching?: boolean;
  isLoading?: boolean;
};

const DashboardWidget = ({
  children,
  title,
  helpText,
  isRefetching,
  isLoading,
  onReload,
}: Props) => {
  const helpIconColor = useToken("colors", "blackAlpha.600");
  return (
    <Card variant="outline" h="full">
      <CardHeader>
        <Flex w="full" justify="space-between">
          <Heading size="md">{title}</Heading>
          <HStack>
            <Tooltip shouldWrapChildren label="Reload">
              <IconButton
                isDisabled={isLoading}
                isLoading={isRefetching}
                variant="ghost"
                aria-label="Refresh widget"
                onClick={onReload}
                size="sm"
                icon={<Icon name="RefreshCw" />}
              />
            </Tooltip>
            {helpText && (
              <Tooltip shouldWrapChildren label={helpText}>
                <Icon color={helpIconColor} name="HelpCircle" />
              </Tooltip>
            )}
          </HStack>
        </Flex>
      </CardHeader>
      <CardBody>{children}</CardBody>
    </Card>
  );
};

export default DashboardWidget;
