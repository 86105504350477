import {
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text
} from "@chakra-ui/react";
import { useRef } from "react";

type Props = {
  sectionName: string;
  isOpen: boolean;
  onClose: () => void;
  onDelete: () => void;
};

export function ConfirmDeleteModal({ isOpen, onClose, onDelete, sectionName }: Props) {
    const initialFocusRef = useRef(null);
  return (
    <Modal initialFocusRef={initialFocusRef} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <chakra-scope>
        <ModalContent>
          <ModalHeader>Delete section?</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
           <Text>Are you sure you want to delete the manual section "{sectionName}"?</Text>
           <Text>All records will be unlinked, and the contents of this section will be lost.</Text>
          </ModalBody>
          <ModalFooter>
            <ButtonGroup>
              <Button ref={initialFocusRef} onClick={onClose} colorScheme="gray">
                Cancel
              </Button>
              <Button colorScheme="red" onClick={() => onDelete()}>Delete</Button>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </chakra-scope>
    </Modal>
  );
}
