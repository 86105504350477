import { add, sub } from "date-fns";

type BaseFilters = {
  statusName?: Status;
  statusId?: string;
  user?: { id: string; name: string };
  objectTypeName?: string;
  objectTypeId?: string;
};

export type Lifecycle =
  | "upcoming"
  | "openResponded"
  | "due"
  | "overdue"
  | "missed"
  | "closed";

export type Status = "positive" | "negative" | "neutral" | "closed";

export type TaskFilters = BaseFilters & {
  type: "tasks";
  lifecycle?: Lifecycle;
  dueBefore?: string;
  dueAfter?: string;
};

export type ActionFilters = BaseFilters & { type: "actions" };

export type RecordFilters = BaseFilters & {
  type: "records";
  recordType?: "issue" | "requirement" | "risk" | "register";
  objectTypeGroupName?: string;
  objectTypeGroupId?: string;
  subIssues?: boolean;
};

export const getDaterange = () => {
  const now = new Date();
  const from = sub(now, { days: 30 });
  const to = add(now, { days: 30 });
  return { from, to };
};

export type Filters = TaskFilters | ActionFilters | RecordFilters;

export type UserInfo = {
  id: string;
  name: string;
};

export type BaseWidgetProps = {
  title?: string;
  widgetID: string;
  dashboardID: string;
  users?: UserInfo[];
  setFilters: ((filters: Filters) => void) | ((prev: Filters) => Filters);
};
