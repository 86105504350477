import {
  Button, FormControl, FormHelperText, FormLabel, Heading, HStack, Input, Text, VStack
} from "@chakra-ui/react";
import {
  endOfDay,
  endOfMonth,
  format,
  startOfDay,
  startOfMonth,
  startOfYear,
  subMonths
} from "date-fns";
import { Formik } from "formik";
import React from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useAppState } from "../../components/App/AppProvider";
import { BreadcrumbElement } from "../../components/chakra/Breadcrumbs";
import { Card, CardContent, CardHeading } from "../../components/chakra/card";
import ChakraScreenContainer from "../../components/chakra/ChakraScreenContainer";
import { formatDateForNativePicker, NativeDatePickerFormat, userDateToUtcDate } from "../../utilities/dateUtils";
import NotFoundScreen from "../NotFound";

const today = () => new Date();

const currentMonth = () => ({
  startDate: formatDateForNativePicker(startOfMonth(today())),
  endDate: formatDateForNativePicker(today()),
});
const lastMonth = () => {
  const lastMonthDate = subMonths(today(), 1);
  return {
    startDate: formatDateForNativePicker(startOfMonth(lastMonthDate)),
    endDate: formatDateForNativePicker(endOfMonth(lastMonthDate)),
  };
};
const thisYear = () => ({
  startDate: formatDateForNativePicker(startOfYear(today())),
  endDate: formatDateForNativePicker(today()),
});

const commonPeriods = [
  {
    label: "Last Month",
    fn: lastMonth,
  },
  {
    label: "This Month",
    fn: currentMonth,
  },
  {
    label: "This Year",
    fn: thisYear,
  },
];

const BREADCRUMBS: BreadcrumbElement[] = [
  {
    label: "Home",
    link: "/",
  },
  {
    label: "Reports",
    link: "/report",
  },
];

const InitialValues = currentMonth();

const ValidationSchema = {
  startDate: Yup.date().required(),
  endDate: Yup.date().required(),
};

const TimeSheetReportScreen = () => {
  const navigate = useNavigate();
  const {app} = useAppState();
  const { permissions } = app;

  // If user can't see time notes or can't see reports, return 404
  if (!permissions.TIME_NOTES.READ.ALL || !permissions.REPORTS.READ.ALL) {
    return <NotFoundScreen />
  }

  return (
    <chakra-scope>
      <ChakraScreenContainer
        contentSize="container.lg"
        pageTitle="Time Sheet Report Configuration"
        breadcrumbs={BREADCRUMBS}>
        <Card>
          <CardHeading title="Configure Report" />
          <CardContent>
            <Formik
              onSubmit={(values) => {
                // Get the start and end exact times, then convert to utc
                const utcStart = userDateToUtcDate(startOfDay(new Date(values.startDate)));
                const utcEnd = userDateToUtcDate(endOfDay(new Date (values.endDate)));
                const start = encodeURIComponent(
                  utcStart.toISOString(),
                );
                const end = encodeURIComponent(
                  utcEnd.toISOString(),
                );
                navigate(
                  `/report/easy/time-sheet/generated?display=reports&startDateUtc=${start}&endDateUtc=${end}`,
                );
              }}
              initialValues={InitialValues}
              validationSchema={ValidationSchema}
              render={({
                values,
                handleChange,
                handleSubmit,
                setFieldValue,
              }) => (
                <VStack align="flex-start" spacing={6}>
                  <Text>
                    Select a time period or input a custom start and end date
                    below
                  </Text>
                  <VStack w="full" align="start" spacing={2}>
                    <Heading size="sm">Common periods</Heading>
                    <HStack>
                      {commonPeriods.map(({ label, fn }) => (
                        <Button
                          key={label}
                          variant="outline"
                          onClick={() => {
                            const { startDate, endDate } = fn();
                            setFieldValue("startDate", startDate);
                            setFieldValue("endDate", endDate);
                          }}>
                          {label}
                        </Button>
                      ))}
                    </HStack>
                  </VStack>
                  <HStack w="full">
                    <FormControl>
                      <FormLabel>Start Date</FormLabel>
                      <Input
                        id="startDate"
                        value={values.startDate}
                        onChange={handleChange}
                        type="date"
                      />
                      <FormHelperText>Inclusive</FormHelperText>
                    </FormControl>
                    <FormControl>
                      <FormLabel>End Date</FormLabel>
                      <Input
                        id="endDate"
                        value={values.endDate}
                        onChange={handleChange}
                        type="date"
                      />
                      <FormHelperText>Inclusive</FormHelperText>
                    </FormControl>
                  </HStack>
                  <Button onClick={() => handleSubmit()}>
                    Generate Report
                  </Button>
                </VStack>
              )}
            />
          </CardContent>
        </Card>
      </ChakraScreenContainer>
    </chakra-scope>
  );
};

export default TimeSheetReportScreen;
