import {
  Box,
  Button,
  HStack,
  Input,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  Tag,
  VStack
} from "@chakra-ui/react";
import { nanoid } from "nanoid";
import { useMemo, useState } from "react";
import {
  FilterableFieldSelection,
  StatusSelectOptions,
  WorkingFilter
} from "../../../../constants/frontEndFilterHelpers";
import { List } from "../../../../shared/v2/definitions/lists";
import { Filter } from "../../../../shared/v2/helpers/filters/baseFilterTypes";
import { FilterOperationsType } from "../../../../shared/v2/helpers/filters/filterSchemas";
import Select from "../../../Select";
import ObjectStatusBadge from "../../../UI/ObjectStatusBadge";
import FilterOpSelect from "./FilterOpSelect";
import UserSelect from "./UserSelect";

type Props = {
  filterableFields: FilterableFieldSelection[];
  lists: Record<string, List | null>;
  addFilter: (filter: Filter) => void;
};

export default function AddFilterPopoverContent({
  filterableFields,
  lists,
  addFilter,
}: Props) {
  const [field, setField] = useState<FilterableFieldSelection>();
  const [op, setOp] = useState<FilterOperationsType>();
  const [inputValue, setInputValue] = useState<string | number>("");
  const [listValue, setListValue] = useState<
    {
      value: string;
      label: string;
    }[]
  >([]);

  const operationOptions = useMemo(() => {
    if (!field) return [];
    return field.availableOps;
  }, [field]);

  const getCurrentValue = ():
    | {
        label: string;
        value: string | number;
      }
    | {
        label: string;
        value: string | number;
      }[]
    | undefined => {
    if (!op) return undefined;
    switch (op.valueType) {
      case "string":
      case "number":
        return {
          label: `${inputValue}`,
          value: inputValue,
        };
      case "objectStatusID":
      case "list":
      case "userID":
        return listValue;
      default:
        return undefined;
    }
  };

  const renderValueDisplay = () => {
    if (!op || !field) {
      return null;
    }
    switch (op.valueType) {
      case "string":
        return (
          <Input
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
          />
        );
      case "number":
        return (
          <NumberInput value={inputValue} onChange={(e) => setInputValue(+e)}>
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        );
      case "objectStatusID":
        return (
          <Select
            multiselect
            w="full"
            options={StatusSelectOptions}
            value={listValue}
            onChange={(newVal) => setListValue(newVal)}
            renderOptionWithWrapper
            renderOption={(renderProps) => (
              <ObjectStatusBadge statusID={`${renderProps.option.value}`} />
            )}
          />
        );
      case "list": {
        const { listID } = field;
        const list = !!listID && lists[listID];
        if (!list) {
          return null;
        }
        const opts: {
          value: string;
          label: string;
        }[] = list.options.map((opt) => ({
          value: opt.id,
          label: `${opt.value}`,
        }));
        return (
          <Select
            multiselect
            value={listValue}
            options={opts}
            onChange={(newVal) => setListValue(newVal)}
            w="full"
          />
        );
      }
      case "userID":
        return <UserSelect selected={listValue} onChange={setListValue} />;
      // Todo: rest of the other options
      default:
        return null;
    }
  };

  const handleSubmitFilter = () => {
    if (!field || !op) return;
    const value = getCurrentValue();
    const workingFilter: WorkingFilter = {
      ID: nanoid(8),
      field: { ...field },
      op: { ...op },
      value,
    };
    // Clear current state
    setField(undefined);
    setOp(undefined);
    setInputValue("");
    setListValue([]);
    addFilter(workingFilter);
  };

  return (
    <PopoverContent w="max-content" ml="4">
      <PopoverArrow />
      <PopoverCloseButton />
      <PopoverHeader>Filter</PopoverHeader>
      <PopoverBody>
        <VStack
          align="start"
          w="full"
          as="form"
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmitFilter();
          }}>
          <Box minW="64" w="full">
            <Select
              w="full"
              singleOptionsDisplayMode="radio"
              placeholderText="Field"
              options={filterableFields}
              value={field}
              onChange={(newVal) => {
                // Maybe change op
                let didUpdateOp = false;
                if (!newVal) {
                  setOp(undefined);
                  didUpdateOp = true;
                } else if (!newVal.availableOps.includes(op)) {
                  // Have to update op
                  setOp(newVal.availableOps[0]);
                  didUpdateOp = true;
                }
                if (didUpdateOp) {
                  setListValue([]);
                  setInputValue("");
                }
                setField(newVal);
              }}
              renderOptionWithWrapper
              renderOption={(renderProps) => (
                <HStack as="span" flexWrap="wrap">
                  <span>{renderProps.option.label}</span>
                  {renderProps.option.identifier.type === "custom" ? (
                    <Tag size="sm" colorScheme="brand">
                      custom
                    </Tag>
                  ) : (
                    <Tag size="sm" colorScheme="gray">
                      core
                    </Tag>
                  )}
                </HStack>
              )}
            />
          </Box>
          <Box minW="64" w="full">
            <FilterOpSelect
              operationOptions={operationOptions}
              onChange={setOp}
              value={op}
            />
          </Box>
          <Box minW="64" w="full">
            {renderValueDisplay()}
          </Box>
        </VStack>
      </PopoverBody>
      <PopoverFooter display="flex" justifyContent="end">
        <Button colorScheme="brand" onClick={handleSubmitFilter}>
          Add Filter
        </Button>
      </PopoverFooter>
    </PopoverContent>
  );
}
