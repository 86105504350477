import { useEffect, useState } from "react";
import moment from "moment";
import DatePicker from "react-datepicker";
import { get } from "../../../utilities/request";
import "react-datepicker/dist/react-datepicker.css";
import { getDefaultWorkDays } from "../../../screens/Settings/AccountCalendar";

interface DateFieldProps {
  id?: any;
  selected: any;
  name: string;
  onUpdate: any;
  readOnly?: boolean;
  disabled?: boolean;
  min?: Date;
  max?: Date;
  className?: string;
  allDates: boolean;
  unit?: string;
}

const formatDateFromDBToControl = (dateStr: string | null): any =>
  dateStr && moment(dateStr).isValid()
    ? moment.utc(dateStr).local().toDate()
    : "";

export const formatDateFromDBToReadOnly = (dateStr: string | null): string => {
  return dateStr && moment(dateStr).isValid()
    ? moment.utc(dateStr).local().format("DD-MMM-YYYY")
    : "";
};

export const DateField = ({ allDates, ...props }: DateFieldProps) => {
  const [workDays, setWorkDays] = useState<any>([]);
  const [excludedDates, setExcludedDates] = useState<any>([]);
  const formatExcludedDates = (date: any) => {
    if (allDates) {
      return true;
    }
    const currentDate = moment.utc(date);
    const currentDateName = moment(date).format("dddd");

    const excludedDate = excludedDates.find((exDate: any) =>
      currentDate.isBetween(exDate.From, exDate.To, "days", "[]"),
    );

    const selectedWorkDays = workDays.find(
      (workingDay: any) => workingDay.name === currentDateName,
    );
    return !excludedDate && selectedWorkDays;
  };

  useEffect(() => {
    const fetchAvailableDates = async () => {
      try {
        const request: any = await get("settings/accountcal");
        const { workDays } = request.data;
        const excludedDates = request.data.excludedDates
          ? request.data.excludedDates.map((date: any) => ({
              To: date.AccountExcludedDateTo,
              From: date.AccountExcludedDateFrom,
              Description: date.AccountExcludedDateDescription,
              ID: date.AccountExcludedDateID,
            }))
          : [];
        if (!workDays) {
          const defaultWorkDays: any[] = getDefaultWorkDays();
          setWorkDays(defaultWorkDays);
          setExcludedDates(excludedDates);
        } else {
          setWorkDays(workDays);
          setExcludedDates(excludedDates);
        }
      } catch (ex) {
        console.log(ex);
      }
    };

    if (workDays.length === 0 && !allDates) {
      fetchAvailableDates();
    }
  }, [workDays, setWorkDays, setExcludedDates, allDates]);
  return (
    <>
      {!props.readOnly ? (
        <DatePicker
          selected={formatDateFromDBToControl(props.selected)}
          name={props.name}
          onChange={props.onUpdate}
          disabled={props.disabled}
          dateFormat={["dd-MMM-yyyy", "dd/MM/yyyy"]}
          minDate={
            props.min ? props.min : moment().subtract(100, "years").toDate()
          }
          maxDate={props.max ? props.max : moment().add(100, "years").toDate()}
          className={props.className ? props.className : "form-control"}
          autoComplete="off"
          filterDate={formatExcludedDates}
        />
      ) : (
        formatDateFromDBToReadOnly(props.selected)
      )}
      {props.unit ? (
        <span className="col-form-label form-unit">{props.unit}</span>
      ) : null}
    </>
  );
};
