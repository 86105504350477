import { Box, Center, Text, VStack } from "@chakra-ui/react";
import React from "react";
import Spinner from "./common/Spinner";

type Props = {
  size?: "sm" | "md" | "lg" | "xl";
  fillScreen?: boolean;
  text?: string;
};
const FullLoader = ({ size, fillScreen, text }: Props) => (
  <chakra-scope>
    <Box h={fillScreen ? "100vh" : "full"} w={fillScreen ? "100vw" : "full"}>
      <Center h="full" w="full">
        <VStack>
          <Spinner size={size ?? "xl"} />
          {text && <Text>{text}</Text>}
        </VStack>
      </Center>
    </Box>
  </chakra-scope>
);
export default FullLoader;
