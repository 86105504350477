const CodeRequirement = 'REQUIREMENT'
const CodeUserSettings = 'USER_SETTINGS'
const CodeAccountSettings = 'ACCOUNT_SETTINGS'

const CodeIssue = 'ISSUE'
const CodeAction = 'ACTION'
const CodeRisk = 'RISK'
const CodeAdminAccounts = 'ADMIN_ACCOUNTS'
const CodeJob = 'JOB'
const CodeRegister = 'REGISTER'
const CodeNotes = 'NOTES'
const CodeTimeNotes = 'TIME_NOTES'
const CodeReports = 'REPORTS'
const CodeManual = 'MANUAL'

const TypeCreate = 'CREATE'
const TypeRead = 'READ'
const TypeUpdate = 'UPDATE'
const TypeDelete = 'DELETE'

const ScopeAll = 'ALL'
const ScopeSelf = 'SELF'
const PermissionTypes = [TypeCreate, TypeRead, TypeUpdate, TypeDelete]
const PermissionCodes = [CodeRequirement, CodeUserSettings, CodeAccountSettings, CodeIssue, CodeAction, CodeRisk, CodeAdminAccounts, CodeJob, CodeRegister, CodeNotes, CodeTimeNotes, CodeReports, CodeManual]

export { CodeRequirement, CodeUserSettings, CodeAccountSettings, CodeAdminAccounts, CodeIssue, CodeRegister, CodeAction, CodeNotes, CodeTimeNotes, CodeReports, TypeCreate, TypeRead, TypeUpdate, TypeDelete, ScopeAll, ScopeSelf, CodeRisk, PermissionTypes, CodeJob , PermissionCodes}
