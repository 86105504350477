import {
  Box,
  Button,
  ContainerProps,
  Divider,
  Heading,
  Icon,
  useBreakpointValue,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import {
  RiAdminLine,
  RiCalendar2Line,
  RiFileUserLine,
  RiGroupLine,
  RiInputMethodLine,
  RiLayoutGridLine,
  RiListUnordered,
  RiPagesLine,
  RiUser3Line,
} from "react-icons/ri";
import { Link as BrowserLink } from "react-router-dom";
import ChakraScreenContainer from "../../../components/chakra/ChakraScreenContainer";
import ConfirmEnterTemplateManagementDialog from "../../../components/chakra/ConfirmEnterTemplateManagementDialog";
import { TemplateManagementIcon } from "../../../constants/commonIcons";

type Props = {
  pageTitle: string;
  children: React.ReactNode;
  headerEndContent?: React.ReactNode;
  contentSize?: ContainerProps["maxW"];
};

export default function SettingsScreenContainer({
  pageTitle,
  children,
  headerEndContent,
  contentSize,
}: Props) {
  const showSidebar = useBreakpointValue({ base: false, md: true });
  const showEndPadding = useBreakpointValue({ base: false, "2xl": true });
  const templateManagementDialogState = useDisclosure();
  const handleEnterTemplateManagement = () => {
    window.location.href = "/template";
  };
  return (
    <>
      {showSidebar && (
        <Box
          key="settings-sidebar"
          w="64"
          position="fixed"
          pt="top-bar"
          zIndex={3}
          top={0}
          left={0}
          h="100vh">
          <chakra-scope>
            <ConfirmEnterTemplateManagementDialog
              state={templateManagementDialogState}
              onConfirm={handleEnterTemplateManagement}
            />
            <VStack pl="2" pt="4" w="full" align="start" spacing="2">
              <Button
                as={BrowserLink}
                to="/settings/profile"
                leftIcon={<Icon as={RiUser3Line} />}
                size="sm"
                colorScheme="gray"
                justifyContent="start"
                variant="ghost"
                w="full">
                Your Profile
              </Button>
              <Divider />
              <Heading
                pl="3"
                size="xs"
                fontSize="xs"
                color="blackAlpha.700"
                fontWeight="bold"
                textTransform="uppercase"
                letterSpacing="wider">
                Account Settings
              </Heading>
              <Button
                as={BrowserLink}
                to="/settings/account"
                leftIcon={<Icon as={RiAdminLine} />}
                size="sm"
                colorScheme="gray"
                justifyContent="start"
                variant="ghost"
                w="full">
                Account & Calendar
              </Button>

              <Button
                as={BrowserLink}
                to="/settings/users"
                leftIcon={<Icon as={RiGroupLine} />}
                size="sm"
                colorScheme="gray"
                justifyContent="start"
                variant="ghost"
                w="full">
                Users
              </Button>
              <Button
                as={BrowserLink}
                to="/settings/roles"
                leftIcon={<Icon as={RiFileUserLine} />}
                size="sm"
                colorScheme="gray"
                justifyContent="start"
                variant="ghost"
                w="full">
                Roles
              </Button>
              <Divider />
              <Heading
                pl="3"
                size="xs"
                fontSize="xs"
                color="blackAlpha.700"
                fontWeight="bold"
                textTransform="uppercase"
                letterSpacing="wider">
                System Config
              </Heading>
              <Button
                as={BrowserLink}
                to="/settings/pages"
                leftIcon={<Icon as={RiPagesLine} />}
                size="sm"
                colorScheme="gray"
                justifyContent="start"
                variant="ghost"
                w="full">
                Pages
              </Button>
              <Button
                as={BrowserLink}
                to="/settings/customfields"
                leftIcon={<Icon as={RiInputMethodLine} />}
                size="sm"
                colorScheme="gray"
                justifyContent="start"
                variant="ghost"
                w="full">
                Custom Fields
              </Button>
              <Button
                as={BrowserLink}
                to="/settings/lists"
                leftIcon={<Icon as={RiListUnordered} />}
                size="sm"
                colorScheme="gray"
                justifyContent="start"
                variant="ghost"
                w="full">
                Lists
              </Button>
              <Button
                as={BrowserLink}
                to="/settings/riskmatrix"
                leftIcon={<Icon as={RiLayoutGridLine} />}
                size="sm"
                colorScheme="gray"
                justifyContent="start"
                variant="ghost"
                w="full">
                Risk Matrix
              </Button>
              <Button
                onClick={templateManagementDialogState.onOpen}
                leftIcon={<Icon as={TemplateManagementIcon} />}
                size="sm"
                colorScheme="gray"
                justifyContent="start"
                variant="ghost"
                w="full">
                Template Management
              </Button>
            </VStack>
          </chakra-scope>
        </Box>
      )}
      <Box w="full" pl={showSidebar ? 64 : 0} pr={showEndPadding ? 64 : 0}>
        <ChakraScreenContainer
          headerEndContent={headerEndContent}
          pageTitle={pageTitle}
          contentSize={contentSize ?? "container.lg"}>
          {children}
        </ChakraScreenContainer>
      </Box>
    </>
  );
}
