import { Formik, yupToFormErrors } from "formik";
import moment from "moment";
import * as momenttz from "moment-timezone";
import { ReactNode, useEffect, useState } from "react";
import {
  Button,
  Card as Card2,
  Col,
  Collapse,
  Dropdown,
  DropdownButton,
  Form,
  OverlayTrigger,
  Row,
  Table,
  Tooltip,
} from "react-bootstrap";
import { Copy, Edit2, Frown } from "react-feather";
// eslint-disable-next-line
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import * as Yup from "yup";
import { useAuthState, useAppState } from "../../components/App/AppProvider";
import * as LocalStorage from "../../components/App/localStorage";
import { CustomFieldList } from "../../components/Forms/CustomFields/CustomField";
import { DateField } from "../../components/Forms/Field/Date";
import { MultiSelect } from "../../components/Forms/Field/MultiSelect";
import { Number } from "../../components/Forms/Field/Number";
import { Select } from "../../components/Forms/Field/Select";
import { Text } from "../../components/Forms/Field/Text";
import { Textarea } from "../../components/Forms/Field/Textarea";
import { HelpText } from "../../components/Forms/HelpText/HelpText";
import { MandatoryIndicator } from "../../components/Forms/MandatoryIndicator/MandatoryIndicator";
import { QuestionWrapper } from "../../components/Forms/QuestionWrapper/QuestionWrapper";
import { Card } from "../../components/UI/Card/Card";
import { CollapseChevron } from "../../components/UI/CollapseChevron/CollapseChevron";
import { Dialog } from "../../components/UI/Dialog/Dialog";
import { DocumentGenerator } from "../../components/UI/DocumentGenerator/DocumentGenerator";
import { TemplateDocumentGenerator } from "../../components/UI/DocumentGenerator/TemplatedDocumentGenerator";
import { History } from "../../components/UI/History/History";
import { Loading } from "../../components/UI/Loading/Loading";
import { Notes } from "../../components/UI/Notes/Notes";
import { NotesIcons } from "../../components/UI/Notes/NotesIcons";
import { LifeCycle } from "../../components/UI/Status/LifeCycle";
import {
  objectStatusToVariant,
  Status,
} from "../../components/UI/Status/Status";
import { checkStoredValues } from "../../constants/storedValuesCheck";
import { urlRegex } from "../../constants/urlRegex";
import * as ActionRecurrence from "../../shared/constants/actionRecurrence";
import * as NoteType from "../../shared/constants/noteTypes";
import * as ObjectStatus from "../../shared/constants/objectStatus";
import * as Permissions from "../../shared/constants/permission";
import * as QuestionType from "../../shared/constants/questionType";
import { Note } from "../../shared/definitions/notes";
import * as Access from "../../utilities/access";
import useToast from "../../utilities/useToast";
import { formatDateTime, validateDate } from "../../utilities/formatDate";
import { del, get, post, put } from "../../utilities/request";
import { toProperCase } from "../../utilities/strings";
import { getUrlSearchParam } from "../../utilities/url";
import { ActionPageData, ActionPageStatus } from "./Action.d";
import LegacyScreenContainer from "../../components/UI/LegacyScreenContainer";

const timezones = momenttz.tz.names();

const validationSchema = Yup.object().shape({
  ObjectTypeID: Yup.string().required("Action type is required."),
  ParentIDs: Yup.array().when(
    "$isTemplate",
    (isTemplate: boolean, schema: any) =>
      !isTemplate ? schema.min(1, "Parent is required") : schema,
  ),
  ActionName: Yup.string().required("Name is required."),
  ActionStartTs: Yup.mixed().when(
    "$isTemplate",
    (isTemplate: boolean, schema: any) =>
      !isTemplate ? schema.required("First task date is required.") : schema,
  ),
  ActionRecurrenceID: Yup.string()
    .nullable(true)
    .when("$recurrenceCheck", (recurrenceCheck: boolean, schema: any) =>
      recurrenceCheck ? schema.required("Recurrence is required.") : schema,
    ),
  ActionResponsibleUsers: Yup.array().min(
    1,
    "Must Select at Least 1 Responsible User",
  ),
  RecordStatusID: Yup.string(),
  TemplateActionID: Yup.string().when("RecordStatusID", {
    is: "Template",
    then: Yup.string().required("Template selection is required."),
  }),
  CustomFields: Yup.array().of(
    Yup.object()
      .shape({})
      .when("$isTemplate", (isTemplate: boolean, schema: any) =>
        isTemplate
          ? schema
          : schema.test({
              name: "validateCustomFields",
              test: (value: any) =>
                !value.IsMandatory ||
                (value.Value !== null &&
                  value.Value !== "" &&
                  value.Value.length > 0),
              message: ({ value }: any) =>
                `'${value.Label}' is a mandatory custom field.`,
              exclusive: false,
            }),
      )
      .when(
        "CustomFieldTypeColumnName",
        (CustomFieldTypeColumnName: string, schema: any) =>
          schema.test({
            name: "validateCustomFieldURL",
            test: (value: any) => {
              if (
                value.CustomFieldTypeColumnName === "URL" &&
                value.Value !== "http://" &&
                value.Value !== "https://" &&
                value.Value !== ""
              ) {
                return urlRegex.test(value.Value);
              }
              return true;
            },
            message: ({ value }: any) =>
              `'${value.Label}' url fields must be valid urls`,
            exclusive: false,
          }),
      ),
  ),
  Questions: Yup.array()
    .of(
      Yup.object().shape({
        QuestionText: Yup.string().required("Question text is required."),
        Responses: Yup.array().when("QuestionTypeID", {
          is: (QuestionTypeID) =>
            QuestionType.RiskControls ||
            QuestionType.AlertRightNow ||
            QuestionType.SectionBreak,
          then: Yup.array(),
          otherwise: Yup.array().when("QuestionTypeID", {
            is: QuestionType.SingleSelect,
            then: Yup.array()
              .of(
                Yup.object().shape({
                  QuestionElementText: Yup.string().required(
                    "Question response text is required.",
                  ),
                }),
              )
              .min(1)
              .required("At least one response per question is required."),
            otherwise: Yup.array()
              .min(1)
              .required("At least one response per question is required."),
          }),
        }),
      }),
    )
    .test({
      name: "validateQuestions",
      test: (value) => !!value.find((question: any) => question.QuestionIsUber),
      message:
        "At least one single select question marked as Master is required.",
      exclusive: false,
    }),
});

const checkParentRelationship = (
  ObjectTypeID: any,
  relationships: any[],
  parentType: any,
  type: any,
  template?: boolean,
) => {
  let check = false;
  relationships.forEach((el: any) => {
    if (
      el.ObjectTypeID === ObjectTypeID &&
      el.ObjectTypeGroupID === parentType &&
      el.ObjectTypeRelationshipParent === type
    ) {
      check = true;
    } else if (
      el.ObjectTypeID === ObjectTypeID &&
      (el.ObjectTypeGroupID === parentType ||
        el.ObjectTypeRelationshipParent === type) &&
      template
    ) {
      check = true;
    }
  });
  return check;
};

const getFirstActionDateMin = (
  pageStatus: string,
  lastRespondedActionDate: any,
  isCopyMode: boolean,
) => {
  if (
    (pageStatus === "Editing" || pageStatus === "New") &&
    lastRespondedActionDate.InstanceWhenTs
  ) {
    return moment(lastRespondedActionDate.InstanceWhenTs)
      .add(1, "day")
      .toDate();
  }
  if (pageStatus === "Editing" || pageStatus === "New" || isCopyMode) {
    return moment().toDate();
  }
  return undefined;
};

const getRequirements = (
  list: any[],
  actionType: any,
  relationships?: any[],
  template?: boolean,
) => {
  const sortedList = list.sort((a: any, b: any) => {
    const aID = a.ParentIDs.length > 0 ? a.ParentIDs[0] : a.RequirementID;
    const bID = b.ParentIDs.length > 0 ? b.ParentIDs[0] : b.RequirementID;
    if (aID !== bID) {
      return aID.localeCompare(bID);
    }
    return a.ParentIDs.length - b.ParentIDs.length;
  });
  const finalParents: any[] = [];
  if (relationships) {
    sortedList.forEach((req: any) => {
      if (
        checkParentRelationship(
          actionType,
          relationships,
          req.ObjectTypeGroupID,
          req.ParentIDs.length <= 0 ? 1 : 0,
          template,
        )
      ) {
        finalParents.push(req);
      }
    });
    return finalParents;
  }
  return sortedList;
};

const renderQuestionsTemplate = (
  data: ActionPageData,
  choice: string,
  id: any,
) => {
  if (data != null) {
    let questions = [];
    if (choice === "Template" && id !== "" && data.TemplateData !== undefined) {
      questions = data.TemplateData.find(
        (template: any) => template.ActionID === id,
      ).Questions;
    } else {
      questions = data.Questions;
    }
    return questions;
  }
};

const restrictQuestionTypes = (
  questionTypes: any[],
  riskQuestionsSupported: boolean,
): any[] => {
  if (riskQuestionsSupported) {
    return questionTypes;
  }
  return questionTypes.filter(
    (questionType: any) =>
      questionType.QuestionTypeID !== QuestionType.InherentRisk &&
      questionType.QuestionTypeID !== QuestionType.ResidualRisk &&
      questionType.QuestionTypeID !== QuestionType.RiskControls,
  );
};

const TemplateOptions = (
  templates: any[],
  setValues: any,
  templateID: any,
): ReactNode =>
  templates.map((template: any) => (
    <Form.Group as={Row} style={{ alignItems: "center" }}>
      <Form.Check
        id={template.ActionID}
        type="radio"
        checked={templateID === template.ActionID}
        onChange={(event: any) => {
          if (event.target.checked) {
            setValues("TemplateActionID", event.target.id);
          }
        }}
        label={template.ActionName}
      />
      <span style={{ color: "#a2aab0" }}>&nbsp;&nbsp;&nbsp;</span>
      <HelpText id={template.ActionID} text={template.ActionDescription} />
    </Form.Group>
  ));

const checkRelationship = (
  ObjectTypes: any[],
  userPermissions: any,
  parents?: any[],
  relationships?: any[],
  Requirements?: any[],
) => {
  if (Requirements && parents && parents.length > 0) {
    const newObjTypes: any[] = [];
    const type =
      Requirements.find(
        (requirement: any) => requirement.RequirementID === parents[0].ParentID,
      ).ParentIDs.length > 0
        ? 0
        : 1;
    const parentType = Requirements.find(
      (requirement: any) => requirement.RequirementID === parents[0].ParentID,
    ).ObjectTypeGroupID;
    ObjectTypes.forEach((obj: any) => {
      let check = false;
      if (relationships) {
        relationships.forEach((el: any) => {
          if (
            el.ObjectTypeID === obj.ObjectTypeID &&
            el.ObjectTypeGroupID === parentType &&
            el.ObjectTypeRelationshipParent === type
          ) {
            check = true;
          }
        });
      }
      if (
        check &&
        Access.checkAccess(
          userPermissions,
          Access.objectTypeGroupIDToName(obj.ObjectTypeGroupID),
          "CREATE",
          false,
          obj.ObjectTypeID,
        )
      ) {
        newObjTypes.push(obj);
      }
    });
    return newObjTypes;
  }
  return ObjectTypes;
};

const getHeaderBreadCrumbs = (
  parentID: string,
  pageStatus: string,
  data: any,
  display: string,
  templateType: boolean,
  actionID: string,
  location: Location,
) => {
  let itemName = "";
  if (pageStatus !== "New") {
    itemName = data.ActionName;
  } else {
    itemName = `New ${
      data.ObjectTypes.find((o: any) => o.ObjectTypeID === data.ObjectTypeID)
        .ObjectTypeName
    }`;
  }

  if (parentID) {
    let baseLink = {};
    if (location.search.indexOf("action") >= 0) {
      baseLink = {
        label: "Actions Dashboard",
        link: "/action?display=actions",
      };
    } else {
      baseLink = {
        label: toProperCase(`${data.ParentObjectTypeGroup}s Dashboard`),
        link: `/${data.ParentObjectTypeGroup}?display=${data.ParentObjectTypeGroup}`,
      };
    }

    return getBreadcrumbs(
      itemName,
      data.ParentObjectTypeGroup,
      parentID,
      data.Requirements,
      baseLink,
      display,
      templateType,
    );
  }
  let baseLink = {};
  if (actionID.indexOf("&") < 0 || location.search.indexOf("action") >= 0) {
    baseLink = { label: "Actions Dashboard", link: "/action?display=actions" };
  } else {
    baseLink = {};
  }

  return getBreadcrumbs(
    itemName,
    "",
    data.ParentObjectTypeGroup,
    data.Requirements,
    baseLink,
    display,
    templateType,
  );
};

const getBreadcrumbs = (
  itemName: string,
  itemLink: string,
  parentID: string,
  parentlist: any[],
  baseLink: Object,
  display: string,
  template?: boolean,
): any => {
  if (parentID) {
    const breadcrumb = [];
    let breadFlag = false;
    let nextParentID: string = parentID;

    // End link of item
    // breadcrumb.push({ label: itemName });

    const filterFunction = (item: any) => item.RequirementID === nextParentID;
    while (breadFlag === false) {
      const item = parentlist.find(filterFunction);
      if (item) {
        breadcrumb.push({
          label: item.RequirementName,
          link: `${template ? "/template/" : "/"}${itemLink}/${
            item.RequirementID
          }${display}`,
        });
        if (item.ParentIDs[0] !== undefined) {
          nextParentID = item.ParentIDs[0];
        } else {
          breadFlag = true;
        }
      } else {
        breadFlag = true;
      }
    }

    // Base link of type
    if (!template) {
      breadcrumb.push(baseLink);
    }
    return breadcrumb.reverse();
  }
  if (parentID === "") {
    const breadcrumbs = template ? [] : [baseLink];
    return breadcrumbs;
  }
  const breadcrumbs = template
    ? []
    : itemLink && itemLink.length >= 1
    ? [
        {
          label: `${toProperCase(itemLink)}s`,
          link: `/${itemLink}?display=${itemLink}`,
        },
      ]
    : [];
  return breadcrumbs;
};

const ScreensAction = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const authState = useAuthState();
  const { app } = useAppState();
  const [savedData, setSavedData] = useState(null);
  const [originalData, setOriginalData] = useState<ActionPageData>(null);
  const { displayToast } = useToast();
  const [pageStatus, setPageStatus] = useState<ActionPageStatus>("Loading");
  const [data, setData] = useState<ActionPageData>(null);
  const [parentID, setparentID] = useState<string>("");
  const templateType = window.location.href.indexOf("template") > -1;
  const [display, setDisplay] = useState<string>("");
  const [isCopyMode, setCopyMode] = useState(false);
  // const [files, setFiles]= useState<any>({fileList:[]})
  const [notes, setNotes] = useState({ isVisible: false, noteType: "" });
  const [subscribedRecordsDialogVisible, setSubscribedRecordsDialogVisible] =
    useState<boolean>(false);

  const actionID = params.actionID || "";

  const deleteAction = async (data: any) => {
    const setDeleteMessage = (success: boolean) => {
      displayToast({
        status: success ? "success" : "error",
        title: success
          ? `${data.ObjectTypeName} deleted successfully`
          : `${data.ObjectTypeName} delete failed`,
      });
    };

    const redirect = () => {
      if (templateType && data.ParentIDs.length < 1) {
        navigate(
          {
            pathname: `/template/action?${data.ObjectTypeName.toLowerCase().replace(
              /\s+/g,
              "",
            )}`,
            search: display,
          },
          {
            state: { hasChanged: true },
          },
        );
      } else if (location.search.includes("&parentid=")) {
        const parentID = getUrlSearchParam(location.search, "parentid");
        navigate(
          {
            pathname: `${
              templateType ? "/template" : ""
            }/${data.Requirements.find(
              (requirement: any) => requirement.RequirementID === parentID,
            ).ObjectTypeGroupName.toLowerCase()}/${parentID}`,
            search: display,
          },
          {
            state: { hasChanged: true },
          },
        );
      } else if (templateType) {
        navigate(
          {
            pathname: `/template`,
            search: display,
          },
          {
            state: { hasChanged: true },
          },
        );
      } else {
        navigate(
          {
            pathname: `/action`,
            search: display,
          },
          {
            state: { hasChanged: true },
          },
        );
      }
    };

    // remove the local storage after deletion
    window.localStorage.removeItem(`${window.location.href}`);

    const deleteResults = await del(
      `action/${actionID}${templateType ? "/template" : ""}${location.search}`,
      authState,
    );
    setDeleteMessage(deleteResults.status === 200);

    window.setTimeout(redirect, 3000);
  };

  const closeAction = async (data: any, close: boolean) => {
    try {
      const result = await post(
        `action/${actionID}`,
        {
          ...data,
          ObjectStatusID: close ? ObjectStatus.Closed : ObjectStatus.Neutral,
        },
        authState,
      );
      setData({
        ...data,
        ObjectStatusID: close ? ObjectStatus.Closed : ObjectStatus.Neutral,
        ObjectStatusName: close ? "Closed" : "Neutral",
        Instances: result.data.Instances,
      });
      displayToast({
        status: "success",
        title: `${data.ObjectTypeName} ${
          close ? "closed" : "re-opened"
        } successfully`,
      });
      setPageStatus("Ready");
    } catch (err) {
      displayToast({
        status: "error",
        title: `Failed to ${close ? "close" : "re-open"} ${
          data.ObjectTypeName
        }`,
      });
      setPageStatus("Editing");
    }
  };

  const getLastRespondedActionDate = (data: any) =>
    data.Instances.reduce(
      (accumulator: any, instance: any) =>
        instance.InstanceMinViableResponse === 1 ? instance : accumulator,
      {},
    );

  const lastRespondedActionDate = templateType
    ? {}
    : data
    ? getLastRespondedActionDate(data)
    : {};

  // collapse
  const [isDetailsCollapsed, updateDetailsCollapsed] = useState<boolean>(false);

  useEffect(() => {
    const fetchAction = async () => {
      setPageStatus("Loading");
      const result = await get(
        `action/${actionID}${templateType ? "/template" : ""}${
          location.search
        }`,
      );
      setparentID(
        location.search.includes("&parentid=")
          ? getUrlSearchParam(location.search, "parentid")!
          : "",
      );
      if (result.status !== 200 || !result.data) {
        displayToast({
          status: "error",
          title: "Failed to retrieve Action",
        });
      } else {
        if (!templateType) {
          result.data.Instances = result.data.Instances.map((instance: any) => {
            if (instance.Duplicates) {
              const negatives = instance.Duplicates.filter(
                (instance: any) =>
                  instance.ObjectStatusID === ObjectStatus.Negative,
              );
              const positives = instance.Duplicates.filter(
                (instance: any) =>
                  instance.ObjectStatusID === ObjectStatus.Positive,
              );
              const neutrals = instance.Duplicates.filter(
                (instance: any) =>
                  instance.ObjectStatusID === ObjectStatus.Neutral,
              );
              if (negatives.length > 0) {
                instance.ObjectStatusID = ObjectStatus.Negative;
                instance.ObjectStatusName = "Negative";
              } else if (neutrals.length > 0) {
                instance.ObjectStatusID = ObjectStatus.Neutral;
                instance.ObjectStatusName = "Neutral";
              } else if (positives.length > 0) {
                instance.ObjectStatusID = ObjectStatus.Positive;
                instance.ObjectStatusName = "Positive";
              }
              return instance;
            }
            return instance;
          });
        }

        if (result.data.ActionID === "NotExist") {
          // display error
          setPageStatus("Error");
        } else {
          const isNew = actionID === "new";
          // proceed
          result.data.ActionWindowBefore =
            result.data.ActionWindowBefore !== null
              ? result.data.ActionWindowBefore
              : result.data.ObjectTypes.find(
                  (objectType: any) =>
                    objectType.ObjectTypeID === result.data.ObjectTypeID,
                ).ObjectTypeWindowBefore;
          result.data.ActionWindowAfter =
            result.data.ActionWindowAfter !== null
              ? result.data.ActionWindowAfter
              : result.data.ObjectTypes.find(
                  (objectType: any) =>
                    objectType.ObjectTypeID === result.data.ObjectTypeID,
                ).ObjectTypeWindowAfter;
          result.data.ActionStartTs = result.data.ActionStartTs
            ? moment
                .utc(result.data.ActionStartTs)
                .subtract(24, "hours")
                .toDate()
            : result.data.ActionStartTs;
          result.data.CustomFields = result.data.CustomFields.map(
            (field: any) => ({
              ...field,
              Value: field.Value === null ? "" : field.Value,
            }),
          );
          result.data.ActionTimezone = result.data.ActionTimezone
            ? result.data.ActionTimezone
            : momenttz.tz.guess();
          const parentIDFromSearch =
            getUrlSearchParam(location.search, "parentid") || "";
          if (isNew && parentIDFromSearch) {
            const parent = result.data.Requirements.find(
              (requirement: any) =>
                requirement.RequirementID ===
                getUrlSearchParam(location.search, "parentid"),
            );
            result.data.ParentObjectTypeGroup =
              parent.ObjectTypeGroupName.toLowerCase();

            if (parent) {
              const parentObject = {
                ParentID: getUrlSearchParam(location.search, "parentid"),
                ParentName: parent.RequirementName,
                RequirementReadableID: parent.RequirementReadableID,
                ParentDescription: parent.RequirementDescription,
                ObjectTypeGroupName: result.data.ParentObjectTypeGroup,
                ObjectTypeIcon: parent.ObjectTypeIcon,
                Permission: parent.Permission,
              };
              result.data.ParentIDs.push(parentObject);
            }
            setparentID(result.data.ParentIDs[0].ParentID);
          } else if (parentIDFromSearch) {
            result.data.ParentObjectTypeGroup = result.data.Requirements.find(
              (requirement: any) =>
                requirement.RequirementID === parentIDFromSearch,
            ).ObjectTypeGroupName.toLowerCase();
            setparentID(parentIDFromSearch);
          } else if (result.data.ParentIDs.length >= 1) {
            result.data.ParentObjectTypeGroup = result.data.Requirements.find(
              (requirement: any) =>
                requirement.RequirementID === result.data.ParentIDs[0].ParentID,
            ).ObjectTypeGroupName.toLowerCase();
            setparentID(result.data.ParentIDs[0].ParentID);
          } else if (result.data.ParentIDs.ObjectTypeGroupName) {
            result.data.ParentObjectTypeGroup =
              result.data.ParentIDs.ObjectTypeGroupName.toLowerCase();
            setparentID(result.data.ParentIDs.ParentID);
          }
          if (
            isNew &&
            result.data.ResponsibleUsers.find(
              (user: any) => user.UserID === app.attributes.userID,
            )
          ) {
            result.data.ActionResponsibleUserID = app.attributes.userID;
            const userObject: any = result.data.ResponsibleUsers.find(
              (user: any) => user.UserID === app.attributes.userID,
            );
            result.data.ActionResponsibleUsers = result.data
              .ActionResponsibleUsers
              ? [...result.data.ActionResponsibleUsers, userObject]
              : [userObject]; // changed because .push() would error out
          }
          let displayMode = "";
          const search = getUrlSearchParam(location.search, "display");
          if (search) {
            displayMode = `?display=${search}`;
          } else if (
            result.data.ParentObjectTypeGroup &&
            result.data.ParentObjectTypeGroup !== "requirement"
          ) {
            displayMode = `?display=${result.data.ParentObjectTypeGroup}s`;
          }
          setDisplay(displayMode);
          result.data.ActionRecurrenceID =
            isNew && result.data.ParentObjectTypeGroup === "issue"
              ? ActionRecurrence.OnceOff
              : result.data.ActionRecurrenceID;
          result.data.QuestionTypes = restrictQuestionTypes(
            result.data.QuestionTypes,
            result.data.ObjectTypes.find(
              (objectType: any) =>
                objectType.ObjectTypeID === result.data.ObjectTypeID,
            ).ObjectTypeRiskQuestionsSupported,
          );
          result.data.UpdateSubscribedRecords = false;
          // here we will check if we have something in local storage, if we do, we will enter it as data and set the page on Editting mode. Otherwise we will persue as normal
          const storedValues = window.localStorage.getItem(
            `${window.location.href}`,
          );
          const parsedStoredValues = storedValues
            ? JSON.parse(storedValues)
            : "";
          if (
            !isNew &&
            parsedStoredValues &&
            checkStoredValues(parsedStoredValues, result.data, "Action") &&
            parsedStoredValues.ActionID === result.data.ActionID
          ) {
            setSavedData({ ...result.data, ...parsedStoredValues });
            setData({ ...result.data });
          } else if (
            isNew &&
            parsedStoredValues &&
            checkStoredValues(parsedStoredValues, result.data, "Action")
          ) {
            setSavedData(null);
            setData({ ...result.data, ...parsedStoredValues });
          } else {
            setSavedData(null);
            setData({ ...result.data });
          }
          setPageStatus(isNew ? "New" : "Ready");
        }
      }
    };

    if (authState.isLoggedIn) {
      fetchAction();
    }
  }, [actionID, params]);

  const breadcrumbs =
    data && pageStatus !== "Loading"
      ? getHeaderBreadCrumbs(
          parentID,
          pageStatus,
          data,
          display,
          templateType,
          actionID,
          window.location,
        )
      : [];

  const pageTitle =
    data && pageStatus !== "Loading"
      ? pageStatus === "New"
        ? "New Action"
        : `${data.ActionName}${
            data.ObjectStatusID === ObjectStatus.Closed ? " [CLOSED]" : ""
          }`
      : "";

  const pageSubtitle =
    data && pageStatus !== "Loading" ? data.ActionReadableID : undefined;

  return (
    <LegacyScreenContainer
      pageTitle={pageTitle}
      pageSubtitle={pageSubtitle}
      breadcrumbs={breadcrumbs}
      headerBottomContent={
        !templateType &&
        pageStatus !== "Loading" &&
        pageStatus !== "Error" &&
        data
          ? NotesIcons({
              userPermissions: app.permissions_LEGACY,
              setNotes,
              isNotesEnabled: data.ObjectTypeNotesEnabled,
              isTimeNotesEnabled: data.ObjectTypeTimeNotesEnabled,
              numberOfNotes: data.Notes.filter(
                (note: any) => note.NoteTypeID === NoteType.Note,
              ).length,
              numberOfTimeNotes: data.Notes.filter(
                (note: any) => note.NoteTypeID === NoteType.TimeNote,
              ).length,
            })
          : null
      }>
      <>
        {data && pageStatus !== "Loading" ? (
          <>
            <section className="card">
              <Row>
                <Col sm="auto">
                  <h1>Details</h1>
                </Col>
                <Col>
                  <Status
                    variant={objectStatusToVariant(data.ObjectStatusID)}
                    text={data.ObjectStatusName}
                    tooltip={data.ObjectStatusDescription}
                  />
                </Col>
                {pageStatus === "Ready" ? (
                  <>
                    <Col sm="auto">
                      <DocumentGenerator data={data} />
                    </Col>
                    <Col sm="auto">
                      {Access.checkAccess(
                        app.permissions_LEGACY,
                        Permissions.CodeAction,
                        Permissions.TypeCreate,
                        data.ActionResponsibleUsers.some(
                          (user: any) => app.attributes.userID === user.UserID,
                        ),
                        data.ObjectTypeID,
                      ) ? (
                        <Button
                          type="button"
                          variant="outline-dark"
                          onClick={() => {
                            setData({ ...data, ActionStartTs: null });
                            setCopyMode(true);
                          }}>
                          <Copy className="feather" size="16" />
                          Copy
                        </Button>
                      ) : (
                        <OverlayTrigger
                          placement="auto"
                          overlay={
                            <Tooltip id="not-authorised-to-edit">
                              You are not authorised to copy this action
                            </Tooltip>
                          }>
                          <Button
                            type="button"
                            variant="outline-dark"
                            className="disabled">
                            <Copy className="feather" size="16" />
                            Copy
                          </Button>
                        </OverlayTrigger>
                      )}
                    </Col>
                    <Col sm="auto">
                      {Access.checkAccess(
                        app.permissions_LEGACY,
                        Permissions.CodeAction,
                        Permissions.TypeUpdate,
                        (app.attributes.userID ===
                          data.ActionResponsibleUsers.find(
                            (user: any) =>
                              app.attributes.userID === user.UserID,
                          )) !==
                          undefined,
                        data.ObjectTypeID,
                      ) ? (
                        savedData ? (
                          <OverlayTrigger
                            placement="auto"
                            overlay={
                              <Tooltip id="tooltip-unsaved-changes">
                                You have unsaved changes
                              </Tooltip>
                            }>
                            <Button
                              type="button"
                              variant="outline-dark"
                              onClick={() => {
                                setOriginalData(data);
                                setData(savedData);
                                setTimeout(() => setPageStatus("Editing"), 20);
                              }}>
                              <Edit2 className="feather" size="16" />
                              Edit
                            </Button>
                          </OverlayTrigger>
                        ) : (
                          <Button
                            type="button"
                            variant="outline-dark"
                            onClick={() => {
                              setTimeout(() => setPageStatus("Editing"), 20);
                            }}>
                            <Edit2 className="feather" size="16" />
                            Edit
                          </Button>
                        )
                      ) : (
                        <OverlayTrigger
                          placement="auto"
                          overlay={
                            <Tooltip id="not-authorised-to-edit">
                              You are not authorised to edit this
                            </Tooltip>
                          }>
                          <Button
                            type="button"
                            variant="outline-dark"
                            className="disabled">
                            <Edit2 className="feather" size="16" />
                            Edit
                          </Button>
                        </OverlayTrigger>
                      )}
                    </Col>
                  </>
                ) : null}
                {pageStatus === "Editing" ||
                pageStatus === "Closing/Opening" ? (
                  <>
                    <Col sm="auto">
                      {Access.checkAccess(
                        app.permissions_LEGACY,
                        Permissions.CodeAction,
                        Permissions.TypeDelete,
                        data.ActionResponsibleUsers
                          ? data.ActionResponsibleUsers.find(
                              (user: any) =>
                                app.attributes.userID === user.UserID,
                            ) !== undefined
                          : false,
                        data.ObjectTypeID,
                      ) ? (
                        <DropdownButton
                          id="dropdown-basic-button"
                          variant="outline-dark"
                          title="Delete">
                          <Dropdown.Item onClick={() => deleteAction(data)}>
                            Confirm delete
                          </Dropdown.Item>
                        </DropdownButton>
                      ) : (
                        <OverlayTrigger
                          placement="auto"
                          overlay={
                            <Tooltip id="not-authorised-to-edit">
                              You are not authorised to delete this
                            </Tooltip>
                          }>
                          <Button
                            type="button"
                            variant="outline-dark"
                            className="disabled">
                            Delete
                          </Button>
                        </OverlayTrigger>
                      )}
                    </Col>

                    {!templateType ? (
                      <Col sm="auto">
                        {Access.checkAccess(
                          app.permissions_LEGACY,
                          Permissions.CodeAction,
                          Permissions.TypeUpdate,
                          data.ActionResponsibleUsers
                            ? data.ActionResponsibleUsers.find(
                                (user: any) =>
                                  app.attributes.userID === user.UserID,
                              ) !== undefined
                            : false,
                          data.ObjectTypeID,
                        ) ? (
                          pageStatus !== "Closing/Opening" ? (
                            <DropdownButton
                              id="dropdown-basic-button"
                              variant="outline-dark"
                              title={
                                data.ObjectStatusID !== ObjectStatus.Closed
                                  ? "Close"
                                  : "Re-Open"
                              }>
                              <Dropdown.Item
                                as="button"
                                onClick={() => {
                                  setPageStatus("Closing/Opening");
                                  closeAction(
                                    data,
                                    data.ObjectStatusID !== ObjectStatus.Closed,
                                  );
                                }}>
                                Confirm{" "}
                                {data.ObjectStatusID !== ObjectStatus.Closed
                                  ? "Close"
                                  : "Re-Open"}
                              </Dropdown.Item>
                            </DropdownButton>
                          ) : (
                            <Loading />
                          )
                        ) : (
                          <OverlayTrigger
                            placement="auto"
                            overlay={
                              <Tooltip id="tooltip-cannot-respond-closed">
                                {`You do not have permission to ${
                                  data.ObjectStatusID !== ObjectStatus.Closed
                                    ? "close"
                                    : "re-open"
                                } this`}
                              </Tooltip>
                            }>
                            <Button variant="primary" className="disabled">
                              {data.ObjectStatusID !== ObjectStatus.Closed
                                ? "Close"
                                : "Re-Open"}
                            </Button>
                          </OverlayTrigger>
                        )}
                      </Col>
                    ) : null}
                  </>
                ) : null}
                <Col sm="auto">
                  <CollapseChevron
                    collapsed={isDetailsCollapsed}
                    updateCollapsed={updateDetailsCollapsed}
                  />
                </Col>
              </Row>
              <Collapse in={!isDetailsCollapsed}>
                <Row>
                  <Col>
                    <hr />
                    <Formik
                      enableReinitialize
                      initialValues={data}
                      validate={(values: ActionPageData) =>
                        validationSchema
                          .validate(values, {
                            abortEarly: false,
                            context: {
                              isTemplate: templateType,
                              recurrenceCheck:
                                !templateType || values!.ParentIDs.length !== 0,
                            },
                          })
                          .catch((err) => {
                            throw yupToFormErrors(err);
                          })
                      }
                      onSubmit={async (values, actions) => {
                        // this is a bit confusing so i'll explain
                        // in the normal state app we want to not continue with the submit if we have choose no parent or if we do not have a valid date
                        // in the template mode we want to continue without a date as it is not important and without parents if chosen to be a standAlone action
                        const ot = values.ObjectTypes.find(
                          (ot: any) => ot.ObjectTypeID === values.ObjectTypeID,
                        ).ObjectTypeName;
                        try {
                          if (
                            isCopyMode === false &&
                            data.ActionStartTs &&
                            values.ActionStartTs &&
                            !moment(values.ActionStartTs).isSame(
                              data.ActionStartTs,
                              "day",
                            )
                          ) {
                            values.ActionStartDateHasChanged = true;
                          }
                          setData(values);
                          setPageStatus("Submitting");
                          values.CustomFields = values.CustomFields.map(
                            (field: any) => ({
                              ...field,
                              Value: field.Value === "" ? null : field.Value,
                            }),
                          );
                          values.CustomFields = values.CustomFields.map(
                            (field: any) => {
                              if (
                                field.CustomFieldTypeColumnName === "DocumentID"
                              ) {
                                if (field.Value !== null) {
                                  field.Value = field.Value.map((doc: any) => {
                                    if (doc.status) {
                                      return { ...doc, buffer: {} };
                                    }
                                    return doc;
                                  });
                                }
                              }
                              return field;
                            },
                          );
                          if (templateType) {
                            values.ActionStartTs = new Date(
                              moment().format("DD-MM-YYYY hh:mm:ss"),
                            );
                          } else {
                            values.ActionStartTs =
                              values.ActionStartTs !== null
                                ? values.ActionStartTs
                                : new Date(
                                    moment().format("DD-MM-YYYY hh:mm:ss"),
                                  );
                          }
                          if (
                            values.RecordStatusID === "Template" &&
                            values.TemplateActionID !== ""
                          ) {
                            values.TemplateID = values.TemplateActionID;
                          }
                          let results: any = {};
                          if (actionID === "new" || isCopyMode) {
                            results = await put(
                              `action${templateType ? "/template" : ""}`,
                              {
                                ...values,
                                ActionStartTs: validateDate(
                                  values.ActionStartTs,
                                  values.ActionTimezone,
                                ),
                                isCopiedAction: isCopyMode,
                              },
                            );
                          } else {
                            results = await post(`action/${actionID}`, {
                              ...values,
                              ActionStartTs: validateDate(
                                values.ActionStartTs,
                                values.ActionTimezone,
                              ),
                            });
                          }
                          // setData(values)
                          displayToast({
                            status: "success",
                            title: `${results.data.ObjectTypeName} ${
                              actionID === "new" || isCopyMode
                                ? "created"
                                : "updated"
                            } successfully`,
                          });
                          // remove the local storage after submit
                          window.localStorage.removeItem(
                            `${window.location.href}`,
                          );
                          setPageStatus("Ready");
                          const searchParams = getUrlSearchParam(
                            location.search,
                            "parentid",
                          )
                            ? `${display}&parentid=${getUrlSearchParam(
                                location.search,
                                "parentid",
                              )}`
                            : display;
                          if (actionID === "new" || isCopyMode) {
                            setCopyMode(false);
                            navigate(
                              {
                                pathname: `${
                                  templateType ? "/template" : ""
                                }/action/${results.data.ActionID}`,
                                search: searchParams,
                              },
                              {
                                state: {
                                  hasChanged: true,
                                  display: display
                                    ? getUrlSearchParam(
                                        location.search,
                                        "display",
                                      )
                                    : "",
                                },
                              },
                            );
                          } else {
                            navigate(
                              {
                                pathname: `${
                                  templateType ? "/template" : ""
                                }/action/${data.ActionID}`,
                                search: searchParams,
                              },
                              {
                                state: {
                                  hasChanged: true,
                                  display: display
                                    ? getUrlSearchParam(
                                        location.search,
                                        "display",
                                      )
                                    : "",
                                },
                              },
                            );
                            // setData(results.data)
                          }
                        } catch (err) {
                          displayToast({
                            status: "error",
                            title: `Failed to ${
                              actionID === "new" || isCopyMode
                                ? "create"
                                : "update"
                            } ${
                              values.ObjectTypeName ? values.ObjectTypeName : ot
                            }`,
                          });
                          actionID === "new"
                            ? setPageStatus("New")
                            : setPageStatus("Editing");
                        }
                        actions.setSubmitting(false);
                      }}
                      render={(formikProps) => {
                        // here we save at every change into local storage
                        if (
                          (pageStatus === "New" || pageStatus === "Editing") &&
                          actionID === formikProps.values.ActionID
                        ) {
                          LocalStorage.saveInLocalStorage(
                            window.location.href,
                            formikProps,
                          );
                        }
                        return (
                          <Form onSubmit={formikProps.handleSubmit}>
                            {pageStatus === "New" || isCopyMode ? (
                              <Form.Group as={Row}>
                                <Form.Label column sm="2">
                                  Action Type
                                  <MandatoryIndicator
                                    ID="type"
                                    pageStatus={pageStatus}
                                    elementType="field"
                                  />
                                </Form.Label>
                                <Col sm="9">
                                  <Select
                                    onUpdate={(e) => {
                                      navigate(
                                        `${
                                          templateType ? "/template" : ""
                                        }/action/new?type=${e.value}${
                                          getUrlSearchParam(
                                            location.search,
                                            "parentid",
                                          )
                                            ? `&parentid=${getUrlSearchParam(
                                                location.search,
                                                "parentid",
                                              )}`
                                            : ""
                                        }`,
                                      );
                                    }}
                                    name="ActionTypeID"
                                    value={formikProps.values.ObjectTypeID}
                                    optionsList={
                                      templateType
                                        ? formikProps.values.ObjectTypes.filter(
                                            (ObjectType: any) =>
                                              Access.checkAccess(
                                                app.permissions_LEGACY,
                                                Access.objectTypeGroupIDToName(
                                                  ObjectType.ObjectTypeGroupID,
                                                ),
                                                "CREATE",
                                                false,
                                                ObjectType.ObjectTypeID,
                                              ),
                                          )
                                        : checkRelationship(
                                            formikProps.values.ObjectTypes,
                                            app.permissions_LEGACY,
                                            formikProps.values.ParentIDs,
                                            formikProps.values.Relationships,
                                            formikProps.values.Requirements,
                                          )
                                    }
                                    optionsListID="ObjectTypeID"
                                    optionsListValue="ObjectTypeName"
                                    readOnly={pageStatus !== "New"}
                                    readOnlyValue={
                                      formikProps.values.ObjectTypeName
                                    }
                                    disabled={
                                      data.ObjectStatusID ===
                                      ObjectStatus.Closed
                                    }
                                  />
                                </Col>
                              </Form.Group>
                            ) : (
                              <Form.Group as={Row}>
                                <Form.Label column sm="2">
                                  Action Type
                                  <MandatoryIndicator
                                    ID="type"
                                    pageStatus={pageStatus}
                                    elementType="field"
                                  />
                                </Form.Label>
                                <Col sm="9">
                                  <Text
                                    value={formikProps.values.ObjectTypeName}
                                    name="ActionObjectType"
                                    readOnly
                                  />
                                </Col>
                              </Form.Group>
                            )}
                            <Form.Group as={Row}>
                              <Form.Label column sm="2">
                                Parent
                                <MandatoryIndicator
                                  ID="parent"
                                  pageStatus={pageStatus}
                                  elementType="field"
                                />
                              </Form.Label>
                              <Col sm="9">
                                <MultiSelect
                                  onUpdate={(e: any) => {
                                    if (e !== null) {
                                      const newParents = e.map(
                                        (element: any) => {
                                          const parentDetails =
                                            formikProps.values.Requirements.find(
                                              (requirement: any) =>
                                                requirement.RequirementID ===
                                                element.value,
                                            );
                                          const parent = {
                                            ParentID: element.value,
                                            ParentName: element.label.substring(
                                              element.label.lastIndexOf("-") +
                                                1,
                                              element.label.length,
                                            ),
                                            RequirementReadableID:
                                              element.label.substring(
                                                0,
                                                element.label.lastIndexOf("-"),
                                              ),
                                            ParentDescription: parentDetails
                                              ? parentDetails.RequirementDescription
                                              : "",
                                            ObjectTypeGroupName: parentDetails
                                              ? parentDetails.ObjectTypeGroupName
                                              : "",
                                            ObjectTypeIcon: parentDetails
                                              ? parentDetails.ObjectTypeIcon
                                              : "",
                                            Permission: parentDetails
                                              ? parentDetails.Permission
                                              : false,
                                          };
                                          return parent;
                                        },
                                      );
                                      formikProps.setValues({
                                        ...formikProps.values,
                                        ParentIDs: newParents,
                                      });
                                    } else {
                                      const newParents: any[] = [];
                                      formikProps.setValues({
                                        ...formikProps.values,
                                        ParentIDs: newParents,
                                      });
                                    }
                                  }}
                                  name="ParentID"
                                  value={
                                    pageStatus !== "New"
                                      ? formikProps.values.ParentIDs
                                      : formikProps.values.ParentIDs !==
                                        undefined
                                      ? formikProps.values.ParentIDs
                                      : []
                                  }
                                  unselectedOption={
                                    templateType
                                      ? "No Parent"
                                      : "Select a Value"
                                  }
                                  optionsListID="RequirementID"
                                  optionsListValue="RequirementName"
                                  optionsListReadable="RequirementReadableID"
                                  optionsList={getRequirements(
                                    formikProps.values.Requirements,
                                    formikProps.values.ObjectTypeID,
                                    formikProps.values.Relationships,
                                    templateType,
                                  )}
                                  optionsChild
                                  readOnly={
                                    pageStatus !== "Editing" &&
                                    pageStatus !== "New" &&
                                    !isCopyMode
                                  }
                                  readOnlyComponent={formikProps.values.ParentIDs.sort(
                                    (a, b) =>
                                      a.ParentTree && b.ParentTree
                                        ? a.ParentTree.length -
                                          b.ParentTree.length
                                        : 0,
                                  )}
                                  disabled={
                                    data.ObjectStatusID === ObjectStatus.Closed
                                  }
                                  template={templateType}
                                  action
                                  seeMore
                                  parentIdFromUrl={parentID}
                                />
                              </Col>
                            </Form.Group>
                            <Form.Group as={Row}>
                              <Form.Label column sm="2">
                                Name
                                <MandatoryIndicator
                                  ID="name"
                                  pageStatus={pageStatus}
                                  elementType="field"
                                />
                              </Form.Label>
                              <Col sm="9">
                                <Text
                                  value={formikProps.values.ActionName}
                                  name="ActionName"
                                  onUpdate={formikProps.handleChange}
                                  readOnly={
                                    pageStatus !== "Editing" &&
                                    pageStatus !== "New" &&
                                    !isCopyMode
                                  }
                                  disabled={
                                    data.ObjectStatusID === ObjectStatus.Closed
                                  }
                                  showCharacterLimit={
                                    pageStatus === "New" ||
                                    pageStatus === "Editing" ||
                                    isCopyMode
                                  }
                                />
                              </Col>
                            </Form.Group>
                            <Form.Group as={Row}>
                              <Form.Label column sm="2">
                                Description
                              </Form.Label>
                              <Col sm="9">
                                <Textarea
                                  value={formikProps.values.ActionDescription}
                                  name="ActionDescription"
                                  onUpdate={(val: any) => {
                                    formikProps.setFieldValue(
                                      "ActionDescription",
                                      val,
                                    );
                                  }}
                                  readOnly={
                                    pageStatus !== "Editing" &&
                                    pageStatus !== "New" &&
                                    !isCopyMode
                                  }
                                  disabled={
                                    data.ObjectStatusID === ObjectStatus.Closed
                                  }
                                  showCharacterLimit={
                                    pageStatus === "New" ||
                                    pageStatus === "Editing" ||
                                    isCopyMode
                                  }
                                  richText
                                />
                              </Col>
                            </Form.Group>
                            {templateType &&
                            formikProps.values.ParentIDs.length !== 0 ? (
                              <Form.Group as={Row}>
                                <Form.Label column sm="2">
                                  Relative Due Days
                                </Form.Label>
                                <Col sm="8">
                                  <Number
                                    value={formikProps.values.ActionTemplateRelativeDays.toString()}
                                    name={formikProps.values.ActionID}
                                    onUpdate={(e: any) =>
                                      formikProps.setValues({
                                        ...formikProps.values,
                                        ActionTemplateRelativeDays:
                                          e.target.value,
                                      })
                                    }
                                    readOnly={
                                      pageStatus !== "Editing" &&
                                      pageStatus !== "New" &&
                                      !isCopyMode
                                    }
                                    disabled={
                                      data.ObjectStatusID ===
                                      ObjectStatus.Closed
                                    }
                                    unit="Days"
                                  />
                                </Col>
                                <Col sm="1">
                                  <HelpText
                                    id="relativeDays"
                                    text="Number of days from the Action Date that this action will be due."
                                  />
                                </Col>
                              </Form.Group>
                            ) : templateType ? null : (
                              <Form.Group as={Row}>
                                <Form.Label column sm="2">
                                  First Task Date
                                  <MandatoryIndicator
                                    ID="type"
                                    pageStatus={pageStatus}
                                    elementType="field"
                                  />
                                </Form.Label>
                                <Col sm="2">
                                  <DateField
                                    name="ActionStartTs"
                                    selected={formikProps.values.ActionStartTs}
                                    onUpdate={((name: any, data: any) =>
                                      formikProps.setFieldValue(
                                        name,
                                        data,
                                      )).bind({}, "ActionStartTs")}
                                    readOnly={
                                      pageStatus !== "Editing" &&
                                      pageStatus !== "New" &&
                                      !isCopyMode
                                    }
                                    min={getFirstActionDateMin(
                                      pageStatus,
                                      lastRespondedActionDate,
                                      isCopyMode,
                                    )}
                                    disabled={
                                      data.ObjectStatusID ===
                                      ObjectStatus.Closed
                                    }
                                    authState={authState}
                                    allDates={false}
                                  />
                                </Col>
                                {pageStatus === "Editing" ? (
                                  <Col sm="4">
                                    <span className="form-label-small">
                                      Date must be beyond any completed
                                      instances.
                                    </span>
                                  </Col>
                                ) : (
                                  ""
                                )}
                                <Col sm="3">
                                  <Select
                                    onUpdate={(e: any) => {
                                      formikProps.setValues({
                                        ...formikProps.values,
                                        ActionTimezone: e.value,
                                      });
                                    }}
                                    name="ActionTimezone"
                                    value={formikProps.values.ActionTimezone}
                                    unselectedOption={
                                      formikProps.values
                                        .ParentObjectTypeGroup !== "Issue" &&
                                      pageStatus === "New"
                                        ? "Select a Value"
                                        : undefined
                                    }
                                    optionsList={timezones}
                                    optionsChild={(listItem: any) => ({
                                      value: listItem,
                                      label: listItem,
                                    })}
                                    readOnly={
                                      pageStatus !== "Editing" &&
                                      pageStatus !== "New"
                                    }
                                    readOnlyValue={
                                      formikProps.values.ActionTimezone
                                    }
                                    disabled={
                                      data.ObjectStatusID ===
                                      ObjectStatus.Closed
                                    }
                                  />
                                </Col>
                              </Form.Group>
                            )}
                            {formikProps.values.ParentIDs.length === 0 &&
                            templateType ? null : (
                              <Form.Group as={Row}>
                                <Form.Label column sm="2">
                                  Recurrence
                                  <MandatoryIndicator
                                    ID="type"
                                    pageStatus={pageStatus}
                                    elementType="field"
                                  />
                                </Form.Label>
                                <Col sm={pageStatus === "Editing" ? "9" : "7"}>
                                  <Select
                                    onUpdate={(e: any) => {
                                      formikProps.setValues({
                                        ...formikProps.values,
                                        ActionRecurrenceID: e.value,
                                      });
                                    }}
                                    name="ActionRecurrenceID"
                                    value={
                                      formikProps.values.ActionRecurrenceID
                                    }
                                    unselectedOption={
                                      formikProps.values
                                        .ParentObjectTypeGroup !== "Issue" &&
                                      pageStatus === "New"
                                        ? "Select a Value"
                                        : undefined
                                    }
                                    optionsList={
                                      formikProps.values.ActionRecurrences
                                    }
                                    optionsListID="ActionRecurrenceID"
                                    optionsListValue="ActionRecurrenceText"
                                    readOnly={
                                      pageStatus !== "Editing" &&
                                      pageStatus !== "New" &&
                                      !isCopyMode
                                    }
                                    readOnlyValue={
                                      formikProps.values.ActionRecurrenceText
                                    }
                                    disabled={
                                      data.ObjectStatusID ===
                                      ObjectStatus.Closed
                                    }
                                  />
                                </Col>
                                {pageStatus === "Ready" &&
                                data.ObjectStatusID !== ObjectStatus.Closed &&
                                !templateType &&
                                !isCopyMode ? (
                                  <Col sm="3">
                                    {Access.checkAccess(
                                      app.permissions_LEGACY,
                                      Permissions.CodeAction,
                                      Permissions.TypeUpdate,
                                      data.ActionResponsibleUsers
                                        ? data.ActionResponsibleUsers.find(
                                            (user: any) =>
                                              app.attributes.userID ===
                                              user.UserID,
                                          ) !== undefined
                                        : false,
                                      data.ObjectTypeID,
                                    ) ? (
                                      <OverlayTrigger
                                        placement="auto"
                                        overlay={
                                          <Tooltip id="create-once-off-instance">
                                            This will create an extra once-off
                                            Task of this action - must be before
                                            the next Task is due
                                          </Tooltip>
                                        }>
                                        <Button
                                          variant="outline-dark"
                                          onClick={() => {
                                            navigate(
                                              `/instance/new${
                                                display ? `${display}&` : "?"
                                              }parentid=${data.ActionID}`,
                                            );
                                          }}>
                                          Create once-off Task
                                        </Button>
                                      </OverlayTrigger>
                                    ) : (
                                      <OverlayTrigger
                                        placement="auto"
                                        overlay={
                                          <Tooltip id="not-authorised-to-edit">
                                            You are not authorised to create a
                                            once-off Task
                                          </Tooltip>
                                        }>
                                        <Button
                                          type="button"
                                          variant="outline-dark"
                                          className="disabled">
                                          Create once-off Task
                                        </Button>
                                      </OverlayTrigger>
                                    )}
                                  </Col>
                                ) : null}
                              </Form.Group>
                            )}
                            <Form.Group as={Row}>
                              <Form.Label column sm="2">
                                Responsible User
                                <MandatoryIndicator
                                  ID="type"
                                  pageStatus={pageStatus}
                                  elementType="field"
                                />
                              </Form.Label>
                              <Col sm="9">
                                <MultiSelect
                                  onUpdate={(e: any[]) => {
                                    if (e) {
                                      const responsibleUsers: any =
                                        formikProps.values.ResponsibleUsers.filter(
                                          (user: any) =>
                                            e.find(
                                              (userOption: any) =>
                                                user.UserID ===
                                                userOption.value,
                                            ) !== undefined,
                                        );
                                      formikProps.setFieldValue(
                                        "ActionResponsibleUsers",
                                        responsibleUsers,
                                      );
                                    } else {
                                      formikProps.setFieldValue(
                                        "ActionResponsibleUsers",
                                        [],
                                      );
                                    }
                                  }}
                                  name="ActionResponsibleUsers"
                                  value={
                                    formikProps.values.ActionResponsibleUsers
                                  }
                                  unselectedOption="Select a Value"
                                  optionsList={
                                    formikProps.values.ResponsibleUsers
                                  }
                                  optionsListID="UserID"
                                  optionsListValue="UserFullName"
                                  readOnly={
                                    pageStatus !== "Editing" &&
                                    pageStatus !== "New" &&
                                    !isCopyMode
                                  }
                                  readOnlyValue={
                                    formikProps.values.ActionResponsibleUsers
                                      ? formikProps.values.ActionResponsibleUsers.sort(
                                          (a: any, b: any) =>
                                            a.UserFullName.localeCompare(
                                              b.UserFullName,
                                              "en",
                                              { numeric: true },
                                            ),
                                        )
                                          .map((user: any) => user.UserFullName)
                                          .join(", ")
                                      : ""
                                  }
                                  disabled={
                                    data.ObjectStatusID === ObjectStatus.Closed
                                  }
                                  template={templateType}
                                  action
                                  actionResponsible
                                />
                              </Col>
                            </Form.Group>
                            {formikProps.values.ParentIDs.length === 0 &&
                            templateType ? null : pageStatus !== "Editing" &&
                              pageStatus !== "New" &&
                              !isCopyMode ? (
                              <Form.Group as={Row}>
                                <Form.Label column xs="12" md="2">
                                  Task Window
                                </Form.Label>
                                <Col xs="4" md="1">
                                  <Text
                                    value={formikProps.values.ActionWindowBefore.toString()}
                                    name="ActionWindowBefore"
                                    onUpdate={formikProps.handleChange}
                                    readOnly
                                    style={{ textAlign: "right" }}
                                    disabled={
                                      data.ObjectStatusID ===
                                      ObjectStatus.Closed
                                    }
                                  />
                                </Col>
                                <Form.Label
                                  column
                                  xs="8"
                                  md="2"
                                  style={{ textAlign: "left" }}>
                                  Days Before{" "}
                                </Form.Label>
                                <Col xs="12" md="1" />
                                <Col xs="4" md="1">
                                  <Text
                                    value={formikProps.values.ActionWindowAfter.toString()}
                                    name="ActionWindowAfter"
                                    onUpdate={formikProps.handleChange}
                                    readOnly
                                    style={{ textAlign: "right" }}
                                    disabled={
                                      data.ObjectStatusID ===
                                      ObjectStatus.Closed
                                    }
                                  />
                                </Col>
                                <Form.Label
                                  column
                                  xs="8"
                                  md="2"
                                  style={{ textAlign: "left" }}>
                                  Days After
                                </Form.Label>
                              </Form.Group>
                            ) : (
                              <Form.Group as={Row}>
                                <Form.Label column xs="12" md="2">
                                  Task Window:
                                  <MandatoryIndicator
                                    ID="action-window"
                                    pageStatus={pageStatus}
                                    elementType="field"
                                  />
                                </Form.Label>
                                <Col xs="4" md="1" style={{ minWidth: "85px" }}>
                                  <Number
                                    value={formikProps.values.ActionWindowBefore.toString()}
                                    name="ActionWindowBefore"
                                    onUpdate={(event: any) => {
                                      if (event.target.value.length <= 3) {
                                        formikProps.handleChange(event);
                                      }
                                    }}
                                    readOnly={false}
                                    disabled={
                                      data.ObjectStatusID ===
                                      ObjectStatus.Closed
                                    }
                                  />
                                </Col>
                                <Form.Label
                                  column
                                  xs="8"
                                  md="2"
                                  style={{ textAlign: "left" }}>
                                  {" "}
                                  Days Before
                                </Form.Label>
                                <Col xs="12" md="1" />
                                <Col xs="4" md="1" style={{ minWidth: "85px" }}>
                                  <Number
                                    value={formikProps.values.ActionWindowAfter.toString()}
                                    name="ActionWindowAfter"
                                    onUpdate={(event: any) => {
                                      if (event.target.value.length <= 3) {
                                        formikProps.handleChange(event);
                                      }
                                    }}
                                    readOnly={false}
                                    disabled={
                                      data.ObjectStatusID ===
                                      ObjectStatus.Closed
                                    }
                                  />
                                </Col>
                                <Form.Label
                                  column
                                  xs="8"
                                  md="2"
                                  style={{ textAlign: "left" }}>
                                  Days After
                                </Form.Label>
                              </Form.Group>
                            )}

                            {/* for non-Date custom fields, onUpdate parameters are: event, null, null */}
                            {/* for Date custom field, onUpdate parameters are: name, Date, event */}
                            {formikProps.values.ParentIDs.length === 0 &&
                            templateType ? null : (
                              <CustomFieldList
                                customFields={formikProps.values.CustomFields}
                                pageStatus={isCopyMode ? "Copy" : pageStatus}
                                disabled={
                                  data.ObjectStatusID === ObjectStatus.Closed
                                }
                                onUpdate={(event: any, a: any, b: any) => {
                                  const newFields =
                                    formikProps.values.CustomFields.map(
                                      (field: any) => {
                                        // for every custom field
                                        if (
                                          event.target !== undefined &&
                                          event.target.name ===
                                            field.CustomFieldID
                                        ) {
                                          return {
                                            ...field,
                                            Value: event.target.value,
                                          };
                                          // just for the uploader one
                                        }
                                        if (
                                          event.cfID === field.CustomFieldID &&
                                          a.fileList
                                        ) {
                                          return {
                                            ...field,
                                            Value: a.fileList,
                                          };
                                        }
                                        if (
                                          event.cfID === field.CustomFieldID &&
                                          a !== null
                                        ) {
                                          return { ...field, Value: a };
                                        }
                                        return field;
                                      },
                                    );
                                  formikProps.setValues({
                                    ...formikProps.values,
                                    CustomFields: newFields,
                                  });
                                }}
                                formError={!!formikProps.errors.CustomFields}
                              />
                            )}
                            {pageStatus === "New" &&
                            formikProps.values.hasTemplate ? (
                              <Form.Group as={Row}>
                                <Form.Label column sm="2">
                                  Use template?
                                </Form.Label>
                                <Col sm="9">
                                  <Form.Check
                                    id="Template"
                                    type="checkbox"
                                    checked={
                                      formikProps.values.RecordStatusID ===
                                      "Template"
                                    }
                                    onChange={(event: any) => {
                                      if (event.target.checked) {
                                        formikProps.setValues({
                                          ...formikProps.values,
                                          RecordStatusID: event.target.id,
                                        });
                                      } else {
                                        formikProps.setValues({
                                          ...formikProps.values,
                                          RecordStatusID: "",
                                        });
                                      }
                                    }}
                                    label="Yes, I want to use a template"
                                  />
                                </Col>
                              </Form.Group>
                            ) : null}
                            {pageStatus === "New" &&
                            formikProps.values.hasTemplate &&
                            formikProps.values.RecordStatusID === "Template" &&
                            formikProps.values.TemplateData !== undefined ? (
                              <Form.Group as={Row}>
                                <Form.Label column sm="2" />
                                <Col sm="9">
                                  <Col sm="9">
                                    {TemplateOptions(
                                      formikProps.values.TemplateData,
                                      formikProps.setFieldValue,
                                      formikProps.values.TemplateActionID,
                                    )}
                                  </Col>
                                </Col>
                              </Form.Group>
                            ) : null}
                            {pageStatus !== "New" &&
                            !isCopyMode &&
                            pageStatus !== "Editing" &&
                            pageStatus !== "Submitting" ? (
                              <History
                                ID={data.ActionID}
                                authState={authState}
                                lastModifiedTs={data.ModifiedTs}
                                lastModififedUser={data.ModifiedUserName}
                              />
                            ) : null}
                            <Form.Group as={Row}>
                              <QuestionWrapper
                                key="QuestionWrapper"
                                handleChange={(e: any) => {
                                  formikProps.setValues({
                                    ...formikProps.values,
                                    Questions: e,
                                  });
                                }}
                                id="QuestionWrapper"
                                riskMatrix={formikProps.values.RiskMatrix}
                                showCharacterLimit={
                                  pageStatus === "New" ||
                                  pageStatus === "Editing" ||
                                  isCopyMode
                                }
                                readOnly={
                                  (pageStatus !== "Editing" &&
                                    pageStatus !== "New" &&
                                    !isCopyMode) ||
                                  data.ObjectStatusID === ObjectStatus.Closed
                                }
                                reset={data.QuestionWrapperReset}
                                customFields={
                                  formikProps.values.CustomFieldsForQuestions
                                }
                                questionTypes={formikProps.values.QuestionTypes}
                                initialQuestions={renderQuestionsTemplate(
                                  data,
                                  formikProps.values.RecordStatusID,
                                  formikProps.values.TemplateActionID,
                                )}
                              />
                            </Form.Group>
                            {pageStatus === "Editing" &&
                            templateType &&
                            formikProps.values.SubscribedRecords.length > 0 ? (
                              <Form.Group as={Row}>
                                <Col sm="auto">
                                  There{" "}
                                  {formikProps.values.SubscribedRecords.length >
                                  1
                                    ? "are"
                                    : "is"}{" "}
                                  {formikProps.values.SubscribedRecords.length}{" "}
                                  {formikProps.values.SubscribedRecords.length >
                                  1
                                    ? "records"
                                    : "record"}{" "}
                                  associated with this template.
                                </Col>
                              </Form.Group>
                            ) : null}
                            <Form.Group as={Row}>
                              {pageStatus === "Editing" ||
                              pageStatus === "New" ||
                              isCopyMode ? (
                                <>
                                  {pageStatus === "Editing" &&
                                  templateType &&
                                  formikProps.values.SubscribedRecords.length >
                                    0 ? (
                                    <>
                                      <Col sm="auto">
                                        <Button
                                          type="button"
                                          onClick={() => {
                                            if (
                                              JSON.stringify(
                                                formikProps.errors,
                                              ) !== "{}"
                                            ) {
                                              const ot =
                                                formikProps.values.ObjectTypes.find(
                                                  (ot: any) =>
                                                    ot.ObjectTypeID ===
                                                    formikProps.values
                                                      .ObjectTypeID,
                                                ).ObjectTypeName;
                                              const errors = JSON.stringify(
                                                formikProps.errors,
                                              )
                                                .replace("{", "")
                                                .replace("}", "")
                                                .split(",");
                                              errors.map(
                                                (e: string, index: number) =>
                                                  (errors[index] = e.substring(
                                                    e.lastIndexOf(":") + 1,
                                                  )),
                                              );
                                              const message = `Failed to ${
                                                actionID === "new" || isCopyMode
                                                  ? "create"
                                                  : "update"
                                              } ${
                                                formikProps.values
                                                  .ObjectTypeName
                                                  ? formikProps.values
                                                      .ObjectTypeName
                                                  : ot
                                              }:\n\n${errors
                                                .map((error: string) =>
                                                  error
                                                    .replace(/\[/g, "")
                                                    .replace(/\]/g, "")
                                                    .replace(/\}/g, "")
                                                    .replace(/\{/g, ""),
                                                )
                                                .filter(
                                                  (error: any) =>
                                                    error !== "null",
                                                )
                                                .join("\n")}`;
                                              displayToast({
                                                status: "error",
                                                title: message,
                                              });
                                            } else {
                                              setSubscribedRecordsDialogVisible(
                                                true,
                                              );
                                            }
                                          }}>
                                          Update template and associated{" "}
                                          {formikProps.values.SubscribedRecords
                                            .length > 1
                                            ? "records"
                                            : "record"}
                                        </Button>
                                      </Col>
                                    </>
                                  ) : null}
                                  <Col
                                    sm={
                                      templateType &&
                                      formikProps.values.SubscribedRecords
                                        .length
                                        ? "auto"
                                        : { span: "auto", offset: "2" }
                                    }>
                                    <Button
                                      type="submit"
                                      onClick={() => {
                                        let errors: any[] = [];
                                        if (
                                          JSON.stringify(formikProps.errors) !==
                                          "{}"
                                        ) {
                                          const ot =
                                            formikProps.values.ObjectTypes.find(
                                              (ot: any) =>
                                                ot.ObjectTypeID ===
                                                formikProps.values.ObjectTypeID,
                                            ).ObjectTypeName;
                                          errors = JSON.stringify(
                                            formikProps.errors,
                                          )
                                            .replace("{", "")
                                            .replace("}", "")
                                            .split(",");
                                          errors.map(
                                            (e: string, index: number) =>
                                              (errors[index] = e.substring(
                                                e.lastIndexOf(":") + 1,
                                              )),
                                          );
                                          const message = `Failed to ${
                                            actionID === "new" || isCopyMode
                                              ? "create"
                                              : "update"
                                          } ${
                                            formikProps.values.ObjectTypeName
                                              ? formikProps.values
                                                  .ObjectTypeName
                                              : ot
                                          }:\n\n${errors
                                            .map((error: string) =>
                                              error
                                                .replace(/\[/g, "")
                                                .replace(/\]/g, "")
                                                .replace(/\}/g, "")
                                                .replace(/\{/g, ""),
                                            )
                                            .filter(
                                              (error: any) => error !== "null",
                                            )
                                            .join("\n")}`;
                                          displayToast({
                                            status: "error",
                                            title: message,
                                          });
                                        }
                                      }}>
                                      {pageStatus === "Editing" &&
                                      templateType &&
                                      formikProps.values.SubscribedRecords
                                        .length > 0
                                        ? "Update template only"
                                        : "Submit"}
                                    </Button>
                                  </Col>
                                  <Col sm="auto">
                                    <Button
                                      type="button"
                                      variant="light"
                                      onClick={() => {
                                        // remove the local storage after pressing cancel
                                        window.localStorage.removeItem(
                                          `${window.location.href}`,
                                        );
                                        setSavedData(null);

                                        if (isCopyMode) {
                                          setCopyMode(false);
                                          navigate(
                                            {
                                              pathname: `${
                                                templateType ? "/template" : ""
                                              }/action/${data.ActionID}`,
                                              search: display,
                                            },
                                            {
                                              state: {
                                                hasChanged: true,
                                                display: display
                                                  ? getUrlSearchParam(
                                                      location.search,
                                                      "display",
                                                    )
                                                  : "",
                                              },
                                            },
                                          );
                                        } else if (pageStatus === "Editing") {
                                          setData({
                                            ...data,
                                            QuestionWrapperReset:
                                              !data.QuestionWrapperReset,
                                          }); // a hack to reset the question wrapper when cancel is pressed
                                          formikProps.resetForm();
                                          if (savedData) {
                                            setData(originalData);
                                          }
                                          setPageStatus("Ready");
                                        } else if (
                                          formikProps.values.ParentIDs.length >=
                                          1
                                        ) {
                                          navigate(
                                            `${
                                              templateType ? "/template" : ""
                                            }/${data.ParentObjectTypeGroup}/${
                                              data.ParentIDs[0].ParentID
                                            }?display=${
                                              data.ParentObjectTypeGroup
                                            }s`,
                                          );
                                        } else {
                                          navigate(
                                            `${
                                              templateType
                                                ? "/template"
                                                : "/action"
                                            }`,
                                          );
                                        }
                                      }}>
                                      Cancel
                                    </Button>
                                  </Col>
                                </>
                              ) : null}
                            </Form.Group>
                            {templateType ? (
                              <Dialog
                                show={subscribedRecordsDialogVisible}
                                hide={() =>
                                  setSubscribedRecordsDialogVisible(false)
                                }
                                title="Confirm update"
                                bodyComponent={
                                  <div style={{ padding: "1em" }}>
                                    <Textarea
                                      value={`Are you sure you want to update ${
                                        formikProps.values.SubscribedRecords
                                          .length
                                      } associated ${
                                        formikProps.values.SubscribedRecords
                                          .length > 1
                                          ? "records"
                                          : "record"
                                      }?`}
                                      name="ConfirmUpdateSubscribedRecords"
                                      readOnly
                                      richText
                                    />
                                  </div>
                                }
                                confirm={() => {
                                  formikProps.setFieldValue(
                                    "UpdateSubscribedRecords",
                                    true,
                                  );
                                  // don't know what is blocking form submission but only works with a timeout
                                  setTimeout(
                                    () => formikProps.submitForm(),
                                    500,
                                  );
                                }}
                              />
                            ) : null}
                          </Form>
                        );
                      }}
                    />
                  </Col>
                </Row>
              </Collapse>
            </section>

            {pageStatus === "Ready" && !templateType ? (
              <TemplateDocumentGenerator
                data={data}
                authState={authState}
                recordID={data.ActionID}
              />
            ) : null}

            {pageStatus !== "New" && !isCopyMode && data.Instances ? (
              <Card
                title="Tasks"
                collapsable
                initialCollapsedState={data.Instances.length === 0}>
                <Table responsive borderless>
                  <thead>
                    <tr>
                      <th>Due</th>
                      <th>Lifecycle</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.Instances.length > 0 ? (
                      data.Instances.map((instance) => {
                        const lifecycle = calculateInstanceLifecycle(instance);
                        const objectStatus = calculateInstanceStatus(instance);
                        return (
                          <tr key={instance.InstanceID}>
                            <td>
                              <OverlayTrigger
                                placement="right"
                                overlay={
                                  <Tooltip id={instance.InstanceID}>
                                    {formatDateTime({
                                      date: instance.InstanceWhenTs,
                                      format: "DateAndTimeAndTimezone",
                                    })}
                                  </Tooltip>
                                }>
                                <Link
                                  to={`/instance/${instance.InstanceID}${display}`}>
                                  {formatDateTime({
                                    date: instance.InstanceWhenTs,
                                    format: "Date",
                                  })}
                                </Link>
                              </OverlayTrigger>
                            </td>
                            <td>
                              <LifeCycle
                                placement="top"
                                lifecycle={lifecycle}
                              />
                            </td>
                            <td>
                              <Status
                                placement="left"
                                variant={objectStatusToVariant(
                                  objectStatus.ObjectStatusID,
                                )}
                                text={objectStatus.ObjectStatusName}
                                tooltip={objectStatus.ObjectStatusDescription}
                              />
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan={4}>No Tasks</td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </Card>
            ) : null}
            {!templateType
              ? pageStatus === "Ready" && (
                  <Notes
                    isVisible={notes.isVisible}
                    noteType={notes.noteType}
                    record={{ type: "Action", id: data.ActionID }}
                    handleClose={() =>
                      setNotes({ isVisible: false, noteType: "" })
                    }
                    handleUpdate={(updatedNotes: Note[]) =>
                      setData({ ...data, Notes: updatedNotes })
                    }
                    data={data.Notes.filter(
                      (note: Note) => note.NoteTypeID === notes.noteType,
                    )}
                    taggables={data.ResponsibleUsers.map((user: any) => ({
                      id: `user/${user.UserID}`,
                      display: `${user.UserFirstName} ${user.UserLastName}`,
                    })).concat(
                      data.Roles.map((role: any) => ({
                        id: `role/${role.RoleID}`,
                        display: `${role.RoleName}`,
                      })),
                    )}
                  />
                )
              : null}
          </>
        ) : pageStatus !== "Error" ? (
          <>
            <div className="progress-spinner" style={{ marginTop: "20%" }}>
              <Loading size={"xl"} />
            </div>
          </>
        ) : (
          <>
            <Card2 style={{ marginTop: "20%", textAlign: "center" }}>
              <Row style={{ marginBottom: "20px" }}>
                <Col>
                  <Frown />
                </Col>
              </Row>
              <Row>
                <Col>
                  <h1>Oops! It looks like this action does not exist.</h1>
                </Col>
              </Row>
            </Card2>
          </>
        )}
      </>
    </LegacyScreenContainer>
  );
};

// for multi instances, display the worst case scenario for lifecycle and object status
const calculateInstanceLifecycle = (instance: any) => {
  if (
    instance.Duplicates &&
    (instance.LifeCycle === "Due" ||
      instance.Duplicates.find((response: any) => response.LifeCycle === "Due"))
  ) {
    return "Due";
  }
  return instance.LifeCycle;
};
const calculateInstanceStatus = (instance: any) => {
  if (instance.Duplicates) {
    const negativeResponse = instance.Duplicates.find(
      (response: any) => response.ObjectStatusID === ObjectStatus.Negative,
    );
    if (negativeResponse || instance.ObjectStatusID === ObjectStatus.Negative) {
      return negativeResponse || instance;
    }

    const neutralResponse = instance.Duplicates.find(
      (response: any) => response.ObjectStatusID === ObjectStatus.Neutral,
    );
    if (neutralResponse || instance.ObjectStatusID === ObjectStatus.Neutral) {
      return neutralResponse || instance;
    }

    return instance;
  }
  return instance;
};

export { ScreensAction };
