import { Icon, MenuItem, MenuList, Portal } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { RegisterIcon } from "../../../constants/commonIcons";
import RegisterMenuList from "./RegisterMenuList";
import TopBarMenu from "./TopBarMenu";

type Props = {
  isInOverflow?: boolean;
};

const RegisterNavItem = ({ isInOverflow }: Props) => {
  if (isInOverflow) {
    return (
      <MenuItem
        icon={<Icon boxSize="4" as={RegisterIcon} />}
        as={Link}
        to="/register">
        Registers
      </MenuItem>
    );
  }

  return (
    <TopBarMenu
      isLazy
      title="Registers"
      leftIcon={<Icon boxSize="4" as={RegisterIcon} />}>
      <Portal>
        <chakra-scope>
          <MenuList zIndex={12}>
            <RegisterMenuList />
          </MenuList>
        </chakra-scope>
      </Portal>
    </TopBarMenu>
  );
};

export default RegisterNavItem;
