import {
  ChakraProvider,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  useBreakpointValue,
  useDisclosure,
} from "@chakra-ui/react";
import { Auth, Hub } from "aws-amplify";
import React, { useCallback, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
  useMatch,
  useNavigate,
  useSearchParams,
} from "react-router-dom";

// Mantine styles
import "@mantine/core/styles.css";
import "@mantine/dropzone/styles.css";

import "./App.css";
import "./manualTiptap.css";

import {
  ActiveAppContextType,
  ActiveAppProvider,
  AppState,
  CoreAppInfo,
  createInitialAppState,
  UnvalidatedCoreAppInfo,
  useActiveAppState,
} from "./components/App/AppProvider";
import IdleTimer from "./components/App/IdleTimer";
import LicenseFeeCalculator from "./components/chakra/LicenseFeeCalculator";
import Redirect from "./components/common/Redirect";
import AuthComponent from "./components/login/AuthComponent";
import CustomSidebar from "./components/login/CustomSidebar";
import { Sidebar } from "./components/UI/Sidebar/Sidebar";
import SidebarStateManager from "./components/UI/Sidebar/SidebarStateManager";
import { ScreensAction } from "./screens/Action/Action";
import { ScreensActionDashboard } from "./screens/Action/Dashboard";
import { ScreensOnDemandAction } from "./screens/Action/OnDemandAction";
import CSSReset from "./screens/ChakraCSSReset";
import { ScreensSingleCustomField } from "./screens/CustomFields/SingleCustomField";
import { ScreensDocuments } from "./screens/Documents/Documents";
import { ErrorBoundary } from "./screens/ErrorBoundary/ErrorBoundary";
import { ScreensExport } from "./screens/Export/Export";
import { HistoryScreen } from "./screens/History/History";
import { ScreensHome } from "./screens/Home/Home";
import AccountDisabledScreen from "./screens/Home/HomeAccountDisabled";
import HomeDashboardScreen from "./screens/Home/HomeDashboardScreen";
import HomeLoading from "./screens/Home/HomeLoading";
import { ScreensInstance } from "./screens/Instance/Instance";
import { ScreensIssueDashboard } from "./screens/Issue/Dashboard";
import { ScreensJobDashboard } from "./screens/Job/Dashboard";
import { ScreensSingleList } from "./screens/List/SingleList";
import InviteScreen from "./screens/Login/InviteScreen";
import LoginScreen from "./screens/Login/LoginScreen";
import MainLayout from "./screens/MainLayout";
import NotFoundScreen from "./screens/NotFound";
import { ScreensPageDocuments } from "./screens/Pages/PageDocuments";
import { ScreensPageFields } from "./screens/Pages/PageFields";
import { ScreensPageSettings } from "./screens/Pages/PageSettings";
import { ScreensPages } from "./screens/Pages/Pages";
import CSVUploaderScreen from "./screens/Register/CSVUploader";
import { ScreensRegisterDashboard as LegacyRegisterDashboardScreen } from "./screens/Register/Dashboard";
import MassUploadScreen from "./screens/Register/MassUploadRegisters";
import RegisterDashboardScreen from "./screens/_chakra/RegisterDashboardScreen";
import RegisterPagesScreen from "./screens/Register/RegisterPagesScreen";
import RegisterRecordScreen from "./screens/Register/RegisterRecordScreen";
import ReportRoutes from "./screens/Report/ReportRoutes";
import RequirementDashboardScreen from "./screens/_chakra/RequirementDashboardScreen";
import { ScreensRequirement } from "./screens/Requirement/Requirement";
import { ScreensRiskDashboard } from "./screens/Risk/Dashboard";
import { ScreensRiskMatrix } from "./screens/RiskMatrix/RiskMatrix";
import ServicePortalRoutes from "./screens/serviceAdmin/ServicePortalRoutes";
import { ScreensAccount } from "./screens/Settings/Account";
import AccountSettingsScreen from "./screens/_chakra/settings/AccountSettingsScreen";
import { ScreensAccountCalendar } from "./screens/Settings/AccountCalendar";
import AdminRoutes from "./screens/Settings/AdminRoutes";
import { ScreensAccountExcludedDate } from "./screens/Settings/ExcludedDate";
import { HubSpotCallback } from "./screens/Settings/Integrations/HubSpotCallback";
import { Integrations } from "./screens/Settings/Integrations/Integrations";
import { MicrosoftCallback } from "./screens/Settings/Integrations/OneDriveCallback";
import { WorkflowMaxIntegrationCallback } from "./screens/Settings/Integrations/WorkflowMaxCallback";
import { ScreensSingleRole } from "./screens/Settings/SingleRole";
import { SingleUserScreen } from "./screens/Settings/SingleUser";
import CustomFieldsScreen from "./screens/_chakra/settings/CustomFieldsScreen";
import ListScreen from "./screens/_chakra/settings/ListsScreen";
import Pages from "./screens/_chakra/settings/Pages";
import RolesScreen from "./screens/_chakra/settings/RolesScreen";
import UsersScreen from "./screens/_chakra/settings/UsersScreen";
import * as UserAccountStatusID from "./shared/v2/constants/UserAccountStatusID";
import chakraTheme from "./theme";
import * as APIBelRequest from "./utilities/apibelRequest";
import * as Request from "./utilities/request";
import useSystemMode from "./utilities/useSystemMode";
import useToast from "./utilities/useToast";
import ProfileScreen from "./screens/_chakra/settings/ProfileScreen";
import BlueprintRoutes from "./screens/_mantine/blueprints/BlueprintRoutes";
import { ScreensPrintReport } from "./screens/Report/PrintReportPage";
import ManualRoutes from "./screens/_chakra/manual/ManualRoutes";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000,
      cacheTime: 1000 * 10,
      retry: 0, // Disable retries - temp
      refetchOnWindowFocus: false,
    },
  },
});

type MainRoutesProps = {};

const MainRoutes = () => {
  const [sidebarWidth, setSidebarWidth] = useState(325);
  const { appState } = useActiveAppState();
  const [searchParams] = useSearchParams();
  const isTemplate = useSystemMode() === "template";
  const sidebarDrawerState = useDisclosure({ defaultIsOpen: false });

  // Check if were a legacy page that needs to display the sidebar still
  const isLegacyRisk = !!useMatch("/risk/*");
  const isLegacyReport = !!useMatch("/report/*");
  const isLegacyIssue = !!useMatch("/issue/*");
  // const isLegacyRegister = !!useMatch("/register/*");
  // const isLegacyRequirement = !!useMatch("/requirement/*");
  const isLegacyPage = isLegacyIssue || isLegacyReport || isLegacyRisk;

  const shouldUseSidebarDrawer = useBreakpointValue({ base: true, lg: false });

  const displayParam = searchParams.get("display");

  const shouldDisplaySidebar =
    isTemplate || (displayParam && displayParam.length >= 1) || isLegacyPage;

  const showDevTools = appState.app.userInfo.isInternal;
  return (
    <SidebarStateManager>
      <MainLayout
        handleClickSidebarDrawer={sidebarDrawerState.onOpen}
        showSidebarDrawerButton={Boolean(
          shouldDisplaySidebar && shouldUseSidebarDrawer,
        )}>
        {shouldDisplaySidebar && shouldUseSidebarDrawer && (
          <Drawer
            size="md"
            isOpen={sidebarDrawerState.isOpen}
            onClose={sidebarDrawerState.onClose}
            placement="left">
            <DrawerOverlay />
            <chakra-scope>
              <DrawerContent>
                <DrawerBody>
                  <Sidebar
                    onSignOut={() => {}}
                    closeSidebar={sidebarDrawerState.onClose}
                    openSidebar={sidebarDrawerState.onOpen}
                    mobileIsOpen={sidebarDrawerState.isOpen}
                    setMobileOpen={sidebarDrawerState.onOpen}
                    isInDrawer
                  />
                </DrawerBody>
              </DrawerContent>
            </chakra-scope>
          </Drawer>
        )}
        <Row style={{ margin: 0 }}>
          {shouldDisplaySidebar && !shouldUseSidebarDrawer && (
            <Col
              sm="auto"
              className="sidebar-col"
              style={{ position: "fixed", padding: 0 }}>
              <CustomSidebar
                width={sidebarWidth}
                onWidthChange={setSidebarWidth}
                onSignOut={() => {}}
                renderSidebar
              />
            </Col>
          )}
          <Col
            style={{
              paddingRight: 0,
              paddingLeft: 0,
              marginLeft: shouldDisplaySidebar
                ? shouldUseSidebarDrawer
                  ? "8px"
                  : sidebarWidth
                : 0,
            }}>
            <ErrorBoundary>
              <Routes>
                <Route
                  path="manual/*" element={<ManualRoutes />} />
                <Route
                  path="instance/:instanceID"
                  element={<ScreensInstance />}
                />
                <Route path="action/:actionID" element={<ScreensAction />} />
                {showDevTools && (
                  <Route path="__pricing" element={<LicenseFeeCalculator />} />
                )}
                <Route
                  path="action?:category"
                  element={<ScreensActionDashboard />}
                />
                <Route path="action" element={<ScreensActionDashboard />} />
                <Route
                  path="requirement/:requirementID"
                  element={<ScreensRequirement />}
                />
                <Route
                  path="requirement"
                  element={<RequirementDashboardScreen />}
                />
                <Route
                  path="ondemandaction/:requirementOnDemandActionID"
                  element={<ScreensOnDemandAction />}
                />
                <Route
                  path="issue/:requirementID"
                  element={<ScreensRequirement />}
                />
                <Route path="issue" element={<ScreensIssueDashboard />} />
                <Route
                  path="history/:referenceID"
                  element={<HistoryScreen />}
                />
                <Route path="history" element={<HistoryScreen />} />

                <Route
                  path="register/:requirementID"
                  element={<ScreensRequirement />}
                />
                <Route path="register" element={<RegisterPagesScreen />} />
                <Route path="registernew" element={<RegisterPagesScreen />} />
                <Route
                  path="register/dashboard/:objectTypeID"
                  element={<RegisterDashboardScreen />}
                />
                <Route
                  path="register/legacy-dashboard"
                  element={<LegacyRegisterDashboardScreen />}
                />
                <Route
                  path="registernew/:recordID"
                  element={<RegisterRecordScreen />}
                />
                <Route
                  path="register/upload/:registerID"
                  element={<CSVUploaderScreen />}
                />
                <Route path="register/upload" element={<MassUploadScreen />} />

                <Route
                  path="risk/:requirementID"
                  element={<ScreensRequirement />}
                />
                <Route path="risk" element={<ScreensRiskDashboard />} />
                <Route path="report/*" element={<ReportRoutes />} />
                <Route
                  path="job/:requirementID"
                  element={<ScreensRequirement />}
                />

                <Route path="settings" element={<ScreensAccount />} />
                <Route path="settings/account" element={<ScreensAccount />} />
                <Route
                  path="settings/customfields"
                  element={<CustomFieldsScreen />}
                />
                <Route
                  path="settings/customField/:customFieldID"
                  element={<ScreensSingleCustomField />}
                />
                <Route
                  path="settings/role/:roleID"
                  element={<ScreensSingleRole />}
                />
                <Route path="settings/users" element={<UsersScreen />} />
                <Route path="settings/roles" element={<RolesScreen />} />

                <Route path="settings/profile" element={<ProfileScreen />} />
                <Route
                  path="settings/user/:userID"
                  element={<SingleUserScreen />}
                />
                <Route path="settings/pages" element={<ScreensPages />} />
                <Route
                  path="settings/pages/fields"
                  element={<ScreensPageFields />}
                />
                <Route
                  path="settings/pages/documents"
                  element={<ScreensPageDocuments />}
                />
                <Route
                  path="settings/account/calendar/excludeddate"
                  element={<ScreensAccountExcludedDate />}
                />
                <Route
                  path="settings/account/calendar"
                  element={<ScreensAccountCalendar />}
                />
                <Route path="settings/lists" element={<ListScreen />} />
                <Route
                  path="settings/list/documents"
                  element={<ScreensDocuments />}
                />
                <Route
                  path="settings/list/:listID"
                  element={<ScreensSingleList />}
                />

                <Route
                  path="settings/riskMatrix"
                  element={<ScreensRiskMatrix />}
                />
                <Route
                  path="settings/integrations"
                  element={<Integrations />}
                />
                <Route
                  path="settings/xerocallback"
                  element={<WorkflowMaxIntegrationCallback />}
                />
                <Route
                  path="settings/microsoftcallback"
                  element={<MicrosoftCallback />}
                />
                <Route
                  path="settings/hubspotcallback"
                  element={<HubSpotCallback />}
                />

                <Route path="export/:exportID" element={<ScreensExport />} />

                <Route path="job" element={<ScreensJobDashboard />} />

                <Route
                  path="settings/pages/settings"
                  element={<ScreensPageSettings />}
                />

                <Route
                  path="template/requirement/:requirementID"
                  element={<ScreensRequirement />}
                />
                <Route
                  path="template/register/:requirementID"
                  element={<ScreensRequirement />}
                />
                <Route
                  path="template/issue/:requirementID"
                  element={<ScreensRequirement />}
                />
                <Route
                  path="template/risk/:requirementID"
                  element={<ScreensRequirement />}
                />
                <Route
                  path="template/job/:requirementID"
                  element={<ScreensRequirement />}
                />
                <Route
                  path="template/action/:actionID"
                  element={<ScreensAction />}
                />

                <Route path="template/*" element={<ScreensHome />} />

                <Route path="invite" element={<InviteScreen />} />

                <Route path="caadmin/*" element={<AdminRoutes />} />

                <Route
                  path="_ccserviceportal/*"
                  element={<ServicePortalRoutes />}
                />

                <Route index element={<HomeDashboardScreen />} />
                <Route path="*" element={<NotFoundScreen />} />
              </Routes>
            </ErrorBoundary>
          </Col>
        </Row>
      </MainLayout>
    </SidebarStateManager>
  );
};

type ProtectedRoutesProps = {
  baseAppState: AppState;
  setBaseAppState: React.Dispatch<React.SetStateAction<AppState>>;
};
const ProtectedRoutes = ({
  baseAppState,
  setBaseAppState,
}: ProtectedRoutesProps) => {
  const location = useLocation();

  if (baseAppState.type === "preparing") {
    return (
      <Col className="mainPane">
        <HomeLoading info="Preparing account data..." />
      </Col>
    );
  }
  if (baseAppState.type === "loggedout") {
    const redirectLocation = encodeURIComponent(
      `${location.pathname}${location.search}`,
    );
    return <Redirect to={`/login?redirect=${redirectLocation}`} />;
  }

  // BaseAppState is 'ready'
  // Valdiate account info

  const { accountInfo } = baseAppState.app;

  // If user doesnt have an account or if its inactive or if theyve been removed from the account
  if (
    accountInfo === null ||
    accountInfo.userAccountStatusID === UserAccountStatusID.Inactive ||
    !accountInfo.active
  ) {
    return (
      <Col className="main-pane">
        <AccountDisabledScreen userAccounts={baseAppState.app.userAccounts} />
      </Col>
    );
  }

  // If the user is pending, show them the invite screen
  if (accountInfo.userAccountStatusID === UserAccountStatusID.Pending) {
    return (
      <Col className="main-pane">
        <InviteScreen code={accountInfo.userAccountID} />
      </Col>
    );
  }

  const appInfo: CoreAppInfo = {
    ...baseAppState.app,
    accountInfo,
  };

  const appCtx: ActiveAppContextType = {
    appState: {
      ...baseAppState,
      app: appInfo,
      type: "active",
    },
    setAppState: setBaseAppState,
    setPageTitle: (title: string) =>
      setBaseAppState((prev) => ({ ...prev, currentPageTitle: title })),
  };
  return (
    <ActiveAppProvider activeAppContext={appCtx}>
      <Routes>
        <Route path="*" element={<MainRoutes />} />
        <Route path="blueprint/*" element={<BlueprintRoutes />} />
        <Route path="print/report/:reportID" element={<ScreensPrintReport />} />
      </Routes>
    </ActiveAppProvider>
  );
};

// anything that exists over each page goes here
const App = () => {
  const [baseAppState, setBaseAppState] = useState<AppState>(
    createInitialAppState(),
  );
  const navigate = useNavigate();

  const { displayToast } = useToast();

  const handleSignOut = useCallback(() => {
    Auth.signOut()
      .then(() => navigate("/"))
      .catch((err) => console.log(err));
  }, [navigate]);

  useEffect(() => {
    const listener = (data: any) => {
      switch (data.payload.event) {
        case "tokenRefresh_failure":
          setBaseAppState({
            type: "loggedout",
            currentPageTitle: "Home",
          });
          // Auth.signOut().then(() => window.location.reload());
          break;
        default:
          break;
      }
    };

    Hub.listen("auth", listener);

    return () => {
      Hub.remove("auth", listener);
    };
  }, []);

  // Get actual app state once we are logged in
  useEffect(() => {
    const getAppState = async () => {
      try {
        if (baseAppState.type !== "preparing") return;
        const { auth } = baseAppState;
        if (auth.apiToken) {
          const app = await APIBelRequest.get("app/main", null);
          const appLEGACY = await Request.get("app");
          const currentUser = await Auth.currentAuthenticatedUser();
          if (!currentUser) {
            throw new Error("No logged in user");
          }
          const userAttributes = await Auth.userAttributes(currentUser);
          const attributes = userAttributes.reduce(
            (object, attribute) => ({
              ...object,
              [attribute.getName().replace("custom:", "")]:
                attribute.getValue(),
            }),
            {},
          );
          const { userAccounts, account, permissions, userInfo } = app;

          console.log("app", app);
          const appData: UnvalidatedCoreAppInfo = {
            accountInfo: account,
            userAccounts,
            permissions,
            userInfo,
            attributes,
            permissions_LEGACY: appLEGACY.data.Permissions,
            features: {
              enabled: app.features.enabledFeatures,
            }
          };
          setBaseAppState({
            type: "ready",
            app: appData,
            auth: baseAppState.auth,
            currentPageTitle: baseAppState.currentPageTitle,
          });
        }
      } catch (error) {
        console.error(error);
        // Push user to login screen
        setBaseAppState({
          type: "loggedout",
          currentPageTitle: baseAppState.currentPageTitle,
        });
        displayToast({
          status: "warning",
          title: "Failed to load your account",
          description:
            "Your login session may have expired. Please sign in again, or check your internet connection then refresh the page.",
        });
      }
    };
    getAppState();
  }, [baseAppState, displayToast]);

  // Timeout stuff
  const handleTimeout = useCallback(() => {
    window.localStorage.clear();
    handleSignOut();
  }, [handleSignOut]);

  useEffect(() => {
    if (baseAppState.type === "ready") {
      const timeout = baseAppState.app.accountInfo?.accountTimeout ?? null;
      if (timeout !== null) {
        const timer = new IdleTimer({
          timeout: timeout * 60 + 1,
          onTimeout: handleTimeout,
          onExpired: handleTimeout,
        });
        return () => timer.cleanUp();
      }
    }
    return () => {};
  }, [baseAppState, handleTimeout]);

  // Our main routes either renders LoginScreen or ProtectedRoutes
  // The auth ui exists within the cognito auth component, so the login screen itself is just blank
  // however it handles redirecting once the user is logged in
  // The ProtectedRoutes handles rendering the rest of our app, and handles redirecting back to the login screen

  return (
    <>
      <AuthComponent
        baseAppState={baseAppState}
        updateBaseAppState={setBaseAppState}
      />
      <Routes>
        <Route
          path="/login"
          element={<LoginScreen baseAppState={baseAppState} />}
        />
        <Route
          path="*"
          element={
            <ProtectedRoutes
              setBaseAppState={setBaseAppState}
              baseAppState={baseAppState}
            />
          }
        />
      </Routes>
    </>
  );
};

const AppWithRouter: React.FC = () => (
  <ChakraProvider theme={chakraTheme} resetCSS={false}>
    <QueryClientProvider client={queryClient}>
      <CSSReset />
      <Router>
        <ErrorBoundary>
          <App />
        </ErrorBoundary>
      </Router>
    </QueryClientProvider>
  </ChakraProvider>
);

export default AppWithRouter;
