import {
  Avatar,
  Badge,
  Box,
  Button,
  Center,
  chakra,
  Flex,
  HStack,
  Input,
  LinkBox,
  LinkOverlay,
  Modal,
  ModalContent,
  ModalOverlay,
  Stat,
  StatLabel,
  StatNumber,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tfoot,
  Th,
  Thead,
  Tooltip,
  Tr,
  IconButton,
  useBreakpointValue,
  useColorModeValue,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { Trash2 as Trash } from "react-feather";
import { useAppState } from "../../../components/App/AppProvider";
import MissingPermission from "../../../components/chakra/common/MissingPermission";
import Spinner from "../../../components/chakra/common/Spinner";
import TableSectionHeading from "../../../components/chakra/table/TableSectionHeading";
import Icon from "../../../components/UI/Icon";
import * as UserAccountStatusID from "../../../shared/v2/constants/UserAccountStatusID";
import { UserSummary } from "../../../shared/v2/definitions/user";
import { useApiQuery } from "../../../utilities/apibelRequest";
import assertNever from "../../../utilities/assertNever";
import { downloadAsCsv } from "../../../utilities/downloadAsCsv";
import NewUserModal from "./NewUserModal";
import SettingsScreenContainer from "./SettingsScreenContainer";
import UserModal from "./UserModal";

const getAccountStatusName = (
  status: UserAccountStatusID.UserAccountStatus,
): string => {
  switch (status) {
    case UserAccountStatusID.Active:
      return "Active";
    case UserAccountStatusID.Inactive:
      return "Inactive";
    case UserAccountStatusID.Pending:
      return "Pending";
    default:
      return assertNever(status);
  }
};

export default function UsersScreen() {
  const emptyColor = useColorModeValue("blackAlpha.500", "whiteAlpha.500");
  const emailColor = useColorModeValue("blackAlpha.700", "whiteAlpha.700");
  const tableSize = useBreakpointValue({ base: "sm", lg: "md" }) as "md" | "lg";
  const newUserModalState = useDisclosure();
  const UserModalState = useDisclosure();
  const [search, setSearch] = useState("");
  const { permissions, accountInfo } = useAppState().app;
  const queryClient = useQueryClient();

  const [selectedUser, setSelectedUser] = useState<UserSummary | undefined>(
    undefined,
  );

  const hasReadPermission = permissions.USER_SETTINGS.READ.ALL;
  const hasCreatePermision = permissions.USER_SETTINGS.CREATE.ALL;

  const usersQuery = useApiQuery("user/allUsers", null, {
    enabled: hasReadPermission,
  });

  
  const licenseQuery = useApiQuery("user/accountLicense", null);

  const rolesQuery = useApiQuery("role/allRoles", null);

  const handleClickRefresh = () => {
    queryClient.invalidateQueries("user");
    setSearch("");
  };

  const handleClickExport = () => {
    if (!usersQuery.isSuccess) {
      return;
    }
    const data = usersQuery.data.map(
      ({ email, firstName, lastName, roles }) => ({
        Email: email,
        "First Name": firstName,
        "Last Name": lastName,
        Roles: roles.map((r) => r.name).join(", "),
      }),
    );
    const filename = "all-users.csv";
    downloadAsCsv(data, filename);
  };

  const users = usersQuery.isSuccess ? usersQuery.data : [];
  const usedLicenses = users.filter((user) => !user.isInternal).length;

  const lowerCaseSearch = search.toLowerCase();
  const filteredUsers = users.filter((user) => {
    if (lowerCaseSearch) {
      return (
        user.email.toLowerCase().includes(lowerCaseSearch) ||
        `${user.firstName} ${user.lastName}`
          .toLowerCase()
          .includes(lowerCaseSearch)
      );
    }
    return true;
  });
  const normalUsers = filteredUsers
    .filter((user) => !user.isInternal)
    .sort((a, b) => a.firstName.localeCompare(b.firstName));
  const internalUsers = filteredUsers.filter((user) => user.isInternal);
  const accountOwnerID = accountInfo.accountOwnerUserID;

  const renderRow = (user: UserSummary) => (
    <LinkBox key={user.userID} as={Tr}>
      <Td _hover={{ textDecoration: "underline" }}>
        <LinkOverlay
          ps="2"
          as={Box}
          display="flex"
          cursor="pointer"
          alignItems="center"
          onClick={() => {
            setSelectedUser(user);
            UserModalState.onOpen();
          }}>
          <Avatar mr="4" size="sm" />

          <Flex flexDir="column" align="start" as="span">
            <span>
              {user.firstName} {user.lastName}
              {accountOwnerID === user.userID && (
                <Badge ms="1" colorScheme="gray">
                  Account Owner
                </Badge>
              )}
            </span>
            <Text as="span" fontSize="sm" color={emailColor}>
              {user.email}
            </Text>
          </Flex>
        </LinkOverlay>
      </Td>
      <Td>{getAccountStatusName(user.userAccountStatus)}</Td>
      <Td>
        {user.roles.length >= 1 ? (
          user.roles.map((role) => role.name).join(", ")
        ) : (
          <HStack as="span" color={emptyColor} fontStyle="italic">
            <Tooltip label="A user with no roles will not be able to access the system">
              <chakra.span>
                <Icon color="blue" name="Info" />
              </chakra.span>
            </Tooltip>
            <chakra.span>No Roles</chakra.span>
          </HStack>
        )}
      </Td>

    </LinkBox>
  );

  return (
    <chakra-scope>
      <SettingsScreenContainer
        pageTitle="Users"
        headerEndContent={
          hasReadPermission && hasCreatePermision ? (
            <Button
              role="link"
              variant="solid"
              onClick={newUserModalState.onOpen}
              size="sm"
              leftIcon={<Icon name="Send" />}>
              Invite User
            </Button>
          ) : null
        }>
        {!hasReadPermission ? (
          <MissingPermission />
        ) : (
          <VStack align="start">
            <Tooltip
              label={
                licenseQuery.isSuccess
                  ? licenseQuery.data.userLicenses <= 0
                    ? ""
                    : usedLicenses > licenseQuery.data.userLicenses
                      ? "You have more users than you have licenses. Please contact help@certcrowd.com to increase your licenses"
                      : usedLicenses === licenseQuery.data.userLicenses
                        ? "You have used all of your available licenses. If you want to invite more users, please contact help@certcrowd.com"
                        : "Contact help@certcrowd.com to increase your available licenses"
                  : ""
              }>
              <Stat>
                <StatLabel>
                  <HStack>
                    <span>Total Users</span>
                    {licenseQuery.isSuccess &&
                      licenseQuery.data.userLicenses > 0 && (
                        <Icon name="Info" />
                      )}
                  </HStack>
                </StatLabel>
                <StatNumber
                  color={
                    licenseQuery.isSuccess &&
                      licenseQuery.data.userLicenses > 0 &&
                      usedLicenses > licenseQuery.data.userLicenses
                      ? "red.700"
                      : undefined
                  }>
                  {usersQuery.isSuccess && licenseQuery.isSuccess ? (
                    <span>
                      {usedLicenses}{" "}
                      {licenseQuery.data.userLicenses > 0 && (
                        <chakra.span fontSize="md">
                          (of {licenseQuery.data.userLicenses} allowed licenses)
                        </chakra.span>
                      )}
                    </span>
                  ) : (
                    "-"
                  )}
                </StatNumber>
              </Stat>
            </Tooltip>
            <Box w="full">
              <HStack justify="space-between">
                <Input
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  size="sm"
                  maxW="64"
                  placeholder="Search by name or email"
                />

                <HStack>
                  <Button
                    isLoading={usersQuery.isRefetching}
                    disabled={usersQuery.isFetching}
                    onClick={handleClickRefresh}
                    leftIcon={<Icon name="RefreshCw" />}
                    variant="ghost"
                    loadingText="Refreshing"
                    size="sm">
                    Refresh
                  </Button>
                  {hasReadPermission ? (
                    <Button
                      disabled={usersQuery.isFetching}
                      onClick={handleClickExport}
                      leftIcon={<Icon name="Download" />}
                      variant="ghost"
                      size="sm">
                      Export as CSV
                    </Button>
                  ) : null}
                </HStack>
              </HStack>
              <TableContainer w="full" pt={2} pb={4}>
                <Table
                  isFetching={usersQuery.isFetching}
                  variant="data-table"
                  size={tableSize}
                  position="relative">
                  <Thead position="relative">
                    {usersQuery.isFetching && (
                      <Spinner
                        zIndex={1}
                        opacity={1}
                        size="lg"
                        position="absolute"
                        bottom={-16}
                        left="50%"
                      />
                    )}
                    <Tr>
                      <Th>User</Th>
                      <Th>Status</Th>
                      <Th>Roles</Th>
                    </Tr>
                  </Thead>
                  {usersQuery.isSuccess && (
                    <>
                      <Tbody>
                          {normalUsers.sort((a, b)=>(
                            `${a.firstName} ${a.lastName}`
                            .localeCompare(`${b.firstName} ${b.lastName}`)
                          )).map(renderRow)}
                        {internalUsers.length >= 1 && (
                          <>
                            <TableSectionHeading
                              title="CertCrowd Staff"
                              text="These users do not count towards your licenses"
                            />
                              {internalUsers.sort((a, b)=>(
                                `${a.firstName} ${a.lastName}`
                                .localeCompare(`${b.firstName} ${b.lastName}`)
                              )).map(renderRow)}
                          </>
                        )}
                      </Tbody>
                      <Tfoot>
                        <Tr>
                          <Th>User</Th>
                          <Th>Status</Th>
                          <Th>Roles</Th>
 
                        </Tr>
                      </Tfoot>
                    </>
                  )}
                </Table>
              </TableContainer>
              {usersQuery.isLoading && (
                <Center mt="4">
                  <Spinner size="lg" />
                </Center>
              )}
            </Box>
          </VStack>
        )}

        <Modal
          motionPreset="slideInBottom"
          size="4xl"
          isOpen={UserModalState.isOpen}
          onClose={UserModalState.onClose}>
          <ModalOverlay />
          <ModalContent>
            <UserModal
              onClose={(refresh?: boolean) => {
                if (refresh) {
                  usersQuery.refetch();
                }
                UserModalState.onClose();
                setSelectedUser(undefined);
              }}
              accountOwnerID={accountOwnerID}
              user={selectedUser}
              users={usersQuery.data}
              roles={rolesQuery.data}
            />
          </ModalContent>
        </Modal>
        <Modal
          motionPreset="slideInBottom"
          size="4xl"
          isOpen={newUserModalState.isOpen}
          onClose={newUserModalState.onClose}>
          <ModalOverlay />
          <ModalContent>
            <NewUserModal onClose={newUserModalState.onClose} />
          </ModalContent>
        </Modal>
      </SettingsScreenContainer>
    </chakra-scope>
  );
}
