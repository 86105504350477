import { AxiosResponse } from "axios";
import React, { createContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { isJobsAccount } from "../../../constants/JobsVisibleCheck";
import * as ObjectTypeGroup from "../../../constants/objectTypeGroup";
import * as Permissions from "../../../shared/constants/permission";
import * as UserAccountStatusID from "../../../shared/constants/userAccountStatus";
import * as Access from "../../../utilities/access";
import { get } from "../../../utilities/request";
import { ActiveAppState, useActiveAppState } from "../../App/AppProvider";
import {
  AppNavData,
  DashboardCategory,
  DashboardCategoryItem,
} from "./sidebar.d";

const getdashboardCategories = (appState: ActiveAppState) => {
  const { isInternal } = appState.app.userInfo;
  const isJobs = isJobsAccount(appState);
  const dashboardCategories: DashboardCategory[] = [
    { title: "Dashboard", id: "Dashboard", link: "/", icon: "Grid" },
    ...(isInternal
      ? [
          {
            title: "Registers (New!)",
            id: "RegistersNew",
            link: "/registernew",
            icon: "Layers",
          },
        ]
      : []),
    {
      title: "Registers",
      id: "Registers",
      link: "/register?display=registers",
      icon: "Layers",
    },
    {
      title: "Requirements",
      id: "Requirements",
      link: "/requirement?display=requirements",
      icon: "FileText",
    },
    {
      title: "Actions",
      id: "Actions",
      link: "/action?display=actions",
      icon: "Edit",
    },
    {
      title: "Issues",
      id: "Issues",
      link: "/issue?display=issues",
      icon: "AlertCircle",
    },
    {
      title: "Reports",
      id: "Reports",
      link: "/report?display=reports",
      icon: "File",
    },
    ...(isJobs
      ? [
          {
            title: "Jobs",
            id: "Jobs",
            link: "/job?display=jobs",
            icon: "Briefcase",
          },
        ]
      : []),
    {
      title: "Risks",
      id: "Risks",
      link: "/risk?display=risks",
      icon: "AlertTriangle",
    },
    {
      title: "Settings",
      id: "Settings",
      link: "/settings/account",
      icon: "Settings",
    },
    {
      title: "Help",
      id: "Help",
      link: "https://help.certcrowd.com",
      icon: "HelpCircle",
    },
  ];
  return dashboardCategories;
};

const getSettingsCategories = (appState: ActiveAppState) => {
  let settingsCategories: DashboardCategoryItem[] = [];

  if (
    Access.checkAccess(
      appState.app.permissions_LEGACY,
      Permissions.CodeAccountSettings,
      Permissions.TypeUpdate,
      false,
    )
  ) {
    settingsCategories = [
      { title: "Account", link: "/settings/account" },
      { title: "Account Calendar", link: "/settings/accountcalendar" },
      {
        title: "Pages & Fields",
        link: "",
        children: [
          { title: "Custom Fields", link: "/settings/customfields" },
          { title: "Pages", link: "/settings/pages" },
          { title: "Lists", link: "/settings/lists" },
          { title: "Template Management", link: "" },
        ],
      },
      {
        title: "Permissions",
        link: "",
        children: [
          { title: "Users", link: "/settings/users" },
          { title: "Roles", link: "/settings/roles" },
        ],
      },
      {
        title: "Risk",
        link: "",
        children: [{ title: "Risk Matrix", link: "/settings/riskmatrix" }],
      },
      { title: "Integrations", link: "/settings/integrations" },
    ];
  } else {
    settingsCategories = [
      { title: "Account", link: "/settings/account" },
      { title: "Account Calendar", link: "/settings/accountcalendar" },
      {
        title: "Pages & Fields",
        link: "",
        children: [
          { title: "Custom Fields", link: "/settings/customfields" },
          { title: "Pages", link: "/settings/pages" },
          { title: "Lists", link: "/settings/lists" },
          { title: "Template Management", link: "" },
        ],
      },
      {
        title: "Permissions",
        link: "",
        children: [
          { title: "Users", link: "/settings/users" },
          { title: "Roles", link: "/settings/roles" },
        ],
      },
      {
        title: "Risk",
        link: "",
        children: [{ title: "Risk Matrix", link: "/settings/riskmatrix" }],
      },
    ];
  }
  return settingsCategories;
};

const actionsCategoriesNormal: DashboardCategory[] = [
  {
    title: "Open Responded",
    id: "Open Responded",
    link: "/action?filter=openresponded&display=actions",
    icon: "Edit",
    topLevel: true,
  },
  {
    title: "Upcoming",
    id: "Upcoming",
    link: "/action?filter=upcoming&display=actions",
    icon: "Edit",
    topLevel: true,
  },
  {
    title: "Due",
    id: "Due",
    link: "/action?filter=due&display=actions",
    icon: "Edit",
    topLevel: true,
  },
  {
    title: "Overdue",
    id: "Overdue",
    link: "/action?filter=overdue&display=actions",
    icon: "Edit",
    topLevel: true,
  },
  {
    title: "Missed",
    id: "Missed",
    link: "/action?filter=missed&display=actions",
    icon: "Edit",
    topLevel: true,
  },
  {
    title: "Closed",
    id: "Closed",
    link: "/action?filter=closed&display=actions",
    icon: "Edit",
    topLevel: true,
  },
];

const getdashboardCategoriesTemplate = (appState: ActiveAppState) => {
  let dashboardCategoriesTemplate: DashboardCategory[] = [];

  if (isJobsAccount(appState)) {
    dashboardCategoriesTemplate = [
      {
        title: "Requirements",
        id: "Requirements",
        link: "/template/requirement",
        icon: "FileText",
      },
      {
        title: "Registers",
        id: "Registers",
        link: "/template/register",
        icon: "Layers",
      },
      {
        title: "Actions",
        id: "Actions",
        link: "/template/action",
        icon: "Edit",
      },
      {
        title: "Issues",
        id: "Issues",
        link: "/template/issue",
        icon: "AlertCircle",
      },
      { title: "Jobs", id: "Jobs", link: "/template/job", icon: "Briefcase" },
      {
        title: "Risks",
        id: "Risks",
        link: "/template/risk",
        icon: "AlertTriangle",
      },
      {
        title: "Help",
        id: "Help",
        link: "https://help.certcrowd.com",
        icon: "HelpCircle",
      },
    ];
    return dashboardCategoriesTemplate;
  }
  dashboardCategoriesTemplate = [
    {
      title: "Requirements",
      id: "Requirements",
      link: "/template/requirement",
      icon: "FileText",
    },
    {
      title: "Registers",
      id: "Registers",
      link: "/template/register",
      icon: "Layers",
    },
    { title: "Actions", id: "Actions", link: "/template/action", icon: "Edit" },
    {
      title: "Issues",
      id: "Issues",
      link: "/template/issue",
      icon: "AlertCircle",
    },
    {
      title: "Risks",
      id: "Risks",
      link: "/template/risk",
      icon: "AlertTriangle",
    },
    {
      title: "Help",
      id: "Help",
      link: "https://help.certcrowd.com",
      icon: "HelpCircle",
    },
  ];

  return dashboardCategoriesTemplate;
};

const BaseNavData: AppNavData = {
  Requirements: [],
  Actions: [],
  Issues: [],
  Jobs: [],
  Risks: [],
  AloneActions: [],
  Reports: [],
  Registers: [],
  AccountData: null,
};

// Why tf are these static instead of part of state?
export type Categories = {
  dashboardCategories: DashboardCategory[];
  issuesCategories: DashboardCategory[];
  reportsCategories: DashboardCategory[];
  registersCategories: DashboardCategory[];
  jobsCategories: DashboardCategory[];
  risksCategories: DashboardCategory[];
  actionsCategories: DashboardCategory[];
  requirementsCategories: DashboardCategory[]; // used for template mode only
  settingsCategories: DashboardCategoryItem[];
};

const BaseCategories: Categories = {
  dashboardCategories: [],
  issuesCategories: [],
  reportsCategories: [],
  registersCategories: [],
  jobsCategories: [],
  risksCategories: [],
  actionsCategories: [],
  requirementsCategories: [], // used for template mode only
  settingsCategories: [],
};

type SidebarState = {
  navData: AppNavData;
  categories: Categories;
  status: "ready" | "fetching";
};

type SidebarContextType = {
  state: SidebarState;
  handleRefresh: () => void;
  pageStatus: PageStatus;
};

const SidebarContext = createContext<SidebarContextType>({
  state: {
    status: "fetching",
    categories: { ...BaseCategories },
    navData: { ...BaseNavData },
  },
  handleRefresh: () => {},
  pageStatus: "Normal",
});

const useSidebarState = () => React.useContext(SidebarContext);

type PageStatus = "Normal" | "Template";

type Props = {
  children: React.ReactNode;
};

export const SidebarStateManager = ({ children }: Props) => {
  const [data, setData] = useState<SidebarState>({
    status: "fetching",
    categories: { ...BaseCategories },
    navData: { ...BaseNavData },
  });
  const [pageStatus, setPageStatus] = useState<PageStatus>("Normal");
  const appContext = useActiveAppState();
  const location = useLocation();
  const { appState } = appContext;

  const isTemplate = location.pathname.indexOf("template") >= 0;

  const _getCategories = (
    categories: Categories,
    result: any,
    isTemplate?: boolean,
  ) => {
    if (isTemplate) {
      categories.settingsCategories = getSettingsCategories(appState);
      categories.issuesCategories = result.data.issueCategories;
      categories.jobsCategories = result.data.jobCategories;
      categories.risksCategories = result.data.riskCategories;
      categories.actionsCategories = result.data.actionCategories;
      categories.requirementsCategories = result.data.requirementCategories;
      categories.registersCategories = result.data.registerCategories;
      categories.dashboardCategories = getdashboardCategoriesTemplate(appState);
    } else {
      categories.issuesCategories = result.data.issueCategories;
      categories.registersCategories = result.data.registerCategories;
      categories.jobsCategories = result.data.jobCategories;
      categories.risksCategories = result.data.riskCategories;
      categories.actionsCategories = actionsCategoriesNormal;
      categories.reportsCategories = isJobsAccount(appState)
        ? result.data.reportCategories
        : result.data.reportCategories.filter(
            (cat: any) => cat.id !== ObjectTypeGroup.Job,
          );
      categories.settingsCategories = getSettingsCategories(appState);
      categories.dashboardCategories = getdashboardCategories(appState);
    }
  };

  const _getNavData = (
    navData: AppNavData,
    result: AxiosResponse<any>,
    isTemplate?: boolean,
  ) => {
    navData.Requirements = result.data.requirements;
    navData.Registers = result.data.registers;
    navData.Issues = result.data.issues;
    navData.Risks = result.data.risks;
    navData.Jobs = result.data.jobs;
    if (isTemplate) {
      navData.AloneActions = result.data.standaloneActions;
    } else {
      navData.Reports = result.data.reports;
      navData.Actions = result.data.instances;
    }
  };

  const handleRefresh = async () => {
    if (data.status === "ready") {
      return;
    }
    const navData = { ...BaseNavData };
    const categories = { ...BaseCategories };
    if (isTemplate) {
      setPageStatus("Template");
      const result = await get("app/nav/template");
      _getCategories(categories, result, true);
      _getNavData(navData, result, true);
    } else {
      setPageStatus("Normal");
      const result = await get("app/nav");
      _getNavData(navData, result);
      _getCategories(categories, result);
    }
    setData({ status: "ready", navData, categories });
  };

  useEffect(() => {
    const fetchNavData = async () => {
      console.log("fetch nav data called with status: ", data.status);
      await handleRefresh();
    };

    if (
      appState.app.userAccounts.length > 0 ||
      (appState.app.accountInfo &&
        appState.app.accountInfo.active &&
        appState.app.accountInfo.userAccountStatusID ===
          UserAccountStatusID.Active)
    ) {
      fetchNavData();
    }
  }, [appState, isTemplate, data.status]);

  const ctx = {
    pageStatus,
    state: data,
    handleRefresh,
  };

  return (
    <SidebarContext.Provider value={ctx}>{children}</SidebarContext.Provider>
  );
};

export default SidebarStateManager;

export { useSidebarState };
