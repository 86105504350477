import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  HStack,
  Link,
  Text,
  UseDisclosureReturn,
} from "@chakra-ui/react";
import React from "react";

type Props = {
  state: UseDisclosureReturn;
  onConfirm?: () => void;
};

export default function ConfirmEnterTemplateManagementDialog({
  state,
  onConfirm,
}: Props) {
  const cancelRef = React.useRef<HTMLButtonElement>(null);
  return (
    <AlertDialog
      leastDestructiveRef={cancelRef}
      isOpen={state.isOpen}
      onClose={state.onClose}>
      <AlertDialogOverlay>
        <AlertDialogContent>
          <chakra-scope>
            <AlertDialogHeader>Enter Template Management</AlertDialogHeader>
            <AlertDialogCloseButton />
            <AlertDialogBody>
              <Text fontSize="inherit" color="inherit">
                Template Management is for advanced users.
              </Text>
              <Text fontSize="inherit" color="inherit">
                Editing templates could break your system or cause issues. Only
                continue to Template Management if you are sure you know what
                you are doing.
              </Text>
              <Text mt="2" fontSize="inherit" color="inherit">
                For more information on templates,{" "}
                <Link
                  target="_blank"
                  href="https://help.certcrowd.com/using-certcrowd/template-management/">
                  click here to see the documentation.
                </Link>
              </Text>
            </AlertDialogBody>
            <AlertDialogFooter>
              <HStack>
                <Button
                  colorScheme="gray"
                  ref={cancelRef}
                  onClick={state.onClose}>
                  Cancel
                </Button>
                <Button
                  colorScheme="red"
                  onClick={() => {
                    state.onClose();
                    if (onConfirm) {
                      onConfirm();
                    }
                  }}>
                  Continue
                </Button>
              </HStack>
            </AlertDialogFooter>
          </chakra-scope>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}
