import { VStack, Text, ButtonProps } from "@chakra-ui/react";
import { useApiQuery } from "../../../../utilities/apibelRequest";
import Select from "../../../Select";

type Props = {
  selected: {
    value: string | number;
    label: string;
  }[];
  onChange: (newVal: { value: string | number; label: string }[]) => void;
  isInActiveFilter?: boolean;
  buttonProps?: ButtonProps;
  text?: React.ReactNode;
};

/**
 * TODO: Eventually this should probably be a searchable select
 */
export default function UserSelect({
  selected,
  text,
  onChange,
  isInActiveFilter,
  buttonProps,
}: Props) {
  const users = useApiQuery("user/selectable", null, {
    cacheTime: 1000 * 15,
    staleTime: 1000 * 15,
  });

  const options = users.isSuccess ? users.data : [];

  const actualSelected = options.filter((option) =>
    selected.some((sel) => sel.value === option.ID),
  );

  return (
    <Select
      placeholderText={text ?? "0 Selected"}
      multiselect
      isLoading={users.isFetching}
      value={actualSelected}
      options={options}
      onChange={(val) =>
        onChange(
          val.map((u) => ({
            value: u.ID,
            label: u.name,
          })),
        )
      }
      w="full"
      getKey={(option) => option.ID}
      getLabel={(option) => option.name}
      renderValue={(value) =>
        value.length >= 2 ? (
          <span>any of {value.length} users</span>
        ) : (
          <span>{value[0]?.name}</span>
        )
      }
      type={isInActiveFilter ? "simple-button" : "dropdown"}
      simpleButtonProps={buttonProps}
      renderOptionWithWrapper
      renderOption={(renderProps) => (
        <VStack as="span" align="start" spacing={0}>
          <span>{renderProps.option.name}</span>
          <Text p={0} m={0} size="xs" fontStyle="italic" color="blackAlpha.600">
            ({renderProps.option.email})
          </Text>
        </VStack>
      )}
    />
  );
}
