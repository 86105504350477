import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
} from "@chakra-ui/react";
import React from "react";
import { useController, useFormContext, useFormState } from "react-hook-form";
import {
  formatDateForNativePicker,
  parseDateFromNativePicker,
} from "../../../../utilities/dateUtils";

type Props = {
  name: string;
  isReadOnly?: boolean;
  fieldProps: {
    label: string;
    unitLabel: string | null;
    isRequired?: boolean;
  };
};

const DateField = ({ fieldProps, name, isReadOnly }: Props) => {
  const { register } = useFormContext();
  const { errors } = useFormState({ name });
  const { field } = useController({ name });
  const error = errors[name];
  const { label, unitLabel, isRequired } = fieldProps;
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const convertedValue = parseDateFromNativePicker(value);
    field.onChange(convertedValue);
  };
  return (
    <FormControl
      isRequired={isRequired}
      isReadOnly={isReadOnly}
      isInvalid={!!error}>
      <FormLabel htmlFor={name}>{label}</FormLabel>
      <Input
        type="date"
        value={formatDateForNativePicker(new Date(field.value))}
        onChange={handleChange}
      />
      <FormErrorMessage>{error && error.message}</FormErrorMessage>
    </FormControl>
  );
};

export default DateField;
