import Chart from "react-apexcharts";
import { WidgetConfigTypes } from "../../../shared/v2/definitions/dashboards";
import { useApiQuery } from "../../../utilities/apibelRequest";
import { BaseWidgetProps } from "./BaseWidgetProps";
import DashboardWidget from "./DashboardWidget";
import SkeletonBarChart from "./skeletons/SkeletonBarChart";

type Props = BaseWidgetProps & {
  config: WidgetConfigTypes["open-issues"];
};

const OpenIssuesWidget = ({
  config,
  title,
  setFilters,
  dashboardID,
  widgetID,
}: Props) => {
  const { stacked } = config;

  const openIssuesQuery = useApiQuery("dashboard/widget/openIssues", {
    dashboardID,
    widgetID,
    widgetType: "open-issues",
  });

  const data = openIssuesQuery.data ?? [];

  return (
    <DashboardWidget
      isLoading={openIssuesQuery.isLoading}
      isRefetching={openIssuesQuery.isRefetching}
      title={title ?? "Open Issues"}
      helpText="All open issues in the system."
      onReload={openIssuesQuery.refetch}>
      {openIssuesQuery.isLoading || openIssuesQuery.isFetching ? (
        <SkeletonBarChart />
      ) : (
        <Chart
          options={{
            chart: {
              stacked,

              events: {
                click: function (chart, w, e) {
                  const series = data[e.dataPointIndex];

                  if (series)
                    setFilters({
                      type: "records",
                      recordType: "issue",
                      objectTypeName: series.name,
                      subIssues: e.seriesIndex === 0,
                    });
                },
              },
            },
            labels: data.map((d) => d.name),
            noData: {
              text: "No data to display",
              align: "center",
              verticalAlign: "middle",
            },
          }}
          type="bar"
          series={[
            {
              name: "Open Sub-Issues",
              data: data.map((d) => d.subIssuesCount),
            },
            {
              name: "Open Issues",
              data: data.map((d) => d.issuesCount),
            },
          ]}
        />
      )}
    </DashboardWidget>
  );
};

export default OpenIssuesWidget;
