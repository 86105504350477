import { Button, Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";
import Case from "case";
import { useState, useEffect } from "react";
import Icon from "../../../UI/Icon";
import { Filters } from "../BaseWidgetProps";
import { createPortal } from "react-dom";

type Props = {
  recordType?: "issue" | "requirement" | "risk" | "register";
  patchFilters: (filters: Partial<Filters>) => void;
};

const RecordType = ({ patchFilters, ...props }: Props) => {
  const [recordType, setRecordType] = useState(props.recordType);

  useEffect(() => {
    if (recordType !== props.recordType) {
      console.log("recordType changed");
      setRecordType(props.recordType);
    }
  }, [props.recordType, setRecordType]);
  const body = document.body;
  return (
    <Menu>
      <MenuButton size="sm" rightIcon={<Icon name="ChevronDown" />} as={Button}>
        {`Record Type: ${Case.capital(recordType || "Any")}`}
      </MenuButton>
      {createPortal(
        <chakra-scope>
          <MenuList zIndex={11}>
            <MenuItem
              onClick={() => {
                setRecordType(undefined);
                patchFilters({
                  type: "records",
                  recordType: undefined,
                });
              }}
            >
              Any
            </MenuItem>
            <MenuItem
              onClick={() => {
                setRecordType("issue");
                patchFilters({
                  type: "records",
                  recordType: "issue",
                });
              }}
            >
              Issue
            </MenuItem>
            <MenuItem
              onClick={() => {
                setRecordType("requirement");
                patchFilters({
                  type: "records",
                  recordType: "requirement",
                });
              }}
            >
              Requirement
            </MenuItem>
            <MenuItem
              onClick={() => {
                setRecordType("risk");
                patchFilters({
                  type: "records",
                  recordType: "risk",
                });
              }}
            >
              Risk
            </MenuItem>
            <MenuItem
              onClick={() => {
                setRecordType("register");
                patchFilters({
                  type: "records",
                  recordType: "register",
                });
              }}
            >
              Register
            </MenuItem>
          </MenuList>
        </chakra-scope>,
        body,
      )}
    </Menu>
  );
};

export default RecordType;
