import { Button, HStack, Icon } from "@chakra-ui/react";
import { RiFolderUserFill, RiShieldUserFill } from "react-icons/ri";
import { UserServicePermissions } from "../../../../api/application/shared/v2/constants/ServicePermissions";
import ChakraScreenContainer from "../../components/chakra/ChakraScreenContainer";
import NotFoundScreen from "../NotFound";
import ServicePortalButton from "./ServicePortalButton";

type Props = {
  servicePermissions: UserServicePermissions;
};

export default function ServicePortalHomeScreen({ servicePermissions }: Props) {
  if (!servicePermissions.access) {
    return <NotFoundScreen />;
  }

  return (
    <ChakraScreenContainer pageTitle="Service Portal">
      <HStack>
        <ServicePortalButton
          icon={<Icon as={RiFolderUserFill} />}
          link="/_ccserviceportal/users">
          Manage Users
        </ServicePortalButton>
        <ServicePortalButton
          icon={<Icon as={RiShieldUserFill} />}
          link="/_ccserviceportal/accounts">
          Manage Accounts
        </ServicePortalButton>
      </HStack>
    </ChakraScreenContainer>
  );
}
