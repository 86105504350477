import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  Card,
  Col,
  Collapse,
  Container,
  Dropdown,
  Form,
  Row,
  Spinner,
  Table
} from "react-bootstrap";
import { Link, useParams } from "react-router-dom"; // eslint-disable-line
import { useAppState } from "../../components/App/AppProvider";
import { DateField } from "../../components/Forms/Field/Date";
import { Textarea } from "../../components/Forms/Field/Textarea";
import { CollapseChevron } from "../../components/UI/CollapseChevron/CollapseChevron";
import { Header } from "../../components/UI/Header/Header";
import {
  Messages
} from "../../components/UI/Messages/Messages";
import { Paginator } from "../../components/UI/Paginator/Paginator";
import { getPaginated, RequestStatus } from "../../utilities/request";
import useToast from "../../utilities/useToast";
import {
  Action,
  HistoryAll,
  HistoryFiltersState, HistoryPageStatus, HistoryRecord
} from "./History.d";

const configureInitialState = () => ({
  referenceID: "",
  searchText: "",
  searchStartDate: null,
  searchEndDate: null,
  searchStatusFilter: "Any",
  searchUserFilter: "Any",
  historyRecords: [],
});

const Reducer: React.Reducer<HistoryAll, Action> = (state, action) => {
  switch (action.type) {
    case "setData":
      return action.newData;
    case "setHistoryRecords":
      return { ...state, historyRecords: action.newRecords };
    case "setSearchText":
      return { ...state, searchText: action.newText };
    case "setStartDate":
      return { ...state, searchStartDate: action.startDate };
    case "setEndDate":
      return { ...state, searchEndDate: action.endDate };
    case "setStatusFilter":
      return { ...state, searchStatusFilter: action.filter };
    case "setUserFilter":
      return { ...state, searchUserFilter: action.filter };
    default:
      return state;
  }
};

/* Render Methods */
const renderHistory = (historyRecords: any, state: any) => {
  if (historyRecords.length > 0) {
    return historyRecords.map((e: any, i: number) => {
      const display = `?display=${e.HistoryTable.toLowerCase()}s`;
      return (
        <tr key={i}>
          <td style={{ whiteSpace: "nowrap" }}>
            {e.RecordStatus === "Active" ? (
              <Link
                to={`/${e.HistoryTable.toLowerCase()}/${
                  e.ReferenceID
                }${display}`}>
                {e.ID}
              </Link>
            ) : (
              e.ID
            )}
          </td>
          <td>{e.Name}</td>
          <td>
            {moment.utc(e.HistoryWhenTs).local().format("DD-MMM-YYYY h:mm a")}
          </td>
          <td>{e.UserName}</td>
          <td>{e.ObjectType}</td>
          <td>
            <Textarea
              name="historyRecord"
              readOnly
              value={e.HistoryDetail}
              richText
            />
          </td>
          <td>{e.RecordStatus}</td>
        </tr>
      );
    });
  }
  return (
    <tr>
      <td>No History...</td>
    </tr>
  );
};

const renderUserFilterOptions = (historyRecords: any[], dispatch: any) => {
  const uniqueUserNames = historyRecords.reduce((accumulator, currentValue) => {
    if (accumulator.indexOf(currentValue.UserName) === -1) {
      accumulator.push(currentValue.UserName);
    }
    return accumulator;
  }, []);

  return uniqueUserNames.map((e: any, i: number) => (
    <Dropdown.Item
      key={`userFilter${i}`}
      onClick={() => dispatch({ type: "setUserFilter", filter: e })}>
      {e}
    </Dropdown.Item>
  ));
};

/* Filters */
const stringFilter = (list: HistoryRecord[], text: string) =>
  list.filter(
    (history: HistoryRecord) =>
      history.ID.toLowerCase().indexOf(text.toLowerCase()) !== -1 ||
      history.ObjectType.toLowerCase().indexOf(text.toLowerCase()) !== -1 ||
      history.Name.toLowerCase().indexOf(text.toLowerCase()) !== -1,
  );

const statusFilter = (list: HistoryRecord[], text: string) => {
  if (text !== "Any") {
    return list.filter(
      (history: HistoryRecord) => history.RecordStatus === text,
    );
  }
  return list;
};

const userFilter = (list: HistoryRecord[], text: string) => {
  if (text !== "Any") {
    return list.filter((history: HistoryRecord) => history.UserName === text);
  }
  return list;
};

const dateFilter = (list: HistoryRecord[], startDate: Date, endDate: Date) =>
  list.filter((history: HistoryRecord) => {
    if (startDate && endDate) {
      return (
        moment(history.HistoryWhenTs).startOf("day") >=
          moment(startDate).startOf("day") &&
        moment(history.HistoryWhenTs).startOf("day") <=
          moment(endDate).startOf("day")
      );
    }
    if (startDate && !endDate) {
      return (
        moment(history.HistoryWhenTs).startOf("day") >=
        moment(startDate).startOf("day")
      );
    }
    if (!startDate && endDate) {
      return (
        moment(history.HistoryWhenTs).startOf("day") <=
        moment(endDate).startOf("day")
      );
    }
    return list;
  });

const idFilter = (list: HistoryRecord[], id: string) =>
  list.filter((history: HistoryRecord) => history.ReferenceID === id);

const pageFilter = (filters: HistoryFiltersState, index: number) =>
  index < filters.page * filters.pageSize &&
  index >= (filters.page - 1) * filters.pageSize;

const HistoryScreen = () => {
  const { auth } = useAppState();
  const params = useParams();
  const [data, dispatch] = React.useReducer<React.Reducer<HistoryAll, Action>>(
    Reducer,
    configureInitialState(),
  );
  const { displayToast } = useToast();
  const [filters, setFilters] = React.useState<HistoryFiltersState>({
    page: 1,
    pageSize: 10,
  });
  const [isDetailsCollapsed, updateDetailsCollapsed] = useState<boolean>(true);
  const [pageStatus, setPageStatus] = useState<HistoryPageStatus>("Loading");

  useEffect(() => {
    const fetchHistory = async () => {
      try {
        await getPaginated(`history`, 1000, updateResults, auth);
      } catch (e) {
        displayToast({
          status: "error",
          title: `Failed to retrieve History`,
        });
        setPageStatus("Error");
      }
    };

    if (auth.isLoggedIn) {
      fetchHistory();
    }
  }, [auth]);

  let interimResults: HistoryRecord[] = [];
  const updateResults = (responseData: any, status: RequestStatus) => {
    if (status !== "Error") {
      interimResults = interimResults.concat(responseData.History);
      dispatch({
        type: "setHistoryRecords",
        newRecords: interimResults,
      });
    } else {
      displayToast({
        status: "error",
        title: `Failed to retrieve History`,
      });
    }
    setPageStatus(status);
  };

  useEffect(() => {
    setFilters({ ...filters, page: 1 });
  }, [
    data.searchText,
    data.searchStartDate,
    data.searchEndDate,
    data.searchStatusFilter,
    data.searchUserFilter,
  ]);

  /* Apply filters */
  let filteredRecords: HistoryRecord[] = data ? data.historyRecords : [];
  let paginatedFilteredRecords: HistoryRecord[] = [];
  if (filteredRecords.length > 0) {
    // Filter by referenceID
    const referenceID = params.referenceID || "";
    if (referenceID && data.searchText === "") {
      if (data.referenceID === "") {
        filteredRecords = idFilter(filteredRecords, referenceID);
        const filteredID = filteredRecords[0] ? filteredRecords[0].ID : "";
        updateDetailsCollapsed(false);
        dispatch({
          type: "setData",
          newData: {
            ...data,
            referenceID,
            searchText: filteredID,
          },
        });
      }
    }

    // Filter by string search
    filteredRecords = stringFilter(filteredRecords, data.searchText);

    // Filter by date search
    filteredRecords = dateFilter(
      filteredRecords,
      data.searchStartDate,
      data.searchEndDate,
    );

    // Filter by status
    filteredRecords = statusFilter(filteredRecords, data.searchStatusFilter);

    // Filter by UserName
    filteredRecords = userFilter(filteredRecords, data.searchUserFilter);

    // Pagination
    paginatedFilteredRecords = filteredRecords.filter(
      (history: any, index: number) => pageFilter(filters, index),
    );
  }

  return (
    <Container>
      <section>
      </section>
      <Header breadcrumbs={[{ title: "History" }]} title="History" />
      {data ? (
        <Card>
          <Row style={{ marginBottom: "10px", color: "#374750" }}>
            <Col>
              <h1>History of changes</h1>
            </Col>
            <Col style={{ textAlign: "right", paddingRight: "0px" }}>
              {isDetailsCollapsed ? (
                <button
                  className="btn-undercover"
                  onClick={() => updateDetailsCollapsed(false)}>
                  Show Search
                </button>
              ) : (
                <button
                  className="btn-undercover"
                  onClick={() => updateDetailsCollapsed(true)}>
                  Hide Search
                </button>
              )}
            </Col>
            <Col sm="auto">
              <CollapseChevron
                collapsed={isDetailsCollapsed}
                updateCollapsed={updateDetailsCollapsed}
              />
            </Col>
          </Row>

          <Collapse in={!isDetailsCollapsed}>
            <div>
              <Row style={{ marginTop: "10px", marginBottom: "10px" }}>
                <Col sm={3} style={{ textAlign: "right" }}>
                  Search by Name, ID or Type:
                </Col>
                <Col sm={9}>
                  <Form.Control
                    type="text"
                    value={data.searchText}
                    name="searchText"
                    onChange={(event: any) =>
                      dispatch({
                        type: "setSearchText",
                        newText: event.target.value,
                      })
                    }
                  />
                </Col>
              </Row>
              <Row style={{ marginTop: "10px", marginBottom: "10px" }}>
                <Col sm={3} style={{ textAlign: "right" }}>
                  Search by Date:
                </Col>
                <Col sm={4}>
                  <span style={{ marginRight: "25px" }}>from</span>
                  <DateField
                    name="searchStartDate"
                    selected={data.searchStartDate}
                    onUpdate={(selected: any) =>
                      dispatch({ type: "setStartDate", startDate: selected })
                    }
                    authState={auth}
                    allDates
                  />
                </Col>
                <Col sm={4}>
                  <span style={{ marginRight: "25px" }}>to</span>
                  <DateField
                    name="searchEndDate"
                    selected={data.searchEndDate}
                    onUpdate={(selected: any) =>
                      dispatch({ type: "setEndDate", endDate: selected })
                    }
                    authState={auth}
                    allDates
                  />
                </Col>
              </Row>
              <Row style={{ marginTop: "10px", marginBottom: "10px" }}>
                <Col sm={3} style={{ textAlign: "right" }}>
                  Other filters:
                </Col>
                <Col sm="auto">
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="secondary"
                      id="dropdown-statusFilter">
                      {data.searchStatusFilter} Status
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={() =>
                          dispatch({ type: "setStatusFilter", filter: "Any" })
                        }>
                        Any
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() =>
                          dispatch({
                            type: "setStatusFilter",
                            filter: "Active",
                          })
                        }>
                        Active
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() =>
                          dispatch({
                            type: "setStatusFilter",
                            filter: "Deleted",
                          })
                        }>
                        Deleted
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() =>
                          dispatch({
                            type: "setStatusFilter",
                            filter: "Template",
                          })
                        }>
                        Template
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Col>
                <Col sm="auto">
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="secondary"
                      id="dropdown-statusFilter">
                      {data.searchUserFilter} User
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={() =>
                          dispatch({ type: "setUserFilter", filter: "Any" })
                        }>
                        Any
                      </Dropdown.Item>
                      {renderUserFilterOptions(data.historyRecords, dispatch)}
                    </Dropdown.Menu>
                  </Dropdown>
                </Col>
              </Row>
            </div>
          </Collapse>

          <hr />

          <Table responsive borderless>
            <thead>
              <tr>
                <th>ID</th>
                <th>Name</th>
                <th>When</th>
                <th>User</th>
                <th>Type</th>
                <th>Detail</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {pageStatus !== "Loading" &&
                renderHistory(paginatedFilteredRecords, data)}
            </tbody>
          </Table>
          {pageStatus === "Fetching" || pageStatus === "Loading" ? (
            <Row>
              <Col sm="auto">
                <Spinner size="sm" animation="border" />
              </Col>
            </Row>
          ) : null}
          <Row className="justify-content-sm-center">
            <Col sm="auto">
              <Paginator
                filters={filters}
                setFilterDispatch={setFilters}
                allPaginatedRecords={filteredRecords}
                pageKey="page"
              />
            </Col>
          </Row>
        </Card>
      ) : null}
    </Container>
  );
};

export { HistoryScreen };
