import moment from "moment";

type DateDisplay =
  | "Date"
  | "DateAndTime"
  | "DateAndTimeAndTimezone"
  | "DateAndTimeIfNotMidnight"
  | "Time";

type FormatDateTimeProps = {
  date: any;
  format: DateDisplay;
  isOpenDate?: boolean;
};

// for ActionStartTs and InstanceWhenTs
const validateDate = (date: Date, timezone: string): string => {
  date = new Date(date);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const dateString = `${year}-${month < 10 ? "0" : ""}${month}-${
    day < 10 ? "0" : ""
  }${day}T00:00:00.000`;
  const momentDate = moment
    .tz(dateString, timezone)
    .add(24, "hours")
    .utc()
    .format("YYYY-MM-DDTHH:mm:ss.SSS");
  const dateWithoutTimezone = momentDate.substring(0, 24) + "Z";
  return dateWithoutTimezone;
};

const formatDateTime = (props: FormatDateTimeProps) => {
  const userTimezone = moment.tz.guess();
  const timeIsMidnight =
    moment(props.date).hours() === 0 &&
    moment(props.date).minutes() === 0 &&
    moment(props.date).seconds() === 0;

  const date = props.isOpenDate
    ? moment.utc(props.date).add(1, "seconds")
    : timeIsMidnight
    ? moment.utc(props.date).subtract(1, "seconds")
    : moment.utc(props.date);

  switch (props.format) {
    case "Date":
      return moment.utc(date).local().format("DD-MMM-YYYY");
    case "DateAndTime":
      return moment.utc(date).local().format("DD-MMM-YYYY hh:mma");
    case "DateAndTimeAndTimezone":
      return `${moment
        .utc(date)
        .local()
        .format("DD-MMM-YYYY hh:mma")}\n${userTimezone}`;
    case "DateAndTimeIfNotMidnight":
      return moment
        .utc(date)
        .local()
        .format(timeIsMidnight ? "DD-MMM-YYYY" : "DD-MMM-YYYY hh:mma");
    case "Time":
      return timeIsMidnight ? "" : moment.utc(date).local().format("hh:mma");
    default:
      return "";
  }
};

export { formatDateTime, validateDate };
