import {
  Button,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";
import { RiFileAddLine } from "react-icons/ri";
import { createPortal } from "react-dom";
import { Link } from "react-router-dom";
import { ActionIcon, DropdownIcon } from "../../../constants/commonIcons";
import StartATaskModal from "./StartATaskModal";

type Props = {
  isInOverflow?: boolean;
};

const ActionNavItem = ({ isInOverflow }: Props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const body = document.body;
  return (
    <>
      {isInOverflow ? (
        <>
          <MenuItem
            as={Link}
            to="/action?display=actions"
            icon={<Icon boxSize="4" as={ActionIcon} />}>
            Actions
          </MenuItem>
          <MenuItem
            icon={<Icon boxSize="4" as={RiFileAddLine} />}
            onClick={onOpen}>
            Start a Task
          </MenuItem>
        </>
      ) : (
        <Menu>
          <MenuButton
            size="sm"
            leftIcon={<Icon boxSize="4" as={ActionIcon} />}
            rightIcon={<Icon boxSize="4" as={DropdownIcon} />}
            colorScheme="gray"
            variant="ghost"
            as={Button}>
            Actions
          </MenuButton>
          {createPortal(
            <chakra-scope>
              <MenuList zIndex={12}>
                <MenuItem
                  as={Link}
                  to="/action?display=actions"
                  icon={<Icon boxSize="4" as={ActionIcon} />}>
                  Actions Dashboard
                </MenuItem>
                <MenuItem
                  icon={<Icon boxSize="4" as={RiFileAddLine} />}
                  onClick={onOpen}>
                  Start a Task
                </MenuItem>
              </MenuList>
            </chakra-scope>,
            body,
          )}
        </Menu>
      )}
      <Modal isOpen={isOpen} onClose={onClose} size="sm">
        <ModalOverlay />
        <StartATaskModal onClose={onClose} />
      </Modal>
    </>
  );
};

export default ActionNavItem;
