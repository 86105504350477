import React, { useState } from "react";
import { Button, OverlayTrigger, Tooltip, Spinner } from "react-bootstrap";
import { Loading } from "../../UI/Loading/Loading";
import { Textarea } from "./Textarea";

interface SpinnyButtonProps {
  text: any;
  onClick: () => void;
  OverlayText?: string;
  variant?: any;
  style?: any;
  className?: string;
  spinnerInline?: boolean;
  spinnerStyle?: any;
}

const SpinnyButton = (props: SpinnyButtonProps) => {
  type PageStatus = "Loading" | "Ready";
  const [pageStatus, setPageStatus] = useState<PageStatus>("Ready");

  const onClick = async () => {
    setPageStatus("Loading");
    try {
      await props.onClick();
      setPageStatus("Ready");
    } catch (e) {
      console.log(e);
      setPageStatus("Ready");
    }
  };

  if (pageStatus === "Ready" && props.OverlayText) {
    return (
      <OverlayTrigger
        placement="auto"
        overlay={
          <Tooltip id={`tooltip-${props.OverlayText}`}>
            <Textarea
              value={props.OverlayText}
              name={`tooltip-text-${props.OverlayText}`}
              readOnly
              richText
            />
          </Tooltip>
        }>
        <Button
          type="button"
          className={props.className ? props.className : ""}
          style={props.style ? props.style : {}}
          variant={props.variant ? props.variant : "outline-dark"}
          onClick={onClick}>
          {props.text}
        </Button>
      </OverlayTrigger>
    );
  }
  if (pageStatus === "Ready") {
    return (
      <Button
        type="button"
        className={props.className ? props.className : ""}
        style={props.style ? props.style : {}}
        variant={props.variant ? props.variant : "outline-dark"}
        onClick={onClick}>
        {props.text}
      </Button>
    );
  }
  return (
    <div
      style={props.spinnerStyle ? props.spinnerStyle : { marginRight: "35px" }}>
      <Spinner
        animation="border"
        size="sm"
        style={{
          margin: "0px",
          display: "block",
          height: "2rem",
          width: "2rem",
        }}
        role="status"
      />
    </div>
  );
};

export { SpinnyButton };
