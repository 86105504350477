import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Text,
  UseDisclosureReturn,
} from "@chakra-ui/react";

type Props = {
  modalState: UseDisclosureReturn;
  link: string;
  actionName: string;
};

export default function ConfirmationModal({
  modalState,
  actionName,
  link,
}: Props) {
  return (
    <Modal isOpen={modalState.isOpen} onClose={modalState.onClose}>
      <ModalOverlay />
      <ModalContent>
        <chakra-scope>
          <ModalHeader>Start {actionName}</ModalHeader>
          <ModalCloseButton />

          <ModalBody>
            <Text>
              Are you sure you want to start {actionName}? <br></br>
              This will open the task in a new tab
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" onClick={modalState.onClose}>
              Cancel
            </Button>
            <Button
              colorScheme="brand"
              onClick={() => {
                window.open(link, "_blank");
                modalState.onClose();
              }}>
              Confirm
            </Button>
          </ModalFooter>
        </chakra-scope>
      </ModalContent>
    </Modal>
  );
}
