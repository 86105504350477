import { Box, SkeletonCircle, SkeletonText } from "@chakra-ui/react";

const SkeletonPieChart = () => {
  return (
    <Box display="flex">
      <SkeletonCircle size="256" />
      <SkeletonText
        m="32px 32px 0 32px"
        noOfLines={6}
        spacing="4"
        flex="1"
        height="256px"
      />
    </Box>
  );
};

export default SkeletonPieChart;
