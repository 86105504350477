const getCustomFieldType = (customFieldID: string): string => {
    const type: string = CustomFieldIDToName[customFieldID]
    return type
    
}

const CustomFieldIDToName: any = {
    '046ec61e-ea42-4c24-84a1-1972bad9b837': 'Single Select List',
    '4e60b38e-e11d-4c3f-9ff5-786999f12c2c': 'Number',
    'b1b550b5-8e76-401b-ae86-476558ce69f7': 'Text',
    'f638a15d-cd76-41be-aac3-4a3204acb48d': 'Date',
    'f2b854e7-1e3f-48ac-9b72-46bd2ebec228' : 'Long Text',
    'fe4cdcdb-c0e9-4681-b00c-37cd868aba91': 'Link',
    'efc1d115-6cb3-46c8-aff3-d7082f16e74c': 'Upload',
    'a7411ec3-7397-4298-a55d-12ac471e7120' : 'Multi-select List',
    'a5778559-530c-46b2-8b3c-001942e285b5' : 'OneDrive Upload'
}

const getCustomFieldID = (customFieldID: string): string => (
    CustomFieldNameToID[customFieldID]
)

const CustomFieldNameToID: any = {
    'List': '046ec61e-ea42-4c24-84a1-1972bad9b837', //list because that is how it appears in url
    'Number': '4e60b38e-e11d-4c3f-9ff5-786999f12c2c',
    'Text': 'b1b550b5-8e76-401b-ae86-476558ce69f7',
    'Date': 'f638a15d-cd76-41be-aac3-4a3204acb48d',
    'LongText': 'f2b854e7-1e3f-48ac-9b72-46bd2ebec228',
    'Link': 'fe4cdcdb-c0e9-4681-b00c-37cd868aba91',
    'Upload' : 'efc1d115-6cb3-46c8-aff3-d7082f16e74c',
    'MultiList' : 'a7411ec3-7397-4298-a55d-12ac471e7120', //to match url appearance
    'OneDriveUpload' : 'a5778559-530c-46b2-8b3c-001942e285b5'
}
const checkMandatoryCF = (cfs:any) => {
    let check =false
    cfs.forEach((cf:any) => {
        if (cf.IsMandatory && (cf.Value === null || cf.Value === '' || cf.Value.length <= 0 )) {
            check = true
        }
    })
    return check
}
const CustomFieldTypes = {
 List: '046ec61e-ea42-4c24-84a1-1972bad9b837',
 MultiList: 'a7411ec3-7397-4298-a55d-12ac471e7120',
 NumberType: '4e60b38e-e11d-4c3f-9ff5-786999f12c2c',
 TextType: 'b1b550b5-8e76-401b-ae86-476558ce69f7',
 DateType: 'f638a15d-cd76-41be-aac3-4a3204acb48d',
 UploadType: 'efc1d115-6cb3-46c8-aff3-d7082f16e74c',
 OneDriveUploadType: 'a5778559-530c-46b2-8b3c-001942e285b5',
 LongTextType: 'f2b854e7-1e3f-48ac-9b72-46bd2ebec228',
 UrlType: 'fe4cdcdb-c0e9-4681-b00c-37cd868aba91',
};

export {getCustomFieldType, CustomFieldIDToName, getCustomFieldID, CustomFieldNameToID, CustomFieldTypes, checkMandatoryCF }