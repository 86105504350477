import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Heading,
  Icon,
  LinkBox,
  LinkOverlay,
  SimpleGrid,
  Skeleton,
  Text,
  useBreakpointValue,
  useColorModeValue,
} from "@chakra-ui/react";
import { RiAddLine, RiArrowRightLine, RiUpload2Line } from "react-icons/ri";
import { Link } from "react-router-dom";
import { useAppState } from "../../components/App/AppProvider";
import FeatherIcon from "../../components/UI/Icon";
import ChakraScreenContainer from "../../components/chakra/ChakraScreenContainer";
import { useApiQuery } from "../../utilities/apibelRequest";

const RegisterPagesScreen = () => {
  const { app } = useAppState();
  const registerPages = useApiQuery("register/pages", null);
  const tableHighlightColor = useColorModeValue("brand.50", "brand.700");
  const tableHighlightNameColor = useColorModeValue("brand.800", "brand.500");
  const tableStripeColor = useColorModeValue("gray.50", "gray.700");
  const tableSize = useBreakpointValue({ base: "sm", lg: "md" });

  const isInternal = Boolean(app.userInfo.isInternal);

  return (
    <chakra-scope>
      <ChakraScreenContainer
        headerEndContent={
          registerPages.isSuccess &&
          isInternal && (
            <Button
              variant="ghost"
              leftIcon={<Icon as={RiUpload2Line} />}
              as={Link}
              size="sm"
              to="/register/upload">
              Upload Tool
            </Button>
          )
        }
        contentSize="container.2xl"
        pageTitle="Register Pages"
        breadcrumbs={[]}>
        {registerPages.isSuccess ? (
          <SimpleGrid flexWrap="wrap" columns={[2, 3, 3, 4, 5]} spacing="4">
            {registerPages.data.map(
              ({ objectTypeID, name, description, namePlural, icon }) => (
                <LinkBox
                  _hover={{
                    boxShadow: "sm",
                    borderColor: "brand.100",
                    "& .arrow-icon": {
                      transform: "translate(3px, 0)",
                      color: "brand.500",
                      opacity: 1.0,
                    },
                    "& .heading-text": {
                      color: "brand.500",
                    },
                  }}
                  as={Card}
                  variant="outline">
                  <CardHeader>
                    <LinkOverlay
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      as={Link}
                      to={`/register/dashboard/${objectTypeID}`}>
                      <Heading
                        transitionProperty="common"
                        transitionDuration="fast"
                        className="heading-text"
                        size="xs"
                        alignItems="center"
                        display="flex">
                        <Box mr="2">
                          <FeatherIcon size={"md"} name={icon} />
                        </Box>
                        {namePlural}
                      </Heading>
                      <Icon
                        transitionProperty="common"
                        transitionDuration="fast"
                        color="black"
                        opacity="0.0"
                        ml="1"
                        boxSize="5"
                        transition=""
                        className="arrow-icon"
                        as={RiArrowRightLine}
                      />
                    </LinkOverlay>
                  </CardHeader>
                  <CardBody>
                    <Text fontSize="sm" color="gray.500">
                      {description}
                    </Text>
                  </CardBody>
                  <CardFooter display="flex" justifyContent="end">
                    <ButtonGroup size="sm" variant="solid" colorScheme="gray">
                      <Button
                        as={Link}
                        size="sm"
                        to={`/register/new?type=${objectTypeID}`}
                        leftIcon={<Icon as={RiAddLine} />}>
                        Create
                      </Button>
                    </ButtonGroup>
                  </CardFooter>
                </LinkBox>
              ),
            )}
          </SimpleGrid>
        ) : registerPages.isError ? (
          <Text>Something went wrong</Text>
        ) : (
          <SimpleGrid flexWrap="wrap" columns={[2, 3, 3, 4, 5]} spacing="4">
            <Skeleton h="40" />
            <Skeleton h="40" />
            <Skeleton h="40" />
            <Skeleton h="40" />
          </SimpleGrid>
        )}
      </ChakraScreenContainer>
    </chakra-scope>
  );
};

export default RegisterPagesScreen;
