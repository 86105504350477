import { useToken } from "@chakra-ui/react";
import Chart from "react-apexcharts";
import { WidgetConfigTypes } from "../../../shared/v2/definitions/dashboards";
import { useApiQuery } from "../../../utilities/apibelRequest";
import { format } from "date-fns";

import { BaseWidgetProps, getDaterange, Lifecycle } from "./BaseWidgetProps";
import DashboardWidget from "./DashboardWidget";
import SkeletonBarChart from "./skeletons/SkeletonBarChart";

type Props = BaseWidgetProps & {
  config: WidgetConfigTypes["tasks-by-user"];
};

const TaskByUserWidget = ({
  config,
  title,
  setFilters,
  widgetID,
  dashboardID,
}: Props) => {
  const upcomingCol = useToken("colors", "teal.400");
  const dueCol = useToken("colors", "blue.500");
  const overdueCol = useToken("colors", "orange.500");
  const openRespondedCol = useToken("colors", "purple.400");
  const missedCol = useToken("colors", "red.700");
  const closedCol = useToken("colors", "blue.700");
  const labelColor = useToken("colors", "whiteAlpha.900");

  const { stacked } = config;

  const tasksByUsersQuery = useApiQuery("dashboard/widget/tasksByUser", {
    widgetID,
    dashboardID,
    widgetType: "tasks-by-user",
  });

  const data = tasksByUsersQuery.data?.filter((d) => d.total > 0) || [];

  const seriesIndexMap = {
    0: "upcoming",
    1: "due",
    2: "overdue",
    3: "openResponded",
    4: "closed",
    5: "missed",
  } as const;

  return (
    <DashboardWidget
      isLoading={tasksByUsersQuery.isLoading}
      isRefetching={tasksByUsersQuery.isRefetching}
      title={title ?? "Current Tasks By Users "}
      helpText={`Status of tasks for all users in the system from ${format(
        getDaterange().from,
        "do MMMM",
      )} to ${format(getDaterange().to, "do MMMM yyyy")}`}
      onReload={tasksByUsersQuery.refetch}>
      {tasksByUsersQuery.isLoading || tasksByUsersQuery.isFetching ? (
        <SkeletonBarChart />
      ) : (
        <Chart
          options={{
            chart: {
              stacked,
              events: {
                click: (__, _, e) => {
                  const series = data[e.dataPointIndex];

                  const lifecycle = seriesIndexMap[
                    e.seriesIndex as keyof typeof seriesIndexMap
                  ] as Lifecycle;

                  const { from, to } = getDaterange();

                  if (series)
                    setFilters({
                      type: "tasks",
                      user: { id: series.id, name: series.name },
                      lifecycle,
                      dueAfter: from ? from.toISOString() : undefined,
                      dueBefore: to ? to.toISOString() : undefined,
                    });
                },
              },
            },
            labels: data.map((d) => d.name),

            dataLabels: {
              style: {
                colors: [labelColor],
              },
            },
            colors: [
              upcomingCol,
              dueCol,
              overdueCol,
              openRespondedCol,
              closedCol,
              missedCol,
            ],
            tooltip: {
              y: {
                title: {
                  formatter: (x) => {
                    switch (x) {
                      case "Upcoming":
                        return "Upcoming (Due in the next 30 days)";
                      case "Due":
                        return "Due and open to answer";
                      case "Overdue":
                        return "Overdue and open to answer";
                      case "Open Responded":
                        return "Open and answered";
                      case "Closed":
                        return "Closed and Completed";
                      case "Missed":
                        return "Closed and Missed (In the last 30 days)";
                      default:
                        return x;
                    }
                  },
                },
              },
            },
            noData: {
              text: "No Users have Tasks assigned",
              align: "center",
              verticalAlign: "middle",
            },
          }}
          type="bar"
          series={[
            {
              name: "Upcoming",
              data: data.map((d) => d.upcoming),
            },
            {
              name: "Due",
              data: data.map((d) => d.due),
            },
            {
              name: "Overdue",
              data: data.map((d) => d.overdue),
            },
            {
              name: "Open Responded",
              data: data.map((d) => d.openResponded),
            },
            {
              name: "Closed (Completed)",
              data: data.map((d) => d.closed),
            },
            {
              name: "Closed (Missed)",
              data: data.map((d) => d.missed),
            },
          ]}
        />
      )}
    </DashboardWidget>
  );
};

export default TaskByUserWidget;
