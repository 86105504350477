import { useState } from "react";
import { RiArrowLeftSLine } from "react-icons/ri";
import {
  Box,
  Button,
  Group,
  Stack,
  Text,
  UnstyledButton,
  rem,
} from "@mantine/core";
import QuestionButton from "./QuestionButton/QuestionButton";

type Props = {
  docName: string;
  onAI: () => void;
  onContinue: () => void;
  onBack: () => void;
};
type Option = "skip" | "ai";

export default function QuestionStepAskAI({
  docName,
  onAI,
  onContinue,
  onBack,
}: Props) {
  const [selected, setSelected] = useState<Option | null>(null);

  const handleContinue = () => {
    if (selected === "skip") {
      onContinue();
    } else if (selected === "ai") {
      onAI();
    }
  };

  return (
    <>
      <Stack w="100%" align="start" style={{ flexGrow: 1, overflowY: "auto" }}>
        <Box>
          <Text>
            Would you like to ask the AI assistant to help you generate a{" "}
            {docName}?
          </Text>
          <Text>
            Alternatively, you can continue without one for now, and create or
            add one later.
          </Text>
        </Box>
        <Group
          w="100%"
          pt="64"
          align="start"
          justify="center"
          style={{ flexGrow: 1 }}>
          <QuestionButton
            maw="240"
            onClick={() => setSelected((prev) => (prev === "ai" ? null : "ai"))}
            label="Ask AI"
            isChecked={selected === "ai"}
          />
          <QuestionButton
            maw="240"
            onClick={() =>
              setSelected((prev) => (prev === "skip" ? null : "skip"))
            }
            label="I'll add one later"
            isChecked={selected === "skip"}
          />
        </Group>
      </Stack>
      <Group w="100%" justify="space-between" style={{ alignSelf: "flex-end" }}>
        <Button
          leftSection={<RiArrowLeftSLine size={16} />}
          variant="subtle"
          onClick={onBack}>
          Back
        </Button>
        <Button disabled={selected === null} onClick={handleContinue}>
          Continue
        </Button>
      </Group>
    </>
  );
  return (
    <>
      <VStack align="start" flex="1" w="full">
        <Text>
          Would you like to ask the AI assistant to help you generate a{" "}
          {docName}?
        </Text>
        <Text>
          Alternatively, you can continue without one for now, and create or add
          one later.
        </Text>
        <VStack w="full">
          <VStack align="center" my="4" spacing="4" w="64">
            <Button w="full" onClick={onContinue} colorScheme="teal" py="6">
              I'll create or add one later
            </Button>
            <Button w="full" onClick={onAI} colorScheme="brand" py="6">
              Ask AI
            </Button>
          </VStack>
        </VStack>
      </VStack>
      <HStack w="full" justify="space-between" align="end">
        <Box>
          <Button
            size="sm"
            onClick={onBack}
            variant="ghost"
            leftIcon={<Icon as={RiArrowLeftSLine} />}>
            Back
          </Button>
        </Box>
      </HStack>
    </>
  );
}
