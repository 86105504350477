import React, { useState, useEffect, useRef } from "react";
import { Modal, Card, Row, Col, Button, Form } from "react-bootstrap";
import moment from "moment";
import * as Feather from "react-feather";
import { Formik } from "formik";
import { MentionsInput, Mention } from "react-mentions";
import { put, del } from "../../../utilities/request";
import * as NoteType from "../../../shared/constants/noteTypes";
import { Note } from "../../../shared/definitions/notes";
import { Number as NumberField } from "../../Forms/Field/Number";
import { CheckType } from "../../Forms/Field/Radio";
import * as Access from "../../../utilities/access";
import * as Permissions from "../../../shared/constants/permission";
import { Dialog } from "../Dialog/Dialog";
import { DateField } from "../../Forms/Field/Date";
import { useAppState } from "../../App/AppProvider";
import useToast from "../../../utilities/useToast";

const colours = [
  "#333366",
  "var(--chakra-colors-brand-500)",
  "var(--chakra-colors-primary2-main)",
  "#cc6633",
  "#999999",
];

const defaultNote: any = {
  NoteID: 0,
  RequirementID: null,
  ActionID: null,
  NoteText: "",
  NoteTime: null,
  NoteTimeBillable: null,
  NoteTypeID: "",
};

const noteTypeIDToName = (id: string): string => {
  if (id === NoteType.Note) {
    return "Notes";
  }
  if (id === NoteType.TimeNote) {
    return "Time Notes";
  }
  return "";
};

const isNumeric = (num: number | string): boolean => !isNaN(Number(num));

interface NotesProps {
  isVisible: boolean;
  noteType: string;
  record: {
    type: string; // 'Requirement' or 'Action' to match db column name
    id: string;
  };
  data: Note[];
  taggables: any[];
  handleClose: () => void;
  handleUpdate: (newData: Note[]) => void;
}

export const Notes = (props: NotesProps) => {
  const { app, auth } = useAppState();
  const [data, setData] = useState<Note[] | null>(null);
  const [noteType, setNoteType] = useState<string>(props.noteType);
  const [pageStatus, setPageStatus] = useState<string>("Loading");

  const prepareNotes = (newData: Note[]) => {
    const newNote = {
      ...defaultNote,
      NoteTime: props.noteType === NoteType.TimeNote ? 0 : null,
      NoteTimeBillable: props.noteType === NoteType.TimeNote ? 0 : null,
      NoteTypeID: props.noteType,
      [`${props.record.type}ID`]: props.record.id,
    };
    if (newData.length > 0) {
      if (!isNumeric(newData[0].NoteTypeID)) {
        newData.unshift(newNote);
        setData(newData);
      }
    } else {
      setData([newNote]);
    }
  };

  // once note type has been selected (note icon clicked), add new note of appropriate type
  useEffect(() => {
    if (props.data && props.noteType !== "") {
      setNoteType(props.noteType);
      prepareNotes(props.data);
      setPageStatus("Ready");
    }
  }, [props.noteType]);

  return (
    <>
      <Modal
        show={props.isVisible}
        className="notes-modal"
        onHide={() => {
          setNoteType("");
          props.handleClose();
        }}
        size="lg"
        scrollable
        centered>
        <Modal.Header closeButton>
          <Modal.Title style={{ width: "100%" }}>
            <Row>
              <Col sm="auto">{noteTypeIDToName(props.noteType)}</Col>
            </Row>
            {props.noteType === NoteType.TimeNote ? (
              <Row>
                <Col>
                  <span style={{ fontSize: "14px" }}>
                    {calculateTime(props.data)}
                  </span>
                </Col>
              </Row>
            ) : null}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {data && props.noteType === noteType && pageStatus === "Ready"
            ? data.map((note: Note) => (
                <SingleNote
                  key={note.NoteID}
                  note={note}
                  record={props.record}
                  taggables={props.taggables}
                  data={data}
                  setData={prepareNotes}
                  handleUpdate={props.handleUpdate}
                />
              ))
            : null}
        </Modal.Body>
      </Modal>
    </>
  );
};

const calculateTime = (notes: Note[]): string => {
  let totalTime = 0;
  let totalTimeBillable = 0;

  notes.forEach((note: Note) => {
    if (note.NoteTime !== null) {
      totalTime += note.NoteTime!;
      if (note.NoteTimeBillable) {
        totalTimeBillable += note.NoteTime!;
      }
    }
  });

  return `Total: ${totalTime} hours (${totalTimeBillable} hours billable)`;
};

interface SingleNoteProps {
  note: Note;
  record: {
    type: string;
    id: string;
  };
  data: Note[];
  taggables: any[];
  setData: (notes: Note[]) => void;
  handleUpdate: (newData: Note[]) => void;
}

const SingleNote = (props: SingleNoteProps) => {
  const { app, auth } = useAppState();
  const { displayToast } = useToast();
  const [isNewNote] = useState(isNumeric(props.note.NoteID));
  const [dialogVisible, setDialogVisible] = useState(false);
  const textareaRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (textareaRef !== null && textareaRef.current !== null) {
      textareaRef.current.focus();
    }
  });

  const handleDelete = async () => {
    let success;
    try {
      const result = await del(
        `${props.record.type.toLowerCase()}/${props.record.id}/note/${
          props.note.NoteID
        }`,
        auth,
      );
      props.setData(
        result.data.filter(
          (note: Note) => note.NoteTypeID === props.note.NoteTypeID,
        ),
      );
      props.handleUpdate(result.data);
      success = true;
    } catch (err) {
      console.log(err);
      success = false;
    } finally {
      displayToast({
        status: success ? "success" : "error",
        title: success ? "Note deleted successfully" : "Failed to delete note",
      });
    }
  };

  const handleSubmit = async (values: any, resetForm: any) => {
    let success;
    try {
      const result = await put(
        `${props.record.type.toLowerCase()}/note`,
        {
          ...values,
          NoteTimeDate: moment
            .utc(values.NoteTimeDate)
            .local()
            .format("YYYY-MM-DD"),
        },
        auth,
      );
      props.setData(
        result.data.filter(
          (note: Note) => note.NoteTypeID === props.note.NoteTypeID,
        ),
      );
      props.handleUpdate(result.data);
      success = true;
    } catch (err) {
      console.log(err);
      success = false;
    } finally {
      resetForm();
      displayToast({
        status: success ? "success" : "error",
        title: success ? "Note created successfully" : "Failed to create note",
      });
    }
  };

  const handleKeyDown = (e: any, formikValues: any, resetForm: any) => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault();
      handleSubmit(formikValues, resetForm);
    }
  };

  return (
    <>
      {!isNewNote ||
      Access.checkAccess(
        app.permissions_LEGACY,
        props.note.NoteTypeID === NoteType.Note
          ? Permissions.CodeNotes
          : Permissions.CodeTimeNotes,
        Permissions.TypeCreate,
      ) ? (
        <>
          <Card className="note-card">
            <Formik
              initialValues={props.note}
              onSubmit={(values, actions) =>
                handleSubmit(values, actions.resetForm)
              }
              render={(formikProps) => (
                <Form onSubmit={formikProps.handleSubmit}>
                  <Card.Body className={isNewNote ? "editable" : ""}>
                    {!isNewNote ? (
                      <Row>
                        <Col sm="auto" style={{ flex: "1 1 auto" }}>
                          {!isNewNote ? (
                            <>
                              <Avatar
                                name={props.note.CreateUserName}
                                className="note-avatar"
                              />
                              <span style={{ fontWeight: "bold" }}>
                                {props.note.CreateUserName}{" "}
                              </span>
                              <span style={{ fontSize: ".8rem" }}>
                                {moment
                                  .utc(props.note.CreateTs)
                                  .local()
                                  .format("D-MMM-YYYY kk:mm")}
                              </span>
                            </>
                          ) : null}
                        </Col>
                        <Col sm="auto">
                          <button
                            type="button"
                            disabled={
                              !Access.checkAccess(
                                app.permissions_LEGACY,
                                props.note.NoteTypeID === NoteType.Note
                                  ? Permissions.CodeNotes
                                  : Permissions.CodeTimeNotes,
                                Permissions.TypeDelete,
                                app.attributes.userID ===
                                  props.note.CreateUserID,
                              )
                            }
                            className="button-invisible"
                            onClick={() => setDialogVisible(true)}>
                            <Feather.Trash2
                              size="20"
                              style={
                                !Access.checkAccess(
                                  app.permissions_LEGACY,
                                  props.note.NoteTypeID === NoteType.Note
                                    ? Permissions.CodeNotes
                                    : Permissions.CodeTimeNotes,
                                  Permissions.TypeDelete,
                                  app.attributes.userID ===
                                    props.note.CreateUserID,
                                )
                                  ? { color: "#d8d8d8" }
                                  : {}
                              }
                            />
                          </button>
                        </Col>
                      </Row>
                    ) : null}
                    {isNewNote ||
                    (formikProps.values.NoteText &&
                      formikProps.values.NoteText.length > 0) ? (
                      <Row>
                        <Col>
                          <MentionsInput
                            className={`mention-input${
                              isNewNote ? " editable" : " read-only"
                            }`}
                            value={formikProps.values.NoteText}
                            onChange={(e: any) =>
                              formikProps.setFieldValue(
                                "NoteText",
                                e.target.value,
                              )
                            }
                            readOnly={!isNewNote}
                            placeholder={
                              isNewNote
                                ? `Add your note here... you can @people or roles within your note`
                                : ""
                            }
                            onKeyDown={(e) =>
                              handleKeyDown(
                                e,
                                formikProps.values,
                                formikProps.resetForm,
                              )
                            }
                            inputRef={isNewNote ? textareaRef : undefined}>
                            <Mention
                              className={`mention-input__mention-tag${
                                isNewNote ? " editable" : " read-only"
                              }`}
                              trigger="@"
                              data={props.taggables.filter(
                                (taggable: any) =>
                                  !formikProps.values.NoteText!.includes(
                                    taggable.id,
                                  ),
                              )}
                              renderSuggestion={(
                                suggestion,
                                search,
                                highlightedDisplay,
                              ) => (
                                <div>
                                  <Avatar
                                    className="note-avatar dropdown"
                                    name={suggestion!.display}
                                  />
                                  {highlightedDisplay}
                                </div>
                              )}
                            />
                          </MentionsInput>
                        </Col>
                      </Row>
                    ) : null}
                    {props.note.NoteTypeID === NoteType.TimeNote ||
                    isNewNote ? (
                      <Row style={{ marginTop: "5px" }}>
                        {props.note.NoteTypeID === NoteType.TimeNote ? (
                          <>
                            <Col sm={isNewNote ? "3" : "auto"}>
                              <Form.Group style={{ marginBottom: "0px" }}>
                                <NumberField
                                  name="NoteTime"
                                  value={
                                    formikProps.values.NoteTime
                                      ? formikProps.values.NoteTime.toString()
                                      : "0"
                                  }
                                  readOnly={!isNewNote}
                                  disabled={!isNewNote}
                                  onUpdate={formikProps.handleChange}
                                  style={{ width: "100%" }}
                                  inputGroupStyle={{
                                    width: "50%",
                                    display: "inline-block",
                                  }}
                                />
                                <Form.Label
                                  column
                                  sm="auto"
                                  style={{ fontWeight: "normal" }}>
                                  Hours
                                </Form.Label>
                              </Form.Group>
                            </Col>
                            <Col sm="auto">
                              <Form.Group style={{ marginBottom: "0px" }}>
                                <DateField
                                  name="NoteTimeDate"
                                  id="NoteTimeDate"
                                  selected={formikProps.values.NoteTimeDate}
                                  disabled={!isNewNote}
                                  readOnly={!isNewNote}
                                  onUpdate={((name: any, data: any) =>
                                    formikProps.setFieldValue(name, data)).bind(
                                    {},
                                    "NoteTimeDate",
                                  )}
                                  allDates
                                  authState={auth}
                                  max={moment().toDate()}
                                />
                                <Form.Label
                                  column
                                  sm="auto"
                                  style={{ fontWeight: "normal" }}>
                                  Date
                                </Form.Label>
                              </Form.Group>
                            </Col>
                            <Col sm="auto" style={{ paddingTop: "5px" }}>
                              <Form.Group style={{ marginBottom: "0px" }}>
                                <CheckType
                                  name="NoteTimeBillable"
                                  id="NoteTimeBillable"
                                  value={
                                    formikProps.values.NoteTimeBillable
                                      ? formikProps.values.NoteTimeBillable.toString()
                                      : ""
                                  }
                                  type="checkbox"
                                  checked={formikProps.values.NoteTimeBillable!}
                                  label=""
                                  disabled={!isNewNote}
                                  onChange={formikProps.handleChange}
                                  style={{ display: "inline" }}
                                />
                                <Form.Label
                                  column
                                  sm="auto"
                                  style={{
                                    fontWeight: "normal",
                                    paddingTop: "0px",
                                  }}>
                                  Billable
                                </Form.Label>
                              </Form.Group>
                            </Col>
                          </>
                        ) : null}
                        {isNewNote ? (
                          <>
                            <Col
                              sm="auto"
                              style={{
                                textAlign: "right",
                                flex: "1 1 auto",
                                paddingTop: "5px",
                              }}
                            />
                            <Col sm="auto" style={{ paddingTop: "5px" }}>
                              <button
                                className="button-invisible"
                                type="submit"
                                disabled={
                                  !(
                                    formikProps.values.NoteText !== undefined &&
                                    formikProps.values.NoteText.length > 0
                                  )
                                }>
                                <Feather.Send
                                  style={{
                                    color: !(
                                      formikProps.values.NoteText !==
                                        undefined &&
                                      formikProps.values.NoteText.length > 0
                                    )
                                      ? "#a2aab0"
                                      : "black",
                                  }}
                                />
                              </button>
                            </Col>
                          </>
                        ) : null}
                      </Row>
                    ) : null}
                  </Card.Body>
                </Form>
              )}
            />
          </Card>
          <Dialog
            show={dialogVisible}
            hide={() => setDialogVisible(false)}
            title="Confirm delete"
            body="Are you sure you want to delete this note?"
            confirm={handleDelete}
          />
        </>
      ) : (
        ""
      )}
    </>
  );
};

interface AvatarProps {
  name: string | undefined;
  className: string;
}

const Avatar = (props: AvatarProps) => {
  let names;
  let initial1 = "";
  let initial2 = "";
  let length = 0;
  if (props.name && props.name.length > 0) {
    length = props.name.length;
    names = props.name.split(" ");
    initial1 = names[0].substring(0, 1).toUpperCase();
    initial2 = names[names.length - 1].substring(0, 1).toUpperCase();
  }

  return (
    <span
      className={props.className}
      style={{ backgroundColor: colours[length % 5] }}>
      {initial1}
      {initial2}
    </span>
  );
};
