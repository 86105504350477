import {
  Button,
  chakra,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Input,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  UnorderedList,
  UseDisclosureReturn,
  VStack,
} from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { Auth } from "aws-amplify";
import { FormProvider, useForm } from "react-hook-form";
import { z } from "zod";
import ShortTextField from "../../../../components/chakra/form/formComponents/ShortTextField";
import useToast from "../../../../utilities/useToast";

type Props = {
  modalState: UseDisclosureReturn;
  onRefresh: () => void;
};

const schema = z.object({
  newPassword: z.string().min(8, "New password must be at least 8 characters"),
  oldPassword: z.string(),
});

export default function ChangePasswordModal({ modalState, onRefresh }: Props) {
  const { displayToast } = useToast();

  const form = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: zodResolver(schema),
    defaultValues: {
      newPassword: "",
      oldPassword: "",
    },
  });

  const onSubmit = async () => {
    const { newPassword, oldPassword } = form.getValues();
    if (oldPassword === newPassword) {
      form.setError("newPassword", {
        message: "New and old passwords are identical",
      });
      return;
    }
    try {
      const user = await Auth.currentAuthenticatedUser();
      await Auth.changePassword(user, oldPassword, newPassword);
      displayToast({
        status: "success",
        title: "Password updated",
      });
      onRefresh();
      modalState.onClose();
    } catch (error) {
      displayToast({
        status: "error",
        title: "Failed to change password",
        description: (error as Error)?.message || "Something wen't wrong",
      });
      onRefresh();
    }
  };

  const isBusy = false;
  return (
    <Modal
      onCloseComplete={form.reset}
      closeOnEsc={!isBusy}
      closeOnOverlayClick={!isBusy}
      isOpen={isBusy || modalState.isOpen}
      onClose={modalState.onClose}>
      <ModalOverlay />
      <chakra-scope>
        <ModalContent>
          <FormProvider {...form}>
            <chakra.form onSubmit={form.handleSubmit(onSubmit)}>
              <ModalHeader>Change Password</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <VStack w="full" align="start">
                  <ShortTextField
                    fieldProps={{ label: "Current Password", isRequired: true }}
                    {...form.register("oldPassword")}
                    inputPropsOverride={{ type: "password" }}
                  />
                  <ShortTextField
                    fieldProps={{ label: "New Password", isRequired: true }}
                    {...form.register("newPassword")}
                    inputPropsOverride={{ type: "password" }}
                  />
                  <Heading size="xs">Tips for a good password</Heading>
                  <UnorderedList stylePosition="inside">
                    <ListItem>Use a series of words strung together</ListItem>
                    <ListItem>Keep it at least 8 characters long</ListItem>
                    <ListItem>Use a password manager</ListItem>
                    <ListItem>Don't re-use or recycle old passwords</ListItem>
                    <ListItem>Don't use all numbers or all letters</ListItem>
                    <ListItem>Don't use single words</ListItem>
                  </UnorderedList>
                </VStack>
              </ModalBody>
              <ModalFooter>
                <HStack>
                  <Button
                    onClick={modalState.onClose}
                    variant="solid"
                    colorScheme="gray">
                    Cancel
                  </Button>
                  <Button
                    isDisabled={!form.formState.isValid}
                    loadingText="Updating Password"
                    isLoading={form.formState.isSubmitting}
                    type="submit">
                    Change Password
                  </Button>
                </HStack>
              </ModalFooter>
            </chakra.form>
          </FormProvider>
        </ModalContent>
      </chakra-scope>
    </Modal>
  );
}
