import {
  CoreFieldType,
  CoreFields,
  FieldIdentifierSchema,
} from "./baseFilterTypes";
import * as z from "zod";
import * as CustomFieldTypeID from "../../constants/CustomFieldTypeID";
import * as ListTypeID from "../../constants/ListTypeID";

export type FilterOperationsType = {
  op: string;
  valueType:
    | "string"
    | "number"
    | "none"
    | "objectStatusID"
    | "userID"
    | "list"
    | "recordID"
    | "date";
  isInverted?: boolean | undefined;
};

/**
 * Set up filters here
 *
 * A filter is a combination of a field, an operator, and a value
 * {
 *  field: "name", "description", "some-cf-id" ect.
 *  op: "is", "is not", ect.
 *  value: "some value"
 * }
 *
 * value can also be an array of values, if so this is 'or'd together
 * this is only used on the front end for list filters and the like
 *
 * The field isnt checked at all, but youll get undefined results if you use a field that doesnt exist or doesnt match
 * On the backend, we should probably do some basic field verification after zod validation
 *
 */

/**
 * Too add a new filter operation:
 * Add the filter op to the FilterOperations array
 * Add the filter op to the ops array in each field type that should support it
 * Add support for the filter op to all backend locations that use it
 * Depending on type of filter, it may need some additional work on the front end
 *
 * isInverted should be true for any value that is a 'not' value
 * IE: should be grouped in an AND rather than an OR if doing something like 'any of:'
 * (Example 'is any of X, Y' should be wrapped in an OR, but 'is not any of X, Y' should be wrapped in an AND )
 * ('is X OR is Y' vs 'is not X AND is not Y')
 *
 */

export const FilterOperationsConfig = [
  // String filters
  {
    // The field contains the value (case insensitive)
    op: "contains",
    valueType: "string",
  },
  {
    op: "not-contains", // The field does not contain the value (case insensitive)
    valueType: "string",
    isInverted: true,
  },
  {
    op: "exactly", // The field is exactly the value (case sensitive)
    valueType: "string",
  },
  {
    op: "starts-with", // The field starts with the value (case insensitive)
    valueType: "string",
  },
  {
    op: "ends-with", // The field ends with the value (case insensitive)
    valueType: "string",
  },
  {
    op: "string-exists", // The field is not empty (Isnt null and isnt empty string)
    valueType: "none",
  },

  // Number filters
  {
    op: "eq", // The field is equal to the value
    valueType: "number",
  },
  {
    op: "not-eq", // The field is not equal to the value
    valueType: "number",
    isInverted: true,
  },
  {
    op: "less", // The field is less than the value
    valueType: "number",
  },
  {
    op: "greater", // The field is greater than the value
    valueType: "number",
  },
  {
    op: "less-eq", // The field is less than or equal to the value
    valueType: "number",
  },
  {
    op: "greater-eq", // The field is greater than or equal to the value
    valueType: "number",
  },

  // Object Status filters
  {
    op: "is-status", // The field is equal to the value
    valueType: "objectStatusID",
  },
  {
    op: "is-not-status", // The field is not equal to the value
    valueType: "objectStatusID",
    isInverted: true,
  },

  // Responsible user Filters
  {
    op: "is-user", // The field is equal to the value
    valueType: "userID",
  },
  {
    op: "is-not-user", // The field is not equal to the value
    valueType: "userID",
    isInverted: true,
  },

  // Related record filters
  {
    op: "has-relation", // The record has a related record of the given type (this could mean child, parent, relation ect.)
    valueType: "recordID",
  },

  // List filters
  {
    op: "includes",
    valueType: "list",
  },
  {
    op: "not-includes",
    valueType: "list",
    isInverted: true,
  },

  // Generic filters
  {
    op: "not-null", // The field is not empty (specifically isnt null)
    valueType: "none",
  },
] as const satisfies readonly FilterOperationsType[];

export type FilterOp = (typeof FilterOperationsConfig)[number]["op"];

const FilterOpsSchema = z.enum(
  FilterOperationsConfig.map((f) => f.op) as [FilterOp, ...FilterOp[]]
);

type FilterOperationsConfigByOp = {
  [key in FilterOp]: FilterOperationsType;
};

export const FilterOperationsConfigByOp: FilterOperationsConfigByOp =
  FilterOperationsConfig.reduce(
    (acc, cur) => ({
      ...acc,
      [cur.op]: cur,
    }),
    {} as FilterOperationsConfigByOp
  );

type FilterFieldConfig = {
  [key in
    | CoreFieldType
    | CustomFieldTypeID.CustomFieldTypeID
    | ListTypeID.ListTypeID]: {
    field:
      | CoreFieldType
      | CustomFieldTypeID.CustomFieldTypeID
      | ListTypeID.ListTypeID;
    type: "core" | "custom" | "list";
    ops: FilterOp[];
  };
};

export const FilterFieldConfig: FilterFieldConfig = {
  name: {
    field: "name",
    type: "core",
    ops: [
      // String filters
      "contains",
      "not-contains",
      "exactly",
      "starts-with",
      "ends-with",
      "string-exists",
    ],
  },
  description: {
    field: "description",
    type: "core",
    ops: [
      // String filters
      "contains",
      "not-contains",
      "exactly",
      "starts-with",
      "ends-with",
      "string-exists",
    ],
  },
  readableID: {
    field: "readableID",
    type: "core",
    ops: [
      // String filters
      // String filters
      "contains",
      "not-contains",
      "exactly",
      "starts-with",
      "ends-with",
      // No string-exists because readable id always exists
    ],
  },
  objectStatusID: {
    field: "objectStatusID",
    type: "core",
    ops: [
      // Object Status filters
      "is-status",
      "is-not-status",
    ],
  },
  responsibleUserID: {
    field: "responsibleUserID",
    type: "core",
    ops: [
      // Responsible user Filters
      "is-user",
      "is-not-user",
    ],
  },
  related: {
    field: "related",
    type: "core",
    ops: [
      // Related record filters
      "has-relation",
    ],
  },

  /**
   * Custom Fields
   */
  [CustomFieldTypeID.Text]: {
    field: CustomFieldTypeID.Text,
    type: "custom",
    ops: [
      // String filters
      "contains",
      "not-contains",
      "exactly",
      "starts-with",
      "ends-with",
      "string-exists",
    ],
  },
  [CustomFieldTypeID.LongText]: {
    field: CustomFieldTypeID.Text,
    type: "custom",
    ops: [
      // String filters
      "contains",
      "not-contains",
      "exactly",
      "starts-with",
      "ends-with",
      "string-exists",
    ],
  },
  [CustomFieldTypeID.Link]: {
    field: CustomFieldTypeID.Link,
    type: "custom",
    ops: ["string-exists"],
  },
  [CustomFieldTypeID.Number]: {
    field: CustomFieldTypeID.Number,
    type: "custom",
    ops: [
      // Number filters
      "eq",
      "not-eq",
      "less",
      "greater",
      "less-eq",
      "greater-eq",
      "not-null",
    ],
  },
  [CustomFieldTypeID.Date]: {
    field: CustomFieldTypeID.Date,
    type: "custom",
    ops: [], // Not supporting date filters in v1 of filtering
  },
  [CustomFieldTypeID.OnedriveUpload]: {
    field: CustomFieldTypeID.OnedriveUpload,
    type: "custom",
    ops: [], // Not supporting onedrive upload filters in v1 of filtering
  },
  [CustomFieldTypeID.Upload]: {
    field: CustomFieldTypeID.Upload,
    type: "custom",
    ops: [], // Not supporting upload filters in v1 of filtering
  },
  [CustomFieldTypeID.SingleSelectList]: {
    field: CustomFieldTypeID.SingleSelectList,
    type: "custom",
    ops: [
      // List filters
      "includes",
      "not-includes",
    ],
  },
  [CustomFieldTypeID.MultiselectList]: {
    field: CustomFieldTypeID.MultiselectList,
    type: "custom",
    ops: [
      // List filters
      "includes",
      "not-includes",
    ],
  },

  /**
   * List value filtering
   */
  [ListTypeID.Text]: {
    field: ListTypeID.Text,
    type: "list",
    ops: [
      // String filters
      "contains",
      "not-contains",
      "exactly",
    ],
  },
  [ListTypeID.Number]: {
    field: ListTypeID.Number,
    type: "list",
    ops: [
      // Number filters
      "eq",
      "not-eq",
      "less",
      "greater",
      "less-eq",
      "greater-eq",
    ],
  },
  [ListTypeID.Date]: {
    field: ListTypeID.Date,
    type: "list",
    ops: [], // Not supporting date filters in v1 of filtering
  },
  [ListTypeID.Document]: {
    field: ListTypeID.Document,
    type: "list",
    ops: [], // Not supporting document filters in v1 of filtering
  },
};

const ValueSchema = z.union([z.string(), z.number(), z.undefined()]);

export const FilterSchema = z.object({
  value: z.union([ValueSchema, z.array(ValueSchema)]),
  field: FieldIdentifierSchema,
  op: FilterOpsSchema,
});

export type Filter = z.infer<typeof FilterSchema>;

export const FilterSetAndSchema = z.object({
  type: z.literal("and"),
  filters: z.array(FilterSchema),
});
type FilterSetAnd = z.infer<typeof FilterSetAndSchema>;

export const FilterSetOrSchema = z.object({
  type: z.literal("or"),
  filters: z.array(FilterSchema),
});
type FilterSetOr = z.infer<typeof FilterSetOrSchema>;

export const FilterSetSchema = z.object({
  type: z.enum(["and", "or"]),
  filters: z.array(
    z.union([FilterSetAndSchema, FilterSetOrSchema, FilterSchema])
  ),
});

/**
 * Filter sets arent infinite, they can go 1 level deep
 * At the top level they are an 'and' or 'or', direct children can be and/or/filter, but children of those and/ors can only be filters
 */
export type FilterSet = z.infer<typeof FilterSetSchema>;

export type FilterSetNode = FilterSet | Filter | FilterSetAnd | FilterSetOr;
