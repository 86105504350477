import moment from "moment";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { get } from "../../../utilities/request";
import useToast, { DisplayToastFn } from "../../../utilities/useToast";
import { SpinnyButton } from "../../Forms/Field/SpinnyButton";
import { Textarea } from "../../Forms/Field/Textarea";
import { HistoryRecords } from "./History.d";

const getHistory = async (
  state: any,
  setData: React.Dispatch<HistoryRecords>,
  authState: any,
  displayToast: DisplayToastFn,
) => {
  const fetchHistory = async () => {
    try {
      const result = await get(`history/${state.referenceID}`, authState);
      if (result.status !== 200 || !result) {
        displayToast({
          status: "error",
          title: "Failed to retrieve History",
        });
      } else if (result.data.length !== 0) {
        setData({
          referenceID: state.referenceID,
          referenceLastModifiedTS: state.referenceLastModifiedTS,
          referenceLastModifiedUserName: state.referenceLastModifiedUserName,
          referenceHistoryAction: result.data[0].HistoryAction,
          referenceHistoryDetail: result.data[0].HistoryDetail,
          referenceHistoryCount: result.data[0].count,
        });
      }
    } catch (e) {
      displayToast({
        status: "error",
        title: "Failed to retrieve History",
      });
    }
  };

  await fetchHistory();
};

const renderSeeMore = (
  state: any,
  setData: React.Dispatch<HistoryRecords>,
  authState: any,
  displayToast: DisplayToastFn,
) => {
  if (state.referenceHistoryAction !== undefined) {
    return (
      <div>
        <span style={{ color: "rgba(0, 0, 0, 0.7)" }}>
          Last modified by {state.referenceLastModifiedUserName}{" "}
          {moment
            .utc(state.referenceLastModifiedTS)
            .local()
            .format("DD-MMM-YYYY h:mm a")}
          <Textarea
            name="historyRecord"
            readOnly
            value={state.referenceHistoryDetail}
            richText
          />
        </span>
        <Link to={`/history/${state.referenceID}`}>{`see more (${
          state.referenceHistoryCount - 1
        })...`}</Link>
      </div>
    );
  }
  return (
    <div>
      <span style={{ color: "rgba(0, 0, 0, 0.7)" }}>
        Last modified by {state.referenceLastModifiedUserName}{" "}
        {moment
          .utc(state.referenceLastModifiedTS)
          .local()
          .format("DD-MMM-YYYY h:mm a")}
      </span>
      <SpinnyButton
        text="see more..."
        className="linkButtonBootstrap"
        style={{ marginLeft: "6px", marginBotton: "2px" }}
        spinnerInline
        spinnerSize={1}
        spinnerStyle={{
          display: "inline-block",
          marginRight: "35px",
          marginLeft: "10px",
        }}
        onClick={async () => {
          await getHistory(state, setData, authState, displayToast);
        }}
      />
    </div>
  );
};

const History = (props: any) => {
  // Set initial state with stock information that gets passed from props (no need to check history table at this stage).
  // Only get referenceHistoryAction & referenceHistoryDetail if asked.
  const [data, setData] = useState<HistoryRecords>({
    referenceID: props.ID,
    referenceLastModifiedTS: props.lastModifiedTs,
    referenceLastModifiedUserName: props.lastModififedUser,
  });
  const { displayToast } = useToast();

  useEffect(() => {
    if (props.ID !== "") {
      setData({
        referenceID: props.ID,
        referenceLastModifiedTS: props.lastModifiedTs,
        referenceLastModifiedUserName: props.lastModififedUser,
      });
    }
  }, [props]);

  if (props.ID !== "") {
    return (
      <div style={{ marginTop: "25px", fontSize: "14px" }}>
        <section />
        <Row>
          <Col>
            <i>
              {data
                ? renderSeeMore(data, setData, props.authState, displayToast)
                : null}
            </i>
          </Col>
        </Row>
      </div>
    );
  }
  return null;
};

export { History };
