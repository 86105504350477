import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import {
  Button,
  Container,
  Dropdown,
  DropdownButton,
  Form,
  OverlayTrigger,
  Table,
  Tooltip,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useAppState } from "../../components/App/AppProvider";
import { Card } from "../../components/UI/Card/Card";
import { Header } from "../../components/UI/Header/Header";
import LegacyScreenContainer from "../../components/UI/LegacyScreenContainer";
import { Loading } from "../../components/UI/Loading/Loading";
import * as ObjectTypeGroupID from "../../constants/objectTypeGroup";
import * as Permissions from "../../shared/constants/permission";
import * as Access from "../../utilities/access";
import { get } from "../../utilities/request";
import useToast from "../../utilities/useToast";
import SettingsScreenContainer from "../_chakra/settings/SettingsScreenContainer";

const ScreensPages = (): JSX.Element => {
  const { auth, app } = useAppState();
  const navigate = useNavigate();
  const [pageStatus, setPageStatus] = useState<any>("Loading");
  const [data, setData] = useState<any>(null);
  const { displayToast } = useToast();

  useEffect(() => {
    const fetchList = async () => {
      setPageStatus("Loading");
      const reply = await get("settings/pages", auth);

      console.log("reply", reply.data.objectTypeInformation);

      if (reply.status !== 200 || !reply.data) {
        displayToast({
          status: "error",
          title: `Failed to retrieve Pages`,
        });
      } else {
        setData(reply.data.objectTypeInformation);
        // distinctObjectTypes = getDistinctTypes(reply.data.objectTypeInformation)
        // console.log(data)
        setPageStatus("Ready");
      }
    };
    fetchList();
  }, []);

  if (pageStatus === "Loading") {
    return (
      <SettingsScreenContainer pageTitle="Pages">
        <div className="progress-spinner" style={{ marginTop: "20%" }}>
          <Loading size={"xl"} />
        </div>
      </SettingsScreenContainer>
    );
  }

  if (
    Access.checkAccess(
      app.permissions_LEGACY,
      Permissions.CodeUserSettings,
      Permissions.TypeRead,
      false,
    )
  ) {
    return (
      <SettingsScreenContainer pageTitle="Pages">
        <Card
          title=""
          collapsable={false}
          headerCols={
            Access.checkAccess(
              app.permissions_LEGACY,
              Permissions.CodeUserSettings,
              Permissions.TypeCreate,
            )
              ? [
                  { colProps: {}, children: <span /> },
                  {
                    colProps: { sm: "auto" },
                    children: (
                      <Button variant="primary" href="/template">
                        Template Management
                      </Button>
                    ),
                  },
                  {
                    colProps: { sm: "auto" },
                    children: (
                      <DropdownButton
                        title="Create New Page"
                        id="createNewPage"
                        onSelect={(eventKey: any) => {
                          navigate(`settings?new=New&type=${eventKey}`);
                        }}>
                        <Dropdown.Item
                          key={0}
                          eventKey={ObjectTypeGroupID.Action}>
                          {" "}
                          Action{" "}
                        </Dropdown.Item>
                        <Dropdown.Item
                          key={1}
                          eventKey={ObjectTypeGroupID.Issue}>
                          {" "}
                          Issue{" "}
                        </Dropdown.Item>
                        <Dropdown.Item
                          key={2}
                          eventKey={ObjectTypeGroupID.Register}>
                          {" "}
                          Register{" "}
                        </Dropdown.Item>
                        <Dropdown.Item
                          key={3}
                          eventKey={ObjectTypeGroupID.Requirement}>
                          {" "}
                          Requirement{" "}
                        </Dropdown.Item>
                        <Dropdown.Item
                          key={4}
                          eventKey={ObjectTypeGroupID.Risk}>
                          {" "}
                          Risk{" "}
                        </Dropdown.Item>
                      </DropdownButton>
                    ),
                  },
                ]
              : [
                  { colProps: {}, children: <span /> },
                  {
                    colProps: { sm: "auto" },
                    children: (
                      <OverlayTrigger
                        placement="auto"
                        overlay={
                          <Tooltip id="tooltip-not-authorised-to-edit">
                            You do not have permission to create new pages
                          </Tooltip>
                        }>
                        <Button type="button" className="disabled">
                          Create New Page
                        </Button>
                      </OverlayTrigger>
                    ),
                  },
                ]
          }>
          <Formik
            enableReinitialize
            initialValues={{ data: [...data] }}
            onSubmit={async (values, actions) => {}}
            render={(formikProps) => (
              <Form onSubmit={formikProps.handleSubmit}>
                <Table borderless>
                  <thead>
                    <tr>
                      <th> Type </th>
                      <th> Name </th>
                      <th> Fields </th>
                      <th> Settings </th>
                      <th> Template Documents </th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((objectType: any) => (
                      <tr key={objectType.ObjectTypeID}>
                        <td>{objectType.ObjectTypeGroupName}</td>
                        <td>{objectType.ObjectTypeName}</td>
                        <td>
                          <Link
                            to={`/settings/pages/fields?objecttype=${objectType.ObjectTypeID}`}>
                            {" "}
                            Fields{" "}
                          </Link>
                        </td>
                        <td>
                          <Link
                            to={`/settings/pages/settings?objecttype=${objectType.ObjectTypeID}`}>
                            {" "}
                            Settings{" "}
                          </Link>
                        </td>
                        <td>
                          <Link
                            to={`/settings/pages/documents?objecttype=${objectType.ObjectTypeID}`}>
                            {" "}
                            Template Documents{" "}
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Form>
            )}
          />
        </Card>
      </SettingsScreenContainer>
    );
  }
  return <></>;
  // No Access
};

export type PageStatus = "Loading" | "Ready" | "Editing" | "Submitting";
export { ScreensPages };
