import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
} from "@chakra-ui/react";
import { useFormContext } from "react-hook-form";

type Props = {
  name: string;
  isReadOnly?: boolean;
  fieldProps: {
    label: string;
    unitLabel?: string | null;
    min?: number;
    max?: number;
    isRequired?: boolean;
  };
};

const NumberField = ({ name, isReadOnly, fieldProps }: Props) => {
  const { register, formState, setValue } = useFormContext();
  const error = formState.errors[name];
  const { label, isRequired } = fieldProps;

  const { onChange, ref } = register(name);

  return (
    <FormControl
      isRequired={isRequired}
      isReadOnly={isReadOnly}
      isInvalid={!!error}
    >
      <FormLabel htmlFor={name}>{label}</FormLabel>
      <FormErrorMessage>{error && error.message}</FormErrorMessage>
      <NumberInput
        min={fieldProps.min !== undefined ? fieldProps.min : (0 as any)}
        max={fieldProps.max !== undefined ? fieldProps.max : (100 as any)}
        onChange={(value) => {
          onChange({ target: { name, value: Number(value) } });
          setValue(name, Number(value).valueOf());
        }}
        {...{ ref, name }}
      >
        <NumberInputField />
        <NumberInputStepper>
          <NumberIncrementStepper />
          <NumberDecrementStepper />
        </NumberInputStepper>
      </NumberInput>
    </FormControl>
  );
};

export default NumberField;
