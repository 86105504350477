type RetryOptions = {
  retries: number;
  shouldRetry?: (error: Error) => boolean;
  onError?: (error: Error) => void | Promise<void>;
};

/**
 * @name retryRequestOf
 *  @example
 *  ```typescript
 *  const retryRequest = retryRequestOf(fetch)
 *  ```
 *  @example
 *  ```typescript
 *  const retryRequest = retryRequestOf(fetch, {
 *  retries: 3,
 *  onError: (error) => {
 *    // do something
 *  })
 *  ```
 */
export function retryRequestsOf<Arguments extends any[], Result>(
  task: (...args: Arguments) => Result | Promise<Result>,
  { retries, shouldRetry = () => true, onError }: RetryOptions,
) {
  return async function (...args: Arguments) {
    let retriesLeft = retries;

    for await (const result of createTaskStream(task, args)) {
      switch (result.type) {
        case "SUCCESS": {
          return result.value;
        }
        case "ERROR": {
          await onError?.(result.error);

          if (shouldRetry(result.error) && retriesLeft > 0) {
            retriesLeft -= 1;
            continue;
          } else {
            throw result.error;
          }
        }
      }
    }

    throw new Error("Unreachable");
  };
}

async function* createTaskStream<Arguments extends any[], Result>(
  task: (...args: Arguments) => Result | Promise<Result>,
  args: Arguments,
) {
  while (true) {
    try {
      const value = await task(...args);

      yield {
        type: "SUCCESS" as const,
        value,
      } as const;
    } catch (error) {
      yield {
        type: "ERROR" as const,
        error: error as any,
      } as const;
    }
  }
}
