import { Box, BoxProps } from "@chakra-ui/react";
import React from "react";

type CardProps = BoxProps & {
  children?: React.ReactNode;
  variant?: "solid" | "subtle";
};

const Card = ({ children, variant = "subtle", ...otherProps }: CardProps) => (
  <Box
    rounded="md"
    shadow={variant === "solid" ? "xs" : undefined}
    {...otherProps}>
    {children}
  </Box>
);

export default Card;
