import React from "react";

const SingleSelectIcon = () => (
  <svg
    style={{ paddingLeft: "10px" }}
    className="feather"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    viewBox="0 0 32 32">
    <path
      fill="#374750"
      d="M16 0c-8.837 0-16 7.163-16 16s7.163 16 16 16 16-7.163 16-16-7.163-16-16-16zM16 28c-6.627 0-12-5.373-12-12s5.373-12 12-12c6.627 0 12 5.373 12 12s-5.373 12-12 12zM10 16c0-3.314 2.686-6 6-6s6 2.686 6 6c0 3.314-2.686 6-6 6s-6-2.686-6-6z"
    />
  </svg>
);

export { SingleSelectIcon };
