import {
  Button,
  ButtonGroup,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: (sectionTitle: string) => void;
};

export default function AddSectionModal({ isOpen, onClose, onConfirm }: Props) {
    const initialFocusRef = useRef(null);
  const [title, setTitle] = useState("");
  useEffect(() => {
    setTitle("");
  }, [isOpen]);
  return (
    <Modal initialFocusRef={initialFocusRef} isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <chakra-scope>
        <ModalContent>
          <ModalHeader>New manual section</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                onConfirm(title);   
              }}>
              <Input
                ref={initialFocusRef}
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                placeholder="Section title"
              />
            </form>
          </ModalBody>
          <ModalFooter>
            <ButtonGroup>
              <Button onClick={onClose} colorScheme="gray">
                Cancel
              </Button>
              <Button onClick={() => onConfirm(title)}>Add section</Button>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </chakra-scope>
    </Modal>
  );
}
