import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  Heading,
  HStack,
  Icon,
  LinkBox,
  LinkOverlay,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  SimpleGrid,
  Skeleton,
  Text,
  Tooltip,
  VStack,
} from "@chakra-ui/react";
import {
  RiArrowDownSLine,
  RiArrowRightLine,
  RiFileAddLine,
} from "react-icons/ri";
import { Link, useNavigate } from "react-router-dom";
import { formatDistance } from "date-fns";
import FeatherIcon from "../../components/UI/Icon";
import ChakraScreenContainer from "../../components/chakra/ChakraScreenContainer";
import { useApiQuery } from "../../utilities/apibelRequest";
import { Negative } from "../../shared/v2/constants/ObjectStatusID";
import ObjectStatusBadge from "../../components/UI/ObjectStatusBadge";
import { formatUtcDateReadableLong } from "../../utilities/dateUtils";
import DescriptionField from "../../components/chakra/DescriptionField";

const RequirementDashboardScreen = () => {
  const navigate = useNavigate();
  const requirementPages = useApiQuery("requirement/pages", null);
  const requirementSummary = useApiQuery("requirement/topLevelSummary", null);
  const requirementData = requirementSummary.data;
  const { isError, isSuccess } = requirementSummary;

  return (
    <chakra-scope>
      <ChakraScreenContainer
        headerEndContent={
          <Menu>
            {requirementPages.isSuccess && (
              <>
                <MenuButton
                  size="sm"
                  as={Button}
                  leftIcon={<Icon as={RiFileAddLine} />}
                  rightIcon={<Icon as={RiArrowDownSLine} />}>
                  Create New Requirement
                </MenuButton>
                <MenuList minH="48px">
                  {requirementPages.isSuccess &&
                    requirementPages.data?.map(
                      ({ objectTypeID, name, icon }) => (
                        <MenuItem
                          onClick={() =>
                            navigate(`/requirement/new?type=${objectTypeID}`)
                          }>
                          <HStack>
                            <FeatherIcon size="md" name={icon} />
                            <Text>{name}</Text>
                          </HStack>
                        </MenuItem>
                      ),
                    )}
                </MenuList>
              </>
            )}
          </Menu>
        }
        contentSize="container.2xl"
        pageTitle="Requirements"
        breadcrumbs={[]}>
        {isSuccess && requirementData ? (
          <SimpleGrid flexWrap="wrap" columns={[2, 3, 4]} spacing="4">
            {requirementData.map(
              ({
                ID,
                name,
                description,
                icon,
                statusID,
                responsibleUsers,
                subRequirements,
                modifiedTs,
              }) => {
                return (
                  <LinkBox
                    _hover={{
                      boxShadow: "sm",
                      borderColor: `${
                        statusID === Negative ? "red" : "brand.500"
                      } `,
                      "& .arrow-icon": {
                        transform: "translate(3px, 0)",
                        color: `${statusID === Negative ? "red" : "brand.500"}`,
                        opacity: 1.0,
                      },
                      "& .heading-text": {
                        color: `${statusID === Negative ? "red" : "brand.500"}`,
                      },
                    }}
                    as={Card}
                    variant="outline"
                    borderColor={statusID === Negative ? "red" : undefined}>
                    <CardHeader>
                      <LinkOverlay
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        as={Link}
                        to={`/requirement/${ID}?display=requirements`}>
                        <Heading
                          transitionProperty="common"
                          transitionDuration="fast"
                          className="heading-text"
                          size="xs"
                          alignItems="center"
                          display="flex">
                          <Box mr="2">
                            <FeatherIcon size="md" name={icon} />
                          </Box>
                          {name}
                        </Heading>
                        <Icon
                          transitionProperty="common"
                          transitionDuration="fast"
                          color="black"
                          opacity="0.0"
                          ml="1"
                          boxSize="5"
                          transition=""
                          className="arrow-icon"
                          as={RiArrowRightLine}
                        />
                      </LinkOverlay>
                      <HStack w="full">
                        <ObjectStatusBadge statusID={statusID} />
                        <Tooltip
                          hasArrow
                          label={
                            <VStack align="start" spacing="0">
                              <Text
                                margin="0"
                                color="whiteAlpha.800">{`${responsibleUsers.firstName} ${responsibleUsers.lastName}`}</Text>
                            </VStack>
                          }>
                          <AvatarGroup size="sm" max={2}>
                            <Avatar
                              name={`${responsibleUsers.firstName} ${responsibleUsers.lastName}`}
                            />
                          </AvatarGroup>
                        </Tooltip>
                      </HStack>
                    </CardHeader>
                    <CardBody display="grid">
                      <VStack
                        align="start"
                        justify="space-between"
                        display="flex"
                        w="full">
                        <Box zIndex={1} w="full" position="relative">
                          <DescriptionField description={description || ""} />
                        </Box>
                        <VStack w="full" justify="space-between" align="start">
                          {subRequirements.count > 0 ? (
                            <>
                              <Text as="h1">
                                {subRequirements.count} Sub Requirements
                              </Text>
                              <Box
                                w="100%"
                                height="4"
                                display="flex"
                                flexDir="row"
                                zIndex={1}
                                borderRadius="md"
                                overflow="hidden">
                                <Tooltip
                                  label={`${subRequirements.positiveCount} Positive Sub Requirements`}
                                  aria-label="A tooltip"
                                  hasArrow>
                                  <Box
                                    h="100%"
                                    mx="1px"
                                    width={`${
                                      (subRequirements.positiveCount /
                                        subRequirements.count) *
                                      100
                                    }%`}
                                    bg="green.500"
                                  />
                                </Tooltip>
                                <Tooltip
                                  label={`${subRequirements.neutralCount} Neutral Sub Requirements`}
                                  aria-label="A tooltip"
                                  hasArrow>
                                  <Box
                                    h="100%"
                                    mx="1px"
                                    width={`${
                                      (subRequirements.neutralCount /
                                        subRequirements.count) *
                                      100
                                    }%`}
                                    bg="blue.400"
                                  />
                                </Tooltip>
                                <Tooltip
                                  label={`${subRequirements.negativeCount} Negative Sub Requirements`}
                                  aria-label="A tooltip"
                                  hasArrow>
                                  <Box
                                    h="100%"
                                    mx="1px"
                                    width={`${
                                      (subRequirements.negativeCount /
                                        subRequirements.count) *
                                      100
                                    }%`}
                                    bg="red.500"
                                  />
                                </Tooltip>
                              </Box>
                            </>
                          ) : (
                            <Text as="i">You have no Sub Requirements</Text>
                          )}
                        </VStack>
                      </VStack>
                    </CardBody>
                    <CardFooter>
                      <HStack zIndex={1} justifyContent="end" w="full">
                        <Tooltip
                          shouldWrapChildren
                          label={`Last Modified: ${formatUtcDateReadableLong(
                            new Date(modifiedTs),
                          )}`}>
                          <Text fontSize="xs" color="gray.500">
                            Last Modified:{" "}
                            {formatDistance(new Date(modifiedTs), Date.now())}{" "}
                            ago
                          </Text>
                        </Tooltip>
                      </HStack>
                    </CardFooter>
                  </LinkBox>
                );
              },
            )}
          </SimpleGrid>
        ) : isError ? (
          <Text>Something went wrong</Text>
        ) : (
          <SimpleGrid flexWrap="wrap" columns={[2, 3, 4]} spacing="4">
            <Skeleton h="60" />
            <Skeleton h="60" />
            <Skeleton h="60" />
            <Skeleton h="60" />
          </SimpleGrid>
        )}
      </ChakraScreenContainer>
    </chakra-scope>
  );
};

export default RequirementDashboardScreen;
