import { Button, Icon, Menu, MenuButton, MenuProps } from "@chakra-ui/react";
import React from "react";
import { DropdownIcon } from "../../../constants/commonIcons";

type Props = {
  leftIcon?: JSX.Element;
  children?: React.ReactNode;
  title: string;
  targetid?: string;
  buttonSize?: string;
} & MenuProps;

const TopBarMenu = ({
  leftIcon,
  children,
  title,
  targetid,
  buttonSize = "sm",
  ...otherProps
}: Props) => (
  <Menu placement="bottom" {...otherProps}>
    <MenuButton
      size={buttonSize}
      leftIcon={leftIcon}
      icon={leftIcon}
      rightIcon={<Icon boxSize={4} as={DropdownIcon} />}
      colorScheme="gray"
      variant="ghost"
      as={Button}>
      {title}
    </MenuButton>
    {children}
  </Menu>
);

export default TopBarMenu;
