import { ComponentStyleConfig } from "@chakra-ui/react";

const popoverStyle: ComponentStyleConfig = {
  parts: ["popper"],
  variants: {
    responsive: {
      popper: {
        width: "unset",
      },
    },
  },
};

export default {
  components: {
    Popper: popoverStyle,
  },
};
