import React from "react";
import { NewObjectTypeButton } from "./NewObjectTypeButton";
import * as ObjectTypeGroup from "../../shared/constants/objectTypeGroup";

export const NewIssueButton = () => (
  <div style={{ float: "right" }}>
    <NewObjectTypeButton
      variant="danger"
      title="Raise an Issue"
      header="New Issue"
      objectTypeGroupID={ObjectTypeGroup.Issue}
      type={1}
      parentType={ObjectTypeGroup.Issue}
    />
  </div>
);
