import { uniq } from "lodash";
import * as ObjectStatusID from "../shared/v2/constants/ObjectStatusID";
import { CustomFieldType } from "../shared/v2/definitions/customFields";
import {
  CoreFieldType,
  FieldIdentifier,
} from "../shared/v2/helpers/filters/baseFilterTypes";
import {
  FilterFieldConfig,
  FilterOp,
  FilterOperationsConfigByOp,
  FilterOperationsType,
} from "../shared/v2/helpers/filters/filterSchemas";

/**
 * Returns the FilterConfigs of the filter types that can be used by the given custom field
 */
export function getFilterTypeForCustomField(cf: CustomFieldType) {
  const { ops } = FilterFieldConfig[cf.customFieldTypeID];
  if (cf.listTypeID !== null) {
    ops.push(...FilterFieldConfig[cf.listTypeID].ops);
  }

  return uniq(ops).map((op) => FilterOperationsConfigByOp[op]);
}

/**
 * Returns the FilterConfigs of the filter types that can be used by the given core field
 */
export function getFilterTypeForCoreField(core: CoreFieldType) {
  const { ops } = FilterFieldConfig[core];
  return uniq(ops).map((op) => FilterOperationsConfigByOp[op]);
}

export function getAvailableOpsForField(
  field: CustomFieldType | CoreFieldType,
) {
  return typeof field === "string"
    ? getFilterTypeForCoreField(field)
    : getFilterTypeForCustomField(field);
}

/**
 * Config object for the actual labels on filter ops
 */
export const OpLabels: {
  [key in FilterOp]: string;
} = {
  /** String */
  contains: "contains",
  "not-contains": "not contains",
  exactly: "exactly",
  "string-exists": "exists",
  "starts-with": "starts with",
  "ends-with": "ends with",

  /** Number */
  eq: "=",
  "not-eq": "!=",
  less: "<",
  greater: ">",
  "less-eq": "<=",
  "greater-eq": ">=",

  /** Status */
  "is-status": "is",
  "is-not-status": "is not",

  /** Responsible User */
  "is-user": "is",
  "is-not-user": "is not",

  /** Related Record */
  "has-relation": "has relation",

  /** List */
  includes: "is",
  "not-includes": "is not",

  "not-null": "exists",
};

export const getOpLabel = (op: FilterOp | (string & {})) => {
  if (op in OpLabels) {
    return OpLabels[op as FilterOp];
  }
  return "unknown";
};

/**
 * A filter field that can be used in the filter builder
 * Includes all the neccessary information to build the UI
 */
export type FilterableFieldSelection = {
  value: string;
  label: string;
  identifier: FieldIdentifier;
  availableOps: FilterOperationsType[];
  listID?: string;
};

/**
 * A filter that is used on the front end while editing filters
 * Includes all the neccessary information to build the UI
 */
export type WorkingFilter = {
  ID: string;
  field: FilterableFieldSelection;
  op: FilterOperationsType;
  value:
    | {
        label: string;
        value: string | number;
      }
    | {
        label: string;
        value: string | number;
      }[]
    | undefined;
};

export const getCoreFilterableFields = (): FilterableFieldSelection[] => [
  {
    value: "name",
    label: "Name",
    identifier: {
      type: "name",
    },
    availableOps: getAvailableOpsForField("name"),
  },
  {
    value: "id",
    label: "ID",
    identifier: {
      type: "readableID",
    },
    availableOps: getAvailableOpsForField("readableID"),
  },
  {
    value: "description",
    label: "Description",
    identifier: {
      type: "description",
    },
    availableOps: getAvailableOpsForField("description"),
  },
  {
    value: "responsibleUser",
    label: "Responsible User",
    identifier: {
      type: "responsibleUserID",
    },
    availableOps: getAvailableOpsForField("responsibleUserID"),
  },
  {
    value: "status",
    label: "Status",
    identifier: {
      type: "objectStatusID",
    },
    availableOps: getAvailableOpsForField("objectStatusID"),
  },
];

export const StatusSelectOptions = [
  {
    value: ObjectStatusID.Positive,
    label: "Positive",
  },
  {
    value: ObjectStatusID.Negative,
    label: "Negative",
  },
  {
    value: ObjectStatusID.Neutral,
    label: "Neutral",
  },
  {
    value: ObjectStatusID.Closed,
    label: "Closed",
  },
];
