import {
  Box,
  Button,
  Flex,
  HStack,
  LinkBox,
  LinkOverlay,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Table,
  TableContainer,
  Tbody,
  Td,
  Tfoot,
  Th,
  Thead,
  Tr,
  useBreakpointValue,
  VStack,
} from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import { useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { useAppState } from "../../../components/App/AppProvider";
import MissingPermission from "../../../components/chakra/common/MissingPermission";
import Spinner from "../../../components/chakra/common/Spinner";
import Icon from "../../../components/UI/Icon";
import {
  ListTypeID,
  ListTypeIDToName,
} from "../../../shared/v2/constants/ListTypeID";
import { useApiQuery } from "../../../utilities/apibelRequest";
import SettingsScreenContainer from "./SettingsScreenContainer";

type ListSummary = {
  listID: string;
  listName: string;
  listTypeID: string;
};

const sortByName = (a: ListSummary, b: ListSummary) =>
  a.listName?.localeCompare(b.listName);
const sortByType = (a: ListSummary, b: ListSummary) =>
  ListTypeIDToName[a.listTypeID as ListTypeID]?.localeCompare(
    ListTypeIDToName[b.listTypeID as ListTypeID],
  );

const ListScreen = () => {
  const tableSize = useBreakpointValue({ base: "sm", lg: "md" }) as "md" | "lg";
  const { permissions } = useAppState().app;
  const hasAccountPermission = permissions.ACCOUNT_SETTINGS;
  const [sortBy, setSortBy] = useState<"name" | "type">("name");
  const listsQuery = useApiQuery("customFields/getAllLists", null, {
    enabled: hasAccountPermission,
  });
  const queryClient = useQueryClient();
  const handleClickRefresh = () => {
    queryClient.invalidateQueries("customFields");
  };
  const finalList = listsQuery.isSuccess ? listsQuery.data : [];

  const renderRow = (list: ListSummary) => (
    <Tr key={list.listID}>
      <LinkBox _hover={{ textDecor: "underline" }} as={Td}>
        <LinkOverlay
          ps="2"
          as={RouterLink}
          display="flex"
          alignItems="center"
          to={`/settings/list/${list.listID}`}>
          <Flex flexDir="column" align="start" as="span">
            <span>{list.listName}</span>
          </Flex>
        </LinkOverlay>
      </LinkBox>
      <Td>{ListTypeIDToName[list.listTypeID as ListTypeID]}</Td>
    </Tr>
  );
  const sortedLists = finalList.sort(
    sortBy === "name" ? sortByName : sortByType,
  );

  return (
    <chakra-scope>
      <SettingsScreenContainer
        pageTitle="Lists"
        headerEndContent={
          hasAccountPermission ? (
            <Box position="relative"><Menu>
            <MenuButton
              as={Button}
              colorScheme="brand"
              variant="solid"
              size="sm"
              rightIcon={<Icon name="ChevronDown" />}>
              Create New List
            </MenuButton>
            <MenuList>
              <MenuItem as={RouterLink} to="/settings/list/New?Number">
                Number
              </MenuItem>
              <MenuItem as={RouterLink} to="/settings/list/New?Text">
                Text
              </MenuItem>
            </MenuList>
          </Menu></Box>
          ) : (
            <> </>
          )
        }>
        {!hasAccountPermission ? (
          <MissingPermission />
        ) : (
          <VStack>
            <Box w="full">
              <Box w="full">
                <HStack justify="space-between">
                  <Menu size="sm">
                    <MenuButton
                      leftIcon={<Icon name="ArrowDown" />}
                      as={Button}
                      variant="ghost"
                      size="sm"
                      rightIcon={<Icon name="ChevronDown" />}>
                      Sort by {sortBy}
                    </MenuButton>
                    <MenuList zIndex="99">
                      <MenuItem onClick={() => setSortBy("name")}>
                        Name
                      </MenuItem>
                      <MenuItem onClick={() => setSortBy("type")}>
                        Type
                      </MenuItem>
                    </MenuList>
                  </Menu>

                  <Button
                    isLoading={listsQuery.isRefetching}
                    disabled={listsQuery.isFetching}
                    onClick={handleClickRefresh}
                    leftIcon={<Icon name="RefreshCw" />}
                    variant="ghost"
                    loadingText="Refreshing"
                    size="sm">
                    Refresh
                  </Button>
                </HStack>
              </Box>
              <TableContainer w="full" pt={2} pb={4}>
                <Table
                  minH="100"
                  variant="data-table"
                  size={tableSize}
                  position="relative">
                  <Thead>
                    <Tr>
                      <Th>List Name</Th>
                      <Th>List Type</Th>
                    </Tr>
                  </Thead>

                  {listsQuery.isSuccess && (
                    <>
                      <Tbody
                        opacity={
                          listsQuery.isLoading || listsQuery.isFetching
                            ? 0.3
                            : 1
                        }>
                        {sortedLists.map(renderRow)}
                      </Tbody>
                      <Tfoot>
                        <Tr>
                          <Th>List Name</Th>
                          <Th>List Type</Th>
                        </Tr>
                      </Tfoot>
                    </>
                  )}
                  {(listsQuery.isLoading || listsQuery.isFetching) && (
                    <Spinner
                      zIndex={1}
                      opacity={1}
                      size="lg"
                      position="absolute"
                      top="50%"
                      left="50%"
                    />
                  )}
                </Table>
              </TableContainer>
            </Box>
          </VStack>
        )}
      </SettingsScreenContainer>
    </chakra-scope>
  );
};

export default ListScreen;
