import {
  Avatar,
  AvatarGroup,
  Badge,
  Box,
  Checkbox,
  Collapse,
  HStack,
  IconButton,
  LinkBox,
  LinkOverlay,
  Spacer,
  Table,
  TableContainer,
  Tbody,
  chakra,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  UseDisclosureReturn,
} from "@chakra-ui/react";
import { addDays, subDays } from "date-fns";
import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { ObjectStatus } from "../../../../shared/v2/constants/ObjectStatusID";
import { TaskSummary } from "../../../../shared/v2/definitions/tasks";
import { lifecycleToLabel } from "../../../../shared/v2/helpers/lifecycle";
import { useApiQuery } from "../../../../utilities/apibelRequest";
import {
  formatTaskDueDate,
  formatTaskDueDateShort,
  formatUtcDateReadableShort,
} from "../../../../utilities/dateUtils";
import Spinner from "../../../chakra/common/Spinner";
import { SearchProviderFactory } from "../../../SearchProvider";
import Icon from "../../../UI/Icon";
import ObjectStatusBadge from "../../../UI/ObjectStatusBadge";
import { TaskFilters } from "../BaseWidgetProps";
import DateFilter from "../filters/DateFilter";
import LifecycleFilter from "../filters/LifecycleFilter";
import StatusFilter from "../filters/StatusFilter";
import UserFilter from "../filters/UserFilter";
import { getTaskWindow } from "../../../../utilities/tasks";

type Task = {
  id: string;
  readableId: string;
  name: string;
  status: ObjectStatus;
  lifecycle: string;
  date: string;
  description: string;
  responsibleUser: string;
  score: number;
};

type Props = {
  setFilters: (filters: TaskFilters) => void;
  onChangeSearch: (search: string) => void;
  disclosureState: UseDisclosureReturn;
  searchQuery: string;
  filters: TaskFilters;
  users: any[];
};

const TaskSearch = SearchProviderFactory<TaskSummary>();

const TasksTab = ({
  setFilters,
  onChangeSearch,
  disclosureState,
  ...props
}: Props) => {
  const [exactMatch, setExactMatch] = useState(true);

  const [disableTooltips, setDisabled] = useState(false);

  const eventRef = useRef<HTMLDivElement>();

  useEffect(() => {
    if (eventRef.current) {
      eventRef.current.addEventListener("scroll", () => {
        setDisabled(true);
      });
      eventRef.current.addEventListener("scrollend", () => {
        setDisabled(false);
      });
    }
    return () => {
      if (eventRef.current) {
        eventRef.current.removeEventListener("scroll", () => {
          setDisabled(true);
        });
        eventRef.current.removeEventListener("scrollend", () => {
          setDisabled(false);
        });
      }
    };
  }, []);
  const tasksQuery = useApiQuery("task/all", {
    query: props.searchQuery,
    fullTextQuery: !exactMatch,
    filters: {
      lifecycleID: props.filters.lifecycle,
      statusID: props.filters.statusId,
      responsibleUserID: props.filters.user?.id,
      dueBefore: props.filters.dueBefore,
      dueAfter: props.filters.dueAfter,
    },
    page: {
      page: 0,
      resultsPerPage: 200,
    },
  });

  return (
    <TaskSearch.SearchProvider>
      <HStack w="full">
        <TaskSearch.SearchController
          data={tasksQuery.data || []}
          placeholder="Search by Name or ID"
          onChangeSearch={onChangeSearch}
          value={props.searchQuery}
          searchConfig={{ debounceTime: 300 }}
          external
        />

        <IconButton
          onClick={disclosureState.onToggle}
          variant="ghost"
          aria-label={disclosureState.isOpen ? "Close Results" : "Open Results"}
          icon={
            <Icon name={disclosureState.isOpen ? "ChevronUp" : "ChevronDown"} />
          }
        />
      </HStack>
      <Box w="full">
        <Collapse in={disclosureState.isOpen} animateOpacity>
          <HStack>
            <LifecycleFilter
              lifecycle={props.filters.lifecycle}
              patchFilters={(patch) => {
                setFilters({ ...(props.filters as any), ...patch });
              }}
            />
            <StatusFilter
              statusId={props.filters.statusId as ObjectStatus | undefined}
              type="tasks"
              patchFilters={(patch) => {
                setFilters({
                  ...(props.filters as any),
                  ...patch,
                });
              }}
            />
            <UserFilter
              patchFilters={(patch) => {
                try {
                  setFilters({
                    ...(props.filters as any),
                    ...patch,
                  });
                } catch (e) {
                  console.error(`Error setting user filter: ${e}`);
                }
              }}
              type="tasks"
              user={props.filters.user}
              users={props.users}
            />
            <Spacer />
            <DateFilter
              patchFilters={(patch) => {
                try {
                  setFilters({
                    ...(props.filters as any),
                    ...patch,
                  });
                } catch (e) {
                  console.error(`Error setting date filter: ${e}`);
                }
              }}
              label="From"
              from={props.filters.dueAfter}
              to={props.filters.dueBefore}
            />

            <Checkbox
              pr={2}
              onChange={(e) => {
                setExactMatch(e.target.checked);
              }}
              isChecked={exactMatch}>
              Exact Match Search
            </Checkbox>
          </HStack>
          <TableContainer
            ref={eventRef}
            overflowY="auto"
            h={420}
            position="relative">
            <Table size="sm" variant="data-table">
              <Thead
                style={{ borderBottom: "none" }}
                position="sticky"
                top={0}
                background="white"
                zIndex={1}>
                <Tr>
                  <Th>Name</Th>
                  <Th>Due</Th>
                  <Th>Lifecycle</Th>
                  <Th>Status</Th>
                  <Th>Responsible User</Th>
                </Tr>
              </Thead>
              {(tasksQuery.isLoading || tasksQuery.isFetching) && (
                <Spinner position="absolute" top="50%" size="md" left="50%" />
              )}
              <Tbody
                opacity={
                  tasksQuery.isLoading || tasksQuery.isFetching ? 0.3 : 1
                }>
                <TaskSearch.SearchContext.Consumer>
                  {({ state }) => {
                    if (state.data.length) {
                      return state.data.map((task) => {
                        const date = new Date(task.instanceWhenTs);
                        const taskWindow = getTaskWindow({
                          instanceWhen: date,
                          windowBefore: task.windowBefore,
                          windowAfter: task.windowAfter,
                        })

                        const isMatch = Number(task.score) >= 1;
                        const isGoodMatch = Number(task.score) >= 5;
                        return (
                          <LinkBox key={task.instanceID} as={Tr}>
                            <Td
                              opacity={isMatch ? 1 : 0.7}
                              maxW={540}
                              overflowWrap="break-word"
                              overflow="clip">
                              <Text
                                as="span"
                                fontSize="sm"
                                color="blackAlpha.900">
                                <Badge
                                  mr="2"
                                  variant="subtle"
                                  colorScheme={isGoodMatch ? "green" : "gray"}>
                                  {task.actionReadableID}
                                </Badge>
                                <Tooltip
                                  isDisabled={disableTooltips}
                                  label={task.actionName}
                                  openDelay={500}
                                  closeOnScroll>
                                  <LinkOverlay
                                    as={Link}
                                    to={`/instance/${task.instanceID}`}>
                                    {task.actionName}
                                  </LinkOverlay>
                                </Tooltip>
                              </Text>
                            </Td>

                            <Td display="flex" flexDir="column">
                              <Text as="span" fontWeight="600">
                                {taskWindow.due.displayDate}
                              </Text>
                              <chakra.span
                                display="flex"
                                flexDir="column"
                                borderLeftWidth="1px"
                                borderLeftColor="brand.300"
                                pl="0.5">
                                <Text
                                  fontSize="xs"
                                  as="span"
                                  color="blackAlpha.800">
                                  <Text as="span" color="blackAlpha.600">
                                    Opens -{" "}
                                  </Text>
                                  {taskWindow.open.displayDate} {taskWindow.open.displayTime}
                                </Text>
                                <Text
                                  fontSize="xs"
                                  as="span"
                                  color="blackAlpha.800">
                                  <Text as="span" color="blackAlpha.600">
                                    Closes -{" "}
                                  </Text>
                                  {taskWindow.close.displayDate} {taskWindow.close.displayTime}
                                </Text>
                              </chakra.span>
                            </Td>

                            <Td>{lifecycleToLabel(task.lifecycle)}</Td>
                            <Td>
                              <ObjectStatusBadge
                                statusID={task.objectStatusID}
                              />
                            </Td>
                            <Td w={50}>
                              <AvatarGroup
                                display="flex"
                                justifyContent="right">
                                <Tooltip
                                  label={task.responsibleUser.userFullName}>
                                  <Avatar
                                    size="sm"
                                    name={
                                      task.responsibleUser.userFullName || ""
                                    }
                                  />
                                </Tooltip>
                              </AvatarGroup>
                            </Td>
                          </LinkBox>
                        );
                      });
                    }
                    return (
                      <Box position="absolute" top="50%" left="45%">
                        <Text>No records found</Text>
                      </Box>
                    );
                  }}
                </TaskSearch.SearchContext.Consumer>
              </Tbody>
            </Table>
          </TableContainer>
        </Collapse>
      </Box>
    </TaskSearch.SearchProvider>
  );
};

export default TasksTab;
