import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Card } from "../../components/UI/Card/Card";
import { Header } from "../../components/UI/Header/Header";

const ScreensAdmin = () => (
  <Container>
    <Header breadcrumbs={[{ title: "Admin", link: "caadmin" }]} title="" />
    <Card title="CA Admin" collapsable={false}>
      <Row>
        <Col>
          <Link to="/caadmin/create">Create account</Link>
        </Col>
      </Row>
      <Row>
        <Col>
          <Link to="/caadmin/suspend">Disable/Enable account</Link>
        </Col>
      </Row>
      <Row>
        <Col>
          <Link to="/caadmin/notify">Create Notification</Link>
        </Col>
      </Row>
    </Card>
  </Container>
);

export default ScreensAdmin;
