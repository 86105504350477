import React from "react";
import ObjectsByStatusWidget from "./widgets/ObjectsByStatusWidget";
import OpenIssuesWidget from "./widgets/OpenIssuesWidget";
import RequirementOverviewWidget from "./widgets/RequirementOverviewWidget";
import MyTasksWidget from "./widgets/MyTasksWidget";
import TaskByUserWidget from "./widgets/TasksByUserWidget";

/**
 * Register widgets for rendering in the dashboardrenderer component here
 */
export const WidgetRegistry = {
  "open-issues": OpenIssuesWidget,
  "tasks-by-user": TaskByUserWidget,
  "requirement-overview": RequirementOverviewWidget,
  "my-tasks": MyTasksWidget,
  "objects-by-status": ObjectsByStatusWidget,
};
