import {
  Button,
  Input,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Tooltip,
  VStack,
  chakra,
  useDisclosure,
} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import {
  FilterableFieldSelection,
  StatusSelectOptions,
} from "../../../../constants/frontEndFilterHelpers";
import { List } from "../../../../shared/v2/definitions/lists";
import { FilterOperationsType } from "../../../../shared/v2/helpers/filters/filterSchemas";
import Select from "../../../Select";
import ObjectStatusBadge from "../../../UI/ObjectStatusBadge";
import UserSelect from "./UserSelect";

type Props = {
  lists?: Record<string, List | null>;

  op: FilterOperationsType | undefined;
  field: FilterableFieldSelection | undefined;
  value:
    | {
        label: string;
        value: string | number;
      }
    | {
        label: string;
        value: string | number;
      }[]
    | undefined;

  onChange: (
    newValue:
      | {
          label: string;
          value: string | number;
        }
      | {
          label: string;
          value: string | number;
        }[]
      | undefined,
  ) => void;
};

const buttonProps = {
  borderLeftWidth: "1px",
  borderRightWidth: "1px",
  borderColor: "gray.200",
  color: "blackAlpha.700",
};

/**
 * This is used only in an already built filter to edit the value of the filter
 */
export default function FilterValueInputButton({
  op,
  field,
  value,
  lists,
  onChange,
}: Props) {
  const [internalState, setInternalState] = useState<string | number>(
    Array.isArray(value) ? "" : value?.value || "",
  );
  const popoverState = useDisclosure();

  const textInputRef = useRef<HTMLInputElement>(null);
  const numberInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (Array.isArray(value)) {
      setInternalState("");
    } else {
      setInternalState(value?.value || "");
    }
  }, [value]);

  if (!op || !field) {
    return <Button />;
  }

  const shortenText = (str: string | undefined) => {
    if (!str) return { text: "", tooltip: undefined };
    if (op.valueType === "number") {
      return { text: str, tooltip: undefined };
    }
    const basicStr = op.valueType === "userID" ? str.split(" ")[0] ?? "" : str;
    if (basicStr.length <= 15) {
      return {
        text: basicStr,
        tooltip: undefined,
      };
    }
    return {
      text: `${basicStr.slice(0, 11)}...`,
      tooltip: str,
    };
  };

  const { text, tooltip } = (() => {
    if (Array.isArray(value)) {
      // Length 4+ array
      // Always show a full tooltip, shorten the text to show 'x options'
      if (value.length >= 4) {
        return {
          text: (
            <span>
              <chakra.span color="blackAlpha.700">any of:</chakra.span>{" "}
              {value.length} options
            </span>
          ),
          tooltip: value.map((v) => v.label).join(", "),
        };
      }
      // Length 2-3 Array
      // Show invididual options in the text
      if (value.length >= 2) {
        const shortenedValues = value.map((v) => shortenText(v.label).text);
        return {
          text: (
            <span>
              <chakra.span color="blackAlpha.700">any of:</chakra.span>{" "}
              {shortenedValues.join(", ")}
            </span>
          ),
          tooltip: value.map((v) => v.label).join(", "),
        };
      }
      // Length 0-1 array
      return shortenText(value[0]?.label);
    }
    // Not an array
    return shortenText(value?.label);
  })();

  const handleSaveInput = () => {
    popoverState.onClose();
    onChange({
      label: `${internalState}`,
      value: internalState,
    });
  };

  switch (op.valueType) {
    case "string":
      // String is a popover with a text box
      return (
        <Popover
          returnFocusOnClose={false}
          isOpen={popoverState.isOpen}
          initialFocusRef={textInputRef}
          onClose={handleSaveInput}>
          <Tooltip label={tooltip}>
            <Button onClick={popoverState.onToggle} {...buttonProps}>
              <PopoverTrigger>
                <span>{text}</span>
              </PopoverTrigger>
            </Button>
          </Tooltip>
          <PopoverContent mt="2">
            <PopoverBody p="0">
              <VStack w="full" m="0">
                <Input
                  ref={textInputRef}
                  onKeyDown={(e) => {
                    // if enter pressed
                    if (e.key === "Enter") {
                      handleSaveInput();
                    }
                  }}
                  value={internalState}
                  onChange={(e) => setInternalState(e.target.value)}
                  variant="ghost"
                />
              </VStack>
            </PopoverBody>
          </PopoverContent>
        </Popover>
      );
    case "number":
      // Number is a popover with a number box
      return (
        <Popover
          returnFocusOnClose={false}
          isOpen={popoverState.isOpen}
          initialFocusRef={numberInputRef}
          onClose={handleSaveInput}>
          <Tooltip label={tooltip}>
            <Button onClick={popoverState.onToggle} {...buttonProps}>
              <PopoverTrigger>
                <span>{text}</span>
              </PopoverTrigger>
            </Button>
          </Tooltip>
          <PopoverContent mt="2">
            <PopoverBody p="0">
              <VStack w="full" m="0">
                <NumberInput
                  w="full"
                  variant="ghost"
                  onKeyDown={(e) => {
                    // if enter pressed
                    if (e.key === "Enter") {
                      handleSaveInput();
                    }
                  }}
                  value={internalState}
                  onChange={(e) => setInternalState(+e)}>
                  <NumberInputField ref={numberInputRef} />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
              </VStack>
            </PopoverBody>
          </PopoverContent>
        </Popover>
      );

    case "objectStatusID":
      // Object status id is a select
      return (
        <Select
          multiselect
          options={StatusSelectOptions}
          value={Array.isArray(value) ? value : value ? [value] : []}
          onChange={(newVal) => onChange(newVal)}
          renderOptionWithWrapper
          renderOption={(renderProps) => (
            <ObjectStatusBadge statusID={`${renderProps.option?.value}`} />
          )}
          placeholderText={text}
          type="simple-button"
          simpleButtonProps={buttonProps}
        />
      );
    case "list": {
      const { listID } = field;
      const list = !!listID && lists?.[listID];
      if (!list) {
        return null;
      }
      const opts: {
        value: string;
        label: string;
      }[] = list.options.map((opt) => ({
        value: opt.id,
        label: `${opt.value}`,
      }));
      return (
        <Select
          multiselect
          options={opts}
          value={Array.isArray(value) ? value : value ? [value] : []}
          onChange={(newVal) => onChange(newVal)}
          type="simple-button"
          simpleButtonProps={buttonProps}
          placeholderText={text}
        />
      );
    }
    case "userID":
      return (
        <UserSelect
          buttonProps={buttonProps}
          isInActiveFilter
          text={text}
          selected={Array.isArray(value) ? value : value ? [value] : []}
          onChange={(newVal) => onChange(newVal)}
        />
      );
      return null;
    // Todo: rest of the other options
    default:
      return null;
  }
}
