import {
  Badge,
  Button,
  ButtonGroup,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Heading,
  Icon,
  Link,
  LinkBox,
  LinkOverlay,
  Skeleton,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
  chakra,
  useColorModeValue,
} from "@chakra-ui/react";
import lodashGet from "lodash/get";
import { useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { Link as RouterLink, useParams } from "react-router-dom";
import Select from "../../components/Select";
import UserAccountStatusBadge from "../../components/UI/UserAccountStatusBadge";
import ChakraScreenContainer from "../../components/chakra/ChakraScreenContainer";
import Spinner from "../../components/chakra/common/Spinner";
import { RefreshIcon } from "../../constants/commonIcons";
import { UserServicePermissions } from "../../shared/v2/constants/ServicePermissions";
import * as UserAccountStatusID from "../../shared/v2/constants/UserAccountStatusID";
import { GlobalAccount } from "../../shared/v2/definitions/accounts";
import { useApiMutation, useApiQuery } from "../../utilities/apibelRequest";
import { formatUtcDateReadableLong } from "../../utilities/dateUtils";
import NotFoundScreen from "../NotFound";

type Props = {
  servicePermissions: UserServicePermissions;
};

export default function ServiceUserScreen({ servicePermissions }: Props) {
  const emptyColor = useColorModeValue("blackAlpha.500", "whiteAlpha.500");
  const { accountID } = useParams();

  // Filter state
  const [isInternal, setIsInternal] = useState<{
    value: boolean | undefined;
    label: string;
  }>({ value: undefined, label: "All" });

  const [userStatus, setUserStatus] = useState<{
    value: string | undefined;
    label: string;
  }>({ value: undefined, label: "Any" });

  // Query
  const accountQuery = useApiQuery("service/account", {
    accountId: accountID as string,
  });
  const blueprintDraftQuery = useApiQuery("service/blueprintDrafts", {
    accountID,
  });

  // Mutations
  const isDemoMutation = useApiMutation("service/markAccountIsDemo");
  const isBlueprintMutation = useApiMutation("service/markAccountIsBlueprint");
  const isAccountActiveMutation = useApiMutation(
    "service/updateIsAccountActive",
  );
  const createBlueprintDraftMutation = useApiMutation(
    "service/createBlueprintDraft",
  );
  const isLoading =
    isBlueprintMutation.isLoading ||
    isDemoMutation.isLoading ||
    accountQuery.isLoading ||
    accountQuery.isFetching;

  const accountDetails = accountQuery.data;
  const dataToDisplay = [
    {
      key: "accountName",
      label: "Account Name",
    },
    {
      key: "systemName",
      label: "Account System Name",
    },
    {
      key: "userLicenses",
      label: "User Licenses",
      type: "badge",
    },
    {
      key: "isAccountActive",
      label: "Active",
    },
    {
      key: "isIcalActive",
      label: "iCal Active",
    },
    {
      key: "lastGodToolRunTs",
      label: "Last God Tool Run",
      type: "date",
    },
    {
      key: "tags",
      label: "Account Tags",
      type: "tags",
    },
  ];
  const queryClient = useQueryClient();
  const handleClickRefresh = () => {
    queryClient.invalidateQueries("service");
  };
  const getData = (key: string, type?: string): JSX.Element | string => {
    const data = lodashGet(accountDetails, key);

    if (data === undefined || data === null || data === "") {
      return (
        <Text fontStyle="italic" color={emptyColor}>
          None
        </Text>
      );
    }
    if (type === "date") {
      return formatUtcDateReadableLong(new Date(data));
    }
    if (type === "badge") {
      const activeUsers = accountDetails?.activeUsersCount;
      return (
        <Badge
          colorScheme={
            activeUsers ? (activeUsers <= data ? "green" : "red") : "red"
          }>
          {activeUsers} / {data}
        </Badge>
      );
    }
    if (type === "tags") {
      return (
        <HStack>
          {data.map((tag: string) => (
            <Badge key={tag} colorScheme="brand">
              {tag}
            </Badge>
          ))}
        </HStack>
      );
    }
    if (typeof data === "boolean") {
      return data ? "True" : "False";
    }
    return data;
  };

  const usersToShow =
    accountDetails?.users
      .filter((u) => {
        if (
          userStatus.value !== undefined &&
          userStatus.value !== u.userAccountStatusID
        ) {
          return false;
        }
        if (
          isInternal.value !== undefined &&
          isInternal.value !== u.isInternal
        ) {
          return false;
        }
        return true;
      })
      .sort(
        (a, b) =>
          `${a.firstName} ${a.lastName}`?.localeCompare(
            `${b.firstName} ${b.lastName}`,
          ),
      ) || [];

  const renderUserRow = (user: GlobalAccount["users"][number]) => (
    <LinkBox as={Tr} key={user.userID}>
      <Td display="flex">
        <LinkOverlay
          ps="2"
          as={RouterLink}
          display="flex"
          flexDir="column"
          alignItems="start"
          to={`/_ccserviceportal/user/${user.userID}`}>
          <Flex flexDir="column" align="start" as="span">
            <chakra.span>
              <Badge colorScheme="gray"> {user.userID} </Badge>
            </chakra.span>
            <Text>
              {user.firstName} {user.lastName}
            </Text>
          </Flex>
        </LinkOverlay>
      </Td>
      <Td>{user.email}</Td>
      <Td>{user.isInternal ? "True" : "False"}</Td>
      <Td>
        <UserAccountStatusBadge statusID={user.userAccountStatusID} />
      </Td>
      <Td>{formatUtcDateReadableLong(new Date(user.userInviteTs))}</Td>
    </LinkBox>
  );
  if (!servicePermissions["manage-accounts"]) {
    return <NotFoundScreen />;
  }
  return (
    <chakra-scope>
      <ChakraScreenContainer
        contentSize="container.xl"
        pageTitle={accountDetails?.accountName ?? "Manage Account"}
        pageSubtitle={accountDetails?.accountID}
        breadcrumbs={[
          { label: "Service Portal", link: "/_ccserviceportal" },
          { label: "Manage Accounts", link: "/_ccserviceportal/accounts" },
        ]}>
        <Button
          isLoading={accountQuery.isRefetching}
          disabled={accountQuery.isFetching}
          onClick={handleClickRefresh}
          leftIcon={<Icon as={RefreshIcon} />}
          variant="ghost"
          loadingText="Refreshing"
          size="sm">
          Refresh
        </Button>
        <VStack w="full" align="start">
          <HStack w="full" align="start" spacing="4">
            {accountQuery.isLoading ? (
              <VStack w="full" spacing={4}>
                <Skeleton h={6} w="90%" />
                <Skeleton h={6} w="90%" />
                <Skeleton h={6} w="90%" />
                <Skeleton h={6} w="90%" />
                <Skeleton h={6} w="90%" />
                <Skeleton h={6} w="90%" />
                <Skeleton h={6} w="90%" />
                <Skeleton h={6} w="90%" />
                <Skeleton h={6} w="90%" />
              </VStack>
            ) : (
              <TableContainer w="full">
                <Table size="sm" colorScheme="gray" variant="striped" w="full">
                  <Tbody>
                    {dataToDisplay.map(({ label, key, type }) => (
                      <Tr
                        key={key}
                        opacity={accountQuery.isFetching ? 0.4 : undefined}>
                        <Td fontWeight="bold">{label}</Td>
                        <Td>{getData(key, type)}</Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </TableContainer>
            )}

            <VStack align="start" w="full">
              <Heading size="sm">Actions</Heading>
              <ButtonGroup isAttached orientation="vertical" variant="outline">
                <Button
                  isLoading={isLoading}
                  isDisabled={isLoading}
                  onClick={async () => {
                    await isDemoMutation.mutateAsync({ accountId: accountID });
                    queryClient.invalidateQueries("service");
                  }}>
                  {!accountDetails?.tags.includes("demo") ? "Add" : "Remove"}{" "}
                  Demo Tag
                </Button>
                <Button
                  isLoading={isLoading}
                  isDisabled={isLoading}
                  onClick={async () => {
                    await isBlueprintMutation.mutateAsync({
                      accountId: accountID,
                    });
                    queryClient.invalidateQueries("service");
                  }}>
                  {!accountDetails?.tags.includes("blueprint")
                    ? "Add"
                    : "Remove"}{" "}
                  Blueprint Tag
                </Button>
                <Button
                  isLoading={isLoading}
                  isDisabled={isLoading}
                  colorScheme={
                    accountDetails?.isAccountActive ? "red" : "brand"
                  }
                  onClick={async () => {
                    if (!accountID) return;
                    await isAccountActiveMutation.mutateAsync({
                      accountID,
                      isAccountActive: !accountDetails?.isAccountActive,
                    });
                    queryClient.invalidateQueries("service");
                  }}>
                  {accountDetails?.isAccountActive ? "Disable" : "Enable"}{" "}
                  Account
                </Button>
                <Button
                  isLoading={isLoading}
                  isDisabled={
                    isLoading ||
                    blueprintDraftQuery.isFetching ||
                    (blueprintDraftQuery.data?.length || 0) >= 1
                  }
                  colorScheme="orange"
                  onClick={async () => {
                    if (!accountID) return;
                    await createBlueprintDraftMutation.mutateAsync({
                      accountID,
                      configID: "iso9001",
                    });
                    queryClient.invalidateQueries("service");
                  }}>
                  Add Blueprint Config
                </Button>
              </ButtonGroup>
              <VStack>
                {blueprintDraftQuery.isSuccess &&
                  blueprintDraftQuery.data.map((draft) => (
                    <VStack
                      key={draft.ID}
                      borderWidth="1px"
                      align="start"
                      p="2">
                      <HStack w="full" justify="space-between">
                        <HStack>
                          <Heading size="sm">Blueprint</Heading>
                          <Badge>{draft.status}</Badge>
                        </HStack>
                        <Text fontStyle="italic" color="gray.500">
                          Contact dev team to delete or edit
                        </Text>
                      </HStack>
                      <Link
                        href={`${window.location.origin}/blueprint/setup/${draft.ID}`}
                        color="gray.600">
                        {window.location.origin}/blueprint/setup/{draft.ID}
                      </Link>
                    </VStack>
                  ))}
              </VStack>
            </VStack>
          </HStack>
        </VStack>

        <VStack paddingTop="28" align="start">
          <Heading size="md">Users</Heading>
          <HStack>
            <FormControl w="180px">
              <FormLabel htmlFor="filterUsersType">Type</FormLabel>
              <Select
                w="100%"
                name="filterUsersType"
                value={isInternal}
                options={[
                  { label: "All", value: undefined },
                  { label: "Internal Users", value: true },
                  { label: "License Users", value: false },
                ]}
                onChange={(e) => {
                  if (!e) {
                    setIsInternal({ label: "All", value: undefined });
                  } else {
                    setIsInternal(e);
                  }
                }}
              />
            </FormControl>
            <FormControl w="180px">
              <FormLabel htmlFor="filterUsersStatus">Status</FormLabel>
              <Select
                w="100%"
                name="filterUsersStatus"
                value={userStatus}
                options={[
                  { label: "Any", value: undefined },
                  { label: "Pending", value: UserAccountStatusID.Pending },
                  { label: "Active", value: UserAccountStatusID.Active },
                ]}
                onChange={(e) => {
                  if (!e) {
                    setUserStatus({ label: "Any", value: undefined });
                  } else {
                    setUserStatus(e);
                  }
                }}
              />
            </FormControl>
          </HStack>
          <TableContainer w="full">
            <VStack w="full" align="start" spacing="4">
              <Table size="sm" colorScheme="gray" variant="striped">
                <Thead position="relative">
                  <Tr>
                    <Th>User</Th>
                    <Th>Email</Th>
                    <Th>Is Internal</Th>
                    <Th>Status</Th>
                    <Th>User Invited</Th>
                  </Tr>
                </Thead>

                {accountQuery.isLoading && (
                  <Tbody>
                    <Tr>
                      <Spinner
                        zIndex={1}
                        opacity={1}
                        size="lg"
                        position="absolute"
                        left="50%"
                      />
                    </Tr>
                  </Tbody>
                )}
                {accountQuery.isSuccess && (
                  <Tbody
                    opacity={
                      accountQuery.isLoading || accountQuery.isFetching
                        ? 0.4
                        : 1
                    }>
                    {usersToShow.map(renderUserRow)}
                  </Tbody>
                )}
              </Table>
            </VStack>
          </TableContainer>
        </VStack>
      </ChakraScreenContainer>
    </chakra-scope>
  );
}
