import {
  Box,
  Center,
  Flex,
  LinkBox,
  LinkOverlay,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Skeleton,
} from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import { WidgetConfigTypes } from "../../../shared/v2/definitions/dashboards";
import ObjectStatusBadge from "../../UI/ObjectStatusBadge";
import { BaseWidgetProps } from "./BaseWidgetProps";
import DashboardWidget from "./DashboardWidget";
import { useApiQuery } from "../../../utilities/apibelRequest";

type Props = BaseWidgetProps & {
  config: WidgetConfigTypes["requirement-overview"];
};

const RequirementOverviewWidget = ({
  title,
  config,
  dashboardID,
  widgetID,
}: Props) => {
  const requirementsOverviewQuery = useApiQuery(
    "dashboard/widget/requirementOverview",
    {
      widgetID,
      dashboardID,
      widgetType: "requirement-overview",
    },
  );
  const data = requirementsOverviewQuery.data ?? [];

  return (
    <DashboardWidget
      isLoading={requirementsOverviewQuery.isLoading}
      isRefetching={requirementsOverviewQuery.isRefetching}
      title={title ?? "Requirement Overview"}
      helpText="Status of all top level requirements in the system"
      onReload={requirementsOverviewQuery.refetch}>
      <Box maxH="250" overflowY="auto">
        <Table size="sm" variant="simple">
          {data.length <= 0 ? null : (
            <Thead>
              <Tr>
                <Th>Top Level Requirement</Th>
                <Th>Status</Th>
              </Tr>
            </Thead>
          )}
          <Tbody>
            {requirementsOverviewQuery.isLoading ||
            requirementsOverviewQuery.isFetching ? (
              <>
                <Tr>
                  <Td>
                    <Skeleton height="4" />
                  </Td>
                  <Td>
                    <Skeleton height="4" />
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <Skeleton height="4" />
                  </Td>
                  <Td>
                    <Skeleton height="4" />
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <Skeleton height="4" />
                  </Td>
                  <Td>
                    <Skeleton height="4" />
                  </Td>
                </Tr>
              </>
            ) : data.length <= 0 ? (
              <Center>
                <span>You have no top level requirements</span>
              </Center>
            ) : (
              data.map((d) => (
                <Tr key={d.requirementID}>
                  <LinkBox _hover={{ textDecor: "underline" }} as={Td}>
                    <LinkOverlay
                      ps="2"
                      as={RouterLink}
                      display="flex"
                      alignItems="center"
                      to={`/requirement/${d.requirementID}?display=requirements`}>
                      <Flex flexDir="column" align="start" as="span">
                        <span>{d.requirementName}</span>
                      </Flex>
                    </LinkOverlay>
                  </LinkBox>

                  <Td>
                    <ObjectStatusBadge statusID={d.objectStatusID} />
                  </Td>
                </Tr>
              ))
            )}
          </Tbody>
        </Table>
      </Box>
    </DashboardWidget>
  );
};

export default RequirementOverviewWidget;
