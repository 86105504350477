const OnceOff = 'b4b5980e-f0be-46de-91c1-382a62c1cd39'
const Daily = '9e8e9142-4582-4cce-8734-dfd92906599b'
const Weekly = 'a3d0c772-5b20-4b33-aaf8-94d4bf34e54a'
const Fortnightly = "82bacb10-b0cd-471e-9758-c28e89228b44"
const Monthly = 'ded2eca5-8077-4a2e-b778-d47c67361c04'
const Quarterly = '67262ab6-0ca3-4d4d-b9c4-76328ba00cd7'
const BiAnnually = 'd094da0c-3377-45c3-ba5f-b17e62b59bed'
const EveryYear = 'c09afaac-b7da-42f9-b39c-a5889d799903'
const Every2Years = '2c7ddd7c-1f14-4d5e-a0a9-54700419bfbf'
const Every3Years = '358aa281-d7dd-45ae-abe8-43dbae95faf9'

export { OnceOff, Daily, Weekly, Fortnightly, Monthly, Quarterly, BiAnnually, EveryYear, Every2Years, Every3Years }
