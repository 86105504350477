import {
  Box,
  Checkbox,
  Flex,
  HStack,
  Icon,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Tag,
  TagCloseButton,
  TagLabel,
  Text,
  useDisclosure,
  As,
  Button,
  ButtonProps,
  Radio,
  FlexProps,
} from "@chakra-ui/react";
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { DropdownIcon } from "../constants/commonIcons";
import Spinner from "./chakra/common/Spinner";

export type SelectKey = string | number;
export type SelectLabel = string;

export type SingleSelectOption<TOptionType> = TOptionType | undefined;
export type MultiSelectOption<TOptionType> = TOptionType[];

/**
 * This is always an array, even if it's a single select
 * This is due to the possibility of the multiselect prop changing
 */
type SelectInternalStateType<TOptionType> = MultiSelectOption<TOptionType>;

type CustomRenderOptionProps<TOptionType> = {
  onClick: () => void;
  option: TOptionType;
  isSelected: boolean;
};

type DefaultOptionComponentProps = {
  onClick: () => void;
  label: React.ReactNode;
  isSelected: boolean;
  multiselect: boolean;
  displaySelected: boolean;
};


const DefaultOptionComponent = ({
  onClick,
  label,
  isSelected,
  multiselect,
  displaySelected,
}: DefaultOptionComponentProps): JSX.Element => {
  return (
    <HStack
      role="option"
      align="center"
      justify="start"
      py={1}
      px={4}
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        onClick();
      }}
      _hover={{ cursor: "pointer", backgroundColor: "blackAlpha.50" }}>
      {displaySelected ? (
        multiselect ? (
          <Checkbox mb="0" size="sm" isChecked={isSelected} />
        ) : (
          <Radio mb="0" size="sm" isChecked={isSelected} />
        )
      ) : null}
      <span>{label}</span>
    </HStack>
  );
};

type GetKeyFunction<TOptionType> = (opt: TOptionType) => SelectKey;
type GetLabelFunction<TOptionType> = (opt: TOptionType) => SelectLabel;

type BaseProps<TOptionType> = {
  options: TOptionType[];
  isDisabled?: boolean;
  isReadOnly?: boolean;
  placeholderText?: React.ReactNode;
  getKey?: GetKeyFunction<TOptionType>;
  getLabel?: GetLabelFunction<TOptionType>;
  renderOption?: (
    props: CustomRenderOptionProps<TOptionType>,
  ) => React.ReactNode;
  renderOptionWithWrapper?: boolean;
  closeOnSelect?: boolean;
  name?: string;
  simpleButtonProps?: ButtonProps;
  buttonGroupFlexProps?: FlexProps;
  type?: "dropdown"  | "simple-button" | "button-group";
  isLoading?: boolean;
};

type Props<TOptionType> = BaseProps<TOptionType> &
  (
    | {
        multiselect: true;
        value?: MultiSelectOption<TOptionType>;
        defaultValue?: MultiSelectOption<TOptionType>;
        onChange?: (value: MultiSelectOption<TOptionType>) => void;
        singleOptionsDisplayMode?: undefined;
        renderValue?: (
          value: MultiSelectOption<TOptionType>,
        ) => React.ReactNode;
      }
    | {
        multiselect?: false;
        value?: SingleSelectOption<TOptionType>;
        defaultValue?: SingleSelectOption<TOptionType>;
        onChange?: (value: SingleSelectOption<TOptionType>) => void;
        singleOptionsDisplayMode?: "hide-selected" | "radio";
        renderValue?: (
          value: SingleSelectOption<TOptionType>,
        ) => React.ReactNode;
      }
  );

const defaultGetLabel = (opt: any): string =>
  typeof opt === "string" ? opt : opt.label;
const defaultGetKey = (opt: any): string =>
  typeof opt === "string" ? opt : opt.value;

/**
 *  `Select` is a customizable and flexible select component that allows
 *  for custom rendering of options. It is designed to be a versatile and
 *  reusable component that can be used as a single or multi-select input.
 *

 * If you are using a custom optiontype that does have (label/value) then you _must_ supply getLabel and getKey functions
 *
 *  @template OptionType - The type of the options provided to the select component.
 *
 *  @param {Props<TOptionType>} props - The properties passed to the select component.
 *  @param {TOptionType[]} props.options - An array of options to be displayed in the select component.
 *  @param {boolean} [props.isDisabled] - Determines if the select component is disabled. Defaults to false.
 *  @param {boolean} [props.isReadOnly] - Determines if the select component is read-only. Defaults to false.
 *  @param {string} [props.placeholderText] - placeholderText string to show if nothing is selected
 *  @param {opt: TOptionType) => string | number} [props.getKey]
 *    - A function to get the key of an option.
 *    - Defaults to opt if opt is a string, otherwise opt.value
 *  @param {opt: TOptionType) => string} [props.getLabel]
 *    - A function to get the display label of an option.
 *    - Defaults to opt if opt is a string, otherwise opt.label
 *  @param {boolean} [props.multiselect] - Determines if multiple options can be selected. Defaults to false.
 *  @param {(props: CustomRenderOptionProps<TOptionType>) => React.ReactNode} [props.renderOption]
 *    - A function to override the render of options in the select dropdown.
 *  @param {boolean} [props.renderOptionWithWrapper] - Determines if the default option wrapper should be used when rendering options. Defaults to true.
 *  @param {TOptionType[] | TOptionType} [props.value]
 *    - The current value(s) of the select component
 *    - If multiselect is true, this should be an array of options, otherwise a single option
 *  @param {(value: OptionType[] | OptionType | undefined) => void} [props.onChange]
 *   - A function to handle value changes
 *   - If multiselect is true, this will be called with an array of options, otherwise a single option/undefined
 *  @param {boolean} [props.closeOnSelect] - Determines if the select should close when an option is selected. Defaults to true in single select and false in multi.
 *  @param {"radio" | "hide-selected"} singleOptionsDisplayMode - Defaults to "hide-selected"
 *  @param {(value: TOptionType[] | TOptionType) => React.ReactNode} [props.renderValue] - A render function that overrides the default render of the selected value(s)
 */
export function Select<TOptionType>({
  multiselect,
  value,
  defaultValue,
  onChange,
  options,
  isDisabled,
  isReadOnly,
  placeholderText,
  getLabel: suppliedGetLabel,
  getKey: suppliedGetKey,
  renderOption,
  renderOptionWithWrapper,
  closeOnSelect,
  name,
  type,
  simpleButtonProps,
  singleOptionsDisplayMode,
  isLoading,
  buttonGroupFlexProps,
  renderValue,
  ...otherProps
}: Props<TOptionType> & Omit<FlexProps, "onChange">): JSX.Element {
  const { onOpen, onClose, isOpen } = useDisclosure();

  const getLabel = suppliedGetLabel || defaultGetLabel;
  const getKey = suppliedGetKey || defaultGetKey;

  const autoClose = closeOnSelect ?? !multiselect;

  const shouldDisplayCheckmarks =
    multiselect || singleOptionsDisplayMode === "radio";

  const [valueState, setValueState] = useState<
    SelectInternalStateType<TOptionType>
  >(() => {
    if (multiselect) {
      return !!value ? value : defaultValue ?? [];
    }
    return value ? [value] : defaultValue ? [defaultValue] : [];
  });

  const ref = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const resizeInput = () => {
    if (ref.current && inputRef.current) {
      const refHeight = ref.current.getBoundingClientRect().height;
      inputRef.current.style.height = `${refHeight}px`;
    }
  };

  useLayoutEffect(() => {
    const handleResize = () => {
      resizeInput();
    };

    handleResize();

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [ref, inputRef]);

  useEffect(() => {
    const observer = new ResizeObserver(() => {
      resizeInput();
    });

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [ref, inputRef]);

  // If multiselect, alwaus show all options so we can deselect them
  const optionsAvailable = shouldDisplayCheckmarks
    ? options
    : valueState
    ? options.filter((option) => {
        // Filter out options that are already selected
        // valueState is always an array of values
        return !valueState.find((curr) => getKey(curr) === getKey(option));
      })
    : options;

  const handleClickOption = useCallback(
    (selected: TOptionType) => {
      if (!multiselect) {
        // Replace current selection with this one
        setValueState([selected]);
        if (onChange) onChange(selected);
      } else {
        // Append or remove this value from the selection
        setValueState((prev) => {
          const selectedValue = getKey(selected);
          const alreadyExists = prev.find(
            (curr) => getKey(curr) === selectedValue,
          );
          const newValue = alreadyExists
            ? prev.filter((curr) => getKey(curr) !== selectedValue)
            : [...prev, selected];
          if (onChange) onChange(newValue);
          return newValue;
        });
      }
      // Close the select if we are not multiselecting or if we are multiselecting and autoClose is true
      if (autoClose) {
        onClose();
      }
    },
    [getKey, multiselect, onChange, autoClose, onClose],
  );

  const handleClickRemoveOption = (optionToRemove: TOptionType) => {
    const valueToRemove = getKey(optionToRemove);
    setValueState((prev) => {
      const newValue = prev.filter((opt) => getKey(opt) !== valueToRemove);
      if (onChange) {
        if (multiselect) {
          onChange(newValue);
        } else {
          onChange(newValue[0] ?? undefined);
        }
      }
      return newValue;
    });
  };

  useEffect(() => {
    if (value) {
      if (multiselect) {
        setValueState(value);
      } else {
        setValueState([value]);
      }
    } else {
      setValueState([]);
    }
  }, [value, handleClickOption, multiselect]);

  useEffect(() => {
    if (!multiselect && valueState.length >= 2) {
      setValueState((prev) => (prev[0] ? [prev[0]] : []));
    }
  }, [multiselect, valueState.length]);

  const isPlaceholder = valueState.length <= 0;

  const valueToRender = multiselect
    ? valueState.length >= 1
      ? ""
      : placeholderText ?? ""
    : valueState[0]
    ? getLabel(valueState[0])
    : placeholderText ?? "";

    if (type === "button-group") {
      return <Flex display="flex" flexWrap="wrap" {...buttonGroupFlexProps}>
        {optionsAvailable?.map((option) => 
        {
          const isSelected = !!valueState.find(
            (v) => getKey(v) === getKey(option),
          );
        return <Button onClick={() => handleClickOption(option)} key={getKey(option)} mr="1" mb="0.5" variant="outline" size="sm" color={isSelected ? undefined : "blackAlpha.700"} bgColor={isSelected ? "brand.50" : undefined}>
          {getLabel(option)}
        </Button>}
        )}
      </Flex>
    }

  return (
    <Popover
      isLazy
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      closeOnBlur
      closeOnEsc
      matchWidth>
      <PopoverTrigger>
        {type === "simple-button" ? (
          <Button {...simpleButtonProps}>{placeholderText}</Button>
        ) : (
          <Flex
            name={name}
            as="button"
            type="button"
            role="button"
            disabled={isDisabled}
            transitionProperty="common"
            transitionDuration="normal"
            borderRadius="md"
            minW="0"
            outline="2px solid transparent"
            outlineOffset="2px"
            pos="relative"
            appearance="none"
            borderWidth="1px"
            minH="42px"
            p="2"
            justifyContent="space-between"
            alignItems="center"
            _focus={{ outline: "none" }}
            _hover={{
              "> .dropdown-icon": {
                color: "brand.500",
              },
              borderColor: isOpen ? "blue.500" : "gray.300",
            }}
            borderColor={isOpen ? "blue.500" : "inherit"}
            opacity={isDisabled ? 0.4 : 1}
            cursor={isDisabled ? "not-allowed" : "pointer"}
            {...otherProps}>
            <Flex w="full" flexWrap="wrap" gap="2">
              {valueState.length <= 0 && (
                <Box as="span" px="2" color="blackAlpha.600">
                  {valueToRender}
                </Box>
              )}
              {multiselect ? (
                renderValue ? (
                  <Box as="span" px="2" color="black">
                    {renderValue(valueState)}
                  </Box>
                ) : (
                  valueState?.map((v) => {
                    return (
                      <Tag
                        flexShrink="0"
                        key={getKey(v)}
                        size="md"
                        variant="subtle"
                        colorScheme="brand">
                        <TagLabel>{getLabel(v)}</TagLabel>
                        {!isReadOnly ? (
                          <TagCloseButton
                            isDisabled={isDisabled || isReadOnly}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleClickRemoveOption(v);
                            }}
                          />
                        ) : null}
                      </Tag>
                    );
                  })
                )
              ) : valueState[0] ? (
                <Box
                  as="span"
                  whiteSpace="nowrap"
                  px="2"
                  color={isPlaceholder ? "blackAlpha.600" : "black"}>
                  {renderValue ? renderValue(valueState[0]) : valueToRender}
                </Box>
              ) : undefined}
            </Flex>
            <Icon className="dropdown-icon" boxSize="5" as={DropdownIcon} />
          </Flex>
        )}
      </PopoverTrigger>
      <PopoverContent minW="max-content" w="full" p={0} maxWidth="full">
        <PopoverBody w="full" maxWidth="full" p={0}>
          <Flex
            flexDirection="column"
            maxHeight="256"
            overflowY="auto"
            position="relative">
            {isLoading ? (
              <Spinner position="absolute" left="0" top="0" />
            ) : optionsAvailable.length >= 1 ? (
              optionsAvailable?.map((option) => {
                const isSelected = !!valueState.find(
                  (v) => getKey(v) === getKey(option),
                );
                if (renderOption && !renderOptionWithWrapper) {
                  return renderOption({
                    option,
                    onClick: () => handleClickOption(option),
                    isSelected,
                  });
                }
                return (
                  <DefaultOptionComponent
                    displaySelected={shouldDisplayCheckmarks}
                    key={getKey(option)}
                    onClick={() => handleClickOption(option)}
                    isSelected={isSelected}
                    multiselect={multiselect || false}
                    label={
                      renderOption
                        ? renderOption({
                            option,
                            onClick: () => handleClickOption(option),
                            isSelected,
                          })
                        : getLabel(option)
                    }
                  />
                );
              })
            ) : (
              <Text color="blackAlpha.600" pt={1} pb={1} pl={4}>
                {isLoading ? " " : "No Options..."}
              </Text>
            )}
          </Flex>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
}

export default Select;
