import { Button, MenuItem } from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";

type Props = {
  isInOverflow?: boolean;
  children: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  to?: string;
  leftIcon?: JSX.Element;
};

const TopBarButton = ({
  isInOverflow,
  children,
  onClick,
  leftIcon,
  to,
}: Props) => {
  const isLink = to !== undefined;
  if (isInOverflow) {
    return (
      <MenuItem
        as={isLink ? Link : undefined}
        to={isLink ? to : ""}
        textDecoration="none"
        icon={leftIcon}
        onClick={isLink ? undefined : onClick}
      >
        {children}
      </MenuItem>
    );
  }

  return (
    <Button
      as={isLink ? Link : undefined}
      to={isLink ? to : ""}
      size="sm"
      leftIcon={leftIcon}
      colorScheme="gray"
      variant="ghost"
      textDecoration="none"
      onClick={onClick}
    >
      {children}
    </Button>
  );
};

export default TopBarButton;
