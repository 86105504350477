import React from "react";
import { Button, Col, Container, Row, Table } from "react-bootstrap";
import * as Feather from "react-feather";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Alert, AlertIcon, VStack, Link as ChakraLink } from "@chakra-ui/react";
import { useAppState } from "../../components/App/AppProvider";
import { AdvancedSearch } from "../../components/UI/AdvancedSearch/AdvancedSearch";
import { Card } from "../../components/UI/Card/Card";
import { Header } from "../../components/UI/Header/Header";
import LegacyScreenContainer from "../../components/UI/LegacyScreenContainer";
import { Loading } from "../../components/UI/Loading/Loading";
import { NewObjectTypeButton } from "../../components/UI/NewObjectTypeButton";
import { Paginator } from "../../components/UI/Paginator/Paginator";
import * as ObjectTypeGroup from "../../constants/objectTypeGroup";
import * as Request from "../../utilities/request";
import { getUrlSearchParam } from "../../utilities/url";
import useToast from "../../utilities/useToast";

type RegisterDashboardStatus = "Loading" | "Ready" | "Fetching" | "Error";

interface RegisterDashboardState {
  registers?: any[];
  fetchingRegisters?: boolean;
  error?: any;
}

interface CategoryFilter {
  name: string;
  pluralName: string;
  id: string;
  icon: string;
  selected: boolean;
}

interface RegisterDashboardFiltersState {
  importantRegisterPage: number;
  pageSize: number;
  categories: CategoryFilter[];
  selectedCategory: string;
  selectedCategoryID: any;
  selectedCategoryPlural: string;
}

const categoryFilter = (
  filters: RegisterDashboardFiltersState,
  register: any,
) => {
  let isIncluded = false;
  filters.categories.forEach((category: CategoryFilter) => {
    if (category.selected && register.ObjectTypeID === category.id) {
      isIncluded = true;
    }
  });
  return isIncluded;
};
// this should be called just once in the begining when the page is being loaded
const getRelationshipValues = (data: any) => {
  const options: any = [];
  data.forEach((record: any) => {
    if (record.RelatedRecords.length > 0) {
      record.RelatedRecords.forEach((related: any) => {
        related.forEach((record: any) => {
          record.RegisterView.forEach((values: any) => {
            options.push({
              ListValueID: values.colValue,
              ListValue: values.colValue,
            });
          });
        });
      });
    }
  });
  return options;
};

const getRelatedObjectTypes = (allObjects: any, id: any) => {
  if (allObjects.length !== undefined && allObjects.length > 0) {
    const related = allObjects.filter((obj: any) => obj.ObjectTypeID === id);
    if (related.length > 0) {
      return related[0].ObjectTypeTree;
    }
    return [];
  }
  return [];
};

const pageFilter = (page: number, pageSize: number, index: number) =>
  index < page * pageSize && index >= (page - 1) * pageSize;

const ScreensRegisterDashboard = () => {
  const { app, auth } = useAppState();
  const location = useLocation();
  const navigate = useNavigate();

  const isInternal: boolean = Boolean(app.userInfo.isInternal);

  const [pageStatus, setPageStatus] =
    React.useState<RegisterDashboardStatus>("Loading");
  const [data, setData] = React.useState<RegisterDashboardState>({});
  const [searchViewData, setSearchViewData] = React.useState<any>([]);
  const [filters, setFilters] = React.useState<RegisterDashboardFiltersState>({
    importantRegisterPage: 1,
    pageSize: 10,
    categories: [],
    selectedCategory: "",
    selectedCategoryPlural: "",
    selectedCategoryID: "",
  });
  const [objectTypes, setObjectTypes] = React.useState<any>([]);
  const [allObjectTypes, setAllObjectTypes] = React.useState<any>([]);
  const [isCollapsed, setCollapsed] = React.useState<boolean>(true);
  const { displayToast } = useToast();
  const isMainPage = !location.search;
  const templateType = window.location.href.indexOf("template") > -1;

  const getUrlFilter = (data: any, categories?: any) => {
    if (!categories) {
      categories = filters.categories;
    }
    const filter = getUrlSearchParam(window.location.search, "filter");
    let selected = "";
    let selectedPlural = "";
    const newCategories = categories.map((category: CategoryFilter) => {
      if (category.id === filter) {
        selected = category.name;
        selectedPlural = category.pluralName;
        return {
          name: category.name,
          pluralName: category.pluralName,
          id: category.id,
          icon: category.icon,
          selected: true,
        };
      }
      return {
        name: category.name,
        pluralName: category.pluralName,
        id: category.id,
        icon: category.icon,
        selected: !!(!filter || filter === ""),
      };
    });
    setFilters((prevData) => ({
      ...prevData,
      importantRegisterPage: 1,
      categories: newCategories,
      selectedCategory: selected,
      selectedCategoryPlural: selectedPlural,
      selectedCategoryID: filter,
    }));
    if (data.length > 0) {
      setSearchViewData(
        data.filter((register: any) =>
          categoryFilter(
            {
              ...filters,
              importantRegisterPage: 1,
              categories: newCategories,
              selectedCategory: selected,
              selectedCategoryPlural: selectedPlural,
              selectedCategoryID: filter,
            },
            register,
          ),
        ),
      );
    }
  };

  React.useEffect(() => {
    const getData = async () => {
      try {
        setData({ fetchingRegisters: true });
        const registerObjectTypes = await Request.get(
          `app/objecttypegroup/${ObjectTypeGroup.Register}/objecttype`,
          auth,
        );

        const categories: CategoryFilter[] =
          registerObjectTypes.data.ObjectTypes.map((objectType: any) => ({
            name: objectType.ObjectTypeName,
            pluralName: objectType.ObjectTypeNamePlural,
            id: objectType.ObjectTypeID,
            icon: objectType.ObjectTypeIcon,
            selected: true,
          }));

        setAllObjectTypes(registerObjectTypes.data.ObjectTypes);
        setObjectTypes(categories);

        await Request.getPaginated(
          "app/register/dashboard",
          300,
          (data, status) => updateRegisterResults(data, status, categories),
          auth,
        );
      } catch (err) {
        setData({
          error: err,
        });
      }
    };
    getData();
  }, [auth]); // eslint-disable-line

  React.useEffect(() => {
    if (filters.categories.length > 0) {
      getUrlFilter(data);
    }
  }, [location.search, location.pathname, data]); // eslint-disable-line

  let interimRegisterResults: any[] = [];
  const updateRegisterResults = (
    responseData: any,
    status: Request.RequestStatus,
    categories: CategoryFilter[],
  ) => {
    if (status !== "Error") {
      interimRegisterResults = interimRegisterResults.concat(
        responseData.Requirements,
      );
      setData((prevData) => ({
        ...prevData,
        registers: interimRegisterResults,
        fetchingRegisters: status === "Fetching",
      }));
      // when we first get data, update the filters
      getUrlFilter(interimRegisterResults, categories);
    } else {
      displayToast({
        status: "error",
        title: `Failed to retrieve Issues`,
      });
      setData((prevData) => ({ ...prevData, fetchingRequirements: false }));
    }
    setPageStatus(status);
  };

  // get advanced filters
  const getAdvancedFilter = () => {
    if (data.registers && data.registers.length > 0) {
      const records = data.registers.filter(
        (objType: any) => objType.ObjectTypeID === filters.selectedCategoryID,
      );
      if (records.length > 0) {
        return records[0].RegisterView.RegisterView.concat({
          CustomFieldTypeColumnName: "ListValueID",
          CustomFieldTypeID: "a7411ec3-7397-4298-a55d-12ac471e7120",
          colName: "Relationship",
          colValue: null,
          ListValues: getRelationshipValues(data.registers),
        });
      }
    } else {
      return {};
    }
  };
  // Important Register Card Filters and Pagination

  let filteredRegisters: any[] = data.registers ? data.registers : [];
  let paginatedfilteredRegisters: any[] = [];
  if (filteredRegisters.length > 0) {
    if (isMainPage) {
      // in the main page we will show nothing for now
      filteredRegisters = [];
    } else {
      // Filter based off Category
      filteredRegisters = filteredRegisters.filter((register: any) =>
        categoryFilter(filters, register),
      );
    }

    // Filter based off Permissions
    filteredRegisters = filteredRegisters.filter(
      (register: any) => register.Permission,
    );

    // Order Results
    filteredRegisters = filteredRegisters.sort((a: any, b: any) =>
      a.RequirementName.localeCompare(b.RequirementName),
    );

    // Pagination
    paginatedfilteredRegisters = filteredRegisters.filter(
      (register: any, index: number) =>
        pageFilter(filters.importantRegisterPage, filters.pageSize, index),
    );
  }

  return (
    <LegacyScreenContainer
      pageTitle={
        isMainPage
          ? "Registers Dashboard"
          : `${filters.selectedCategory} Register`
      }
      breadcrumbs={[]}>
      <>
        {pageStatus === "Ready" || pageStatus === "Fetching" ? (
          <>
            {isMainPage ? (
              <>
                <Card
                  title="Registers"
                  collapsable={false}
                  headerCols={[
                    { colProps: {}, children: <span /> },
                    {
                      colProps: { sm: "auto" },
                      children: isInternal ? (
                        <Button
                          variant="primary"
                          onClick={() => navigate("/register/upload")}>
                          Upload Tool
                        </Button>
                      ) : (
                        <></>
                      ),
                    },
                    {
                      colProps: { sm: "auto" },
                      children: (
                        <NewObjectTypeButton
                          variant="primary"
                          title="Create New Register Item"
                          header="New Register Item"
                          objectTypeGroupID={ObjectTypeGroup.Register}
                          parentType={ObjectTypeGroup.Register}
                          template={templateType}
                        />
                      ),
                    },
                  ]}>
                  {pageStatus === "Ready" && filters.categories.length === 0 ? (
                    <Row>
                      <Col>
                        There are no Registers yet. Create one in{" "}
                        <Link to="/settings/pages">Pages</Link>.
                      </Col>
                    </Row>
                  ) : (
                    <Table responsive borderless>
                      <tbody>
                        {filters.categories.map((obj: any, index: number) => {
                          const IconType: keyof typeof Feather = obj.icon;
                          const Icon = Feather[IconType];
                          return (
                            <tr key={index}>
                              <td>
                                <Link to={`?filter=${obj.id}`}>
                                  <Icon /> {obj.pluralName}
                                </Link>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  )}
                  <Row className="justify-content-sm-center">
                    <Col sm="auto">
                      <Paginator
                        filters={filters}
                        setFilterDispatch={setFilters}
                        allPaginatedRecords={filteredRegisters}
                        pageKey="importantRegistersPage"
                      />
                    </Col>
                  </Row>
                </Card>
              </>
            ) : (
              <>
                <chakra-scope>
                  <Alert status="warning" mb="1">
                    <AlertIcon />
                    <VStack align="start" spacing="0">
                      <span>
                        You are currently on the legacy register dashboard. This
                        dashboard will be phased out in the future.
                      </span>
                      <span>
                        To transition to the new dashboard,{" "}
                        <ChakraLink
                          color="blue.700"
                          to={`/register/dashboard/${filters.selectedCategoryID}`}
                          as={Link}>
                          click here
                        </ChakraLink>{" "}
                      </span>
                    </VStack>
                  </Alert>
                </chakra-scope>
                <Card
                  title={`${filters.selectedCategoryPlural}`}
                  collapsable={false}
                  headerCols={[
                    { colProps: {}, children: <span /> },
                    {
                      colProps: { sm: "auto" },
                      children: (
                        <Button
                          onClick={() => {
                            navigate(
                              `${
                                templateType ? "/template" : ""
                              }/register/new?type=${
                                filters.selectedCategoryID
                              }`,
                            );
                          }}>{`Create New ${filters.selectedCategory}`}</Button>
                      ),
                    },
                  ]}>
                  <Row>
                    <Col>
                      <AdvancedSearch
                        handleChange={(e: any) => {
                          setSearchViewData(e);
                        }}
                        data={filteredRegisters}
                        availableFilters={getAdvancedFilter()}
                        isCollapsed={isCollapsed}
                        updateDetailsCollapsed={setCollapsed}
                        type={filters.selectedCategoryPlural}
                        searchText={`Search ${filters.selectedCategory} Register`}
                        relatedObjectTypes={getRelatedObjectTypes(
                          allObjectTypes,
                          filters.selectedCategoryID,
                        )}
                        typeID={filters.selectedCategoryID}
                        urlSearch={location.search}
                      />
                    </Col>
                  </Row>
                  {data.fetchingRegisters ? (
                    <Row className="justify-content-sm-center">
                      <Col sm="auto">
                        <Loading size={"md"} />
                      </Col>
                    </Row>
                  ) : (
                    <div />
                  )}
                  <Row className="justify-content-sm-center">
                    <Col sm="auto">
                      <Paginator
                        filters={filters}
                        setFilterDispatch={setFilters}
                        allPaginatedRecords={filteredRegisters}
                        pageKey="importantRegistersPage"
                      />
                    </Col>
                  </Row>
                </Card>
              </>
            )}
          </>
        ) : (
          <>
            <div className="progress-spinner" style={{ marginTop: "20%" }}>
              <Loading size={"xl"} />
            </div>
          </>
        )}
      </>
    </LegacyScreenContainer>
  );
};

export { ScreensRegisterDashboard };
