import {
  Button,
  FormControl,
  FormLabel,
  HStack,
  chakra,
  Icon,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Switch,
  VStack,
} from "@chakra-ui/react";

import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { z } from "zod";
import * as ActionRecurrenceID from "../../../../shared/v2/constants/ActionRecurrenceID";
import { DropdownIcon } from "../../../../constants/commonIcons";
import {
  getFrequencyLabel,
  getRecurrenceText,
  getSendOnOptions,
} from "../../../../utilities/notificationRecurrenceHelpers";
import { useState } from "react";

export type NotificationState = {
  type: "upcoming" | "due";
} & (
  | {
      isEnabled: true;
      frequency: ActionRecurrenceID.NotificationRecurrenceIDs;
      sendOn: number;
    }
  | {
      isEnabled: false;
    }
);

const schema = z.object({
  isEnabled: z.boolean(),
  frequency: z.enum(ActionRecurrenceID.NotificationRecurrenceIDs),
  sendOn: z.number(),
});

type Props = {
  isSubmitting: boolean;
  onClose: () => void;
  currentState: NotificationState;
  taskLifecycleType: "due" | "upcoming";
  onSubmit: (data: NotificationState) => Promise<boolean>;
};

const createDefaultValues = (currentState: NotificationState) => ({
  isEnabled: currentState.isEnabled,
  frequency: currentState.isEnabled
    ? currentState.frequency
    : ActionRecurrenceID.Weekly,
  sendOn: currentState.isEnabled ? currentState.sendOn : 1,
});

export default function NotificationSettingsModal({
  currentState,
  taskLifecycleType,
  onClose,
  onSubmit,
  isSubmitting,
}: Props) {
  const form = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: zodResolver(schema),
    defaultValues: createDefaultValues(currentState),
  });

  const isEnabled = form.watch("isEnabled");
  const frequency = form.watch("frequency");

  const handleSubmit = async () => {
    const data = form.getValues();
    const res = await onSubmit({ ...data, type: taskLifecycleType });
    if (res) {
      onClose();
    }
  };

  return (
    <chakra-scope>
      <ModalContent>
        <ModalHeader textTransform="capitalize">
          {taskLifecycleType} Task Notifications
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormProvider {...form}>
            <chakra.form onSubmit={form.handleSubmit(handleSubmit)}>
              <VStack w="full" align="start">
                <FormControl display="flex" alignItems="center">
                  <FormLabel htmlFor="enable-notifications">
                    Send Task Notifications?
                  </FormLabel>
                  <Switch
                    {...form.register("isEnabled")}
                    id="enable-notifications"
                  />
                </FormControl>

                {isEnabled ? (
                  <FormControl display="flex" alignItems="center">
                    <FormLabel textAlign="center" margin="0">
                      Send Tasks by Email
                    </FormLabel>
                    <Controller
                      control={form.control}
                      name="frequency"
                      render={({ field }) => (
                        <Menu>
                          <MenuButton
                            as={Button}
                            size="sm"
                            variant="ghost"
                            rightIcon={<Icon as={DropdownIcon} />}>
                            {getFrequencyLabel(field.value)}
                          </MenuButton>
                          <MenuList>
                            <MenuOptionGroup
                              type="radio"
                              value={field.value}
                              onChange={(val) => {
                                field.onChange(val);
                                form.setValue("sendOn", 1); // reset sendon
                              }}>
                              <MenuItemOption value={ActionRecurrenceID.Daily}>
                                Daily
                              </MenuItemOption>
                              <MenuItemOption value={ActionRecurrenceID.Weekly}>
                                Weekly
                              </MenuItemOption>
                              <MenuItemOption
                                value={ActionRecurrenceID.Monthly}>
                                Monthly
                              </MenuItemOption>
                              <MenuItemOption
                                value={ActionRecurrenceID.Quarterly}>
                                Quarterly
                              </MenuItemOption>
                            </MenuOptionGroup>
                          </MenuList>
                        </Menu>
                      )}
                    />
                  </FormControl>
                ) : null}
                {isEnabled
                  ? (() => {
                      const sendOptions = getSendOnOptions(frequency);
                      if (sendOptions.length <= 0) {
                        return null;
                      }
                      return (
                        <Controller
                          name="sendOn"
                          control={form.control}
                          render={({ field }) => (
                            <FormControl display="flex" alignItems="center">
                              <FormLabel textAlign="center" margin="0">
                                {getRecurrenceText(frequency, field.value)[1]}
                              </FormLabel>
                              <Menu>
                                <MenuButton
                                  as={Button}
                                  size="sm"
                                  variant="ghost"
                                  rightIcon={<Icon as={DropdownIcon} />}>
                                  {getRecurrenceText(frequency, field.value)[2]}
                                </MenuButton>
                                <MenuList maxHeight="40vh" overflow="auto">
                                  <MenuOptionGroup
                                    value={field.value.toString()}
                                    onChange={(val) => field.onChange(+val)}>
                                    {sendOptions.map((option) => (
                                      <MenuItemOption
                                        key={option.key.toString()}
                                        value={option.key.toString()}>
                                        {option.label}
                                      </MenuItemOption>
                                    ))}
                                  </MenuOptionGroup>
                                </MenuList>
                              </Menu>
                            </FormControl>
                          )}
                        />
                      );
                    })()
                  : null}
              </VStack>
            </chakra.form>
          </FormProvider>
        </ModalBody>
        <ModalFooter>
          <HStack>
            <Button
              isDisabled={isSubmitting}
              onClick={onClose}
              variant="solid"
              colorScheme="gray">
              Cancel
            </Button>
            <Button
              onClick={handleSubmit}
              isLoading={isSubmitting}
              loadingText="Saving"
              type="submit">
              Save
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </chakra-scope>
  );
}
