export const Closed = "1e753925-3a0d-4255-8565-494c312a9956";
export const Negative = "2cc17bd9-3ff4-4392-aeac-0c8971765747";
export const Positive = "a30dabb5-0818-41ca-bb17-db56c8c81bb5";
export const Neutral = "eb01e49c-5d14-408b-a0dd-a1ec425606bb";

export const ObjectStatusIDToName = {
  "1e753925-3a0d-4255-8565-494c312a9956": "Closed",
  "2cc17bd9-3ff4-4392-aeac-0c8971765747": "Negative",
  "a30dabb5-0818-41ca-bb17-db56c8c81bb5": "Positive",
  "eb01e49c-5d14-408b-a0dd-a1ec425606bb": "Neutral",
} as const;

export type ObjectStatus =
  | typeof Positive
  | typeof Negative
  | typeof Neutral
  | typeof Closed;
