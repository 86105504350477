import { Tooltip, VStack, Text, Avatar, AvatarGroup } from "@chakra-ui/react";

type Props = {
  responsibleUsers: {
    ID: string;
    firstName: string;
    lastName: string;
  }[];
  size?: "xs" | "sm" | "md" | "lg";
};

export default function TableResponsibleUserField({ responsibleUsers, size }: Props) {
  const avatars = responsibleUsers.map((u) => ({
    name: `${u.firstName} ${u.lastName}`,
    ID: u.ID,
  }));
  return (
    <Tooltip
      label={
        <VStack w="full" align="start" spacing="0">
          {avatars.map(({ name, ID }) => (
            <Text margin="0" color="whiteAlpha.800" key={ID}>
              {name}
            </Text>
          ))}
        </VStack>
      }>
      <AvatarGroup size={size ?? "sm"} max={4}>
        {avatars.map(({ name, ID }) => (
          <Avatar size={size ?? "sm"} name={name || ""} key={ID} />
        ))}
      </AvatarGroup>
    </Tooltip>
  );
}
