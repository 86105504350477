import {
  Card,
  CardBody,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  UseDisclosureReturn,
  VStack,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Filters, UserInfo } from "./BaseWidgetProps";
import ActionsTab from "./tabs/ActionsTab";
import RecordsTab from "./tabs/RecordsTab";
import TasksTab from "./tabs/TasksTab";

type SystemSearchFilterState = {
  searchQuery: string;
  searchFilters: Filters | null;
};

type Props = {
  users?: UserInfo[];
  filters: SystemSearchFilterState;
  onChangeSearch: (value: string) => void;
  setFilters: (filters: Filters) => void;
  disclosureState: UseDisclosureReturn;
};

const getQuery = (params: string) => {
  const arr = params.split("?")?.[1]?.split("&");
  if (!!arr && arr.length > 0) {
    return arr.reduce((acc: { [key: string]: string }, curr) => {
      const [key, value] = curr.split("=");
      if (key && value) {
        acc[key] = value;
      }
      return acc;
    }, {});
  }
  return {};
};

const TypeToTabIndex = { records: 0, actions: 1, tasks: 2 } as const;

export default function SystemSearchWidget({
  filters,
  onChangeSearch,
  disclosureState,
  setFilters,
  users,
}: Props) {
  const [tabIndex, setTabIndex] = useState(0);

  const location = useLocation();

  useEffect(() => {
    const query = getQuery(location.search);

    if (query.tab) {
      const tab = TypeToTabIndex[query.tab as "records" | "actions" | "tasks"];
      if (tab !== undefined && typeof tab === "number") setTabIndex(tab);
    }
  }, [location.search, setTabIndex]);

  useEffect(() => {
    if (filters.searchFilters?.type === "records") {
      setTabIndex(TypeToTabIndex.records);
    }
    if (filters.searchFilters?.type === "actions") {
      setTabIndex(TypeToTabIndex.actions);
    }
    if (filters.searchFilters?.type === "tasks") {
      setTabIndex(TypeToTabIndex.tasks);
    }
  }, [filters, setTabIndex]);

  const clearSearch = (type: "records" | "actions" | "tasks") => {
    onChangeSearch("");
    setFilters({ type });
  };

  return (
    <Card h="full" variant="outline">
      <CardBody>
        <Tabs index={tabIndex} onChange={setTabIndex} isLazy>
          <TabList>
            <Tab onClick={() => clearSearch("records")}>Records</Tab>
            <Tab onClick={() => clearSearch("actions")}>Actions</Tab>
            <Tab onClick={() => clearSearch("tasks")}>Tasks</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <VStack align="start" w="full">
                <RecordsTab
                  setFilters={setFilters}
                  disclosureState={disclosureState}
                  filters={filters.searchFilters || ({ type: "record" } as any)}
                  onChangeSearch={onChangeSearch}
                  users={users!}
                  searchQuery={filters.searchQuery}
                />
              </VStack>
            </TabPanel>
            <TabPanel>
              <VStack align="start" w="full">
                <ActionsTab
                  setFilters={setFilters}
                  disclosureState={disclosureState}
                  filters={
                    filters.searchFilters || ({ type: "actions" } as any)
                  }
                  onChangeSearch={onChangeSearch}
                  users={users!}
                  searchQuery={filters.searchQuery}
                />
              </VStack>
            </TabPanel>
            <TabPanel>
              <VStack align="start" w="full">
                <TasksTab
                  setFilters={setFilters}
                  disclosureState={disclosureState}
                  filters={filters.searchFilters || ({ type: "tasks" } as any)}
                  onChangeSearch={onChangeSearch}
                  users={users!}
                  searchQuery={filters.searchQuery}
                />
              </VStack>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </CardBody>
    </Card>
  );
}
