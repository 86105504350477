import React from "react";
import {
  Container,
  Box,
  BoxProps,
  Heading,
  HStack,
  VStack,
  ContainerProps,
  useColorModeValue,
} from "@chakra-ui/react";
import Breadcrumbs, { BreadcrumbElement } from "./Breadcrumbs";

type Props = {
  children: React.ReactNode;
  breadcrumbs?: BreadcrumbElement[];
  pageTitle?: string;
  pageSubtitle?: string;
  contentSize?: ContainerProps["maxW"];
  headerSize?: ContainerProps["maxW"];
  headerBottomContent?: React.ReactNode;
  headerEndContent?: React.ReactNode;
};

/**
 * Used for most screens, displays breadcrumbs, a title, plus a container with padding.
 * Container defaults to 2xl size but can be overriden.
 * Use container.2xl (Or no max width at all) for 'list' type screens and container.lg for detailed information screens, especially ones that can be edited
 * headerSize defaults to whatever contentSize is, but can also be overriden
 */
const ChakraScreenContainer = ({
  children,
  breadcrumbs = [],
  pageTitle,
  pageSubtitle,
  contentSize = "container.2xl",
  headerSize,
  headerBottomContent,
  headerEndContent,
}: Props): JSX.Element => {
  const headingColor = useColorModeValue("gray.700", "whiteAlpha.900");
  const subHeadingColor = useColorModeValue("gray.600", "whiteAlpha.800");
  const hasAnyHeaderContent = !!(pageTitle || headerEndContent);
  const hasAnyBottomHeaderContent = !!(pageSubtitle || headerBottomContent);
  return (
    <>
      <Container
        maxW={headerSize ?? contentSize}
        px={4}
        py={hasAnyHeaderContent ? 2 : 1}>
        <VStack w="full" align="start" spacing="0">
          {hasAnyHeaderContent && (
            <HStack justify="space-between" w="full">
              <HStack align="center" spacing={0}>
                {breadcrumbs.length >= 1 && (
                  <Breadcrumbs breadcrumbs={breadcrumbs} />
                )}
                <Heading as="h1" size="md" color={headingColor}>
                  {pageTitle && pageTitle}
                </Heading>
              </HStack>
              {headerEndContent}
            </HStack>
          )}

          {hasAnyBottomHeaderContent && (
            <HStack spacing="3">
              {pageSubtitle && (
                <Heading ps="0.5" color={subHeadingColor} size="xs">
                  {pageSubtitle}
                </Heading>
              )}
              {headerBottomContent}
            </HStack>
          )}
        </VStack>
      </Container>
      <Container px={4} maxW={contentSize}>
        {children}
      </Container>
    </>
  );
};

export default ChakraScreenContainer;
