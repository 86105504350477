import moment from "moment";
import React, { useEffect, useState } from "react";
import { Col, Dropdown, DropdownButton, Row, Table } from "react-bootstrap";
import { Trash2 } from "react-feather";
import * as DocumentType from "../../../shared/constants/documentTypes";
import { del, get, put } from "../../../utilities/request";
import useToast from "../../../utilities/useToast";
import { Card } from "../Card/Card";
import { Loading } from "../Loading/Loading";

type DocumentGeneratorPageStatus = "Loading" | "Ready" | "Submitting";

const TemplateDocumentGenerator = (props: any) => {
  const [data, setData] = useState<any>(null);
  const [pageStatus, setPageStatus] =
    useState<DocumentGeneratorPageStatus>("Loading");
  const { displayToast } = useToast();

  const fetchPageData = async () => {
    let ID = "";
    if (
      props.data.ObjectTypeGroupName === "Job" ||
      props.data.ObjectTypeGroupName === "Requirement" ||
      props.data.ObjectTypeGroupName === "Issue" ||
      props.data.ObjectTypeGroupName === "Risk" ||
      props.data.ObjectTypeGroupName === "Register"
    ) {
      ID = props.data.RequirementID;
    } else if (props.data.ObjectTypeGroupName === "Action") {
      ID = props.data.ActionID;
    } else if (props.data.ObjectTypeGroupName === "Instance") {
      ID = props.data.InstanceID;
    }

    const templateDocuments = await get(
      `templatedocument/${props.data.ObjectTypeID}`,
      props.authState,
    );
    const existingDocuments = await get(
      `mergetemplatedocument/${ID}`,
      props.authState,
    );

    console.log("existingDocuments", existingDocuments.data);
    console.log("templateDocuments", templateDocuments.data);

    setData({
      ...props,
      templateDocuments: templateDocuments.data,
      createdDocuments: existingDocuments.data,
    });
    setPageStatus("Ready");
  };

  useEffect(() => {
    if (props.data.ObjectTypeGroupName) {
      fetchPageData();
    }
  }, [props.recordID]);

  const generateDocument = async (document: any, createdDocuments: any) => {
    setPageStatus("Submitting");

    const { data } = props;

    const getDocumentName = (readableID: string) => {
      // .replace(/\.[^/.]+$/, "") removes the extension
      let documentName = `${readableID} ${document.name.replace(
        /\.[^/.]+$/,
        "",
      )}`;

      let documentCounter = 0;
      createdDocuments.forEach((doc: any) => {
        if (doc.DocumentName.split("(")[0].trim() === documentName) {
          documentCounter++;
        }
      });

      if (documentCounter !== 0) {
        documentName = `${documentName} (${documentCounter})`;
      }

      return documentName;
    };

    let tokens: any = {};
    if (
      data.ObjectTypeGroupName === "Job" ||
      data.ObjectTypeGroupName === "Requirement" ||
      data.ObjectTypeGroupName === "Issue" ||
      props.data.ObjectTypeGroupName === "Risk" ||
      props.data.ObjectTypeGroupName === "Register"
    ) {
      tokens = {
        DocumentRefID: data.RequirementID,
        name: data.RequirementName,
        ObjectTypeGroupID: data.ObjectTypeGroupID,
        ObjectTypeGroupName: data.ObjectTypeGroupName,
        printDate: moment().format("DD-MMM-YYYY hh:mm a"),
        DocumentName: getDocumentName(data.RequirementReadableID),
        DocumentID: document.uid,
        DocumentExtension: document.type,
      };
    } else if (data.ObjectTypeGroupName === "Action") {
      console.log("data", data);
      tokens = {
        DocumentRefID: data.ActionID,
        name: data.ActionName,
        ObjectTypeGroupID: data.ObjectTypeGroupID,
        ObjectTypeGroupName: data.ObjectTypeGroupName,
        printDate: moment().format("DD-MMM-YYYY hh:mm a"),
        DocumentName: getDocumentName(data.ActionReadableID),
        DocumentID: document.uid,
        DocumentExtension: document.type,
      };
    } else if (data.ObjectTypeGroupName === "Instance") {
      console.log("data", data);
      tokens = {
        DocumentRefID: data.InstanceID,
        name: data.ActionName,
        ObjectTypeGroupID: data.ObjectTypeGroupID,
        ObjectTypeGroupName: data.ObjectTypeGroupName,
        printDate: moment().format("DD-MMM-YYYY hh:mm a"),
        DocumentName: getDocumentName(data.InstanceReadableID),
        DocumentID: document.uid,
        DocumentExtension: document.type,
      };
    }

    tokens.timezone = moment.tz.guess(true);

    console.log("tokens", tokens);
    // return true

    const result = await put("mergetemplatedocument", tokens, props.authState);

    console.log("Returned", result);
    if (result.data.error) {
      console.log("here!", result.data.error);
      displayToast({
        status: "error",
        title: `${result.data.error}`,
      });
    } else {
      openDocument(result.data.DocumentURL);
    }

    fetchPageData();
    setPageStatus("Ready");
    return result;
  };

  console.log("TemplateDocumentGenerator data", data);

  if (pageStatus !== "Loading") {
    if (data.templateDocuments.length > 0) {
      return (
        <Card
          title="Template Documents"
          collapsable
          headerColsRight
          headerCols={[
            {
              colProps: { sm: "auto" },
              children:
                pageStatus === "Submitting" ? (
                  <Loading noBorder />
                ) : (
                  <DropdownButton
                    id="documentDropdown"
                    title="Create New Document from Template">
                    {data.templateDocuments.map((document: any, i: number) => (
                      <Dropdown.Item
                        key={i}
                        onClick={() => {
                          generateDocument(document, data.createdDocuments);
                        }}>
                        {document.name}
                      </Dropdown.Item>
                    ))}
                  </DropdownButton>
                ),
            },
          ]}>
          <Row>
            <Col>
              <CreatedDocumentsTable
                createdDocuments={data.createdDocuments}
                authState={props.authState}
                fetchPageData={fetchPageData}
              />
            </Col>
          </Row>
        </Card>
      );
    }
  }
  return null;
};

const CreatedDocumentsTable = (props: any) => {
  const getDocument = async (documentID: string, authState: any) => {
    const result = await get(
      `document/${documentID}/${DocumentType.TemplateGenerated}`,
      authState,
    );
    console.log(result.data);
    openDocument(result.data.url);
  };

  const deleteDocument = async (documentID: string, authState: any) => {
    const result = await del(`document/${documentID}`, authState);
    console.log(result);
    props.fetchPageData();
  };

  return (
    <Table responsive borderless>
      <thead>
        <tr>
          <th>Name</th>
          <th>Created Timestamp</th>
          <th>Created By</th>
          <th>Delete</th>
        </tr>
      </thead>
      <tbody>
        {props.createdDocuments.map((e: any, i: number) => (
          <tr key={i}>
            <td style={{ whiteSpace: "nowrap" }}>
              <button
                className="stealthLink"
                onClick={() => {
                  getDocument(e.DocumentID, props.authState);
                }}>
                {e.DocumentName}
              </button>
            </td>
            <td>{moment(e.CreateTs).format("DD-MMM-YYYY hh:mm a")}</td>
            <td>{e.CreateUserName}</td>
            <td>
              <button
                style={{ backgroundColor: "white", border: "none" }}
                onClick={() => deleteDocument(e.DocumentID, props.authState)}>
                <Trash2 style={{ color: "#d8d8d8" }} className="feather" />
              </button>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

const openDocument = (url: any) => {
  window.open(url);
};

export { TemplateDocumentGenerator };
