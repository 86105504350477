
/**
 * Action Recurrence IDs
 * Comments are proposed new IDs
 */
export const OnceOff = "b4b5980e-f0be-46de-91c1-382a62c1cd39"; // "once"
export const Daily = "9e8e9142-4582-4cce-8734-dfd92906599b"; // "day"
export const Weekly = "a3d0c772-5b20-4b33-aaf8-94d4bf34e54a"; // "week"
export const Fortnightly = "82bacb10-b0cd-471e-9758-c28e89228b44"; // "fortnight"
export const Monthly = "ded2eca5-8077-4a2e-b778-d47c67361c04"; // "month"
export const Every2Months = "3148e770-e99b-43d5-9850-a5700db04bea"; // "month-2"
export const Quarterly = "67262ab6-0ca3-4d4d-b9c4-76328ba00cd7"; // "quarter"
export const BiAnnually = "d094da0c-3377-45c3-ba5f-b17e62b59bed"; // "biannual"
export const EveryYear = "c09afaac-b7da-42f9-b39c-a5889d799903"; // "year"
export const Every2Years = "2c7ddd7c-1f14-4d5e-a0a9-54700419bfbf"; // "year-2"
export const Every3Years = "358aa281-d7dd-45ae-abe8-43dbae95faf9"; // "year-3"
export const Every4Years = "1d6db4d5-06a8-4f49-95cc-a81d3dd8b897"; // "year-4"
export const Every5Years = "f44e70ad-d487-443b-b39e-5f4e8151fe85"; // "year-5"

export const ActionRecurrenceID = {
  OnceOff,
  Daily,
  Weekly,
  Fortnightly,
  Monthly,
  Every2Months,
  Quarterly,
  BiAnnually,
  Every2Years,
  EveryYear,
  Every3Years,
  Every4Years,
  Every5Years,
} as const;


/**
 * Recurring Action Recurrence IDs are Recurrences that can be used on Actions (Other than once off)
 */
const RecurringActionRecurrenceIDs = [
  Daily,
  Weekly,
  Fortnightly,
  Monthly,
  Every2Months,
  Quarterly,
  BiAnnually,
  Every2Years,
  EveryYear,
  Every3Years,
  Every4Years,
  Every5Years,
] as const;

export type RecurringActionRecurrenceIDs =
  (typeof RecurringActionRecurrenceIDs)[number];

/**
 * Notification Recurrence IDs are Recurrences that can be used on Notifications
 */

export const NotificationRecurrenceIDs = [
  Daily,
  Weekly,
  Monthly,
  Quarterly,
] as const;

export type NotificationRecurrenceIDs =
  (typeof NotificationRecurrenceIDs)[number];

/**
 * Once off
 */
export type OnceOffActionRecurrenceIDs = typeof OnceOff;
