import {
  Box,
  Center,
  Container,
  Image,
  Spacer,
  VStack,
} from "@chakra-ui/react";
import * as React from "react";
import Logo from "../../images/logo_stacked.png";
import { Card, CardContent, CardHeading } from "../chakra/card";

type Props = {
  children?: React.ReactNode;
  bottomContent?: React.ReactNode;
  title?: string;
};

const AuthScreenContainer = ({ children, title, bottomContent }: Props) => (
  <Box h="100vh" bg="white">
    <Container height="100%" maxW="container.lg">
      <VStack height="100%" w="full">
        <Spacer />
        <VStack height="100%" spacing="4" w="full" justifyContent="center">
          <Image maxW="96" src={Logo} />
          <Card width="full" maxW="540px">
            {title && <CardHeading title={title} />}
            <CardContent>{children}</CardContent>
          </Card>
        </VStack>
        {bottomContent ?? <Spacer />}
      </VStack>
    </Container>
  </Box>
);

export default AuthScreenContainer;
