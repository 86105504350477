import { addDays, isAfter, isBefore, subDays } from "date-fns";
const Lifecycles = [
  "due",
  "openResponded",
  "overdue",
  "missed",
  "upcoming",
  "closed",
] as const;
export type Lifecycle = typeof Lifecycles[number];

export const isValidLifecycle = (life: string | undefined): life is Lifecycle =>
  life !== undefined ? (Lifecycles as readonly string[]).includes(life) : false;

/**
 * Converts a lifecycle to a human-readable label.
 * @param life The lifecycle to convert.
 * @returns The human-readable label.
 */
export const lifecycleToLabel = (life: Lifecycle): string => {
  switch (life) {
    case "due":
      return "Due";
    case "openResponded":
      return "Open Responded";
    case "overdue":
      return "Overdue";
    case "missed":
      return "Closed (Missed)";
    case "upcoming":
      return "Upcoming";
    case "closed":
      return "Closed (Completed)";
  }
  return "-";
};

type Task = {
  whenTs: string;
  windowAfter: number;
  windowBefore: number;
  minViableResponse: boolean;
};

type LifecycleTotals = {
  [K in Lifecycle]: number;
};

/* Determine the lifecycle type of the task
   The SQL for filtering on lifecycle is contained in `lifecycleSQL.ts`
*/
export const getLifecycle = (
  task: Task,
  overrideNow?: string | number
): Lifecycle => {
  const dueDate = new Date(task.whenTs);
  const closeDate = addDays(dueDate, task.windowAfter);
  const openDate = subDays(dueDate, task.windowBefore + 1); // Sub extra day from openDate so that tasks can be answered

  const now = overrideNow ? new Date(overrideNow) : new Date();

  const isAnswered = !!task.minViableResponse;

  // Task is upcoming if its before the open date
  // This will normally also be only within 30 days but that filtering should be done elsewhere
  if (isBefore(now, openDate)) {
    return "upcoming";
  }
  if (isAfter(now, openDate)) {
    // If the task is responded too its either open responded or closed
    if (isAnswered) {
      // If task is before close date, its open-responded
      if (isBefore(now, closeDate)) {
        return "openResponded";
      }
      // Otherwise its closed
      return "closed";
    } else {
      // If task is not responded too, its either due, overdue or missed
      // Task is due if it is between openDate and dueDate
      if (isBefore(now, dueDate)) {
        return "due";
      }
      // Otherwise if the task is after openDate & after dueDate, but before closeDate, its overdue
      if (isBefore(now, closeDate)) {
        return "overdue";
      }
      // Otherwise its after closeDate, so task is mised
      return "missed";
    }
  }
  return "closed";
};

export const getLifecycleCounts = (tasks: Task[]): LifecycleTotals => {
  const totals = tasks.reduce(
    (acc, task) => {
      const lifecycle = getLifecycle(task);
      return {
        ...acc,
        [lifecycle]: acc[lifecycle] + 1,
      };
    },
    {
      due: 0,
      openResponded: 0,
      overdue: 0,
      missed: 0,
      upcoming: 0,
      closed: 0,
    }
  );

  return totals;
};
