import { z } from "zod";

export const config = z
  .object({
    // Endpoints
    VITE_AWS_SERVICE_ENDPOINT: z.string(),
    VITE_AWS_APIBEL_SERVICE_ENDPOINT: z.string(),
    // Cognito
    VITE_COGNITO_USER_POOL_REGION: z.string(),
    VITE_COGNITO_USER_POOL_ID: z.string(),
    VITE_COGNITO_USER_POOL_CLIENT_ID: z.string(),
    // Keys
    VITE_FROALA_API_KEY: z.string(),
    // Misc
    VITE_VERSION: z.string(),
  })
  .parse(import.meta.env);

export const awsConfig = {
  Auth: {
    region: config.VITE_COGNITO_USER_POOL_REGION,
    userPoolId: config.VITE_COGNITO_USER_POOL_ID,
    userPoolWebClientId: config.VITE_COGNITO_USER_POOL_CLIENT_ID,
  },
};

export const apiConfig = {
  controllerBaseUrl: config.VITE_AWS_SERVICE_ENDPOINT,
  apibelBaseUrl: config.VITE_AWS_APIBEL_SERVICE_ENDPOINT,
};
