import {
  Editable as ChakraEditable,
  EditableInput,
  Icon,
  Input,
  EditablePreview,
  EditableProps,
  IconButton,
  ButtonGroup,
  useEditableControls,
  Flex,
  Tooltip,
} from "@chakra-ui/react";
import { RiCheckLine, RiCloseLine } from "react-icons/ri";
import { EditItemIcon } from "../../../constants/commonIcons";
import Spinner from "./Spinner";

type ControlsProps = {
  isLoading?: boolean;
};

const EditableControls = ({ isLoading }: ControlsProps) => {
  const {
    isEditing,
    getSubmitButtonProps,
    getCancelButtonProps,
    getEditButtonProps,
  } = useEditableControls();
  if (isEditing) {
    return (
      <ButtonGroup
        size="sm"
        pos="absolute"
        bottom="-4px"
        right="0"
        transform="translateY(100%)"
        zIndex="2"
        justifyContent="end">
        <IconButton
          shadow="md"
          colorScheme="gray"
          {...getCancelButtonProps()}
          aria-label="Cancel"
          icon={<Icon boxSize="4" as={RiCloseLine} />}
        />
        <IconButton
          shadow="md"
          {...getSubmitButtonProps()}
          aria-label="Save"
          icon={<Icon boxSize="4" as={RiCheckLine} />}
        />
      </ButtonGroup>
    );
  }
  return (
    <Flex justifyContent="end">
      <Tooltip label={isLoading ? "" : "Edit"} shouldWrapChildren>
        <IconButton
          visibility={isLoading ? "visible" : "hidden"}
          isLoading={isLoading}
          {...getEditButtonProps()}
          size="sm"
          variant="ghost"
          aria-label="Edit"
          icon={<Icon as={EditItemIcon} />}
        />
      </Tooltip>
    </Flex>
  );
};

type Props = EditableProps & {
  isLoading?: boolean;
  isDisabled?: boolean;
};

export default function Editable({
  isLoading,
  isDisabled,
  ...otherProps
}: Props) {
  return (
    <ChakraEditable
      _hover={{
        backgroundColor: "blackAlpha.100",
        button: {
          visibility: "visible",
        },
      }}
      submitOnBlur={false}
      paddingEnd="1px"
      borderRadius="md"
      isDisabled={isLoading || isDisabled}
      position="relative"
      display="flex"
      flexDir="row"
      justifyContent="space-between"
      alignItems="center"
      {...otherProps}>
      <EditablePreview
        color="gray.700"
        w="full"
        alignItems="center"
        display="flex"
        paddingStart="4"
        paddingEnd="4"
        paddingTop="0"
        paddingBottom="0"
        height="10"
        opacity={otherProps.value ? undefined : 0.6}
        _disabled={{
          color: "gray.500",
          cursor: "not-allowed",
        }}
      />
      <Input as={EditableInput} />
      <EditableControls isLoading={isLoading} />
    </ChakraEditable>
  );
}
