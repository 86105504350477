import {
  useToast as useToastChakra,
  Alert,
  AlertDescription,
  CloseButton,
  AlertTitle,
  AlertIcon,
  Box,
  ToastId,
  CloseAllToastsOptions,
} from "@chakra-ui/react";
import React from "react";

export type ToastDurations = "short" | "long";
const ToastDurationMap = {
  short: 3000,
  long: 8000,
};

export type DisplayToastFn = (args: ToastArguments) => ToastId | undefined;

type ToastArguments = {
  title: string;
  description?: React.ReactNode;
  status: "success" | "error" | "warning" | "info";
  isClosable?: boolean;
  duration?: ToastDurations;
};

/**
 * Wrapper hook around chakras use toast
 * Sets some defaults
 */
const useToast = (): {
  displayToast: DisplayToastFn;
  closeAllToasts: (options?: CloseAllToastsOptions | undefined) => void;
  closeToast: (id: ToastId) => void;
} => {
  const toast = useToastChakra();

  const displayToast = ({
    title,
    description,
    status,
    isClosable = true,
    duration = "long",
  }: ToastArguments) =>
    toast({
      title,
      description,
      status,
      isClosable,
      duration: ToastDurationMap[duration],
      position: "top-right",
      render: ({ onClose }) => (
        <chakra-scope>
          <Alert
            alignItems="start"
            textAlign="start"
            width="auto"
            boxShadow="lg"
            status={status}
            variant="left-accent"
            borderRightRadius="md"
            mr={2}>
            <AlertIcon />
            <Box flex="1" mr="1">
              {title && <AlertTitle>{title}</AlertTitle>}
              {description && (
                <AlertDescription display="block">
                  {description}
                </AlertDescription>
              )}
            </Box>
            {isClosable && (
              <CloseButton
                size="sm"
                onClick={onClose}
                position="absolute"
                insetEnd={1}
                top={1}
              />
            )}
          </Alert>
        </chakra-scope>
      ),
    });

  const closeAllToasts = toast.closeAll;

  const closeToast = toast.close;

  return { displayToast, closeToast, closeAllToasts };
};

export default useToast;
