import { ComponentStyleConfig } from "@chakra-ui/react";

// Add the isFetching prop to Table
declare module "@chakra-ui/react" {
  interface TableProps {
    isFetching?: boolean;
  }
}

const tableStyle: ComponentStyleConfig = {
  parts: ["thead", "tfoot", "tbody"],
  variants: {
    "data-table": ({ colorMode, isFetching }) => ({
      thead: {
        paddingTop: "24px",
        borderBottom: "solid 2px",
        borderBottomColor: "gray.400",
        tr: {
          opacity: isFetching ? 0.5 : undefined,
        },
      },
      tfoot: {
        borderTop: "solid 2px",
        borderTopColor: "gray.400",
        tr: {
          opacity: isFetching ? 0.5 : undefined,
        },
      },
      tbody: {
        tr: {
          pointerEvents: isFetching ? "none" : undefined,
          opacity: isFetching ? 0.2 : undefined,
          "&:not(.table-section-heading)": {
            "&:nth-of-type(odd)": {
              td: {
                bg: "rgba(0,0,0,0.02)",
                // bg: colorMode === "light" ? "blackAlpha.50" : "whiteAlpha.50",
              },
            },
            "&:hover": {
              td: {
                bg: "rgba(0,51,161,0.06)",
                // bg: colorMode === "light" ? "blackAlpha.100" : "whiteAlpha.100",
              },
            },
          },
          "&.table-section-heading": {
            borderTopColor: "brand.200",
            borderTopWidth: "2px",
          },
        },
      },
    }),
  },
};

export default {
  components: {
    Table: tableStyle,
  },
};
