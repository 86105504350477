import { Badge } from "@chakra-ui/react";
import React from "react";
import {
  ObjectStatus,
  ObjectStatusIDToName,
} from "../../shared/v2/constants/ObjectStatusID";

type Props = {
  statusID: ObjectStatus | (string & {});
};

const IDToColor = {
  "1e753925-3a0d-4255-8565-494c312a9956": "gray", // closed
  "2cc17bd9-3ff4-4392-aeac-0c8971765747": "red", // negative
  "a30dabb5-0818-41ca-bb17-db56c8c81bb5": "green", // positive
  "eb01e49c-5d14-408b-a0dd-a1ec425606bb": "blue", // neutral
} as const;

const ObjectStatusBadge = ({ statusID }: Props) => (
  <Badge
    variant="solid"
    colorScheme={IDToColor[statusID as ObjectStatus] || "gray"}>
    {ObjectStatusIDToName[statusID as ObjectStatus] || "Unknown"}
  </Badge>
);

export default ObjectStatusBadge;
