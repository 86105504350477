import {
  HStack,
  MenuDivider,
  MenuGroup,
  MenuItem,
  Icon,
} from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";
import { useApiQuery } from "../../../utilities/apibelRequest";
import Spinner from "../common/Spinner";
import { RegisterIcon } from "../../../constants/commonIcons";
import FeatherIcon from "../../UI/Icon";

const RegisterMenuList = () => {
  const isUsingNewRegisters = false;
  const registerPages = useApiQuery("register/pages", null);

  const createRegisterLink = (
    registerName: string,
    registerID: string,
  ): { to: string; state?: any } => {
    if (isUsingNewRegisters) {
      return {
        to: `/registernew/dashboard/${registerID}`,
        state: { registerName },
      };
    }
    return { to: `/register/dashboard/${registerID}` };
  };

  return (
    <>
      <MenuItem
        as={Link}
        icon={<Icon boxSize="4" as={RegisterIcon} />}
        to={isUsingNewRegisters ? "/registernew" : "register"}>
        Registers Dashboard
      </MenuItem>
      <MenuDivider />
      {registerPages.isSuccess ? (
        <MenuGroup title="Register Pages">
          {registerPages.data.map(({ namePlural, objectTypeID, icon }) => (
            <MenuItem
              as={Link}
              {...createRegisterLink(namePlural, objectTypeID)}
              icon={<FeatherIcon name={icon} />}>
              {namePlural}
            </MenuItem>
          ))}
        </MenuGroup>
      ) : (
        <HStack justify="center" w="full">
          <Spinner size="sm" />
        </HStack>
      )}
    </>
  );
};

export default RegisterMenuList;
