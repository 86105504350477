import { Input, InputGroup, InputRightElement } from "@chakra-ui/react";
import debounce from "lodash/debounce";
import { useCallback, useEffect, useState } from "react";
import Icon from "./UI/Icon";

type DebouncedInputProps = {
  value: string | undefined;
  onChange: (value?: string) => void;
  placeholder?: string;
  debounceTime?: number;
};

export const DebouncedInput = ({
  value,
  onChange,
  debounceTime = 800,
  placeholder = "Search",
}: DebouncedInputProps) => {
  const [inputValue, setInputValue] = useState<string | undefined>(value);

  useEffect(() => {
    setInputValue(value || "");
  }, [value]);

  const debouncedOnChange = useCallback(debounce(onChange, debounceTime), [
    onChange,
  ]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value || undefined);
    debouncedOnChange(event.target.value || undefined);
  };

  const handleClearInput = () => {
    setInputValue("");
    debouncedOnChange("");
  };

  return (
    <InputGroup>
      <Input
        value={inputValue}
        onChange={handleChange}
        placeholder={placeholder}
        size="sm"
      />
      <InputRightElement
        h="100%"
        w="30px"
        cursor="pointer"
        onClick={handleClearInput}>
        <Icon name="XCircle" color="gray" size="sm" />
      </InputRightElement>
    </InputGroup>
  );
};

export default DebouncedInput;
