import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  InputGroup,
  InputProps,
  Textarea,
  InputRightAddon,
} from "@chakra-ui/react";
import { useFormContext, useFormState } from "react-hook-form";

type Props = {
  name: string;
  isReadOnly?: boolean;
  isDisabled?: boolean;
  fieldProps: {
    label: string;
    unitLabel?: string | null;
    helperText?: string | null;
    isRequired?: boolean;
    multiline?: boolean;
    maxLength?: number;
  };
  inputPropsOverride?: InputProps;
};

const ShortTextField = ({
  fieldProps,
  name,
  isReadOnly,
  inputPropsOverride,
  isDisabled,
}: Props) => {
  const { register } = useFormContext();
  const { errors } = useFormState({ name });
  const error = errors[name];
  const { label, unitLabel, isRequired, helperText, maxLength } = fieldProps;
  return (
    <FormControl
      isRequired={isRequired}
      isReadOnly={isReadOnly}
      isDisabled={isDisabled}
      isInvalid={!!error}>
      <FormLabel htmlFor={name}>{label}</FormLabel>
      <InputGroup>
        {fieldProps.multiline ? (
          <Textarea maxLength={maxLength} noOfLines={3} {...register(name)} />
        ) : (
          <Input
            maxLength={maxLength}
            {...register(name)}
            {...inputPropsOverride}
          />
        )}
        {unitLabel && <InputRightAddon>{unitLabel}</InputRightAddon>}
      </InputGroup>
      <FormHelperText>{helperText}</FormHelperText>
      <FormErrorMessage>{error && error.message?.toString()}</FormErrorMessage>
    </FormControl>
  );
};

export default ShortTextField;
