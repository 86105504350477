import "./init";
import { ColorModeScript } from "@chakra-ui/react";
import { createRoot } from "react-dom/client";
import "./index.css";
import "react-dates/initialize";
import "froala-editor/css/froala_style.min.css";
import "react-dates/lib/css/_datepicker.css";
import "froala-editor/css/froala_editor.pkgd.min.css";
import "froala-editor/js/plugins.pkgd.min.js";
import "./styles/chakra-date-picker.css";
import initLogging from "./utilities/Logging";
import * as serviceWorker from "./serviceWorker";
import App from "./App";

initLogging();

const container = document.getElementById("root");

const root = createRoot(container!);
root.render(
  <>
    <ColorModeScript initialColorMode="light" />
    <App />
  </>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
