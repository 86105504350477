import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";

const renderNotificationRecurrence = (NotificationOptions: any) =>
  NotificationOptions.recurrenceOptions.map((option: any, i: number) => (
    <Dropdown.Item
      key={`recurrenceOptions${i}`}
      eventKey={JSON.stringify({
        value: option.ActionRecurrenceID,
        key: option.ActionRecurrenceText,
      })}>
      {option.ActionRecurrenceText}
    </Dropdown.Item>
  ));

const renderNotificationMedium = (NotificationOptions: any) =>
  NotificationOptions.mediumOptions.map((option: any, i: number) => (
    <Dropdown.Item
      key={`mediumOptions${i}`}
      eventKey={JSON.stringify({
        value: option.NotificationMediumID,
        key: option.NotificationMediumName,
      })}>
      {option.NotificationMediumName}
    </Dropdown.Item>
  ));

const renderMonthDropdown = () => {
  const result = [];
  for (let i = 1; i < 32; i++) {
    result.push(
      <Dropdown.Item
        key={`MonthDropdown${i}`}
        eventKey={JSON.stringify({ value: i })}>
        {getOrdinal(i)}
      </Dropdown.Item>,
    );
  }
  return result;
};

// This is used instead of moment to replicate how mySQL will later use DAYOFWEEK() to verify a day
const mySqlDayOfWeekEquivilant = (dayOfWeek: any) => {
  switch (dayOfWeek.toString()) {
    case "1":
      return "Sunday";
    case "2":
      return "Monday";
    case "3":
      return "Tuesday";
    case "4":
      return "Wednesday";
    case "5":
      return "Thursday";
    case "6":
      return "Friday";
    case "7":
      return "Saturday";
    default:
      return "Unrecognised Date";
  }
};

const getOrdinal = (i: number) => {
  const j = i % 10;
  const k = i % 100;
  if (j === 1 && k !== 11) {
    return `${i}st`;
  }
  if (j === 2 && k !== 12) {
    return `${i}nd`;
  }
  if (j === 3 && k !== 13) {
    return `${i}rd`;
  }
  return `${i}th`;
};

export {
  getOrdinal,
  renderNotificationRecurrence,
  renderNotificationMedium,
  renderMonthDropdown,
  mySqlDayOfWeekEquivilant,
};
