import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { apiConfig } from "../constants/config";
import getApiToken from "./getApiToken";

export type RequestStatus = "Fetching" | "Ready" | "Error";

const setConfig = (apiToken: string): AxiosRequestConfig => ({
  headers: {
    Authorization: apiToken,
  },
});

const getUrl = (endpoint: string): string =>
  `${apiConfig.controllerBaseUrl}/${endpoint}`;

// central function for handling pagination throughout the site
// pass in a function to handle receiving data however your page wants to handle it
export const getPaginated = async (
  endpoint: string,
  limit: number,
  updatePage: (data: any, pageStatus: RequestStatus) => void,
): Promise<AxiosResponse<any>> => {
  let offset = 0;
  let finished = false;
  let status: RequestStatus = "Fetching";
  const data: any[] = [];
  let result: any;

  while (!finished) {
    result = await get(
      `${endpoint}${
        endpoint.endsWith("&") ? "" : "?"
      }limit=${limit}&offset=${offset}`,
    );

    if (!result.data || result.status !== 200) {
      status = "Error";
      finished = true;
    } else {
      offset += limit;

      if (result.data.resultsLength < limit) {
        status = "Ready";
        finished = true;
      }
    }
    updatePage(result.data, status);
  }
  return {
    ...result,
    data,
  };
};

export const get = async (endpoint: string): Promise<AxiosResponse<any>> => {
  const apiToken = await getApiToken();
  return new Promise<AxiosResponse<any>>((resolve, reject) => {
    axios
      .get(getUrl(endpoint), apiToken ? setConfig(apiToken) : undefined)
      .catch((error: any) => {
        if (error.response && error.response.status === 401) {
          window.location.reload();
        }
        reject(error);
      })
      .then((value: AxiosResponse<any> | void) => {
        resolve(value || undefined);
      });
  });
};

export const post = async (endpoint: string, body: any) => {
  const apiToken = await getApiToken();
  return axios.post(getUrl(endpoint), body, setConfig(apiToken));
};
export const put = async (endpoint: string, body: any) => {
  const apiToken = await getApiToken();
  return axios.put(getUrl(endpoint), body, setConfig(apiToken));
};
export const del = async (endpoint: string) => {
  const apiToken = await getApiToken();
  return axios.delete(getUrl(endpoint), setConfig(apiToken));
};
