import {
  Badge,
  Button,
  CloseButton,
  Heading,
  HStack,
  Link,
  Text,
  Tooltip,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import formatDistance from "date-fns/formatDistance";
import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { formatUtcDateReadableLong } from "../../../utilities/dateUtils";

type Props = {
  notification: {
    id: string;
    body: string;
    link: {
      label: string;
      to: string;
      variant: "ghost" | "outline" | "solid";
      isExternal?: boolean;
    }[];
    title: string;
    time: Date;
    status: "unread" | "read";
  };
  markAsRead: (id: string) => Promise<void>;
  onClickButton: () => void;
  isLoading?: boolean;
};

const NotificationCard = ({
  notification,
  onClickButton,
  markAsRead,
  isLoading,
}: Props) => {
  const { id, body, link, title, time, status } = notification;
  const timeTextColor = useColorModeValue("blackAlpha.700", "whiteAlpha.700");
  return (
    <>
      <VStack
        w="100%"
        spacing={0}
        align="stretch"
        transition="all 0.2s ease-in-out"
        _hover={{ opacity: 1 }}>
        <HStack justify="space-between">
          <HStack align="center">
            {status === "unread" ? (
              <Badge colorScheme="brand" borderRadius="full" boxSize="0.75em" />
            ) : null}
            <Heading size="sm">{title} </Heading>
          </HStack>
          <CloseButton
            isDisabled={isLoading}
            onClick={() => {
              markAsRead(id);
            }}
          />
        </HStack>
        <Tooltip label={formatUtcDateReadableLong(time)}>
          <Text color={timeTextColor} fontSize="xs">
            {formatDistance(time, Date.now())} ago
          </Text>
        </Tooltip>
        <Text>{body}</Text>
        <VStack align="end" width="full" px="4" flexWrap="wrap">
          {link.map(({ label, to, variant, isExternal }) => (
            <>
              {isExternal ? (
                <Button
                  key={label}
                  isExternal={isExternal}
                  as={Link}
                  variant={variant}
                  size="sm"
                  href={to}>
                  {label}
                </Button>
              ) : (
                <Button
                  key={label}
                  onClick={onClickButton}
                  as={RouterLink}
                  variant={variant}
                  size="sm"
                  to={to}>
                  {label}
                </Button>
              )}
            </>
          ))}
        </VStack>
      </VStack>
    </>
  );
};

export default NotificationCard;
