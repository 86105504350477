import {Auth} from "aws-amplify";

/**
 * Returns the api token for the current authenticated user
 * This should trigger a refresh of the api token if needed
 */
const getApiToken = async () => {
    const session = await Auth.currentSession();
    if (session.isValid()) {
        return session.getIdToken().getJwtToken();
    }
    throw new Error ("Invalid Session");
}

export default getApiToken;