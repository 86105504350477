import {
  Avatar,
  Badge,
  HStack,
  LinkBox,
  LinkOverlay,
  Tag,
  TagLabel,
  Text,
  useColorModeValue,
  VStack
} from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";
import { ObjectStatus } from "../../shared/v2/constants/ObjectStatusID";
import {
  ObjectTypeGroup,
  ObjectTypeGroupIDToName
} from "../../shared/v2/constants/ObjectTypeGroupID";
import { formatDateTime } from "../../utilities/formatDate";
import Icon from "./Icon";
import ObjectStatusBadge from "./ObjectStatusBadge";

type ResultDataCore = {
  id: string;
  readableID: string;
  name: string;
  responsibleUserName: string;
  responsibleUserID: string;
  status: ObjectStatus;
};

type ResultDataRecord = ResultDataCore & {
  resultCategory: "record";
  objectType: string;
  objectTypeGroupID: ObjectTypeGroup;
};

type ResultDataTask = ResultDataCore & {
  resultCategory: "task";
  dueDate: string;
};

type ResultData = ResultDataRecord | ResultDataTask;

type Props = {
  data: ResultData;
  isSelected?: boolean;
  onMouseEnter: () => void;
  onClick: () => void;
};

const SearchResult = ({ data, isSelected, onMouseEnter, onClick }: Props) => {
  const normalBorderColor = useColorModeValue("gray.200", "gray.700");
  const selectedSearchResultBg = useColorModeValue("gray.100", "gray.700");
  const bg = isSelected ? selectedSearchResultBg : "transparent";
  const isTask = data.resultCategory === "task";
  const href = (() => {
    if (isTask) {
      return `/instance/${data.id}?display=actions`;
    }
    const type = ObjectTypeGroupIDToName[data.objectTypeGroupID].toLowerCase();
    return `/${type}/${data.id}?display=${type}s`;
  })();
  return (
    <LinkBox
      bg={bg}
      borderColor={normalBorderColor}
      borderWidth="1px"
      as={HStack}
      p={2}
      w="full"
      justifyContent="space-between"
      onMouseEnter={onMouseEnter}>
      <VStack w="full">
        <HStack w="full" justify="space-between">
          <HStack>
            <Badge variant="subtle" colorScheme={isSelected ? "brand" : "teal"}>
              {data.readableID}
            </Badge>
            <LinkOverlay as={Link} to={href} onClick={onClick}>
              <Text
                fontWeight="bold"
                color={isSelected ? "brand.800" : undefined}>
                {data.name}
              </Text>
            </LinkOverlay>
          </HStack>
          <Badge variant="outline" colorScheme="gray">
            {isTask ? "Task" : data.objectType}
          </Badge>
        </HStack>
        <HStack w="full" justify="space-between">
          <HStack>
            <Tag colorScheme="gray" borderRadius="full">
              <Avatar
                ml={-1}
                mr={2}
                size="2xs"
                name={data.responsibleUserName}
              />
              <TagLabel>{data.responsibleUserName}</TagLabel>
            </Tag>
            {isTask && (
              <Tag borderRadius="full" colorScheme="gray">
                {formatDateTime({ date: data.dueDate, format: "Date" })}
              </Tag>
            )}
          </HStack>
          <ObjectStatusBadge statusID={data.status} />
        </HStack>
      </VStack>
      <Icon
        style={{ visibility: isSelected ? "visible" : "hidden" }}
        name="CornerDownLeft"
      />
    </LinkBox>
  );
};

export default SearchResult;
