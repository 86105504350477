import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import * as Feather from "react-feather";
import {
  AuthState,
  CoreAppInfo,
  useActiveAppState,
} from "../../components/App/AppProvider";
import { Number } from "../../components/Forms/Field/Number";
import { Uploader } from "../../components/Forms/Field/Uploader";
import SingleUserSettings from "../../components/settings/SingleUserSettings";
import { Card } from "../../components/UI/Card/Card";
import { Header } from "../../components/UI/Header/Header";
import LegacyScreenContainer from "../../components/UI/LegacyScreenContainer";
import { Loading } from "../../components/UI/Loading/Loading";
import * as DocumentType from "../../shared/constants/documentTypes";
import * as Permissions from "../../shared/constants/permission";
import * as RecordStatus from "../../shared/constants/recordStatus";
import * as Access from "../../utilities/access";
import { get, post } from "../../utilities/request";
import useToast, { DisplayToastFn } from "../../utilities/useToast";
import SettingsScreenContainer from "../_chakra/settings/SettingsScreenContainer";
import { Account, AccountPageStatus } from "./Account.d";
import { ScreensAccountCalendar } from "./AccountCalendar";

const renderAccount = (
  auth: AuthState,
  appInfo: CoreAppInfo,
  pageStatus: AccountPageStatus,
  setPageStatus: React.Dispatch<React.SetStateAction<AccountPageStatus>>,
  data: any,
  setData: any,
  displayToast: DisplayToastFn,
) => {
  if (pageStatus === "Loading") {
    return (
      <Card title="Account" collapsable={false}>
        <Loading />
      </Card>
    );
  }
  if (
    (pageStatus === "Editing" ||
      pageStatus === "Ready" ||
      pageStatus === "Submitting") &&
    data
  ) {
    return (
      <Formik
        initialValues={data}
        onSubmit={async (values, actions) => {
          setPageStatus("Submitting");
          setData(values);
          // before uploading send back only the DocumentID if the logo was changed
          const documentID =
            values.NewLogo && values.NewLogo.length !== 0
              ? values.NewLogo[0].status === "done"
                ? values.NewLogo[0].uid
                : values.NewLogo[0].originFileObj.DocumentID
              : values.DocumentID;
          await post(
            `settings/account/${values.AccountID}`,
            { ...values, DocumentID: documentID },
            auth,
          );

          displayToast({
            status: "success",
            title: "Account updated successfully",
          });
          setPageStatus("Ready");
          // todo: fix this :(
          window.location.reload();
        }}
        render={(formikProps) => (
          <Card
            title="Account"
            collapsable={false}
            headerColsRight
            headerCols={
              pageStatus === "Ready"
                ? [
                    {
                      colProps: { sm: "auto" },
                      children: (
                        <Row>
                        {/* <Col sm="auto">
                          <Button
                            variant="primary"
                            onClick={async () => {
                              setPageStatus("Submitting");
                              const exportingProm = get("export");
                              displayToast({
                                status: "info",
                                title: "Exporting data",
                                description: "This may take some time. You will receive an email when complete."
                              });
                              await new Promise((res) => {setTimeout(res, 1500)});
                              setPageStatus("Ready");
                              try {
                                await exportingProm;
                              } catch (err) {}
                            }}>
                            Export data to CSV
                          </Button>
                        </Col> */}
                          <Col sm="auto">
                            {Access.checkAccess(
                              appInfo.permissions_LEGACY,
                              Permissions.CodeAccountSettings,
                              Permissions.TypeUpdate,
                              false,
                            ) ? (
                              <Button
                                type="button"
                                variant="outline-dark"
                                onClick={() => setPageStatus("Editing")}>
                                <Feather.Edit2 className="feather" size="16" />
                                Edit
                              </Button>
                            ) : (
                              <OverlayTrigger
                                placement="auto"
                                overlay={
                                  <Tooltip id="not-authorised-to-edit">
                                    You are not authorised to edit this
                                  </Tooltip>
                                }>
                                <Button
                                  type="button"
                                  variant="outline-dark"
                                  className="disabled">
                                  <Feather.Edit2
                                    className="feather"
                                    size="16"
                                  />
                                  Edit
                                </Button>
                              </OverlayTrigger>
                            )}
                          </Col>
                        </Row>
                      ),
                    },
                  ]
                : []
            }>
            <Form onSubmit={formikProps.handleSubmit}>
              <Form.Group as={Row}>
                <Form.Label column sm="2">
                  {" "}
                  {" Account Name: "}{" "}
                </Form.Label>
                <Col sm="8">
                  <Form.Control
                    defaultValue={data.AccountName}
                    placeholder={data.AccountName}
                    type="text"
                    name="AccountName"
                    onChange={formikProps.handleChange}
                    isValid={!!formikProps.errors.RequirementName}
                    plaintext
                    readOnly
                  />
                </Col>
              </Form.Group>

              {pageStatus === "Editing" || pageStatus === "Submitting" ? (
                <>
                  <Form.Group as={Row} style={{ alignItems: "center" }}>
                    <Form.Label column sm="2">
                      {" "}
                      {" Account Owner: "}{" "}
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        as="select"
                        readOnly={pageStatus !== "Editing"}
                        id="AccountOwnerUserID"
                        plaintext={pageStatus !== "Editing"}
                        onChange={formikProps.handleChange}>
                        <option
                          key="default"
                          value={
                            data.AccountOwnerUserID
                              ? data.AccountOwnerUserID
                              : "Select Owner"
                          }>
                          {" "}
                          {data.AccountOwnerUserID
                            ? `${
                                data.Users.find(
                                  (user: any) =>
                                    user.UserID === data.AccountOwnerUserID,
                                ).UserFirstName
                              } ${
                                data.Users.find(
                                  (user: any) =>
                                    user.UserID === data.AccountOwnerUserID,
                                ).UserLastName
                              }`
                            : "Select Owner"}{" "}
                        </option>
                        {data.Users.map((user: any, index: number) => (
                          <option
                            key={user.UserID.concat(index)}
                            value={user.UserID}>
                            {" "}
                            {`${user.UserFirstName} ${user.UserLastName}`}{" "}
                          </option>
                        ))}
                      </Form.Control>
                    </Col>
                  </Form.Group>
                </>
              ) : (
                <>
                  <Form.Group as={Row}>
                    <Form.Label column sm="2">
                      {" "}
                      {" Account Owner: "}{" "}
                    </Form.Label>
                    <Col sm="10">
                      <Form.Control
                        value={
                          data.AccountOwnerUserID
                            ? `${
                                data.Users.find(
                                  (user: any) =>
                                    user.UserID === data.AccountOwnerUserID,
                                ).UserFirstName
                              } ${
                                data.Users.find(
                                  (user: any) =>
                                    user.UserID === data.AccountOwnerUserID,
                                ).UserLastName
                              }`
                            : undefined
                        }
                        type="text"
                        name="AccountOwnerUserID"
                        onChange={formikProps.handleChange}
                        isValid={!!formikProps.errors.RequirementName}
                        readOnly
                        plaintext
                      />
                    </Col>
                  </Form.Group>
                </>
              )}

              <Form.Group as={Row}>
                <Form.Label column sm="2">
                  {" "}
                  {" Account Logo: "}{" "}
                </Form.Label>
                <Col sm="8">
                  {pageStatus !== "Editing" ? (
                    <img style={{ width: "50%" }} src={data.Logo} />
                  ) : (
                    <Uploader
                      fileList={formikProps.values.NewLogo}
                      uploading={false}
                      onChange={(e) => {
                        if (e.fileList.length > 1) {
                          formikProps.setValues({
                            ...formikProps.values,
                            NewLogo: [e.fileList[0]],
                          });
                        } else {
                          formikProps.setValues({
                            ...formikProps.values,
                            NewLogo: e.fileList,
                          });
                        }
                      }}
                      readOnly={pageStatus !== "Editing"}
                      limitTypes=".png, .jpeg, .jpg"
                      limitTypesExplanation=".png, .jpeg, .jpg"
                      name="NewLogo"
                      previewVisible
                      limitSize={800 * 1024}
                      limitUploadNumber={1}
                    />
                  )}
                </Col>
              </Form.Group>

              <Form.Group as={Row}>
                <Form.Label column sm="2">
                  {" "}
                  {" System Name: "}{" "}
                </Form.Label>
                <Col sm="8">
                  <Form.Control
                    placeholder={data.AccountSystemName}
                    type="text"
                    name="AccountSystemName"
                    onChange={(event: any) => {
                      if (event.target.value.length <= 100) {
                        formikProps.setValues({
                          ...formikProps.values,
                          AccountSystemName: event.target.value,
                        });
                      }
                    }}
                    value={formikProps.values.AccountSystemName}
                    plaintext={pageStatus !== "Editing"}
                    readOnly={pageStatus !== "Editing"}
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row}>
                <Form.Label column sm="2">
                  Calendar Feeds Available:
                </Form.Label>
                <Col sm="8">
                  <Form.Check.Input
                    type="checkbox"
                    id="AccountiCalActive"
                    name="AccountiCalActive"
                    value="AccountiCalActive"
                    onChange={formikProps.handleChange}
                    checked={formikProps.values.AccountiCalActive}
                    disabled={!(pageStatus === "Editing")}
                    style={{ marginLeft: 0 }}
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row}>
                <Form.Label column sm="2">
                  Idle Timeout:
                </Form.Label>
                <Col sm="1">
                  <Form.Check.Input
                    type="checkbox"
                    id="AccountTimeoutMinutesEnabled"
                    name="AccountTimeoutMinutesEnabled"
                    value="AccountTimeoutMinutesEnabled"
                    onChange={(event: any) => {
                      if (!formikProps.values.AccountTimeoutMinutesEnabled) {
                        formikProps.setValues({
                          ...formikProps.values,
                          AccountTimeoutMinutes: 30,
                        });
                      } else {
                        formikProps.setValues({
                          ...formikProps.values,
                          AccountTimeoutMinutes: null,
                        });
                      }
                      formikProps.handleChange(event);
                    }}
                    checked={formikProps.values.AccountTimeoutMinutesEnabled}
                    disabled={!(pageStatus === "Editing")}
                    style={{ marginLeft: 0 }}
                  />
                </Col>
                {formikProps.values.AccountTimeoutMinutesEnabled ? (
                  <Col sm="8">
                    <Number
                      value={
                        formikProps.values.AccountTimeoutMinutes !== null
                          ? formikProps.values.AccountTimeoutMinutes
                          : ""
                      }
                      name="AccountTimeoutMinutes"
                      onUpdate={formikProps.handleChange}
                      readOnly={!(pageStatus === "Editing")}
                      min={1}
                      max={30}
                      unit="Minutes"
                    />
                  </Col>
                ) : null}
              </Form.Group>

              {pageStatus === "Editing" || pageStatus === "Submitting" ? (
                Access.checkAccess(
                  appInfo.permissions_LEGACY,
                  "USER_SETTINGS",
                  "READ",
                ) && pageStatus === "Submitting" ? (
                  <Loading />
                ) : null
              ) : Access.checkAccess(
                  appInfo.permissions_LEGACY,
                  "USER_SETTINGS",
                  "READ",
                ) ? (
                <Form.Group>
                  <Col sm="4" />
                  <Col sm="10" />
                </Form.Group>
              ) : null}

              <Form.Group as={Row}>
                {pageStatus === "Editing" ? (
                  <>
                    <Col sm={{ span: "auto", offset: "2" }}>
                      <Button type="submit">Submit</Button>
                    </Col>
                    <Col sm="auto">
                      <Button
                        type="button"
                        variant="light"
                        onClick={() => {
                          setData(data);
                          formikProps.resetForm();
                          setPageStatus("Ready");
                        }}>
                        Cancel
                      </Button>
                    </Col>
                  </>
                ) : null}
              </Form.Group>
            </Form>
          </Card>
        )}
      />
    );
  }
};

const ScreensAccount = () => {
  const { appState, setAppState } = useActiveAppState();
  const { app, auth } = appState;
  const [pageStatus, setPageStatus] = useState<AccountPageStatus>("Loading");
  const [data, setData] = useState<any>(null);
  const { displayToast } = useToast();

  useEffect(() => {
    const fetchRequirement = async () => {
      if (app.attributes !== undefined && app.accountInfo) {
        // api call

        try {
          // Returns all users for an account, with account information attached to each user
          const info: any = await get("settings/account", auth);

          let AccountLogoPath = null;
          if (info.data[0].DocumentID) {
            const doc = await get(
              `document/${info.data[0].DocumentID}/${DocumentType.UploadedFile}`,
              auth,
            );
            AccountLogoPath = doc.data.url;
          }

          const allUsers: User[] = [];
          info.data
            .filter(
              (user: any) => user.RecordStatusID !== RecordStatus.Template,
            )
            .forEach((user: any) => {
              const newUser: User = {
                UserID: user.UserID,
                UserFirstName: user.UserFirstName,
                UserLastName: user.UserLastName,
                UserEmail: user.UserEmail,
              };

              allUsers.push(newUser);
            });

          const displayAccount: Account = {
            AccountID: app.attributes.currentAccountID,
            AccountName: info.data[0].AccountName,
            AccountSystemName: info.data[0].AccountSystemName,
            AccountOwnerUserID: info.data[0].AccountOwnerUserID,
            AccountiCalActive: info.data[0].AccountiCalActive,
            Logo: AccountLogoPath,
            DocumentID: info.data[0].DocumentID,
            Users: allUsers,
            AccountTimeoutMinutes: info.data[0].AccountTimeoutMinutes,
            AccountTimeoutMinutesEnabled:
              info.data[0].AccountTimeoutMinutes !== null,
          };

          setData(displayAccount);

          setPageStatus("Ready");
        } catch (ex) {
          console.log("catch error", ex);
        }
      }
    };
    fetchRequirement();
  }, []);

  return (
    <SettingsScreenContainer pageTitle="Account & Calendar">
      <Container>
        {pageStatus !== "Loading" ? (
          <Container>
            {renderAccount(
              auth,
              app,
              pageStatus,
              setPageStatus,
              data,
              setData,
              displayToast,
            )}
            <ScreensAccountCalendar />
          </Container>
        ) : (
          <Loading />
        )}
      </Container>
    </SettingsScreenContainer>
  );
};

export type State = {} | String;

export type User = {
  UserID: string;
  UserFirstName: string;
  UserLastName: string;
  UserEmail: string;
};

export type UserStatus = "Adding" | "Ready" | "Submitting";

export { ScreensAccount };
