import {
  Button,
  Group,
  Stack,
  Text,
  UnstyledButton,
  Box,
  LoadingOverlay,
  Loader,
} from "@mantine/core";

import { useEffect, useState } from "react";
import {
  BlueprintOrgInfo,
  BlueprintQuestionConfig,
} from "../../useBlueprintState";
import RichText from "../../../../../components/chakra/form/RichText";
import { RiArrowLeftSLine } from "react-icons/ri";
import { handleGetAIResult } from "../../getAIResult";

type Props = {
  onBack: () => void;
  question: BlueprintQuestionConfig;
  orgInfo: BlueprintOrgInfo;
  onContinue: (result: string) => void;
};

type State =
  | {
      type: "generating";
    }
  | {
      type: "complete";
      result: string;
    };

export default function QuestionStepAiResult({
  question,
  orgInfo,
  onBack,
  onContinue,
}: Props) {
  const [state, setState] = useState<State>({ type: "generating" });
  useEffect(() => {
    setState({
      type: "generating",
    });
    const get = async () => {
      const res = await handleGetAIResult(question, orgInfo);
      setState({ type: "complete", result: res });
    };
    get();
  }, [question, orgInfo]);

  const handleClickContinue = () => {
    if (state.type === "complete") {
      onContinue(state.result);
    }
  };

  return (
    <>
      <Stack w="100%" pos="relative" align="center" style={{ flexGrow: 1 }}>
        <LoadingOverlay
          loaderProps={{
            type: "bars",
            children: (
              <Stack align="center">
                <Loader type="bars" size="lg" />
                <Text>Asking AI...</Text>
              </Stack>
            ),
          }}
          overlayProps={{ blur: 2 }}
          visible={state.type === "generating"}
          zIndex={1000}
        />
        <Stack justify="center" align="center" style={{ flexGrow: 1 }}>
          <Box className="rich-text-container" w="80%" style={{ flexGrow: 0 }}>
            <RichText
              heightMin={250}
              heightMax={320}
              value={state.type === "complete" ? state.result : ""}
              onChange={(v) =>
                state.type === "complete"
                  ? setState({ type: "complete", result: v })
                  : null
              }
              charCounterMax={10000}
            />
          </Box>
        </Stack>
      </Stack>
      <Group w="100%" justify="space-between" style={{ alignSelf: "flex-end" }}>
        <Button
          leftSection={<RiArrowLeftSLine size={16} />}
          variant="subtle"
          onClick={onBack}>
          Back
        </Button>
        <Button
          disabled={state.type === "generating"}
          onClick={handleClickContinue}>
          Save and Continue
        </Button>
      </Group>
    </>
  );
}
