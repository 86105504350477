import React from "react";
import Select from "react-select";
import { Form, OverlayTrigger, Tooltip, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { CornerDownRight } from "react-feather";
import * as Feather from "react-feather";
import { Text } from "./Text";
import { Textarea } from "./Textarea";
import { text_stripHTML } from "../../../utilities/strings";
import * as ObjectTypeGroup from "../../../shared/constants/objectTypeGroup";
import { DraggableMultiSelect } from "./MultiSelectDraggable";

interface MultiSelectProps {
  onUpdate?: (event: any, action?: any) => void;
  name: string;
  value: any[] | any;
  unselectedOption?: string;
  optionsList: any[];
  optionsListID?: string;
  optionsListValue?: string;
  optionsListReadable?: string;
  optionsChild?: boolean;
  readOnly: boolean;
  readOnlyValue?: string;
  readOnlyComponent?: any[];
  expiredReadOnlyComponent?: any[];
  disabled?: boolean;
  display?: string;
  template?: boolean;
  action?: boolean;
  risk?: boolean;
  visibility?: boolean;
  reportSettings?: boolean;
  unit?: any;
  customField?: boolean;
  available?: boolean;
  actionResponsible?: boolean;
  seeMore?: boolean;
  parentIdFromUrl?: string;
  draggable?: boolean;
  components?: any;
}

export const styles = {
  multiValue: (base: any, state: any) =>
    state.data.isFixed ? { ...base, backgroundColor: "gray" } : base,
  multiValueLabel: (base: any, state: any) =>
    state.data.isFixed
      ? {
          ...base,
          fontWeight: "bold",
          color: "white",
          minWidth: "max-content",
          paddingRight: 6,
        }
      : base,
  multiValueRemove: (base: any, state: any) =>
    state.data.isFixed ? { ...base, display: "none" } : base,
};

export const MultiSelect = (props: MultiSelectProps): any => {
  const [limitParentTree, updateLimitParentTree] = React.useState<boolean>(
    props.seeMore || false,
  );

  if (
    props.optionsListID === undefined &&
    props.optionsListValue === undefined &&
    props.optionsChild === undefined
  ) {
    throw new Error(
      "Either optionsListID and optionsListValue must be defined or optionsChild",
    );
  }
  const formattedValues: any = [];
  let def: any = [];
  const noParent = {
    label: props.risk ? props.unselectedOption : "No Parent",
    value: "",
  };
  props.optionsList
    .filter(
      (option: any) =>
        option.Permission ||
        props.template ||
        props.visibility ||
        props.customField ||
        props.available ||
        props.actionResponsible,
    )
    .forEach((listItem: any) => {
      // If optionsListID, optionsListValue and optionsListReadableID && it has children are passed....
      if (
        props.optionsChild &&
        props.optionsListID &&
        props.optionsListValue &&
        props.optionsListReadable
      ) {
        // here we need to check if it is risk or not
        // if it is not a risk multi select means it is a parent so we need to check permissions before adding them
        let label = "";
        listItem.ParentIDs && listItem.ParentIDs.length > 0
          ? (label = `${"\u00A0\u00A0\u00A0"}${
              listItem[props.optionsListReadable]
            }-${listItem[props.optionsListValue]}`)
          : (label = `${listItem[props.optionsListReadable]}-${
              listItem[props.optionsListValue]
            }`);
        if (props.value) {
          if (props.value.length > 0) {
            props.value.forEach((v: any) => {
              if (
                listItem.RequirementID === v.RiskID ||
                listItem.RequirementID === v.ParentID ||
                listItem.RequirementID === v.RequirementID
              ) {
                def.push({ label, value: listItem.RequirementID });
                // formattedValues.push({ label: label, value: listItem.RequirementID })
              }
              // there was an else here that when the value was more than one, it would enter the parents as many times as the values creating duplicates
            });
          } // another else here was removed that made duplicates when no parent was selected
          // regardless if we have a value we put the parent inside the list
          formattedValues.push({ label, value: listItem.RequirementID });
        }
      } else if (
        props.optionsListID &&
        props.optionsListValue &&
        props.optionsListReadable
      ) {
        // If this listItem is in the value list, add it to the multi-select value
        const found = props.value.find((selectedValue: any) => {
          if (selectedValue.RequirementID) {
            return selectedValue.RequirementID === listItem.RequirementID;
          }
          if (selectedValue.ParentID) {
            return selectedValue.ParentID === listItem.RequirementID;
          }
          if (selectedValue.ID) {
            return selectedValue.ID === listItem.ID;
          }
          return selectedValue.RiskID === listItem.RequirementID;
        });

        if (found) {
          def.push({
            label: `${listItem[props.optionsListReadable]} - ${
              listItem[props.optionsListValue]
            }`,
            value: listItem[props.optionsListID],
          });
        } else {
          // Otherwise add it to the options List
          formattedValues.push({
            label: `${listItem[props.optionsListReadable]} - ${
              listItem[props.optionsListValue]
            }`,
            value: listItem[props.optionsListID],
          });
        }
      } else if (props.optionsListID && props.optionsListValue) {
        // visible to multiselect in reports
        const SeenIds: string[] = [];
        if (props.value && props.visibility) {
          const label = listItem[props.optionsListValue];
          const value = props.reportSettings
            ? listItem.value
            : listItem[props.optionsListID];
          if (props.value.length > 0) {
            props.value.forEach((v: any) => {
              if (
                props.reportSettings
                  ? listItem.value === v.value
                  : listItem.ID === v.ID
              ) {
                def.push({ label, value, isFixed: listItem.isFixed });
                SeenIds.push(listItem.ID);
              } else if (!SeenIds.includes(listItem.ID)) {
                formattedValues.push({
                  label,
                  value,
                  isFixed: listItem.isFixed,
                });
                SeenIds.push(listItem.ID);
              }
            });
          } else {
            formattedValues.push({ label, value, isFixed: listItem.isFixed });
          }
        } else if (props.customField) {
          // this is for multiselects used in ustom fields
          const label = listItem[props.optionsListValue];
          const ID = listItem[props.optionsListID];
          if (props.value.length > 0) {
            props.value.forEach((v: any) => {
              if (ID === v.ListValueID) {
                def.push({ label, value: ID });
                SeenIds.push(listItem.ID);
              } else if (!SeenIds.includes(ID)) {
                formattedValues.push({ label, value: ID });
                SeenIds.push(ID);
              }
            });
          } else {
            formattedValues.push({ label, value: ID });
          }
        } else if (props.available || props.value || props.actionResponsible) {
          const label = listItem[props.optionsListValue];
          const ID = listItem[props.optionsListID];
          if (props.value && props.value.length > 0) {
            props.value.forEach((v: any) => {
              const existingID = v[props.optionsListID!];
              if (ID === existingID) {
                def.push({ label, value: ID });
                SeenIds.push(listItem.ID);
              } else if (!SeenIds.includes(ID)) {
                formattedValues.push({ label, value: ID });
                SeenIds.push(ID);
              }
            });
          } else {
            formattedValues.push({ label, value: ID });
          }
        } else {
          formattedValues.push({
            label: listItem[props.optionsListValue],
            value: listItem[props.optionsListID],
          });
        }
      }
    });
  if (!props.action && props.template && !props.visibility) {
    formattedValues.push(noParent);
    if (def.length <= 0) {
      def.push(noParent);
    }
  }

  // since the list is ordered alphabetically but we want the selected values to follow the order we have selected them in, the following is needed
  if (props.reportSettings && props.readOnlyComponent) {
    const def1: any[] = [];
    props.readOnlyComponent.forEach((cv: any) => {
      def.forEach((d: any, index: number) => {
        if (cv.value === d.value) {
          def1.push(d);
        }
      });
    });
    def = def1;
  }

  if (!props.readOnly) {
    return (
      <Row>
        <Col>
          {props.draggable ? (
            <DraggableMultiSelect
              options={formattedValues}
              placeholder={props.unselectedOption || ""}
              value={def}
              onUpdate={props.onUpdate!}
              disabled={props.disabled || false}
              name={props.name}
              components={props.components}
            />
          ) : (
            <Select
              options={formattedValues}
              isMulti={
                props.action ? true : props.visibility ? true : !props.template
              }
              isSearchable
              placeholder={props.unselectedOption}
              value={def}
              onChange={props.onUpdate}
              isDisabled={props.disabled}
              styles={styles}
              isClearable={formattedValues.some((v: any) => !v.isFixed)}
              name={props.name}
              components={props.components}
            />
          )}
        </Col>
        {props.unit ? (
          <span className="col-form-label form-unit">{props.unit}</span>
        ) : null}
      </Row>
    );
  }
  if (props.readOnlyValue) {
    return (
      <Row>
        <Col>
          <Form.Control
            type="text"
            value={props.readOnlyValue}
            readOnly
            plaintext
          />
        </Col>
        {props.unit ? (
          <span className="col-form-label form-unit">{props.unit}</span>
        ) : null}
      </Row>
    );
  }
  if (
    props.readOnlyComponent &&
    props.readOnlyComponent.length > 0 &&
    Array.isArray(props.readOnlyComponent)
  ) {
    // for actions with immediate multi parents, if we know the current parent id, display that one first
    if (
      props.parentIdFromUrl &&
      props.readOnlyComponent.length > 1 &&
      props.action
    ) {
      const parentIndex = props.readOnlyComponent.findIndex(
        (parent: any) => parent.ParentID === props.parentIdFromUrl,
      );
      if (parentIndex >= 0) {
        const [removed] = props.readOnlyComponent.splice(parentIndex, 1);
        props.readOnlyComponent.splice(0, 0, removed);
      }
    }

    // count total records to calculate how many are hidden
    let ancestorCount = 0;
    let ancestorsDisplayed = 0;
    props.readOnlyComponent.forEach((parent: any) => {
      parent.ParentTree && parent.ParentTree.length > 0
        ? parent.ParentTree.forEach((grandparent: any) => {
            ancestorCount +=
              (grandparent.ParentTree && grandparent.ParentTree.length > 0
                ? 3
                : 2) *
              (grandparent.ParentTree && grandparent.ParentTree.length > 0
                ? grandparent.ParentTree.length
                : 1);
          })
        : ancestorCount++;
    });

    return props.readOnlyComponent
      .slice(0, limitParentTree ? 1 : props.readOnlyComponent.length)
      .map((parent: any, index: number, array: any[]) => {
        ancestorsDisplayed++;
        let label: string = "";
        let name: string = "";
        let type: string = "";

        if (props.visibility) {
          label = props.reportSettings ? parent.label : parent.Name;
          name = parent.ID;
          type = parent.Type.toLowerCase();
        } else if (props.customField && props.optionsListValue) {
          const option = props.optionsList.find(
            (o: any) => o.ListValueID === parent,
          );

          if (option) {
            label = option.ListValue;
          } else if (props.expiredReadOnlyComponent) {
            label = props.expiredReadOnlyComponent.find(
              (o: any) => o.ListValueID === parent,
            ).ListValue;
          }
          name = parent;
        } else {
          label = parent.RequirementName
            ? parent.RequirementName
            : parent.ParentName
            ? parent.ParentName
            : parent.RiskName;
          name = parent.RequirementID
            ? parent.RequirementID
            : parent.ParentID
            ? parent.ParentID
            : parent.RiskID;
          type = parent.ObjectTypeGroupName
            ? parent.ObjectTypeGroupName.toLowerCase()
            : "";
        }

        if (parent.Permission !== undefined && parent.Permission === 0) {
          return (
            <OverlayTrigger
              key={index}
              placement="auto"
              overlay={
                <Tooltip id="tooltip-not-authorised-to-edit">
                  You do not have permission to edit this
                </Tooltip>
              }>
              <Text
                name="parentName"
                readOnly
                value={props.visibility ? parent.Name : parent.ParentName}
              />
            </OverlayTrigger>
          );
        }
        if (props.reportSettings || props.customField) {
          return (
            <React.Fragment key={index}>
              <Form.Label>{label}</Form.Label>
              {props.unit ? (
                <span className="col-form-label form-unit">{props.unit}</span>
              ) : null}
              <Form.Label>
                {index === array.length - 1 ? "" : `,${"\u00A0\u00A0"}`}
              </Form.Label>
            </React.Fragment>
          );
        }
        if (props.seeMore !== undefined) {
          return (
            <React.Fragment key={index}>
              {parent.ParentTree && parent.ParentTree.length > 0 ? (
                parent.ParentTree.slice(
                  0,
                  limitParentTree ? 1 : parent.ParentTree.length,
                ).map((grandparent: any, gpIndex: number) => {
                  ancestorsDisplayed++;
                  return (
                    <React.Fragment key={`${index}_${gpIndex}`}>
                      {grandparent.ParentTree &&
                      grandparent.ParentTree.length > 0 ? (
                        grandparent.ParentTree.slice(
                          0,
                          limitParentTree ? 1 : grandparent.ParentTree.length,
                        ).map((greatgrandparent: any, ggpIndex: number) => {
                          ancestorsDisplayed++;
                          return (
                            <React.Fragment
                              key={`${index}_${gpIndex}_${ggpIndex}`}>
                              <ParentView
                                index={index}
                                indent={0}
                                parent={greatgrandparent}
                                multiSelectProps={props}
                              />
                              <ParentView
                                index={index}
                                indent={1}
                                parent={grandparent}
                                multiSelectProps={props}
                              />
                              <ParentView
                                index={index}
                                indent={2}
                                parent={parent}
                                multiSelectProps={props}
                              />
                            </React.Fragment>
                          );
                        })
                      ) : (
                        <>
                          <ParentView
                            index={index}
                            indent={0}
                            parent={grandparent}
                            multiSelectProps={props}
                          />
                          <ParentView
                            index={index}
                            indent={1}
                            parent={parent}
                            multiSelectProps={props}
                          />
                        </>
                      )}
                    </React.Fragment>
                  );
                })
              ) : (
                <ParentView
                  index={index}
                  indent={0}
                  parent={parent}
                  multiSelectProps={props}
                />
              )}
              {ancestorCount > ancestorsDisplayed && limitParentTree ? (
                <>
                  <Row>
                    <Col>
                      <Link
                        to="/"
                        onClick={(e: any) => {
                          e.preventDefault();
                          updateLimitParentTree(false);
                        }}>
                        See more ({ancestorCount - ancestorsDisplayed})...
                      </Link>
                    </Col>
                  </Row>
                </>
              ) : null}
            </React.Fragment>
          );
        }

        return (
          <Link
            key={index}
            to={`${
              props.template ? "/template" : props.visibility ? "/settings" : ""
            }/${type}/${name}`.concat(
              props.visibility
                ? ""
                : props.display
                ? props.display
                : `?display=${parent.ObjectTypeGroupName.toLowerCase()}s`,
            )}>
            {" "}
            {label}
            {index === array.length - 1 ? "" : ","}{" "}
          </Link>
        );
      });
  }
  // if it is a custom field show nothing
  return (
    <Form.Label>
      {props.risk || props.visibility
        ? props.unselectedOption
        : props.customField
        ? ""
        : "No Parent"}
    </Form.Label>
  );
};

interface ParentViewProps {
  index: number;
  indent: number;
  parent: any;
  multiSelectProps: MultiSelectProps;
}

const ParentView = (props: ParentViewProps) => {
  const label = props.parent.RequirementName
    ? props.parent.RequirementName
    : props.parent.ParentName
    ? `${props.parent.RequirementReadableID} - ${props.parent.ParentName}:`
    : props.parent.RiskName;
  const description = props.parent.ParentDescription
    ? props.parent.ParentDescription
    : "";
  const name = props.parent.RequirementID
    ? props.parent.RequirementID
    : props.parent.ParentID
    ? props.parent.ParentID
    : props.parent.RiskID;
  const type = props.parent.ObjectTypeGroupName
    ? props.parent.ObjectTypeGroupName.toLowerCase()
    : "";
  const iconType: keyof typeof Feather = props.parent.ObjectTypeIcon;
  const Icon = Feather[iconType];

  return (
    <React.Fragment key={props.parent.ParentID}>
      <Row style={{ paddingTop: "5px" }}>
        {props.indent > 0 ? (
          <span
            style={{
              minWidth: `${props.indent * 20}px`,
              paddingLeft: `${props.indent * 20 + 20}px`,
            }}>
            <CornerDownRight
              size={16}
              color="#2F4F4F"
              style={{ marginBottom: "5px" }}
            />
          </span>
        ) : iconType ? (
          <Icon
            size={14}
            color="#2F4F4F"
            style={{ marginTop: "5px", marginLeft: "15px" }}
          />
        ) : null}
        <Col
          sm="auto"
          style={{
            paddingRight: "0px",
            paddingLeft: props.indent > 0 ? "5px" : "10px",
          }}>
          {props.parent.ObjectTypeGroupID === ObjectTypeGroup.Action &&
          !props.parent.ActionIsActive ? (
            <Text value={`${label} (deleted)`} name="ActionID" readOnly />
          ) : (
            <Link
              to={`${
                props.multiSelectProps.template
                  ? "/template"
                  : props.multiSelectProps.visibility
                  ? "/settings"
                  : ""
              }/${type}/${name}`.concat(
                props.multiSelectProps.visibility
                  ? ""
                  : props.multiSelectProps.display
                  ? props.multiSelectProps.display
                  : `?display=${props.parent.ObjectTypeGroupName.toLowerCase()}s`,
              )}>
              {label}
            </Link>
          )}
        </Col>

        <Col style={{ ...noWrapStyle, paddingLeft: "5px" }}>
          {description ? (
            text_stripHTML(description).length +
              props.parent.ParentName.length >
            70 ? (
              <OverlayTrigger
                placement="auto"
                overlay={
                  <Tooltip id={props.index}>
                    <Textarea
                      value={description}
                      name={`ActionDescription${props.index}`}
                      readOnly
                      richText
                    />
                  </Tooltip>
                }>
                <span style={noWrapStyle}>{text_stripHTML(description)}</span>
              </OverlayTrigger>
            ) : (
              <span style={noWrapStyle}>{text_stripHTML(description)}</span>
            )
          ) : null}
        </Col>
      </Row>
    </React.Fragment>
  );
};

const noWrapStyle: any = {
  whiteSpace: "nowrap",
  minWidth: 0,
  overflow: "hidden",
  textOverflow: "ellipsis",
};
