/* 
	Function can be used where type is not known as it will check whether params are arrays.
	Can be used to test if elements occur in same order in each array or if elements are simply present in both arrays.
	Pass through a property name to test arrays of objects (currently only one level deep).
*/
export const arraysAreEqual = (
  arr1: any,
  arr2: any,
  compareOrder: boolean,
  propertyToCompare?: string,
) => {
  if (
    Array.isArray(arr1) &&
    Array.isArray(arr2) &&
    arr1.length === arr2.length &&
    (propertyToCompare
      ? arr1.every((el) => el.hasOwnProperty(propertyToCompare)) &&
        arr2.every((el) => el.hasOwnProperty(propertyToCompare))
      : true)
  ) {
    for (let i = 0; i < arr1.length; i++) {
      if (compareOrder) {
        if (propertyToCompare) {
          if (arr1[i][propertyToCompare] !== arr2[i][propertyToCompare]) {
            return false;
          }
        } else {
          if (arr1[i] !== arr2[i]) {
            return false;
          }
        }
      } else {
        if (propertyToCompare) {
          if (
            !arr2.find(
              (el) => el[propertyToCompare] === arr1[i][propertyToCompare],
            )
          ) {
            return false;
          }
        } else {
          if (!arr2.find((el) => el === arr1[i])) {
            return false;
          }
        }
      }
    }
    return true;
  } else {
    return false;
  }
};
