import {
  Button,
  ButtonGroup,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { useRef } from "react";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  title: string;
  onChangeTitle: (title: string) => void;
};

export default function EditManualNameModal({ isOpen, onClose, onConfirm, onChangeTitle, title }: Props) {
    const initialFocusRef = useRef(null);
  
  return (
    <Modal initialFocusRef={initialFocusRef} isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <chakra-scope>
        <ModalContent>
          <ModalHeader>Edit manual name</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                onConfirm();   
              }}>
              <Input
                ref={initialFocusRef}
                value={title}
                onChange={(e) => onChangeTitle(e.target.value)}
                placeholder="Manual name"
              />
            </form>
          </ModalBody>
          <ModalFooter>
            <ButtonGroup>
              <Button onClick={onClose} colorScheme="gray">
                Cancel
              </Button>
              <Button onClick={() => onConfirm()}>Update name</Button>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </chakra-scope>
    </Modal>
  );
}
