import {
  VStack,
  Text,
  Heading,
  HStack,
  FormControl,
  FormLabel,
  Box,
} from "@chakra-ui/react";
import React from "react";
import * as CustomFieldTypeID from "../../../shared/v2/constants/CustomFieldTypeID";
import { ObjectRecord } from "../../../shared/v2/definitions/record";
import ReadOnlyRecordField from "./ReadOnlyRecordField";

type Props = {
  record: ObjectRecord;
};

const ReadOnlyRecord = ({ record }: Props) => {
  console.log();
  return (
    <VStack spacing="4">
      <FormControl>
        <FormLabel>Name</FormLabel>
        <Box
          px="4"
          py="2"
          bg="gray.50"
          borderLeftColor="brand.500"
          borderLeftWidth="1px"
          borderLeftStyle="solid"
          borderRadius="md"
          borderLeftRadius="unset">
          <ReadOnlyRecordField
            customFieldRecord={{
              customFieldTypeID: CustomFieldTypeID.Text,
              value: record.name,
            }}
            isInTable={false}
          />
        </Box>
      </FormControl>
      <FormControl>
        <FormLabel>Description</FormLabel>
        <Box
          px="4"
          py="2"
          bg="gray.50"
          borderLeftColor="brand.500"
          borderLeftWidth="1px"
          borderLeftStyle="solid"
          borderRadius="md"
          borderLeftRadius="unset">
          <ReadOnlyRecordField
            customFieldRecord={{
              customFieldTypeID: CustomFieldTypeID.LongText,
              value: record.description,
            }}
            isInTable={false}
          />
        </Box>
      </FormControl>
      {record.customFields.map(
        ({ label, unitLabel, customFieldID, customFieldTypeID, value, listID }) => (
          <FormControl>
            <FormLabel>{label}</FormLabel>
            <Box
              px="4"
              py="2"
              bg="gray.50"
              borderLeftColor="brand.500"
              borderLeftWidth="1px"
              borderLeftStyle="solid"
              borderRadius="md"
              borderLeftRadius="unset">
              <ReadOnlyRecordField
                unitLabel={unitLabel}
                listID={listID}
                customFieldRecord={{
                  customFieldID,
                  customFieldTypeID,
                  value,
                }}
                isInTable={false}
              />
            </Box>
          </FormControl>
        ),
      )}
    </VStack>
  );
};

export default ReadOnlyRecord;
