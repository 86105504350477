import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  HStack,
  Input,
  ListItem,
  PinInput,
  PinInputField,
  Text,
  UnorderedList,
  VStack,
} from "@chakra-ui/react";
import { Amplify } from "aws-amplify";
import * as React from "react";
import AuthScreenContainer from "./AuthScreenContainer";

type Props = {
  backToSignin: () => void;
};

type State = "enter-email" | "enter-password";

const ForgotPassword = ({ backToSignin }: Props) => {
  const [state, setState] = React.useState<State>("enter-email");
  const [resendTimeout, setResendTimeout] = React.useState<ReturnType<
    typeof setTimeout
  > | null>(null);
  const [code, setCode] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");

  const [loading, setLoading] = React.useState<boolean>(false);
  const [error, setError] = React.useState<
    | {
        error: false;
      }
    | {
        error: true;
        msg: string;
      }
  >({ error: false });

  const handleSendCode = async () => {
    setLoading(true);
    setError({ error: false });
    try {
      await Amplify.Auth.forgotPassword(email);
      setState("enter-password");
    } catch (e) {
      console.error(e);
      setError({
        error: true,
        msg: e instanceof Error ? e.message : "Something went wrong",
      });
    }

    setLoading(false);
  };

  const handleResendCode = async () => {
    if (resendTimeout === null) {
      setResendTimeout(setTimeout(() => setResendTimeout(null), 8000));
    }
    handleSendCode();
  };

  const handleSubmitNewPassword = async () => {
    setLoading(true);
    try {
      await Amplify.Auth.forgotPasswordSubmit(email, code, password);
      backToSignin();
    } catch (e) {
      console.error(e);
      setError({
        error: true,
        msg: e instanceof Error ? e.message : "Something went wrong",
      });
    }
    setLoading(false);
  };

  const isResendDisabled = resendTimeout !== null;

  return (
    <AuthScreenContainer title="Reset your password">
      {state === "enter-password" ? (
        <VStack spacing={6} align="start">
          <Text>
            A verification code has been sent to your email address. It may take
            a few minutes to arrive.
          </Text>
          <VStack spacing={2} w="full" align="start">
            <FormControl isRequired isInvalid={error.error}>
              <FormLabel>Code</FormLabel>
              <HStack>
                <PinInput onChange={(value) => setCode(value)} value={code}>
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                </PinInput>
              </HStack>
            </FormControl>
            <HStack w="full" justify="start">
              {!isResendDisabled && <Text>Didn&apos;t get your code?</Text>}
              <Button
                onClick={handleResendCode}
                isDisabled={isResendDisabled || loading}
                size="sm"
                colorScheme="blue"
                variant="ghost">
                {isResendDisabled ? "Code Sent" : "Resend"}
              </Button>
            </HStack>
          </VStack>
          <VStack spacing={2} w="full" align="start">
            <FormControl isRequired isInvalid={error.error}>
              <FormLabel>Password</FormLabel>
              <Input
                onChange={(e) => setPassword(e.target.value)}
                value={password}
                name="password"
                key="new-password"
                id="new-password"
                type="password"
                autoComplete="new-password"
              />
              {error.error && <FormErrorMessage>{error.msg}</FormErrorMessage>}
            </FormControl>
            <Heading size="xs">Tips for a good password</Heading>
            <UnorderedList stylePosition="inside">
              <ListItem>Use a series of words strung together</ListItem>
              <ListItem>Keep it at least 8 characters long</ListItem>
              <ListItem>Use a password manager</ListItem>
              <ListItem>Don&apos;t re-use or recycle old passwords</ListItem>
              <ListItem>Don&apos;t use all numbers or all letters</ListItem>
              <ListItem>Don&apos;t use single words</ListItem>
            </UnorderedList>
          </VStack>

          <Button
            w="full"
            onClick={handleSubmitNewPassword}
            isLoading={loading}
            loadingText="Updating your password">
            Update Password
          </Button>

          <HStack w="full" justifyContent="end">
            <Button
              size="sm"
              colorScheme="gray"
              variant="link"
              onClick={backToSignin}>
              Back to Sign In
            </Button>
          </HStack>
        </VStack>
      ) : (
        <Box as="form">
          <VStack spacing={6}>
            <FormControl isInvalid={error.error} isRequired>
              <FormLabel>Email</FormLabel>
              <Input
                onChange={(event: any) => {
                  const newEvent = event;
                  newEvent.target.value = event.target.value
                    .trim()
                    .toLowerCase();
                  setEmail(newEvent.target.value);
                }}
                name="email"
                key="email"
                id="email"
                type="email"
              />
              {error.error && <FormErrorMessage>{error.msg}</FormErrorMessage>}
            </FormControl>
            <Button
              onClick={handleSendCode}
              w="full"
              isLoading={loading}
              loadingText="Sending code...">
              Send Code
            </Button>
            <HStack w="full" justifyContent="end">
              <Button
                size="sm"
                colorScheme="gray"
                variant="link"
                onClick={backToSignin}>
                Back to Sign In
              </Button>
            </HStack>
          </VStack>
        </Box>
      )}
    </AuthScreenContainer>
  );
};

export default ForgotPassword;
