import React from "react";
import Select, { components } from "react-select";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";
import { styles } from "./MultiSelect";

interface DraggableSelectProps {
  options: any[];
  placeholder: string;
  value: any[];
  onUpdate: (event: any, action?: any) => void;
  disabled: boolean;
  name: string;
  components?: any;
  readOnly?: boolean;
  clearable?: boolean;
}

const draggableStyles = {
  option: (base: any) => ({ ...base, paddingLeft: "1.25em" }),
};

export const DraggableMultiSelect = (props: DraggableSelectProps) =>
  props.readOnly ? (
    <>{props.value.map((val: any) => val.label).join(", ")}</>
  ) : (
    <DraggableSelect
      // special draggable props
      useDragHandle
      axis="xy"
      onSortEnd={(newOrder: any) =>
        props.onUpdate(
          { ...newOrder, list: props.value },
          { action: "reorder" },
        )
      }
      distance={4}
      getHelperDimensions={({ node }: { node: any }) =>
        node.getBoundingClientRect()
      }
      components={{
        MultiValue: DraggableMultiValue,
        MultiValueLabel: DraggableMultiValueLabel,
        GroupHeading,
      }}
      // standard multi select props
      options={props.options}
      isMulti
      isSearchable
      placeholder={props.placeholder}
      value={props.value}
      onChange={props.onUpdate}
      isDisabled={props.disabled}
      styles={{ ...styles, ...draggableStyles }}
      isClearable={props.clearable || true}
      name={props.name}
      filterOption={customFilter}
    />
  );

const customFilter = (
  option: { label: string; value: string },
  input: string,
) =>
  option.label.toLowerCase().includes(input.toLowerCase()) ||
  input
    .split(" ")
    .every((word) => option.label.toLowerCase().includes(word.toLowerCase()));

const DraggableMultiValue = SortableElement((props: any) => {
  const onMouseDown = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const innerProps = { ...props.innerProps, onMouseDown };

  return <components.MultiValue {...props} innerProps={innerProps} />;
});

const DraggableMultiValueLabel = SortableHandle((props: any) => (
  <MultiValueLabel {...props} />
));

const MultiValueLabel = (props: any) => (
  <span style={{ borderRadius: "10em", maxWidth: "90%" }}>
    <components.MultiValueLabel {...props} />
  </span>
);

const GroupHeading = (props: any) => (
  <div
    style={{
      padding: ".25em .25em .25em .5em",
      backgroundColor: "lightgrey",
      borderRadius: "1em",
      fontSize: "1.2em",
    }}>
    {props.children}
  </div>
);

const DraggableSelect = SortableContainer(Select);
