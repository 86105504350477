import {
  MenuDivider,
  MenuItem,
  MenuList,
  useMediaQuery,
  Icon,
  Portal,
} from "@chakra-ui/react";
import { RiExternalLinkLine } from "react-icons/ri";
import {
  HomeIcon,
  RequirementIcon,
  RiskIcon,
  ReportIcon,
  ManualIcon,
} from "../../../constants/commonIcons";
import ActionNavItem from "./ActionNavItem";
import IssueNavItem from "./IssueNavItem";
import RegisterNavItem from "./RegisterNavItem";
import TopBarButton from "./TopBarButton";
import TopBarMenu from "./TopBarMenu";
import { useAppState } from "../../App/AppProvider";

const breakpoints = [500, 600, 810, 930, 1100, 1240, 1330, 1330].map(
  (bp) => `(min-width: ${bp}px)`,
);
/**
 *
 * @returns 0 to 8, 0 if screen less than 500px, 8 if above 1330px
 */
export const useTopBarNavItemsStage = () => {
  const mediaQuery = useMediaQuery(breakpoints);
  const value = mediaQuery.filter((a) => a).length;
  return value;
};

const TopBarOverflow = () => {
  const { app } = useAppState();
  const numToShow = useTopBarNavItemsStage();
  return (
    <TopBarMenu title={numToShow <= 1 ? "Menu" : "More"}>
      <Portal>
        <chakra-scope>
          <MenuList zIndex={12}>
            {numToShow <= 1 && (
              <>
                <TopBarButton
                  isInOverflow
                  to="/"
                  leftIcon={<Icon boxSize={4} as={HomeIcon} />}>
                  Home
                </TopBarButton>
                <MenuDivider />
              </>
            )}
            {numToShow <= 2 && (
              <TopBarButton
                isInOverflow
                to="/requirement"
                leftIcon={<Icon boxSize="4" as={RequirementIcon} />}>
                Requirements
              </TopBarButton>
            )}
            {numToShow <= 3 && <RegisterNavItem isInOverflow />}
            {numToShow <= 4 && <ActionNavItem isInOverflow />}
            {numToShow <= 5 && <IssueNavItem isInOverflow />}
            {numToShow <= 6 && (
              <TopBarButton
                isInOverflow
                leftIcon={<Icon as={RiskIcon} boxSize={4} />}
                to="risk?display=risks">
                Risk
              </TopBarButton>
            )}
            {numToShow <= 6 && app.features.enabled.includes("manual") && (
              <TopBarButton
                isInOverflow
                leftIcon={<Icon boxSize={4} as={ManualIcon} />}
                to="manual">
                Manual
              </TopBarButton>
            )}
             {numToShow <= 6 && (
              <TopBarButton
                isInOverflow
                leftIcon={<Icon boxSize={4} as={ReportIcon} />}
                to="report?display=reports">
                Reports
              </TopBarButton>
            )}
            {numToShow <= 2 && (
              <>
                <MenuDivider />
                <MenuItem
                  as="a"
                  target="_blank"
                  icon={<Icon as={RiExternalLinkLine} boxSize={4} />}
                  href="https://help.certcrowd.com/using-certcrowd/">
                  Using CertCrowd
                </MenuItem>
              </>
            )}
          </MenuList>
        </chakra-scope>
      </Portal>
    </TopBarMenu>
  );
};

export default TopBarOverflow;
