import {
  Button,
  Center,
  HStack,
  Menu,
  MenuButton, MenuGroup, MenuItem,
  MenuList, Portal,
  Text,
  Tooltip,
  VStack
} from "@chakra-ui/react";
import { Auth } from "aws-amplify";
import React, { useState } from "react";
import { useAppState } from "../../App/AppProvider";
import Spinner from "../../chakra/common/Spinner";
import Icon from "../Icon";

const SidebarAccount = () => {
  const { app } = useAppState();
  const { userAccounts, accountInfo } = app;
  const [status, setStatus] = useState<"changing" | "signingout" | "ready">(
    "ready",
  );
  const accountsToDisplay = userAccounts
    .filter((acc) => acc.accountID !== accountInfo?.accountID)
    .sort((a, b) => a.accountName.localeCompare(b.accountName));
  const currentAccountName = accountInfo?.accountName ?? "(Unknown Account)";

  const handleChangeAccount = async (newAccountID: string) => {
    setStatus("changing");
    try {
      const user = await Auth.currentAuthenticatedUser();
      await Auth.updateUserAttributes(user, {
        "custom:currentAccountID": newAccountID,
      });

      const session = await Auth.currentSession();

      await new Promise((resolve) =>
        user.refreshSession(session.getRefreshToken(), resolve),
      );
      window.location.href = "/";
    } catch (e) {
      console.error(e);
      window.location.reload();
    }
  };

  const handleSignOut = async () => {
    setStatus("signingout");
    window.localStorage.clear();
    try {
      await Auth.signOut();
    } catch (e) {
      console.error(e);
    }
    window.location.reload();
  };
  return (
    <>
      <chakra-scope>
        <VStack px="2" w="full">
          {status === "changing" ? (
            <HStack p="2">
              <Text fontSize="lg">Changing Account...</Text>
              <Spinner size="md" />
            </HStack>
          ) : (
            <HStack p="2" spacing="2" justify="space-between">
              <Center w="full">
                <Text fontSize="lg">{currentAccountName}</Text>
              </Center>
              {accountsToDisplay.length >= 1 ? (
                <Menu placement="top" isLazy size="lg">
                  <Tooltip label="Change Account">
                    <MenuButton variant="ghost" as={Button}>
                      <Icon name="Layers" />
                    </MenuButton>
                  </Tooltip>
                  <Portal>
                  <chakra-scope>
                  <MenuList sx={{ ".chakra-menu__group": { maxHeight: "60vh", overflowX: "auto"} }}>
                    <MenuGroup title="Change Account">
                    {accountsToDisplay.map(({ accountName, accountID }) => (
                      <MenuItem
                        onClick={() => handleChangeAccount(accountID)}
                        key={accountID}>
                        {accountName}
                      </MenuItem>
                    ))}
                    </MenuGroup>
                  </MenuList>
                  </chakra-scope>
                  </Portal>
                </Menu>
              ) : null}
            </HStack>
          )}
          <Button
            isLoading={status === "signingout"}
            loadingText="Logging Out..."
            variant="ghost"
            colorScheme="red"
            onClick={handleSignOut}>
            Log Out
          </Button>
        </VStack>
      </chakra-scope>
    </>
  );
};

export default SidebarAccount;
