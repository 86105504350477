import * as UserAccountStatus from "../shared/constants/userAccountStatus";

const getUserAccountStatus = (id: string): string => {
  if (id === UserAccountStatus.Active) return "Active";
  if (id === UserAccountStatus.Pending) return "Pending";
  if (id === UserAccountStatus.Inactive) return "Inactive";
  return "Active";
};

export { getUserAccountStatus };
