import React from "react";
import { Row, Col, Form } from "react-bootstrap";
import { MultiSelect } from "./MultiSelect";

import { QuestionResponse } from "./Question";
import { User } from "../../../shared/definitions/users";

interface AlertUsersProps {
  readOnly: boolean;
  onUpdate: (event: any) => void;
  InstanceQuestionID: string;
  Responses: QuestionResponse[];
  ResponsibleUsers: User[];
}

const AlertUsers = (props: AlertUsersProps) => {
  if (!props.readOnly) {
    return (
      <Row>
        <Col xs="8" sm="2" style={{ textAlign: "right", paddingRight: "30px" }}>
          <Form.Check.Label className="col-form-label">
            Alerted:
          </Form.Check.Label>
        </Col>
        <Col xs="4" sm="10">
          <MultiSelect
            name={props.InstanceQuestionID}
            onUpdate={props.onUpdate}
            value={formatResponseToValue(
              props.Responses[0].QuestionElementResponseVARCHAR,
              props.ResponsibleUsers,
            )}
            unselectedOption="Select a Value"
            optionsList={formatUsersToOptions(props.ResponsibleUsers)}
            optionsListValue="Name"
            optionsListID="ID"
            readOnly={false}
            readOnlyValue={formatResponseToReadOnlyValue(
              props.Responses[0].QuestionElementResponseVARCHAR,
              props.ResponsibleUsers,
            )}
            visibility
          />
          <span className="form-label-small">
            Users will be notified via email on response submission.
          </span>
        </Col>
      </Row>
    );
  }
  return (
    <Row>
      <Col xs="8" sm="2" style={{ textAlign: "right", paddingRight: "30px" }}>
        <Form.Check.Label className="col-form-label">Alerted:</Form.Check.Label>
      </Col>
      <Col xs="4" sm="10">
        {formatResponseToReadOnlyValue(
          props.Responses[0].QuestionElementResponseVARCHAR,
          props.ResponsibleUsers,
        )}
      </Col>
    </Row>
  );
};

const formatUsersToOptions = (users: any[]): any[] =>
  users.map((user: any) => ({
    Name: `${user.UserFirstName} ${user.UserLastName}`,
    ID: user.UserID,
    Permission: true,
  }));

const formatResponseToValue = (response: string, users: any[]) => {
  if (response) {
    const selectedUsers = response.split(",");
    return users
      .filter((user) => selectedUsers.includes(user.UserID))
      .map((selectedUser) => ({
        Name: `${selectedUser.UserFirstName} ${selectedUser.UserLastName}`,
        ID: selectedUser.UserID,
        Permission: true,
      }));
  }
  return "";
};

const formatResponseToReadOnlyValue = (response: string, users: any[]) => {
  if (response) {
    const selectedUsers = response.split(",");
    let formattedUsers = "";
    selectedUsers.forEach((userID: string) => {
      const userDetails = users.find((user: any) => user.UserID === userID);
      if (userDetails) {
        formattedUsers += `${userDetails.UserFirstName} ${userDetails.UserLastName}, `;
      }
    });
    return formattedUsers.slice(0, -2);
  }
  return "No users selected";
};

export { AlertUsers };
