import { Text, Link, ListItem, UnorderedList } from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import { useApiQuery } from "../../../../utilities/apibelRequest";

type Props = {
  sectionID: string;
};

export function UsageManualSectionsLink({ sectionID }: Props) {
  const linkedItemsQuery = useApiQuery("manual/manyRecordsForLinkedSection", {
    sectionID,
  });

  return (
    <chakra-scope>
      {linkedItemsQuery.isSuccess &&
        (linkedItemsQuery.data.linkedRecords.length <= 0 ? (
          <Text>No linked Records.</Text>
        ) : (
          linkedItemsQuery.data.linkedRecords.map((record) => (
            <UnorderedList>
              <ListItem>
                {/* the display requirements exists for the side-bar, once manual is used in registers will make a statement to determine if this string is applied or not */}
                <Link
                  as={RouterLink}
                  to={`/requirement/${record.recordID}?display=requirements`}
                  key={record.recordID}>
                  {record.readableID} {record.name}
                </Link>
              </ListItem>
            </UnorderedList>
          ))
        ))}
    </chakra-scope>
  );
}
