export const Document = "f8093fad-696e-43e3-a342-5c90d12029f5";
export const Number = "1f91c6ca-c2f0-49a5-9e64-9d0b35770be4";
export const Text = "1f484656-ed84-4f64-b8ad-4af16daee7ab";
export const Date = "78df4d8d-9944-4a04-ba3f-e01739fb0e42";

export const ListTypeIDToName = {
  "f8093fad-696e-43e3-a342-5c90d12029f5": "Document",
  "1f91c6ca-c2f0-49a5-9e64-9d0b35770be4": "Number",
  "1f484656-ed84-4f64-b8ad-4af16daee7ab": "Text",
  "78df4d8d-9944-4a04-ba3f-e01739fb0e42": "Date",
} as const;

export type ListTypeID = keyof typeof ListTypeIDToName;

export type ListType =
  | typeof Document
  | typeof Number
  | typeof Text
  | typeof Date;
