import React from "react";
import { Pagination } from "react-bootstrap";

interface PaginationProps {
  filters: any;
  setFilterDispatch: React.Dispatch<React.SetStateAction<any>>;
  allPaginatedRecords: any[];
  pageKey: string;
}

export const Paginator = (props: PaginationProps) => {
  const numPages = Math.ceil(
    props.allPaginatedRecords.length / props.filters.pageSize,
  );

  return (
    <Pagination>
      {props.filters[props.pageKey] > 1 && numPages > 2 ? (
        <Pagination.First
          onClick={() =>
            props.setFilterDispatch({ ...props.filters, [props.pageKey]: 1 })
          }
        />
      ) : null}
      {props.filters[props.pageKey] > 1 ? (
        <Pagination.Prev
          onClick={() =>
            props.setFilterDispatch({
              ...props.filters,
              [props.pageKey]: props.filters[props.pageKey] - 1,
            })
          }
        />
      ) : null}
      {props.filters[props.pageKey] > 6 ? <Pagination.Ellipsis /> : null}
      {props.allPaginatedRecords.length > 0
        ? Array.from({ length: numPages }).map((value: any, index: number) =>
            index >= props.filters[props.pageKey] - 6 &&
            index <= props.filters[props.pageKey] + 4 ? (
              <Pagination.Item
                key={index}
                active={props.filters[props.pageKey] === index + 1}
                onClick={() =>
                  props.setFilterDispatch({
                    ...props.filters,
                    [props.pageKey]: index + 1,
                  })
                }>
                {index + 1}
              </Pagination.Item>
            ) : null,
          )
        : null}
      {props.filters[props.pageKey] <= numPages - 6 ? (
        <Pagination.Ellipsis />
      ) : null}
      {props.filters[props.pageKey] < numPages ? (
        <Pagination.Next
          onClick={() =>
            props.setFilterDispatch({
              ...props.filters,
              [props.pageKey]: props.filters[props.pageKey] + 1,
            })
          }
        />
      ) : null}
      {props.filters[props.pageKey] < numPages && numPages > 2 ? (
        <Pagination.Last
          onClick={() =>
            props.setFilterDispatch({
              ...props.filters,
              [props.pageKey]: numPages,
            })
          }
        />
      ) : null}
    </Pagination>
  );
};
