import { HStack, MenuDivider, MenuGroup, MenuItem } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { useApiQuery } from "../../../utilities/apibelRequest";
import Icon from "../../UI/Icon";
import Spinner from "../common/Spinner";

const IssueMenuList = () => {
  const issuePages = useApiQuery("issue/pages", null);
  return (
    <>
      <MenuItem as={Link} icon={<Icon name="AlertCircle" />} to="/issue">
        Issues Dashboard
      </MenuItem>
      {issuePages.isSuccess ? (
        issuePages.data.length >= 1 && (
          <>
            <MenuDivider />
            <MenuGroup title="Raise an Issue">
              {issuePages.data.map(({ name, objectTypeID }) => (
                <MenuItem
                  as={Link}
                  icon={<Icon name="Plus" />}
                  color="red.700"
                  to={`/issue/new?type=${objectTypeID}`}
                >
                  New {name}
                </MenuItem>
              ))}
            </MenuGroup>
            {/* <MenuDivider />
              <MenuGroup title="Issue Pages">
                {issuePages.data.map(({ namePlural, objectTypeID, icon }) => (
                  <MenuItem
                    icon={<Icon name={icon} />}
                    onClick={() => navigate(`/issue?filter=${objectTypeID}`)}>
                    {namePlural}
                  </MenuItem>
                ))}
              </MenuGroup> */}
          </>
        )
      ) : issuePages.isError ? (
        <></>
      ) : (
        <>
          <MenuDivider />

          <HStack justify="center" w="full">
            <Spinner size="sm" />
          </HStack>
        </>
      )}
    </>
  );
};

export default IssueMenuList;
