import { HStack, Input, Text, VStack } from "@chakra-ui/react";
import debounce from "lodash/debounce";
import React, { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ObjectTypeGroupIDToName } from "../../constants/objectTypeGroup";
import { useApiQuery } from "../../utilities/apibelRequest";
import Spinner from "../chakra/common/Spinner";
import Icon from "./Icon";
import SearchResult from "./SearchResult";

type Props = {
  onCloseModal: () => void;
};

const QuickSearch = ({ onCloseModal }: Props) => {
  const navigate = useNavigate();
  const [selectedSearchIndex, setSelectedSearchIndex] = useState(0);
  const [search, setSearch] = useState("");
  const [debouncedSearch, setDebouncedSearch] = useState("");
  const handleUpdateSearchDebounced = useMemo(
    () => debounce(setDebouncedSearch, 500),
    [setDebouncedSearch],
  );
  const searchResult = useApiQuery(
    "app/globalSearch",
    {
      query: debouncedSearch,
    },
    {
      keepPreviousData: false,
      enabled: debouncedSearch.length >= 2,
    },
  );

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedSearchIndex(0);
    setSearch(e.target.value);
    handleUpdateSearchDebounced(e.target.value);
  };
  const handleSearchResultHover = (idx: number) => {
    setSelectedSearchIndex(idx);
  };
  const handleSearchKeyDown = (e: React.KeyboardEvent) => {
    if (!searchResult.isSuccess) return;
    if (e.key === "ArrowDown") {
      e.preventDefault();
      setSelectedSearchIndex((prev) => {
        const newIdx = prev + 1;
        if (newIdx >= searchResult.data.length) {
          return 0;
        }
        return newIdx;
      });
    } else if (e.key === "ArrowUp") {
      e.preventDefault();
      setSelectedSearchIndex((prev) => {
        const newIdx = prev - 1;
        if (newIdx < 0) {
          return searchResult.data.length - 1;
        }
        return newIdx;
      });
    } else if (e.key === "Enter") {
      e.preventDefault();
      const item = searchResult.data[selectedSearchIndex];
      if (item) {
        if (item.resultCategory === "task") {
          navigate(`/instance/${item.id}?display=actions`);
        } else {
          const type =
            ObjectTypeGroupIDToName[item.objectTypeGroupID].toLowerCase();
          navigate(`/${type}/${item.id}?display=${type}s`);
        }
        onCloseModal();
      }
    }
  };
  return (
    <VStack spacing="2" w="full" py="2">
      <HStack w="full" py="2" px="1">
        <Icon name="Search" size="lg" />
        <Input
          value={search}
          onChange={handleSearchChange}
          onKeyDown={handleSearchKeyDown}
          size="lg"
          variant="unstyled"
          placeholder="Quick Search"
        />
      </HStack>
      {search === "" ? (
        <></>
      ) : searchResult.isSuccess ? (
        <>
          {searchResult.data.length >= 1 ? (
            (searchResult.data.length >= 6
              ? searchResult.data.slice(0, 8)
              : searchResult.data
            ).map((data, idx) => (
              <SearchResult
                onClick={onCloseModal}
                onMouseEnter={() => handleSearchResultHover(idx)}
                isSelected={idx === selectedSearchIndex}
                key={data.id}
                data={data}
              />
            ))
          ) : (
            <>
              <Text fontSize="md" color="gray.500">
                No results...
              </Text>
            </>
          )}
        </>
      ) : (
        <>
          <HStack p="4">
            <Spinner size="md" />
          </HStack>
        </>
      )}
    </VStack>
  );
};

export default QuickSearch;
