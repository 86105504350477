const saveInLocalStorage = (key: any, formikProps: any) => {
  // Make a deep copy
  // We only want to save the values of fields, not all data
  const dataToSave = trimToFieldValues(JSON.parse(JSON.stringify(formikProps)));

  // We can't save files as they are just too large, strip them out
  if (dataToSave.values.CustomFields) {
    dataToSave.values.CustomFields.forEach((CustomField: any) => {
      if (
        (CustomField.CustomFieldTypeColumnName === "DocumentID" ||
          CustomField.CustomFieldTypeColumnName === "OneDriveDocumentID") &&
        CustomField.Value &&
        CustomField.Value.length !== 0
      ) {
        // last condition so it does not error out on new records
        CustomField.Value.reduceRight(
          (acc: any, document: any, index: number, object: any) => {
            if (document.originFileObj) {
              // This is only true if the file has not yet been uploaded
              object.splice(index, 1);
            }
          },
          [],
        );
      }
    });
  }
  if (dataToSave.values.CustomFieldsForQuestion) {
    dataToSave.values.CustomFieldsForQuestion.forEach((CustomField: any) => {
      if (
        CustomField.CustomFieldTypeColumnName === "DocumentID" ||
        CustomField.CustomFieldTypeColumnName === "OneDriveDocumentID"
      ) {
        CustomField.Value.reduceRight(
          (acc: any, document: any, index: number, object: any) => {
            if (document.originFileObj) {
              // This is only true if the file has not yet been uploaded
              object.splice(index, 1);
            }
          },
          [],
        );
      }
    });
  }

  try {
    window.localStorage.setItem(`${key}`, JSON.stringify(dataToSave.values));
  } catch (e) {
    // error has not been specified due to the variety among browsers
    // data wasn't successfully saved due to quota exceed so delete empty local storage
    window.localStorage.clear();
    // now you can enter the values
    window.localStorage.setItem(`${key}`, JSON.stringify(dataToSave.values));
  }
};

// We want to keep the values of all core fields, custom fields, and questions, but not associated data (users, object types, etc)
const trimToFieldValues = (dataToSave: any) => {
  const trimmedData: any = {
    values: {},
  };
  const fieldObjectTypeGroupName: string =
    dataToSave.values.ObjectTypeGroupName === "Action"
      ? "Action"
      : "Requirement";

  for (const property in dataToSave.values) {
    if (isRequiredFieldValue(property, fieldObjectTypeGroupName)) {
      trimmedData.values[property] = dataToSave.values[property];
    }
  }

  return trimmedData;
};

const isRequiredFieldValue = (
  key: string,
  fieldObjectTypeGroupName: string,
): boolean => {
  // hard coded list of core fields for each page while core fields are not implemented on record pages
  const coreFieldList = {
    // fields not displayed but properties required for storedValuesCheck logic when retrieving storage
    ObjectTypeID: true,
    [`${fieldObjectTypeGroupName}ID`]: true,

    // global fields
    ObjectTypeName: true,
    ParentIDs: true,
    [`${fieldObjectTypeGroupName}Name`]: true,
    [`${fieldObjectTypeGroupName}Description`]: true,
    RequirementResponsibleUserID: true,
    CustomFields: true,

    // risk fields
    RelatedRequirements: true,

    // issue fields
    RiskIDs: true,

    // action fields
    ActionResponsibleUsers: true,
    ActionStartTs: true,
    ActionTimezone: true,
    ActionRecurrenceID: true,
    ActionWindowBefore: true,
    ActionWindowAfter: true,
    Questions: true,

    // instance fields
    InstanceID: true,
  };

  if (coreFieldList[key]) {
    return true;
  }

  return false;
};

export { saveInLocalStorage };
