import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertProps,
  AlertTitle,
} from "@chakra-ui/react";

type Props = {
  title: string;
  children: React.ReactNode;
} & AlertProps;

const CenteredAlert = ({ title, children, ...rest }: Props) => (
  <Alert minH="32" flexDir="column" justifyContent="center" {...rest}>
    <AlertIcon boxSize={6} m="0" />
    <AlertTitle mt="3" mb="2" fontSize="lg">
      {title}
    </AlertTitle>
    <AlertDescription fontSize="sm">{children}</AlertDescription>
  </Alert>
);

export default CenteredAlert;
