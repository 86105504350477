import {
  Box,
  Button,
  Icon,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  StackDivider,
  Tooltip,
  useDisclosure,
  Portal,
  VStack,
} from "@chakra-ui/react";
import { useEffect } from "react";
import { NotificationIcon } from "../../../constants/commonIcons";
import { useApiMutation, useApiQuery } from "../../../utilities/apibelRequest";
import NotificationBadge from "./NotificationBadge";
import NotificationCard from "./NotificationCard";

const SEC = 1000;
const MIN = 60 * SEC;

const useNotifications = () => {
  const notificationQueries = useApiQuery("message/all", null);
  const markAsReadMutation = useApiMutation("message/markRead");
  const markManyAsReadMutation = useApiMutation("message/markManyRead");
  const markManyAsClearedMutation = useApiMutation("message/markManyCleared");
  const markAsClearedMutation = useApiMutation("message/markCleared");

  const refetch = async () => {
    await notificationQueries.refetch();
  };

  const markAsRead = async (id: string) => {
    await markAsReadMutation.mutateAsync({ messageId: id });
    await refetch();
  };

  const markAllAsRead = async () => {
    const messageIds = notificationQueries.data?.messages!.map((m) => m.id);
    if (messageIds && messageIds.length >= 1) {
      await markManyAsReadMutation.mutateAsync({ messageIds });
      await refetch();
    }
  };

  const markAllAsCleared = async () => {
    const messageIds = notificationQueries.data?.messages!.map((m) => m.id);
    if (messageIds?.length) {
      await markManyAsClearedMutation.mutateAsync({ messageIds });
      await refetch();
    }
  };

  const markAsCleared = async (id: string) => {
    await markAsClearedMutation.mutateAsync({ messageId: id });
    await refetch();
  };

  useEffect(() => {
    const timeout = setInterval(() => {
      notificationQueries.refetch();
    }, MIN * 10);
    return () => clearInterval(timeout);
  }, [notificationQueries.data]);

  return {
    notifications: notificationQueries.data?.messages || [],
    markAsRead,
    markAllAsRead,
    markAllAsCleared,
    markAsCleared,
    isLoading:
      notificationQueries.isFetching ||
      markManyAsClearedMutation.isLoading ||
      markAsClearedMutation.isLoading,
  };
};

export const NotificationMenu = () => {
  const { isOpen, onToggle, onClose } = useDisclosure();
  const {
    notifications,
    markAllAsRead,
    markAsCleared,
    markAllAsCleared,
    isLoading,
  } = useNotifications();

  return (
    <div>
      <Popover isLazy isOpen={isOpen} onClose={onClose}>
        <Tooltip label="Notifications">
          <Box display="inline-block">
            <PopoverTrigger>
              <Button
                as={Button}
                variant="ghost"
                size="sm"
                onClick={() => {
                  onToggle();
                  setTimeout(markAllAsRead, 2000);
                }}>
                <NotificationBadge
                  number={
                    notifications.reduce((acc, curr) => {
                      if (curr.status === "unread") {
                        return acc + 1;
                      }
                      return acc;
                    }, 0) || 0
                  }
                />
              </Button>
            </PopoverTrigger>
          </Box>
        </Tooltip>
        <Portal>
          <chakra-scope style={{ zIndex: 12 }}>
            <PopoverContent borderRadius="10">
              <PopoverArrow backgroundColor="primary1.main" />
              <PopoverCloseButton color="white" />
              <PopoverHeader
                borderTopRadius="10"
                backgroundColor="primary1.main"
                color="white"
                fontSize="sm">
                Notifications
              </PopoverHeader>
              <PopoverBody maxHeight="96" overflowY="auto">
                <VStack
                  spacing={4}
                  divider={<StackDivider borderColor="gray.200" />}>
                  {notifications?.length === 0 ? (
                    <Box
                      flexDirection="column"
                      alignItems="center"
                      display="flex"
                      padding="8px"
                      gap="8px"
                      color="gray.500"
                      textAlign="center"
                      fontSize="sm">
                      <Icon boxSize={4} as={NotificationIcon} />
                      No notifications
                    </Box>
                  ) : (
                    notifications?.map((message) => (
                      <NotificationCard
                        key={message.id}
                        notification={{
                          id: message.id,
                          body: message.body,
                          title: message.title,
                          link: message.links,
                          time: new Date(message.time),
                          status: message.status,
                        }}
                        isLoading={isLoading}
                        markAsRead={markAsCleared}
                        onClickButton={onClose}
                      />
                    ))
                  )}
                </VStack>
              </PopoverBody>

              <PopoverFooter>
                <Button
                  onClick={markAllAsCleared}
                  variant="outline"
                  colorScheme="red"
                  isDisabled={!notifications?.length || isLoading}
                  width="full">
                  Clear All
                </Button>
              </PopoverFooter>
            </PopoverContent>
          </chakra-scope>
        </Portal>
      </Popover>
    </div>
  );
};
