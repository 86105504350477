import {
  Alert,
  AlertIcon,
  AlertTitle,
  FormControl,
  FormLabel,
} from "@chakra-ui/react";
import * as CustomFieldTypeID from "../../../../shared/v2/constants/CustomFieldTypeID";
import DateField from "./DateField";
import LongTextfield from "./LongTextField";
import NumberField from "./NumberField";
import ShortTextField from "./ShortTextField";

type Props = {
  name: string;
  isReadOnly?: boolean;
  customFieldTypeID: typeof CustomFieldTypeID.CustomFieldTypeIDs[number];
  fieldProps: {
    label: string;
    unitLabel: string | null;
    isRequired?: boolean;
  };
};

const FormItem = ({ customFieldTypeID, ...otherProps }: Props) => {
  switch (customFieldTypeID) {
    case CustomFieldTypeID.Text:
      return <ShortTextField {...otherProps} />;
    case CustomFieldTypeID.LongText:
      return <LongTextfield {...otherProps} />;
    case CustomFieldTypeID.Number:
      return <NumberField {...otherProps} />;
    case CustomFieldTypeID.Date:
      return <DateField {...otherProps} />;
    default:
      return (
        <FormControl>
          <FormLabel>{otherProps.fieldProps.label}</FormLabel>
          <Alert status="warning">
            <AlertIcon />
            <AlertTitle>Field type not yet implemented</AlertTitle>
          </Alert>
        </FormControl>
      );
  }
};

export default FormItem;
