import { IconButton, Tooltip } from "@chakra-ui/react";

type RichTextButtonProps = {
  onClick: () => void;
  isDisabled?: boolean;
  label: string;
  isActive?: boolean;
  children: React.ReactNode;
};

export function RichTextButton({
  onClick,
  label,
  isActive,
  isDisabled,
  children,
}: RichTextButtonProps) {
  const active = isActive === undefined ? false : isActive;

  return (
    <Tooltip label={label}>
      <IconButton
        onClick={onClick}
        bg={active ? "brand.50" : undefined}
        isDisabled={isDisabled === undefined ? false : isDisabled}
        aria-label={label}>
        {children}
      </IconButton>
    </Tooltip>
  );
}
