import { Button, Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";
import { useState, useEffect } from "react";
import {
  Closed,
  Negative,
  Neutral,
  ObjectStatus,
  ObjectStatusIDToName,
  Positive,
} from "../../../../shared/v2/constants/ObjectStatusID";
import Icon from "../../../UI/Icon";
import { Filters } from "../BaseWidgetProps";
import { createPortal } from "react-dom";

type Props = {
  statusId?: ObjectStatus;
  type?: "tasks" | "actions" | "records";
  patchFilters: (filters: Partial<Filters>) => void;
};

const StatusFilter = ({ type, patchFilters, ...props }: Props) => {
  const [statusId, setStatusId] = useState(props.statusId);

  useEffect(() => {
    setStatusId(props.statusId);
  }, [props.statusId, setStatusId]);

  const body = document.body;

  return (
    <Menu>
      <MenuButton size="sm" rightIcon={<Icon name="ChevronDown" />} as={Button}>
        Status: {statusId ? ObjectStatusIDToName[statusId] : "All"}
      </MenuButton>
      {createPortal(
        <chakra-scope>
          <MenuList zIndex={11}>
            <MenuItem
              onClick={() => {
                setStatusId(undefined);
                patchFilters({ type, statusId: undefined });
              }}
            >
              Any
            </MenuItem>
            <MenuItem
              onClick={() => {
                setStatusId(Positive);
                patchFilters({ type, statusId: Positive });
              }}
            >
              Positive
            </MenuItem>
            <MenuItem
              onClick={() => {
                setStatusId(Neutral);
                patchFilters({ type, statusId: Neutral });
              }}
            >
              Neutral
            </MenuItem>
            <MenuItem
              onClick={() => {
                setStatusId(Negative);
                patchFilters({ type, statusId: Negative });
              }}
            >
              Negative
            </MenuItem>
            <MenuItem
              onClick={() => {
                setStatusId(Closed);
                patchFilters({ type, statusId: Closed });
              }}
            >
              Closed
            </MenuItem>
          </MenuList>
        </chakra-scope>,
        body,
      )}
    </Menu>
  );
};

export default StatusFilter;
