import { useReducer } from "react";
import {
  BlueprintConfig,
  BlueprintConfigQuestion,
  BlueprintState,
  BlueprintStateOrgInfo,
  BlueprintStateQuestion,
} from "../../../shared/v2/definitions/blueprints";

// State stored in the reducer
type BlueprintDraft = {
  state: BlueprintState;
  questions: BlueprintConfigQuestion[];
};

export type BlueprintStateAction =
  | {
      type: "set-state";
      payload: BlueprintDraft;
    }
  | {
      // On change for the org info
      type: "update-org-info";
      payload: {
        key: keyof BlueprintStateOrgInfo;
        value: string;
      };
    }
  | {
      // When finishing the org info section
      type: "complete-org-info";
    }
  | {
      // When updating the state of a question
      type: "update-question-state";
      payload: {
        state: BlueprintStateQuestion;
        questionID: string;
      };
    }
  | {
      type: "back";
    }
  | {
      type: "next-question";
    }
  | {
      type: "init-question-state";
      payload: {
        questionID: string;
      };
    }
  | {
      type: "complete-question";
      payload: {
        resultText?: string;
        resultFile?: {
          fileName: string;
          fileID: string;
        };
        resultType: "text" | "file";
        questionID: string;
      };
    }
  | {
      type: "goto-review";
    };

/**
 * On the front end we store the state and the question config
 */
export const blueprintStateReducer = (
  blueprint: BlueprintDraft | null,
  action: BlueprintStateAction,
): BlueprintDraft | null => {
  switch (action.type) {
    case "set-state":
      return action.payload;
    case "update-org-info":
      if (!blueprint) return blueprint;
      return {
        ...blueprint,
        state: {
          ...blueprint.state,
          organisationInfo: {
            ...blueprint.state.organisationInfo,
            [action.payload.key]: action.payload.value,
          },
        },
      };
    case "complete-org-info":
      if (!blueprint) return blueprint;
      return {
        ...blueprint,
        state: {
          ...blueprint.state,
          step: "questions",
        },
      };
    case "update-question-state":
      if (!blueprint) return blueprint;
      return {
        ...blueprint,
        state: {
          ...blueprint.state,
          questionStates: {
            ...blueprint.state.questionStates,
            [action.payload.questionID]: action.payload.state,
          },
        },
      };
    case "complete-question": {
      if (!blueprint) return blueprint;

      const originalQ =
        blueprint.state.questionStates[action.payload.questionID];
      const questionStates = { ...blueprint.state.questionStates };
      if (originalQ) {
        questionStates[action.payload.questionID] = {
          ...originalQ,
          step: "complete",
          ...(action.payload.resultType === "text" ? {
            answerType: action.payload.resultType,
            answerText: action.payload.resultText,
          } : {
            answerType: action.payload.resultType,
            answerFile: action.payload.resultFile,
          })
        };
      }

      const hasNextQuestion =
        blueprint.state.currentQuestionIdx < blueprint.questions.length - 1;
      return {
        ...blueprint,
        state: {
          ...blueprint.state,
          // If we have a next question we go to it otherwise we go to review step
          currentQuestionIdx: hasNextQuestion
            ? blueprint.state.currentQuestionIdx + 1
            : blueprint.state.currentQuestionIdx,
          step: hasNextQuestion ? blueprint.state.step : "review",
          // Complete the current question
          questionStates,
        },
      };
    }
    case "next-question": {
      if (!blueprint) return blueprint;
      const hasNextQuestion =
        blueprint.state.currentQuestionIdx < blueprint.questions.length - 1;
      return {
        ...blueprint,
        state: {
          ...blueprint.state,
          currentQuestionIdx: hasNextQuestion
            ? blueprint.state.currentQuestionIdx + 1
            : blueprint.state.currentQuestionIdx,
          step: hasNextQuestion ? blueprint.state.step : "review",
        },
      };
      break;
    }
    case "init-question-state": {
      if (!blueprint) return blueprint;
      return {
        ...blueprint,
        state: {
          ...blueprint.state,
          questionStates: {
            ...blueprint.state.questionStates,
            [action.payload.questionID]: {
              step: "initial",
              questionID: action.payload.questionID,
            },
          },
        },
      };
    }
    case "back":
      if (!blueprint) return blueprint;
      // Go back a question or go back to org info
      if (blueprint.state.step === "questions") {
        const currQuestionCfg =
          blueprint.questions[blueprint.state.currentQuestionIdx];
        const currQuestion =
          blueprint.state.questionStates[currQuestionCfg?.questionID || ""];
        if (currQuestion && currQuestion.step !== "initial") {
          // Not initial step, so return to initial step
          return {
            ...blueprint,
            state: {
              ...blueprint.state,

              questionStates: {
                ...blueprint.state.questionStates,
                [currQuestion.questionID]: {
                  ...currQuestion,
                  step: "initial",
                },
              },
            },
          };
        }
        // First question, return to org info step
        if (blueprint.state.currentQuestionIdx === 0) {
          return {
            ...blueprint,
            state: {
              ...blueprint.state,
              step: "organisation",
            },
          };
        }
        // Go to prev question
        return {
          ...blueprint,
          state: {
            ...blueprint.state,
            currentQuestionIdx: blueprint.state.currentQuestionIdx - 1,
          },
        };
      }
      // If were in review, just return to questions
      if (blueprint.state.step === "review") {
        return {
          ...blueprint,
          state: {
            ...blueprint.state,
            step: "questions",
          },
        };
      }
      return blueprint;
    case "goto-review":
      if (!blueprint) return blueprint;
      return {
        ...blueprint,
        state: {
          ...blueprint.state,
          step: "review",
        },
      };
    default:
      return blueprint;
  }
  return blueprint;
};

export const useBlueprintState = () => {
  const state = useReducer(blueprintStateReducer, null);
  return state;
};
