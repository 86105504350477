import queryString from "query-string";
import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { Frown } from "react-feather";
import { Link, useLocation } from "react-router-dom";
import { useAppState } from "../../../components/App/AppProvider";
import Redirect from "../../../components/common/Redirect";
import { put } from "../../../utilities/request";

type HubSpotCallbackStatus = "Loading" | "Ready" | "Failed";

const HubSpotCallback = () => {
  const { auth } = useAppState();
  const location = useLocation();
  const [pageStatus, setPageStatus] = useState<HubSpotCallbackStatus>("Failed");
  const [periods, setPeriods] = useState<string>(".");

  useEffect(() => {
    const updateCallback = async () => {
      setPageStatus("Loading");

      const returnedQueryString = queryString.parse(location.search);

      try {
        await put("settings/hubspot", returnedQueryString.code, auth);
        setPageStatus("Ready");
      } catch (err) {
        setPageStatus("Failed");
      }
    };

    updateCallback();
  }, [auth, location]);

  useEffect(() => {
    const interval = setInterval(() => {
      setPeriods(".".repeat((periods.length % 3) + 1));
    }, 250);
    return () => clearInterval(interval);
  }, []);

  if (pageStatus === "Loading") {
    return (
      <Container>
        <Card style={{ marginTop: "20%", textAlign: "center" }}>
          <Row>
            <Col>
              <h1>We are connecting you to HubSpot now{periods}</h1>
            </Col>
          </Row>
        </Card>
      </Container>
    );
  }
  if (pageStatus === "Ready") {
    return <Redirect to="/settings/integrations" />;
  }
  // (pageStatus === 'Failed')
  return (
    <Container>
      <Card style={{ marginTop: "20%", textAlign: "center" }}>
        <Row style={{ marginBottom: "20px" }}>
          <Col>
            <Frown />
          </Col>
        </Row>
        <Row>
          <Col>
            <h1>Oops! An error occurred</h1>
          </Col>
        </Row>
        <Row>
          <Col>
            <h1>
              <Link to="/settings/integrations">Go back</Link> to the
              integrations page to try again
            </h1>
          </Col>
        </Row>
      </Card>
    </Container>
  );
};

export { HubSpotCallback };
