import { getOpLabel } from "../../../../constants/frontEndFilterHelpers";
import { FilterOperationsType } from "../../../../shared/v2/helpers/filters/filterSchemas";
import Select from "../../../Select";

type Props = {
  operationOptions: FilterOperationsType[];
  isInActiveFilter?: boolean;
  onChange: (newVal: FilterOperationsType | undefined) => void;
  value: FilterOperationsType | undefined;
};

export default function FilterOpSelect({
  operationOptions,
  isInActiveFilter,
  value,
  onChange,
}: Props) {
  return (
    <Select
      w="full"
      singleOptionsDisplayMode="radio"
      value={value}
      onChange={(newVal) => {
        onChange(newVal);
      }}
      getKey={(option) => option.op}
      getLabel={(option) => getOpLabel(option.op)}
      options={operationOptions}
      closeOnSelect
      type={isInActiveFilter ? "simple-button" : "dropdown"}
      placeholderText={
        isInActiveFilter && value ? getOpLabel(value.op) : "Operation"
      }
      simpleButtonProps={{
        borderLeftWidth: "1px",
        borderRightWidth: "1px",
        borderColor: "gray.200",
        color: "blackAlpha.700",
      }}
    />
  );
}
