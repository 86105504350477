import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Center,
  HStack,
} from "@chakra-ui/react";

const MissingPermission = () => (
  <Center pt={8}>
    <Alert flexDir="column" justifyContent="center" py={12} status="error">
      <HStack spacing={0} pb={2}>
        <AlertIcon boxSize={8} />
        <AlertTitle fontSize="2xl">Missing Permissions</AlertTitle>
      </HStack>
      <AlertDescription>
        You don&apos;t have permission to access this.
      </AlertDescription>
    </Alert>
  </Center>
);

export default MissingPermission;
