import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SkeletonText,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useRef } from "react";
import { UsageManualSectionsLink } from "./UsageManualSectionsLink";
import { useApiQuery } from "../../../../utilities/apibelRequest";

type Props = {
  sectionName: string;
  sectionID?: string;
  isOpen: boolean;
  onClose: () => void;
};

export function ViewUsageModal({
  sectionName,
  sectionID,
  isOpen,
  onClose,
}: Props) {
  const initialFocusRef = useRef(null);

  const linkedQuery = useApiQuery(
    "manual/manyRecordsForLinkedSection",
    {
      sectionID,
    },
    {
      enabled: sectionID !== undefined,
    },
  );

  const isLoading = linkedQuery.isLoading;

  return (
    <Modal initialFocusRef={initialFocusRef} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <chakra-scope>
        <ModalContent>
          <ModalHeader>Section Usage</ModalHeader>
          <ModalCloseButton />
          {isLoading ? (
            <ModalBody>
              <SkeletonText
                mt="2"
                noOfLines={3}
                spacing="2"
                skeletonHeight="3"
              />
            </ModalBody>
          ) : (
            linkedQuery.isSuccess &&
            (linkedQuery.data.linkedRecords.length > 0 ? (
              <ModalBody>
                <VStack align="left">
                  <Text>"{sectionName}" is used in the following:</Text>
                  <UsageManualSectionsLink sectionID={sectionID} />
                </VStack>
              </ModalBody>
            ) : (
              <ModalBody>
                "{sectionName}" is not linked to any records.
              </ModalBody>
            ))
          )}
          <ModalFooter />
        </ModalContent>
      </chakra-scope>
    </Modal>
  );
}
