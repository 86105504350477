import { Button, Skeleton, Stack, Text, VStack } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { BreadcrumbElement } from "../../components/chakra/Breadcrumbs";
import { Card, CardHeading, CardContent } from "../../components/chakra/card";
import AccessGuard from "../../components/chakra/AccessGuard";
import Icon from "../../components/UI/Icon";
import * as ObjectTypeGroup from "../../constants/objectTypeGroup";
import * as Request from "../../utilities/request";
import ChakraScreenContainer from "../../components/chakra/ChakraScreenContainer";
import { useAppState } from "../../components/App/AppProvider";

declare global {
  namespace JSX {
    interface IntrinsicElements {
      "chakra-scope": React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement>,
        HTMLElement
      >;
    }
  }
}

const BREADCRUMBS: BreadcrumbElement[] = [
  {
    label: "Home",
    link: "/",
  },
  {
    label: "Registers",
    link: "/register",
  },
];

type PendingStatus = {
  status: "pending";
};
type ErrorStatus = {
  status: "error";
  error: string;
};
type ReadyStatus<T> = {
  status: "ready";
  data: T;
};
type FetchStatus<T> = PendingStatus | ErrorStatus | ReadyStatus<T>;

// Incomplete type for this but yeah
type RegisterObjectType = {
  ObjectTypeCode: string;
  ObjectTypeNamePlural: string;
  ObjectTypeID: string;
  ObjectTypeIcon: string;
};

const MassUploadScreen = () => {
  const {app, auth} = useAppState();
  const isInternal: boolean = Boolean(app.userInfo.isInternal);

  const [registers, setRegisters] = useState<FetchStatus<RegisterObjectType[]>>(
    {
      status: "pending",
    },
  );

  useEffect(() => {
    // Fetch register types
    const fetchRegisterTypes = async () => {
      setRegisters({ status: "pending" });
      try {
        const objTypes = await Request.get(
          `app/objecttypegroup/${ObjectTypeGroup.Register}/objecttype`,
          auth,
        );
        if (objTypes && objTypes.data) {
          setRegisters({ status: "ready", data: objTypes.data.ObjectTypes });
        } else {
          setRegisters({ status: "error", error: "Failed to fetch registers" });
        }
      } catch (e) {
        setRegisters({ status: "error", error: "Failed to fetch registers" });
      }
    };
    fetchRegisterTypes();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <chakra-scope>
      <AccessGuard hasAccess={isInternal}>
        <>
          <ChakraScreenContainer
            breadcrumbs={BREADCRUMBS}
            pageTitle="Register - Mass Upload">
            {registers.status !== "error" ? (
              <Card>
                <CardHeading title="Registers" />
                <CardContent>
                  {registers.status === "pending" ? (
                    <Stack>
                      <Skeleton height="20px" />
                      <Skeleton height="20px" />
                      <Skeleton height="20px" />
                    </Stack>
                  ) : (
                    <VStack align="flex-start" spacing={6}>
                      {registers.data.map(
                        ({
                          ObjectTypeIcon,
                          ObjectTypeNamePlural,
                          ObjectTypeCode,
                          ObjectTypeID,
                        }) => (
                          <Button
                            as={Link}
                            to={`/register/upload/${ObjectTypeID}`}
                            variant="link"
                            leftIcon={<Icon size="lg" name={ObjectTypeIcon} />}>
                            {ObjectTypeNamePlural} [{ObjectTypeCode}]
                          </Button>
                        ),
                      )}
                    </VStack>
                  )}
                </CardContent>
              </Card>
            ) : (
              <Text>Something went wrong - {registers.error}</Text>
            )}
          </ChakraScreenContainer>
        </>
      </AccessGuard>
    </chakra-scope>
  );
};

export default MassUploadScreen;
