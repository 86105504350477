import React from "react";
import { Col, Collapse, Row } from "react-bootstrap";
import { CollapseChevron } from "../CollapseChevron/CollapseChevron";

interface CardHeaderColsProps {
  colProps: object;
  show?: boolean;
  children: React.ReactElement;
}

interface CardProps {
  title: string;
  collapsable: boolean;
  initialCollapsedState?: boolean;
  headerComponent?: (cardProps: any) => React.ReactElement;
  headerCols?: CardHeaderColsProps[];
  headerColsRight?: boolean;
  children: React.ReactElement[] | React.ReactElement;
}

const CardHeaderCols = ({
  show,
  colProps,
  children,
}: CardHeaderColsProps): JSX.Element => {
  if (show === undefined || show === true) {
    return <Col {...colProps}>{children}</Col>;
  }
  return <></>;
};

export const Card = ({
  children,
  collapsable,
  initialCollapsedState,
  headerComponent,
  headerCols,
  headerColsRight,
  title,
}: CardProps): JSX.Element => {
  const [isCollapsed, updateCollapsed] = React.useState<boolean>(
    collapsable
      ? initialCollapsedState === undefined
        ? false
        : initialCollapsedState
      : false,
  );

  let headerRow = null;
  if (headerComponent) {
    headerRow = headerComponent({ isCollapsed, updateCollapsed });
  } else if (headerCols) {
    headerRow = headerCols.map((props: CardHeaderColsProps, index: number) => (
      <CardHeaderCols key={index} {...props} />
    ));
  } else {
    headerRow = <Col />;
  }

  return (
    <div className="card">
      <Row>
        <Col sm={headerColsRight ? undefined : "auto"}>
          <h1>{title}</h1>
        </Col>
        {headerRow}
        <CardHeaderCols show={collapsable} colProps={{ sm: "auto" }}>
          <CollapseChevron
            collapsed={isCollapsed}
            updateCollapsed={updateCollapsed}
          />
        </CardHeaderCols>
      </Row>
      <Collapse in={!isCollapsed}>
        <Row>
          <Col>
            <hr />
            {children}
          </Col>
        </Row>
      </Collapse>
    </div>
  );
};
