import React from "react";
import { HelpCircle } from "react-feather";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Textarea } from "../Field/Textarea";

interface HelpTextProps {
  id: string;
  text: string;
}

export const HelpText = (props: HelpTextProps) => (
  <OverlayTrigger
    placement="auto"
    overlay={
      <Tooltip id={`tooltip-${props.id}`}>
        <Textarea
          value={props.text}
          name={`tooltip-text-${props.id}`}
          readOnly
          richText
        />
      </Tooltip>
    }>
    <HelpCircle size="18" />
  </OverlayTrigger>
);
