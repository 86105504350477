import { Grid, GridItem, useDisclosure } from "@chakra-ui/react";
import { useState } from "react";
import { WidgetRegistry } from "./dashboardConfig";
import {
  DashboardConfig,
  WidgetConfig,
} from "../../shared/v2/definitions/dashboards";
import { Filters, UserInfo } from "./widgets/BaseWidgetProps";
import SystemSearchWidget from "./widgets/SystemSearchWidget";

type Props = {
  dashboardConfig: DashboardConfig;
  users?: UserInfo[];
};

export default function DashboardRenderer({ dashboardConfig, users }: Props) {
  const searchResultsState = useDisclosure({ defaultIsOpen: false });
  const [searchQuery, setSearchQuery] = useState("");
  const [searchFilters, setSearchFilters] = useState<Filters | null>(null);

  const handleChangeSearch = (value: string) => {
    setSearchQuery(value);
    if (!searchResultsState.isOpen) searchResultsState.onOpen();
  };

  const handleChangeFilter = (filters: Filters) => {
    setSearchFilters(filters);
    if (!searchResultsState.isOpen) searchResultsState.onOpen();
  };

  const renderWidget = (widget: WidgetConfig) => {
    if (widget.type === "system-search") {
      return (
        <SystemSearchWidget
          filters={{ searchQuery, searchFilters }}
          setFilters={handleChangeFilter}
          users={users}
          onChangeSearch={handleChangeSearch}
          disclosureState={searchResultsState}
        />
      );
    }
    const Widget = WidgetRegistry[widget.type];
    return (
      <Widget
        users={users}
        title={widget.title}
        widgetID={widget.widgetID}
        dashboardID={dashboardConfig.dashboardID}
        setFilters={handleChangeFilter}
        config={widget.config as any}
      />
    ); // These props are correct at this point but ts cant track it
  };

  return (
    <Grid w="full" templateColumns="repeat(3, 1fr)" gap="2">
      {dashboardConfig.widgets.map((widget) => (
        <GridItem key={widget.widgetID} colSpan={widget.span || 1}>
          {renderWidget(widget)}
        </GridItem>
      ))}
    </Grid>
  );
}
