import React from "react";
import {
    Button, Dropdown,
    DropdownButton,
    OverlayTrigger,
    Tooltip
} from "react-bootstrap";
import * as ObjectTypeGroup from "../../constants/objectTypeGroup";
import * as ObjectStatus from "../../shared/constants/objectStatus";
import * as Permissions from "../../shared/constants/permission";
import * as Access from "../../utilities/access";
import { useAppState } from "../App/AppProvider";


const getObjectTypeGroup = (thisObjectTypeGroupName: string) => {
  switch (thisObjectTypeGroupName) {
    case "issue":
      return ObjectTypeGroup.Issue;
    case "risk":
      return ObjectTypeGroup.Risk;
    case "job":
      return ObjectTypeGroup.Job;
    case "register":
      return ObjectTypeGroup.Register;
    default:
      return ObjectTypeGroup.Requirement;
  }
};

type DisabledCloseButtonProps = {
  reason: string;
  isClosed: boolean;
};

const DisabledCloseButton = ({
  reason,
  isClosed,
}: DisabledCloseButtonProps) => (
  <OverlayTrigger
    placement="auto"
    overlay={<Tooltip id="tooltip-cannot-respond-closed">{reason}</Tooltip>}>
    <Button variant="primary" className="disabled">
      {isClosed ? "Re-Open" : "Close"}
    </Button>
  </OverlayTrigger>
);

type CloseButtonProps = {
  actions: any[];
  requirements: any[];
  objectStatusID: string;
  objectTypeID: string;
  requirementID: string;
  requirementResponsibleUserID: string;
  objectTypeGroupName: string;
  handleClickClose: () => void;
};

const RequirementCloseButton = ({
  handleClickClose,
  actions,
  requirements,
  objectStatusID,
  requirementID,
  objectTypeID,
  requirementResponsibleUserID,
  objectTypeGroupName,
}: CloseButtonProps) => {
  const { app } = useAppState();
  const isClosed = objectStatusID === ObjectStatus.Closed;

  // Check permissions first
  if (
    !Access.checkAccess(
      app.permissions_LEGACY,
      Access.objectTypeGroupIDToName(getObjectTypeGroup(objectTypeGroupName)),
      Permissions.TypeUpdate,
      app.attributes.userID === requirementResponsibleUserID,
      objectTypeID,
    )
  ) {
    return (
      <DisabledCloseButton
        isClosed={isClosed}
        reason={`You do not have permission to ${
          isClosed ? "open" : "close"
        } this.`}
      />
    );
  }

  // At this point if the record is closed, it can be re-opened
  if (isClosed) {
    return (
      <DropdownButton
        id="dropdown-basic-button"
        variant="outline-dark"
        title="Re-Open">
        <Dropdown.Item as="button" onClick={handleClickClose}>
          Confirm Re-Open
        </Dropdown.Item>
      </DropdownButton>
    );
  }

  // Check if theres any open actions or children
  const hasOpenAction =
    actions.findIndex(
      (action) => action.ObjectStatusID !== ObjectStatus.Closed,
    ) >= 0;
  const hasOpenChildren =
    requirements.findIndex(
      (requirement) =>
        // Find the first requirement that lists this as a parent and isnt closed
        (requirement.ParentIDs as any[]).includes(requirementID) &&
        requirement.ObjectStatusID !== ObjectStatus.Closed,
    ) >= 0;
  if (hasOpenAction || hasOpenChildren) {
    return (
      <DisabledCloseButton
        isClosed={isClosed}
        reason={`The dependants of this ${objectTypeGroupName} must first be closed before closing the ${objectTypeGroupName}.`}
      />
    );
  }

  // Everything is fine so allow the user to close
  return (
    <DropdownButton
      id="dropdown-basic-button"
      variant="outline-dark"
      title="Close">
      <Dropdown.Item as="button" onClick={handleClickClose}>
        Confirm Close
      </Dropdown.Item>
    </DropdownButton>
  );
};

export default RequirementCloseButton;
