import {
  Box,
  Button,
  Checkbox,
  CloseButton,
  Container,
  FormLabel,
  Heading,
  Input,
  Select,
  Textarea,
} from "@chakra-ui/react";
import { Field, FieldArray, Form, Formik } from "formik";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Card from "../../components/chakra/card/Card";
import Icon from "../../components/UI/Icon";
import { useApiMutation } from "../../utilities/apibelRequest";
import useToast from "../../utilities/useToast";

type Link = {
  label: string;
  to: string;
  variant: "ghost" | "outline" | "solid";
  isExternal?: boolean;
};

type Notification = {
  title: string;
  body: string;
  type: "transaction" | "promotion";
  links: Link[];
};

const CreateNotification = () => {
  const navigate = useNavigate();
  const { displayToast } = useToast();
  const notifyMutation = useApiMutation("message/notify");

  const loading = notifyMutation.isLoading;

  return (
    <>
      <chakra-scope>
        <Container padding="24px" maxWidth={"780px"}>
          <Box textAlign="center">
            <Heading as="h3">Create Notification</Heading>
          </Box>
          <Formik<Notification>
            initialValues={{
              title: "",
              body: "",
              type: "promotion",
              links: [],
            }}
            onSubmit={async (values, actions) => {
              await notifyMutation.mutateAsync(values);
              displayToast({ title: "Notification sent", status: "success" });
            }}
            render={({ values, handleSubmit, handleChange }) => (
              <Card>
                <Form
                  style={{
                    gap: "8px",
                    display: "flex",
                    flexDirection: "column",
                  }}>
                  <FormLabel>Title</FormLabel>
                  <Input
                    name="title"
                    placeholder="Title"
                    isDisabled={loading}
                    onChange={handleChange}
                    value={values.title}
                  />
                  <FormLabel>Body</FormLabel>
                  <Textarea
                    name="body"
                    placeholder="Body"
                    isDisabled={loading}
                    value={values.body}
                    onChange={handleChange}
                  />
                  <FormLabel>Links</FormLabel>
                  <FieldArray
                    name="links"
                    render={(helpers) => (
                      <div
                        style={{
                          gap: "12px",
                          display: "flex",
                          flexDirection: "column",
                        }}>
                        {values.links?.length > 0 &&
                          values.links.map((l, index) => (
                            <div
                              style={{
                                padding: 8,
                                background: "#fdfdfd",
                                borderRadius: "8px",
                              }}
                              key={`links.${index}`}>
                              <CloseButton
                                float="right"
                                onClick={() => helpers.remove(index)} // remove a friend from the list
                              />
                              <FormLabel>Label</FormLabel>
                              <Input
                                name={`links[${index}].label`}
                                placeholder="label"
                                isDisabled={loading}
                                onChange={handleChange}
                              />
                              <FormLabel>To</FormLabel>
                              <Input
                                name={`links[${index}].to`}
                                placeholder="to"
                                isDisabled={loading}
                                onChange={handleChange}
                              />
                              <FormLabel>Variant</FormLabel>
                              <Select
                                name={`links[${index}].variant`}
                                onChange={handleChange}
                                isDisabled={loading}
                                placeholder="variant"
                                defaultValue="ghost"
                                value={values.links[index]!.variant}>
                                <option value="ghost">Ghost</option>
                                <option value="solid">Solid</option>
                                <option value="outline">Outlined</option>
                              </Select>
                              <FormLabel>Is External?</FormLabel>
                              <Checkbox
                                name={`links[${index}].isExternal`}
                                isDisabled={loading}
                                onChange={handleChange}
                              />
                            </div>
                          ))}
                        <Button
                          isDisabled={loading}
                          onClick={() =>
                            helpers.push({
                              label: "",
                              to: "",
                              variant: "ghost",
                              isExternal: false,
                            })
                          }>
                          Add a link
                        </Button>
                      </div>
                    )}
                  />
                  <FormLabel>Notification Type</FormLabel>
                  <Select
                    name="type"
                    onChange={handleChange}
                    placeholder="Notification Type"
                    defaultValue="promotion"
                    value={values.type}>
                    <option value="promotion">Promotion</option>
                    <option value="transaction">Transaction</option>
                  </Select>
                  {values.type === "promotion" && (
                    <Box>
                      WARNING! `Promotion` notification will send a notification
                      to all users in all accounts! (For testing purposes you
                      can use `Transaction` notification, only you will receive
                      a notification.)
                    </Box>
                  )}
                  <Button type="submit" isLoading={loading}>
                    <Icon name="Send" /> Send
                  </Button>
                </Form>
              </Card>
            )}
          />
        </Container>
      </chakra-scope>
    </>
  );
};

export default CreateNotification;
