import { Box, HStack, Tag, Text, Tooltip } from "@chakra-ui/react";
import React from "react";
import {
  Col,
  Container,
  Dropdown,
  OverlayTrigger,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";
import * as Feather from "react-feather";
import Plot from "react-plotly.js";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAppState } from "../../components/App/AppProvider";
import { Textarea } from "../../components/Forms/Field/Textarea";
import { Card } from "../../components/UI/Card/Card";
import { Header } from "../../components/UI/Header/Header";
import LegacyScreenContainer from "../../components/UI/LegacyScreenContainer";
import { Loading } from "../../components/UI/Loading/Loading";
import { NewObjectTypeButton } from "../../components/UI/NewObjectTypeButton";
import { Paginator } from "../../components/UI/Paginator/Paginator";
import {
  objectStatusToVariant,
  Status,
} from "../../components/UI/Status/Status";
import * as ObjectTypeGroup from "../../constants/objectTypeGroup";
import * as ObjectStatus from "../../shared/constants/objectStatus";
import * as Request from "../../utilities/request";
import { text_stripHTML, text_truncate } from "../../utilities/strings";
import { getUrlSearchParam } from "../../utilities/url";
import useToast from "../../utilities/useToast";

type RiskMatrixMeta = {
  RiskMatrixDimensions: RiskDimension[];
};

type RiskDimension = {
  RiskMatrixDimensionID: string;
  RiskMatrixDimensionName: string;
  RiskMatrixDimensionEdiable: 0 | 1;
  RiskMatrixElements: RiskMatrixElements[];
};

type RiskMatrixElements = {
  RiskMatrixID: string;
  RiskMatrixElementName: string;
  RiskMatrixElementDescription: string;
  RiskMatrixDimensionTypeName: string;
};

type TooltipType = { label: string; description: string };

type RiskMatrixToolTip = Record<string, TooltipType[]>;

const CustomToolTip = (props: { tooltip: string; label: string }) => {
  const ref = React.useRef<HTMLDivElement>(null);
  return (
    <Tooltip label={props.tooltip}>
      <Tag>{props.label}</Tag>
    </Tooltip>
  );
};

// turn hex colors to rgba
const hexToRGBA = (hex: any, alpha: any) => {
  if (hex !== null) {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);

    if (alpha) {
      return `rgba(${r}, ${g}, ${b}, ${alpha})`;
    }
    return `rgba(${r}, ${g}, ${b})`;
  }
  return "";
};

/* used for the division of parts */
const fill = (n: number, x: number) => Array(n).fill(x);
const concat = (xs: any, ys: any) => xs.concat(ys);
const quotrem = (n: number, d: number) => [
  Math.floor(n / d),
  Math.floor(n % d),
];
const distribute = (p: number, d: number, n: number) => {
  const e = Math.pow(10, p);

  const [q, r] = quotrem(n * e, d);

  return concat(fill(r, (q + 1) / e), fill(d - r, q / e));
};
/** */

interface RiskDashboardState {
  requirements?: any[];
  instances?: any[];
  fetchingRequirements?: boolean;
  error?: any;
  riskAnswersWithNewMatrix?: any;
  dimensionNames?: any;
  xValues?: string[];
  yValues?: string[];
}

interface CategoryFilter {
  name: string;
  id: string;
  selected: boolean;
}

interface RiskDashboardFiltersState {
  dimension1: CategoryFilter[];
  selectedD1: string;
  dimension2: CategoryFilter[];
  selectedD2: string;
  importantRisksPage: number;
  riskActionsPage: number;
  pageSize: number;
  categories: CategoryFilter[];
  selectedCategory: string;
}
interface RiskDashboardSeeMore {
  display: boolean;
}
type RiskDashboardPageStatus = "Loading" | "Ready" | "Fetching" | "Error";

const categoryFilter = (
  filters: RiskDashboardFiltersState,
  risk: any,
  riskAnswersWithNewMatrix: any,
) => {
  let isIncluded = false;
  let isIncluded2 = false;
  filters.categories.forEach((category: CategoryFilter) => {
    if (category.selected && risk.ObjectTypeID === category.id) {
      isIncluded = true;
    }
  });
  if (filters.selectedD1 !== "All" && filters.selectedD2 !== "All") {
    riskAnswersWithNewMatrix.map((r: any) => {
      if (
        r[0] === filters.selectedD2 &&
        r[1] === filters.selectedD1 &&
        risk.RequirementID === r[2]
      ) {
        isIncluded2 = true;
      }
    });
    return isIncluded && isIncluded2;
  }
  if (filters.selectedD1 !== "All" || filters.selectedD2 !== "All") {
    riskAnswersWithNewMatrix.map((r: any) => {
      if (
        (r[0] === filters.selectedD2 || r[1] === filters.selectedD1) &&
        risk.RequirementID === r[2]
      ) {
        isIncluded2 = true;
      }
    });
    return isIncluded && isIncluded2;
  }
  return isIncluded;
};

const findParam = (params: string, param: string) => {
  const arr = params.split("?");
  const maybekv = arr.find((pair) => pair.split("=")[0] === param)?.split("=");
  if (maybekv) {
    return {
      key: maybekv[0],
      value: maybekv[1],
    };
  }
  return;
};

const pageFilter = (page: number, pageSize: number, index: number) =>
  index < page * pageSize && index >= (page - 1) * pageSize;

const ScreensRiskDashboard = () => {
  const { app, auth } = useAppState();
  const navigate = useNavigate();
  const location = useLocation();
  const [tooltips, setTooltips] = React.useState<RiskMatrixToolTip>({});
  const [data, setData] = React.useState<RiskDashboardState>({});
  const [filters, setFilters] = React.useState<RiskDashboardFiltersState>({
    dimension1: [],
    selectedD1: "All",
    dimension2: [],
    selectedD2: "All",
    importantRisksPage: 1,
    riskActionsPage: 1,
    pageSize: 10,
    categories: [],
    selectedCategory: "all",
  });
  const [seeMore, setSeeMore] = React.useState<RiskDashboardSeeMore>({
    display: false,
  });
  const [pageStatus, setPageStatus] =
    React.useState<RiskDashboardPageStatus>("Loading");
  const { displayToast } = useToast();
  const templateType = window.location.href.indexOf("template") > -1;
  let D1: CategoryFilter[] = [];
  let D2: CategoryFilter[] = [];
  const isMainPage = !location.search || filters.selectedCategory === "all";
  const input1Ref = React.createRef<HTMLDivElement>();

  const getUrlFilter = (D1m?: any, D2m?: any, categories?: any) => {
    if (!categories) {
      categories = filters.categories;
    }
    const filter = getUrlSearchParam(location.search, "filter");
    let selected = "all";
    const newCategories = categories.map((category: CategoryFilter) => {
      if (category.id === filter) {
        selected = category.name;
        return { name: category.name, id: category.id, selected: true };
      }
      return {
        name: category.name,
        id: category.id,
        selected: !!(!filter || filter === "all"),
      };
    });
    setFilters({
      ...filters,
      importantRisksPage: 1,
      categories: newCategories,
      selectedCategory: selected,
      dimension1: D1m,
      dimension2: D2m,
      selectedD1: "All",
      selectedD2: "All",
    });
  };

  React.useEffect(() => {
    const getData = async () => {
      try {
        setPageStatus("Loading");
        setFilters({ ...filters, selectedD1: "All", selectedD2: "All" });
        setData({ fetchingRequirements: true });

        const [riskObjectTypes, riskMatrix, riskMeta] = await Promise.all([
          Request.get(`app/objecttypegroup/${ObjectTypeGroup.Risk}/objecttype`),
          Request.get(
            `riskMatrix/heatmap${
              location.search.includes("?filter=")
                ? `?filter=${findParam(location.search, "filter")?.value}` ||
                  "?filter=all"
                : "?filter=all"
            }`,
          ),
          Request.get(`settings/riskMatrix`),
        ]);

        const tooltipsData = (
          riskMeta.data as RiskMatrixMeta
        ).RiskMatrixDimensions[2]!.RiskMatrixElements.reduce(
          (a: Record<string, TooltipType[]>, c) => {
            if (
              !a[c.RiskMatrixDimensionTypeName] ||
              !a[c.RiskMatrixDimensionTypeName]?.length
            ) {
              a[c.RiskMatrixDimensionTypeName] = [];
            }
            a[c.RiskMatrixDimensionTypeName]?.push({
              label: c.RiskMatrixElementName,
              description: c.RiskMatrixElementDescription,
            });
            return a;
          },
          {},
        );

        setTooltips(tooltipsData);

        const categories: CategoryFilter[] =
          riskObjectTypes.data.ObjectTypes.map((objectType: any) => ({
            name: objectType.ObjectTypeName,
            id: objectType.ObjectTypeID,
            selected: true,
          }));

        D1 = riskMatrix.data.xValues.map((objectType: any) => ({
          name: objectType,
          selected: false,
        }));
        D2 = riskMatrix.data.yValues.map((objectType: any) => ({
          name: objectType,
          selected: false,
        }));

        getUrlFilter(D1, D2, categories);
        setData((prevData) => ({ ...prevData, ...riskMatrix.data }));

        await Request.getPaginated(`risk`, 30, updateRiskResults);
      } catch (err) {
        setData({
          error: err,
        });
      }
    };
    getData();
  }, [auth, location.search]); // eslint-disable-line

  React.useEffect(() => {
    if (pageStatus === "Ready") {
      getUrlFilter(filters.dimension1, filters.dimension2);
    }
  }, [location.search, location.pathname, data]); // eslint-disable-line

  let interimRiskResults: any[] = [];
  const updateRiskResults = (
    responseData: any,
    status: Request.RequestStatus,
  ) => {
    if (status !== "Error") {
      interimRiskResults = interimRiskResults.concat(responseData.Requirements);
      setData((prevData) => ({
        ...prevData,
        requirements: interimRiskResults,
        fetchingRequirements: status === "Fetching",
      }));
    } else {
      displayToast({
        status: "error",
        title: `Failed to retrieve Issues`,
      });
      setData((prevData) => ({ ...prevData, fetchingRequirements: false }));
    }
    setPageStatus(status);
  };

  // Important Risks Card Filters and Pagination
  let filteredImportantRisks: any[] = data.requirements
    ? data.requirements
    : [];
  let paginatedFilteredImportantRisks: any[] = [];
  if (filteredImportantRisks.length > 0) {
    if (isMainPage) {
      // Filter based off Negative Object Status and Permission
      filteredImportantRisks = filteredImportantRisks.filter(
        (risk: any) =>
          risk.ObjectStatusID === ObjectStatus.Negative &&
          (risk.Permission || risk.ActionIDs.length > 0),
      );
    } else {
      // Filter based off Category and Permission
      filteredImportantRisks = filteredImportantRisks.filter(
        (risk: any) =>
          categoryFilter(filters, risk, data.riskAnswersWithNewMatrix) &&
          (risk.Permission || risk.ActionIDs.length > 0),
      );
    }

    // Pagination
    paginatedFilteredImportantRisks = filteredImportantRisks.filter(
      (risk: any, index: number) =>
        pageFilter(filters.importantRisksPage, filters.pageSize, index),
    );
  }

  // Risk Card Filters and Pagination
  let filteredRisks: any[] = data.requirements ? data.requirements : [];
  let paginatedFilteredRisks: any[] = [];
  if (filteredRisks.length > 0) {
    // Filter based off Category and Permission
    filteredRisks = filteredRisks.filter(
      (risk: any) =>
        categoryFilter(filters, risk, data.riskAnswersWithNewMatrix) &&
        (risk.Permission || risk.ActionIDs.length > 0),
    );

    // Pagination
    paginatedFilteredRisks = filteredRisks.filter((risk: any, index: number) =>
      pageFilter(filters.riskActionsPage, filters.pageSize, index),
    );
  }

  const rederSeeMore = (
    data: any,
    seeMore: RiskDashboardSeeMore,
    setSeeMore: React.Dispatch<React.SetStateAction<RiskDashboardSeeMore>>,
  ) => {
    if (seeMore.display === false) {
      return (
        <button
          type="button"
          className="linkButton"
          onClick={() => setSeeMore({ display: true })}>
          {" "}
          {"\u00A0"}See more ({data.oldRiskMatrixAnswers.length})...
        </button>
      );
    }
    return data.oldRiskMatrixAnswers.map((el: any, index: number) => {
      if (index !== data.oldRiskMatrixAnswers.length - 1) {
        return (
          <Link to={`/action/${el.ActionID}?display=risks`}>
            {" "}
            {el.ActionName},
          </Link>
        );
      }
      return (
        <Link to={`/action/${el.ActionID}?display=risks`}>
          {" "}
          {el.ActionName}
        </Link>
      );
    });
  };

  const getHeatmap = (data: any) => {
    const ColorPerRisk2: any = [];
    data.NumberPerRisk.map((el: any, index: number) => {
      data.ColorPerRisk.map((el3: any) => {
        if (el[0] === el3[0]) {
          ColorPerRisk2.push([el[0], el3[1]]);
        }
      });
    });
    const ColorscaleValues: any = [];
    let max: number = -1;
    const colors: any = [];
    for (let i = 0; i < data.NumberPerRisk.length; i++) {
      if (max < data.NumberPerRisk[i][1]) {
        max = parseInt(data.NumberPerRisk[i][1]);
      }
    }
    for (let i = 0; i < data.ColorPerRisk.length; i++) {
      if (!colors.includes(data.ColorPerRisk[i][1])) {
        colors.push(data.ColorPerRisk[i][1]);
      }
    }
    const colorscale: any = [];
    const newdiv = distribute(3, data.NumberPerRisk.length, 1)[0];
    ColorPerRisk2.map((el: any, index: number) => {
      const alpha =
        data.NumberPerRisk[index]?.[1] === max
          ? 1
          : data.NumberPerRisk[index]?.[1] !== 0
          ? data.NumberPerRisk[index]?.[1]
          : 0.25;
      if (index === 0) {
        colorscale[index] = [0, hexToRGBA(el[1], alpha)];
        ColorscaleValues.push([data.NumberPerRisk[index]?.[0], 0]);
      } else if (index === ColorPerRisk2.length - 1) {
        colorscale[index] = [1, hexToRGBA(el[1], alpha)];
        ColorscaleValues.push([data.NumberPerRisk[index]?.[0], 1]);
      } else {
        colorscale[index] = [
          parseFloat((colorscale[index - 1][0] + newdiv).toPrecision(6)),
          hexToRGBA(el[1], alpha),
        ];
        ColorscaleValues.push([
          data.NumberPerRisk[index]?.[0],
          parseFloat((colorscale[index - 1][0] + newdiv).toPrecision(6)),
        ]);
      }
    });
    // creating the zMatrix
    const getZ = (ColorscaleValues: any) => {
      const zMatrix: any[][] = [];
      let i = 0;
      while (i < data.xValues.length * data.yValues.length) {
        const temp: any = [];
        for (let j = 0; j < data.xValues.length; j++) {
          temp.push(ColorscaleValues[i + j][1]);
        }
        i += data.xValues.length;
        zMatrix.push(temp);
      }
      return zMatrix;
    };
    const numbersMAtrix = [];
    let j = 0;
    while (j < data.xValues.length * data.xValues.length) {
      const temp: any = [];
      const temp2: any = [];
      for (let i = 0; i < data.yValues.length; i++) {
        temp.push(data.NumberPerRisk[i + j][1]);
      }
      j += data.xValues.length;
      numbersMAtrix.push(temp);
    }
    const dataPlot = [
      {
        x: data.xValues,
        y: data.yValues,
        z: getZ(ColorscaleValues),
        type: "heatmap",
        hoverinfo: `none`,
        colorscale,
        showscale: false,
      },
    ];
    const result: any = [];

    for (let j = data.yValues.length - 1; j > -1; j--) {
      for (let i = 0; i < data.xValues.length; i++) {
        let textValue = data.matrixRisk[j][i];
        textValue = textValue.length > 12 ? textValue.substr(0, 12) : textValue;
        const textColor = "white";
        result.push({
          xref: "x1",
          yref: "y1",
          x: data.xValues[i],
          y: data.yValues[j],
          text: `<b>(${numbersMAtrix[j][i]})<br />${textValue}</b>`,
          font: {
            family: "Open Sans, sans-serif",
            size: 14,
            backgroundcolor: "rgb(50, 171, 96)",
            color: textColor,
          },
          showarrow: false,
        });
      }
    }
    const layout = {
      annotations: result,
      xaxis: {
        ticks: "",
        side: "top",
        tickangle: 25,
        fixedrange: true,
        showgrid: false,
        automargin: true,
        tickfont: {
          family: "Open Sans, sans-serif",
          size: 13,
          color: "rgb(82, 82, 82)",
        },
      },
      yaxis: {
        ticks: "",
        ticksuffix: " ",
        fixedrange: false,
        showgrid: false,
        automargin: true,
        tickfont: {
          family: "Open Sans, sans-serif",
          size: 13,
          color: "rgb(82, 82, 82)",
        },
      },
      autosize: true,
      // width: 600,
      // height: 500,
      hovermode: "closest",
      clickmode: true,
    };
    return (
      <div>
        <Text>
          <b>Legend</b>* (Hover legend for description)
        </Text>
        <HStack spacing={2}>
          <Box>Risk Levels:</Box>
          {tooltips["Risk Level"]?.map((tooltip) => {
            return (
              <CustomToolTip
                tooltip={tooltip.description}
                label={tooltip.label}
              />
            );
          })}
        </HStack>
        <Plot
          data={dataPlot}
          layout={layout}
          onClick={(e: any) => {
            const newDim1 = filters.dimension1.map((category: CategoryFilter) =>
              category.name === e.points[0].x
                ? { name: category.name, id: category.id, selected: true }
                : {
                    name: category.name,
                    id: category.id,
                    selected: e.points[0].x === "All",
                  },
            );
            const newDim2 = filters.dimension2.map((category: CategoryFilter) =>
              category.name === e.points[0].y
                ? { name: category.name, id: category.id, selected: true }
                : {
                    name: category.name,
                    id: category.id,
                    selected: e.points[0].y === "All",
                  },
            );
            // set focus
            if (input1Ref && input1Ref.current) {
              input1Ref.current.scrollIntoView();
            }
            setFilters({
              ...filters,
              dimension1: newDim1,
              dimension2: newDim2,
              selectedD1: e.points[0].x,
              selectedD2: e.points[0].y,
            });
          }}
        />
        <div style={{ marginTop: "25px", fontSize: "14px" }}>
          <Row>
            <Col>
              <i>
                {data.oldRiskMatrixAnswers.length > 0 ? (
                  <div>
                    <span style={{ color: "rgba(0, 0, 0, 0.7)" }}>
                      There are risk assessments based on old versions of the
                      Risk Matrix.
                    </span>
                    {rederSeeMore(data, seeMore, setSeeMore)}{" "}
                  </div>
                ) : null}
              </i>
            </Col>
          </Row>
        </div>
      </div>
    );
  };

  return (
    <LegacyScreenContainer pageTitle="Risks Dashboard" breadcrumbs={[]}>
      <>
        {data.requirements || data.dimensionNames ? (
          <>
            {/* <Header
            breadcrumbs={[
              { title: "Home", link: "/" },
              { title: "Risk Dashboard" },
            ]}
            title="Risk Dashboard"
            buttonProps={{}}
          /> */}

            {/* //insert heatmap */}
            <Card
              title="Risk Heat Map"
              collapsable={false}
              headerCols={[{ colProps: {}, children: <span /> }]}>
              {pageStatus === "Ready" || pageStatus === "Fetching" ? (
                getHeatmap(data)
              ) : (
                <div style={{ textAlign: "center" }}>
                  {" "}
                  <Spinner animation="border" />{" "}
                </div>
              )}
            </Card>

            <Card
              title={
                isMainPage ? "Important Risks" : `${filters.selectedCategory}s`
              }
              collapsable={false}
              headerCols={
                isMainPage
                  ? [
                      { colProps: {}, children: <span /> },
                      {
                        colProps: { sm: "auto" },
                        children: (
                          <NewObjectTypeButton
                            variant="primary"
                            title="Create New Risk"
                            header="New Risk"
                            objectTypeGroupID={ObjectTypeGroup.Risk}
                            type={1}
                            parentType={ObjectTypeGroup.Risk}
                            template={templateType}
                          />
                        ),
                      },
                    ]
                  : [
                      { colProps: {}, children: <span /> },
                      {
                        colProps: { sm: "auto" },
                        children: data.dimensionNames && (
                          <Dropdown
                            id="dropdown-basic-button"
                            alignRight
                            onSelect={(eventKey: any) => {
                              const newCategories = filters.dimension1.map(
                                (category: CategoryFilter) =>
                                  category.name === eventKey
                                    ? {
                                        name: category.name,
                                        id: category.id,
                                        selected: true,
                                      }
                                    : {
                                        name: category.name,
                                        id: category.id,
                                        selected: eventKey === "All",
                                      },
                              );
                              setFilters({
                                ...filters,
                                riskActionsPage: 1,
                                dimension1: newCategories,
                                selectedD1: eventKey,
                              });
                            }}>
                            <Dropdown.Toggle
                              variant="secondary"
                              id="dropdown-riskcategory">
                              {`${data.dimensionNames.d1}: ${filters.selectedD1}`}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item eventKey="All">All</Dropdown.Item>
                              {filters.dimension1.map(
                                (category: CategoryFilter, index: number) => (
                                  <Dropdown.Item
                                    key={index}
                                    eventKey={category.name}>
                                    {category.name}
                                  </Dropdown.Item>
                                ),
                              )}
                            </Dropdown.Menu>
                          </Dropdown>
                        ),
                      },
                      {
                        colProps: { sm: "auto" },
                        children: data.dimensionNames && (
                          <Dropdown
                            id="dropdown-basic-button"
                            alignRight
                            onSelect={(eventKey: any) => {
                              const newCategories = filters.dimension2.map(
                                (category: CategoryFilter) =>
                                  category.name === eventKey
                                    ? {
                                        name: category.name,
                                        id: category.id,
                                        selected: true,
                                      }
                                    : {
                                        name: category.name,
                                        id: category.id,
                                        selected: eventKey === "All",
                                      },
                              );
                              setFilters({
                                ...filters,
                                riskActionsPage: 1,
                                dimension2: newCategories,
                                selectedD2: eventKey,
                              });
                            }}>
                            <Dropdown.Toggle
                              variant="secondary"
                              id="dropdown-riskcategory">
                              {`${data.dimensionNames.d2}: ${filters.selectedD2}`}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item eventKey="All">All</Dropdown.Item>
                              {filters.dimension2.map(
                                (category: CategoryFilter, index: number) => (
                                  <Dropdown.Item
                                    key={index}
                                    eventKey={category.name}>
                                    {category.name}
                                  </Dropdown.Item>
                                ),
                              )}
                            </Dropdown.Menu>
                          </Dropdown>
                        ),
                      },
                      {
                        colProps: { sm: "auto" },
                        children: (
                          <Dropdown
                            id="dropdown-basic-button"
                            alignRight
                            onSelect={(eventKey: any) => {
                              const newCategories = filters.categories.map(
                                (category: CategoryFilter) =>
                                  category.name === eventKey
                                    ? {
                                        name: category.name,
                                        id: category.id,
                                        selected: true,
                                      }
                                    : {
                                        name: category.name,
                                        id: category.id,
                                        selected: eventKey === "all",
                                      },
                              );
                              setFilters({
                                ...filters,
                                riskActionsPage: 1,
                                categories: newCategories,
                                selectedCategory: eventKey,
                              });
                              if (location.search) {
                                navigate(
                                  `risk?filter=${eventKey
                                    .toLowerCase()
                                    .replace(/\s/g, "")}`,
                                );
                              }
                            }}
                          />
                        ),
                      },
                      {
                        colProps: { sm: "auto" },
                        children: (
                          <NewObjectTypeButton
                            variant="primary"
                            title="Create New Risk"
                            header="New Risk"
                            objectTypeGroupID={ObjectTypeGroup.Risk}
                            type={1}
                            parentType={ObjectTypeGroup.Risk}
                          />
                        ),
                      },
                    ]
              }>
              {pageStatus === "Ready" || pageStatus === "Fetching" ? (
                <Table responsive borderless>
                  <thead>
                    <tr>
                      <th />
                      <th>Name</th>
                      <th>Risk Type</th>
                      <th>Status</th>
                      <th>Description</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.requirements &&
                    paginatedFilteredImportantRisks.length > 0 ? (
                      paginatedFilteredImportantRisks.map(
                        (requirement: any, index: number) => {
                          const IconType: keyof typeof Feather =
                            requirement.ObjectTypeIcon;
                          const Icon = Feather[IconType];
                          return (
                            <tr key={index}>
                              <td>
                                <Icon />
                              </td>
                              <td>
                                <Link
                                  to={`/risk/${requirement.RequirementID}?display=risks`}>
                                  {requirement.RequirementReadableID} -{" "}
                                  {requirement.RequirementName}
                                </Link>
                              </td>
                              <td>{requirement.ObjectTypeName}</td>
                              <td>
                                <Status
                                  placement="left"
                                  variant={objectStatusToVariant(
                                    requirement.ObjectStatusID,
                                  )}
                                  text={requirement.ObjectStatusName}
                                  tooltip={requirement.ObjectStatusDescription}
                                />
                              </td>
                              {requirement.RequirementDescription.length >
                              25 ? (
                                <OverlayTrigger
                                  placement="left"
                                  overlay={
                                    <Tooltip
                                      id={requirement.RequirementDescription}>
                                      <Textarea
                                        value={
                                          requirement.RequirementDescription
                                        }
                                        name={`RiskDescription${index}`}
                                        readOnly
                                        richText
                                      />
                                    </Tooltip>
                                  }>
                                  <td style={{ whiteSpace: "nowrap" }}>
                                    <Textarea
                                      value={text_truncate(
                                        text_stripHTML(
                                          requirement.RequirementDescription,
                                        ),
                                        25,
                                      )}
                                      name={`RiskDescription${index}`}
                                      readOnly
                                      richText
                                    />
                                  </td>
                                </OverlayTrigger>
                              ) : (
                                <Textarea
                                  value={requirement.RequirementDescription}
                                  name="RiskDescription"
                                  readOnly
                                  richText
                                />
                              )}
                            </tr>
                          );
                        },
                      )
                    ) : data.requirements ? (
                      <tr>
                        <td />
                        <td colSpan={4}>No Risks</td>
                      </tr>
                    ) : null}
                  </tbody>
                </Table>
              ) : (
                <div style={{ textAlign: "center" }}>
                  <Spinner animation="border" />{" "}
                </div>
              )}
              <Row className="justify-content-sm-center">
                <Col sm="auto">
                  <Paginator
                    filters={filters}
                    setFilterDispatch={setFilters}
                    allPaginatedRecords={filteredImportantRisks}
                    pageKey="importantRisksPage"
                  />
                </Col>
              </Row>
            </Card>
            <div ref={input1Ref} />
            {isMainPage && (
              <Card
                title="Risks"
                collapsable={false}
                headerColsRight
                headerCols={[
                  {
                    colProps: { sm: "auto" },
                    children: (
                      <Dropdown
                        id="dropdown-basic-button"
                        alignRight
                        onSelect={(eventKey: any) => {
                          const newCategories = filters.dimension1.map(
                            (category: CategoryFilter) =>
                              category.name === eventKey
                                ? {
                                    name: category.name,
                                    id: category.id,
                                    selected: true,
                                  }
                                : {
                                    name: category.name,
                                    id: category.id,
                                    selected: eventKey === "All",
                                  },
                          );
                          setFilters({
                            ...filters,
                            riskActionsPage: 1,
                            dimension1: newCategories,
                            selectedD1: eventKey,
                          });
                        }}>
                        <Dropdown.Toggle
                          variant="secondary"
                          id="dropdown-riskcategory">
                          {`${data.dimensionNames.d1}: ${filters.selectedD1}`}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item eventKey="All">All</Dropdown.Item>
                          {filters.dimension1.map(
                            (category: CategoryFilter, index: number) => (
                              <Dropdown.Item
                                key={index}
                                eventKey={category.name}>
                                {category.name}
                              </Dropdown.Item>
                            ),
                          )}
                        </Dropdown.Menu>
                      </Dropdown>
                    ),
                  },
                  {
                    colProps: { sm: "auto" },
                    children: (
                      <Dropdown
                        id="dropdown-basic-button"
                        alignRight
                        onSelect={(eventKey: any) => {
                          const newCategories = filters.dimension2.map(
                            (category: CategoryFilter) =>
                              category.name === eventKey
                                ? {
                                    name: category.name,
                                    id: category.id,
                                    selected: true,
                                  }
                                : {
                                    name: category.name,
                                    id: category.id,
                                    selected: eventKey === "All",
                                  },
                          );
                          setFilters({
                            ...filters,
                            riskActionsPage: 1,
                            dimension2: newCategories,
                            selectedD2: eventKey,
                          });
                        }}>
                        <Dropdown.Toggle
                          variant="secondary"
                          id="dropdown-riskcategory">
                          {`${data.dimensionNames.d2}: ${filters.selectedD2}`}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item eventKey="All">All</Dropdown.Item>
                          {filters.dimension2.map(
                            (category: CategoryFilter, index: number) => (
                              <Dropdown.Item
                                key={index}
                                eventKey={category.name}>
                                {category.name}
                              </Dropdown.Item>
                            ),
                          )}
                        </Dropdown.Menu>
                      </Dropdown>
                    ),
                  },
                  {
                    colProps: { sm: "auto" },
                    children: (
                      <Dropdown
                        id="dropdown-basic-button"
                        alignRight
                        onSelect={(eventKey: any) => {
                          const newCategories = filters.categories.map(
                            (category: CategoryFilter) =>
                              category.name === eventKey
                                ? {
                                    name: category.name,
                                    id: category.id,
                                    selected: true,
                                  }
                                : {
                                    name: category.name,
                                    id: category.id,
                                    selected: eventKey === "all",
                                  },
                          );
                          setFilters({
                            ...filters,
                            riskActionsPage: 1,
                            categories: newCategories,
                            selectedCategory: eventKey,
                          });
                          if (location.search) {
                            navigate(
                              `risk?filter=${eventKey
                                .toLowerCase()
                                .replace(/\s/g, "")}`,
                            );
                          }
                        }}>
                        <Dropdown.Toggle
                          variant="secondary"
                          id="dropdown-riskcategory">
                          Risk Type:{" "}
                          {filters.selectedCategory
                            .charAt(0)
                            .toUpperCase()
                            .concat(filters.selectedCategory.slice(1))}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item eventKey="all">All</Dropdown.Item>
                          {filters.categories.map(
                            (category: CategoryFilter, index: number) => (
                              <Dropdown.Item
                                key={index}
                                eventKey={category.name}>
                                {category.name}
                              </Dropdown.Item>
                            ),
                          )}
                        </Dropdown.Menu>
                      </Dropdown>
                    ),
                  },
                ]}>
                <Row>
                  <Col>
                    <Table responsive borderless>
                      <thead>
                        <tr>
                          <th />
                          <th>Name</th>
                          <th>Risk Type</th>
                          <th>Status</th>
                          <th>Description</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.requirements &&
                        paginatedFilteredRisks.length > 0 ? (
                          paginatedFilteredRisks.map(
                            (requirement: any, index: number) => {
                              const IconType: keyof typeof Feather =
                                requirement.ObjectTypeIcon;
                              const Icon = Feather[IconType];
                              return (
                                <tr key={index}>
                                  <td>
                                    <Icon />
                                  </td>
                                  <td>
                                    <Link
                                      to={`/risk/${requirement.RequirementID}?display=risks`}>
                                      {requirement.RequirementReadableID} -{" "}
                                      {requirement.RequirementName}
                                    </Link>
                                  </td>
                                  <td>{requirement.ObjectTypeName}</td>
                                  <td>
                                    <Status
                                      placement="left"
                                      variant={objectStatusToVariant(
                                        requirement.ObjectStatusID,
                                      )}
                                      text={requirement.ObjectStatusName}
                                      tooltip={
                                        requirement.ObjectStatusDescription
                                      }
                                    />
                                  </td>
                                  {requirement.RequirementDescription.length >
                                  25 ? (
                                    <OverlayTrigger
                                      placement="left"
                                      overlay={
                                        <Tooltip
                                          id={
                                            requirement.RequirementDescription
                                          }>
                                          <Textarea
                                            value={
                                              requirement.RequirementDescription
                                            }
                                            name={`RequirementDescription${index}`}
                                            readOnly
                                            richText
                                          />
                                        </Tooltip>
                                      }>
                                      <td style={{ whiteSpace: "nowrap" }}>
                                        <Textarea
                                          value={text_truncate(
                                            text_stripHTML(
                                              requirement.RequirementDescription,
                                            ),
                                            25,
                                          )}
                                          name={`RequirementDescription${index}`}
                                          readOnly
                                          richText
                                        />
                                      </td>
                                    </OverlayTrigger>
                                  ) : (
                                    <td>
                                      <Textarea
                                        value={
                                          requirement.RequirementDescription
                                        }
                                        name={`RequirementDescription${index}`}
                                        readOnly
                                        richText
                                      />
                                    </td>
                                  )}
                                </tr>
                              );
                            },
                          )
                        ) : data.requirements ? (
                          <tr>
                            <td />
                            <td colSpan={4}>No Risks</td>
                          </tr>
                        ) : null}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
                {data.fetchingRequirements ? (
                  <Row className="justify-content-sm-center">
                    <Col sm="auto">
                      <Loading size={"md"} />
                    </Col>
                  </Row>
                ) : (
                  <div />
                )}
                <Row className="justify-content-sm-center">
                  <Col sm="auto">
                    <Paginator
                      filters={filters}
                      setFilterDispatch={setFilters}
                      allPaginatedRecords={filteredRisks}
                      pageKey="riskActionsPage"
                    />
                  </Col>
                </Row>
              </Card>
            )}
          </>
        ) : (
          <>
            <div className="progress-spinner" style={{ marginTop: "20%" }}>
              <Loading size={"xl"} />
            </div>
          </>
        )}
      </>
    </LegacyScreenContainer>
  );
};

export { ScreensRiskDashboard };
