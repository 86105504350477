import _ from "lodash";
import assertNever from "./assertNever";
import * as ActionRecurrenceID from "../shared/v2/constants/ActionRecurrenceID";

/**
 * Converts an ActionRecurrenceID and SendOn into readable text
 */
const getOrdinal = (i: number) => {
  const j = i % 10;
  const k = i % 100;
  if (j === 1 && k !== 11) {
    return `${i}st`;
  }
  if (j === 2 && k !== 12) {
    return `${i}nd`;
  }
  if (j === 3 && k !== 13) {
    return `${i}rd`;
  }
  return `${i}th`;
};

const WeeklyOptions = [
  { key: 2, label: "Monday" },
  { key: 3, label: "Tuesday" },
  { key: 4, label: "Wednesday" },
  { key: 5, label: "Thursday" },
  { key: 6, label: "Friday" },
  { key: 7, label: "Saturday" },
  {
    key: 1,
    label: "Sunday",
  },
]; // Sunday is last in list but 1st in week

const MonthlyOptions = _.range(1, 32).map((val) => ({
  key: val,
  label: getOrdinal(val),
}));

const convertDay = (day: number) => {
  const found = WeeklyOptions.find((opt) => opt.key === day);
  if (found) return found.label;
  return "Unknown Date";
};

export const BasicRecurrences = [
  {
    key: ActionRecurrenceID.Daily,
    label: "Daily",
  },
  {
    key: ActionRecurrenceID.Weekly,
    label: "Weekly",
  },
  {
    key: ActionRecurrenceID.Monthly,
    label: "Monthly",
  },
  {
    key: ActionRecurrenceID.Quarterly,
    label: "Quarterly",
  },
];

export const getFrequencyLabel = (
  key: ActionRecurrenceID.NotificationRecurrenceIDs,
) => {
  return (
    BasicRecurrences.find((recurrence) => recurrence.key === key)?.label || ""
  );
};

export const getRecurrenceText = (
  recurrenceID:
    | ActionRecurrenceID.NotificationRecurrenceIDs
    | ActionRecurrenceID.OnceOffActionRecurrenceIDs,
  sendOn: number,
) => {
  switch (recurrenceID) {
    case ActionRecurrenceID.Daily:
      return ["Daily", "", ""];
    case ActionRecurrenceID.Weekly:
      return ["Weekly", "on a", convertDay(sendOn)];
    case ActionRecurrenceID.Monthly:
      return ["Monthly", "on the", getOrdinal(sendOn)];
    case ActionRecurrenceID.Quarterly:
      return ["Quarterly", "", ""];
    case ActionRecurrenceID.OnceOff:
      return ["Once-Off", "", ""];
    default:
      return assertNever(recurrenceID);
  }
};

export const getSendOnOptions = (
  recurrenceID:
    | ActionRecurrenceID.NotificationRecurrenceIDs
    | ActionRecurrenceID.OnceOffActionRecurrenceIDs,
) => {
  switch (recurrenceID) {
    case ActionRecurrenceID.Weekly:
      return WeeklyOptions;
    case ActionRecurrenceID.Monthly:
      return MonthlyOptions;
    default:
      return [];
  }
};
