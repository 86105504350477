import { Box } from "@chakra-ui/react";
import { Route, Routes } from "react-router-dom";
import FullLoader from "../../components/chakra/FullLoader";
import { useApiQuery } from "../../utilities/apibelRequest";
import NotFoundScreen from "../NotFound";
import ServiceAccountScreen from "./ServiceAccountScreen";
import ServiceManageUsersScreen from "./ServiceManageUsersScreen";
import ServicePortalHomeScreen from "./ServicePortalHomeScreen";
import ServiceUserScreen from "./ServiceUserScreen";
import ServiceAccountsScreen from "./ServiceManageAccountsScreen";

export default function ServicePortalRoutes() {
  const permissions = useApiQuery("service/permissions", null);

  if (permissions.isLoading) {
    return (
      <Box pt="12%">
        <FullLoader />
      </Box>
    );
  }

  if (permissions.isSuccess && permissions.data.access) {
    return (
      <chakra-scope>
        <Routes>
          <Route
            path="/"
            element={
              <ServicePortalHomeScreen servicePermissions={permissions.data} />
            }
          />
          <Route
            path="/users"
            element={
              <ServiceManageUsersScreen servicePermissions={permissions.data} />
            }
          />
          <Route
            path="/user/:userID"
            element={
              <ServiceUserScreen servicePermissions={permissions.data} />
            }
          />
          <Route
            path="/accounts"
            element={
              <ServiceAccountsScreen servicePermissions={permissions.data} />
            }
          />

          <Route
            path="/account/:accountID"
            element={
              <ServiceAccountScreen servicePermissions={permissions.data} />
            }
          />
        </Routes>
      </chakra-scope>
    );
  }

  return <NotFoundScreen />;
}
