import React, { useEffect } from "react";
import * as Yup from "yup";
import { Button, VStack, Box } from "@chakra-ui/react";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import FormCustomField from "./formComponents/FormCustomField";
import * as CustomFieldTypeID from "../../../shared/v2/constants/CustomFieldTypeID";
import { CustomFieldRecord } from "../../../shared/v2/definitions/customFields";
import ShortTextField from "./formComponents/ShortTextField";
import LongTextField from "./formComponents/LongTextField";
import { ObjectRecord } from "../../../shared/v2/definitions/record";

const getValidatorForField = (
  field: CustomFieldFullRecord
) => {
  switch (field.customFieldTypeID) {
    case CustomFieldTypeID.Text: {
      if (field.isRequired) {
        return Yup.string().required("This field is required.");
      }
      return Yup.string();
    }
    case CustomFieldTypeID.Number: {
      if (field.isRequired) {
        return Yup.number()
          .typeError("You must input a valid number.")
          .required("This field is required.");
      }
      return Yup.number().typeError("You must input a valid number.");
    }
    default:
      return Yup.string();
  }
};

type Props = {
  record: ObjectRecord;
};

const RecordForm = ({ record }: Props) => {
  const validationSchema = record.customFields.reduce(
    (schema, field) =>
      schema.shape({
        [field.customFieldID]: getValidatorForField(field),
      }),
    Yup.object(),
  );
  const formMethods = useForm({
    defaultValues: record.customFields.reduce(
      (acc, val) =>
        val.value === undefined
          ? acc
          : { ...acc, [val.customFieldID]: val.value },
      {
        core__name: record.name,
        core__description: record.description
      },
    ),
    shouldUseNativeValidation: false,
    mode: "onBlur",
    reValidateMode: "onBlur",
    resolver: yupResolver(validationSchema),
  });
  const { register, control, handleSubmit, formState, reset } = formMethods;

  const onSubmitForm = async (values: any) => {
    console.log(values);
    await new Promise((res) => setTimeout(res, 2500));
    return true;
  };

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmitForm)}>
        <VStack spacing="4">
          <ShortTextField fieldProps={{label: "Name", isRequired: true}} name="core__name" />
          <LongTextField fieldProps={{label: "Description", isRequired: false}} name="core__description" />
          {record.customFields.map(({ customFieldID, customFieldTypeID, label, unitLabel, isRequired }) => (
            <FormCustomField
              key={customFieldID}
              customFieldTypeID={customFieldTypeID}
              name={customFieldID}
              fieldProps={{
                label, unitLabel, isRequired
              }}
            />
          ))}
          <VStack w="full" pt="4" alignItems="end">
            <Button
              type="submit"
              isLoading={formState.isSubmitting}
              loadingText="Saving...">
              Save
            </Button>
          </VStack>
        </VStack>
      </form>
    </FormProvider>
  );
};

export default RecordForm;
