import React from "react";
import { Container } from "react-bootstrap";
import { useParams } from "react-router-dom";
import SingleUserSettings from "../../components/settings/SingleUserSettings";
import { Header } from "../../components/UI/Header/Header";
import LegacyScreenContainer from "../../components/UI/LegacyScreenContainer";

const SingleUserScreen = () => {
  const params = useParams();
  const [state, setState] = React.useState<{
    firstName: string;
    lastName: string;
  } | null>(null);

  const { userID } = params;

  if (userID === undefined) {
    return (
      <Container>
        <p>User Not Found</p>
      </Container>
    );
  }

  return (
    <LegacyScreenContainer
      pageTitle={
        userID === "new"
          ? "New User"
          : state
          ? `${state?.firstName || ""} ${state?.lastName || ""}`
          : ""
      }
      breadcrumbs={[{ label: "Users", link: "/settings/users" }]}>
      <>
        {/* {state ? (
          <Header
            breadcrumbs={[
              { title: "Settings", link: "/settings" },
              { title: "Users", link: "/settings/users" },
              {
                title:
                  userID === "new"
                    ? "New User"
                    : `${state.firstName} ${state.lastName}`,
              },
            ]}
            title="Users"
          />
        ) : (
          <Header
            breadcrumbs={[
              { title: "Settings", link: "/settings" },
              { title: "Users", link: "/settings/users" },
            ]}
            title="Users"
          />
        )} */}

        <SingleUserSettings
          userID={userID}
          listMode
          setParentUserName={setState}
        />
      </>
    </LegacyScreenContainer>
  );
};

export { SingleUserScreen };
