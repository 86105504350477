const Requirement = {
    Description: '70668cb0-e3d8-4b0c-b6e2-72ba136af4b8',
    ID: 'f8d22eb9-b686-11ea-b718-0a8336ba1b8a',
    Name: 'c7cb1992-a414-4c03-a9b4-cbf9026cc3f1',
    PageName: '1c17d783-b688-11ea-b718-0a8336ba1b8a',
    ResponsibleUser: 'f6beecab-dcb9-4c8c-9a7b-83721f37f370',
    Status: 'fe21495c-5a7b-4267-8f6b-f2824a6bb10f',
    Parent: 'c34fbe5b-32c3-4ebb-af9b-296ee8d878ec',
    Notes: '87d3490f-f3ca-11ea-9e21-024ef06a0f02',
    TimeNotes: '35fe8a2b-f3ff-11ea-9e21-024ef06a0f02',
    NoteText: 'a5e3cdbd-02ff-4a75-bd36-ea74d0890944',
    TimeNoteDate: 'e817dec3-87d0-4671-bf7c-8d69dfe0dae2',
    TimeNoteCreationDate: '5ba94417-5fa0-4b8d-830a-77bcebc0a560',
    TimeNoteAuthor: 'db1921aa-4851-428f-a840-7521d3105202',
    TimeNoteAmount: '8fa6b36e-4c39-4893-b8b8-07d02c0a9174',
    NoteCreationDate: 'c1c12e7c-537d-4788-8d07-3ffc0d7e5896',
    NoteAuthor: '47248ac6-5066-48af-bf47-895992e79006',
    TimeNoteText: 'da696ebe-5c3f-4d64-8575-80bc7a3be4c3',
    TimeNoteBillable: 'd6977a8e-ddf7-46b6-97f9-4a27e1f11cf3'
}

const Issue = {
    Description: '08e49fcd-0b90-49c9-9b1b-05e36865b214',
    ID: '0204af68-b687-11ea-b718-0a8336ba1b8a',
    Name: '068d48f5-719f-46e7-8b80-666c7b2d67af',
    PageName: '20ab2120-b688-11ea-b718-0a8336ba1b8a',
    ResponsibleUser: '444098b3-9290-409a-829d-2c5c6d958b5b',
    Status: '5ec630e2-61d4-4be7-8910-dcbe5dc36e78',
    Parent: '4cd09015-d132-432a-b3d0-5e61ea091319',
    Notes: 'ac76f1f5-f3fc-11ea-9e21-024ef06a0f02',
    TimeNotes: 'e261b536-f878-11ea-9e21-024ef06a0f02',
    NoteText: '320864e8-a65c-4745-aa8a-da04442d3334',
    TimeNoteDate: '3ee9b9bf-4c81-45f4-9855-ec002668f5ba',
    TimeNoteCreationDate: '6defc7a0-973b-4069-bfaf-dbb482ce91a1',
    TimeNoteAuthor: '6e7d24e6-145c-403e-ba4d-845cfae710f5',
    TimeNoteAmount: '909047d5-6197-40dc-834c-27ea6f69484b',
    NoteCreationDate: 'b427708b-dfba-455b-9577-bc9a103f31d7',
    NoteAuthor: 'ec3f3503-835c-4b0d-bdff-701bae48ee57',
    TimeNoteText: 'edc36719-6c27-441f-8e60-f0a34de03b7f',
    TimeNoteBillable: '0e96aa29-d843-4fb6-bd19-f3185f7993e5'

}

const Risk = {
    Description: '84a8e5a4-dba2-4b42-ad28-41d5ba9c8298',
    ID: '090bfa4d-b687-11ea-b718-0a8336ba1b8a',
    Name: '27398b55-d777-43b1-ad59-e50d1330f7a7',
    PageName: '256ebc98-b688-11ea-b718-0a8336ba1b8a',
    ResponsibleUser: 'd8ca44be-aeb1-4783-8541-e3e686b21c9a',
    Status: 'd2f46735-f6f6-4d0f-98cb-30f32af21f4e',
    Parent: 'd27ea2fc-f2fc-4221-aaa3-f3a74cdc0dfd',
    Notes: 'c3644ef0-f3fc-11ea-9e21-024ef06a0f02',
    TimeNotes: 'ff3e9e31-f878-11ea-9e21-024ef06a0f02',
    NoteText: '4074d4b6-b564-44ee-8214-286a8847d6cc',
    TimeNoteDate: 'bbf09ee3-e519-4c9e-a9a6-00e002b067db',
    TimeNoteCreationDate: 'caa43e6e-ca2b-4497-bd43-bd7e1591bcd5',
    TimeNoteAuthor: 'cb8093f5-583d-4a09-a1eb-594aed7d7d24',
    TimeNoteAmount: '29f45581-06ac-4fd3-8b1c-c0aea3dcbeb5',
    NoteCreationDate: 'bd7d4d0b-90d0-4f9a-ab95-614338186c92',
    NoteAuthor: '76d172ac-0586-4c30-bf50-912a93c91178',
    TimeNoteText: '1840ba10-1017-4286-874b-4265d774d3e8',
    TimeNoteBillable: 'e739fd61-2333-4c3f-92e7-a59d44698b5f'
}

const Job = {
    Description: 'fa862580-9946-4c11-a79a-7c36452f417c',
    ID: '0e84e264-b687-11ea-b718-0a8336ba1b8a',
    Name: '144f6388-eed3-4b95-a907-37a8c2b7a54f',
    PageName: '29acb5be-b688-11ea-b718-0a8336ba1b8a',
    ResponsibleUser: 'ba96fd35-41c6-4ba5-92de-1edd9f00f06d',
    Status: '23ed3651-e6be-4c08-a1f2-1bb39bc458d1',
    Notes: 'd146815a-f3fc-11ea-9e21-024ef06a0f02', //to delete
    TimeNotes: '089f392c-f879-11ea-9e21-024ef06a0f02', //to delete
    NoteText: '02f78062-95b2-441a-9170-e2f8138b9d20',
    TimeNoteDate: '1410dec2-d8b0-4f58-9857-04de8a565619',
    TimeNoteCreationDate: 'cc1346ed-694d-407f-b8c0-4e03a5511f47',
    TimeNoteAuthor: '0ee8ae82-e6ca-4f38-9f6c-68b2d5b940e0',
    TimeNoteAmount: '9aee8ab7-3785-42b0-9f6e-f49cee004b13',
    NoteCreationDate: '029e5a0f-60ad-44cd-8f9f-d6ab3bedc5de',
    NoteAuthor: '35d14438-a409-4229-b786-d5437adf8deb',
    TimeNoteText: 'ac08f2d7-ea6f-4bb3-b002-0e3a63e6e4c0',
    TimeNoteBillable: 'd8b4614f-1b46-4709-b312-d9bc9e18f479'
}

const Action = {
    Description: 'ea815154-5e38-4d4c-b186-deb3aec1bacf',
    ID: '13b22037-b687-11ea-b718-0a8336ba1b8a',
    Name: '3a1b78a8-9786-4746-aa66-292a3bc903f9',
    PageName: '2fa6e045-b688-11ea-b718-0a8336ba1b8a',
    ResponsibleUser: 'b65fa16c-a870-4eda-8f89-3a607e756d8b',
    Status: '22e259d4-81ba-4d57-883f-8dd16515df88',
    InstanceLifecycle: '56e9cdfe-4f29-4ef9-9bf2-5b91a42e96f4',
    FirstActionDate: '04efc94f-69f9-47f4-b979-b3c4e9bc14f0',
    InstanceDueDate: '979b7aaa-9a04-43f1-b1fe-7d2d7c7e3793',
    InstanceResponses: '6bca129c-bf40-4be9-b43d-cba6148f72e7',
    InstanceResponsibleUser: '51fcf0c8-1fd0-11eb-9e21-024ef06a0f02',
    InstanceResponseDate: '9358fecd-8871-11eb-9803-02fb79c5563a',
    Recurrence: '33d2be1f-fcd4-4dea-a7d9-29f5233a7705',
    Parent: '7a8caab9-dc4f-4ccc-8058-628e17a545af',
    Notes: 'dbcb84e8-f3fc-11ea-9e21-024ef06a0f02',
    TimeNotes: '10e0e40e-f879-11ea-9e21-024ef06a0f02',
    NoteText: '56739015-0f74-4873-96e4-52507086bc06',
    TimeNoteDate: 'f4e80409-d18a-46cc-a75f-33a53101e3c4',
    TimeNoteCreationDate: '259c039b-88e9-4ebf-b94e-dfe375b1c7c8',
    TimeNoteAuthor: 'd25a3948-9551-461b-ace0-afcfecf2e11c',
    TimeNoteAmount: '88ef3187-1a50-47a7-9b8f-10540c269e3a',
    NoteCreationDate: 'a43ca706-2e95-48cc-84d2-e654d988a35b',
    NoteAuthor: '95445115-5cf7-4388-812d-013406872e47',
    TimeNoteText: 'a155c73f-589a-45bc-8610-c5431d880a4a',
    TimeNoteBillable: 'dc206b86-be0a-4225-8d4e-ac9c7e3869a9',
    RiskAssessmentDate: 'af24c1b8-0656-443f-b8bf-93fec359b804',
    InherentRiskValue: '08635c6b-8e9b-422a-97ed-f64be3307395',
    ResidualRiskValue: 'c6ca1b68-ec9f-48d1-8f67-5640443fca0a',
    ListOfControls: '86eeee32-f1a6-4e91-9e93-ceaac30ea02b'
}

const Register = {
    Status: '29e7edec-f952-41bc-9719-62db15588bcd',
    ResponsibleUser: '317d87f0-844f-4122-bb48-5e2626947e3c',
    ID: '7526a634-7e64-4ed4-94f9-6977b2c8bca8',
    Description:'c16de81d-1748-48be-8162-6272c65ff340',
    Name: 'e0673d42-4ac2-46dc-bf48-95712e58248e',
    PageName: 'f1cab358-ff66-46c9-a877-17075878e107',
    Notes: 'ba36b6e6-f3fc-11ea-9e21-024ef06a0f02',
    TimeNotes: 'f5f4ac45-f878-11ea-9e21-024ef06a0f02',
    NoteText: 'a6a6c329-cce8-484c-aca1-df5e46999506',
    TimeNoteDate: 'd6ea13aa-d8a1-4fe1-ab73-b08e4f06b667',
    TimeNoteCreationDate: 'd0b0d289-4f13-4015-9c56-51c69f1e064b',
    TimeNoteAuthor: 'ad65c027-cdfe-47c3-b05a-740aa534817e',
    TimeNoteAmount: '67174a3c-728e-4c6d-82f6-b9b15abd79cd',
    NoteCreationDate: '9cdcc1a9-4f91-4a29-bf0e-a68021e7d524',
    NoteAuthor: '90bbde1c-47b4-433a-aeaa-9ab5fd06b507',
    TimeNoteText: '6b99d4b3-d9e6-4e3b-842a-13bb3722ea3b',
    TimeNoteBillable: 'a5fb7b8d-7afe-4f55-a1d1-82321b6e798b'
}

const FieldMap = { Requirement, Issue, Risk, Job, Action, Register };

export { 
    FieldMap,
    Requirement,
    Issue,
    Risk,
    Job,
    Action,
    Register
}