import React, { useEffect } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

// a little function to help us with reordering the result
const reorder = (
  list: Iterable<unknown> | ArrayLike<unknown>,
  startIndex: number,
  endIndex: number,
  itemList: any[],
  setOrder: any,
) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  const [itemRemoved] = itemList.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  itemList.splice(endIndex, 0, itemRemoved);

  const returnResult = {
    customfieldsComponents: result,
    customfieldsList: itemList,
  };
  setOrder(itemList);

  return returnResult;
};

const CustomFieldDragAndDrop = (props: any) => {
  const [customfieldOrder, setCustomFieldOrder] = React.useState<any>(
    props.fields,
  );
  const [state, setState] = React.useState<any>({ ...props });
  const grid = 3;

  const myFunction = props.setOrder;
  const getItemStyle = (isDragging: any, draggableStyle: any) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,
    boxShadow: isDragging ? `rgba(0, 0, 0, 0.17) 0px 3px 10px 0px` : "",
    background: "white",
    // styles we need to apply on draggables
    ...draggableStyle,
  });

  const getListStyle = (isDraggingOver: boolean) => ({
    background: "white",
    padding: grid,
  });

  const onDragEnd = (result: any) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const itemList = {
      items: { customfields: props.customfields },
    };

    const items = reorder(
      state.customfields,
      result.source.index,
      result.destination.index,
      state.fields,
      myFunction,
    );

    setState({
      customfields: items.customfieldsComponents,
      fields: items.customfieldsList,
      setOrder: props.setOrder,
      selectedObjectType: props.selectedObjectType,
    });
    console.log("stateChange", items);
  };
  useEffect(() => {
    setState({
      customfields: props.customfields,
      fields: props.fields,
      setOrder: props.setOrder,
      selectedObjectType: props.selectedObjectType,
    });
    console.log("ddstate: ", state);
  }, [props]);
  // Normally you would want to split things out into separate components.
  // But in this example everything is just done in one place for simplicity
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={getListStyle(snapshot.isDraggingOver)}>
            {state.customfields.map((item: any, index: number) => (
              <Draggable
                key={item.props.children[0].key}
                draggableId={item.props.children[0].key}
                index={index}>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={getItemStyle(
                      snapshot.isDragging,
                      provided.draggableProps.style,
                    )}
                    key={item.props.children[0].key}>
                    {item}
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export { CustomFieldDragAndDrop };
