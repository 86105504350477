import Papa from "papaparse";

/**
 * Converts the given data to csv, and triggers a download
 * @param data
 * @param filename
 */
export function downloadAsCsv(data: unknown[], filename: string) {
  const csvData = Papa.unparse(data);

  const linkElement = document.createElement("a");
  linkElement.href = `data:/text/plain:charset=utf-8, ${encodeURIComponent(
    csvData,
  )}`;
  linkElement.download = filename;
  linkElement.style.display = "none";
  document.body.appendChild(linkElement);
  linkElement.click();
  document.body.removeChild(linkElement);
}
