import {
  Avatar,
  AvatarGroup,
  Badge,
  Box,
  Checkbox,
  Collapse,
  HStack,
  IconButton,
  LinkBox,
  LinkOverlay,
  Spacer,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useDisclosure,
  UseDisclosureReturn,
} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { ObjectStatus } from "../../../../shared/v2/constants/ObjectStatusID";
import { useApiQuery } from "../../../../utilities/apibelRequest";
import Spinner from "../../../chakra/common/Spinner";
import { SearchProviderFactory } from "../../../SearchProvider";
import Icon from "../../../UI/Icon";
import ObjectStatusBadge from "../../../UI/ObjectStatusBadge";
import { RecordFilters } from "../BaseWidgetProps";
import DateFilter from "../filters/DateFilter";
import RecordType from "../filters/RecordType";
import StatusFilter from "../filters/StatusFilter";
import SubIssueFilter from "../filters/SubIssueFilter";
import UserFilter from "../filters/UserFilter";

type Records = {
  id: string;
  readableId: string;
  name: string;
  status: ObjectStatus;
  type: string;
  responsibleUser: string;
  objectTypeGroupName: string;
  description: string;
  score: number;
  modifiedTs: string;
};

type Props = {
  setFilters: (filters: RecordFilters) => void;
  onChangeSearch: (search: string) => void;
  disclosureState: UseDisclosureReturn;
  searchQuery: string;
  filters: RecordFilters;
  users: any[];
};

const RecordSearch = SearchProviderFactory<Records>();

const RecordsTab = ({
  setFilters,
  onChangeSearch,
  disclosureState,
  ...props
}: Props) => {
  const [exactMatch, setExactMatch] = useState(true);
  const recordsQuery = useApiQuery("dashboard/search/records", {
    query: props.searchQuery,
    filters: props.filters,
    exactMatch,
  });

  const [disableTooltips, setDisabled] = useState(false);

  const eventRef = useRef<HTMLDivElement>();

  useEffect(() => {
    if (eventRef.current) {
      eventRef.current.addEventListener("scroll", () => {
        setDisabled(true);
      });
      eventRef.current.addEventListener("scrollend", () => {
        setDisabled(false);
      });
    }
    return () => {
      if (eventRef.current) {
        eventRef.current.removeEventListener("scroll", () => {
          setDisabled(true);
        });
        eventRef.current.removeEventListener("scrollend", () => {
          setDisabled(false);
        });
      }
    };
  }, []);

  return (
    <RecordSearch.SearchProvider>
      <HStack w="full">
        <RecordSearch.SearchController
          data={recordsQuery.data || []}
          placeholder="Search by Name or ID"
          onChangeSearch={onChangeSearch}
          value={props.searchQuery}
          searchConfig={{ debounceTime: 300 }}
          external
        />

        <IconButton
          onClick={disclosureState.onToggle}
          variant="ghost"
          aria-label={disclosureState.isOpen ? "Close Results" : "Open Results"}
          icon={
            <Icon name={disclosureState.isOpen ? "ChevronUp" : "ChevronDown"} />
          }
        />
      </HStack>
      <Box w="full">
        <Collapse in={disclosureState.isOpen} animateOpacity>
          <Box display="flex" gap={2} flexDirection="column">
            <HStack>
              <RecordType
                patchFilters={(patch) => {
                  setFilters({
                    type: "records" as any,
                    user: props.filters.user,
                    statusId: props.filters.statusId,
                    ...patch,
                  });
                }}
                recordType={props.filters.recordType}
              />

              {props.filters.recordType === "issue" && (
                <SubIssueFilter
                  recordType={"issue"}
                  subIssues={props.filters.subIssues}
                  patchFilters={(patch) => {
                    setFilters({
                      ...(props.filters as any),
                      ...patch,
                    });
                  }}
                />
              )}

              <StatusFilter
                statusId={props.filters.statusId as ObjectStatus | undefined}
                type="records"
                patchFilters={(patch) => {
                  setFilters({
                    ...(props.filters as any),
                    ...patch,
                  });
                }}
              />
              <UserFilter
                patchFilters={(patch) => {
                  try {
                    setFilters({
                      ...(props.filters as any),
                      ...patch,
                    });
                  } catch (e) {
                    console.error(`Error setting user filter: ${e}`);
                  }
                }}
                type={"records"}
                user={props.filters.user}
                users={props.users}
              />
              <Spacer />
              <Checkbox
                pr={2}
                onChange={(e) => {
                  setExactMatch(e.target.checked);
                }}
                isChecked={exactMatch}>
                Exact Match Search
              </Checkbox>
            </HStack>
          </Box>

          <TableContainer
            ref={eventRef}
            overflowY="auto"
            h={420}
            position="relative">
            <Table size="sm" variant="data-table">
              <Thead
                style={{ borderBottom: "none" }}
                position="sticky"
                top={0}
                background="white"
                zIndex={1}>
                <Tr>
                  <Th>Name</Th>
                  <Th>Type</Th>
                  <Th>Status</Th>
                  <Th>Responsible User(s)</Th>
                </Tr>
              </Thead>
              {(recordsQuery.isLoading || recordsQuery.isFetching) && (
                <Spinner position="absolute" top="50%" size="md" left="50%" />
              )}
              <Tbody
                opacity={
                  recordsQuery.isLoading || recordsQuery.isFetching ? 0.3 : 1
                }>
                <RecordSearch.SearchContext.Consumer>
                  {({ state }) => {
                    if (state.data.length) {
                      return state.data.map((record) => {
                        const isMatch = Number(record.score) > 1;
                        const isGoodMatch = Number(record.score) > 6;
                        return (
                          <LinkBox key={`${record.id}-${record.name}`} as={Tr}>
                            <Td
                              overflowX="clip"
                              maxW={380}
                              opacity={!exactMatch && !isMatch ? 0.7 : 1}>
                              <Badge
                                mr="2"
                                variant="subtle"
                                colorScheme={isGoodMatch ? "green" : "gray"}>
                                {record.readableId}
                              </Badge>

                              <Tooltip
                                label={record.name}
                                openDelay={500}
                                isDisabled={disableTooltips}
                                closeOnScroll>
                                <LinkOverlay
                                  as={Link}
                                  to={`/${record.objectTypeGroupName.toLowerCase()}/${
                                    record.id
                                  }?display=${record.objectTypeGroupName.toLowerCase()}s`}>
                                  {record.name}
                                </LinkOverlay>
                              </Tooltip>
                            </Td>
                            <Td>{record.type}</Td>
                            <Td>
                              <ObjectStatusBadge statusID={record.status} />
                            </Td>
                            <Td>
                              <AvatarGroup
                                display="flex"
                                justifyContent="right">
                                <Tooltip label={record.responsibleUser}>
                                  <Avatar
                                    size="sm"
                                    name={record.responsibleUser || ""}
                                  />
                                </Tooltip>
                              </AvatarGroup>
                            </Td>
                          </LinkBox>
                        );
                      });
                    }
                    return (
                      <Box position="absolute" top="50%" left="45%">
                        <Text>No records found</Text>
                      </Box>
                    );
                  }}
                </RecordSearch.SearchContext.Consumer>
              </Tbody>
            </Table>
          </TableContainer>
        </Collapse>
      </Box>
    </RecordSearch.SearchProvider>
  );
};

export default RecordsTab;
