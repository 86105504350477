import { FormControl, FormErrorMessage, FormLabel } from "@chakra-ui/react";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import RichText from "../RichText";

type Props = {
  name: string;
  isReadOnly?: boolean;
  fieldProps: {
    label: string;
    unitLabel?: string | null;
    isRequired?: boolean;
  };
};

const LongTextField = ({ fieldProps, name, isReadOnly }: Props) => {
  const { control, formState } = useFormContext();
  const error = formState.errors[name];
  const { label, isRequired } = fieldProps;
  return (
    <FormControl isRequired={isRequired}>
      <FormLabel htmlFor={name}>{label}</FormLabel>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <RichText
            value={field.value}
            isReadOnly={isReadOnly}
            onChange={field.onChange}
          />
        )}
      />
      <FormErrorMessage>{error && error.message}</FormErrorMessage>
    </FormControl>
  );
};

export default LongTextField;
