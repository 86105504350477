import {
  Box,
  Button,
  Center,
  Flex,
  HStack,
  LinkBox,
  LinkOverlay,
  Modal,
  ModalContent,
  ModalOverlay,
  Table,
  TableContainer,
  Tbody,
  Td,
  Tfoot,
  Th,
  Thead,
  Tr,
  useBreakpointValue,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import { Link as RouterLink } from "react-router-dom";
import { useAppState } from "../../../components/App/AppProvider";
import MissingPermission from "../../../components/chakra/common/MissingPermission";
import Spinner from "../../../components/chakra/common/Spinner";
import Icon from "../../../components/UI/Icon";
import { useApiQuery } from "../../../utilities/apibelRequest";
import NewRoleModal from "./NewRoleModal";
import SettingsScreenContainer from "./SettingsScreenContainer";

type RoleSummary = {
  roleID: string;
  name: string;
  description: string;
};

const RolesScreen = () => {
  const tableSize = useBreakpointValue({ base: "sm", lg: "md" }) as "md" | "lg";
  const { permissions } = useAppState().app;
  const newRoleModalState = useDisclosure();
  const hasReadPermission = permissions.USER_SETTINGS.READ.ALL;
  const hasCreatePermision = permissions.USER_SETTINGS.CREATE.ALL;

  const queryClient = useQueryClient();

  const handleClickRefresh = () => {
    queryClient.invalidateQueries("role");
  };

  const rolesQuery = useApiQuery("role/allRoles", null, {
    enabled: hasReadPermission,
  });

  const renderRow = (role: RoleSummary) => (
    <Tr key={role.roleID}>
      <LinkBox _hover={{ textDecor: "underline" }} as={Td}>
        <LinkOverlay
          ps="2"
          as={RouterLink}
          display="flex"
          alignItems="center"
          to={`/settings/role/${role.roleID}`}>
          <Flex flexDir="column" align="start" as="span">
            <span>{role.name}</span>
          </Flex>
        </LinkOverlay>
      </LinkBox>
      <Td>{role.description}</Td>
    </Tr>
  );
  return (
    <chakra-scope>
      <SettingsScreenContainer
        pageTitle="Roles"
        headerEndContent={
          hasReadPermission && hasCreatePermision ? (
            <Button
              colorScheme="brand"
              variant="solid"
              size="sm"
              onClick={newRoleModalState.onOpen}
              leftIcon={<Icon name="UserPlus" />}>
              Create Role
            </Button>
          ) : null
        }>
        {!hasReadPermission ? (
          <MissingPermission />
        ) : (
          <VStack>
            <Box w="full">
              <TableContainer w="full" pt={2} pb={4}>
                <Box w="full">
                  <HStack justify="right">
                    <Button
                      isLoading={rolesQuery.isRefetching}
                      disabled={rolesQuery.isFetching}
                      onClick={handleClickRefresh}
                      leftIcon={<Icon name="RefreshCw" />}
                      variant="ghost"
                      loadingText="Refreshing"
                      size="sm">
                      Refresh
                    </Button>
                  </HStack>
                </Box>
                <Table
                  variant="data-table"
                  isFetching={rolesQuery.isFetching}
                  size={tableSize}
                  position="relative">
                  <Thead position="relative">
                    {rolesQuery.isFetching && (
                      <Spinner
                        zIndex={1}
                        opacity={1}
                        size="lg"
                        position="absolute"
                        bottom={-16}
                        left="50%"
                      />
                    )}
                    <Tr>
                      <Th>Name</Th>
                      <Th>Description</Th>
                    </Tr>
                  </Thead>
                  {rolesQuery.isSuccess && (
                    <>
                      <Tbody>
                        {[...rolesQuery.data]
                          .sort((a, b) => a.name.localeCompare(b.name))
                          .map(renderRow)}
                      </Tbody>
                      <Tfoot>
                        <Tr>
                          <Th>Name</Th>
                          <Th>Description</Th>
                        </Tr>
                      </Tfoot>
                    </>
                  )}
                </Table>
              </TableContainer>
              {rolesQuery.isLoading && (
                <Center mt="4">
                  <Spinner size="lg" />
                </Center>
              )}
            </Box>
          </VStack>
        )}
        <Modal
          motionPreset="slideInBottom"
          size="2xl"
          isOpen={newRoleModalState.isOpen}
          onClose={newRoleModalState.onClose}>
          <ModalOverlay />
          <ModalContent>
            <NewRoleModal onClose={newRoleModalState.onClose} />
          </ModalContent>
        </Modal>
      </SettingsScreenContainer>
    </chakra-scope>
  );
};
export default RolesScreen;
