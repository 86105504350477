import { uniq } from "lodash";
import * as z from "zod";
import * as CustomFieldTypeID from "../../constants/CustomFieldTypeID";
import * as ListTypeID from "../../constants/ListTypeID";

/**
 * List of core fields that are supported by filters
 */
export const CoreFields = [
  "name",
  "description",
  "readableID",
  "objectStatusID",
  "responsibleUserID",
  "related",
] as const;
export type CoreFieldType = (typeof CoreFields)[number];

/**
 * Identifier for core fields
 */
const CoreFieldIdentifierSchema = z.object({
  type: z.enum(CoreFields),
});
export type CoreFieldIdentifier = z.infer<typeof CoreFieldIdentifierSchema>;

/**
 * Identifier for custom fields, just the custom fields ID + if its an aggregate field
 */
const CustomFieldIdentifierSchema = z.object({
  type: z.literal("custom"),
  ID: z.string(),
  agg: z.boolean().optional(),
});
export type CustomFieldIdentifier = z.infer<typeof CustomFieldIdentifierSchema>;

/**
 * Identifier for fields, either core or custom
 */
export const FieldIdentifierSchema = z.union([
  CustomFieldIdentifierSchema,
  CoreFieldIdentifierSchema,
]);
export type FieldIdentifier = z.infer<typeof FieldIdentifierSchema>;
