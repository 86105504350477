const Issue = "1d410566-8902-4e51-a5ef-7d1d53477d9b";
const Requirement = "24816703-d4bb-4e7d-89f9-341e38b4d66d";
const Action = "b2662c1b-c2c4-4013-aab7-080ce019f073";
const Risk = "8d60cafe-91bb-4e5d-9b7c-739927419130";
const Job = "a5e5841b-7056-4ba0-8ce4-6efc73fb583f";
const Register = "2264d816-9067-49f4-bdc2-706246e1a670";

// Yucky index signature, but has to be done for now :(
const ObjectTypeGroupIDToName = {
  "1d410566-8902-4e51-a5ef-7d1d53477d9b": "Issue",
  "24816703-d4bb-4e7d-89f9-341e38b4d66d": "Requirement",
  "b2662c1b-c2c4-4013-aab7-080ce019f073": "Action",
  "8d60cafe-91bb-4e5d-9b7c-739927419130": "Risk",
  "a5e5841b-7056-4ba0-8ce4-6efc73fb583f": "Job",
  "2264d816-9067-49f4-bdc2-706246e1a670": "Register",
};

const ObjectTypeGroupNametoID = {
  Issue: "1d410566-8902-4e51-a5ef-7d1d53477d9b",
  Requirement: "24816703-d4bb-4e7d-89f9-341e38b4d66d",
  Action: "b2662c1b-c2c4-4013-aab7-080ce019f073",
  Risk: "8d60cafe-91bb-4e5d-9b7c-739927419130",
  Job: "a5e5841b-7056-4ba0-8ce4-6efc73fb583f",
  Register: "2264d816-9067-49f4-bdc2-706246e1a670",
};

export {
  Issue,
  Requirement,
  Action,
  Risk,
  ObjectTypeGroupIDToName,
  Job,
  Register,
  ObjectTypeGroupNametoID,
};
