import React from "react";
import {
  Col,
  Container, Dropdown, Form, OverlayTrigger, Row,
  Table, Tooltip
} from "react-bootstrap";
import * as Feather from "react-feather";
import { Link, useLocation } from "react-router-dom";
import { useAppState } from "../../components/App/AppProvider";
import { Textarea } from "../../components/Forms/Field/Textarea";
import { Card } from "../../components/UI/Card/Card";
import { Header } from "../../components/UI/Header/Header";
import { Loading } from "../../components/UI/Loading/Loading";
import { Paginator } from "../../components/UI/Paginator/Paginator";
import { LifeCycle } from "../../components/UI/Status/LifeCycle";
import {
  objectStatusToVariant, Status
} from "../../components/UI/Status/Status";
import * as ObjectTypeGroup from "../../constants/objectTypeGroup";
import { formatDateTime } from "../../utilities/formatDate";
import * as Request from "../../utilities/request";
import { text_truncate } from "../../utilities/strings";
import { getUrlSearchParam } from "../../utilities/url";
import useToast from "../../utilities/useToast";

interface JobDashboardState {
  jobs?: any[];
  instances?: any[];
  error?: any;
  fetchingJobs?: boolean;
  fetchingInstances?: boolean;
}

interface CategoryFilter {
  name: string;
  id: string;
  selected: boolean;
}

interface JobFiltersState {
  importantJobsPage: number;
  jobActionsPage: number;
  pageSize: number;
  upcoming: boolean;
  due: boolean;
  overdue: boolean;
  categories: CategoryFilter[];
  selectedCategory: string;
}

const lifeCycleFilter = (filters: JobFiltersState, instance: any) =>
  instance.LifeCycle !== "Closed" &&
  ((filters.upcoming && instance.LifeCycle === "Upcoming") ||
    (filters.due && instance.LifeCycle === "Due") ||
    (filters.overdue && instance.LifeCycle === "Overdue"));
const categoryFilter = (filters: JobFiltersState, instance: any) => {
  let isIncluded = false;
  filters.categories.forEach((category: CategoryFilter) => {
    if (category.selected && instance.RequirementObjectTypeID === category.id) {
      isIncluded = true;
    }
  });
  return isIncluded;
};

const pageFilter = (page: number, pageSize: number, index: number) =>
  index < page * pageSize && index >= (page - 1) * pageSize;

type JobsDashboardStatus =
  | "Loading"
  | "Ready"
  | "Editing"
  | "Fetching"
  | "Error";

const ScreensJobDashboard = () => {
  const location = useLocation();
  const { app, auth } = useAppState();
  const [data, setData] = React.useState<JobDashboardState>({});
  const [pageStatus, setPageStatus] =
    React.useState<JobsDashboardStatus>("Loading");
  const [filters, setFilters] = React.useState<JobFiltersState>({
    upcoming: false,
    due: true,
    overdue: true,
    importantJobsPage: 1,
    jobActionsPage: 1,
    pageSize: 10,
    categories: [],
    selectedCategory: "all",
  });
  const { displayToast } = useToast();
  const onMainDashboard = !location.search;

  const getData = async () => {
    setPageStatus("Loading");
    setData({ fetchingJobs: true, fetchingInstances: true });
    try {
      const jobObjectTypes = await Request.get(
        `app/objecttypegroup/${ObjectTypeGroup.Job}/objecttype`,
        auth,
      );
      const objectTypes: CategoryFilter[] = jobObjectTypes.data.ObjectTypes.map(
        (objectType: any) => ({
          name: objectType.ObjectTypeName,
          id: objectType.ObjectTypeID,
          selected: true,
        }),
      );
      setFilters({
        ...filters,
        importantJobsPage: 1,
        categories: objectTypes,
        selectedCategory: "all",
      });

      await Request.getPaginated(`job`, 300, updateJobResults, auth);
      await Request.getPaginated(
        `instance?requirementobjecttypegroupid=${ObjectTypeGroup.Job}&`,
        500,
        updateInstanceResults,
        auth,
      );
    } catch (err) {
      setData({
        error: err,
      });
    }
    setPageStatus("Ready");
  };

  let interimJobResults: any[] = [];
  const updateJobResults = (
    responseData: any,
    status: Request.RequestStatus,
  ) => {
    if (status !== "Error") {
      interimJobResults = interimJobResults.concat(
        responseData.Requirements.filter(
          (job: any) => job.Permission || job.ActionIDs.length > 0,
        ),
      );
      setData((prevData) => ({
        ...prevData,
        jobs: interimJobResults,
        fetchingJobs: status === "Fetching",
      }));
    } else {
      displayToast({
        status: "error",
        title: `Failed to retrieve Jobs`,
      });
      setData((prevData) => ({ ...prevData, fetchingJobs: false }));
    }
    setPageStatus(status);
  };

  let interimInstanceResults: any[] = [];
  const updateInstanceResults = (
    responseData: any,
    status: Request.RequestStatus,
  ) => {
    if (status !== "Error") {
      interimInstanceResults = interimInstanceResults.concat(
        responseData.Instances,
      );
      setData((prevData) => ({
        ...prevData,
        instances: interimInstanceResults,
        fetchingInstances: status === "Fetching",
      }));
    } else {
      displayToast({
        status: "error",
        title: `Failed to retrieve Tasks`,
      });
      setData((prevData) => ({ ...prevData, fetchingInstances: false }));
    }
    setPageStatus(status);
  };

  React.useEffect(() => {
    getData();
  }, [auth]); // eslint-disable-line

  // Jobs Card Filters and Pagination
  let filteredJobs: any[] = data.jobs ? data.jobs : [];
  let paginatedFilteredJobs: any[] = [];
  if (filteredJobs.length > 0) {
    // Filter by ObjectTypeName
    const filter = getUrlSearchParam(location.search, "filter");
    if (filter) {
      filteredJobs = filteredJobs.filter(
        (job: any) => job.ObjectTypeID === filter,
      );
    }
    // Pagination
    paginatedFilteredJobs = filteredJobs.filter((job: any, index: number) =>
      pageFilter(filters.importantJobsPage, filters.pageSize, index),
    );
  }

  // Instance Card Filters and Pagination
  let filteredInstances: any[] = data.instances ? data.instances : [];
  let paginatedFilteredInstances: any[] = [];
  if (filteredInstances.length > 0) {
    // Filter based off lifecycle
    filteredInstances = filteredInstances.filter((instance: any) =>
      lifeCycleFilter(filters, instance),
    );

    // Filter based off category
    filteredInstances = filteredInstances.filter((instance: any) =>
      categoryFilter(filters, instance),
    );

    // Pagination
    paginatedFilteredInstances = filteredInstances.filter(
      (instance: any, index: number) =>
        pageFilter(filters.jobActionsPage, filters.pageSize, index),
    );
  }

  return (
    <Container>
      <Header
        breadcrumbs={[{ title: "Home", link: "/" }, { title: "Job Dashboard" }]}
        title="Job Dashboard"
      />
      {pageStatus !== "Loading" && data.jobs ? (
        <Card title="Jobs" collapsable={false} headerCols={[]}>
          <Table responsive borderless>
            <thead>
              <tr>
                <th />
                <th>Name</th>
                <th>Audit Type</th>
                <th>Status</th>
                <th>Description</th>
              </tr>
            </thead>
            <tbody>
              {data.jobs && getJobsTableBody(paginatedFilteredJobs)}
            </tbody>
          </Table>
          {data.fetchingJobs ? (
            <Row className="justify-content-sm-center">
              <Col sm="auto">
                <Loading size={"md"} />
              </Col>
            </Row>
          ) : (
            <div />
          )}
          <Row className="justify-content-sm-center">
            <Col sm="auto">
              <Paginator
                filters={filters}
                setFilterDispatch={setFilters}
                allPaginatedRecords={filteredJobs}
                pageKey="importantJobsPage"
              />
            </Col>
          </Row>
        </Card>
      ) : (
        <Card title="Jobs" collapsable={false} headerCols={[]}>
          <Loading />
        </Card>
      )}

      {onMainDashboard ? (
        <Card
          title="Job Actions"
          collapsable={false}
          headerColsRight
          headerCols={[
            {
              colProps: { sm: "auto", style: { paddingTop: "5px" } },
              children: (
                <Form.Check
                  custom
                  inline
                  label="Upcoming"
                  type="checkbox"
                  id="checkbox-upcoming"
                  checked={filters.upcoming}
                  onChange={() =>
                    setFilters({
                      ...filters,
                      upcoming: !filters.upcoming,
                      jobActionsPage: 1,
                    })
                  }
                />
              ),
            },
            {
              colProps: { sm: "auto", style: { paddingTop: "5px" } },
              children: (
                <Form.Check
                  custom
                  inline
                  label="Due"
                  type="checkbox"
                  id="checkbox-due"
                  checked={filters.due}
                  onChange={() =>
                    setFilters({
                      ...filters,
                      due: !filters.due,
                      jobActionsPage: 1,
                    })
                  }
                />
              ),
            },
            {
              colProps: { sm: "auto", style: { paddingTop: "5px" } },
              children: (
                <Form.Check
                  custom
                  inline
                  label="Overdue"
                  type="checkbox"
                  id="checkbox-overdue"
                  checked={filters.overdue}
                  onChange={() =>
                    setFilters({
                      ...filters,
                      overdue: !filters.overdue,
                      jobActionsPage: 1,
                    })
                  }
                />
              ),
            },
            {
              colProps: { sm: "auto" },
              children: (
                <Dropdown
                  id="dropdown-basic-button"
                  alignRight
                  onSelect={(eventKey: any) => {
                    const newCategories = filters.categories.map(
                      (category: CategoryFilter) =>
                        category.name === eventKey
                          ? {
                              name: category.name,
                              id: category.id,
                              selected: true,
                            }
                          : {
                              name: category.name,
                              id: category.id,
                              selected: eventKey === "all",
                            },
                    );
                    setFilters({
                      ...filters,
                      jobActionsPage: 1,
                      categories: newCategories,
                      selectedCategory: eventKey,
                    });
                  }}>
                  <Dropdown.Toggle
                    variant="secondary"
                    id="dropdown-jobcategory">
                    Filtered by: {filters.selectedCategory}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item eventKey="all">All</Dropdown.Item>
                    {filters.categories.map(
                      (category: CategoryFilter, index: number) => (
                        <Dropdown.Item key={index} eventKey={category.name}>
                          {category.name}
                        </Dropdown.Item>
                      ),
                    )}
                  </Dropdown.Menu>
                </Dropdown>
              ),
            },
          ]}>
          <Row>
            <Col>
              <Table responsive borderless>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Action Type</th>
                    <th>Action Name</th>
                    <th>Lifecycle</th>
                    <th>Person Responsible</th>
                  </tr>
                </thead>
                <tbody>
                  {data.instances &&
                    getInstancesTableBody(paginatedFilteredInstances)}
                </tbody>
              </Table>
            </Col>
          </Row>
          {data.fetchingInstances ? (
            <Row className="justify-content-sm-center">
              <Col sm="auto">
                <Loading size={"md"} />
              </Col>
            </Row>
          ) : (
            <div />
          )}
          <Row className="justify-content-sm-center">
            <Col sm="auto">
              <Paginator
                filters={filters}
                setFilterDispatch={setFilters}
                allPaginatedRecords={filteredInstances}
                pageKey="jobActionsPage"
              />
            </Col>
          </Row>
        </Card>
      ) : null}
    </Container>
  );
};

const getInstancesTableBody = (instancesList: any[]) => {
  if (instancesList.length > 0) {
    return instancesList.map((instance: any, index: number) => (
      <tr key={index}>
        <td>
          <OverlayTrigger
            placement="auto"
            overlay={
              <Tooltip id={instance.InstanceID}>
                {formatDateTime({
                  date: instance.InstanceWhenTs,
                  format: "DateAndTimeAndTimezone",
                })}
              </Tooltip>
            }>
            <Link to={`/instance/${instance.InstanceID}?display=jobs`}>
              {formatDateTime({
                date: instance.InstanceWhenTs,
                format: "Date",
              })}
            </Link>
          </OverlayTrigger>
        </td>
        <td>{instance.ActionTypeName}</td>
        {instance.ActionName.length > 25 ? (
          <OverlayTrigger
            placement="auto"
            overlay={
              <Tooltip id={instance.ActionName}>{instance.ActionName}</Tooltip>
            }>
            <td style={{ whiteSpace: "nowrap" }}>
              {text_truncate(instance.ActionName, 25)}
            </td>
          </OverlayTrigger>
        ) : (
          <td>{instance.ActionName}</td>
        )}
        <td>
          <LifeCycle placement="left" lifecycle={instance.LifeCycle} />
        </td>
        <td>{instance.InstanceResponsibleUserName}</td>
      </tr>
    ));
  }
  return (
    <tr>
      <td colSpan={5}>No Jobs</td>
    </tr>
  );
};

const getJobsTableBody = (jobList: any) => {
  if (jobList.length > 0) {
    return jobList.map((job: any, index: number) => {
      const IconType: keyof typeof Feather = job.ObjectTypeIcon;
      const Icon = Feather[IconType];

      return (
        <tr key={index}>
          <td>
            <Icon />
          </td>
          {job.RequirementName.length > 25 ? (
            <td style={{ whiteSpace: "nowrap" }}>
              <OverlayTrigger
                placement="right-end"
                overlay={
                  <Tooltip id={job.RequirementName}>
                    {job.RequirementReadableID} - {job.RequirementName}
                  </Tooltip>
                }>
                <Link to={`/job/${job.RequirementID}?display=jobs`}>
                  {job.RequirementReadableID} -{" "}
                  {text_truncate(job.RequirementName, 25)}
                </Link>
              </OverlayTrigger>
            </td>
          ) : (
            <td>
              <Link to={`/job/${job.RequirementID}?display=jobs`}>
                {job.RequirementReadableID} - {job.RequirementName}
              </Link>
            </td>
          )}
          <td>{job.ObjectTypeName}</td>
          <td>
            <Status
              placement="left"
              variant={objectStatusToVariant(job.ObjectStatusID)}
              text={job.ObjectStatusName}
              tooltip={job.ObjectStatusDescription}
            />
          </td>
          {job.RequirementDescription.length > 25 ? (
            <OverlayTrigger
              placement="auto"
              overlay={
                <Tooltip id={job.RequirementDescription}>
                  <Textarea
                    value={job.RequirementDescription}
                    name={`JobDescription${index}`}
                    readOnly
                    richText
                  />
                </Tooltip>
              }>
              <td style={{ whiteSpace: "nowrap" }}>
                <Textarea
                  value={text_truncate(job.RequirementDescription, 25)}
                  name={`jobDescription${index}`}
                  readOnly
                  richText
                />
              </td>
            </OverlayTrigger>
          ) : (
            <td>
              <Textarea
                value={job.RequirementDescription}
                name={`jobDescription${index}`}
                readOnly
                richText
              />
            </td>
          )}
        </tr>
      );
    });
  }
  return (
    <tr>
      <td />
      <td colSpan={4}>No Jobs</td>
    </tr>
  );
};

export { ScreensJobDashboard };
