// import {
//   Box,
//   Button,
//   ButtonGroup,
//   FormControl,
//   FormHelperText,
//   FormLabel,
//   HStack,
//   Heading,
//   Input,
//   Text,
//   VStack,
// } from "@chakra-ui/react";
import LogoUploader from "../LogoUploader";
import { CompanyIndustries, CompanySizes } from "../blueprintSelectOptions";
import { BlueprintStateAction } from "../useBlueprintState";
import BlueprintMenuSelect from "./BlueprintMenuSelect";
import { BlueprintState } from "../../../../shared/v2/definitions/blueprints";
import {
  Box,
  Group,
  Stack,
  TextInput,
  Title,
  rem,
  Select,
  Center,
  Button,
} from "@mantine/core";

const locations = Intl.supportedValuesOf("timeZone")
  .filter((tz) => tz.toLowerCase().includes("australia"))
  .map((tz) => ({
    value: tz,
    topLabel: tz.split("/")[0],
    restLabel: tz.split("/")[1]?.replace("_", " ") || "",
  }));
locations.push({
  value: "outside australia",
  topLabel: "Outside Australia",
  restLabel: "Outside Australia",
});

type Props = {
  orgInfo: BlueprintState["organisationInfo"];
  dispatch: (action: BlueprintStateAction) => void;
  onComplete: () => void;
};

export default function BlueprintOrganisationStepCard({
  orgInfo,
  onComplete,
  dispatch,
}: Props) {
  const handleUpdate = (
    key: "orgName" | "orgSize" | "orgIndustry" | "orgLocation",
    value: string,
  ) => {
    dispatch({
      type: "update-org-info",
      payload: {
        key,
        value,
      },
    });
  };

  const handleClickNext = () => {
    onComplete();
  };

  const isComplete = orgInfo.orgName && orgInfo.orgIndustry && orgInfo.orgSize;

  return (
    <Stack
      w="100%"
      px="sm"
      pt="lg"
      pb="xl"
      justify="space-between"
      style={{ flexGrow: 1 }}>
      <Stack w="100%" align="center">
        
        <Stack maw="30rem" gap="xl" pt="xl">
            <Title order={2}>Organisation Information</Title>
          <TextInput
            value={orgInfo.orgName || ""}
            onChange={(e) => handleUpdate("orgName", e.target.value)}
            label="Organisation Name"
            size="md"
            maw="28rem"
          />
          <Select
            label="What Industry is your organisation in?"
            value={orgInfo.orgIndustry}
            data={CompanyIndustries}
            searchable
            onChange={(sel) =>
              dispatch({
                type: "update-org-info",
                payload: {
                  key: "orgIndustry",
                  value: sel || "",
                },
              })
            }
            size="md"
            maw="28rem"
          />

          <Select
            label="Approximately how many employees does your company have?"
            value={orgInfo.orgSize}
            data={CompanySizes}
            searchable
            onChange={(sel) =>
              dispatch({
                type: "update-org-info",
                payload: {
                  key: "orgSize",
                  value: sel || "",
                },
              })
            }
            size="md"
            maw="28rem"
          />
        </Stack>
      </Stack>
      <Group w="100%" justify="end" style={{ alignSelf: "flex-end" }}>
        <Button disabled={!isComplete} onClick={handleClickNext}>Save and Continue</Button>
      </Group>
    </Stack>
  );
}
