import { useEffect, useRef, useState } from "react";

type EditableState = {
  realValue: string;
  currentValue: string;
  setValue: (value: string) => void;
  resetValue: () => void;
  isLoading: boolean;
  setIsLoading: (newStatus: boolean) => void;
};

const useEditableState = (realValue: string): EditableState => {
  const cachedValue = useRef<string | null>(realValue);
  const [currentValue, setCurrentValue] = useState(realValue);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const resetValue = () => {
    setCurrentValue(cachedValue.current || "");
  };

  useEffect(() => {
    console.log("use eff trigger");
    // Update realValue if its changed
    console.log({ realValue, cachedValue: cachedValue.current, currentValue });
    if (realValue !== cachedValue.current) {
      cachedValue.current = realValue;
      setCurrentValue(realValue);
    }
  }, [realValue, isLoading]);

  const handleSetStatus = (newIsLoading: boolean) => {
    if (!newIsLoading) {
      resetValue();
    }
    setIsLoading(newIsLoading);
  };

  return {
    realValue,
    currentValue,
    isLoading,
    setIsLoading: handleSetStatus,
    resetValue,
    setValue: (val: string) => setCurrentValue(val),
  };
};

export default useEditableState;
