import * as Feather from "react-feather";

type FeatherKeys = keyof typeof Feather;
const IconSet = Feather as Record<string, Feather.Icon>;
export type Props = {
  name: FeatherKeys | (string & {});
  cursor?: "pointer" | (string & {});
  size?: "sm" | "md" | "lg";
  margin?: string;
} & Feather.IconProps;

export const IconSizes = {
  sm: 12,
  md: 16,
  lg: 24,
};

const Icon = ({ name, size, ...otherProps }: Props) => {
  const FeatherIcon = IconSet[name];
  if (FeatherIcon) {
    return (
      <FeatherIcon
        style={{
          ...otherProps.margin ? { margin: otherProps.margin } : {}, 
          ...otherProps.cursor ? { cursor: otherProps.cursor } : {}
        }}
        size={size ? IconSizes[size] : IconSizes.md}
        {...otherProps}
      />
    );
  }
  console.warn(`Unknown icon [${name}]`);
  return <Feather.HelpCircle {...otherProps} />;
};

export default Icon;
