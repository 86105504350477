import React, { useState, useEffect } from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";
import moment from "moment";
import { get, put } from "../../../utilities/request";
import { Loading } from "../Loading/Loading";
import * as DocumentType from "../../../constants/documentType";
import useToast, { DisplayToastFn } from "../../../utilities/useToast";
import { formatDateFromDBToReadOnly } from "../../Forms/Field/Date";

type DocumentGeneratorPageStatus = "Loading" | "Ready";
interface DocumentRecord {
  DocumentID: string;
  DocumentName: string;
  CreateTs: string;
  ModifiedTs: string;
  CreateUserID: string;
  ModifiedUserID: string;
  AccountID: string;
  DocumentURL: string;
  RecordStatusID: string;
  DocumentRefTable: string;
  DocumentRefID: string;
  DocumentBucket: string;
  DocumentBucketPath: string;
  DocumentTypeID: string;
}
const generateDocumentDropdown = (
  documentData: DocumentRecord[],
  authState: any,
  setPageStatus: any,
) =>
  documentData.map((e: DocumentRecord, i: number) => (
    <Dropdown.Item
      key={i}
      onClick={() => {
        getDocument(e.DocumentID, setPageStatus);
      }}>
      {e.DocumentName}
      {e.DocumentBucketPath
        ? "." + e.DocumentBucketPath.split(".").pop()
        : null}{" "}
      ({moment(e.ModifiedTs).format("DD-MMM-YYYY hh:mm a")})
    </Dropdown.Item>
  ));

const getDocument = async (documentID: string, setPageStatus: any) => {
  setPageStatus("Loading");
  const result = await get(
    `document/${documentID}/${DocumentType.ReportGenerated}`,
  );
  console.log(result.data);
  openDocument(result.data.url);
  setPageStatus("Ready");
};

const openDocument = async (url: any) => {
  window.open(url);
};

const generateDocument = async (
  props: any,
  setPageStatus: any,
  setData: any,
  displayToast: DisplayToastFn,
  extension: "PDF" | "docx",
) => {
  setPageStatus("Loading");

  const { data } = props;

  let tokens: any = {};
  switch (data.ObjectTypeGroupName) {
    case "Issue":
      tokens = {
        RelatedRequirements: data.RiskIDs.map((req: any) => ({
          RequirementReadableID: req.RequirementReadableID,
          RequirementName: req.RequirementName,
        })),
      };
    case "Risk":
      tokens = tokens.RelatedRequirements
        ? tokens
        : {
          RelatedRequirements: data.RelatedRequirements.map((req: any) => ({
            RequirementReadableID: req.RequirementReadableID,
            RequirementName: req.RequirementName,
          })),
        };
    case "Requirement":
    case "Register":
    case "Job":
      tokens = {
        ...tokens,

        type: data.ObjectTypeGroupName,
        DocumentName: data.RequirementReadableID,
        DocumentRefID: data.RequirementID,
        name: data.RequirementName,
        printDate: moment().format("DD-MMM-YYYY hh:mm a"),
        docType: extension,
        tokens: {
          RequirementName: data.RequirementName,
          RequirementID: data.RequirementReadableID,
          RequirementStatus: data.ObjectStatusName,
          RequirementFields: {
            [`${data.ObjectTypeGroupName} Type`]: data.ObjectTypeName,
            Description: data.RequirementDescription,
            "Responsible User": data.RequirementResponsibleUserName,
          },
          RequirementCustomFields: data.CustomFields,
        },
      };

      // Conditional adds, dont show unless theres a value
      if (data.ParentName) {
        tokens.tokens.ParentName = data.ParentName;
      }
      if (data.Notes) {
        tokens.tokens.RequirementNotes = data.Notes;
      }

      break;
    case "Action":
      tokens = {
        type: data.ObjectTypeGroupName,
        DocumentName: data.ActionReadableID,
        DocumentRefID: data.ActionID,
        name: data.ActionName,
        printDate: moment().format("DD-MMM-YYYY hh:mm a"),
        docType: extension,
        tokens: {
          ActionName: data.ActionName,
          ActionID: data.ActionReadableID,
          ActionStatus: data.ObjectStatusName,
          ActionFields: {
            "Action Type": data.ObjectTypeName,
            Parent: data.ParentName,
            Description: data.ActionDescription,
            "First Task Date": moment
              .utc(data.ActionStartTs)
              .local()
              .format("DD-MMM-YYYY"),
            Recurrence: data.ActionRecurrenceText,
            "Responsible User": data.ActionResponsibleUsers
              ? data.ActionResponsibleUsers.map(
                (user: any) => user.UserFullName,
              ).join(", ")
              : data.ActionResponsibleUserID,
            "Action Window": `${data.ActionWindowBefore} days before ${data.ActionWindowAfter} days after`,
          },
          ActionCustomFields: data.CustomFields,
        },
      };

      if (data.Notes) {
        tokens.tokens.ActionNotes = data.Notes;
      }
      break;
    case "Instance":
      tokens = {
        type: data.ObjectTypeGroupName,
        DocumentName: data.InstanceReadableID,
        DocumentRefID: data.InstanceID,
        name: data.ActionName,
        printDate: moment().format("DD-MMM-YYYY hh:mm a"),
        docType: extension,
        tokens: {
          InstanceName: data.ActionName,
          InstanceID: data.InstanceReadableID,
          InstanceStatus: data.ObjectStatusName,
          InstanceFields: {
            "Action Type": data.ActionTypeName,
            Parent: data.ActionName,
            Description: data.ActionDescription,
            "Responsible User": data.InstanceResponsibleUsers
              ? data.InstanceResponsibleUsers.map(
                (instance: any) => instance.InstanceResponsibleUserName,
              ).join(", ")
              : data.InstanceResponsibleUserName,
            Due: moment.utc(data.InstanceWhenTs).local().format("DD-MMM-YYYY"),
          },
          InstanceCustomFields: data.CustomFields,
        },
      };
      break;
    default:
      throw new Error(`Unknown document type ${data.ObjectTypeGroupName}`);
  }

  tokens.timezone = moment.tz.guess(true);

  let result;
  try {
    result = await put("document", tokens);
    openDocument(result.data.DocumentURL);
    props.data.Documents = [result.data];
    setData({ ...props });
  } catch (err) {
    displayToast({
      status: "error",
      title: `Document generation failed`,
    });
  }

  setPageStatus("Ready");
  return result;
};

const DocumentGenerator = (props: any) => {
  const [data, setData] = useState<any>(null);
  const { displayToast } = useToast();
  const [pageStatus, setPageStatus] =
    useState<DocumentGeneratorPageStatus>("Ready");

  useEffect(() => {
    setData(props);
  }, [props]);

  if (data) {
    if (pageStatus === "Ready") {
      return (
        <>
          <DropdownButton
            variant="outline-dark"
            id="documentDropdown"
            title="Print">
            <Dropdown.Item
              onClick={() => {
                generateDocument(
                  data,
                  setPageStatus,
                  setData,
                  displayToast,
                  "docx",
                );
              }}>
              Generate Word Document
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                generateDocument(
                  data,
                  setPageStatus,
                  setData,
                  displayToast,
                  "PDF",
                );
              }}>
              Generate PDF
            </Dropdown.Item>
            {generateDocumentDropdown(
              data.data.Documents,
              data.authState,
              setPageStatus,
            )}
          </DropdownButton>
        </>
      );
    }
    return <Loading noBorder />;
  }
  return null;
};

export { DocumentGenerator };
